import React, { useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import * as queryString from "query-string";
import {
  FiChevronDown,
  FiEdit3,
  FiExternalLink,
} from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import csvDownload from "json-to-csv-export";
import { toast } from "react-toastify";
import { animateScroll } from "react-scroll";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { useFetchAllActiveSellersQuery } from "@ekenta/redux/store/services/adminSellerApi";
import {
  AdminSellerProfileId,
  DashboardSellers,
} from "@ekenta/routes/constant";
import ActiveSellerDialog from "./Dialog/ActiveSellerDialog";
import Export from "./Headers/Export";
import { Pagination, DataTable, HelmetHeader } from "@ekenta/components";
import Dropdown from "@ekenta/components/Dropdown/Dropdown";
import PageTitle from "@ekenta/components/PageTitle/PageTitle";
import { VscArrowSmallLeft } from "react-icons/vsc";
import withDashboardLayout from "@ekenta/hoc/Layout/withDashboardLayout";

const AllActiveSellers = () => {
  const query = queryString.parse(window.location.search);
  const _page = query.page;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useAppSelector((state) => state.auth.authentication);

  const [pageno, setPageno] = useState(0);
  const [showModal, setshowModal] = useState(false);
  const [dataObj, setDataObj] = useState<any | null>(null);

  const result = useFetchAllActiveSellersQuery({ pageno, token });

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",
    },
    {
      Header: "Store Name",
      accessor: "store",
      sortType: "basic",
    },
    {
      Header: "Date",
      accessor: "date",
      sortType: "basic",
    },
    {
      Header: "Live code",
      accessor: "liveLink",
      disableSortBy: true,
    },
    {
      Header: "Live key",
      accessor: "liveKey",
      disableSortBy: true,
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: "option",
      disableSortBy: true,

    },
  ];

  useEffect(() => {
    scrollToTop();

    setTimeout(() => {
      if (_page) {
        const _newPage = parseInt(_page.toString());
        if (_newPage > 0) {
          setPageno(_newPage - 1);
        } else {
          navigate(`${pathname}?page=1&sortType=default`);
        }
      } else {
        setPageno(0);
      }
    }, 1000);
  }, [_page]);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };



  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      name: item.merchantOwner.firstName + " " + item.merchantOwner.lastName,
      store: item.businessName,
      date: item.merchantOwner.createdDate,
      status: item.sellerRequestStatus,
      liveLink: item.liveLink,
      option: (
        <Dropdown
          name={"more"}
          suffixIcon={<FiChevronDown />}
          placement="bottom"
          menus={[
            {
              name: "Edit Livecode & key",
              icon: <FiEdit3 />,
              active: false,
              key: "live-code-key",
              handler: () => triggerPopup(item),
            },
            {
              name: "Seller Profile",
              icon: <FiExternalLink />,
              active: false,
              key: "seller-profile",
              handler: () =>
                navigate(`${AdminSellerProfileId}/${item.merchantOwner.id}`),
            },
            {
              name: "View details",
              icon: <FiExternalLink />,
              active: false,
              key: "view-detail",
              handler: () =>
                navigate(`/admin/seller-review/${item.merchantOwner.id}?type=active`),
            },
          ]}
          tooltipContent={"More Option"}
        />
      ),
    }));
  };

  const triggerPopup = (data: any) => {
    setshowModal(true);
    setDataObj(data);
  };

  const handleClose = () => setshowModal(false);

  const handleDownloadData = useCallback(
    (type: string) => {
      if (result?.data) {
        if (type === "csv") {
          try {
            const data: any = [];
            result?.data.content.map((item: any) => {
              data.push({
                firstname: item.merchantOwner.firstName,
                lastname: item.merchantOwner.lastName,
                email: item.merchantOwner.email,
                phoneNumber: item.merchantOwner.phoneNumber,
                businessName: item.businessName,
                businessPhoneNumber: item.contactPersonPhoneNumber,
              });
            });
            const name = `${`Activesellers`}-ekenta.csv`;
            csvDownload(data, name);
            toast.success("Exported csv file successfully", {
              toastId: "export-csv-id-toast-success",
            });
          } catch (err) {
            toast.error("Unable to export to csv file", {
              toastId: "export-to-csv-toast-error",
            });
          }
        }
      }
    },
    [result?.data],
  );

  return (
    <>
      <HelmetHeader
        title="All Active Sellers"
        description="Ekenta All Active Sellers Page"
      />
      <Col className=" col-lg-12 mx-auto _listing_product_ dash_top px-2">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title=" Active Sellers"
              subTitle={

                <span
                  className="cursor-pointer d-flex w-25 align-items-center 
                    text-muted"
                  onClick={() => navigate(DashboardSellers)}>
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            >
              <Export downloadData={handleDownloadData} name={"Export"} />
            </PageTitle>
          </Col >

          <Col className="mt-4 tab__ position-relative">
            <DataTable
              theme="simple"
              data={result?.data ? mapData(result?.data) : []}
              loading={result?.isLoading}
              isSelectable={true}
              columns={columns}
              showPagination={false}
              hasLink={true}
              thClassName="w-25"
              trClassName="w_Tr"
              tdClassName={`Row__col`}
              renderPagnation={
                <>
                  {result?.data
                    ? result?.data.totalElements > 20 && (
                      <Pagination
                        pathname={pathname}
                        itemPerPage={result?.data.pageable.pageSize}
                        itemsLength={result?.data.totalElements}
                        currentPage={pageno}
                      />
                    )
                    : null}
                </>
              }
            />

            <br />
          </Col>
        </div >
      </Col >

      <ActiveSellerDialog
        data={dataObj}
        show={showModal}
        onClose={handleClose}
      />


    </>
  );
};

export default withDashboardLayout(AllActiveSellers);
