/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react'
import { Radio, StarRating } from '@ekenta/components';
import { SearchFilterContext } from '@ekenta/context/SearchFilterContext';
import "./Rating.scss";

const Ratings = () => {
    const { configureFilterUrl } = useContext(SearchFilterContext);


    const listRating = [
        {
            id: "star-4",
            name: "rating",
            rating: 4,
        },
        {
            id: "star-3",
            name: "rating",
            rating: 3,
        },
        {
            id: "star-2",
            name: "rating",
            rating: 2,
        },
        {
            id: "star-1",
            name: "rating",
            rating: 1,
        }
    ];

    const handleRating = (value: number) => {
        let data = { key: "rating", value: 0, set: true };
        if (value) {
            data = { ...data, value: value, set: true }
        } else {
            data = { ...data, set: false }
        }
        configureFilterUrl(data);

    }


    return (
        <div className="RatingDiv__Wrapper">
            <div className="Rating__Checkbox">
                {listRating.map((item: any) => (
                    <RatingCheck
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        rating={item.rating}
                        onChange={(value) => handleRating(value)}
                    />

                ))}
            </div>
        </div>
    )
}

const RatingCheck = (props: {
    id: string;
    name: string;
    rating: number;
    onChange: (value: number) => void;
}) => {
    return (
        <div className="Rating__FlexItem">
            <Radio
                id={props.id}
                name={props.name}
                className="Radio mt-2"
                variant="primary"
                disabled={false}
                onChange={() =>
                    props.onChange(props.rating)
                }
            />
            <label htmlFor={props.id}>
                <StarRating
                    name="rating"
                    value={props.rating}
                    starDimension="20px"
                    starSpacing="1px"
                    color="primary"
                    numberOfStars={5}
                />
            </label>

        </div>
    )
}

export default Ratings;