import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom'
import { accountProfilePathUrl } from '../../../../routes/constant'

interface BreadCrumbPropsType {activePage:string;}

export default function BreadCrumb(props:BreadCrumbPropsType) {
  return (
    <div className="mt-0 _ct_wrapper_">
      {/* <div className="d-block d-lg-none __back_Btn_mobile">
        <span className="d-flex align-items-center" onClick={() => navigate(mobileAccountIndexPath)}>
          <FiArrowLeft fontSize={18} />
          <span className="ms-2">Back</span>
        </span>
      </div> */}
     <div className="d-none d-lg-block __sub_wrapper">
        <Breadcrumb className="_p_">
        <Breadcrumb.Item className="d-none d-lg-flex">
                <Link to={accountProfilePathUrl}>My Account</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="active_breadcrumb">{props.activePage}</Breadcrumb.Item>
        
        </Breadcrumb>
    </div>
    </div>
  )
}
