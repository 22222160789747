import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const updateAccountAddressFormValidation = () => Yup.object().shape({

    firstname: Yup.string()
               .required('Firstname is required'),
    lastname: Yup.string()
               .required('Lastname is required'),
    phonenumber: Yup.string()
               .matches(phoneRegExp, 'Phone number is not valid')
               .required('Phone Number is required'),
    othernumber: Yup.string()
               .matches(phoneRegExp, 'Phone number is not valid'),
    address: Yup.string()
                .required('Address is required'),
    otherinformation: Yup.string(),
    region:  Yup.string().required('Region is required!'),
    city:  Yup.string().required('City is required!'),
});

export default updateAccountAddressFormValidation;