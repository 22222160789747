import { Switch } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col } from "react-bootstrap";
import { FiChevronDown, FiEdit3, FiTrash2 } from "react-icons/fi";
import * as queryString from "query-string";
import { VscAdd, VscArrowSmallLeft } from "react-icons/vsc";
import { useNavigate, useLocation } from "react-router";
import { SpinLoader } from "@ekenta/components/Spinner/SpinLoader";
import { useAppSelector } from "@ekenta/redux/store/hook";
import {
  useFlagToggleMutation,
  useGetSingleSellerDetailQuery,
} from "@ekenta/redux/store/services/adminSellerApi";
import {
  useFetchAdminAllProductsQuery,
  useFetchSellerProductsQuery,
} from "@ekenta/redux/store/services/vendorProductApi";
import {
  DashboardAddProductPathUrl,
  DashboardPathUrl,
  DashboardSellers,
} from "@ekenta/routes/constant";
import { checkIfAdmin } from "@ekenta/utils";
import { withDashboardLayout } from "@ekenta/hoc";
import { UnAuthorizedCard } from "../component/UnAuthorizedCard";
import { HelmetHeader } from "../../../components/HelmetHeader/HelmetHeader";
import RoundedButton from "../../../components/CustomButtons/RoundedButton";
import { Overlay, Pagination, Loader, PageTitle, DataTable, CustomAlert, Dropdown } from "@ekenta/components";
import { BASE_URL } from "@ekenta/constants";
import axios from "axios";


const AllProducts = () => {
  const query = queryString.parse(window.location.search);
  const _page = query.page;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [pageno, setPageno] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);


  const { token, account } = useAppSelector((state) => state.auth.authentication);
  const isAdmin = checkIfAdmin(token);
  const id = account.user.id;


  let getResult;
  !isAdmin ? (getResult = useGetSingleSellerDetailQuery(token)) : null;

  let result: any;
  isAdmin
    ? (result = useFetchAdminAllProductsQuery({ pageno, token }))
    : (result = useFetchSellerProductsQuery({ id, token, pageno }));






  const columns = [
    {
      Header: "Picture",
      accessor: "image",
      sortType: "basic",
      disableSortBy: true,
    },

    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",
    },
    {
      Header: "Price",
      accessor: "price",
      sortType: "basic",
    },
    {
      Header: "Subcollection",
      accessor: "subcollection",
      sortType: "basic",
    },

    {
      Header: "Quantity",
      accessor: "stock",
      disableSortBy: true,
    },
    {
      Header: `${isAdmin ? "Flagged" : "Published"}`,
      accessor: "visiblity",
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: "option",
      disableSortBy: true,
    },
  ];


  useEffect(() => {
    setTimeout(() => {
      if (_page) {
        const _newPage = parseInt(_page.toString());
        if (_newPage > 0) {
          setPageno(_newPage - 1);
        } else {
          navigate(`${pathname}?page=1&sortType=default`);
        }
      } else {
        setPageno(0);
      }
    }, 1000);
  }, [_page]);

  const togglePublish = (productId: string, visibility: boolean) => {
    if (productId) {
      const status = visibility ? "unpublish" : "publish";
      const _visibility = visibility ? false : true;
      toggleFunction(status, productId, _visibility);
    }
  };

  const toggleFlagged = (productId: string, isFlagged: boolean) => {
    if (productId) {
      const status = isFlagged ? "unflag" : "flag";
      toggleFunction(status, productId, false);
    }
  };

  const toggleFunction = useCallback((status: string, prodId: string, visibility: boolean) => {
    const url = isAdmin ? `admin/product/flag/${prodId}`
      : `product/publish/${prodId}?visibility=${visibility}`;
    const config = {
      method: 'PUT',
      url: `${BASE_URL}/api/${url}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      data: ""
    };
    setIsLoading(true);
    axios(config)
      .then(function () {
        setIsLoading(false);
        toast.success(`Product ${status} successfully`, {
          toastId: "-product-toggle-success-id-toast",
        });
        result?.refetch();
      })
      .catch(function () {
        setIsLoading(false);
        toast.error(`Something went wrong`, {
          toastId: "-toggle-error-400-id-toast-error",
        });
        result?.refetch();
      });
  }, [result]);

  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      image: (
        <div
          className="picture__thumb"
          style={{ width: "70px", height: "auto" }}
        >
          <img
            className="img-thumbnail rounded"
            width="100%"
            height="50px"
            src={item?.imageUrls[0]}
          />
        </div>
      ),
      name: item?.name,
      price: item?.price,
      // subcollection: item?.subCollection.name,
      stock: item?.stock,
      visiblity: (
        <>
          {isAdmin ? (
            <Switch
              defaultChecked={item?.flag ?? false}
              size="small"
              color="warning"
              onChange={() => toggleFlagged(item?.id, item?.flag)}
            />
          ) : (
            <Switch
              readOnly={item?.flag}
              size="small"
              color="warning"
              defaultChecked={item?.stock > 0 ? item?.visibility : false}
              disabled={item?.stock > 0 && !item?.flag ? false : true}
              onChange={() =>
                item?.stock > 0 && !item?.flag ? togglePublish(item?.id, item?.visibility) : console.log("")
              }
            />
          )}
        </>
      ),
      option: (
        <Dropdown
          name={"more"}
          suffixIcon={<FiChevronDown />}
          placement="bottom"
          menus={[
            {
              name: "Edit product",
              icon: <FiEdit3 />,
              active: false,
              key: "live-code-key",
              handler: () =>
                navigate(
                  DashboardAddProductPathUrl + `?_id=${item?.id}&type=edit`,
                ),
            },
            {
              name: "Delete product",
              icon: <FiTrash2 />,
              active: false,
              key: "view-detail",
              handler: () => console.log("delete"),
            },
          ]}
          tooltipContent={"More Option"}
        />
      ),
    }));
  };

  const navBack = isAdmin ? DashboardSellers : DashboardPathUrl;
  return (
    <>
      <HelmetHeader
        title="All Products"
        description="Ekenta All Products Page"
      />

      {getResult?.isLoading ? (
        <SpinLoader size={2} variant="dark" />
      ) : (
        <>
          {(getResult?.isSuccess &&
            getResult?.data.sellerRequestStatus === "APPROVED") ||
            isAdmin ? (
            <Col className="_listing_product_ mt-0 px-2">
              <div className="mt-0">
                <Col className="AppEvents_col mx-1 mx-lg-0">
                  <PageTitle
                    title="All Products"
                    subTitle={
                      <span
                        className="cursor-pointer d-flex w-25 align-items-center
                            text-muted"
                        onClick={() => navigate(navBack)}
                      >
                        <VscArrowSmallLeft />
                        Back to Dashboard
                      </span>
                    }
                  />
                </Col>
                <div className="w-100 mt-1">
                  <div className="_horizontal_buttons_div py-2">
                    <div className="_horizontal_btn_group">
                      <RoundedButton
                        text={"Sell New"}
                        icon={<VscAdd />}
                        handler={() =>
                          navigate(
                            DashboardAddProductPathUrl +
                            "?type=add&condition=new",
                          )
                        }
                      />
                      <RoundedButton
                        text={"Sell Used"}
                        icon={<VscAdd />}
                        handler={() =>
                          navigate(
                            DashboardAddProductPathUrl +
                            "?type=add&condition=used",
                          )
                        }
                      />
                    </div>

                  </div>
                </div>
                <Col className="mt-2 tab__ position-relative">
                  {result?.isError && (
                    <CustomAlert
                      variant="danger"
                      show={true}
                      message="Unable to load Content"
                    />
                  )}

                  <DataTable
                    theme="simple"
                    data={
                      result?.data ? mapData(result?.data.content) : []
                    }
                    loading={
                      result?.isLoading}
                    isSelectable={true}
                    columns={columns}
                    showPagination={false}
                    hasLink={true}
                    thClassName="w"
                    trClassName="w"
                    tdClassName={`Row__col`}
                    align="left"
                    searchValue={searchValue}
                    showSearch={isAdmin ? true : false}
                    onChange={(e: any) => setSearchValue(e.target.value)}
                    renderPagnation={
                      <div className="mt-4">
                        {!searchValue ? result?.data
                          ? result?.data.totalElements > 20 && (
                            <Pagination
                              pathname={pathname}
                              itemPerPage={result?.data.pageable.pageSize}
                              itemsLength={result?.data.totalElements}
                              currentPage={pageno}
                            />
                          )
                          : null : null}
                      </div>
                    }
                  />
                  <br />
                </Col>
              </div>
            </Col>
          ) : (
            <UnAuthorizedCard />
          )}
        </>
      )}
      {isLoading ? (
        <Overlay bgColor="light">
          <div
            className="d-flex align-items-center 
                 justify-content-end mx-auto w-25 p-4"
          >
            <Loader withWrapper />
          </div>
        </Overlay>
      ) : null}
    </>
  );
};
export default withDashboardLayout(AllProducts);
