import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { FiExternalLink, FiPlus } from 'react-icons/fi';
import styled from 'styled-components';
import {
  useCreateEventMutation,
  useFetchAllEventQuery,
} from '../../../redux/store/services/adminSellerApi';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import CustomModal from '../../../components/Modal/CustomModal';
import PageTitle from '../../../components/PageTitle/PageTitle';
import withDashboardLayout from '../../../hoc/Layout/withDashboardLayout';
import DataTable from '../../../components/DataTable/DataTable';
import { Pagination, Button } from '../../../components';
import { useAppSelector } from '../../../redux/store/hook';
import { AdminDashoardEkeMarketPathUrl, DashboardSellers } from '../../../routes/constant';
import { useLocation, useNavigate } from 'react-router';
import { VscArrowSmallLeft } from 'react-icons/vsc';

const mapDataAddUrl = (data: any, urls: { name: string; url: string }[]) => {
  const newData = [];
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < urls.length; j++) {
        if (data[i].name.toLowerCase() == urls[j].name.toLowerCase()) {
          newData.push({
            ...data[i],
            url: `${urls[j].url}?_id=${data[i].id}`,
          });
        } else {
          newData.push({
            ...data[i],
            url: '',
          });
        }
      }
    }
  }
  return newData;
};

const Events = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.auth.authentication);

  const [modal, setModal] = useState(false);
  const [event, setEvent] = useState({
    name: '',
  });
  const [iserror, setisError] = useState('');

  const [createEvent, result] = useCreateEventMutation();
  const allEvent = useFetchAllEventQuery(token);

  useEffect(() => {
    if (result?.isSuccess) {
      setModal(false);
    }
  }, [result?.isSuccess]);

  const handleModal = () => setModal(true);

  const handleSubmit = () => {
    setisError('');

    if (event.name.trim()) {
      const data = {
        name: event.name,
      };
      createEvent({
        data,
        token,
      });
    } else {
      setisError('*Field is required');
    }
  };

  const navigateTo = (url: string) => {
    if (url) {
      navigate(url);
    }
  };

  const urls = [
    {
      name: 'ekemarket',
      url: AdminDashoardEkeMarketPathUrl,
    },
  ];
  const columns = React.useMemo(
    () => [
      {
        Header: 'Event Name',
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: 'Url',
        accessor: 'url',
        sortType: 'basic',
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <span
              className="ViewExternal__link"
              onClick={() => navigateTo(value)}
            >
              <FiExternalLink />
              view event
            </span>
          );
        },
      },
    ],
    [],
  );

  return (
    <div className="EventDiv_Wrapper">
      <HelmetHeader title="Events " description="Ekenta Events Page" />
      <Col className="AppEvents_col">
        <PageTitle
          title="Events"
          subTitle={
            <span
              className="cursor-pointer d-flex align-items-center
              text-muted"
              onClick={() => navigate(DashboardSellers)}>
              <VscArrowSmallLeft />
              Back to Dashboard
            </span>
          }
        >
          <Button
            prefixIcon={<FiPlus />}
            name="Add Event"
            onClick={handleModal}
          />
        </PageTitle>
      </Col>
      <Col>
        <DataTable
          theme="simple"
          data={allEvent?.data ? mapDataAddUrl(allEvent?.data, urls) : []}
          loading={allEvent?.isLoading}
          isSelectable={true}
          columns={columns}
          showPagination={false}
          thClassName="w-25"
          trClassName="w-25"
          tdClassName={`Row__col`}
          renderPagnation={() => (
            <>
              {allEvent?.data && allEvent?.data.length > 0 ? (
                <div className="mt-3">
                  <Pagination
                    pathname={pathname}
                    itemPerPage={1}
                    itemsLength={10}
                    currentPage={1}
                  />
                </div>
              ) : null}
            </>
          )}
        />
      </Col>
      <CustomModal
        title="Add Event"
        show={modal}
        centered={false}
        showFooter={true}
        onHide={() => {
          setModal(false);
          setEvent({ name: '' });
        }}
        isLoading={result?.isLoading}
        onSubmit={handleSubmit}
        render={
          <RenderComponent event={event} error={iserror} setEvent={setEvent} />
        }
      />
    </div>
  );
};

const RenderComponent = (props: {
  event: { name: string };
  error: string;
  setEvent: React.Dispatch<
    React.SetStateAction<{
      name: string;
    }>
  >;
}) => {
  return (
    <DIV>
      <div className="mb-2">
        <label>Name</label>
        <Input
          type="text"
          height={'400px'}
          name="addevent"
          placeholder="Event name"
          value={props.event.name}
          onChange={(e) =>
            props.setEvent({ ...props.event, name: e.target.value })
          }
        />
        {props.error && !props.event.name ? <small>{props.error}</small> : null}
      </div>
    </DIV>
  );
};

const DIV = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    font-size: 13px;
    margin-bottom: 5px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
  }

  small {
    font-size: 12px;
    color: red;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
`;

export default withDashboardLayout(Events);
