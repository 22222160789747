import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FiMinus, FiPlus } from 'react-icons/fi';
import {
  useFindUserCartItemByIdReturnQuantityQuery,
  useRemoveUserCartItemMutation,
  useUpdateUserCartItemQuantityMutation,
} from '../../../redux/store/services/cartApi';
import styled from 'styled-components';
import { useAppSelector } from '../../../redux/store/hook';
import { useLocation } from 'react-router';
import { VscTrash } from 'react-icons/vsc';
import toast from 'react-hot-toast';

interface QuantityCartButtonIKitchenPropsType {
  cartItemId: string | null;
  cartItems: any;
  className: string;
}

export default function QuantityCartButtonIKitchen({
  cartItemId,
  cartItems,
  className,
}: QuantityCartButtonIKitchenPropsType) {

  const [isAdded, setIsAdded] = useState(true);

  const [inputValue, setInputValue] = useState<number>(1);

  const [quantity, setQuantity] = useState<string | number>('');

  const { token } = useAppSelector((state) => state.auth.authentication);
  const {
    data: cartquantity_data,
    refetch,
  } = useFindUserCartItemByIdReturnQuantityQuery(
    { token, cartItemId },
    {
      skip: !cartItemId || !token,
    },
  );

  const [
    updateUserCartItemQuantity,
    { isLoading, isFetching, isSuccess, isError },
  ] = useUpdateUserCartItemQuantityMutation();
  const [
    removeUserCartItem,
    { isError: remove_isError, isSuccess: remove_isSuccess },
  ] = useRemoveUserCartItemMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        !isAdded ? (
          <div className="cus_t">
            <span></span>Quantity reduced in your cart list
          </div>
        ) : (
          <div className="cus_t">
            <span></span>Quantity added to your cart list
          </div>
        ),
      );
    }



    if (cartquantity_data) {
      setInputValue(parseInt(cartquantity_data.toString()));
    }

    if (inputValue && cartquantity_data) {
      setQuantity(inputValue);
    }
  }, [cartquantity_data, remove_isError, isError, remove_isSuccess]);

  const handleReduceQuantity = () => {
    const _value = inputValue - 1;
    console.log('reduce');

    if (cartItemId) {
      updateUserCartItemQuantity({
        token: token,
        quantity: _value,
        cartItemId: cartItemId,
      });
      setIsAdded(false);
    }
  };

  const handleIncreaseQuantity = () => {
    const _value = inputValue + 1;
    setInputValue(_value);
    if (cartItemId) {
      updateUserCartItemQuantity({
        token: token,
        quantity: _value,
        cartItemId: cartItemId,
      });
      setIsAdded(true);
    }
  };

  const handleRemoveCartItem = (cartItemId: string | null) => {
    if (cartItemId) {
      removeUserCartItem({
        token: token,
        cartItemId: cartItemId,
      });
    }
  };

  return (
    <>
      {/* { pathname !== useMatchPath(productDetailPathUrl,pathname) && (
      <Button className="action_round_btn p-0">
          <span className="mr2">
            {ek_isAuth && token ? (
              <>
              {isFetching || isLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner size="sm" animation="border" variant="white" />
                  </div> ) : 
                  <>
                      {cartquantity_isSuccess ? (<>{cartquantity_data}</>) : (
                         <div className="d-flex align-items-center justify-content-center">
                            <Spinner size="sm" animation="border" variant="white" />
                         </div>
                      )}
                  </>}
              </>
            ): (
              <>
                {cartItems ? (<>{cartItems.quantity}</>) : (
                  <div className="d-flex align-items-center justify-content-center">
                      <Spinner size="sm" animation="border" variant="white" />
                  </div>
                )}
              </>
            )}
          </span>
      </Button>
    )} */}

      <Container className={className}>
        <div className="quantity--div w-100">
          {cartquantity_data && cartquantity_data <= 1 ? (
            <span
              className="minus--button_span"
              style={
                quantity && quantity > 1
                  ? { background: '#ff4026' }
                  : { background: '' }
              }
              onClick={() => handleRemoveCartItem(cartItemId)}
            >
              <VscTrash />
            </span>
          ) : (
            <span
              className="minus--button_span"
              style={
                quantity && quantity > 1
                  ? { background: '#ff4026' }
                  : { background: '' }
              }
              onClick={handleReduceQuantity}
            >
              <FiMinus />
            </span>
          )}

          <div className="quantity--display">
            {isLoading || isFetching ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner size="sm" animation="border" variant="white" />
              </div>
            ) : (
              `${
                cartquantity_data
                  ? cartquantity_data + ' ' + 'added'
                  : 'loading...'
              } `
            )}
            <input
              className="w-25"
              style={{ display: 'none' }}
              value={inputValue}
              disabled
            />
          </div>
          <span
            className="increase--button"
            style={{ background: '#ff4026' }}
            onClick={handleIncreaseQuantity}
          >
            <FiPlus />
          </span>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 150px;
  height: auto;
  padding: 0.4rem 0.1rem;
  background-color: #fc624d;
  border-radius: 20px;
  transition: all 0.3s ease-out;

  .quantity--div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .quantity--display {
      font-size: 15px !important;
      text-align: center;
      color: #fff;
    }

    .minus--button_span,
    .increase--button {
      height: 25px;
      width: 25px;
      font-size: 15px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: #ff4026 !important;
      }
    }
  }
`;
