import React from "react";
import { ListGroup, Card, Row, Col } from "react-bootstrap";
import { VscHeart } from "react-icons/vsc";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import RemoveCartButton from "./CartButtons/RemoveCartButton";
import QuantityCartPageButton from "./CartButtons/QuantityCartPageButton";
import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./CartSingleItem.module.scss";
import { style } from "@mui/system";
import Tag from "../Tag/Tag";



export default function CartSingleItem(props: any) {
    //const [cartItemId, setCartItemId] = useState<{id:number} | any>({id: null});
    //const [_quantity, setQuantity] = useState<number>(props.quantity);

    return (
        <ListGroup.Item as={"li"} className={styles.ListGroup__Item}>
            <Card style={{ width: "100%" }}>
                <div className="row g-0">
                    <div className="col-md-4">
                        <Link to={props.productUrl}>
                            <LazyLoadImage
                                className={styles.ListGroup__Item__LoadImage}
                                effect="blur"
                                height={'100%'}
                                width={'100%'}
                                src={props.imageUrl}
                                alt={props.title ? props.title : ''}
                            />
                        </Link>
                    </div>
                    <div className="col-md-8">
                        <Card.Body>
                            <Link to={props.productUrl}>
                                <Card.Title className={styles.ListGroup__Item__Title}>
                                    {props.title.toLowerCase()}
                                </Card.Title>
                                <div className="card-text">
                                    <div className="cart_listing_price">
                                        <Tag
                                            numberOf={2}
                                            className={styles.Tag}
                                        />
                                        <NumberFormat
                                            value={`${props.cartItemPrice}`}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="₦"
                                            className="some"
                                        />
                                        {/* <p className="actual_price_quantity">
                                            <NumberFormat
                                                value={`${props.productPrice}`}
                                                displayType="text"
                                                thousandSeparator={true}
                                                prefix="₦"
                                                className="some"
                                            />
                                            <span className="ms-1">x {props.quantity} {props.quantity > 1 ? "Items" : "Item"}</span>
                                        </p> */}
                                    </div>
                                </div>
                            </Link>
                        </Card.Body>
                    </div>
                </div>
                <div className="_cart_bottom_div">
                    <Row>
                        <Col xs={3} className="_action_icons_col me-1 ps-0">
                            <RemoveCartButton
                                text="Remove"
                                className="cursor-pointer remove__item"
                                cartItemId={props.cartItemId}
                            />
                        </Col>
                        <Col xs={8} className="_action_right_">
                            <div className="cart_listing_quantity mb-1">

                                <QuantityCartPageButton
                                    cartItem={props.cartItem}
                                    cartItemId={props.cartItemId ? props.cartItemId : ""}
                                />
                                {/* {isLoading || isFetching  ? (<span className="w-100 spinner_div text-center d-flex align-item-center justify-content-center "><Spinner size="sm" animation="border" variant="secondary" /></span>) 
                        : (<QuantityPicker value={_quantity} min={1} 
                         onChange={(value:number) => handleChangeQuantity(value,props.cartItemId)} />)} */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>
        </ListGroup.Item>
    );
}
