import React, { Suspense, useEffect, useState } from "react";
import { getIn, useFormik } from "formik";
import { toast } from "react-toastify";
import { Col, Form, Spinner } from "react-bootstrap";
import { useAppSelector } from "@ekenta/redux/store/hook";
import PageTitle from "../../PageTitle/PageTitle";
import {
  useAddNewUserAddressMutation,
  useFetchAllStateQuery,
  useFetchCitiesByStateIdQuery,
  useFetchUserDefaultAddressQuery,
} from "@ekenta/redux/store/services/accountApi";
import addNewAddressFormValidation from "@ekenta/validations/schemas/newaddress-form.schema";
import TextButton from "../../CustomButtons/TextButton";
import Button from "@ekenta/components/Button/Button";

const CustomSelect = React.lazy(() => import("../../Select/CustomSelect"));

interface AddressFormPropsType {
  onClose: () => void;
  defaultAddress?: boolean;
  isModal?: boolean;
}

const mapStateData = (data: any) => {
  return (
    data &&
    data.map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
  );
};

export default function AddressForm({ onClose, defaultAddress, isModal }: AddressFormPropsType) {
  const [stateId, setStateId] = useState<null | string>(null);
  const [stateOptions, setStateOption] = useState<any>(null);
  const [cityOptions, setCityOption] = useState(null);

  const [initialValues, setInitialValues] = useState({
    homeAddress: "",
    stateId: "",
    cityId: "",
  });

  const { token } = useAppSelector((state) => state.auth.authentication);
  const { data, isLoading, isFetching, isError } = useFetchAllStateQuery();

  const {
    data: fetchCity_data,
    isLoading: fetchCity_isLoading,
    isFetching: fetchCity_isFetching,
    isError: fetchCity_isError,
  } = useFetchCitiesByStateIdQuery({ stateId }, { skip: !stateId });

  const [
    addNewUserAddress,
    {
      isLoading: newaddress_isLoading,
      isFetching: newaddress_isFetching,
      isError: newaddress_isError,
      isSucess: newaddress_isSuccess,
    },
  ] = useAddNewUserAddressMutation();

  const defaultValue = (options: any, val: string) => {
    return options ? options.find((option: any) => option.value === parseInt(val)) : "";
  };

  useEffect(() => {
    if (data) {
      const objs = mapStateData(data);
      setStateOption(objs);
    }

    if (fetchCity_data) {
      const resObj = mapStateData(fetchCity_data);
      setCityOption(resObj);
    }

    if (newaddress_isSuccess) {
      //toastDisplay("success","Address was successfully",``,true, `you have added a default address`);
      toast.success(`Address was added successfully`, {
        toastId: "address--id-toast-success",
      });
      //refetch();
      window.location.reload();
      onClose();
    }

    if (newaddress_isError) {
      toast.error(`Could not add address`, {
        toastId: "address--id-toast-error",
      });
    }
  }, [isLoading, fetchCity_data, newaddress_isSuccess, newaddress_isError]);

  const handleStateChange = (value: string) => {
    setStateOption(null);
    setStateId(value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addNewAddressFormValidation(),
    onSubmit: (values, { resetForm }) => {
      const data = {
        cityId: values.cityId,
        stateId: values.stateId,
        defaultAddress: defaultAddress ? defaultAddress : false,
        homeAddress: values.homeAddress,
      };
      addNewUserAddress({ data, token });
      onClose();
      resetForm({
        values: {
          homeAddress: "",
          stateId: "",
          cityId: "",
        },
      });
    },
  });

  return (
    <>
      <Col className="address_form_col col-12">
        {!isModal ? (
          <div className="address__h5_">
            <PageTitle
              title="Add Address"
              margin={false}
            />
          </div>
        ) : null}
        <div className="address_form_form">
          <span className="note__span">*Required fields</span>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-2" controlId="formapt">
              <Form.Label>Address, suite*</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Street name/Building/Apartment no/Floor"
                autoComplete="off"
                name="homeAddress"
                value={formik.values.homeAddress}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isInvalid={!!getIn(formik.errors, "homeAddress")}
              />
              {getIn(formik.errors, "homeAddress") ? (
                <div className="invalid-fbk">{formik.errors.homeAddress}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-2" controlId="formState">
              <Form.Label>State*</Form.Label>
              <Suspense fallback={<Spinner animation="border" size="sm" />}>
                <CustomSelect
                  className="custom-select"
                  placeholder="Select State"
                  isClearable={true}
                  isLoading={isLoading && isFetching}
                  noOptionsMessage={() =>
                    isError ? "Could not load, refresh" : "No State Found"
                  }
                  value={
                    stateOptions
                      ? defaultValue(stateOptions, formik.values.stateId)
                      : ""
                  }
                  options={stateOptions ? stateOptions : []}
                  onBlur={formik.handleBlur}
                  onChanged={(value: any) => {
                    formik.setFieldValue("stateId", value.value);
                    handleStateChange(value.value);
                  }}
                />
                {getIn(formik.errors, "stateId") ? (
                  <div className="invalid-fbk">{formik.errors.stateId}</div>
                ) : null}
              </Suspense>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formCity">
              <Form.Label>City*</Form.Label>
              <Suspense fallback={<Spinner animation="border" size="sm" />}>
                <CustomSelect
                  isDisabled={cityOptions ? false : true}
                  className="custom-select"
                  placeholder="Select City"
                  isClearable={true}
                  isLoading={fetchCity_isLoading && fetchCity_isFetching}
                  noOptionsMessage={() =>
                    fetchCity_isError
                      ? "Could not load, refresh"
                      : "No City Found"
                  }
                  value={
                    cityOptions
                      ? defaultValue(cityOptions, formik.values.cityId)
                      : ""
                  }
                  options={cityOptions ? cityOptions : []}
                  onBlur={formik.handleBlur}
                  onChanged={(value: any) =>
                    formik.setFieldValue("cityId", value.value)
                  }
                />
                {getIn(formik.errors, "cityId") ? (
                  <div className="invalid-fbk">{formik.errors.cityId}</div>
                ) : null}
              </Suspense>
            </Form.Group>
            {/* <Form.Group className="mb-2 mt-3 " controlId="formbasic">
                <Form.Check 
                        disabled
                        type="checkbox" 
                        label="Set as default delivery address" 
                        onBlur={formik.handleBlur}
                        checked={formik.values.defaultAddress}
                  />
            </Form.Group> */}
            <div className="d-flex align-items-center justify-content-end group__buttons pt-2">
              <Button
                as="text"
                className="me-2"
                onClick={onClose}
                type="button"
                name="Cancel"
              />
              <Button
                disabled={!formik.isValid || newaddress_isLoading}
                className="text-white save-button"
                type="submit"
              >
                {newaddress_isLoading || newaddress_isFetching ? (
                  <Spinner size="sm" animation="border" variant="secondary" />
                ) : (
                  <span>Save</span>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </>
  );
}
