import React,{ useState} from "react"
import { Switch } from "@headlessui/react"
import styled from "styled-components";
import classnames from "classnames";

const ToggleButton = (props:any) => {
  return (
    <Container>
        <Switch
            as={SwitchStyle}
            checked={props.enabled}
            onChange={(e) => props.setEnabled(e)}
            disabled={props.disabled}
            className={
                classnames(props.outerClass,`${props.enabled ? props.activeColor ? props.activeColor : "bg-primary" : "bg-dark-color"} ${props.disabled ? "disabled" : ""}`)}
        >
            <span
            aria-hidden="true"
            className={classnames(props.innerClass,`roundbtn ${props.enabled ? props.innerPos ? props.innerPos : "translate-x-9" : "translate-x-0"}
                pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`)}
            />
        </Switch>
    </Container>
  )
}
const Container = styled.div`
     .bg-dark-color {
        background-color: rgba(0,0,0,0.3);
    }
    .disabled{
        background-color:#e7e6e6 !important;
    }
`;

const SwitchStyle = styled.div`
    display: inline-flex; 
    position: relative; 
    transition-property: background-color, border-color, color, fill, stroke; 
    transition-duration: 200ms; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); 
    border-radius: 9999px; 
    border-width: 2px; 
    border-color: transparent; 
    cursor: pointer; 
    height: 38px;
    width: 74px;


   
  
    .roundbtn{
        display: inline-block; 
        background-color: #ffffff; 
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform; 
        transition-duration: 200ms; 
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); 
        border-radius: 9999px; 
        box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color); 
        pointer-events: none; 
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
        margin-top: 0.1rem;
        height: 34px;
        width: 34px;
    }



    .translate-x-9{
        transform: translateX(2.25rem);
    }

    .translate-x-0{
        transform: translateX(0.1rem);
    }

`
export default ToggleButton