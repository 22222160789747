import React, { useCallback, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { FiMinus, FiPlus } from 'react-icons/fi'
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from '../../../redux/store/hook';
import { useFindUserCartItemByIdReturnQuantityQuery, useUpdateCartItemQuantityMutation } from '../../../redux/store/services/cartApi';

interface QuantityCartPageButtonPropsType {
  cartItemId: string | null;
  cartItem: any;
}

const QuantityCartPageButton = ({cartItemId,cartItem}:QuantityCartPageButtonPropsType) => {
  const dispatch = useAppDispatch();
  const [isAdded, setIsAdded] = useState(true)
 const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
 
 const {data:cartquantity_data,
       isLoading:cartquantity_isLoading} = useFindUserCartItemByIdReturnQuantityQuery({token,cartItemId},{
                    skip: !cartItemId || !token
                });
                
  const [updateCartItemQuantity,{isLoading,isSuccess,error,isError}] = useUpdateCartItemQuantityMutation();
  
  const isAddedMsg =  !isAdded ? "Quantity reduced in your cart list" : "Quantity added to your cart list";

  useEffect(() => {
      
    if(isSuccess){
      toast.success(`${isAddedMsg}`,{
        toastId: "quantity-component-added-or-remove-id-toast-success",
      });
    }

    if(isError && error.status == 400){
      toast.error(`${error.data.title}`,{
        toastId: "quantity-400-quantity-id-toast-error",
      });
    }

    
    if(isError && error.status == 'FETCH_ERROR'){
      toast.error(`Cart Error unable to add the quantity to your cart list`,{
        toastId: "quantity-comp-quantity-id-toast-error",
      });
    }

      // if(cartquantity_data) {
      //     setInputValue(parseInt(cartquantity_data.toString()));
      // }
     
  }, [cartquantity_data,isError]);

    const handleReduceQuantity = () => {
      if(cartquantity_data && cartquantity_data > 1 || cartItem && cartItem.quantity > 1){
        if(cartItemId){
           if(ek_isAuth && token){
              updateCartItemQuantity({
                token:token, 
                cartItemId:cartItemId,
                type:"decrease"
              });
              setIsAdded(false)
        }else{
          toast.error("Sign in to reduce quantity !",{
            toastId: "signin-to-reduce-qty-2-id-toast-error",
          });
        }
      }else{
        toast.error('Could not find product',{
          toastId: "-qty-pg-add-cart-list-id-toast-error",
        });

      }
}

}


const handleIncreaseQuantity = useCallback(() => {
  if(cartItemId){
   if(ek_isAuth && token){
          updateCartItemQuantity({
            token:token, 
            cartItemId:cartItemId,
            type:"increase"
          });
          setIsAdded(true)
    }else{
      toast.error("Sign in to increase quantity !",{
        toastId: "signin-to-increase-qty-2-id-toast-error",
      });
    }

  }else{
    toast.error('Could not find product',{
      toastId: "qty-pg-cart-added-list-id-toast-error-id",
    });
  }


},[dispatch,cartItemId])


  return (
    <div className="QuantityBtn__Wrapper">
      <div className={`QuantityBtn__div ${cartquantity_isLoading || isLoading ? 'disabled' : ''}`}>
            <span className={`QuantityBtn__span QuantityBtn__Reduce 
                   ${cartquantity_data && cartquantity_data <= 1 || cartItem && cartItem.quantity <=1  ?
                   'disabled':''}`}
                onClick={handleReduceQuantity} >
            <FiMinus />
          </span>
          <div className="QuantityBtn__DisplayNo_Div">
            <span className="QuantityBtn__Display_No">
              {ek_isAuth && token ? (
                    <>
                      {cartquantity_data && cartquantity_data}
                    </>
              ):(
                <>
                  {cartItem && (<>{cartItem.quantity}</>) }
                </>
              )}

            </span>
          </div>
          <span className="QuantityBtn__span QuantityBtn__Increase"
             onClick={handleIncreaseQuantity}>
           <FiPlus />
         </span>
      </div>
    </div>
  )
}

export default QuantityCartPageButton