import React from 'react';
import styled from 'styled-components';
import statusliveimg from '../../assets/images/TB1live_white.gif'

export default function LiveIconComp() {
  return (
    <Div className="lh-main-status lc-element">
        <div className="lh-status pst true">
            <img className="status-live-icon Live pst" src={statusliveimg} title="Live Commerce ongoing" alt="live" />
            <span className="statusD pst" title="Live Commerce ongoing">LIVE</span>
        </div>
    </Div>
  )
}


 
const Div = styled.div`
    position: absolute;
    z-index: 9;
    display: block;
    top: 8px;
    left: 8px;
    transition: all .4s ease-in-out;

    .lh-status.pst {
        height: 20px;
        max-width: 250px;
        border-radius: 1.3em;
        padding: .71em .4em;
    }

    .lh-status{
        background-color: red; // #fc624d;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-shadow: rgba(0, 0, 0, 0.10) 0px 2px 4px;

        .statusD {
            color: #fff;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .statusD.pst{
            font-size: 11px;
            letter-spacing: .42px;
            max-width: 200px;
            margin-right: 2px;
            font-weight: 500;
            font-family: 'Noto Sans', sans-serif;
        }

        .status-live-icon{
            width: 10px;
            height: 10px;
            margin-top: 0;
            margin-left: 2px;
            margin-right: 4px;
        }
     
    }
`