import React, { Fragment } from 'react'
import { Typography } from '@ekenta/components'
import classnames from 'classnames';
import Slider from 'react-slick';
import SingleLiveBroadcast from '../SingleLiveBroadcast/SingleLiveBroadcast';
import { NextBannerArrow, PrevBannerArrow } from '../Reuseables';

import { useFetchAllLiveSellersOnEkeQuery } from '@ekenta/redux/store/services/livecommerceApi';
import defaultImg from "@ekenta/assets/images/default_livestream.webp";

import "./livecarosuel.css";
import styles from "../../index.module.scss";
import SectionCard from '../SectionCard/SectionCard';

const LiveStreams = () => {

    const getResult = useFetchAllLiveSellersOnEkeQuery();


    const listSettings = {
        dots: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: <PrevBannerArrow />,
        nextArrow: <NextBannerArrow />,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    dots: true
                }
            },
        ]
    }

    return (
        <Fragment>


            {
                getResult.isSuccess ? (
                    <>
                        {getResult.data.content.length > 0 ? (
                            <SectionCard
                                title="LiveStreaming"
                                headerColor="#fff"
                                isError={getResult?.isError}
                                isLoading={getResult?.isLoading}
                                isSuccess={getResult?.isSuccess}
                                margin="10px"
                                render={
                                    <div className={styles.LiveMain__AllLive}>
                                        {/* <Typography
                                    as="h5"
                                    color="dark"
                                    size="medium"
                                    className={styles.AllLive_Title}
                                    heading>
                                    More Livestreams
                                </Typography> */}
                                        <div className={classnames(styles.LiveFeed, "LiveSection")}>
                                            {getResult.data.content.length >= 10 ? (
                                                <Slider  {...listSettings}>
                                                    {getResult.data.content.map((result: any, index: number) => (
                                                        <SingleLiveBroadcast
                                                            key={index}
                                                            id={result.userId}
                                                            type="isEkeLive"
                                                            imageUrl={result.thumbnailUrl ? result.thumbnailUrl : defaultImg}
                                                            title={result.businessName}
                                                        />
                                                    ))}
                                                </Slider>
                                            ) : (
                                                <Fragment>
                                                    {getResult.data.content.map((result: any, index: number) => (
                                                        <SingleLiveBroadcast
                                                            key={index}
                                                            id={result.userId}
                                                            type="isEkeLive"
                                                            imageUrl={result.thumbnailUrl ? result.thumbnailUrl : defaultImg}
                                                            title={result.business_name}
                                                        />
                                                    ))
                                                    }
                                                </Fragment>
                                            )}
                                        </div>
                                        <br />
                                    </div>
                                }
                            />
                        ) : null}
                    </>
                ) : null
            }
        </Fragment>
    )
}

export default LiveStreams