
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { AnalyticsProvider } from "use-analytics";
import { store } from "./redux/store";
import "./scss/global-custom.scss";
import "./scss/global.scss";
import App from "./App";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { TriggerSideOffCanvasProvider } from "./context/TriggerSideOffCanvasContext";
import { TriggerMobileSearchOffCanvasProvider } from "./context/TriggerMobileSearchOffCanvasContext";

import { TriggerHelpCanvasProvider } from "./context/TriggerHelpCanvasContext";
import analyticsInstance from "./hooks/useAnalyticsTracking";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { HelmetProvider } from "react-helmet-async";
import { ScrollTop } from "./components";


const _persistor = persistStore(store);

ReactDOM.render(
  <TriggerSideOffCanvasProvider>
    <TriggerHelpCanvasProvider>
      <TriggerMobileSearchOffCanvasProvider>
        <StrictMode>
          <Router>
            <AnalyticsProvider instance={analyticsInstance}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={_persistor}>
                  <HelmetProvider>
                    <App />
                  </HelmetProvider>
                </PersistGate>
              </Provider>
            </AnalyticsProvider>
          </Router>
        </StrictMode>
      </TriggerMobileSearchOffCanvasProvider>
    </TriggerHelpCanvasProvider>
  </TriggerSideOffCanvasProvider>,
  document.getElementById('root'),
);

reportWebVitals();
serviceWorkerRegistration.register();
