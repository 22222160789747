import React, { memo, useEffect, useState } from 'react';
import { getIn, useFormik } from 'formik';
import { Button, Col, Form, Row, InputGroup, FormControl } from 'react-bootstrap';
import { toast } from "react-toastify";

import { VscArrowSmallRight } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { SellerOnboardFormikFormType } from '../../../../../interfaces/sellerOnboarding.interface';
import { loginPathUrl } from '../../../../../routes/constant';
import NumberFormat from 'react-number-format';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useVerifyAccountDetailCredentialMutation } from '../../../../../redux/store/services/onboardingApi';

// Shape of form values

function AccountDetails({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    nextPage, setFieldValue, prevPage }: SellerOnboardFormikFormType) {

    const [verifyAccountDetailCredential, { isLoading, isError, error, isSuccess }] = useVerifyAccountDetailCredentialMutation();

    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false);

    const [initialValues, setInitialValues] = useState({
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password: '',
        confirmpassword: '',
    })



    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: '',
        onSubmit: values => {

            const objData = {
                username: values.username.toLowerCase(),
                firstName: values.firstname,
                lastName: values.lastname,
                email: values.email.toLowerCase(),
                password: values.password
            }
            verifyAccountDetailCredential(objData);


        }
    });

    useEffect(() => {
        if (values) {
            setInitialValues({
                firstname: values.personal_detail.firstname,
                lastname: values.personal_detail.lastname,
                username: values.personal_detail.username,
                email: values.personal_detail.email,
                password: values.personal_detail.password,
                confirmpassword: values.personal_detail.confirmpassword,

            })
        }
    }, []);




    useEffect(() => {
        if (formik.values) {
            setFieldValue('personal_detail.firstname', formik.values.firstname)
            setFieldValue('personal_detail.lastname', formik.values.lastname)
            setFieldValue('personal_detail.username', formik.values.username)
            setFieldValue('personal_detail.email', formik.values.email)
            setFieldValue('personal_detail.password', formik.values.password)
            setFieldValue('personal_detail.confirmpassword', formik.values.confirmpassword)

        }

    }, [formik.values])


    useEffect(() => {
        if (isSuccess) {
            console.log(isSuccess, 'data')
            nextPage();
        }

        if (isError && error.status === 'FETCH_ERROR') {
            toast.error(`Something went wrong`, {
                toastId: "error-onboarding-status-register-id-toast-error",
            });
        }

        if (isError && error.status === 401) {
            toast.error(`${error.data}`, {
                toastId: "error-401-register-id-toast-error",
            });
        }


        if (isError && error.status === 400) {
            toast.error(`Something went wrong`, {
                toastId: "error-onboarding-400-register-id-toast-error",
            });
        }

    }, [isSuccess, isError, error])





    // { regular register success response check}




    return (

        <div className="seller_account_details_form_col">
            <div className="_top_header mt-1">
                <h5>Personal Account Details</h5>

            </div>

            <Col lg={8} className="mx-auto _col_container">
                <div className=" mt-4">

                    <Form.Group as={Row} className="mb-4  position-relative">
                        <Form.Label className="form-label">
                            First Name <span>*</span>
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control type="text" name="firstname" placeholder=""
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstname}
                            />
                            {
                                getIn(formik.touched, 'firstname') &&
                                    getIn(formik.errors, 'firstname')
                                    ? (
                                        <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                            {formik.errors.firstname}
                                        </Form.Control.Feedback>
                                    ) : null}

                        </Col>

                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 position-relative">
                        <Form.Label className="form-label">
                            Last Name <span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="lastname" placeholder=""
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastname}
                            />
                            {getIn(formik.touched, 'lastname') &&
                                getIn(formik.errors, 'lastname') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {formik.errors.lastname}
                                </Form.Control.Feedback>
                            ) : null}

                        </Col>

                    </Form.Group>
                    <Form.Group as={Row} className="mb-4  position-relative">
                        <Form.Label className="form-label">
                            User Name <span>*</span>
                        </Form.Label>
                        <Col sm={12}>
                            <Form.Control type="text" name="username" placeholder=""
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.username}
                            />
                            {getIn(formik.touched, 'username') &&
                                getIn(formik.errors, 'username') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {formik.errors.username}
                                </Form.Control.Feedback>
                            ) : null}


                        </Col>

                    </Form.Group>

                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="form-label">
                            Email Address <span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="email" name="email"
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {getIn(formik.touched, 'email') &&
                                getIn(formik.errors, 'email') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {formik.errors.email}
                                </Form.Control.Feedback>
                            ) : null}


                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 position-relative">

                        <Form.Label className="form-label">
                            Password <span>*</span>
                        </Form.Label>
                        <InputGroup className="mb-3">
                            <FormControl
                                type={show ? "text" : "password"} name="password"
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                            />
                            <Button id="basic-addon1" onClick={() => setShow(!show)}>{show ? <FaEyeSlash color='white' /> : <FaEye color='white' />} </Button>
                        </InputGroup>
                        {getIn(formik.touched, 'password') &&
                            getIn(formik.errors, 'password') ? (
                            <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                {formik.errors.password}
                            </Form.Control.Feedback>
                        ) : null}

                        <Form.Text className=" text-muted">
                            At least 6 characters containing a capital letter, and a numeric character
                        </Form.Text>

                        {/* <Col sm="12">
                    <Form.Control  type="password" name="personal_detail.password" 
                    autoComplete='off'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.personal_detail.password}
                    isInvalid={!!getIn(errors,'personal_detail.password')}
                    />
                    {getIn(touched,'personal_detail.password') &&
                        getIn(errors, 'personal_detail.password')  ? (
                        <Form.Control.Feedback type="invalid" className="invalid-fbk">
                            {errors.personal_detail?.password}
                        </Form.Control.Feedback>
                    ): null}
                    <Form.Text className=" text-muted">
                    At least 6 characters containing a capital letter, and a numeric character
                    </Form.Text>

                </Col> */}
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="form-label">
                            Confirm Password <span>*</span>
                        </Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control type={show2 ? "text" : "password"} name="confirmpassword"
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmpassword}
                            />

                            <Button id="basic-addon1" onClick={() => setShow2(!show2)}>{show2 ? <FaEyeSlash color='white' /> : <FaEye color='white' />} </Button>
                        </InputGroup>

                        {getIn(formik.touched, 'confirmpassword') &&
                            getIn(formik.errors, 'confirmpassword') ? (
                            <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                {formik.errors.confirmpassword}
                            </Form.Control.Feedback>
                        ) : null}

                    </Form.Group>


                    <Form.Group as={Row} className="mb-4 position-relative">
                        <Form.Label className="form-label">
                            Phone Number <span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <NumberFormat
                                customInput={Form.Control}
                                thousandSeparator={false}
                                prefix=""
                                name="personal_detail.phonenumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="tel"
                                value={values.personal_detail.phonenumber}
                            />
                            {getIn(touched, 'personal_detail.phonenumber') &&
                                getIn(errors, 'personal_detail.phonenumber') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.personal_detail?.phonenumber}
                                </Form.Control.Feedback>
                            ) : null}

                        </Col>

                    </Form.Group>
                    <Form.Group as={Row} className="mb-1 position-relative">
                        <Form.Label className="form-label">
                            Referred by
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="personal_detail.referedby"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.personal_detail.referedby}
                            />
                            {getIn(touched, 'personal_detail.referedby') &&
                                getIn(errors, 'personal_detail.referedby') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.personal_detail?.referedby}
                                </Form.Control.Feedback>
                            ) : null}

                        </Col>
                    </Form.Group>
                    <div className="_stepper_trigger_col">
                        <Button disabled={
                            !formik.values.firstname ||
                            !formik.values.lastname ||
                            !formik.values.username ||
                            !formik.values.email ||
                            !formik.values.password ||
                            !formik.values.confirmpassword ||
                            !values.personal_detail.phonenumber ||
                            getIn(formik.errors, 'firstname') ||
                            getIn(formik.errors, 'lastname') ||
                            getIn(formik.errors, 'username') ||
                            getIn(formik.errors, 'email') ||
                            getIn(formik.errors, 'password') ||
                            getIn(formik.errors, 'confirmpassword') ||
                            getIn(errors, 'personal_detail.phonenumber')
                        }

                            className="_button_A_" onClick={() => formik.handleSubmit()}>
                            {isLoading ? <>Loading...</> :
                                <>
                                    <span>Proceed</span>
                                    <VscArrowSmallRight />
                                </>
                            }
                        </Button>
                        {/* <a onClick={nextPage} className="_A_">
                  <span>Proceed</span>
                  <FiChevronsRight />
                </a> */}
                    </div>
                    <div className="highlight">Already have an account?
                        <Link to={loginPathUrl}> Sign in here! </Link>
                    </div>

                </div>
            </Col>
        </div>
    )
}


export default memo(AccountDetails);