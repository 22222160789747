import React, { ReactElement } from 'react'
import styled from 'styled-components';

interface RoundedButtonPropType{
  text:string;
  icon: ReactElement<any, any>;
  handler:() => void;
}

const RoundedButton:React.FC<RoundedButtonPropType> = (props) => {
  return (
    <Wrapper>
      <Button onClick={props.handler}>
         {props.icon}
         <span>{props.text}</span>
      </Button>
    </Wrapper>
  )
}

export default RoundedButton;


const Wrapper = styled.div`
  margin-right: 0.8rem ;
`;

const Button = styled.button`
  background-color: #fff;
  border-radius: 100px;
  //box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset,rgba(44, 187, 99, .15) 0 1px 2px,rgba(44, 187, 99, .15) 0 2px 4px,rgba(44, 187, 99, .15) 0 4px 8px,rgba(44, 187, 99, .15) 0 8px 16px,rgba(44, 187, 99, .15) 0 16px 32px;
  box-shadow: #adcfffb3 0 1px 2px, #3435364b 0 1px 2px;
  color: #232F3E;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Open Sans", "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 13.5px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  svg{
    font-size: 16px !important;
    margin-top: 0.1rem;
    margin-right: 0.3rem;
  }
  
  &:hover {
    box-shadow: #E04826 0 -2px 8px inset;
    transform: scale(1.05);
  }
`;
