import Analytics from 'analytics'
//import googleAnalyticsPlugin from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import googleAnalytics from '@analytics/google-analytics'
import { GOOGLE_ANALYTICS_TRACKINGID } from '../constants'


const analyticsInstance = Analytics({
  app: "ekenta",
  plugins: [
    googleAnalytics({
      measurementIds: GOOGLE_ANALYTICS_TRACKINGID ? GOOGLE_ANALYTICS_TRACKINGID : []
    }),
  ]
})

export default analyticsInstance;
