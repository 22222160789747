import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios from 'axios'
import type { AxiosRequestConfig, AxiosError } from 'axios'

import { BASE_URL } from '../../../constants/index';

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers: AxiosRequestConfig['headers']
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, params, headers }) => {
      try {
        const result = await axios({ url: baseUrl + url, method, data, params, headers })
        return { data: result.data }
      } catch (axiosError) {
        const err = axiosError as AxiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

const checkoutApiHeaders = (token: string) => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
});


const createMutationRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
  url: url,
  headers: checkoutApiHeaders(token),
  method: method
});

const createMutationRequestWithBody = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
  url: url,
  headers: checkoutApiHeaders(token),
  method: method,
  data: body
});



const createQueryRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
  url: url,
  responseHandler: (response: any) => response.text(),
  headers: {
    'Authorization': `Bearer ${token}`,
    'content-type': 'text/plain',
  },
  method: method
});


const createQueryJSONRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
  url: url,
  headers: checkoutApiHeaders(token),
  method: method
});



export const checkoutApi: any = createApi({
  reducerPath: 'checkoutApi',
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL ? BASE_URL : ''
  }),
  endpoints: (builder) => ({
    initiateTransaction: builder.mutation<void, { orderId: string; reference: string; token: string; }>({
      query: ({ orderId, reference, token }) => createMutationRequest({ url: `/api/initiate-transaction/${orderId}?reference=${reference}`, method: 'POST', token: token })
    }),
    flutterInitiateTransaction: builder.mutation<void, { orderId: string; transactionId: string; token: string; reference: string; }>({
      query: ({ orderId, transactionId, reference, token }) => createMutationRequest({ url: `/api/flutterwave-initiate/${orderId}/${transactionId}?reference=${reference}`, method: 'POST', token: token })
    }),
    flutterPaymentTransaction: builder.query<string, { transactionId: string; token: string }>({
      query: ({ transactionId, token }) => createQueryRequest({ url: `/api/verify-transaction/flutterwave/${transactionId}`, method: 'GET', token: token }),
    }),
    flutterNotificationTransaction: builder.query<string, { transId: string; token: string }>({
      query: ({ transId, token }) => createQueryRequest({ url: `/api/handles-successful-notification/${transId}`, method: 'GET', token: token }),
    }),
    verifyPaymentTransaction: builder.query<string, { verifyRef: string; token: string }>({
      query: ({ verifyRef, token }) => createQueryRequest({ url: `/api/verify-transaction?reference=${verifyRef}`, method: 'GET', token: token }),
    }),
    fetchGIGLogisticPrice: builder.mutation<void, { data: any; token: string; }>({
      query: ({ data, token }) => createMutationRequestWithBody({ url: `/api/logistics/gig/get-price`, method: 'POST', body: data, token: token }),
    }),
    fetchAllLogistics: builder.query({
      query: (token) => createQueryJSONRequest({ url: `/api/logistics/list`, method: 'GET', token: token }),
    }),
  }),
});


export const {
  useFetchGIGLogisticPriceMutation,
  useInitiateTransactionMutation,
  useVerifyPaymentTransactionQuery,
  useFetchAllLogisticsQuery,
  useFlutterInitiateTransactionMutation,
  useFlutterPaymentTransactionQuery,
  useFlutterNotificationTransactionQuery
} = checkoutApi;
