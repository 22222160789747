import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Tab, Tabs, Stack, Form } from 'react-bootstrap'
import TableComponent from '../../../components/Dashboard/components/TableComponent'
import withDashboardLayout from '../../../hoc/Layout/withDashboardLayout'
import { useAppSelector } from '../../../redux/store/hook'
import { useFetchAllOrdersQuery, useFetchAdmniAllOrdersQuery } from '../../../redux/store/services/vendorProductApi'
import EmptyOrder from '../../../components/Box/EmptyOrder'
import { useAddNewSliderImageMutation, useFetchAccountingSalesAdminQuery, useFetchDailyTotalOrderQuery } from '../../../redux/store/services/adminSellerApi'
import { FiBarChart, FiBarChart2, FiChevronLeft, FiGift, FiToggleRight, FiUser, FiUserCheck } from 'react-icons/fi';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa'
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router'
import { adminManageBanner } from '../../../routes/constant'
import sent from '../../../assets/images/sent.gif'
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader'

const AddBanner = () => {
    const [lgShow, setLgShow] = useState(false);
    const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [addSliderImage, { data: addSlider_data, isLoading: addSlider_isLoading, isError: addSlider_isError, error: addSlider_error,
        isSuccess: addSlider_isSuccess }] = useAddNewSliderImageMutation();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [details, setDetails] = useState({
        slideTitle: '',
        slideNumber: '',
        file: ''
    })
    // const formData = new FormData()
    const handleImage = (e: any) => {
        // console.log(e.target.files[0])
        setDetails({ ...details, file: e.target.files[0] })

        // console.log(formData)
    }

    const handleSubmit = () => {
        setLoading(true)
        console.log(details)
        const formData = new FormData()
        formData.append('file', details.file)
        formData.append('slideNumber', details.slideNumber)
        formData.append('slideTitle', details.slideTitle)
        // let data = details
        addSliderImage({ data: formData, other: details })
        if (addSlider_data) {
            setLoading(false)
            // navigate(adminManageBanner)
        }
    }

    useEffect(() => {
        if (addSlider_data) {
            handleShow()
        }

        // navigate(adminManageBanner)
    }, [addSlider_data])


    return (
        <>

            <HelmetHeader
                title="Add Banner"
                description="Ekenta Add Banner Dashboard Page"
            />

            <div className="mt-0">
                <hr />
                <h3 className="display_lg_header">
                    {/* <FiChevronLeft /> */}
                    Add Slider Image
                </h3>
                <hr />

                <Col className="mt-3">

                    <Card>

                        <Card.Body>

                            <Form.Label htmlFor="inputPassword5">Slider Title * </Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e) => setDetails({ ...details, slideTitle: e.target.value })}

                            />
                            <br />
                            <Form.Label htmlFor="inputPassword5">Slider Number * </Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e) => setDetails({ ...details, slideNumber: e.target.value })}

                            />
                            <br />
                            <Form.Label htmlFor="inputPassword5">Slider Image *</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => handleImage(e)}

                            />
                            <br />
                            <Form.Label htmlFor="inputPassword5">Status</Form.Label>
                            <Form.Select>
                                <option>Active</option>
                                <option>Inactive</option>
                            </Form.Select>
                            <br />

                            <Button variant="danger" disabled={loading ? true : false} onClick={handleSubmit}>
                                {loading ? 'Loading...' : 'Submit'}
                            </Button>{' '}
                            <Button variant="outline-danger">Cancel</Button>




                        </Card.Body>
                    </Card>

                </Col>
            </div>
            <Modal
                show={show} onHide={handleClose}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body>
                    <Row>
                        <img src={sent} alt="" height={300} width={'100%'} />
                        <Button variant="success" className='text-white text-center mx-auto' onClick={() => navigate(adminManageBanner)}>
                            Done
                        </Button>
                    </Row>



                </Modal.Body>

            </Modal>

        </>
    )
}


export default withDashboardLayout(AddBanner)

