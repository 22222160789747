import styled from "styled-components";
import React from "react";

interface ContainerType {
  bgColor?: string;
}

interface OverlayType {
  children?: React.ReactNode;
  bgColor?: "light" | "dark";
  onClick?: () => void;
}

function Overlay(props: OverlayType) {
  return <Container onClick={props.onClick} bgColor={props.bgColor}>{props.children}</Container>;
}

const Container = styled.div<ContainerType>`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => `${props.bgColor === "dark"
    ? "rgba(0, 0, 0, 0.5)"
    : props.bgColor === "light"
      ? "rgba(248, 248, 255, 0.5)"
      : props.bgColor
    }`};
  z-index: 2;
`;

export default Overlay;
