import React, { useCallback, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
//import Scrollbars from "react-custom-scrollbars";

//import "./SearchBox.css";
//import Tippy from "@tippyjs/react";
import { SearchBoxPropsType } from "@ekenta/interfaces";
import SearchSvg from "@ekenta/assets/icons/search.svg";

import Field from "../InputField/Field/Field";
import styles from "./SearchBox.module.scss";
import Loader from "../Loader/Loader";

import classnames from "classnames";
import { slugifyText } from "@ekenta/utils";
import { productBaseUrl } from "@ekenta/routes/constant";
import { SvgArrowUpLeft } from "../icons";



const SearchBox = ({
  renderPrefix,
  renderSuffix,
  value,
  autoCompleteData,
  name,
  isError,
  isLoading,
  onChange,
  showAutocomplete,
  setShowAutocomplete,
  onKeyUp,
  category,
  setValue
}: SearchBoxPropsType) => {

  const ref: React.RefObject<HTMLInputElement> = React.createRef();
  const navigate = useNavigate();
  const [focus, setFocus] = useState(false);



  const enterHandler = (e: any) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      //setValue("");
      setShowAutocomplete(false);
    }
  };

  const handleNavigation = (category: any, name: string, slug: string, id: string) => {
    if (category !== "products") {
      const urlSlug = '/search-result?q=' + name + '&category=' + category + '&id=' + id;
      window.location.href = urlSlug;
    } else {
      window.location.href = `${productBaseUrl}/${slug}`
    }
  }

  const renderProducts = (data: any) => {
    return data ? data.length > 0 ? data.map((item: any, index: number) => {
      const name = item.name.toLowerCase();
      const _slugProduct = item?.id ? slugifyText(item?.name.toLowerCase(), item?.id) : '/';

      return (
        <SingleItem
          key={"searchId" + item.id}
          imageUrls={item.imageUrls}
          name={name}
          setValue={setValue}
          setShowAutocomplete={setShowAutocomplete}
          onClick={() => handleNavigation(category, name, _slugProduct, item?.id)}
        />
      )
    })
      : <li className={styles.SearchAutocomplete_noResult}>no results</li>
      : null
  }

  const renderSellers = (_data: any) => {
    console.log(_data)
    return _data ? _data.length > 0 ? _data.map((item: any, index: number) => {
      const name = item.businessName.toLowerCase();
      return (
        <SingleItem
          key={item.id}
          imageUrls={null}
          name={name}
          setValue={setValue}
          setShowAutocomplete={setShowAutocomplete}
          onClick={() => handleNavigation(category, name, "", item?.id)}
        />
      )
    })
      : <li className={styles.SearchAutocomplete_noResult}>no results</li>
      : null
  };

  const autoCompleteContainer = (data: any) => {
    return (
      <div className={styles.SearchAutocomplete}>
        {/* <Scrollbars style={{ width: "100%", height:"350px" }}> */}
        <div className={styles.SearchAutocomplete__fieldContainer}>
          <ul className={styles.SearchAutocomplete__List}>
            {category === "products" ? (
              <li className={styles.SearchAutocomplete__List__val}>
                <div className={classnames("mt-0", styles.SearchAutocomplete_Group)}>
                  <h5>Suggestions</h5>
                </div>
                <a className={styles.SearchAutocomplete__List__href}
                  onClick={() => {
                    setShowAutocomplete(false);
                    window.location.href = '/search-result?q=' + value + '&category=' + category;
                  }}>
                  <span className={styles.SearchAutocomplete__List__icon}>
                    <SVG src={SearchSvg} />
                  </span>
                  <span className={styles.SearchAutocomplete__List__value}>
                    {value}
                  </span>
                </a>
              </li>
            ) : null}

            <div className={classnames(styles.SearchAutocomplete_Group, {
              [styles.SearchAutoComplete_Margin]: category === "products"
            })}>
              <h5>{category}</h5>
            </div>
            <li className={styles.SearchAutocomplete_Content_li}>
              <ul className={styles.SearchAutocomplete_Content_Sublist}>
                {isError ? <li className={styles.SearchAutocomplete__Loading}><Loader /></li> : null}
                {isLoading ? <li className={styles.SearchAutocomplete__Loading}><Loader /></li> : null}

                {category === "products" ? renderProducts(data) : renderSellers(data)}

                {/* {data ? data.length > 0 ? data.map((item: any, index: number) => {
                  const name = item.name.toLowerCase();
                  const _slugProduct = item?.id ? slugifyText(item?.name.toLowerCase(), item?.id) : '/';
                  return (
                    <SingleItem
                      key={"searchid_" + item.id}
                      imageUrls={item.imageUrls}
                      name={name}
                      setValue={setValue}
                      setShowAutocomplete={setShowAutocomplete}
                      handleNavigate={() => navigate(`${productBaseUrl}/${_slugProduct}`)}
                    />
                  )
                  return (
                    <li key={"searchid_" + item.id}
                      className={styles.SearchAutocomplete__List__item}>
                      <a className={styles.SearchAutocomplete__item_href}
                        onClick={() => {
                          setValue("search", "");
                          setShowAutocomplete(false);
                          navigate(`${productBaseUrl}/${_slugProduct}`)
                        }
                        }>

                        {item.imageUrls ? <img className={styles.SearchAutocomplete__Img} src={item.imageUrls[0]} /> : null}
                        <span className={styles.SearchAutocomplete__item_href__Span}>{name}</span>
                        <SvgArrowUpLeft className={styles.SearchAutocomplete__item_href__Svg} />
                      </a>
                    </li>
                  )
                })
                  : <li className={styles.SearchAutocomplete_noResult}>no results</li>
                  : null} */}
              </ul>
            </li>

          </ul>
        </div>
        {/* </Scrollbars>
          */}
      </div>
    )
  }

  // const EmptyQuery = () => {
  //   return (
  //     <div className={styles.EmptyQuery__Wrapper}>
  //       <div className={styles.EmptyQuery__Div}>
  //         <div className={styles.EmptyQuery__Div__Group}>
  //           <div className={styles.EmptyQuery__Div__Group_Img}>
  //             <img className="" src={searchQuery} alt="" height="120" />
  //           </div>
  //           <div className={styles.EmptyQuery__Content}>
  //               <h5>Looking for something?</h5>
  //               <p>
  //               Type a keyword and we’ll search through products, collections, brands and features, 
  //               </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className={styles.MainSearch__Container}>
      <div className={styles.Searchautocomplete__Wrapper}>
        {renderPrefix ? (
          <div className={styles.SearchBox__Prefix}>
            {renderPrefix}
          </div>
        ) : null}
        <div className={styles.Searchautocomplete__inputwrap}>
          {/* <Tippy
              
              arrow={false}
              visible={!value && focus ? true : value ? true : false}
              interactive={true}
              onClickOutside={() => setFocus(false)}
              content={autoCompleteContainer(autoCompleteData)}
              placement={"bottom"}
              theme="light"
              animation="shift-toward"
              className={styles.tippy_custom_styles}
          > */}
          <div ref={ref} className={styles.SearchBox__FieldDiv}>
            <Field
              id="search-field"
              name={name}
              type="search"
              value={value}
              fieldClassName={styles.SearchBox__FieldWrap}
              placeholder={`Search for ${category}..`}
              autoComplete="off"
              onChange={onChange}
              onKeyUp={(e) => {
                onKeyUp(e);
                enterHandler(e);
              }}
              onFocus={() => setFocus(!value ? true : false)}

            />
            {showAutocomplete ? (
              <div className={classnames(styles.SearchAutocomplete__Div, ".shadow-lg")}>
                {autoCompleteContainer(autoCompleteData)}
                {/* {!isLoading ? (
                  <div className={styles.SearchAutocomplete__fieldBottom}
                    onClick={() => {
                      setShowAutocomplete(false);
                      navigate('/search-result?q=' + value)
                    }}>
                    <h5>See All Search Results</h5>
                  </div>
                ) : null} */}
              </div>
            ) : null}

          </div>
          {/* </Tippy> */}
        </div>
      </div>
      {renderSuffix ? (
        <div className={styles.SearchBox__Right}>
          {renderSuffix}
        </div>
      ) : null}
    </div>
  )
}


const SingleItem = (props: {
  imageUrls: string[] | null;
  name: string;
  setValue: any;
  setShowAutocomplete: any;
  onClick: () => void;
}) => {
  return (
    <li
      className={styles.SearchAutocomplete__List__item}>
      <a className={styles.SearchAutocomplete__item_href}
        onClick={() => {
          props.setValue("search", "");
          props.setShowAutocomplete(false);
          props.onClick();
        }
        }>

        {props.imageUrls ? <img className={styles.SearchAutocomplete__Img} src={props.imageUrls[0]} /> : null}
        <span className={styles.SearchAutocomplete__item_href__Span}>{props.name}</span>
        <SvgArrowUpLeft className={styles.SearchAutocomplete__item_href__Svg} />
      </a>
    </li>
  )
}

export default SearchBox;