import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query'; 

import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { unauthenticatedMiddleware } from '../middlewares/error-handling.middleware';
import { accountApi } from './services/accountApi';
import { authenticationApi } from './services/authenticationApi';
import { cartApi } from './services/cartApi';
import { collectionApi } from './services/collectionApi';
import { onboardingApi } from './services/onboardingApi';
import {authReducer, cartsReducer} from './states';
import { REDUX_PERSIST_SECRET } from '../../constants';
import { vendorProductApi } from './services/vendorProductApi';
import { productsApi } from './services/productsApi';
import { checkoutApi } from './services/checkoutApi';
import { adminSellerApi } from './services/adminSellerApi';
import { liveCommerceApi } from './services/livecommerceApi';
import { homeApi } from './services/homeApi';
import { shopApi } from './services/shopApi';


const encryptor = encryptTransform({
  secretKey: REDUX_PERSIST_SECRET ? REDUX_PERSIST_SECRET : '',
  onError: function (error) {
    //console.log(error);
  },
});

const persistAuthConfig = {
          key: 'auth_ekenta',
          version: 1,
          storage,
          transforms: [encryptor],
}
const persistCartConfig = {
         key: 'cart_ekenta',
         version: 1,
         storage,
         transforms: [encryptor],
}

const authpersistedReducer = persistReducer(persistAuthConfig, authReducer);
const cartpersistedReducer = persistReducer(persistCartConfig, cartsReducer);

export const store = configureStore({
    reducer:{
        [authenticationApi.reducerPath]: authenticationApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [cartApi.reducerPath]: cartApi.reducer,
        [collectionApi.reducerPath]: collectionApi.reducer,
        [onboardingApi.reducerPath]: onboardingApi.reducer,
        [vendorProductApi.reducerPath]: vendorProductApi.reducer,
        [adminSellerApi.reducerPath]: adminSellerApi.reducer,

        [productsApi.reducerPath]: productsApi.reducer,
        [checkoutApi.reducerPath]: checkoutApi.reducer,
        [liveCommerceApi.reducerPath]: liveCommerceApi.reducer,
        [homeApi.reducerPath]: homeApi.reducer,
        [shopApi.reducerPath]: shopApi.reducer,
        auth:authpersistedReducer,
        cart:cartpersistedReducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([
             unauthenticatedMiddleware,
             authenticationApi.middleware,
             collectionApi.middleware,
             onboardingApi.middleware,
             accountApi.middleware,
             vendorProductApi.middleware,
             adminSellerApi.middleware,
             cartApi.middleware,
             productsApi.middleware,
             checkoutApi.middleware,
             liveCommerceApi.middleware,
             homeApi.middleware,
             shopApi.middleware
             
         ]),
  })


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);