import React, { useState } from 'react';
import { useLocation } from 'react-router';
import AppContent from '../../../components/AppContent';
import ErrorBoundaries from '../../../components/ErrorBoundary/ErrorBoundaries';
import { DashboardSellerLiveCommercePathUrl } from '../../../routes/constant';
import { useMatchPath } from '../../../utils';
import { DashboardCustomMainBar } from './DashboardCustomMainBar';
import { DashboardCustomSideBar } from './DashboardCustomSideBar';

interface DashboardComponentPropsType {
  scrollerRef?: any;
  full?: boolean;
  noStyle?: boolean;
  isPad?: boolean;
  className?: string;
  children: any;
  collapsed?: boolean;
  toggled?: boolean;
  handleToggleSidebar?: () => any;
  handleCollapsedChange?: () => any;
}

const DashboardComponent: React.FC<DashboardComponentPropsType> = (props) => {
  const [visibility, setVisibility] = useState(false);

  const { pathname } = useLocation();

  return (
      <div className="dashboard-main-app">
         {pathname !==
         useMatchPath(DashboardSellerLiveCommercePathUrl, pathname) ? (
         <DashboardCustomSideBar
            visibilty={visibility}
            setvisibilty={setVisibility}
         />
         ) : null}
                <DashboardCustomMainBar
                visibilty={visibility}
                setvisibilty={setVisibility}
                ref={props.scrollerRef}
                >
               <ErrorBoundaries externalError={false}>
                  <AppContent 
                   full={props.full} 
                   className={props.className}
                   noStyle={props.noStyle}
                   isPad={props.isPad}>
                    {props.children}
                </AppContent>
              </ErrorBoundaries>
                </DashboardCustomMainBar>
      </div>
  );
};

export default DashboardComponent;
