import React from "react";
import classnames from "classnames";
import { FiCheck } from "react-icons/fi";

interface HeaderPropType {
  title: string;
  active: boolean;
  isBorder: boolean;
  renderAction?: () => void;
}

function Header({
  title, active, isBorder, renderAction,
}:HeaderPropType) {
  return (
    <div className={classnames("_heading_d", isBorder ? "borderBottom" : null)}>
      <div className="d-flex align-items-start">
        <span className={`check__icon ${active ? "active_check" : "inactive_check"} `}>
          <FiCheck fontSize={15} />
        </span>
        <h5>{title}</h5>
      </div>
      {renderAction ? renderAction() : null}
    </div>
  );
}

export default Header;
