import React,{useState} from 'react';
import {ToastContainer,Toast} from 'react-bootstrap';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';

interface CustomToastProps {
    color: string;
    show: boolean;
    type: string;
    message: string;
}

export default function CustomToast(props:CustomToastProps) {
    const [show, setShow] = useState(props.show);

    return (
        <ToastContainer className="p-3 custom_toast" position='bottom-end'>
            <Toast className="d-inline-block m-1" bg={props.color}  
            onClose={() => setShow(false)} show={show} delay={3000} autohide>
                <Toast.Body className="text-white d-flex align-items-center ">
                   {props.type === 'error' ? <FiAlertCircle className="me-2" /> : props.type === 'success' ?
                   <FiCheckCircle className="me-2 " /> : null  }
                    {props.message}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
