import React from 'react';
import { Accordion, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FiInbox, FiLayers } from 'react-icons/fi';
import { VscCircuitBoard } from 'react-icons/vsc';
import DashboardComponent from '../component/DashboardComponent';
import GetStarted_Timeline from '../component/Timeline';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import DashboardTour from '../component/DashboardTour';
import { Link } from 'react-router-dom';
import { DashboardLowStockPathUrl, DashboardOrdersPathUrl } from '../../../routes/constant';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import { useFetchAllOrdersQuery, useFetchPendingOrdersQuery } from '../../../redux/store/services/vendorProductApi';
import { useAppSelector } from '../../../redux/store/hook';
import EmptyOrder from '../../../components/Box/EmptyOrder';


export default function DashboardHome() {

  const columns = [
    {
      Header: 'Order Status',
      accessor: 'order_status',
      sortType: 'basic',
    },

    {
      Header: 'Today',
      accessor: 'today',
      sortType: 'basic',
    },

    {
      Header: 'This week',
      accessor: 'this_week',
      sortType: 'basic',
    },
    {
      Header: 'This month',
      accessor: 'this_month',
      sortType: 'basic',
    },
    {
      Header: 'This year',
      accessor: 'this_year',
      sortType: 'basic',
    },
    {
      Header: 'All time',
      accessor: 'all_time',
      sortType: 'basic',
    },
  ]

  const { token } = useAppSelector(state => state.auth.authentication);
  const result = useFetchAllOrdersQuery(token)
  const pending = useFetchPendingOrdersQuery(token)

  return (
    <DashboardLayout>
      <Helmet>
        <title>Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Dashboard Page" />
        <meta name="description"
          content="Ekenta Dashboard  Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <DashboardTour />
      <DashboardComponent>
        <Col className="mt-1 px-2">
          <div>
            <h4 className=" mb-0 display_lg_header">Dashboard</h4>
          </div>
          <div className="mt-2">
            <Col className="mb-2">
              <div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div>
                        <FiLayers className="icon_variant_color" />
                        <span className="ms-2">Get started</span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="collapse show">
                      <div className="py-3 _getstarted_sellerdrop_">
                        <h5>Start accepting orders seamlessly</h5>
                        <p>Let us show you how to setup your store fast and easy. With the tips below you will be ready immediately to start selling
                          after you go through these steps. Good luck.</p>

                        <div className="pt-2">
                          <GetStarted_Timeline />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col className="mb-2">
              <div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <FiInbox className="icon_variant_color" />
                      <span className="ms-2">Ekenta Commerce News</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Ekenta commerce is all about bringing products closer to customers as fast as possible at the best possible price.
                      The Livestream feature has been designed to help you sell your products in real-time to customers.
                      The Live stream market has been projected to be worth $35 billion by 2024 and now is the best time to maximize it.
                      <br /> More to come soon
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col className="mb-2">
              <div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <VscCircuitBoard className="icon_variant_color" />
                      <span className="ms-2">Total Orders</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                        !result.isSuccess ? 'Loading' : result.data.content.length > 0 ? <TableComponent columns={columns} data={result.data.content} /> : <EmptyOrder orderType='Total Orders' />
                      }

                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col className="mb-2">
              <div>
                <Accordion >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <VscCircuitBoard className="icon_variant_color" />
                      <span className="ms-2">Incomplete Orders</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {
                        !pending.isSuccess ? 'Loading' : <EmptyOrder orderType='Pending' />
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>


            <Col className="mb-2">
              <div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as={Link} to={DashboardOrdersPathUrl}
                      className="_order_custom" style={{ textDecoration: "none" }}>
                      <div>
                        <VscCircuitBoard className="icon_variant_color" />
                        <span className="ms-2">Orders</span>
                      </div>
                      {/* <div>
                                 <ul className="d-flex m-0 p-0 align-items-center"> 
                                   <li className="list-group-item border-0 ">
                                     <span className='text-primary'>Week</span>
                                   </li>
                                   <li  className="list-group-item  border-0">
                                     <span>Month</span>
                                   </li>
                                   <li   className="list-group-item  border-0">
                                     <span>Year</span>
                                   </li>
                                 </ul>
                               </div> */}
                    </Accordion.Header>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col className="mb-2">
              <div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as={Link} to={DashboardLowStockPathUrl} style={{ textDecoration: "none" }}>
                      <VscCircuitBoard className="icon_variant_color" />
                      <span className="ms-2">Low Stock</span>
                    </Accordion.Header>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  )
}
