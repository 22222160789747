import React from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useRowSelect,
  useSortBy,
} from "react-table";
import classnames from "classnames";
import styles from "./DataTable.module.scss";
//import { DataTablePropType } from '../../interfaces'
import Table from "../Table/Table";
import Loader from "../Loader/Loader";
import { Card } from "react-bootstrap";
import Field from "../InputField/Field/Field";
import { FiSearch } from "react-icons/fi";
import Button from "../Button/Button";

// eslint-disable-next-line react/display-name

const DataTable = ({
  theme,
  data,
  columns,
  loading,
  isSelectable = true,
  className,
  cardClassName,
  thClassName,
  trClassName,
  tdClassName,
  renderEndTable,
  renderPagnation,
  renderBottom,
  renderTop,
  hasLink,
  align,
  showSearch,
  searchValue,
  onChange,
  onKeyUp
}: any) => {

  const tableHeadStyle = classnames({
    [styles.TheadWith__Bg]: showSearch
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const tableOutput = (
    <Card className={classnames(cardClassName, styles.cardTable, "boxShadow")}>
      <Card.Body>

        {showSearch ? (
          <div className={classnames(styles.SearchWrapper__Box)}>
            <div className={classnames(styles.renderSearchWrapper__Box)}>
              <label className={styles.renderSearchWrapper__Label}></label>
              <Field
                id="search-field"
                name="search"
                value={searchValue}
                type="search"
                placeholder="Search"
                fieldClassName={styles.renderSearchWrapper__Field}
                onChange={onChange}
                onKeyUp={onKeyUp}
                autoComplete="off"
                renderPrefix={<FiSearch />}
              />
              {/* {showSearchButton ? (
                <Button
                  type="button"
                  width=""
                  name="search"
                  className={styles.renderSearchButton}
                  shadow={false}>
                  <FiSearch />
                </Button>
              ) : null} */}
            </div>
          </div>
        ) : null}
        {renderTop && (
          <div className={styles.renderTop__Horizontal}>
            {renderTop}
          </div>
        )}
        <Table
          simple={theme === "simple"}
          className={className}
          {...getTableProps()}
        >
          <Table.Head className={tableHeadStyle}>
            {headerGroups.map((headerGroup, index) => (
              <Table.Row {...headerGroup.getHeaderGroupProps()} key={'id_' + index}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Table.Cell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      component="th"
                      className={classnames(thClassName, {
                        [styles.selection]: index === 0,
                        [styles.sortable]: column.canSort,
                      })}
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <span
                          className={classnames(styles.sort, {
                            [styles.sortDesc]: column.isSortedDesc,
                            [styles.sortAsc]: !column.isSortedDesc,
                          })}
                        ></span>
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body {...getTableBodyProps()}>
            {loading ? (
              <Table.Row>
                <Table.Cell colSpan="100%">
                  <div className={styles.loader}>
                    <Loader withWrapper />
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : data.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan="100%">
                  <div className={styles.noResults}>No results to display.</div>
                </Table.Cell>
              </Table.Row>
            ) : (
              <>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Table.Row  {...row.getRowProps()} className={trClassName} hasLink={hasLink}>
                      {row.cells.map(cell => {
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <Table.Cell
                            {...cell.getCellProps()}
                            className={tdClassName}
                            align={align}
                          >
                            {cell.render("Cell")}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </>
            )}
          </Table.Body>
        </Table>

        {renderBottom && renderBottom}
        {renderPagnation && renderPagnation}
      </Card.Body>
    </Card>
  );


  return (
    <>
      {tableOutput}
      {renderEndTable && renderEndTable()}
    </>
  )
}

export default DataTable;