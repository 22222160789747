import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import AllRoutes from './routes';
import ErrorBoundaries from './components/ErrorBoundary/ErrorBoundaries';
import { ScrollTop } from './components';

const App = () => {
  const location = useLocation();

  return (
    <Fragment>
      {/* <ErrorBoundaries
        externalError={true}> */}
      {/* <ScrollTop pathname={location.pathname}> */}
      <AllRoutes />
      {/* </ScrollTop> */}
      {/* </ErrorBoundaries> */}

      <ToastContainer
        theme="dark"
        position="bottom-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>

  )
}

export default App;
