import React from 'react'
import { Col,Card } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export const ProductLoader = ({layoutView}:{layoutView:string}) => {
  return (
    <Col xs={12} sm={4} md={4} lg={`${layoutView === 'grid' ? '3': '12'}`} 
        className='product__card__col' style={{height:"100%"}}> 
        <Card>
          <Col className={`_card_image-wrapper p-0  bg-transparent position-relative ${layoutView === 'grid' ? 'col-lg-12': 'col-md-4'}`}>
            <div className=" mx-auto p-0">
              <Skeleton  width={'100%'} height={200} />
            </div>
          </Col>
          <Col className={`${layoutView === 'grid' ? 'col-lg-12': 'col-md-8'}`} >
                <Card.Body >
                    <Card.Title  className="w-100" >
                       <Skeleton  width={'100%'} height={10} />
                       <Skeleton  width={50} height={10} />
                    </Card.Title>
                    <div className="card-text">
                      <div className="no_item_sold_ d-flex justify-content-between">
                          <div className="_no_p_">
                            <span className="_text_">
                                <Skeleton  width={85} height={9} />
                            </span>
                          </div>
                          <div className="_n_rating_row">
                                <Skeleton  width={60} height={9} />
                          </div>
                        </div>
                        <div className="wrap__t__group d-flex align-items-center justify-content-between">
                          <div className="card-image-wrapper me-2">
                             <Skeleton  width={36} height={37} />
                          </div>
                          <div className="d-flex align-items-center mt-2 justify-content-between w-100">
                             <Skeleton  width={60} height={12} />
                          </div>
                        </div>
                    </div>
                </Card.Body>
                
        </Col>
        </Card>
    </Col>
  )
}
 