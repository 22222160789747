import React, { useEffect } from "react"
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Overlay } from "@ekenta/components";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { ScaleSpinner } from "@ekenta/components/Spinner/CustomSpinner";
import { withCheckoutLayout } from "@ekenta/hoc";
import { useVerifyPaymentTransactionQuery } from "@ekenta/redux/store/services/checkoutApi";
import { checkoutCallbackPagePathUrl, homePathUrl } from "@ekenta/routes/constant";
import { HelmetHeader } from "../../components/HelmetHeader/HelmetHeader";
import * as queryString from "query-string";
import { Card, Col, Container, Row } from "react-bootstrap";

import "./CheckoutVerifyPage.scss";

const CheckoutVerifyPage = () => {
    const navigate = useNavigate();
    const query = queryString.parse(window.location.search);
    const verifyRef = query.trxref;

    const { token } = useAppSelector(state => state.auth.authentication);

    const {isLoading,isError,isSuccess }
    = useVerifyPaymentTransactionQuery({verifyRef, token}, {skip: !verifyRef});


    useEffect(() => {
      if(isError){
          toast.error(`Verification Payment Failed`,{
            toastId: "error-verification-pay-id-toast-error",
        });
      }

      if(!verifyRef){
         navigate(`${homePathUrl}`,{
          replace: true
         });
      }
    },[isError,verifyRef]);


      // If Verifying Payment and reference was valid
   useEffect(() => {
    if(isSuccess){
      navigate(`${checkoutCallbackPagePathUrl}?status=success`, {
        state: {
            status: "success", 
            statusMsg: "Payment successfully" 
        },
        replace: true
    });
    }
   },[isSuccess]);

  return (
    <>
     <HelmetHeader 
         title="Verify Payment -Checkout- Ekenta.com"
         description="Ekenta Order and Checkout Page"
      />
       <div className="d_checkout_wrapper">
         <div className="checkout_col__v">
             <Container>
                  <Col lg={12} className="checkout__mainBar mt-4">
                      <Row>
                          <Col className="checkout__stepper__pro" xs={12} lg={4}>
                             <Col lg={12} className="checkout__tit_ ">
                                <Card style={{height:"450px",boxShadow:"none",background:"tranparent"}}>
                                  <Card.Body>
                                   <div className="d-flex align-items-center justify-content-center h-100">
                                     {isLoading ? 
                                      <Overlay><ScaleSpinner color={"#384a60"} /></Overlay>
                                        : null}
                                    </div>
                                  </Card.Body>
                                </Card>
                          </Col>
                          </Col>
                      </Row>
                  </Col>
              </Container>
          </div>
        </div>
    </>
  )
}

export default withCheckoutLayout(CheckoutVerifyPage);

const OverlayLoader = styled.div`
  position: absolute;
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(248,248,255,0.5);
    z-index: 2;
`;