
export const GOOGLE_CLIENTID =process.env.REACT_APP_GOOGLE_CLIENTID;
export const FACEBOOK_APPID =process.env.REACT_APP_FACEBOOK_CLIENTID;

export const REDUX_PERSIST_SECRET = process.env.REACT_APP_REDUX_PERSIST_SECRET;
export const FLUTTERWAVE_KEY_PUBLIC = process.env.REACT_APP_FLUTTERWAVE_KEY;
export const PAYSTACK_KEY_PUBLIC =process.env.REACT_APP_PAYSTACK_KEY;
export const PAYSTACK_AUTHORIZATION_KEY= process.env.REACT_APP_PAYSTACK_AUTHORIZATION_KEY;

export const GOOGLE_ANALYTICS_TRACKINGID = ["G-E8YTEEW404"]; //process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID

export const BASE_URL = process.env.REACT_APP_DEPLOYMENT_ENDPOINT;
export const BASE_URL_WEBSOCKET = 'ws';
export const LiveStream_BaseURL = 'https://app.simplelive.co/livestream/';
