import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FiPlus } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from '../../../redux/store/hook';
import { useAddCartItemMutation } from '../../../redux/store/services/cartApi';
import { addToCart } from '../../../redux/store/states/reducerSlice/cartSlice';

interface AddCartButtonIKitchenPropsType {
  productId: string;
  productname: string;
  product: any;
  name: string;
}

export default function AddCartButtonIKitchen(
  props: AddCartButtonIKitchenPropsType,
) {
  const dispatch = useAppDispatch();

  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [addCartItem, { isLoading, isFetching }] = useAddCartItemMutation();

  const handleAddToCart = () => {
    console.log(ek_isAuth);
    if (ek_isAuth && token) {
      if (props.productId) {
        addCartItem({
          productId: props.productId,
          token: token,
        });
      }
    } else {
      dispatch(addToCart(props.product));
    }
  };
  return (
    <>
      <Button
        className={`account-login-btn w-100 text-white ${
          isLoading || isFetching ? 'disabled' : ''
        }`}
        onClick={handleAddToCart}
      >
        {isLoading || isFetching ? (
          <Spinner size="sm" animation="border" variant="white" />
        ) : (
          <>
            <FiPlus fontSize={18} />
            <span className="mr2">{props.name}</span>
          </>
        )}
      </Button>
    </>
  );
}
