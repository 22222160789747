import * as Yup from 'yup';

const RatingAndReviewFormValidation = () => Yup.object({

    title: Yup.string()
               .required('Title is required'),
    details: Yup.string()
               .required('Details is required'),
    name: Yup.string()
               .required('Name is required'),
   
});

export default RatingAndReviewFormValidation;