import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { useNavigate } from 'react-router';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import withDefaultLayout from '../../../hoc/Layout/withDefaultLayout';
import { useFetchAllCollectionsQuery } from '../../../redux/store/services/collectionApi';
import { homePathUrl } from '../../../routes/constant';
// import SingleCollectionCard from '../component/SingleCollectionCard';
// import SingleMobileCollectionDrop from '../component/SingleMobileCollectionDrop';

import "./AllCollection.scss";

const AllCollection = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError, isSuccess } = useFetchAllCollectionsQuery();

  return (
    <>
      <HelmetHeader
        title="Browse All Collections"
        description="Ekenta Collection Page"
      />
      <div className="d_collection_wrapper_ browse__collection ">
        <Container>
          <div className="all_collection_heading d-flex align-items-center ">
            <PageTitle
              title="Browse Collections"
              margin={false}
              subTitle={
                <span
                  className="cursor-pointer  d-flex d-lg-none w-auto align-items-center
                        text-muted"
                  onClick={() => navigate(homePathUrl)}
                >
                  <VscArrowSmallLeft />
                  Back Home
                </span>
              }
            />
          </div>
          <div className="main__all_collection_">
            {isLoading ? (
              <>Loading...</>
            ) : (
              <>
                <Row>
                  {data &&
                    isSuccess &&
                    data.map((item: any, index: number) => {
                      //const _slug = slugifyText(item.name.toLowerCase(),item.id);
                      return (
                        <></>
                        // <SingleCollectionCard
                        //   key={item.id + '_' + index}
                        //   collectionName={item.name}
                        //   subCatgories={item.subCollectionCategories}
                        // />
                      );
                    })}
                </Row>
                {/* <SingleMobileCollectionDrop data={data} /> */}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default withDefaultLayout(AllCollection);
