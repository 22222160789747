import React from "react";
import classNames from "classnames";
import classnames from "classnames";
import { TypographyPropsType } from "@ekenta/interfaces";
import styles from "./Typography.module.scss";

function Typography({ children, ...props }: TypographyPropsType) {
  const {
    as: Component = "h1",
    size = "medium",
    uiText,
    color,
    id,
    transform,
    heading,
    className,
  } = props;

  const classes = classNames(styles.TypographyText, {
    [styles[size]]: size,
    [styles.uiText]: uiText,
    [styles.displayText]: !uiText,
    [styles.primaryColor]: color === "primary",
    [styles.secondaryColor]: color === "secondary",
    [styles.defaultColor]: color === "default",
    [styles.lightColor]: color === "light",
    [styles.darkColor]: color === "dark",
    [styles.errorColor]: color === "error",
    [styles.heading]: heading,
    [styles.upperCaseText]: transform === "uppercase",
    [styles.lowerCaseText]: transform === "lowercase",
    [styles.capitalizeText]: transform === "capitalize",

  });

  return (
    <Component className={classnames(classes, className)} id={id}>
      {children}
    </Component>
  );
}

export default Typography;
