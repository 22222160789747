import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Navbar, Nav, Form, FormControl, Container, Button as BSButton, Col, ListGroup, Badge, InputGroup, Card, Row } from 'react-bootstrap';
import { FiSearch, FiMenu, } from 'react-icons/fi';
import searchFormValidation from '../../validations/schemas/search-input.schema';
import { useAppDispatch, useAppSelector } from '../../redux/store/hook';
import { removeUser } from '../../redux/store/states/reducerSlice/authenticationSlice';
import { DashboardPathUrl, DashboardSellers, SellerOnboardingPathUrl, accountProfilePathUrl, cartPathUrl, loginPathUrl, ordersPathUrl, productBaseUrl, registerPathUrl, savedItemPathUrl, singleLivePathUrl } from '../../routes/constant';
import CartItems from '../Cart/CartItems';
import { useFetchCartItemsQuery } from '../../redux/store/services/cartApi';
import { VscAccount, VscQuestion } from 'react-icons/vsc';
import { ShoppingCartIcon } from '../icons/icons';
import { TriggerMobileSearchOffCanvasContext } from '../../context/TriggerMobileSearchOffCanvasContext';
import { useGoogleLogout } from 'react-google-login';
import { GOOGLE_CLIENTID } from '../../constants';
import { toast } from "react-toastify";
import { TriggerSideOffCanvasContext } from '../../context/TriggerSideOffCanvasContext';
import { useFetchSearchProductsRQuery, useSearchSellerByMerchantQuery } from '../../redux/store/services/productsApi';
import { TriggerHelpCanvasContext } from '../../context/TriggerHelpCanvasContext';
import HelpSideCanvas from '../OffCanvas/CollectionSidCanvas/HelpSideCanvas';
import { checkIfAdmin, checkIfAdminOrSellerRoleExist, logoutHandler, slugifyText, useMatchPath } from '../../utils';
import Select from '../Select/SelectDrop';
import { removeLive } from '../../redux/store/states/reducerSlice/livecomSlice';

import "./NavigationBar.scss";
import SearchBox from '../SearchBox/SearchBox';
import Button from '../Button/Button';
import Overlay from '../Overlay/Overlay';
import Logo from '../Logo/Logo';
import { Typography } from '..';

interface NavigationBarProps {
    layoutType: string;
    bottomcomponent: any;
}

const mapCartReturnTotal = (cartData: any) => {
    const quantities: number[] = [];
    cartData ? cartData.cartItems.map((item: any) => {
        if (item) { quantities.push(item.quantity); }
    }) : null
    if (quantities.length > 0) {
        const total = quantities.reduce((a: number, b: number) => {
            return a + b;
        });
        return total;
    }
    return null;
}

const mapCartReturnTotalQty = (cartData: any) => {
    const { quantity } = cartData.cartItems.reduce((cartTotal: any, cartItem: any) => {
        cartTotal.quantity += cartItem.quantity;
        return cartTotal;
    }, {
        quantity: 0
    });

    return quantity;
}


export default function NavigationBar(props: NavigationBarProps) {
    const { pathname } = useLocation();
    const { signOut } = useGoogleLogout({
        clientId: GOOGLE_CLIENTID ? GOOGLE_CLIENTID : ''
    });
    const { handleSearchCanvas } = useContext(TriggerMobileSearchOffCanvasContext);
    const { handleShowCanvas } = useContext(TriggerSideOffCanvasContext);
    const { handleHelpShowCanvas } = useContext(TriggerHelpCanvasContext);


    const [bgColor, setBgColor] = useState(false);
    const [noOfCart, setNoOfCart] = useState<number | null>(null);
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [searchSeller, setSearchSeller] = useState("");
    const [keyword, setKeyword] = useState("");


    const navbarForLayoutType = props.layoutType === 'account' && bgColor ?
        'account-nav-b-scroll' :
        props.layoutType === 'account' && !bgColor ? 'account-nav-bg' : 'default-nav-bg';
    const [scroll, setScroll] = useState(false);
    const { account, ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const isAdmin = checkIfAdmin(token);
    const { cartItems } = useAppSelector(state => state.cart.cart);

    const navigate = useNavigate();
    const dispatch = useAppDispatch()
        ;

    const { data, isSuccess } = useFetchCartItemsQuery(token, { skip: !token });
    const navRef = useRef<HTMLDivElement>(null);

    const pageno = 1;
    const getResult = useFetchSearchProductsRQuery({ keyword, pageno }, { skip: keyword == "" });
    const query = searchSeller;


    const controlNavBarBackgroundColor = () => {
        if (window.scrollY >= 10) {
            setBgColor(true);
        } else {
            setBgColor(false);
        }
    }


    useEffect(() => {
        controlNavBarBackgroundColor();
        window.addEventListener("scroll", controlNavBarBackgroundColor);
        window.onscroll = function () { triggerSrollFunction() };
        return () => {
            window.removeEventListener("scroll", controlNavBarBackgroundColor);
        }
    }, []);


    useEffect(() => {
        if (ek_isAuth && token) {
            if (isSuccess && data) {
                const noCart = mapCartReturnTotalQty(data);
                const res = noCart > 0 ? noCart : null;
                setNoOfCart(res);
            } else {
                setNoOfCart(null);
            }
        } else {
            if (cartItems) {
                const noCart = mapCartReturnTotal({ cartItems: cartItems });
                setNoOfCart(noCart);
            }
        }
    }, [
        isSuccess,
        data,
        cartItems
    ])


    const searchLists = [
        { name: 'products' },
        { name: 'sellers' },
        // { name: 'brands' },
        // { name: 'collections' },
        // { name: 'features', },
    ]


    const formik = useFormik({
        initialValues: {
            search: '',
            by: searchLists[0]
        },
        validationSchema: searchFormValidation(),
        onSubmit: values => {
            setShowAutocomplete(false);
            window.location.href = '/search-result?q=' + values.search + '&category=' + values.by.name;
        }
    });


    useEffect(() => {
        if (formik.values.search.trim()) {
            setShowAutocomplete(true);
        } else {
            setShowAutocomplete(false);
        }

        if (formik.values.search.trim() && formik.values.by.name === "sellers") {
            setSearchSeller(formik.values.search);
        }
    }, [
        formik.values.search,
        formik.values.by
    ]);



    const getSearch = (myParam: any) => {
        let query = myParam.target.value;
        setKeyword(query)

    };

    const logoutHandlerFunc = () => {
        logoutHandler(
            navigate,
            dispatch,
            signOut,
            loginPathUrl,
            removeUser,
            removeLive
        );
        toast.success(`You've successfully logout`, {
            toastId: "logout-success-id-toast-success",
        });
    }

    const triggerSrollFunction = () => {
        if (navRef.current != null) {
            const navOffset = navRef.current.offsetTop;
            if (window.pageYOffset >= navOffset) {
                setScroll(true);
            } else {
                setScroll(false);
            }
        }
    }

    const autoCompleteData = () => {
        if (formik.values.by.name === "products") {
            return getResult?.isSuccess && getResult?.data.productPage.content;

        } else if (formik.values.by.name === "sellers") {
            return getResult?.isSuccess && getResult?.data.merchantBusinessList;
        }
    }



    return (
        <>
            <div className={`nav-header ${navbarForLayoutType} ${scroll ? 'position-top' : ''} 
                         ${pathname === useMatchPath(singleLivePathUrl, pathname) && 'nav_header_display'}`}>
                {/* collapseOnSelect */}
                <Navbar ref={navRef} className='menubar' variant="dark" expand="lg">
                    <Container className="Dcontainer_wrapper">
                        <div className="brand_wrapper">
                            <button className="navbar-toggler d-lg-none p-0 border-0" type="button" aria-controls=""
                                aria-expanded="false" onClick={() => handleShowCanvas('all')}>
                                <span className="navbar-mobile-icon me-1">
                                    <FiMenu fontSize={22} />
                                    {/* <MenuIcon className="text-white" /> */}
                                </span>
                            </button>
                            <Navbar as={Link} to="/">
                                <Logo />
                            </Navbar>
                        </div>
                        <div className="navigation_Left_Seller">
                            {token ? (
                                checkIfAdminOrSellerRoleExist(token) === true ? (
                                    <Nav.Link
                                        as={Link}
                                        className="nav-ng-seller"
                                        to={isAdmin ? DashboardSellers : DashboardPathUrl}
                                    >Go To Dashboard</Nav.Link>
                                ) : (
                                    <Nav.Link
                                        as={Link}
                                        target="_blank"
                                        className="nav-ng-seller"
                                        to={SellerOnboardingPathUrl}
                                    >Sell Anything</Nav.Link>
                                )
                            ) : (
                                <Nav.Link
                                    as={Link}
                                    target="_blank"
                                    className="nav-ng-seller"
                                    to={SellerOnboardingPathUrl}
                                > Sell Anything</Nav.Link>
                            )}
                            {/* <Link to="/">
                                <Typography
                                    as="h5"
                                    color="light"
                                    size="small"
                                    className="nav-ng-seller"
                                    uiText>
                                    Sell anything
                                </Typography>
                            </Link> */}
                        </div>
                        <div className="d-lg-none mb_navigation_div">
                            <Nav.Link className="account_mb" as={Link} to={"#"}
                                onClick={() => handleSearchCanvas('bottom')}>
                                <FiSearch />
                            </Nav.Link>
                            {/* <Nav.Link className="account_mb" as={Link} to={"#"}>
                                <StoreIcon className="store__icon" />
                            </Nav.Link> */}
                            <Nav.Link className="cart_mb position-relative" as={Link} to={cartPathUrl}>
                                <ShoppingCartIcon className="shopping_cart " />
                                {noOfCart ?
                                    (<Badge className="cart_badge_">
                                        {noOfCart}
                                    </Badge>) :
                                    null}
                            </Nav.Link>
                        </div>

                        <Navbar.Collapse className="d-sm-none d-md-none " id="basic-navbar-nav">
                            <Col className="form-search-col">
                                <Form noValidate className="d-flex align-items-center justify-content-center" onSubmit={formik.handleSubmit}>
                                    {/* <InputGroup className="input_group_div">
                                        <Select
                                            lists={searchLists}
                                            selected={formik.values.by}
                                            setSelected={(value: any) => formik.setFieldValue('by', value)}
                                        />
                                        <Col
                                            l className="pe-0 form-search-col">
                                            <FormControl

                                                type="search"
                                                name="search"
                                                autoComplete="off"
                                                placeholder="I'm shopping for..."
                                                className=" form-search-input"
                                                aria-label="Search"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.search}
                                                onKeyUp={(e) => getSearch(e)}
                                            />
                                            {formik.values.search && (<Col className="p-0 search-auto-complete_">
                                                <Col className="w-100 auto-complete-wrapper">
                                                    <Card className="mt-1 shadow-lg">
                                                        <Card.Body>
                                                            <ListGroup as="ul">

                                                                {
                                                                    fetchProduct_isSuccess && fetchProduct_data?.content?.map((e: any, i: any) => {
                                                                        const _slugProduct = e?.id ? slugifyText(e?.name.toLowerCase(), e?.id) : '/';
                                                                        return <ListGroup.Item as="li" key={i} className="list-item" onClick={() => navigate(`${productBaseUrl}/${_slugProduct}`)}>
                                                                            <Row style={{ alignContent: 'center', alignItems: 'center' }}>
                                                                                <Col lg={2}><img className='img-thumbnail' src={e?.thumbnailUrl} height={'50px'} alt="" /></Col>
                                                                                <Col lg={10} style={{ fontSize: '14px' }}>{e?.name}</Col>
                                                                            </Row>
                                                                        </ListGroup.Item>
                                                                    })
                                                                }
                                                                {
                                                                    fetchProduct_data?.content.length == 0 ?
                                                                        <p>NO RESULT FOUND FOR {formik.values.search.toUpperCase()}</p> : ''
                                                                }
                                                            </ListGroup>

                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Col>
                                            )}
                                        </Col>
                                        <BSButton className="nav_button text-white px-4 " type="submit">
                                            <FiSearch className="iconSearch me-1" />
                                            Search
                                        </BSButton>
                                    </InputGroup>
                                     */}
                                    <SearchBox
                                        name="search"
                                        value={formik.values.search}
                                        isLoading={getResult?.isLoading}
                                        isError={getResult?.isError}
                                        autoCompleteData={
                                            getResult?.data ?
                                                autoCompleteData()
                                                : null}
                                        onChange={formik.handleChange}
                                        category={formik.values.by.name}
                                        showAutocomplete={showAutocomplete}
                                        setShowAutocomplete={setShowAutocomplete}
                                        onKeyUp={(e) => getSearch(e)}
                                        setValue={formik.setFieldValue}
                                        renderPrefix={(
                                            <Select
                                                lists={searchLists}
                                                selected={formik.values.by}
                                                setSelected={(value: any) => {
                                                    formik.setFieldValue('by', value)
                                                }
                                                }
                                            />
                                        )}
                                        renderSuffix={(
                                            <Button name="navButton"
                                                bgVariant="primary"
                                                width=""
                                                className="nav_button text-white px-4 " type="submit">
                                                <FiSearch className="iconSearch me-1" />
                                                Search
                                            </Button>
                                        )}

                                    />
                                </Form>

                                {showAutocomplete ? (
                                    <div className="SearchAutocomplete__Overlay" onClick={() => setShowAutocomplete(false)}></div>
                                ) : null}

                            </Col>
                            <Nav className="me-auto">
                                <div className="_account_nav_wrapper">
                                    <>
                                        <Nav.Link className="_account_hover_" as={Link} to={ek_isAuth && account.user ? `${accountProfilePathUrl}` : `${loginPathUrl}`}>
                                            <VscAccount fontSize={16} />
                                            <div className="ms-2 navLink__group">
                                                <span className="navLink__group_item">{ek_isAuth && account.user ? 'Hi,  ' + account.user.firstName : 'Sign In'}</span>
                                                <h5 className="navLink__group_h5">Account</h5>
                                            </div>
                                        </Nav.Link>
                                        <div className="_dropdown_y_">
                                            {!ek_isAuth && !account.user &&
                                                <div className="auth_wrapper">
                                                    <h5>Welcome to Ekenta!</h5>
                                                    <div className="mb-2 button_group">
                                                        <Link to={registerPathUrl} className="button_group_join"><BSButton name="Join">Join</BSButton></Link>
                                                        <Link to={loginPathUrl} className="button_group_login" ><BSButton name="SignIn" className="button_outline_sigin">Sign in</BSButton></Link>
                                                    </div>
                                                </div>
                                            }
                                            <ListGroup className="list_ul" as="ul">
                                                {ek_isAuth && account.user && <ListGroup.Item
                                                    className="_my_account_ mb-0"
                                                    as={Link}
                                                    to={accountProfilePathUrl}>
                                                    <div className="item_wrap">
                                                        <VscAccount className="icon__acc_" />
                                                        <span>My Account</span>
                                                    </div>
                                                </ListGroup.Item>}

                                                <ListGroup.Item
                                                    as={Link}
                                                    to={ordersPathUrl}>
                                                    <div className="item_wrap">
                                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.86461 6.88236C8.17813 5.56432 9.99326 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 11.5858 4.41421 11.25 4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 16.8325 7.16751 20.75 12 20.75C16.8325 20.75 20.75 16.8325 20.75 12C20.75 7.16751 16.8325 3.25 12 3.25C9.57851 3.25 7.38558 4.23462 5.80213 5.82352C5.77668 5.84906 5.75345 5.87597 5.73245 5.90401L4.47488 4.64645C4.33769 4.50926 4.13353 4.46382 3.95111 4.52986C3.76869 4.59591 3.64094 4.76152 3.62338 4.95473L3.26982 8.84382C3.25639 8.9916 3.30929 9.13771 3.41422 9.24264C3.51914 9.34757 3.66526 9.40047 3.81304 9.38703L7.70213 9.03348C7.89534 9.01591 8.06095 8.88817 8.12699 8.70575C8.19304 8.52332 8.1476 8.31916 8.01041 8.18198L6.7823 6.95386C6.81101 6.93235 6.83854 6.90852 6.86461 6.88236Z" fill="" />
                                                            <path d="M12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7V12C11.25 12.2586 11.3832 12.4989 11.6025 12.636L14.6025 14.511C14.9538 14.7305 15.4165 14.6238 15.636 14.2725C15.8555 13.9212 15.7488 13.4585 15.3975 13.239L12.75 11.5843V7Z" fill="" />
                                                        </svg>
                                                        <span>Orders</span>
                                                    </div>
                                                </ListGroup.Item>
                                                {/* <ListGroup.Item
                                                as={Link}
                                                to={""}
                                                >
                                                <div className="item_wrap">
                                                    <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.5 12C15.5 11.1716 16.1716 10.5 17 10.5C17.8284 10.5 18.5 11.1716 18.5 12C18.5 12.8284 17.8284 13.5 17 13.5C16.1716 13.5 15.5 12.8284 15.5 12Z" fill="black"/>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.4413 6.67402C19.7836 5.12836 18.3302 4.01723 16.6007 3.83523L15.9488 3.76664C12.6565 3.42018 9.33575 3.44303 6.04851 3.83475L5.61657 3.88622C3.94777 4.08508 2.62552 5.38889 2.40324 7.05473C1.96528 10.337 1.96528 13.663 2.40324 16.9453C2.62552 18.6111 3.94777 19.9149 5.61657 20.1138L6.04851 20.1653C9.33575 20.557 12.6565 20.5798 15.9488 20.2334L16.6007 20.1648C18.3302 19.9828 19.7836 18.8717 20.4413 17.326C21.4806 17.0166 22.2738 16.1156 22.404 15.0024C22.6373 13.0076 22.6373 10.9924 22.404 8.99764C22.2738 7.88442 21.4806 6.98344 20.4413 6.67402ZM15.7918 5.2584C12.6107 4.92365 9.40218 4.94573 6.226 5.32421L5.79406 5.37568C4.80524 5.49351 4.02177 6.26606 3.89007 7.25312C3.46967 10.4038 3.46967 13.5963 3.89007 16.7469C4.02177 17.734 4.80525 18.5065 5.79406 18.6243L6.226 18.6758C9.40218 19.0543 12.6107 19.0764 15.7918 18.7416L16.4437 18.673C17.2942 18.5835 18.0468 18.1643 18.5683 17.542C17.0602 17.6299 15.532 17.5906 14.0417 17.4241C12.7724 17.2822 11.7458 16.2826 11.5961 15.0024C11.3628 13.0076 11.3628 10.9924 11.5961 8.99764C11.7458 7.71738 12.7724 6.71784 14.0417 6.57597C15.532 6.40942 17.0602 6.37012 18.5683 6.45806C18.0468 5.83574 17.2942 5.4165 16.4437 5.327L15.7918 5.2584ZM19.2774 8.01471C19.278 8.01855 19.2786 8.02239 19.2792 8.02623L19.2852 8.06511L19.4839 8.03426C19.5867 8.04444 19.6893 8.05524 19.7917 8.06669C20.3791 8.13234 20.8468 8.59648 20.9141 9.17189C21.1339 11.0509 21.1339 12.9491 20.9141 14.8281C20.8468 15.4035 20.3791 15.8677 19.7917 15.9333C19.6893 15.9448 19.5866 15.9556 19.4839 15.9658L19.2852 15.9349L19.2792 15.9738C19.2786 15.9776 19.278 15.9815 19.2774 15.9853C17.5987 16.1372 15.8772 16.1199 14.2084 15.9333C13.621 15.8677 13.1532 15.4035 13.0859 14.8281C12.8661 12.9491 12.8661 11.0509 13.0859 9.17189C13.1532 8.59648 13.621 8.13234 14.2084 8.06669C15.8772 7.88017 17.5987 7.86285 19.2774 8.01471Z" fill=""/>
                                                    </svg>
                                                        <span>My Wallet</span> 
                                                 </div>
                                            </ListGroup.Item> */}
                                                <ListGroup.Item
                                                    as={Link}
                                                    to={savedItemPathUrl}>
                                                    <div className="item_wrap">
                                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M3.25 10.0298C3.25 7.3293 5.61914 5.25 8.4 5.25C9.83347 5.25 11.0948 5.92214 12 6.79183C12.9052 5.92214 14.1665 5.25 15.6 5.25C18.3809 5.25 20.75 7.3293 20.75 10.0298C20.75 11.8797 19.9611 13.5064 18.8682 14.8815C17.7771 16.2543 16.35 17.4193 14.9835 18.366C14.4615 18.7276 13.9335 19.0611 13.4503 19.3072C12.9965 19.5383 12.4747 19.75 12 19.75C11.5253 19.75 11.0035 19.5383 10.5497 19.3072C10.0665 19.0611 9.53846 18.7276 9.01653 18.366C7.65005 17.4193 6.22287 16.2543 5.13182 14.8815C4.03888 13.5064 3.25 11.8797 3.25 10.0298ZM8.4 6.75C6.32075 6.75 4.75 8.2791 4.75 10.0298C4.75 11.4333 5.34579 12.74 6.30609 13.9482C7.26828 15.1588 8.56292 16.2269 9.87074 17.133C10.3656 17.4758 10.8317 17.7675 11.2305 17.9706C11.6586 18.1886 11.9067 18.25 12 18.25C12.0933 18.25 12.3414 18.1886 12.7695 17.9706C13.1683 17.7675 13.6344 17.4758 14.1293 17.133C15.4371 16.2269 16.7317 15.1588 17.6939 13.9482C18.6542 12.74 19.25 11.4333 19.25 10.0298C19.25 8.2791 17.6792 6.75 15.6 6.75C14.4058 6.75 13.2908 7.46342 12.5946 8.36892C12.4526 8.55356 12.2329 8.66176 12 8.66176C11.7671 8.66176 11.5474 8.55356 11.4054 8.36892C10.7092 7.46342 9.59415 6.75 8.4 6.75Z" fill="" />
                                                        </svg>
                                                        <span>Saved Items</span>
                                                    </div>
                                                </ListGroup.Item>
                                                {ek_isAuth && account.user && <>
                                                    <hr className='mb-1 mt-1' />
                                                    <ListGroup.Item
                                                        name=""
                                                        as={BSButton}
                                                        onClick={logoutHandlerFunc}
                                                        className="d_logout">
                                                        <div className="item_wrap">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 18.25C11.5858 18.25 11.25 18.5858 11.25 19C11.25 19.4142 11.5858 19.75 12 19.75H18C18.9665 19.75 19.75 18.9665 19.75 18V6C19.75 5.0335 18.9665 4.25 18 4.25H12C11.5858 4.25 11.25 4.58579 11.25 5C11.25 5.41421 11.5858 5.75 12 5.75L18 5.75C18.1381 5.75 18.25 5.86193 18.25 6L18.25 18C18.25 18.1381 18.1381 18.25 18 18.25H12Z" fill="" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M14.5031 14.3652C15.1934 14.3652 15.7531 13.8056 15.7531 13.1152V10.8747C15.7531 10.1843 15.1934 9.6247 14.5031 9.6247L9.89048 9.6247C9.88396 9.55128 9.87713 9.47787 9.87 9.40448L9.81597 8.8486C9.73354 8.00049 8.83294 7.49258 8.06451 7.86084C6.43029 8.64403 4.95085 9.71578 3.69736 11.0245L3.59816 11.1281C3.13395 11.6128 3.13395 12.3771 3.59815 12.8618L3.69736 12.9654C4.95085 14.2741 6.43029 15.3459 8.06451 16.1291C8.83293 16.4973 9.73354 15.9894 9.81597 15.1413L9.87 14.5854C9.87713 14.512 9.88396 14.4386 9.89048 14.3652H14.5031ZM9.19511 12.8652C8.92874 12.8652 8.69326 13.0045 8.56008 13.216C8.49523 13.319 8.45464 13.4391 8.44656 13.5685C8.42842 13.8594 8.40524 14.15 8.37703 14.4403L8.36135 14.6017C7.3253 14.0677 6.36316 13.4028 5.49838 12.6239C5.27402 12.4218 5.05622 12.2121 4.84538 11.995C5.86892 10.9409 7.05651 10.0607 8.36135 9.38824L8.37703 9.54959C8.40524 9.83987 8.42842 10.1305 8.44656 10.4214C8.47122 10.8167 8.79902 11.1247 9.19511 11.1247H14.2531V12.8652H9.19511Z" fill="" />
                                                            </svg>
                                                            <span>Logout</span>
                                                        </div>
                                                    </ListGroup.Item>
                                                </>
                                                }
                                            </ListGroup>
                                        </div>
                                    </>
                                </div>

                                <Nav.Link className="nav__link_help" onClick={() => handleHelpShowCanvas()}>
                                    <VscQuestion className="svg__icon" />
                                    <div className="ms-2 navLink__group_">
                                        <span className="navLink__group_item">Give</span>
                                        <h5 className="navLink__group_h5">Feedback</h5>
                                    </div>
                                </Nav.Link>

                                <div className="cart_wrapper_nav">
                                    <Nav.Link className="_cart_navlink" as={Link} to={ek_isAuth ? cartPathUrl : loginPathUrl}>
                                        <ShoppingCartIcon className="shopping_cart " />
                                        <div className="ms-2 navLink__group__">
                                            <span className="navLink__group_item">Cart</span>
                                            <h5 className="navLink__group_h5">Items</h5>
                                        </div>
                                        {noOfCart ?
                                            (<Badge className="_cart_badge_" bg="primary">
                                                {noOfCart}
                                            </Badge>) :
                                            null}

                                    </Nav.Link>
                                    <div className="_dropd_cart">
                                        <div className="_main_cart_header">
                                            <span>Your Cart</span>
                                        </div>
                                        <div>
                                            <CartItems />
                                        </div>
                                    </div>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* {bottom navbar} */}
                {/* <Col className="bottom-menubar">
                    {props.bottomcomponent}
                </Col> */}
            </div>
            <HelpSideCanvas />
        </>
    )
}


