import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Navigate } from "react-router";
import { DecodeTokenType } from "../../interfaces/decode";
import { useAppSelector } from "../../redux/store/hook";
import { removeUser } from "../../redux/store/states/reducerSlice/authenticationSlice";
import { decodeToken } from "../../utils/decodeToken";
import { loginPathUrl } from "../constant";


const LandingPgRoute = ({ component: RouteComponent, ...rest }: { component: () => JSX.Element; }) => {
  const dispatch = useDispatch();
  const { ek_isAuth, token } = useAppSelector((state: any) => state.auth.authentication);

  useEffect(() => {
    if (ek_isAuth && token) {
      const decoded_Token: DecodeTokenType | any = decodeToken(token);
      if (!decoded_Token || decoded_Token.exp * 1000 < Date.now()) {
        dispatch(removeUser());
        <Navigate to={loginPathUrl} />;
      }
    }
  }, [
    ek_isAuth,
    token
  ])

  return <RouteComponent {...rest} />
};

export default LandingPgRoute;

