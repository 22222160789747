import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {ListGroup} from 'react-bootstrap';
import { accountProfilePathUrl, addressesPathUrl, inboxPathUrl, loginPathUrl, 
    ordersPathUrl, pendingReviewsPathUrl, returnRequestPathUrl, savedItemPathUrl, WalletSystemPathUrl } from '../../../../routes/constant';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hook';
import { VscAccount, VscDebugStepBack, VscHeart, VscHistory, VscInbox, VscQuestion } from 'react-icons/vsc';
import { removeUser } from '../../../../redux/store/states/reducerSlice/authenticationSlice';
import { LocationIcon, LogoutIcon } from '../../../../components/icons/icons';
import { useGoogleLogout } from 'react-google-login';
import { GOOGLE_CLIENTID } from '../../../../constants';
import { MdOutlineRateReview } from 'react-icons/md';


export default function ProfileSideBar(props:{isLoading: boolean | null}) {
    const { pathname } = useLocation();
    const { signOut } = useGoogleLogout({
        clientId: GOOGLE_CLIENTID ? GOOGLE_CLIENTID : ''
    });
    const { account,ek_isAuth} = useAppSelector(state => state.auth.authentication);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const logoutHandler = () => {
        signOut();
        dispatch(removeUser());
        navigate(loginPathUrl);
    }

 
    return (
    <>
        <ListGroup>
             
            <div className=" me-auto mb-4 w-100 _display-header">
                <div className="pb-1  mb-2">
                    <h5 className="_wrapper_h5">
                        <Link to={accountProfilePathUrl}>
                            Hi, {ek_isAuth && account.user ? account.user.firstName : 'Account'}
                        </Link>
                    </h5>
                    <p>Thanks for being Ekenta customer</p>
                </div>
            </div>
            <div className="_list_child_group">
                <ListGroup.Item
                        as={Link} to={accountProfilePathUrl}
                        className={`
                        ${
                            pathname === '/user/account/profile' ? 'active' :
                            pathname === '/user/account/profile/edit/' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <VscAccount className="icon__acc_"/>
                            <span>Manage Profile</span> 
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as={Link} to={addressesPathUrl}
                        className={`
                        ${
                            pathname === '/user/account/addresses' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <LocationIcon className="icon__acc_2" />
                            <span>Address Book</span> 
                        </div>
                    </ListGroup.Item>
                    {/* <ListGroup.Item
                        as={Link} to={inboxPathUrl}
                        className={`
                        ${
                            pathname === '/user/account/inbox' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <VscInbox className="icon__acc_" />
                            <span>Inbox</span> 
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as={Link} to={WalletSystemPathUrl}
                        className={`
                        ${
                            pathname === '/user/account/wallet' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <WalletIcon  className="icon__acc_2" />
                            <span>My Wallet</span> 
                        </div>
                    </ListGroup.Item> */}
            </div>
            <div className="_list_child_group">
                <ListGroup.Item
                        as={Link} to={ordersPathUrl}
                        className={`
                        ${
                            pathname === '/user/account/orders' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <VscHistory className="icon__acc_"/>
                            <span>Orders</span> 
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as={Link} to={savedItemPathUrl}
                        className={`
                        ${
                            pathname === '/user/account/saved-item' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <VscHeart className="icon__acc_" />
                            <span>Saved Items</span> 
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as={Link} to={pendingReviewsPathUrl}
                        className={`
                        ${
                            pathname === '/user/account/pending-reviews' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <MdOutlineRateReview className="icon__acc_" />
                            <span>Pending Reviews</span> 
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as={Link} to={returnRequestPathUrl}
                        className={`
                        ${
                            pathname === '/user/account/return-request' ? 'active' : 
                            pathname === '/user/account/return-request/new' && 'active'
                            }
                        `}>
                        <div className="sD_m_ ms-2 me-auto">
                            <VscDebugStepBack className="icon__acc_" />
                            <span>Return Request</span> 
                        </div>
                    </ListGroup.Item>
                    
            </div>


            <div className="_list_child_group">
                <ListGroup.Item
                        as={Link} to={'#'}>
                        <div className="sD_m_ ms-2 me-auto">
                            <VscQuestion className="icon__acc_" />
                            <span>Help Center</span> 
                        </div>
                    </ListGroup.Item>
                   
            </div>
           {ek_isAuth && (
            <div>
                <ListGroup.Item
                        as={Link} to={'#'} onClick={logoutHandler}>
                        <div className="sD_m_ ms-2 me-auto">
                            <LogoutIcon className="icon__acc_2" />
                            <span>Log Out</span> 
                        </div>
                    </ListGroup.Item>
                </div>
           )} 
         
    </ListGroup>
    
    </>
 )
}
