/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card, Col, Row, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchAdmniAllOrdersQuery,
} from '../../../redux/store/services/vendorProductApi';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import {
  useFetchAccountingSalesAdminQuery,
  useFetchDailyTotalOrderQuery,
} from '../../../redux/store/services/adminSellerApi';
import {
  FiBarChart,
  FiBarChart2,
  FiChevronLeft,
  FiChevronsLeft,
  FiGift,
  FiToggleRight,
  FiUser,
  FiUserCheck,
} from 'react-icons/fi';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router';
import {
  DashboardReturnRequestPathUrl,
  returnRequestPathUrl,
} from '../../../routes/constant';

export default function ViewReturnRequest({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const navigate = useNavigate();
  // const allOrders = useFetchDailyTotalOrderQuery(token)
  // const ord = useFetchAccountingSalesAdminQuery(token)

  return (
    <DashboardLayout>
      <Helmet>
        <title>Seller Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Orders Dashboard Page" />
        <meta name="description" content="Ekenta Orders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <hr />
            <h3 className="display_lg_header text-bold">
              <FiChevronLeft
                size={'16px'}
                onClick={() => navigate(DashboardReturnRequestPathUrl)}
              />
              View return request details
            </h3>
            <hr />

            <Col className="mt-3">
              <Card className={'p-3'}>
                <Card.Body>
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">ID ? </Form.Label>
                    </Col>
                    <Col lg={9}>1</Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">Order# ?</Form.Label>
                    </Col>
                    <Col lg={9}>6</Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Customer ?{' '}
                      </Form.Label>
                    </Col>
                    <Col lg={9}>Customer@gmail.com</Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Product ?:
                      </Form.Label>
                    </Col>
                    <Col lg={9}>Mens Shoe size 38</Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Quantity?
                      </Form.Label>
                    </Col>
                    <Col lg={9}>
                      <Form.Control
                        type="text"
                        value={1}
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                      />
                    </Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Quantity returned to stock?
                      </Form.Label>
                    </Col>
                    <Col lg={9}>
                      <Form.Control
                        type="text"
                        value={1}
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                      />
                    </Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Return Request Reason?
                      </Form.Label>
                    </Col>
                    <Col lg={9}>
                      <Form.Control
                        value={'Received wrong product'}
                        type="text"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                      />
                    </Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Return Request Status?
                      </Form.Label>
                    </Col>
                    <Col lg={9}>
                      <Form.Control
                        type="text"
                        value={'Pending'}
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                      />
                    </Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Request Action?{' '}
                      </Form.Label>
                    </Col>
                    <Col lg={9}>
                      <Form.Control
                        type="text"
                        value={'Replace'}
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                      />
                    </Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">
                        Customers Comment?
                      </Form.Label>
                    </Col>
                    <Col lg={9}>
                      <Form.Control
                        type="text"
                        value={'Replace'}
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                      />
                    </Col>
                  </Row>{' '}
                  <br />
                  <Row>
                    <Col lg={3}>
                      <Form.Label htmlFor="inputPassword5">Date</Form.Label>
                    </Col>
                    <Col lg={9}>03/9/2022 12PM</Col>
                  </Row>{' '}
                  <br />
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
