import { Roles } from './roleConfig';
import {
  Login,
  Register,
  EmailVerification,
  ForgotPassword,
  PasswordReset,
} from '../pages/Authentication';

import {
  Profile,
  UpdateAccount,
  UpdateAddress,
  ChangePassword,
  ReturnRequest,
  NewReturnRequest,
  SavedItem,
  BuyerOrders,
  AccountAddresses,
  // Inbox,
  // WalletSystem,
} from '../pages/Account';

import {
  DashboardHome,
  DashboardCollection,
  AllProducts,
  Orders,
  LowStock,
  AddProduct,
  PreviewProduct,
  WareHouse,
  ReturnsRequested,
  Sellers,
  DashboardSingleSeller,
  SellerLiveScreen,
  AllActiveSellers,
  UserManagement,
  EkeMarket,
  AllUsers,
  AllVerifiedUsers,
  AddCollection,
  EditCollection,
  AddSubCollection,
  EditSubCollection,
  EditSubCollectionCategory,
  AddSubCollectionCategory,
  AdminCollection,
  AdminSubCollection,
  AdminSubCollectionCategories,
  ManageVideo,
  SellersAwaitingApproval,
  SellerDetails,
  AdminOrders,
  AdminAccountingOrders,
  ManageBanner,
  AddBanner,
  ViewReturnRequest,
  OrderDetails,
  OrderListDetails,
  Events,
  SellerProfile,
  AdminPayoutRequest,
  Discount,
} from '../pages/Dashboard';

//lazy(() => import('../pages/ErrorPage/PageNotFound'));
import SellerOnboarding from '../pages/Seller/Onboading/SellerOnboarding';

import ProductHome from '../pages/Product';
import ProductDetail from '../pages/Product/Detail/Detail';
import Cart from '../pages/Cart/Cart';

import CheckoutOnePage from '../pages/Checkout';
//lazy(() => import('../pages/ErrorPage/PageNotFound'));

import {
  loginPathUrl,
  registerPathUrl,
  emailVerifyPathUrl,
  forgotPassPathUrl,
  resetPassPathUrl,
  mobileAccountIndexPath,
  accountProfilePathUrl,
  addressesPathUrl,
  // inboxPathUrl,
  // WalletSystemPathUrl,
  sellerShopPathUrl,
  accountUpdateProfilePathUrl,
  accountUpdateAddressPathUrl,
  accountChangePasswordPathUrl,
  returnRequestPathUrl,
  newreturnRequestPathUrl,
  checkoutOnePagePathUrl,
  checkoutCallbackPagePathUrl,
  SellerOnboardingPathUrl,
  allLivePathUrl,
  singleLivePathUrl,
  savePlanetPathUrl,
  homePathUrl,
  productDetailPathUrl,
  cartPathUrl,
  collectionPathUrl,
  subCollectionPathUrl,
  SubCollectionCartegoryPathUrl,
  DashboardPathUrl,
  DashboardAddProductPathUrl,
  DashboardCollectionPathUrl,
  DashboardProductsPathUrl,
  DashboardOrdersPathUrl,
  DashboardLowStockPathUrl,
  DashboardWareHousePathUrl,
  DashboardReturnRequestPathUrl,
  DashboardSellerLiveCommercePathUrl,
  savedItemPathUrl,
  ordersPathUrl,
  DashboardAdminIndex,
  DashboardSellers,
  SingleSeller,
  awaitingSellers,
  activeSellersPathUrl,
  sellerReview,
  adminAllOrders,
  adminAccountingOrders,
  adminManageBanner,
  adminManageLogo,
  adminAddBanner,
  viewReturnRequest,
  DashboardOrdersPathUrlSingle,
  AdminOrdersPathUrlSingle,
  AdminUserMangement,
  AdminProductsPathUrl,
  adminAddLogo,
  adminReturnRequest,
  adminPayoutRequest,
  adminAccountingSellers,
  searchPathUrl,
  adminVideos,
  adminAddVideos,
  adminGetOrderDetails,
  termsAndConditionsUrl,
  iKitchenCollectionUrl,
  privacyUrl,
  pendingReviewsPathUrl,
  ratingAndReviewsPathUrl,
  adminGetAllUsersUrl,
  adminGetAllVerifiedUsersUrl,
  AdminDashboardCollectionPathUrl,
  AddCollectionUrl,
  AdminDashboardSubCollectionPathUrl,
  AdminDashboardSubCollectionCategoriesPathUrl,
  AddSubCollectionUrl,
  AddSubCollectionCategoryUrl,
  AdminSellerProfile,
  AdminDiscountPathUrl,
  previewProductUrl,
  EditCollectionUrl,
  EditSubCollectionUrl,
  EditSubCollectionCategoryUrl,
  iKitchenComingSoonPathUrl,
  rentItPathUrl,
  AdminDashoardEkeMarketPathUrl,
  DashboardEventsPathUrl,
  ekeMarketLivePathUrl,
  checkoutVerifyPagePathUrl,
} from './constant';

import MobileScreenAccountIndex from '../pages/Account/ManageAccount/MobileScreenAccountIndex';
import ManageLogo from '../pages/Dashboard/Admin/ManageLogo';
import AddLogo from '../pages/Dashboard/Admin/AddLogo';
import AdminAccountingSellers from '../pages/Dashboard/Admin/AdminAccountingSellers';
import AddVideo from '../pages/Dashboard/Admin/AddVideo';
import SaveThePlanetHome from '../pages/SaveThePlanet/SaveThePlanetHome';
import TermsAndCondition from '../pages/SaveThePlanet/TermsAndCondition';
import IKitchenCollection from '../pages/iKitchen/iKitchenCollection';
import PrivacyPolicy from '../pages/SaveThePlanet/PrivacyPolicy';
import PendingReviews from '../pages/Account/ManageAccount/PendingReviews';
import RatingAndReviews from '../pages/Account/ManageAccount/RatingAndReviews';
import IKitchenComingSoon from '../pages/SaveThePlanet/IKitchenComingSoon';
import RentItSoon from '../pages/SaveThePlanet/RentItSoon';

import { 
  AllLiveSellers,
  ViewLiveSeller,
  AllCollection,
  Ekemarket,
  CheckoutVerifyPage,
  CheckoutCallbackPage,
  SellerShop,
  SearchResult
} from '../pages'; 

export const LandingPageRoutes: { path: string; component: any }[] = [
  { path: homePathUrl, component: ProductHome },
  { path: productDetailPathUrl, component: ProductDetail },
  { path: collectionPathUrl, component: AllCollection },
  { path: sellerShopPathUrl, component: SellerShop },
  //{ path: SubCollectionCartegoryPathUrl, component: Collection },
  { path: allLivePathUrl, component: AllLiveSellers },
  { path: ekeMarketLivePathUrl, component: Ekemarket },
  { path: singleLivePathUrl, component: ViewLiveSeller },
  { path: searchPathUrl, component: SearchResult },
  { path: savePlanetPathUrl, component: SaveThePlanetHome },
  { path: termsAndConditionsUrl, component: TermsAndCondition },
  //for mobilescreen accout sideview
  { path: mobileAccountIndexPath, component: MobileScreenAccountIndex },
  { path: iKitchenCollectionUrl, component: IKitchenCollection },
  { path: privacyUrl, component: PrivacyPolicy },
  { path: iKitchenComingSoonPathUrl, component: IKitchenComingSoon },
  { path: rentItPathUrl, component: RentItSoon },
];

export const OnlyOnboardingPageRoute: { path: string; component: any }[] = [
  { path: SellerOnboardingPathUrl, component: SellerOnboarding },
];

export const AuthenticationRoutes: {
  path: string;
  component: (props?: any) => JSX.Element;
  displayName?: string;
}[] = [
  { path: loginPathUrl, component: Login },
  { path: registerPathUrl, component: Register },
  { path: emailVerifyPathUrl, component: EmailVerification },
  { path: forgotPassPathUrl, component: ForgotPassword },
  { path: resetPassPathUrl, component: PasswordReset },
];

export const AuthorizeUserRoutes: {
  path: string;
  component: (props?: any) => JSX.Element;
  displayName?: string;
  roles: string[];
}[] = [
  { path: accountProfilePathUrl, component: Profile, roles: [Roles.ROLE_USER] },
  { path: cartPathUrl, component: Cart, roles: [Roles.ROLE_USER] },
  {
    path: addressesPathUrl,
    component: AccountAddresses,
    roles: [Roles.ROLE_USER],
  },
  // { path: inboxPathUrl, component: Inbox,roles: [Roles.ROLE_USER,]},
  // { path: WalletSystemPathUrl, component: WalletSystem,roles: [Roles.ROLE_USER,]},
  {
    path: accountUpdateProfilePathUrl,
    component: UpdateAccount,
    roles: [Roles.ROLE_USER],
  },
  {
    path: accountUpdateAddressPathUrl,
    component: UpdateAddress,
    roles: [Roles.ROLE_USER],
  },
  {
    path: accountChangePasswordPathUrl,
    component: ChangePassword,
    roles: [Roles.ROLE_USER],
  },
  {
    path: returnRequestPathUrl,
    component: ReturnRequest,
    roles: [Roles.ROLE_USER],
  },
  {
    path: newreturnRequestPathUrl,
    component: NewReturnRequest,
    roles: [Roles.ROLE_USER, Roles.ROLE_SELLER],
  },
  { path: savedItemPathUrl, component: SavedItem, roles: [Roles.ROLE_USER] },
  { path: ordersPathUrl, component: BuyerOrders, roles: [Roles.ROLE_USER] },
  {
    path: checkoutOnePagePathUrl,
    component: CheckoutOnePage,
    roles: [Roles.ROLE_USER],
  },
  {
    path: checkoutCallbackPagePathUrl,
    component: CheckoutCallbackPage,
    roles: [Roles.ROLE_USER],
  },
  {
    path: checkoutVerifyPagePathUrl,
    component: CheckoutVerifyPage,
    roles: [Roles.ROLE_USER],
  },

  {
    path: pendingReviewsPathUrl,
    component: PendingReviews,
    roles: [Roles.ROLE_USER],
  },
  {
    path: ratingAndReviewsPathUrl,
    component: RatingAndReviews,
    roles: [Roles.ROLE_USER],
  },

  //
];

export const AuthorizeDashboardRoutes: {
  path: string;
  component: any;
  roles: string[];
}[] = [
  {
    path: DashboardPathUrl,
    component: DashboardHome,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardCollectionPathUrl,
    component: DashboardCollection,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardReturnRequestPathUrl,
    component: ReturnsRequested,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardWareHousePathUrl,
    component: WareHouse,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardAddProductPathUrl,
    component: AddProduct,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardProductsPathUrl,
    component: AllProducts,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardOrdersPathUrl,
    component: Orders,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardLowStockPathUrl,
    component: LowStock,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardSellerLiveCommercePathUrl,
    component: SellerLiveScreen, //livecommerce
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },
  {
    path: DashboardAdminIndex,
    component: LowStock,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: DashboardSellers,
    component: Sellers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: SingleSeller,
    component: DashboardSingleSeller,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: awaitingSellers,
    component: SellersAwaitingApproval,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: activeSellersPathUrl,
    component: AllActiveSellers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: sellerReview,
    component: SellerDetails,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminAllOrders,
    component: AdminOrders,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminAccountingOrders,
    component: AdminAccountingOrders,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminUserMangement,
    component: UserManagement,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminSellerProfile,
    component: SellerProfile,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminManageBanner,
    component: ManageBanner,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminManageLogo,
    component: ManageLogo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminAddBanner,
    component: AddBanner,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: viewReturnRequest,
    component: ViewReturnRequest,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: DashboardOrdersPathUrlSingle,
    component: OrderDetails,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: AdminOrdersPathUrlSingle,
    component: OrderDetails,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminProductsPathUrl,
    component: AllProducts,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminAddLogo,
    component: AddLogo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminReturnRequest,
    component: ReturnsRequested,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminPayoutRequest,
    component: AdminPayoutRequest,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashoardEkeMarketPathUrl,
    component: EkeMarket,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: DashboardEventsPathUrl,
    component: Events,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminAccountingSellers,
    component: AdminAccountingSellers,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: AdminDiscountPathUrl,
    component: Discount,
    roles: [Roles.ROLE_ADMIN],
  },
  {
    path: adminVideos,
    component: ManageVideo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminAddVideos,
    component: AddVideo,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminGetOrderDetails,
    component: OrderListDetails,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminGetAllUsersUrl,
    component: AllUsers,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: adminGetAllVerifiedUsersUrl,
    component: AllVerifiedUsers,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashboardCollectionPathUrl,
    component: AdminCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddCollectionUrl,
    component: AddCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashboardSubCollectionPathUrl,
    component: AdminSubCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AdminDashboardSubCollectionCategoriesPathUrl,
    component: AdminSubCollectionCategories,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddSubCollectionUrl,
    component: AddSubCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddSubCollectionCategoryUrl,
    component: AddSubCollectionCategory,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: AddSubCollectionCategoryUrl,
    component: AdminSubCollectionCategories,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: previewProductUrl,
    component: PreviewProduct,
    roles: [Roles.ROLE_ADMIN, Roles.ROLE_SELLER],
  },

  {
    path: EditCollectionUrl,
    component: EditCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: EditSubCollectionUrl,
    component: EditSubCollection,
    roles: [Roles.ROLE_ADMIN],
  },

  {
    path: EditSubCollectionCategoryUrl,
    component: EditSubCollectionCategory,
    roles: [Roles.ROLE_ADMIN],
  },
];
