import React from 'react'
import { Breadcrumb, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import ProfileSideBar from './component/AccountSideBar'
import DefaultLayout from '../../../hoc/Layout/Default-Layout'
import ActiveOrder from './BuyerOrders/component/ActiveOrder';
import CompleteOrder from './BuyerOrders/component/CompleteOrder';
import ClosedOrder from './BuyerOrders/component/ClosedOrder';
import { useAppSelector } from '../../../redux/store/hook';
import BreadCrumb from './component/BreadCrumb';
import img5 from '../../../assets/images/slatelive4.jpg';
import { Link } from 'react-router-dom';
import { useFetchUserCompletedOrderQuery } from '../../../redux/store/services/accountApi';
import EmptyOrder from '../../../components/Box/EmptyOrder';


export default function PendingReviews() {
    const { token } = useAppSelector(state => state.auth.authentication);
    const { data, isLoading, isFetching, isError, error, isSuccess } = useFetchUserCompletedOrderQuery(token, { skip: !token });
    return (
        <DefaultLayout>
            <Helmet>
                <title>Manage Account - Pending Reviews - Ekenta</title>
                <meta charSet="utf-8" />
                <meta name="title" content="Ekenta Order Page" />
                <meta name="description"
                    content="Ekenta Update Order Page"
                />
                <meta property="og:locale" content="en_NG" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>
            <Container className="account-user-container">
                <><BreadCrumb activePage="Order History" /></>

                <Row className="account-pro-row g-2">
                    <Col xs lg={3} className="d-none d-lg-block account-col-left">
                        <ProfileSideBar isLoading={null} />
                    </Col>
                    <Col className="account-profile-col-right px-0">
                        <div className="mb-0 ">
                            <h5 className="display_lg_header d-flex align-items-center">
                                <span>Pending Reviews({data?.length}) </span>
                            </h5>
                        </div>
                        <div className="buyer__pg_tabs">
                            <div className="row card">
                                
                                {isSuccess && data ? data?.length > 0 ? data?.map((item: any, i: any) => {
                                    return (
                                        <div className="col-lg-12" key={i}>
                                            <div className="row g-0">
                                                <div className="col-3 col-md-3 pe-0 card--image_div">
                                                    <img src={img5} className="img-fluid rounded-start" alt="..." />
                                                </div>
                                                <div className="col-7 col-md-7 ps-0">
                                                    <div className="card-body">
                                                        <div className="top--section__d">
                                                            <p className="headings_review">{'Sit amet ultrices vestibulum eu quis nam diam nulla.'}</p>
                                                            <p className="text_p_review_order">Order Number: {'1100110011'}</p>
                                                            <p className="text_p_review_date">Delivered on 20-06-22</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-2 col-md-2 mt-3">
                                                    <Link style={{ textDecoration: 'none' }} to={'/user/account/ratings-and-reviews/:id'}>
                                                        <h5 className='text_p_review_order text-uppercase'>Rate this Product</h5>
                                                    </Link>

                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                }) : <EmptyOrder orderType='Complete' />
                                    : null

                                }

                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </DefaultLayout>
    )
}


