import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { animateScroll } from "react-scroll";
import Footer from "../../components/Footer/Footer";
import BottomNav from "../../components/NavigationBar/BottomNav/BottomNav";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import CollectionsOffCanvas from "../../components/OffCanvas/CollectionSidCanvas/CollectionSideCanvas";
import HelpSideCanvas from "../../components/OffCanvas/CollectionSidCanvas/HelpSideCanvas";
import SearchMobileOffCanavs from "../../components/OffCanvas/SearchMobileOffCanavs";
import {
  mobileAccountIndexPath,
  singleLivePathUrl,
} from "../../routes/constant";
import { useMatchPath } from "../../utils";

interface DefaultLayoutProps {
  children: any;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };
  return (
    <>
      <>
        <NavigationBar layoutType="default" bottomcomponent={<BottomNav />} />
        <div
          className={`main--container parent  
                        ${pathname ===
            useMatchPath(singleLivePathUrl, pathname) &&
            "view_live_pad"
            }`}
        >
          {props.children}
        </div>

        {pathname === useMatchPath(mobileAccountIndexPath, pathname) ? null : (
          <Footer />
        )}
      </>
      <CollectionsOffCanvas />
      <HelpSideCanvas />
      {/* Mobile search off canvas */}
      <div className="mobile_search_window">
        <SearchMobileOffCanavs />{" "}
      </div>
    </>
  );
}
