import React from 'react';
import classnames from 'classnames';
import { TableRowPropType } from '../../interfaces/Table';

import styles from "./Table.module.scss";

const TableRow = ({ children, hasLink, className, ...rest }:TableRowPropType) => {
  return (
    <tr
        {...rest}
        className={classnames(className, styles.tr, { [styles.hasLink]: hasLink })}
        >
        {children}
    </tr>
  )
}

export default TableRow;
