import React, { useState } from 'react'
import styles from "./Carousel.module.scss";
import banner from "@ekenta/assets/images/landing-banner.png";
import banner2 from "@ekenta/assets/images/landing-banner2.png";
import banner3 from "@ekenta/assets/images/landing-banner3.png";
import banner4 from "@ekenta/assets/images/landing-banner4.png";
import banner5 from "@ekenta/assets/images/landing-banner5.png";
import classnames from 'classnames';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';


const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [translateValue, setTranslateValue] = useState(0);
    const length = 4;



    const prevSlide = () => {
        if (currentIndex === 0) return;
        setCurrentIndex(currentIndex - 1);

        setTranslateValue(translateValue + carouselSlideWidth());
    };

    const nextSlide = () => {
        if (currentIndex === length - 1) {
            return setCurrentIndex(0), setTranslateValue(0);
        }
        setCurrentIndex(currentIndex + 1);
        setTranslateValue(translateValue - carouselSlideWidth());
    };

    const carouselSlideWidth = () => {
        const ele = document.querySelector('.carousel-item');
        return ele!.clientWidth;
    };



    return (
        <div data-eke-c-id="live-1" className={styles.LiveMain_TopWidget}>

            <div className={styles.TopWidget_module}>
                <div className={styles.Slider}
                    style={{ transform: `translate(calc(${translateValue}px))` }}>
                    <div className={classnames(styles.Slider_Child, "carousel-item")}>
                        <img alt="" src={banner}
                            title="" />
                    </div>
                    <div className={classnames(styles.Slider_Child, "carousel-item")}>
                        <img alt="" src={banner2}
                            title="" />
                    </div>
                    <div className={classnames(styles.Slider_Child, "carousel-item")}>
                        <img alt="" src={banner3}
                            title="" />
                    </div>
                    <div className={classnames(styles.Slider_Child, "carousel-item")}>
                        <img alt="" src={banner4}
                            title="" />
                    </div>
                    <div className={classnames(styles.Slider_Child, "carousel-item")}>
                        <img alt="" src={banner5}
                            title="" />
                    </div>
                </div>
            </div>

            <button className={classnames(styles.Slider_Button, styles.Left)} onClick={prevSlide}>
                <FiChevronLeft />
            </button>
            <button className={classnames(styles.Slider_Button, styles.Right)} onClick={nextSlide}>
                <FiChevronRight />
            </button>
        </div>
    )
}

export default Carousel