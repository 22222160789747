import React from "react"
import { withDefaultLayout } from "@ekenta/hoc";
import { HelmetHeader, Typography } from "@ekenta/components";
import classnames from "classnames";

import { Container } from "react-bootstrap";

import { LazyLoadImage } from "react-lazy-load-image-component";

import styles from "./index.module.scss";
import "./carousel.css";
import { NextBannerArrow, PrevBannerArrow } from "./common/Reuseables";
import LiveStreams from "./common/LiveStreams/LiveStreams";
import { useGetDiscountedProductQuery, useGetTopDealsQuery, useGetTopRatedQuery } from "@ekenta/redux/store/services/homeApi";
import LazyLoad from "react-lazyload";
import SectionCard from "./common/SectionCard/SectionCard";
import CollectionList from "./common/CollectionList";
import Carousel from "./common/Carousel/Carousel";
import FeatureShop from "./common/FeatureShop/FeatureShop";

const ProductHome = () => {
    const getTopDeals = useGetTopDealsQuery();
    const getDiscountProduct = useGetDiscountedProductQuery();
    const getTopRated = useGetTopRatedQuery();




    return (
        <>
            <HelmetHeader
                title="Ekenta - Online Shopping Made Easy"
                description="Ekenta Home Page"
            />
            <div className={styles.HomeWrapper}>
                <br />

                <Container className={classnames(styles.HomeWrapper__TopContainer)}>
                    <div className={styles.LiveContainer}>
                        <div className={classnames(styles.LiveContainer__Main, "main_Grid")}>
                            <div className={styles.LiveContainer__Main__MaxWrapper}>
                                <Carousel />
                                <CollectionList />
                                {/* { All Live section} */}
                                <LiveStreams />
                                <FeatureShop />
                                <LazyLoad>
                                    <SectionCard
                                        title="Top Deals"
                                        headerColor="#E2E8F0"
                                        data={getTopDeals?.data}
                                        isError={getTopDeals?.isError}
                                        isLoading={getTopDeals?.isLoading}
                                        isSuccess={getTopDeals?.isSuccess}
                                    />
                                </LazyLoad>
                                <LazyLoad>
                                    <SectionCard
                                        title="Discount Price | Up to 50% Off"
                                        headerColor="#E2E8F0"

                                        data={getDiscountProduct?.data}
                                        isError={getDiscountProduct?.isError}
                                        isLoading={getDiscountProduct?.isLoading}
                                        isSuccess={getDiscountProduct?.isSuccess}
                                    />
                                </LazyLoad>

                                <LazyLoad>
                                    <SectionCard
                                        title="Top Rated"
                                        headerColor="#E2E8F0"
                                        data={getTopRated?.data}
                                        isError={getTopRated?.isError}
                                        isLoading={getTopRated?.isLoading}
                                        isSuccess={getTopRated?.isSuccess}
                                    />

                                </LazyLoad>

                            </div>
                        </div>
                    </div>
                </Container>

            </div>
        </>
    )
}




export default withDefaultLayout(ProductHome);