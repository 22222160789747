import React,{useState} from 'react';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';

interface TriggerMobileSearchOffCanvasContextPropsType {
    offCanvas: {show:boolean,placement:OffcanvasPlacement |undefined};
    handleSearchCanvas?: any;
    handleOnHideCanvas?: () => void;
}

const defaultState = {
    offCanvas: {show:false,placement:undefined},
};

export const TriggerMobileSearchOffCanvasContext = React.createContext<TriggerMobileSearchOffCanvasContextPropsType>(defaultState);

export const TriggerMobileSearchOffCanvasProvider = ({children}:any) => {
    const [offCanvas, setOffCanvas] = useState<{show:boolean;placement:OffcanvasPlacement| undefined}>({
                        show: false,
                        placement: undefined
                     });

    const handleSearchCanvas = (placement:OffcanvasPlacement|undefined) => setOffCanvas((prevState) => (
        {show: !prevState.show,placement:placement}
    ));
    const handleOnHideCanvas = () => setOffCanvas({...offCanvas,show:false});

    return(
        <TriggerMobileSearchOffCanvasContext.Provider value={{offCanvas,handleSearchCanvas,handleOnHideCanvas}}>
            {children}
        </TriggerMobileSearchOffCanvasContext.Provider>
    )
}