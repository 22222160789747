import React, { useState, useContext, useEffect } from "react"
import styles from "./PriceFilter.module.scss";
import { Button, Typography } from "@ekenta/components";
import { Form, FormControl } from "react-bootstrap";
//import NumberFormat from "react-number-format";
import { SearchFilterContext } from "@ekenta/context/SearchFilterContext";

const PriceFilter = () => {
    const { configureFilterUrl } = useContext(SearchFilterContext);

    const [priceRange, setPriceRange] = useState({
        min: "",
        max: ""
    });


    useEffect(() => {
        if (priceRange.min === "" &&
            priceRange.max === "") {
            const data = { key: "min_max", value: "", set: false };
            configureFilterUrl(data);
        }
    }, [priceRange]);



    const handlePriceRangeCheck = () => {
        if (parseInt(priceRange.min.toString()) > 0
            && parseInt(priceRange.max.toString()) > 0) {
            const price_ = `${priceRange.min.toString()}-${priceRange.max.toString()}`;
            const data = { key: "min_max", value: price_, set: true };
            configureFilterUrl(data);
        }
    }

    return (
        <section>
            <div className={styles.MarginBottom}>
                <div className={styles.PriceRange_div}>
                    <FormControl
                        type="number"
                        placeholder="₦Min"
                        min="0"
                        step=".01"
                        value={priceRange.min}
                        onChange={(e: any) => setPriceRange({ ...priceRange, min: e.target.value })}
                    />
                    {/* <NumberFormat
                        name=""
                        placeholder="₦Min"
                        allowNegative={false}
                        onValueChange={(value: any) => setPriceRange({ ...priceRange, min: e.target.value })} /> */}
                    -
                    <FormControl
                        type="number"
                        placeholder="₦Max"
                        min="0"
                        step=".01"
                        value={priceRange.max}
                        onChange={(e: any) => setPriceRange({ ...priceRange, max: e.target.value })}
                    />
                    <Button
                        onClick={handlePriceRangeCheck}
                        bgVariant="primary"
                        textVariant="white"
                        disabled={
                            parseInt(priceRange.min.toString()) <= 0 ||
                            parseInt(priceRange.max.toString()) <= 0 ||
                            priceRange.min === "" ||
                            priceRange.max === ""
                        }>
                        GO
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default PriceFilter