import React from 'react';
import { Col } from 'react-bootstrap';
import { useLocation } from 'react-router';
import AppContent from '../../components/AppContent';
import ErrorBoundaries from '../../components/ErrorBoundary/ErrorBoundaries';
import BottomNav from '../../components/NavigationBar/BottomNav/BottomNav';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import CollectionsOffCanvas from '../../components/OffCanvas/CollectionSidCanvas/CollectionSideCanvas';
import { loginPathUrl } from '../../routes/constant';
import { useMatchPath } from '../../utils';

export default function withAuthenticationLayout(
  WrappedComponent: () => JSX.Element,
  className?: string,
) {
  const AuthenticationLayout = (props: any) => {
    const { pathname } = useLocation();
    return (
      <>
        {/* {navigationbar component} */}
        <NavigationBar layoutType="account" bottomcomponent={<BottomNav />} />
        
        <div
         
        >
          <ErrorBoundaries>
            <AppContent full className={className}>
              <WrappedComponent {...props} />
            </AppContent>
          </ErrorBoundaries>
        </div>
        <CollectionsOffCanvas />
      </>
    );
  };
  // className={`w-100 ${
  //   pathname === useMatchPath(loginPathUrl, pathname)
  //     ? '_sBoMLG'
  //     : '_RegLayout'
  // } account-layout   position-relative`}

  AuthenticationLayout.displayName = 'AuthenticationLayout';
  return AuthenticationLayout;
}
