import React, { Suspense, useEffect, useState } from 'react'
import { Badge, Button, CloseButton, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { BsHeart } from 'react-icons/bs';
import { FaHeart } from 'react-icons/fa';
import { FiCheck, FiHeart } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Checkbox from "react-custom-checkbox";
import VideoPlayerWithoutControls from '../VideoPlayer/VideoPlayerWithoutControls';
import { useAppSelector } from '../../redux/store/hook';
import { useAddToAndRemoveFavoriteItemQuery } from '../../redux/store/services/productsApi';
import { slugifyText } from '../../utils';
import QuantityCartButton from '../Cart/CartButtons/QuantityCartButton/QuantityCartButton';
import AddCartButton from '../Cart/CartButtons/AddCartButton/AddCartButton';
import QuantityCartButtonIKitchen from '../Cart/CartButtons/QuantityCartButtonIKitchen';
import AddCartButtonIKitchen from '../Cart/CartButtons/AddCartButtonIKitchen';

interface iKitchenCardPropType {
    url: string;
    imageUrl: string;
    title: string;
}

export const mapCartDataReturnQuantity = (cartData: any, productid: number) => {
    return cartData ? cartData.find((item: any) => {
        if (item.product.id === productid) {
            return item;
        }
    }) : null
}

export default function IKitchenProductCard(props: any) {
    const savedItems: string | any[] = [];
    const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const [showModal, setShowModal] = React.useState(false);
    const [showRecipeModal, setShowRecipeModal] = React.useState(false);
    const { cartItems } = useAppSelector(state => state.cart.cart)
    const [mysaved, setMySaved] = useState([0])
    const allSaved = () => {
        const x = []
        for (let i = 0; i < savedItems.length; i++) {
            x.push(savedItems[i].id);
        }
        setMySaved(x)
    }
    const [localCartItem, setLocalCartItem] = useState<any>(null);

    // Add and Remove Product from favorite/ saved Item List
    const [prodId, setProdId] = useState<{ id: number | null }>({ id: null });
    const addToFavoriteObj = { token: token, productId: prodId.id }
    const { isLoading: toFavorite_isLoading, isFetching: toFavorite_isFetching, isError: toFavorite_isError, isSuccess: toFavorite_isSuccess }
        = useAddToAndRemoveFavoriteItemQuery(addToFavoriteObj, { skip: !addToFavoriteObj.token || !addToFavoriteObj.productId });

    const _slugProduct = props?.prod?.id ? slugifyText(props.title.toLowerCase(), props?.prod?.id) : '/';

    useEffect(() => {
        allSaved()
    }, [])
    useEffect(() => {
        if (!ek_isAuth) {
            const items = mapCartDataReturnQuantity(cartItems, props?.prod?.id);
            setLocalCartItem(items);

        }
    }, [ek_isAuth, cartItems]);

    const handleClose = () => {
        setShowModal(false)
    }

    const handleCloseRecipe = () => {
        setShowRecipeModal(false)
        setShowModal(true)
    }

    return (

        <>
            <Col lg={4} xs={12} className={'col__cardb-wrapper mb-3'} style={{ "textDecoration": "none" }}>
                <div className="card">
                    {/* <div className="video__wrapper">
                        <Suspense fallback={<Spinner animation='border' size='sm' />}>
                            <VideoPlayerWithoutControls videoUrl={props.imageUrl} thumbnailUrl={props.imageUrl} title={props.title} />
                        </Suspense>
                    </div> */}

                    <div className={'container-kitchen'} style={{ height: '200px' }}>
                        <img className="img-fluid" src={props?.imageUrl} alt={props?.title} />
                        <Badge className={'tag '} bg="secondary">{props?.brandName} </Badge>
                    </div>
                    <div className="card-body bg-white py-2 px-1 ikitchen-body" style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)}>
                        <div className="px-1">
                            <h5 className="col-lg-12 ikitchen-title">{props?.title}</h5>
                            <h6 className="col-lg-5" style={{ color: '#E04826' }}>₦ {new Intl.NumberFormat().format(props?.price)}</h6>
                        </div>
                        <div className="px-1">
                            <p>Seller&apos;s score 80%</p>
                        </div>
                        <div className="px-1">
                            <p style={{ fontSize: '12px' }}>{props?.shortDescription.substr(0, 50)}...</p>
                        </div>
                    </div>
                </div>
            </Col>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ zIndex: '99999999999999999999' }}
                centered
                show={showModal}
                onHide={handleClose}
            >
                <Modal.Body>
                    <CloseButton onClick={handleClose} style={{ position: 'absolute', right: '0', padding: '3px 20px' }} />
                    <br />
                    <Row className={'p-2 align-items-center'}>
                        <Col lg={5} className={'modal-img'}>
                            <img src={props.imageUrl} className={'img-fluid'} alt="" />
                            <div className={'badge-position-top'}>
                                <Badge bg="secondary">{props?.brandName}</Badge>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className="row">
                                <div className="col-lg-11"><p className='my-header'>{props?.title}</p></div>
                                <div className="col-lg-1 fav_ikitchen"><FiHeart /></div>
                            </div>
                            <div>
                                <p className='my-text'>Seller&apos;s Name: ekenta</p>
                                <p className='my-text'>Sellers Score: 80%</p>
                                <hr />
                                <h5>NGN: {new Intl.NumberFormat().format(props?.price)}</h5>
                                <hr />
                            </div>
                            <div className='myflex'>
                                <p>Quantiy &nbsp;</p>

                                <button className='my-btn'>+</button>
                                <p>&nbsp;&nbsp;&nbsp;</p>
                                <button className='my-btn'>-</button>
                            </div>

                            <div>
                                <p>Ingredients : Egunsi,  Ugwu, Palm oil.  <span className={'view-recipe'} onClick={() => { setShowModal(false); setShowRecipeModal(true) }}>View reciepe</span></p>
                            </div>

                            <div>
                                <div className="myflex align-items-center mb-2">
                                    <div className="col-lg-1">
                                        <Checkbox
                                            name="my-input"
                                            borderColor="#C4C4C4"
                                            icon={<FiCheck color="#E04826" size={18} />}
                                            style={{ cursor: "pointer", backgroundColor: '#F4F4F4' }}
                                        />
                                    </div>
                                    <div className="col-lg-6"> <div>Beef (NGN 100 / Pc)</div></div>
                                    <div className="col-lg-3 myflex">
                                        <button className='my-btn increment-button'>+</button>
                                        <div className={'mx-2'}><b>25</b></div>
                                        <button className='my-btn'> - </button>
                                    </div>
                                </div>
                                <div className="myflex align-items-center mb-2">
                                    <div className="col-lg-1">
                                        <Checkbox
                                            name="my-input"
                                            borderColor="#C4C4C4"
                                            icon={<FiCheck color="#E04826" size={18} />}
                                            style={{ cursor: "pointer", backgroundColor: '#F4F4F4' }}
                                        />
                                    </div>
                                    <div className="col-lg-6"> <div>Chicken (NGN 150 / Pc)</div></div>
                                    <div className="col-lg-3 myflex">
                                        <button className='my-btn increment-button'>+</button>
                                        <div className={'mx-2'}><b>25</b></div>
                                        <button className='my-btn'>-</button>
                                    </div>
                                </div>
                                <div className="myflex align-items-center mb-2">
                                    <div className="col-lg-1">
                                        <Checkbox
                                            name="my-input"
                                            borderColor="#C4C4C4"
                                            icon={<FiCheck color="#E04826" size={18} />}
                                            style={{ cursor: "pointer", backgroundColor: '#F4F4F4' }}
                                        />
                                    </div>
                                    <div className="col-lg-6"> <div>Fish (NGN 50 / Pc)</div></div>
                                    <div className="col-lg-3 myflex">
                                        <button className='my-btn increment-button'>+</button>
                                        <div className={'mx-2'}><b>25</b></div>
                                        <button className='my-btn decrement-button'>-</button>
                                    </div>
                                </div>

                            </div>

                            <hr />

                            <div>
                                <p><b>Subtotal:</b> 10,000</p>
                                <p><b>Total:</b> 10,000 </p>
                            </div>

                            {/* <Button className={'account-login-btn w-100 text-white'}>
                                Add to cart
                            </Button> */}
                            <div className="_add_cart_button">
                                {ek_isAuth && token ? (
                                    <>
                                        {props?.cartItem && props?.cartItem.quantity >= 1 ? (
                                            <div className="group--components">
                                                <QuantityCartButtonIKitchen
                                                    cartItems={cartItems}
                                                    cartItemId={props?.cartItem ? props?.cartItem.id : ''}
                                                    className="quantity--picker"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <AddCartButtonIKitchen
                                                    productId={props?.prod?.id}
                                                    productname={props?.title}
                                                    product={props?.prod}
                                                    name="Add to Cart"
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {localCartItem && localCartItem.quantity >= 1 ? (
                                            <div className="group--components">
                                                <QuantityCartButtonIKitchen
                                                    cartItems={localCartItem}
                                                    cartItemId={localCartItem ? localCartItem.id : ''}
                                                    className="quantity--picker"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <AddCartButtonIKitchen
                                                    productId={props?.prod?.id}
                                                    productname={props?.title}
                                                    product={props?.prod}
                                                    name="Add to Cart"
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>

                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>

            {/* recipe modal */}
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ zIndex: '99999999999999999999' }}
                centered
                show={showRecipeModal}
                onHide={handleCloseRecipe}
            >
                <Modal.Body>
                    <CloseButton onClick={handleCloseRecipe} style={{ position: 'absolute', right: '0', padding: '3px 20px' }} />
                    <div className="row text-center">
                        <div className={'mb-2 text-center'}><h4>{props?.title} Recipe</h4></div>
                        <div className={'text-center'}>
                            <img src={props.imageUrl} style={{ height: '300px' }} className={'img-fluid'} alt="" />
                        </div>
                        <div className={'text-center my-2'}>
                            <h6>Ingredients : Egunsi,  Ugwu, Palm oil. </h6>
                        </div>
                        <div className={'container'} style={{ width: '75%' }}>
                            <ul>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id turpis.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id turpis.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id turpis.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id turpis.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id turpis.</li>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit id turpis.</li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}


