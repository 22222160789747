import React, { useCallback, useRef, forwardRef } from 'react';
import Tippy from '@tippyjs/react/headless';
import { Placement } from 'tippy.js';


import './Tooltips.scss';

interface TooltipPropsType {
	style?: any;
	content: string;
	children: React.ReactElement;
	hasHeader?: boolean;
	maxWidth?: string;
	appendTo?: any;
	allowHTML?: boolean;
	placement?: Placement;
}

const Tooltip = forwardRef((
	{
		style,
		content,
		children,
		hasHeader,
		maxWidth,
		placement,
		appendTo,
		allowHTML = false,
		...rest
	}: TooltipPropsType,
	ref: any
) => {


	return (
		<Tippy
			arrow={true}
			ref={ref}
			placement={placement}
			appendTo={appendTo}
			render={attrs => {
				return (
					<div
						className="Tooltip_tooltip"
						style={{ maxWidth: maxWidth || '200px', ...style }}
						{...attrs}
					>
						{allowHTML ? (
							<div
								className="Tooltip_content"
								dangerouslySetInnerHTML={{ __html: content }}
							/>
						) : (
							<div className="Tooltip_content">{content}</div>
						)}

						<div className="Tooltip_arrow" data-popper-arrow="true"></div>
					</div>
				);
			}}
			{...rest}
		>
			{children}
		</Tippy>
	);
}
);

Tooltip.displayName = 'Tooltip'

export default Tooltip;
