import React, { Fragment, useState } from 'react'
import Tippy from "@tippyjs/react";
import classnames from 'classnames';

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-toward.css";
import Tooltip from '../Tooltips/Tooltips';

import { Placement } from 'tippy.js';

import dropdownStyles from "./Dropdown.module.scss";
import { FiCheck } from 'react-icons/fi';

interface DropdownPropType {
    name?: string;
    placement: Placement | undefined;
    prefixIcon?: JSX.Element;
    suffixIcon?: JSX.Element;
    render?: (isOpen: boolean) => React.ReactElement;
    menus: any;
    content?: JSX.Element;
    contentClass?: String;
    tooltipContent: string;
}

const Dropdown = ({
    name,
    prefixIcon,
    suffixIcon,
    placement,
    tooltipContent,
    menus,
    content,
    contentClass,
    render
}: DropdownPropType) => {

    const [isOpen, setIsOpen] = useState(false);

    const dropdownMenu = (
        <Fragment>
            {menus.length > 0 ?
                <>
                    <ul className={dropdownStyles.dropdown_menu}>
                        {menus.map((menu: any, index: number) => (
                            <>
                                <li
                                    key={menu.key + index}
                                    className="DropdownMenu__item"
                                    onClick={() => {
                                        setIsOpen(false);
                                        menu.handler();
                                    }}>
                                    <span className="DropdownMenu__icon">
                                        {menu.active ? <FiCheck /> : menu.icon}
                                    </span>
                                    <span className="DropdownMenu__text">{menu.name}</span>
                                </li>
                                {index === 3 ? <li className={dropdownStyles.divider}></li> : null}
                            </>
                        ))}
                    </ul>
                </>
                : (
                    <div className={classnames(contentClass)}>
                        {content}
                    </div>
                )}
        </Fragment>
    );

    return (
        <Tippy
            arrow={true}
            visible={isOpen}
            interactive={true}
            onClickOutside={() => setIsOpen(false)}
            content={dropdownMenu}
            placement={placement}
            theme="light"
            animation="shift-toward"
            className={dropdownStyles.tippy_custom_styles}
        >
            <Tooltip
                content={tooltipContent}
                style={!tooltipContent ? { display: 'none' } : {}}
            >
                {render ? <div onClick={() => setIsOpen((prev) => !prev)}>{render(isOpen)}</div> : (
                    <button
                        onClick={() => setIsOpen((prev) => !prev)}
                        type="button"
                        className={classnames("Button Button--icon w-auto btn-icon Button--iconOnly", dropdownStyles.btn, dropdownStyles.Dropdown_Button)}>
                        <span className="Button__icon d-flex align-items-center gap-1">
                            <>
                                {prefixIcon}
                                {name}
                            </>
                            {suffixIcon}
                        </span>
                    </button>
                )}

            </Tooltip>
        </Tippy>
    )
}

export default Dropdown;