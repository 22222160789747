import * as Yup from 'yup';

const changePassFormValidation = () => Yup.object({

    currentpassword: Yup.string()
               .required('Crrent Password field is required'),
    newpassword: Yup.string()
               .required('New Password field is required')
               .min(6, 'New Password is short - should be 6 chars minimum'),
    confirmpassword: Yup.string()
               .required('Confirm New Password field is required')
               .oneOf([Yup.ref('newpassword'),null], 'Passwords must match')
})

export default changePassFormValidation;