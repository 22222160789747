import React from 'react'
import { Col} from 'react-bootstrap'
import { css } from "@emotion/react";
import PulseLoader from 'react-spinners/PulseLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import BounceLoader from 'react-spinners/BounceLoader';
import ScaleLoader from 'react-spinners/ScaleLoader';


export function CustomSpinner() {
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
   `;

  return (
    <Col style={{width:"50%", margin:"0 auto",textAlign:"center", paddingTop:"3rem"}}>
        <PulseLoader color={'rgba(224, 72, 38, .4)'} loading={true}  size={18} css={override} />
    </Col>
  )
}

export const CustomLoader = () => {
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: gray;
`;
  return (
    <div><ClipLoader  color={'rgba(224, 72, 38, .4)'}  loading={true}  size={18} css={override} /></div>
  )
} 

export const BounceSpinner = () => {
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: gray;
`;
  return (
    <div><BounceLoader  color={'#fff'}  loading={true}  size={50} css={override} /></div>
  )
} 

export const ScaleSpinner = (props:{color:string}) => {
  const override = css`
  display: block;
`;
  return (
    <div><ScaleLoader color={props.color} loading={true} css={override} /></div>
  )
} 
