interface AuthenticationStateType {
    account: {
        user: any | null
    },
    anonymousUser: boolean;
    token: string | null;
    ek_isAuth: boolean;
    ek_joyride: boolean;
}


export const AuthenticationState:AuthenticationStateType = {
    account: {
        user: null
    },
    token: null,
    anonymousUser: true,
    ek_isAuth: false,
    ek_joyride: true,
}

interface LoginStateType {
    redirectPath: string  | null;
}
export const LoginState:LoginStateType = {
    redirectPath: null
}


interface LiveComStateType {
    nickname: string  | null;
    isLive: boolean;
    isLiveLink: string | null;
    welcomeDialog: boolean;
    liveType: string | null;
}

export const LiveComState:LiveComStateType = {
    nickname: null,
    isLive: false,
    isLiveLink: null,
    welcomeDialog: false,
    liveType: null
}


export const SellerDetailState:any = {
    details: {
        sellerInfo: null
    },
}


interface CartInitialStateType {
    cartItems: any[];
    cartTotalQuantity: number;
    cartTotalAmount: number;
    isCart: boolean;
}
export const CartInitialState:CartInitialStateType = {
    cartItems: [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    isCart: false,
}
