import React from "react";
import { Row } from "react-bootstrap";
import {
  LazyLoadComponent,
  trackWindowScroll,
} from "react-lazy-load-image-component";

import { CustomAlert } from "../../../components";
import ProductCardComponent from "../../../components/Product/ProductCardComponent";
import ProductListLoader from "../../Collections/component/SkeletonLoader/ProductListLoader";
import { useAppSelector } from "../../../redux/store/hook";
import { useFetchUserFavoriteItemsQuery } from "../../../redux/store/services/accountApi";
import { useFetchCartItemsQuery } from "../../../redux/store/services/cartApi";
import {
  useFetchJustForYouProductQuery,
} from "../../../redux/store/services/productsApi";
import { mapCartDataReturnQuantity } from "../../../utils";

import "./JustForYouSection.scss";

const JustForYouComponent = (props: any) => {

  const {
    data: justforyou_data,
    isLoading: justforyou_isLoading,
    isFetching: justforyou_isFetching,
    isSuccess: justforyou_isSuccess,
  } = useFetchJustForYouProductQuery();
  // useFetchJustForYouProductQuery

  const { token } = useAppSelector((state) => state.auth.authentication);
  const { data: allCartData } = useFetchCartItemsQuery(token);
  const { data, isSuccess } = useFetchUserFavoriteItemsQuery(token);

  return (
    <>
      <div className="div util-clearfix-content mt-3">
        <Row className="product_vid_commponent p-0 m-0">
          {justforyou_isLoading && justforyou_isFetching ? (
            <ProductListLoader />
          ) : (
            <>
              {justforyou_isSuccess && justforyou_data ? (
                <>
                  {justforyou_data.length > 0
                    ? justforyou_data.map((item: any) => {
                      return (
                        <LazyLoadComponent key={item.id}>
                          <ProductCardComponent
                            productId={item.id}
                            product={item}
                            layoutView={'grid'}
                            cartItem={mapCartDataReturnQuantity(
                              allCartData,
                              item.id,
                            )}
                            isShadow={props.isShadow}
                            productname={item.name}
                            defaultprice={item.price}
                            discount={item.discount}
                            discountPercentage={item.discountPercentage}
                            maxPrice={item.maxPrice}
                            minPrice={item.minPrice}
                            thumbnailUrl={
                              item.imageUrls ? item.imageUrls[0] : ''
                            }
                            imageUrl={item.imageUrls ? item.imageUrls[1] : ''}
                            videoUrl={item.videoUrl}
                            condition={item.condition}
                            savedItems={isSuccess ? data.products : []}
                          />
                        </LazyLoadComponent>
                      );
                    })
                    : null}
                </>
              ) : (
                <>
                  <CustomAlert
                    variant="danger"
                    show={true}
                    message="Unable to load Content"
                  />
                  <ProductListLoader />
                </>
              )}
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default trackWindowScroll(JustForYouComponent);
