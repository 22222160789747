import React, { useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Stack, Tab, Tabs, Form } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import { Helmet } from 'react-helmet'
import { FiChevronLeft, FiInbox } from 'react-icons/fi'
import { BiExport, BiArrowBack, BiImport } from 'react-icons/bi'
import { BsPiggyBank, BsEye } from 'react-icons/bs'
import { AiOutlinePlus } from 'react-icons/ai'
import TableComponent from '../../../components/Dashboard/components/TableComponent'
import DashboardComponent from '../component/DashboardComponent'
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router'
import { useAppSelector } from '../../../redux/store/hook'
import { useFetchAdminSellerDetailsQuery } from '../../../redux/store/services/adminSellerApi'
import OrderSkeletonLoader from '../../../components/SkeletonLoader/Account/OrderSkeletonLoader'

const LiveData = [
    {
        logo: 'Completed',
        name: 'Completed',
        address: 'Completed',
        business_name: 'Completed',
        products: 'Completed',
        status: 'active',
        service_status: 'inactive',
        bank: 'Completed',
    },
    {
        logo: 'Completed',
        name: 'Completed',
        address: 'Completed',
        business_name: 'Completed',
        products: 'Completed',
        status: 'active',
        service_status: 'active',
        bank: 'Completed',
    },
    {
        logo: 'Completed',
        name: 'Completed',
        address: 'Completed',
        business_name: 'Completed',
        products: 'Completed',
        status: 'inactive',
        service_status: 'inactive',
        bank: 'Completed',
    },

]

const LData = [
    {
        order: 'Apple MacBook Pro 13 inch',
        quantity: '5',
        deliverystatus: 'Completed',
        paymentstatus: 'Pending',
        datecreated: '10/02/2022',
        totalamount: 'NGN 1,000'
    },
    {
        order: 'Phone Pro 13 inch',
        quantity: '5',
        deliverystatus: 'Completed',
        paymentstatus: 'Paid',
        datecreated: '1/02/2022',
        totalamount: 'NGN 1,000'
    }
]


export default function DashboardSingleSeller({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }: any) {
    const [lgShow, setLgShow] = useState(false);
    const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const params = useParams()
    const merchantId = params.id
    const result = useFetchAdminSellerDetailsQuery({ merchantId, token })
    const mapData = LiveData.map((items) => ({
        ...items,
        logo: <Image thumbnail={true} />,
        status: <Badge pill bg={items.status == 'active' ? "dark" : "light"} text={items.status == 'active' ? "light" : "dark"}>{items.status}</Badge>,
        service_status: <Form.Switch color='#232F3E' size={24} className="lg" checked={items.service_status == 'active' ? true : false} />,
        bank: <BsPiggyBank size={24} color={'#E04826'} />,
        view: <Link to={'/'}><BsEye size={24} color={'#E04826'} /></Link>
    }))
    const [data, setData] = useState(mapData);

    const [_pageSize, setPageSize] = useState(7);

    const columns = [
        {
            Header: 'Product Name',
            accessor: 'productName',
            sortType: 'basic',
        },

        {
            Header: 'Collection',
            accessor: 'collection',
            sortType: 'basic',
        },
        {
            Header: 'Brand',
            accessor: 'brand',
            sortType: 'basic',
        },
        {
            Header: 'Price',
            accessor: 'price',
            sortType: 'basic',
        },
        {
            Header: 'Quantity',
            accessor: 'quantity',
            sortType: 'basic',
        },
        {
            Header: 'Date',
            accessor: 'date',
            sortType: 'basic',
        },
        {
            Header: 'Status',
            accessor: 'status',
            sortType: 'basic',
        },

    ]

    const navigate = useNavigate()

    const handleViewOrder = (id: string) => {
        setLgShow(true);
        alert(id)
    }

    return (
        <DashboardLayout>
            <Helmet>
                <title>Sellers Profile - Ekenta</title>
                <meta charSet="utf-8" />
                <meta name="title" content="Ekenta Orders Dashboard Page" />
                <meta name="description"
                    content="Ekenta Orders Dashboard  Page"
                />
                <meta property="og:locale" content="en_NG" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>

            <DashboardComponent
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            >
                <Col className=" col-lg-11 mx-auto _listing_product_ dash_top px-2">
                    <div className="mt-0">

                        <div className="display_lg_header" onClick={() => navigate('/admin/sellers')}>
                            {/* <Link to={'/admin/sellers'}> */}
                            <BiArrowBack /> &nbsp;
                            {/* </Link> */}
                            Back
                        </div>

                        {
                            result.isSuccess ?
                                <Row className='mt-5'>
                                    <Col xs lg="4">
                                        <Card className='p-3'>
                                            <h6 className="text-center">Seller Profile</h6>
                                            <Image className='mx-auto' width={'70%'} src={'https://cdn.pixabay.com/photo/2021/08/25/20/42/field-6574455__340.jpg'} />
                                            <p className='text-center text-danger'>Block</p>
                                            <h4>Collections Configuration</h4>
                                            <Row>
                                                <Col lg='8'><p>Can Add Collection</p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={true} /></Col>
                                            </Row>
                                            <p className='text-bold'>Seller Collections</p>
                                            <Row>
                                                <Col lg='8'> <p>Food &amp; Beverages</p></Col>
                                                <Col><Form.Switch color='#232F3E' /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Phone &amp; Tablet </p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={false} /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Food &amp; Beverages</p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={true} /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Computers &amp; Related Items</p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={true} /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Home &amp; Kitchen </p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={true} /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Electronics &amp; accessories</p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={false} /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Children World</p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={true} /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Ekenta Fashion</p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={false} /></Col>
                                            </Row>
                                            <Row>
                                                <Col lg='8'> <p>Vechicle Parts &amp; Accessories</p></Col>
                                                <Col><Form.Switch color='#232F3E' checked={true} /></Col>
                                            </Row>
                                        </Card>

                                    </Col>
                                    <Col xs lg="8">
                                        <Col className=" col-lg-11 mx-auto _listing_product_">
                                            <div className="mt-0">
                                                <Row>
                                                    <Col className="text-center">
                                                        <Card>
                                                            <Card.Body>
                                                                <h3>{result.isSuccess ? result.data.totalProducts : '---'} <br /> Total Products</h3>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col className="text-center">
                                                        <Card>
                                                            <Card.Body>
                                                                <h3>{'---'} <br /> New Products</h3>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col className="text-center">
                                                        <Card>
                                                            <Card.Body>
                                                                <h3>{result.isSuccess ? result.data.totalPublishedProduct : '---'} <br /> Published Products</h3>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Stack direction="horizontal" className='mt-5' gap={3}>
                                                    <h3>Catalog</h3>
                                                    <Button className='ms-auto' variant="danger">Import <BiImport /> </Button>
                                                    <Button variant="outline-danger"><AiOutlinePlus /> Add Products</Button>
                                                </Stack>

                                                <Col className="mt-5 tab__">
                                                    <Card>
                                                        <Card.Body>
                                                            <TableComponent columns={columns} data={result.data.viewSellerProfileDTO.content} pageSizeno={_pageSize} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>

                                : 

                                <OrderSkeletonLoader/>

                        }




                    </div>
                </Col>
            </DashboardComponent>

        </DashboardLayout>
    )
}

