
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createQueryWithTokenRequestByText, createMutationRequestToTextWithoutBody } from '.';
import { BASE_URL } from '../../../constants/index';


const generalApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
});

const createQueryRequest = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json'
    },
    method: method
});

const createQueryWithTokenRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
    url: url,
    headers: generalApiHeaders(token),
    method: method,
});

const createMutationRequestWithoutBody = ({ url, method, token }: { url: string, method: string, token: string }) => {
    return {
        url: url,
        headers: generalApiHeaders(token),
        method: method,
    }
};

const createFormDataMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: {
        "Authorization": `Bearer ${token}`
    },
    method: method,
    body: body
});

export const adminSellerApi: any = createApi({
    reducerPath: 'adminSellerApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['banner', 'Seller', 'Event'],
    endpoints: (builder) => ({
        fetchTotalOrders: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/brand/all`, method: 'GET' })
        }),
        fetchPendingSellers: builder.query<void, { token: string; pageno: number }>({
            query: ({ pageno, token }) => createQueryWithTokenRequest({ url: `/api/admin/fetch-seller-all-pending-registration-request?page=${pageno}`, method: 'GET', token: token })
        }),
        fetchSellerDetails: builder.query<void, { merchantId: string; token: string; }>({
            query: ({ merchantId, token }) => createQueryWithTokenRequest({ url: `/api/admin/get-seller-merchant-business-details/${merchantId}`, method: 'GET', token: token })
        }),
        getSingleSellerDetail: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/seller-merchant-details`, method: 'GET', token: token })
        }),


        approveSellerRequest: builder.mutation<void, { merchantid: string; token: string; }>({
            query: ({ merchantid, token }) => createMutationRequestWithoutBody({ url: `/api/admin/update-seller-registration-request-to-active/${merchantid}`, method: 'PUT', token: token })
        }),
        blockSellerRequest: builder.mutation<void, { merchantid: string; token: string; }>({
            query: ({ merchantid, token }) => createMutationRequestWithoutBody({ url: `/api/admin/update-seller-registration-request-to-blocked/${merchantid}`, method: 'PUT', token: token })
        }),

        fetchAllOrders: builder.query<void, { token: string; status: string }>({
            query: ({ token, status }) => createQueryWithTokenRequest({ url: `/api/admin/get-all-order?orderStatus=${status}`, method: 'GET', token: token })
        }),
        fetchDailyTotalOrder: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-daily-total-order-accounting`, method: 'GET', token: token })
        }),
        fetchAdminDashboard: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/admin-dashboard`, method: 'GET', token: token })
        }),
        fetchAccountingSalesAdmin: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-seller-daily-total-order-accounting`, method: 'GET', token: token })
        }),

        fetchActiveSellers: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-all-seller-approved-registration-request`, method: 'GET', token: token })
        }),
        fetchAdminSellerDetails: builder.query<void, { merchantId: string; token: string; }>({
            query: ({ merchantId, token }) => createQueryWithTokenRequest({ url: `/api/admin/view-seller-profile-product-listing-page/${merchantId}`, method: 'GET', token: token })
        }),

        fetchAllActiveSellers: builder.query<void, { token: string; pageno: number }>({
            query: ({ pageno, token }) => createQueryWithTokenRequest({ url: `/api/admin/get-all-active-seller?page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Seller']
        }),

        fetchAdminAllReturnRequest: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-all-product-return-request`, method: 'GET', token: token })
        }),
        fetchSellerDailyTotal: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/fetch-all-seller-daily-total-order-accounting`, method: 'GET', token: token })
        }),
        fetchAdminSliderImages: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/slide/view-slider-images`, method: 'GET', token: token }),
            providesTags: ['banner'],
        }),
        addNewSliderImage: builder.mutation<any, any>({
            query: ({ data, token, other }) => createFormDataMutationRequest({ url: `/api/admin/slider/image-upload?slideTitle=${other.slideTitle}&slideNumber=${other.slideNumber}`, method: 'POST', body: data, token: token }),
        }),
        fetchAdminVideos: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/view-uploaded-video`, method: 'GET', token: token }),
            providesTags: ['banner'],
        }),
        addNewVideo: builder.mutation<any, any>({
            query: ({ data, other, token }) => createFormDataMutationRequest({ url: `/api/admin/video/upload?videoTitle=${other.videoTitle}&videoNumber=${other.videoNumber}`, method: 'POST', body: data, token: token })
        }),
        updateSellerProfile: builder.mutation<any, any>({
            query: ({ data, merchantOwnerId, token }) => createFormDataMutationRequest({ url: `/api/admin/seller/update-profile/${merchantOwnerId}`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Seller']
        }),
        fetchAllUsers: builder.query<void, { token: string, pageno: string }>({
            query: ({ token, pageno }) => createQueryWithTokenRequest({ url: `/api/users/managed-users?page=${pageno}`, method: 'GET', token: token })
        }),
        fetchAllVerifiedUsers: builder.query<void, { token: string, pageno: string }>({
            query: ({ token, pageno }) => createQueryWithTokenRequest({ url: `/api/users/managed-verified-users?page=${pageno}`, method: 'GET', token: token })
        }),
        fetchPayoutRequest: builder.query<void, { token: string }>({
            query: ({ token }) => createQueryWithTokenRequest({ url: `/api/admin/payout-request`, method: 'GET', token: token })
        }),
        flagToggle: builder.mutation<void, { productId: string; token: string; }>({
            query: ({ productId, token }) =>
            ({
                url: `/api/admin/product/flag/${productId}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                method: 'PUT',
            }),
        }),

        fetchAverageRevenue: builder.query<void, { token: string, from: any, to: any }>({
            query: ({ from, to, token }) => createQueryWithTokenRequestByText({ url: `/api/admin/user/average-revenue?from=${from}&to=${to}`, method: 'GET', token: token })
        }),
        getTotalUserChurnRate: builder.query<void, { token: string, from: any, to: any }>({
            query: ({ from, to, token }) => createQueryWithTokenRequestByText({ url: `/api/admin/user/churn-rate?from=${from}&to=${to}`, method: 'GET', token: token })
        }),

        createEvent: builder.mutation<void, { data: { name: string }; token: string; }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/admin/event/new`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Event']
        }),

        fetchAllEvent: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/event/all`, method: 'GET', token: token }),
            providesTags: ['Event'],
        }),
        fetchAllCollection: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/all/activated-collection`, method: 'GET', token: token }),
        }),

        toggleEvent: builder.mutation<void, { id: string; token: string; }>({
            query: ({ id, token }) => createMutationRequestWithoutBody({ url: `/api/admin/event/toggle/${id}`, method: 'PUT', token: token }),
            invalidatesTags: ['Event']
        }),
        setDiscountToAllProduct: builder.mutation<void, { categoryId: string; value: string, token: string; }>({
            query: ({ categoryId, value, token }) => createMutationRequestToTextWithoutBody({ url: `/api/admin/activate-discount-for-all-product-by-category/${categoryId}?percentageValue=${value}`, method: 'PUT', token: token }),
        }),
        fetchSingleEvent: builder.query<void, { id: string; token: string; }>({
            query: ({ id, token }) => createQueryWithTokenRequest({ url: `/api/event?id=${id}`, method: 'GET', token: token }),
            providesTags: ['Event']
        }),

        updateEkeMarketActivation: builder.mutation<void, { collectionId: string; token: string; }>({
            query: ({ collectionId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/toggle-ekemarket-activation/collection/${collectionId}`, method: 'PUT', token: token })
        }),

        setOrderToPaid: builder.mutation<void, { orderId: string; token: string; }>({
            query: ({ orderId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/pay-on-delivery-update/${orderId}`, method: 'PUT', token: token }),
        }),

        // /api/admin/event/toggle/{id}

        // /api/seller-merchant-details
        // /api/admin/fetch-all-product-return-request
        // /api/admin/video/upload

        // addNewProduct: builder.mutation<any, any>({
        //     query: ({data,token}) => createMutationRequest({url:'/api/product/new',method:'POST', body:data,token:token})    
        // }),

        // addNewProductVideo: builder.mutation<any, any>({
        //     query: ({data,productId,token}) => createFormDataMutationRequest({url:`/api/product/video/${productId}`,method:'POST', body:data,token:token})     
        // }),
        // addNewMultipleProductImages: builder.mutation<any, any>({
        //     query: ({data,productId,token}) => createFormDataMutationRequest({url:`/api/product/images/${productId}`,method:'POST', body:data,token:token})     
        // }),
    })
})


export const {
    useFetchPendingSellersQuery,
    useFetchAllOrdersQuery,
    useFetchSellerDetailsQuery,
    useApproveSellerRequestMutation,
    useBlockSellerRequestMutation,
    useFetchDailyTotalOrderQuery,
    useFetchAdminDashboardQuery,
    useFetchAccountingSalesAdminQuery,
    useFetchActiveSellersQuery,
    useFetchAllActiveSellersQuery,
    useFetchAdminSellerDetailsQuery,
    useFetchAdminAllReturnRequestQuery,
    useFetchAdminSliderImagesQuery,
    useAddNewSliderImageMutation,
    useFetchSellerDailyTotalQuery,
    useFetchAdminVideosQuery,
    useGetSingleSellerDetailQuery,
    useAddNewVideoMutation,
    useUpdateSellerProfileMutation,
    useFlagToggleMutation,
    useFetchAllUsersQuery,
    useFetchAllVerifiedUsersQuery,
    useFetchPayoutRequestQuery,
    useFetchAllEventQuery,
    useFetchSingleEventQuery,
    useFetchAllCollectionQuery,

    useFetchAverageRevenueQuery,
    useGetTotalUserChurnRateQuery,
    useUpdateEkeMarketActivationMutation,
    useCreateEventMutation,
    useToggleEventMutation,
    useSetDiscountToAllProductMutation,
    useSetOrderToPaidMutation
} = adminSellerApi;