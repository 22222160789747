import React from 'react';
import { Col, Button, Form, Row, Spinner } from 'react-bootstrap';
import { VscArrowSmallLeft, VscArrowSmallRight } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { SellerOnboardFormikFormType } from '../../../../../interfaces/sellerOnboarding.interface';

export default function Summary({ handleChange, handleBlur, values, errors, touched, isLoading, prevPage }: SellerOnboardFormikFormType) {
  const covertAccountNumber = (acc_number: any) => {
    return acc_number ? acc_number.toString().replace(/\d(?=\d{4})/g, "*") : '......';
  }

  console.log(values.business_detail)


  return (
    <div className="seller_account_details_form_col">
      <div className="_top_header mt-1">
        <h5>All Summary</h5>
      </div>
      <Col lg={8} className="mx-auto _col_container">

        <div className="mt-4">
          <div className="group__wrapp_ mb-2">
            <h5>Personal Details</h5>
            <div className="list__table_">
              <dl className="dl__tb">
                <dt>First Name :</dt>
                <dd>{values.personal_detail?.firstname != '' ? values.personal_detail.firstname : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Last Name :</dt>
                <dd>{values.personal_detail?.lastname != '' ? values.personal_detail.lastname : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>User Name :</dt>
                <dd>{values.personal_detail?.username != '' ? values.personal_detail.username : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Phone Number :</dt>
                <dd>{values.personal_detail?.phonenumber != '' ? values.personal_detail.phonenumber : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Email Address :</dt>
                <dd>{values.personal_detail?.email != '' ? values.personal_detail.email : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Password :</dt>
                <dd>{values.personal_detail?.password != '' ? '************' : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Referred by :</dt>
                <dd>{values.personal_detail?.referedby != '' ? values.personal_detail.referedby : '......'}</dd>
              </dl>
            </div>
          </div>
          <div className="group__wrapp_">
            <h5>Business Details</h5>
            <div className="list__table_">
              <dl className="dl__tb">
                <dt>Seller Name :</dt>
                <dd>{values.business_detail?.businessname != '' ? values.business_detail?.businessname : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Address :</dt>
                <dd>{values.business_detail?.address != '' ? values.business_detail?.address : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>State:</dt>
                <dd>{values.business_detail?.state != '' ? values.business_detail?.state.name : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>City/Town :</dt>
                <dd>{values.business_detail?.city_or_town != '' ? values.business_detail?.city_or_town.name : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Country :</dt>
                <dd>{values.business_detail?.country != '' ? values.business_detail?.country : '......'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Business Phone Number :</dt>
                <dd>{values.business_detail?.business_phone_number != '' ? values.business_detail?.business_phone_number : 'empty'}</dd>
              </dl>
              <dl className="dl__tb">
                <dt>Business Registration Number:</dt>
                <dd>{values.business_detail?.business_registration_number != '' ? values.business_detail?.business_registration_number : '......'}</dd>

              </dl>

            </div>
          </div>
          <div className="group__wrapp_">
            <h5>Bank Details</h5>
            <div>
              <div className="list__table_">
                <dl className="dl__tb">
                  <dt>Bank :</dt>
                  <dd>{values.bank_detail?.bank != '' ? values.bank_detail?.bank : '......'}</dd>
                </dl>
                <dl className="dl__tb">
                  <dt>Account Name :</dt>
                  <dd>{values.bank_detail?.account_name != '' ? values.bank_detail?.account_name : '......'}</dd>
                </dl>
                <dl className="dl__tb">
                  <dt>Account Number:</dt>
                  <dd>{covertAccountNumber(values.bank_detail?.account_number)}</dd>
                </dl>
                {/* <dl className="dl__tb">
                      <dt>Bank Code:</dt>
                      <dd>{values.bank_detail?.bank_code != '' ? values.bank_detail?.bank_code : '......'}</dd>
                    </dl> */}
              </div>
            </div>
          </div>
        </div>
        <Col>
          <Form.Group as={Row} className="mb-1 ms-2 position-relative">
            <Form.Check type={'checkbox'} id={`check`}>
              <Form.Check.Input
                color="secondary"
                required
                name="acceptTermsAndConditions"
                type={'checkbox'}
                checked={values.acceptTermsAndConditions}
                onBlur={handleBlur}
                onChange={handleChange} />
              <Form.Check.Label>
                <span>I agree to the </span>
                <Link to={''} className="me-1">Privacy policy & </Link>
                <Link to={''}>Terms and Conditions</Link>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
          {touched.acceptTermsAndConditions && errors.acceptTermsAndConditions ? (
            <Form.Control.Feedback type="invalid">
              {errors.acceptTermsAndConditions}
            </Form.Control.Feedback>
          ) : null}
        </Col>
        <div className="_stepper_trigger_col">
          <Button className="_button_A_ me-1" onClick={prevPage}>
            <VscArrowSmallLeft />
            <span>Back</span>
          </Button>
          <Button className={`_button_A_`}
            type='submit' disabled={!values.acceptTermsAndConditions || isLoading}>
            {isLoading ? (<div className="d-flex align-items-center justify-content-center">
              <Spinner size="sm" animation="border" variant="white" />
              <span className="ms-1 text-sm"><small>Loading..</small></span>
            </div>) :
              (
                <>
                  <span>Start Selling</span>
                  <VscArrowSmallRight />
                </>
              )
            }

          </Button>
        </div>
      </Col>
    </div>
  )
}
