import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

interface RoundButtonPropsType {
  isLoading?: boolean;
  text:string;
  className?: string;
  onClick?: () => void;

}
export default function RoundButton({isLoading,text,className,onClick}:RoundButtonPropsType) {
  return (
    <Button className={`${className} _pq ${isLoading  ? 'disabled':''}`} 
        onClick={onClick}>
        {isLoading ? (<Spinner size="sm" animation="border" variant="white" />) : (
            <>
               <span className="mr2">{text}</span>
            </>
        )}
        </Button>
  )
}
