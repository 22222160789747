import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as queryString from "query-string";
import { FiShare } from "react-icons/fi";
import styled from "styled-components";
import withDefaultLayout from "@ekenta/hoc/Layout/withDefaultLayout";
import { HelmetHeader } from "@ekenta/components/HelmetHeader/HelmetHeader";

import shopLogo from "@ekenta/assets/images/shoplogo.webp";

import styles from "./SellerShop.module.scss";
import { Button, FollowButton, SocialShare, Typography } from "@ekenta/components";
import { useGetSingleMerchantQuery } from "@ekenta/redux/store/services/shopApi"
import { SearchFilterProvider } from "@ekenta/context/SearchFilterContext";
import SellerShopGrid from "./common/SellerShopGrid";
import ShopFilterSort from "./common/ShopFilterSort";

const SellerShop = () => {

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  // get the query params from the url
  const query = queryString.parse(window.location.search);
  const _page = query.page;
  const [pageno, setPageno] = useState<any>(0);

  const [show, setShow] = useState(false);
  const [count, setCount] = useState<any>(null);


  const id = params ? params.id : null;
  const getMerchant = useGetSingleMerchantQuery(id, { skip: !id });



  useEffect(() => {
    setTimeout(() => {
      if (_page) {
        const _newPage = parseInt(_page.toString());
        if (_newPage > 0) {
          setPageno(_newPage - 1);
        } else {
          navigate(`${pathname}?page=1&sortType=default`);
        }
      } else {
        setPageno(0);
      }
    }, 1000);
  }, [_page]);

  // const scrollToTop = () => {
  //   animateScroll.scrollToTop();
  // };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  function getSellerShopUrl() {
    return `${window.location.origin}/seller/shop/${params.id}`;
  }


  return (
    <Fragment>
      <HelmetHeader
        title={"Seller Shop"}
        description="Ekenta Seller Shop Page"
      />

      <SearchFilterProvider>
        <div className={styles.ShopContainer}>
          <div className={styles.ShopBanner_Wrapper}>
            <div className={styles.ShopBanner_Container}>
              <div className={styles.ShopAvatar_Div}>
                <div className={styles.ShopAvatar_Wrapper}>
                  <LazyLoadImage
                    src={shopLogo}
                    alt=""
                    className="LazyImage"
                    effect="blur"
                  />
                </div>
              </div>
            </div>
          </div>
          <section className={styles.ShopContent}>
            <div className={styles.ShopContent__Sub}>
              <div className={styles.ShopDetails}>
                {getMerchant?.isLoading ? "Loading" : null}
                {getMerchant?.isSuccess ? (
                  <>
                    <Typography
                      as="h3"
                      color="dark"
                      size="medium"
                      className={styles.ShopDetails_Title}
                      transform="capitalize"
                      heading>
                      {getMerchant?.data.merchantBusiness.businessName}
                    </Typography>
                    <Typography
                      as="p"
                      color="dark"
                      size="small"
                      className={styles.ShopDetails_Para}
                      uiText>
                      <span>{
                        count ? count :
                          getMerchant?.data.totalFollowers
                      }
                      </span>
                      <span>Follower</span>
                    </Typography>
                  </>
                ) : null}
              </div>
              <div className={styles.ShopOptions}>
                <ul>
                  <li>
                    {getMerchant?.isLoading ? "Loading" : null}
                    {getMerchant?.isSuccess ? (
                      <FollowButton
                        sellerId={
                          getMerchant?.data.merchantBusiness.id
                        }
                        count={getMerchant?.data.totalFollowers}
                        inline={true}
                        callback={(value) => setCount(value)}
                      />
                    ) : null}
                  </li>
                  <li>
                    <Button
                      name="Share"
                      bgColor="#f1f1f2"
                      prefixIcon={<FiShare />}
                      shadow={false}
                      onClick={handleShow}
                    />
                  </li>
                </ul>
              </div>
              <div className={styles.BottomDivider}>
                <br />
                <hr className={styles.Hr_Line} />
              </div>
              <ShopFilterSort />
            </div>
          </section>

          <section className={styles.ShopMain_Bar}>
            <SellerShopGrid getMerchant={getMerchant} />
          </section>
        </div>
      </SearchFilterProvider>

      <Modal
        className="modal_component shadow"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SocialShare
            url={getSellerShopUrl()}
            title={""}
          />
        </Modal.Body>
      </Modal>


    </Fragment>
  );
}




export default withDefaultLayout(SellerShop);
