
import { useEffect } from "react";
import withRouter from "@ekenta/hooks/withRouter";


const ScrollTop = ({ children, pathname }: { children?: React.ReactNode; pathname?: any }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [pathname]);

    return children || null;
};

export default withRouter(ScrollTop);