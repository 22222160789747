import React from 'react';
import { useFetchUserAllOrderQuery } from '../../../../../redux/store/services/accountApi';
import OrderSkeletonLoader from '../../../../../components/SkeletonLoader/Account/OrderSkeletonLoader';
import EmptyOrder from '../../../../../components/Box/EmptyOrder';
import SingleOrderCard from './SingleOrderCard';
import { Accordion } from 'react-bootstrap';
import MainOrder from './mainOrder';

interface ActiveOrderPropsType {
  token: string | null;
}

export default function ActiveOrder({ token }: ActiveOrderPropsType) {
  const { data, isLoading, isFetching, isError, isSuccess } =
    useFetchUserAllOrderQuery(token, { skip: !token });
  // const {data, isLoading, isFetching, isError, isSuccess}  = useFetchUserActiveOrderQuery(token, {skip: !token});
  // useFetchUserAllOrderQuery
  const recalibrate = (data: any) => {
    const newArr = [];
    for (let i = data.length - 1; i >= 0; i--) {
      if (
        data[i]?.orderStatus == 'PROCESSING' ||
        data[i]?.orderStatus == 'PENDING'
      ) {
        newArr.push(data[i]);
      }
    }
    return newArr;
  };

  return (
    <div className="w-100" style={{ marginBottom: '100px' }}>
      {isLoading || isFetching ? (
        <OrderSkeletonLoader />
      ) : (
        <>
          {isError ? <>We could not fetch this at this moment</> : null}
          <Accordion>
            {isSuccess && data ? (
              recalibrate(data).length > 0 ? (
                recalibrate(data).map((item: any, i: any) => {
                  if (
                    item?.orderStatus == 'PROCESSING' ||
                    item?.orderStatus == 'PENDING'
                  ) {
                    return (
                      <MainOrder key={i} itemData={item} />
                      //  <CardOrder
                      //    key={item.id}
                      //    orderId={item.id}
                      //    thumbnailUrl={item.productImgUrl}
                      //    name={item.productName}
                      //    quantity={item.quantity}
                      //    orderStatus={item.orderStatus}
                      //    date={item.createdDate}
                      //    orderNumber={item.orderNumber} isPending={true}/>
                    );
                  } else {
                    null;
                  }
                })
              ) : (
                <EmptyOrder orderType="active" />
              )
            ) : null}
          </Accordion>
        </>
      )}
    </div>
  );
}
