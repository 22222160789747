import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Col, Container, Form, Button, Row, Spinner } from 'react-bootstrap';
import { returnRequestPathUrl } from '../../../routes/constant';
import { Helmet } from 'react-helmet'
import ProfileSideBar from './component/AccountSideBar'
import DefaultLayout from '../../../hoc/Layout/Default-Layout'
import BreadCrumb from './component/BreadCrumb';
import { useAddNewReturnRequestMutation } from '../../../redux/store/services/accountApi';
import { useAppSelector } from '../../../redux/store/hook';
import { toast } from 'react-toastify';

export default function NewReturnRequest() {
    const navigate = useNavigate();
    const { token, account } = useAppSelector(state => state.auth.authentication);
    const [details, setDetails] = useState({
        productReturnAction: '',
        productReturnReason: '',
        comment: '',
        id: '',
        customerEmail: account.user.email,
        orderItemId: ''
    })
    const [addNewReturnRequest, { data: addproduct_data, isLoading: addproduct_isLoading, isError: addproduct_isError, error,
        isSuccess: addproduct_isSuccess }] = useAddNewReturnRequestMutation();

    const [isloading, setIsLoading] = useState(false)

    const handleSubmit = ()=> {
        if(details.orderItemId == null || details.orderItemId == ''){
            toast.error(`Item not selected`, {
                toastId: 'wrong-password-id-toast-error',
            });
        }
        // useAddNewReturnRequestMutation({data, token, })
    }

    return (
        <DefaultLayout>
            <Helmet>
                <title>New Return Request - Ekenta</title>
                <meta charSet="utf-8" />
                <meta name="title" content="Ekenta Ureturn request" />
                <meta name="description"
                    content="Ekenta return request"
                />
                <meta property="og:locale" content="en_NG" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>
            <Container className="account-user-container ">
                <><BreadCrumb activePage="New Return Request" /></>

                <Row className="account-pro-row g-2">
                    <Col xs lg={3} className="d-none d-lg-block account-col-left">
                        <ProfileSideBar isLoading={null} />
                    </Col>
                    <Col className="account-profile-col-right px-0">
                        <div className="border-bottom">
                            <h5 className="_display-header d-flex align-items-center">
                                <a onClick={() => navigate(`${returnRequestPathUrl}`)}>
                                    <svg className="" width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0303 8.53033C11.3232 8.23744 11.3232 7.76256 11.0303 7.46967C10.7374 7.17678 10.2626 7.17678 9.96967 7.46967L5.96967 11.4697C5.82322 11.6161 5.75 11.8081 5.75 12C5.75 12.1017 5.77024 12.1987 5.80691 12.2871C5.84351 12.3755 5.89776 12.4584 5.96967 12.5303L9.96967 16.5303C10.2626 16.8232 10.7374 16.8232 11.0303 16.5303C11.3232 16.2374 11.3232 15.7626 11.0303 15.4697L8.31066 12.75H18C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25H8.31066L11.0303 8.53033Z" fill="black" />
                                    </svg>
                                </a>
                                <span>New Return Request Form</span>
                            </h5>
                        </div>
                        <Col lg={10} className="new_return__req pt-2 bg-white">
                            <div className="text-center _heading_tit"><h5>Which item do you want to return?</h5></div>
                            <div className="list_of_product"></div>
                            <div className="text-center _heading_tit mb-4 mt-3"><h5>Why are you returning these items?</h5></div>
                            <Col lg={11} className="mx-auto _col__product">
                                <Form>
                                    <Form.Group className="mb-4" as={Row}>
                                        <Form.Label column sm="2">
                                            Return reason:
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Select aria-label="Default select" onChange={(e) => setDetails({ ...details, productReturnReason: e.target.value })}>
                                                <option value="Recieved Wrong Product">Recieved Wrong Product</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group className="mb-4" as={Row}>
                                        <Form.Label column sm="2">
                                            Return Action:
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Select aria-label="Default select" onChange={(e) => setDetails({ ...details, productReturnAction: e.target.value })}>
                                                <option value="Repair">Repair</option>
                                                <option value="Change">Change</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-4" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label column sm="2">Comment:</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control as="textarea" rows={7} onChange={(e) => setDetails({ ...details, comment: e.target.value })} />
                                        </Col>
                                    </Form.Group>
                                    <Button className="mb-3 mt-3 text-uppercase w-100 text-white" onClick={handleSubmit}>
                                        {
                                            !isloading ?
                                                'Submmit Return Requests' :
                                                (<div className="d-flex align-items-center justify-content-center">
                                                    <Spinner size="sm" animation="border" variant="white" />
                                                    <span className="ms-1 text-sm"><small>Loading..</small></span>
                                                </div>)
                                        }
                                    </Button>
                                </Form>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </DefaultLayout>
    )
}