import React, { useState } from 'react';
import { Card, Col,Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchAdmniAllOrdersQuery,
} from '../../../redux/store/services/vendorProductApi';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import {
  useFetchAccountingSalesAdminQuery,
  useFetchDailyTotalOrderQuery,
  useFetchSellerDailyTotalQuery,
} from '../../../redux/store/services/adminSellerApi';
import { MDBDataTable } from 'mdbreact';
import OrderSkeletonLoader from '../../../components/SkeletonLoader/Account/OrderSkeletonLoader';

const LiveData = [
  {
    order: 'Apple MacBook Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Pending',
    datecreated: '10/02/2022',
    totalamount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
];

const LData = [
  {
    orderId: '10028',
    customer: 'Customer',
    seller: 'seller',
    deliverystatus: 'Completed',
    promoCodeDiscount: 'promoCodeDiscount',
    paymentstatus: 'Pending',
    date: '10/02/2022',
    subTotalAmount: 'NGN 1,000',
  },
  {
    order: 'Phone Pro 13 inch',
    quantity: '5',
    deliverystatus: 'Completed',
    paymentstatus: 'Paid',
    datecreated: '1/02/2022',
    totalamount: 'NGN 1,000',
  },
];

const Tdata = {
  columns: [
    // {
    //     label: 'Order',
    //     field: 'orderId',
    //     sort: 'asc',
    //     Header: 'Order',
    //     accessor: 'orderId',
    //     sortType: 'basic',
    // },
    {
      label: 'Seller Name',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Order Value',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Delivery Fees',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Admin Commission',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Promo(Seller)',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Promo(Admin)',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Payment(Gateway)',
      field: 'seller',
      sortType: 'basic',
    },
    {
      label: 'Cash collected',
      field: 'date',
      sortType: 'basic',
    },

    {
      label: 'Seller earning',
      field: 'customer',
      sortType: 'basic',
    },
  ],
  rows: [
    {
      customer: 'Tiger Nixon',
      seller: 'System Architect',
      adminCommission: 'Edinburgh',
      orderId: '61',
      date: '2011/04/25',
      subTotalAmount: '$320',
      promoCodeDiscount: '10202',
      finalTotal: 'ahah',
    },
    {
      customer: 'Tiger Nixon',
      seller: 'System Architect',
      adminCommission: 'Edinburgh',
      orderId: '61',
      date: '2011/04/25',
      subTotalAmount: '$320',
      promoCodeDiscount: '10202',
      finalTotal: 'ahah',
    },
  ],
};
export default function AdminAccountingSellers({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const allOrders = useFetchDailyTotalOrderQuery(token);
  const ord = useFetchAccountingSalesAdminQuery(token);
  const acccounting = useFetchSellerDailyTotalQuery(token);
  // console.log(allOrders)
  // const result = useFetchAllOrdersQuery(token)
  // const completed = useFetchCompletedOrdersQuery(token)
  // const pending = useFetchPendingOrdersQuery(token)
  // const cancelled = useFetchCancelledOrdersQuery(token)
  // const processing = useFetchProcessingOrdersQuery(token)

  const mapData = LiveData.map((items) => ({
    ...items,
  }));

  const recalibrate = (data: any) => {
    Tdata.rows = data;
    return Tdata;
  };

  const [data, setData] = useState(mapData);

  const [_pageSize, setPageSize] = useState(7);

  const columns = [
    {
      Header: 'Seller Name',
      accessor: 'orderId',
      sortType: 'basic',
    },

    {
      Header: 'Date',
      accessor: 'date',
      sortType: 'basic',
    },
    {
      Header: 'Customer',
      accessor: 'customer',
      sortType: 'basic',
    },
    {
      Header: 'Seller',
      accessor: 'seller',
      sortType: 'basic',
    },
    {
      Header: 'Subtotal Amount',
      accessor: 'subTotalAmount',
      sortType: 'basic',
    },
    {
      Header: 'Promo Code Discount',
      accessor: 'promoCodeDiscount',
      sortType: 'basic',
    },
    {
      Header: 'Admin Comm',
      accessor: 'adminCommission',
      sortType: 'basic',
    },
    {
      Header: 'Final Total',
      accessor: 'finalTotal',
      sortType: 'basic',
    },
  ];

  const handleViewOrder = (id: string) => {
    setLgShow(true);
    alert(id);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Admin Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Orders Dashboard Page" />
        <meta name="description" content="Ekenta Orders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">
              {/* <FiChevronLeft /> */}
              Accounting Sellers
            </h3>
            <hr className="mt-1" />
            <Row className="">
              <Col md={4}>
                ₦
                {new Intl.NumberFormat().format(
                  !acccounting.isSuccess
                    ? '---'
                    : acccounting.data.totalOrderValues,
                )}{' '}
                <br /> Total Orders Value
              </Col>
              <Col md={4}>
                ₦
                {new Intl.NumberFormat().format(
                  !acccounting.isSuccess
                    ? '---'
                    : acccounting.data.totalDeliveryFees,
                )}{' '}
                <br /> Total Delivery Fees
              </Col>
              <Col md={4}>
                ₦
                {new Intl.NumberFormat().format(
                  !acccounting.isSuccess
                    ? '---'
                    : acccounting.data.totalAdminCommission,
                )}{' '}
                <br /> Total Admin Commission{' '}
              </Col>
              {/* <Col md={3}>₦{new Intl.NumberFormat().format(!allOrders.isSuccess ? "---" : allOrders.data.totalDeliveryFees)} <br /> Total Delivery Fees</Col> */}
            </Row>
            <hr />
            <Col className="mt-5">
              <Card>
                <Card.Body>
                  {acccounting.isSuccess ? (
                    acccounting.data.orders.content.length > 0 ? (
                      <MDBDataTable
                        striped
                        bordered
                        hover
                        responsive={true}
                        searchLabel="Search"
                        exportToCSV
                        data={recalibrate(acccounting.data.orders.content)}
                      />
                    ) : (
                      <EmptyOrder orderType="" />
                    )
                  ) : (
                    <OrderSkeletonLoader />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
