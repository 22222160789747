import React from 'react'
import { Button, Dropdown } from '@ekenta/components';
import classnames from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import styles from "../../SellerShop.module.scss";

const Sort = () => {
    const handler = (key: string, name: string) => {
        console.log(key, name)
    }


    const sortMenu = [
        {
            name: 'Default',
            icon: '',
            active: false,
            key: 'default',
            handler: () => handler('default', 'Default')
        },
        {
            name: 'Popular',
            icon: '', active: false,
            key: 'popular',
        },
        {
            name: 'Used',
            icon: '',
            active: false,
            key: 'used',
        },
        {
            name: 'Newest',
            icon: '',
            active: false,
            key: 'newest',
        },
        {
            name: 'Lowest',
            icon: '', active: false,
            key: 'lowest',
        },
        {
            name: 'Highest',
            icon: '',
            active: false,
            key: 'highest',
        },
    ];

    return (
        <Dropdown
            placement="bottom"
            contentClass={styles.FilterContent}
            menus={sortMenu}
            tooltipContent={""}
            render={(isOpen) => (
                <Button
                    name="Default"
                    bgColor="#f1f1f2"
                    className={styles.Button}
                    suffixIcon={<FiChevronDown className={classnames(isOpen ? styles.Icon_Up : styles.Icon_Down)} fontSize="1rem" style={{ marginTop: "3px" }} />}
                    shadow={false}
                    onClick={() => console.log('mee')}
                />
            )
            }
        />
    )
}

export default Sort