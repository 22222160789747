import React,{useContext} from 'react'
import {Offcanvas,Col } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { TriggerSideOffCanvasContext } from '../../../context/TriggerSideOffCanvasContext';
import { useFetchAllCollectionsQuery } from '../../../redux/store/services/collectionApi';
import CollectionGroupItem from './components/CollectionGroupItem';
import {CustomSpinner} from '../../Spinner/CustomSpinner';
import { Link } from 'react-router-dom';
import { collectionPathUrl } from '../../../routes/constant';
import { FiChevronRight } from 'react-icons/fi';
import { StoreIcon } from '../../icons/icons';


export default function CollectionsOffCanvas() {
  const { offCanvas,handleOnHideCanvas } = useContext(TriggerSideOffCanvasContext);
  const {data, isLoading, isError, isSuccess} = useFetchAllCollectionsQuery();
  
  return (
      <Offcanvas closeButton={true} scroll={true} backdrop={true} show={offCanvas.show} placement={offCanvas.placement} onHide={handleOnHideCanvas}>
         
          <Offcanvas.Header className="d-none d-lg-flex" closeVariant='white' closeButton>
            <Link  to={collectionPathUrl} style={{color:"#fff", textDecoration:"none"}} onClick={handleOnHideCanvas}>
              <Offcanvas.Title>
                <span className="d-flex align-items-center" onClick={handleOnHideCanvas}>
                <span className="ms-2">Collections</span>
             </span>
                </Offcanvas.Title> 
            </Link>
          </Offcanvas.Header>

          {/* <Scrollbars 
            className="offcanvas_bd"
            style={{width: "100%"}}> */} 
            <Offcanvas.Body className="offcanvas_bd">
              
              {isLoading ?  <CustomSpinner />: null }
              {isSuccess ?  <CollectionGroupItem data={data} closeHandler={handleOnHideCanvas}  /> : null}
              {isError ? <CustomSpinner />: null}
            </Offcanvas.Body>
          {/* </Scrollbars> */}
      </Offcanvas>
  )
}
