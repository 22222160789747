import React, {
  useEffect, memo, useState, useCallback,
} from "react";
import {
  Card, Col, ListGroup, Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import axios from "axios";
import { useAppSelector } from "../../../redux/store/hook";
import {
  useFlutterInitiateTransactionMutation,
  useFlutterPaymentTransactionQuery,
  useInitiateTransactionMutation
} from "@ekenta/redux/store/services/checkoutApi";
import { PAYSTACK_AUTHORIZATION_KEY } from "@ekenta/constants";
import Header from "./Header/Header";
import { Loader, Overlay } from "@ekenta/components";
import { PaystackHookButton, PayOnDeliveryHookButton, FlutterwaveHookButton } from "./PaymentButtons";
import { checkoutCallbackPagePathUrl, ordersPathUrl } from "@ekenta/routes/constant";
import { useNavigate } from "react-router-dom";

interface ReviewOrderPropType {
  orderSuccess: boolean;
  isLoading: boolean;
  orderData: any;
  values: any;
  paymentOptionEnums: {
    PAYSTACK: string;
    FLUTTERWAVE: string;
    POD: string;
  }
}

function ReviewOrder({ orderSuccess, orderData, values, paymentOptionEnums, isLoading }: ReviewOrderPropType) {
  const navigate = useNavigate();
  const { token } = useAppSelector((state) => state.auth.authentication);

  const [transactionId, setTransactionId] = useState(null);

  const [flutterInitiateTransaction, flutterResult] = useFlutterInitiateTransactionMutation();
  const flutterVerify = useFlutterPaymentTransactionQuery({ transactionId, token }, {
    skip: !transactionId,
  });

  const [payStackData, setPayStackData] = useState<{
    reference: string
    checkoutUrl: string;
  }>({
    reference: "",
    checkoutUrl: "",
  });

  const [Loading, setLoading] = useState(false);

  const [initiateTransaction, {
    isLoading: initial_isLoading,
    isError: initial_isError,
    isSuccess: initial_isSuccess,
  }] = useInitiateTransactionMutation();

  useEffect(() => {
    if (initial_isError) {
      toast.error("Initializing Payment Failed", {
        toastId: "error-verification-id-toast-error",
      });
    }
  }, [
    initial_isError,
  ]);

  useEffect(() => {
    (async () => {
      if (orderSuccess && !payStackData.reference &&
        values.paymentChannel === paymentOptionEnums.PAYSTACK) {
        const _data = {
          email: orderData.user.email,
          amount: parseInt(`${orderData.totalOrderAmount}00`),
        };
        initializePaystack(_data);
      }
    })();

    if (orderSuccess && payStackData.reference &&
      values.paymentChannel === paymentOptionEnums.PAYSTACK) {
      const orderId = orderData.id;
      _initiateTransaction(
        orderId,
        payStackData.reference,
        token,
      );
    }
  }, [
    orderSuccess,
    payStackData.reference,
  ]);

  useEffect(() => {
    if (initial_isSuccess) {
      setLoading(false);
    }
  }, [
    initial_isSuccess,
  ]);

  const _initiateTransaction = useCallback((orderId, reference, token) => {
    initiateTransaction({
      orderId,
      reference,
      token,
    });
  }, []);

  // PayStack Payment Initializing

  const initializePaystack = useCallback((data: { email: string, amount: number }) => {
    setLoading(true);
    axios({
      url: "https://api.paystack.co/transaction/initialize",
      method: "POST",
      headers: {
        Authorization: `Bearer ${PAYSTACK_AUTHORIZATION_KEY || ""}`,
        "Content-Type": "application/json",
      },
      data,
    })
      .then((response) => {
        setPayStackData({
          reference: response.data.data.reference,
          checkoutUrl: response.data.data.authorization_url,
        });
      })
      .catch(() => {
        toast.error("Generating Reference Failed", {
          toastId: "error-paystack-reference-id-toast-error",
        });
      });
  }, []);


  useEffect(() => {
    if (flutterResult?.isError && flutterResult?.error) {
      toast.error("Initializing Payment Failed", {
        toastId: "error-flutter-initialize-id-toast-error",
      });
    }

    if (flutterResult?.isSuccess) {
      setTransactionId(flutterResult?.data.transactionId)
    }

    if (flutterVerify?.isError && flutterVerify?.error) {
      toast.error("Verifying Payment Failed", {
        toastId: "error-flutter-verifying-id-toast-error",
      });
    }
    if (flutterVerify?.isSuccess) {
      setLoading(false);
      console.log(flutterVerify, "flutterVerify");
      navigate(checkoutCallbackPagePathUrl);
    }
  }, [
    flutterResult?.isSuccess,
    flutterVerify?.isSuccess,
    flutterResult?.isError,
    flutterVerify?.isError,
  ]);

  // Flutterwave Payment Initializing

  const initializeFlutterwave = (response: any) => {
    console.log(response, "response");
    setLoading(true);
    flutterInitiateTransaction({
      orderId: orderData.id,
      transactionId: response.transaction_id,
      reference: response.flw_ref,
      token
    });
  }



  return (
    <>
      <Col className="checkout_payment__option">
        <Card>
          <Card.Body>
            {isLoading ? <Loader large isCenter /> : (
              <>
                <Header
                  title="Review Order"
                  active={orderSuccess}
                  isBorder={!!orderSuccess}
                />
                {orderSuccess && orderData
                  ? (
                    <Col className="D__opt pt-2">

                      <div className="show__order_pay__summary mt-2">
                        <p className="mb-2">Review Order </p>
                        <div className="summary__list__g">
                          <ListGroup>
                            {orderData.orderItems.length > 0
                              ? orderData.orderItems.map((item: any, index: number) => (
                                <ListGroup.Item key={`item_${index}`}>
                                  <div className="item__group_">
                                    <span className="quan__byx">
                                      x
                                      {item.quantity}
                                    </span>
                                    <span className="order__item_name">
                                      {item.product.name}
                                    </span>
                                  </div>
                                  <NumberFormat
                                    className="order__item_price"
                                    thousandSeparator
                                    displayType="text"
                                    decimalSeparator="."
                                    decimalScale={2}
                                    prefix="NGN"
                                    fixedDecimalScale
                                    value={item.price}
                                  />
                                </ListGroup.Item>
                              )) : null}
                          </ListGroup>
                        </div>
                        <div className="all__subt__div">
                          <ListGroup>
                            <ListGroup.Item className="mb-1">
                              <div className="subtotal__p_div flex_flow_div">
                                <span className="sub__total_span total_item_style">Subtotal</span>
                                <NumberFormat
                                  className="sub__tot_price_ total_item_style"
                                  thousandSeparator
                                  displayType="text"
                                  decimalSeparator="."
                                  decimalScale={2}
                                  prefix="NGN"
                                  fixedDecimalScale
                                  value={orderData.subTotal}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="mb-1">
                              <div className="delivery_fee__p_div flex_flow_div mb-1">
                                <span className="delivery_name_span listitem__style">Delivery fee</span>
                                <NumberFormat
                                  className="delivery_price_span listitem__style"
                                  thousandSeparator
                                  displayType="text"
                                  decimalSeparator="."
                                  decimalScale={2}
                                  prefix="NGN"
                                  fixedDecimalScale
                                  value={orderData.deliveryCharge}
                                />
                              </div>
                              <div className="discount_fee__p_div flex_flow_div mt-2">
                                <span className="listitem__style">Discount</span>
                                <NumberFormat
                                  className="delivery_price_span listitem__style"
                                  thousandSeparator
                                  displayType="text"
                                  decimalSeparator="."
                                  decimalScale={2}
                                  prefix="NGN"
                                  fixedDecimalScale
                                  value={0}
                                />
                              </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="mb-1">
                              <div className="total_pay__p_div flex_flow_div">
                                <span className="total_p_n total_item_style">Total</span>
                                <NumberFormat
                                  className="total_p_span total_item_style"
                                  thousandSeparator
                                  displayType="text"
                                  decimalSeparator="."
                                  decimalScale={2}
                                  prefix="NGN"
                                  fixedDecimalScale
                                  value={orderData.totalOrderAmount}
                                />
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        </div>
                      </div>
                      <div className="my-2">
                        {orderSuccess && orderData
                          ? (
                            <>
                              {/* {values.paymentChannel === paymentOptionEnums.FLUTTERWAVE ? (
                                    <FlutterwaveHookButton
                                      text="Confirm Payment"
                                      textPrice={orderData.totalOrderAmount}
                                      email={orderData.user.email}
                                      name={orderData.user.firstName}
                                      phoneNumber={orderData.user.phoneNumber}
                                      amount={orderData.totalOrderAmount}
                                      currency="NGN "
                                      reference="tyu0922"
                                      className="p__button_style"
                                      isLoading={Loading}
                                      callback={initializeFlutterwave}
                                    />
                                ):values.paymentChannel === paymentOptionEnums.PAYSTACK ? (
                                    <PaystackHookButton
                                      text="Confirm Payment"
                                      textPrice={orderData.totalOrderAmount}
                                      className="p__button_style"
                                      url={payStackData.checkoutUrl}
                                    />
                                ): */}
                              {values.paymentChannel === paymentOptionEnums.FLUTTERWAVE ? (
                                <FlutterwaveHookButton
                                  text="Confirm Payment"
                                  textPrice={orderData.totalOrderAmount}
                                  email={orderData.user.email}
                                  name={orderData.user.firstName}
                                  phoneNumber={orderData.user.phoneNumber}
                                  amount={orderData.totalOrderAmount}
                                  currency="NGN "
                                  reference="Trefernce_id"
                                  className="p__button_style"
                                  isLoading={Loading}
                                  callback={initializeFlutterwave}
                                />
                              ) : values.paymentChannel === paymentOptionEnums.POD ? (
                                <PayOnDeliveryHookButton
                                  text="Confirm Order"
                                  textPrice={orderData.totalOrderAmount}
                                  className="p__button_style"
                                  url={ordersPathUrl}
                                />
                              ) : null}
                            </>
                          ) : null}

                      </div>
                    </Col>
                  ) : null}
                {Loading || initial_isLoading && (<Overlay><Spinner size="sm" animation="border" variant="light" /></Overlay>)}
              </>
            )}


          </Card.Body>
        </Card>
      </Col>

      {/* {isLoading ? (<OverlayLoader>
          <CustomLottieLoader animationData={animationData} size={200} />
      </OverlayLoader>)
          : null} */}
    </>
  );
}

export default memo(ReviewOrder);

