import React from "react"

import { RefreshLink, Typography } from "@ekenta/components";
import LiveIconComp from "@ekenta/components/LiveCommerce/LiveIconComp";
import LiveLoveComp from "@ekenta/components/LiveCommerce/LiveLoveComp";
import { singLiveBaseUrl } from "@ekenta/routes/constant";
import styles from "./SingleLiveBroadcast.module.scss";


const SingleLiveBroadcast = (props: any) => {
    return (
        <div className={styles.LiveBroadcast__Wrapper}>
            <LiveIconComp />
            <LiveLoveComp />
            <div className={styles.LiveBroadcast_module}>
                <RefreshLink aria-current="page" to={`${singLiveBaseUrl}/spm-${props.id}?type=${props.type}`}
                    aria-label={props.title}>
                    <div className={styles.LiveBroadcast_aspectBox} data-thumb="true">
                        <div className={styles.LiveBroadcast_ImageBox}>
                            <img src={props.imageUrl} data-id="responsiveImage"
                                srcSet={props.imageUrl}
                                alt={props.title} />
                        </div>
                    </div>
                </RefreshLink>
            </div>
            <div className={styles.LiveBroadcast_Detail}>
                <RefreshLink aria-current="page" to={`${singLiveBaseUrl}/spm-${props.id}?type=${props.type}`}
                    className={styles.LiveBroadcast_DetailLink}
                    aria-label={props.title}>
                    <span></span>
                    <div className={styles.LiveBroadcast_Detail__Content}>
                        <Typography
                            as="h4"
                            color="light"
                            size="medium"
                            className={styles.Heading}
                            transform="capitalize"
                            heading>
                            {props.title}
                        </Typography>
                        <Typography
                            as="span"
                            color="default"
                            size="small"
                            className={styles.description}
                            uiText>
                            Ekenta Livestream
                        </Typography>

                    </div>
                </RefreshLink>
            </div>
        </div>
    )
}

export default SingleLiveBroadcast; 