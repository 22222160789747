import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { DecodeTokenType } from '../../interfaces/decode';
import { useAppSelector } from '../../redux/store/hook';
import { removeUser } from '../../redux/store/states/reducerSlice/authenticationSlice';
import { checkAuthorizationRoles } from '../../utils';
import {decodeToken} from '../../utils/decodeToken';
import { homePathUrl, loginPathUrl } from '../constant';



export default function ProtectedRouteWithRoles({ component: RouteComponent,roles, ...rest }
                                               :{component: () => JSX.Element; roles:string[];}) {

  const dispatch = useDispatch();
  const { ek_isAuth,token,account } = useAppSelector((state:any) => state.auth.authentication); 
  
  if (!ek_isAuth) {
    return <Navigate to={loginPathUrl} />;
  }

  if(!token) {
    return <Navigate to={loginPathUrl} />;
  }

  if(!account.user) {
    return <Navigate to={loginPathUrl} />;
  }

  const user_token = token;
  const decoded_Token:DecodeTokenType | any  =  decodeToken(user_token);

  if (!decoded_Token || decoded_Token.exp * 1000 < Date.now()) {
    dispatch(removeUser());
    return <Navigate to={loginPathUrl} />;
  }

  const userRole = decoded_Token.auth;
  const checkUserRole = userRole.indexOf(',') > -1 ? userRole.split(',') : [userRole];
  const authorize = checkAuthorizationRoles(checkUserRole, roles);
  
  if (!authorize)
    return <Navigate to={homePathUrl} />; //or redirect to unauthorize page

  return <RouteComponent {...rest} />
}

