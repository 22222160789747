import React, { useContext } from 'react'
import { Col, Form } from 'react-bootstrap'

import styles from "./SiderBar.module.scss"
import { Accordion } from '@ekenta/components'
import PriceFilter from '../Filter/PriceFilter/PriceFilter'

import "./index.scss";
import BrandFilter from '../Filter/BrandFilter/BrandFilter'
import Ratings from '../Filter/Ratings/Ratings'
import { SearchFilterContext } from '@ekenta/context/SearchFilterContext'

const SideBar = (props: { collection: string, style?: any }) => {
    const { configureFilterUrl } = useContext(SearchFilterContext);


    const handleDiscounted = (checked: boolean) => {
        let data = { key: "discounted", value: checked, set: true };
        if (checked) {
            data = { ...data, set: true }
        } else {
            data = { ...data, set: false }
        }
        configureFilterUrl(data);
    }

    const handleInStore = (checked: boolean) => {
        let data = { key: "in_stock", value: checked, set: true };
        if (checked) {
            data = { ...data, set: true }
        } else {
            data = { ...data, set: false }
        }
        configureFilterUrl(data);
    }




    return (
        <Col className={styles.SideBar_Filter} style={{ ...props.style }}>
            <section className={styles.FilterSection}>
                <div className={styles.FilterDiv}>
                    <Accordion
                        title="Collection"
                        titleClass={styles.titleClass}
                        className={styles.CustomAccordion}
                        defaultActiveKey="0">
                        {props.collection ? (
                            <Form.Check
                                disabled
                                checked
                                type="radio"
                                label={props.collection}
                                id="collection"
                            />
                        ) : "Loading"}
                    </Accordion>
                    <Accordion
                        title="Price"
                        titleClass={styles.titleClass}
                        className={styles.CustomAccordion}
                        defaultActiveKey="0"
                    >
                        <PriceFilter />
                    </Accordion>
                    <Accordion
                        title="Brand"
                        titleClass={styles.titleClass}
                        className={styles.CustomAccordion}
                        defaultActiveKey="0"
                    >
                        <BrandFilter />
                    </Accordion>
                    <Accordion
                        title="Discount"
                        titleClass={styles.titleClass}
                        className={styles.CustomAccordion}
                    >
                        <Form.Check
                            type="checkbox"
                            label="Only discounted"
                            id="discount"
                            onChange={(e) => handleDiscounted(e.target.checked)}

                        />
                    </Accordion>
                    <Accordion
                        title="Ratings"
                        titleClass={styles.titleClass}
                        className={styles.CustomAccordion}
                    >
                        <Ratings />
                    </Accordion>
                    <Accordion
                        title="Available in stock"
                        titleClass={styles.titleClass}
                        className={styles.CustomAccordion}
                    >
                        <Form.Check
                            type="checkbox"
                            label="In stock"
                            id="in_stock"
                            onChange={(e) => handleInStore(e.target.checked)}

                        />
                    </Accordion>
                </div>
            </section>
        </Col>
    )
}

export default SideBar