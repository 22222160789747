import React, { useEffect, useState } from "react";
import { Col, ListGroup } from "react-bootstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { cartPathUrl, checkoutOnePagePathUrl, collectionPathUrl, productBaseUrl } from "@ekenta/routes/constant";
import NumberFormat from "react-number-format";
import { VscArrowLeft, VscArrowSmallLeft, VscArrowSmallRight } from "react-icons/vsc";
import { useAppDispatch, useAppSelector } from "@ekenta/redux/store/hook";
import { useFetchCartItemsQuery } from "@ekenta/redux/store/services/cartApi";
import { mapCartDataReturnQuantity, slugifyText } from "@ekenta/utils";
import { getTotals } from "@ekenta/redux/store/states/reducerSlice/cartSlice";
import EmptyCart from "../Box/EmptyCartBox";
import CartSingleItem from "./CartSingleItem";
import RefreshLink from "../RefreshLink/RefreshLink";
import Button from "../Button/Button";


const mapCartData = (cartData: any, ek_isAuth: boolean) => {
    return cartData ? cartData.map((item: any) => {
        const id = ek_isAuth ? item.id : item.product.id;
        return {
            id: id,
            quantity: item.quantity,
            price: item.price,
            product: item.product
        }
    }) : null;
}


export default function CartItems() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    const [resultData, setResultData] = useState<
        { totalCost: number, cartItems: any[] }>({ "totalCost": 0, cartItems: [] });

    const { cartItems, cartTotalAmount } = useAppSelector(state => state.cart.cart);
    const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const { data, isError, isSuccess } = useFetchCartItemsQuery(token, { skip: token == null });


    useEffect(() => {
        if (ek_isAuth && token) {
            if (isSuccess && data) {
                const _cartData = mapCartData(data.cartItems, ek_isAuth);
                setResultData({ "totalCost": data.totalCost, cartItems: _cartData });
            } else {
                setResultData({ "totalCost": 0, cartItems: [] });
            }
        } else {
            if (cartItems) {
                const _cartData = mapCartData(cartItems, ek_isAuth);
                dispatch(getTotals());
                setResultData({ "totalCost": cartTotalAmount, cartItems: _cartData });
            } else {
                setResultData({ "totalCost": 0, cartItems: [] });
            }
        }
    }, [isSuccess, data, cartItems, cartTotalAmount])

    return (
        <div className="__cart_general__">

            {isError && <div></div>}
            <div>
                {resultData.cartItems.length > 0 ? (
                    <div>
                        <div className="_sub__g__cart">
                            <ListGroup className="list_cart_ul">
                                <div>
                                    {resultData.cartItems.map((item: any) => {
                                        const _slugProduct = item ? slugifyText(item.product.name.toLowerCase(), item.product.id) : "/";
                                        return (
                                            <CartSingleItem
                                                key={item.id}
                                                cartItemId={item.id}
                                                title={item.product.name}
                                                cartItemPrice={item.price}
                                                cartItem={mapCartDataReturnQuantity(resultData, item.product.id)}
                                                productPrice={item.product.price}
                                                imageUrl={item.product.imageUrls ? item.product.imageUrls[0] : null}
                                                quantity={item.quantity}
                                                productUrl={`${productBaseUrl}/${_slugProduct}`}
                                            />
                                        )
                                    })}
                                </div>
                            </ListGroup>
                        </div>
                        <div className="bottom_cart_L mt-2">
                            <ListGroup className="_u_L_" as={"ul"}>
                                <ListGroup.Item className="_sub_total__">
                                    <div className="_row_">
                                        <h5>SUBTOTAL</h5>
                                        <span>

                                            <NumberFormat
                                                value={resultData.totalCost ? resultData.totalCost : 0.00}
                                                displayType="text"
                                                thousandSeparator={true}
                                                prefix="₦"
                                                className="some"
                                            />

                                        </span>
                                    </div>
                                </ListGroup.Item>
                                {ek_isAuth && token ? (
                                    <ListGroup.Item className="_list_g_S">
                                        <div className="_row_c justify-content-center">
                                            <Col className="w-100">
                                                <RefreshLink to={checkoutOnePagePathUrl}>
                                                    <Button
                                                        name="Continue to checkout"
                                                        bgVariant="primary"
                                                        textVariant="light"
                                                        shadow={false}
                                                        width="w-100"
                                                        style={{
                                                            width: "100%",
                                                            paddingTop: "11px",
                                                            paddingBottom: "11px",
                                                            textAlign: "center",
                                                            justifyContent: "center",
                                                        }}
                                                        suffixIcon={<VscArrowSmallRight />}
                                                    />
                                                </RefreshLink>
                                            </Col>
                                        </div>
                                    </ListGroup.Item>
                                ) : (
                                    <>
                                    </>
                                )}
                            </ListGroup>
                        </div>
                    </div>
                ) : resultData ? resultData.cartItems.length <= 0 && (
                    <Col lg={12} className="d-flex flex-column align-items-center justify-content-center mb-3 empty__cart__div">
                        <div style={{ width: "90%" }}>
                            <EmptyCart
                                imgSize="150"
                                textClass="display_sm_header"
                            />
                        </div>
                    </Col>

                ) : null}
            </div>

        </div>

    );
}
