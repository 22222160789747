import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FiLink } from 'react-icons/fi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import styled from 'styled-components';
import { toast } from 'react-toastify';

import styles from "./SocialShare.module.scss"

interface SocialShareProps {
    url: string;
    title: string;
}

interface CopyLinkProps {
    size: number;
    round: boolean;
}


const CopyLink = (props: CopyLinkProps) => (
    <Container size={props.size} round={props.round}>
        <FiLink />
    </Container>
);

export default function SocialShare(props: SocialShareProps) {
    const [copied, setCopied] = useState(false);

    const shareIconSize = 50;
    const shareUrl = props.url;
    const title = props.title;

    useEffect(() => {
        if (copied) {
            toast.success(`Link copied`, {
                toastId: "sellerLive-allproduct-fetch-id-toast-error",
            });
            setCopied(false)
        }
    }, [copied])

    return (
        <div className={styles.SocialShare_Wrapper}>
            <Row className={styles.SocialShare_Wrapper__Row}>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <CopyToClipboard text={shareUrl}
                        onCopy={() => setCopied(true)}>
                        <span style={{ cursor: 'pointer' }}>
                            <CopyLink size={shareIconSize} round={true} />
                            <span className={styles.SocialHelper__Text}>Copy Link</span>
                        </span>
                    </CopyToClipboard>
                </Col>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <FacebookShareButton url={shareUrl} quote={title} hashtag="">
                        <FacebookIcon size={shareIconSize} round />
                        <span className={styles.SocialHelper__Text}>Facebook</span>
                    </FacebookShareButton>
                </Col>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <TwitterShareButton url={shareUrl} title={title}>
                        <TwitterIcon size={shareIconSize} round={true} />
                        <span className={styles.SocialHelper__Text}>Twitter</span>
                    </TwitterShareButton>
                </Col>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <LinkedinShareButton url={shareUrl} title={title} summary="" source="">
                        <LinkedinIcon size={shareIconSize} round={true} />
                        <span className={styles.SocialHelper__Text}>Linkedin</span>
                    </LinkedinShareButton>
                </Col>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <PinterestShareButton
                        url={String(window.location)}
                        media={`${String(window.location)}/${''}`}
                        description="">
                        <PinterestIcon size={shareIconSize} round={true} />
                        <span className={styles.SocialHelper__Text}>Pinterest</span>
                    </PinterestShareButton>
                </Col>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <RedditShareButton url={shareUrl} title={title}>
                        <RedditIcon size={shareIconSize} round={true} />
                        <span className={styles.SocialHelper__Text}>Reddit</span>
                    </RedditShareButton>
                </Col>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <TelegramShareButton url={shareUrl} title={title}>
                        <TelegramIcon size={shareIconSize} round={true} />
                        <span className={styles.SocialHelper__Text}>Telegram</span>
                    </TelegramShareButton>
                </Col>
                <Col className={styles.SocialShare_Wrapper__Col} xs={4} md={2} lg={2}>
                    <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon size={shareIconSize} round={true} />
                        <span className={styles.SocialHelper__Text}>Whatsapp</span>
                    </WhatsappShareButton>
                </Col>

            </Row>
        </div>
    )
}

const Container = styled.span<CopyLinkProps>`
    width: ${(props) => `${props.size ? props.size : 50}px`};
    height: ${(props) => `${props.size ? props.size : 50}px`};
    border-radius:  ${(props) => `${props.round ? 50 : 0}%`};
    background : #ddd;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;


    svg{
        font-size: 20px;
    }
`;

