import React, { useState, useEffect, useCallback } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import AccountDetails from './Steps/AccountDetails';
import BusinessDetail from './Steps/BusinessDetail';
import BankDetails from './Steps/BankDetails';
import Summary from './Steps/Summary';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hook';
import { useSellerOnboardingAnonymousSignUpMutation, useSellerOnboardingSignUpMutation } from '../../../../redux/store/services/onboardingApi';
import sellerOnboardingFormValidation from '../../../../validations/schemas/seller-onboarding';
import { useNavigate } from 'react-router';
import CustomLottieLoader from '@ekenta/components/Spinner/CustomLottieLoader';
import { toast } from "react-toastify";

import * as animationData from '../../../../assets/lotties_files/loading-bubbles.json';
import { removeUser } from '../../../../redux/store/states/reducerSlice/authenticationSlice';
import { emailVerifyPathUrl, loginPathUrl } from '@ekenta/routes/constant';
import { Overlay } from '@ekenta/components';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';

interface SelleronBoardingType {
  page: number;
  nextPage: () => void;
  prevPage: () => void;
}

export default function SellerOnboadingForms({ page, nextPage, prevPage }: SelleronBoardingType) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [vatFile, setVatFile] = useState('');
  const [legalForm, setLegalForm] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [anonymousEmail, setAnonymousEmail] = useState<string>();
  const { ek_isAuth, account, token } = useAppSelector(state => state.auth.authentication);
  const [sellerOnboardingSignUp, { isError, isLoading: registeredUserLoading, isSuccess: isRegisteredUserSuccess, error }]
    = useSellerOnboardingSignUpMutation();
  const [sellerOnboardingAnonymousSignUp, { data, isError: isAnonymousError, isLoading, isSuccess: isAnonymousSuccess, error: anonymousError }]
    = useSellerOnboardingAnonymousSignUpMutation();


  const [initialValue, setIntialValue] = useState({
    personal_detail: {
      firstname: '',
      lastname: '',
      username: '',
      phonenumber: '',
      email: '',
      password: '',
      confirmpassword: '',
      referedby: '',
    },
    business_detail: {
      businessname: '',
      address: '',
      state: { id: '', name: '', },
      city_or_town: { id: '', name: '', },
      country: '',
      business_phone_number: '',
      business_registration_number: '',
      vat_registration_number: '',
      seller_vat: '',
      tax_identification_number: '',
      businessLogo: '',
    },
    bank_detail: {
      bank: '',
      account_name: '',
      account_number: '',
      bank_code: '',
      bankSwift: '',
      bankIban: '',
      bank_information: '',
      bankBvn: '',
    },
    acceptTermsAndConditions: false
  });


  useEffect(() => {
    if (ek_isAuth && token && account.user) {
      nextPage();
      setIntialValue({
        ...initialValue,
        personal_detail: {
          firstname: account.user.firstName,
          lastname: account.user.lastName,
          username: account.user.username,
          phonenumber: '080324',
          email: account.user.email,
          password: '********',
          confirmpassword: '********',
          referedby: ''
        }
      })
    }
  }, []);


  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: sellerOnboardingFormValidation(),
    onSubmit: values => {
      if (ek_isAuth && token) {
        handleOnboardingRegisteredUserSignUp(values);
      } else {
        setAnonymousEmail(values.personal_detail.email);
        handleOnboardingAnonymousSignUp(values);
      }
    }
  });



  // handling Errors messages
  useEffect(() => {
    if (isError && error) {
      toast.error(`Something went wrong`, {
        toastId: "error-onboarding registered-users-id-toast-error",
      });
    }
    if (isAnonymousError && anonymousError) {
      toast.error(`Something went wrong`, {
        toastId: "error-anonymousError-register-id-toast-error",
      });
    }

  }, [isError, error, isAnonymousError, anonymousError]);


  useEffect(() => {

    if (isRegisteredUserSuccess) {
      toast.success(`You're Onboarded successfully`, {
        toastId: "onboarding-id-toast-55555-success",
      });

      dispatch(removeUser());

      setTimeout(() => {
        navigate(loginPathUrl);
      }, 500)
    }


    if (isAnonymousSuccess) {

      // const businessLogo = formik.values.business_detail.businessLogo;
      // handleUploadLogo(data.merchantOwner.id, businessLogo);

      toast.success(`You're Onboarded successfully`, {
        toastId: "onboarding-id-toast-55555-success",
      });

      setTimeout(() => {
        navigate(emailVerifyPathUrl,
          {
            state: {
              email: anonymousEmail
            },
            replace: true
          }
        );
      }, 1000)

    }



  }, [isRegisteredUserSuccess, isAnonymousSuccess]);



  const handleUploadLogo = useCallback((sellerId: string, businessLogo: any) => {
    if (businessLogo && sellerId) {
      const imageLogo = new FormData();
      imageLogo.append("files", businessLogo);

      setIsLoading(true);
      const config = {
        method: 'PUT',
        url: `${BASE_URL}/api/attachLogoToMerchantBusiness/${sellerId}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: imageLogo
      };
      axios(config)
        .then(function (res) {
          setIsLoading(false);
          setSuccess(false);
        })
        .catch(function () {
          setIsLoading(false);
          toast.error(`Something went wrong`, {
            toastId: "-toggle-error-400-id-toast-error",
          });
        });
    }
  }, []);






  const handleOnboardingAnonymousSignUp = (values: any) => {
    const objData = {
      businessName: values.business_detail.businessname,
      bankName: values.bank_detail.bank,
      bankAccountName: values.bank_detail.account_name,
      bankAccountNumber: values.bank_detail.account_number,
      bankCode: values.bank_detail.bank_code,
      bankSwift: values.bank_detail.bankSwift,
      bankIban: values.bank_detail.bankIban,
      address: {
        homeAddress: values.business_detail.address,
        cityId: values.business_detail.city_or_town.id,
        stateId: values.business_detail.state.id,
      },
      bankInformation: values.bank_detail.bank_information,
      bankBvn: "",
      contactPerson: "",
      contactPersonPhoneNumber: values.business_detail.business_phone_number,
      businessRegistrationNumber: values.business_detail.business_registration_number,
      vatFileUrl: "",
      vatRegistrationNumber: values.business_detail.vat_registration_number,
      sellerVat: values.business_detail.seller_vat,
      tin: values.business_detail.tax_identification_number,
      legalForm: "",
      acceptTermsAndConditions: values.acceptTermsAndConditions,
      merchantOwner: {
        username: values.personal_detail.username.toLowerCase(),
        firstName: values.personal_detail.firstname,
        lastName: values.personal_detail.lastname,
        email: values.personal_detail.email,
        phoneNumber: values.personal_detail.phonenumber,
      },
      password: values.personal_detail.password
    };

    sellerOnboardingAnonymousSignUp(objData);

  }

  const handleOnboardingRegisteredUserSignUp = (values: any) => {
    const data = {
      businessName: values.business_detail.businessname,
      bankName: values.bank_detail.bank,
      bankAccountName: values.bank_detail.account_name,
      bankAccountNumber: values.bank_detail.account_number,
      bankCode: values.bank_detail.bank_code,
      bankSwift: values.bank_detail.bankSwift,
      bankIban: values.bank_detail.bankIban,
      address: {
        homeAddress: values.business_detail.address,
        cityId: values.business_detail.city_or_town.id,
        stateId: values.business_detail.state.id
      },
      bankInformation: values.bank_detail.bank_information,
      bankBvn: "",
      contactPerson: "",
      contactPersonPhoneNumber: values.business_detail.business_phone_number,
      businessRegistrationNumber: values.business_detail.business_registration_number,
      vatFileUrl: "",
      vatRegistrationNumber: values.business_detail.vat_registration_number,
      sellerVat: values.business_detail.seller_vat,
      tin: values.business_detail.tax_identification_number,
      legalForm: "",
      acceptTermsAndConditions: values.acceptTermsAndConditions
    };

    sellerOnboardingSignUp({ data: data, token: token });
  }


  return (
    <div className="position-relative">

      {/* {formik.errors && (<CustomAlert show={true} message={'All Fields are required '} variant="danger" />) } */}

      <FormikProvider value={formik}>
        <Form noValidate onSubmit={formik.handleSubmit}>

          {page === 0 && (
            <AccountDetails
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              nextPage={nextPage}
              prevPage={() => null}
            />
          )}

          {page === 1 && (
            <BusinessDetail
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              vatFile={vatFile}
              setVatFile={setVatFile}
              setLegalForm={setLegalForm}
              nextPage={nextPage}
              prevPage={prevPage}
            />)}

          {page === 2 && (
            <BankDetails
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              setFieldValue={formik.setFieldValue}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          )}

          {page === 3 && (
            <Summary
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              prevPage={prevPage}
              isLoading={
                ek_isAuth ?
                  registeredUserLoading
                  : isLoading
                  || isloading
              }
            />
          )}


        </Form>
      </FormikProvider>

      {ek_isAuth ? registeredUserLoading : isLoading && (
        <Overlay>
          <CustomLottieLoader animationData={animationData} size={200} />
        </Overlay>
      )}

    </div>
  );
}
