import React, { useEffect, useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars';
import { SpinLoader } from '../../../../../components/Spinner/SpinLoader';
import { LiveStream_BaseURL } from '../../../../../constants';
import MessagingStreams from '../../../../LiveCommerce/SingleLiveCommerce/components/MessagingStreams'
import SingleSellerProduct from '../SingleSellerProduct';


interface LiveVideoProductFramePropsType {
    nickname: string;
    clientRef:any;
    sellerId:string;
    setIsDisconected: any;
    setIsConected: any;
    liveCode: string;
    data:any;
    isSuccess:boolean;
    isLoading:boolean;
    setShowPopover: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LiveVideoProductFrame = ({data,isLoading,isSuccess,nickname,clientRef,sellerId,setIsDisconected,setIsConected,liveCode,setShowPopover}:LiveVideoProductFramePropsType) => {

  return (
   <>
    <div className="vid_list_product_container" >
      <h5 className="vid__h5">Products</h5>
      <Scrollbars className="vid_product_contain mt-2" thumbMinSize={80}  
        style={{ width: '150px', height: '610px'}} universal={false}>
          {isLoading && <SpinLoader size={3} variant="light" />}
          {isSuccess && data ? data.content.map((item:any) => (
            <SingleSellerProduct
               key={item.id}
               title={item.name}
               image={item.thumbnailUrl}
              />
          )): null}
      </Scrollbars>
    </div>


    <div className="video__player__s3">
         <div className="col-12 iframe-stream-div">
            <div className=" text-center">
              <iframe  id="iframe" className="live-stream-frame" src={`${LiveStream_BaseURL}${liveCode}`} name="upstream" 
          scrolling="no" allow="camera; microphone;display-capture; autoplay"  allowFullScreen={false}>
                </iframe>
               <div className="iframe-stream-cover_blank"></div>
            </div>
            <div className="d-lg-none">
              <MessagingStreams
                from={nickname}
                clientRef={clientRef}
                sellerId={sellerId}
                setIsDisconected={setIsDisconected}
                setIsConected={setIsConected}
                addClass={'messaging__bottom'} 
                setHandle={setShowPopover}
              /> 
            </div>
          </div>
    </div>
    </>
  )
}
