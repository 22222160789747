import React, { useCallback } from "react";
import { BsFillCaretDownFill } from "react-icons/bs";
import { FiChevronLeft, FiLogOut, FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogout } from "react-google-login";
import {
  DashboardSellerLiveCommerceBaseUrl,
  DashboardSellerLiveCommercePathUrl,
  homePathUrl,
  loginPathUrl,
} from "../../../routes/constant";
import avatar from "../../../assets/images/avatar_pro.png";
import liveicon from "../../../assets/images/TB1live.gif";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import {
  checkIfAdmin,
  checkIfAdminOrSellerRoleExist,
  logoutHandler,
  useMatchPath,
} from "../../../utils";
import { removeUser } from "../../../redux/store/states/reducerSlice/authenticationSlice";
import logo from "../../../assets/images/ekentalogo.png";

import Dropdown from "../../../components/Dropdown/Dropdown";
import {
  removeLive,
  setLiveType,
} from "../../../redux/store/states/reducerSlice/livecomSlice";
import { GOOGLE_CLIENTID } from "../../../constants";
import { toast } from "react-toastify";
import { useFetchProductFilteredByCollectionQuery } from "../../../redux/store/services/livecommerceApi";
import { Button } from "@ekenta/components";

export const DashboardCustomNavBar = ({
  setvisibilty,
}: {
  setvisibilty: React.Dispatch<React.SetStateAction<boolean>>;
}) => {


  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account, ek_isAuth, token } = useAppSelector(
    (state: any) => state.auth.authentication,
  );

  const { signOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENTID ? GOOGLE_CLIENTID : "",
  });

  const sellerId = account ? account.user.id : "";
  const isAdmin = checkIfAdmin(token);
  const checkResult = useFetchProductFilteredByCollectionQuery(sellerId, { skip: !sellerId });

  const logoutHandlerFunc = () => {
    logoutHandler(
      navigate,
      dispatch,
      signOut,
      loginPathUrl,
      removeUser,
      removeLive,
    );
    toast.success(`You"ve successfully logout, see you again`, {
      toastId: "logout-seller-navbar-id-toast-success",
    });
  };

  const navigateToLivestream = (type: string) => {
    dispatch(
      setLiveType({
        liveType: type,
      }),
    );
    navigate(
      `${DashboardSellerLiveCommerceBaseUrl}/view-${account.user.id}?type=${type}`,
    );
  };

  const checkIfEkeMarketExist = useCallback(() => {

    if (checkResult?.data) {
      if (checkResult?.data.content.length > 0) {
        return <Button
          name="Go Live"
          prefixIcon={
            <>
              <img
                src={liveicon}
                alt="live stream icon"
                width="9"
                height="9"
              />
            </>
          }
          onClick={() =>
            navigateToLivestream("isEkeLive")
          }
        />
      }
    }
  }, [checkResult?.data]);

  return (
    <div className="dashboard-custom-nav">
      <div className="dashboard-custom-nav-menu d-flex align-items-center">
        <button
          className="navbar-toggler d-lg-none p-0 border-0"
          type="button"
          aria-controls=""
          aria-expanded="false"
          onClick={() => setvisibilty(true)}
        >
          <span className="navbar-mobile-icon">
            <FiMenu className="text-black" fontSize={22} />
          </span>
        </button>
        <div className="dashboard-brand d-lg-none ">
          <img
            alt="ekenta logo"
            src={logo}
            width="140px"
            height="auto"
            className="d-inline-block"
          />{" "}
        </div>
      </div>
      <div className="dashboard-custom-nav-container">
        <ul className="ul__list">
          {pathname !==
            useMatchPath(DashboardSellerLiveCommercePathUrl, pathname) ? (
            <>
              {ek_isAuth && checkIfAdminOrSellerRoleExist(token) && !isAdmin ? (
                <li>{checkIfEkeMarketExist()}</li>
              ) : null}
            </>
          ) : null}

          <li>
            <Dropdown
              placement="bottom"
              menus={[
                {
                  name: "Switch to Buyer",
                  icon: <FiChevronLeft />,
                  active: false,
                  key: "switch-to-buyer",
                  handler: () => navigate(homePathUrl),
                },
                {
                  name: "Logout",
                  icon: <FiLogOut />,
                  active: false,
                  key: "logout",
                  handler: logoutHandlerFunc,
                },
              ]}
              tooltipContent={""}
              render={() => (
                <div className="dashboard-custom-nav-profile shadow-sm">
                  <div className="dashboard-custom-nav-image">
                    <img src={avatar} alt="seller account" />
                  </div>
                  <div className="dashboard-profile_name">
                    <h5>{account && account.user.firstName}</h5>
                    <BsFillCaretDownFill className="dropDownIcon" />
                  </div>
                </div>
              )
              }
            />

            {/* <div className="dashboard-custom-nav-profile">
                            <div className="dashboard-custom-nav-image">
                                    <img src={avatar} alt="seller account" />
                                </div>
                                <div className="dashboard-profile_name">
                                    <h5>{account && account.user.firstName}</h5>
                                    <BsFillCaretDownFill className="dropDownIcon" />
                                </div>
                         </div> */}
          </li>
        </ul>
      </div>
    </div>
  );
};
