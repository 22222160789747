import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Breadcrumb, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import * as queryString from "query-string";
import { animateScroll } from "react-scroll";

// import CollectionMainBar from '../../components/Collection/CollectionMainBar';
// import CollectionSideBar from '../../components/Collection/CollectionSideBar';
import DefaultLayout from '../../hoc/Layout/Default-Layout';
import { useFetchSingleSubCollectionCategoryQuery, useFetchSingleSubCollectionQuery } from '../../redux/store/services/collectionApi';
// import CollectionPageLoader from '../../components/SkeletonLoader/Collection/CollectionPageLoader';

import { FiSearch } from 'react-icons/fi';
// import { FilterIcon, SortIcon } from '../../components/icons/icons';
// import BreadCrumbs from './component/BreadCrumbs';
// import { collectionPathUrl } from '../../routes/constant';
import egusi from '../../assets/images/egusi.png'
import cutlery from '../../assets/images/cutlery.png'
import brand from '../../assets/images/brand.png'
import fork from '../../assets/images/fork.png'
import stew from '../../assets/images/stew.png'
import score from '../../assets/images/score.png'


import cutlery_blue from '../../assets/images/cutlery_blue.png'
import IKitchenProductCard from '../../components/iKitchen/iKitchenProductCard';
import { useFetchProductByCollectionIdQuery, useFetchProductBySubCategoryCollectionIdQuery, useFetchProductBySubCollectionIdQuery } from '../../redux/store/services/productsApi';
import ErrorBox from '../../components/Box/ErrorBox';
import EmptyBox from '../../components/Box/EmptyBox';
import { mapCartDataReturnQuantity } from '../../utils';
import { useFetchCartItemsQuery } from '../../redux/store/services/cartApi';
import { useAppSelector } from '../../redux/store/hook';



export default function IKitchenCollection() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const params = useParams()

    // get the query params from the url
    const query = queryString.parse(window.location.search);
    const _page = query.page;
    const [pageno, setPageno] = useState<any>(0);

    // const subCollectionId = params['id'] ? params.id!.slice(params.id!.lastIndexOf('-') + 1) : null;
    let categoryId = params['id'] ? params.id!.slice(params.id!.lastIndexOf('-') + 1) : null;
    let subCollectionId;
    // console.log(categoryId, subCollectionId)

    if(categoryId == 'all'){
        categoryId = '202'
        subCollectionId = null
    }else{
        subCollectionId = categoryId
        categoryId = null
    }
    // console.log(categoryId, subCollectionId)
    const { token } = useAppSelector(state => state.auth.authentication);
    //fetchProductByCollectionId
    const { data: categoryProductData, isLoading: categoryProduct_isLoading, isFetching: categoryProduct_isFetching, isSuccess: categoryProduct_isSuccess, isError:categoryProduct_isError } =
    useFetchProductByCollectionIdQuery(categoryId, { skip: !categoryId });

    //useFetchProductBySubCollectionIdQuery
    const { data: subCollectionProductData, isLoading: subCollectionProduct_isLoading, isFetching: subCollectionProduct_isFetching, isSuccess: subCollectionProduct_isSuccess, isError:subCollectionProduct_isError } =
    useFetchProductBySubCategoryCollectionIdQuery({categoryId:subCollectionId, pageno}, { skip: !subCollectionId });

    //fetch subcollection by it Id
    const { data: subsingleData, isLoading: subsingle_isLoading, isFetching: subsingle_isFetching, isSuccess: subsingle_isSuccess } =
        useFetchSingleSubCollectionQuery(subCollectionId, { skip: !subCollectionId });

    //fetch subcollectioncategory by it Id
    const { data: subsinglecategory_data, isLoading: subsinglecategory_isLoading, isFetching: subsinglecategory_isFetching,
        isSuccess: subsinglecategory_isSuccess } = useFetchSingleSubCollectionCategoryQuery(categoryId, { skip: !categoryId });

    const {data:allCartData} = useFetchCartItemsQuery(token, {skip: !token}); 

    useEffect(() => {
        scrollToTop();

        setTimeout(() => {
            if (_page) {
                const _newPage = parseInt(_page.toString());
                if (_newPage > 0) {
                    setPageno(_newPage - 1);
                } else {
                    navigate(`${pathname}?page=1&sortType=default`);
                }
            } else {
                setPageno(0);
            }

        }, 1000);

    }, [_page])


    const scrollToTop = () => {
        animateScroll.scrollToTop();
    }


    return (
        <DefaultLayout>
            <Helmet>
                <title>iKitchen - Ekenta </title>
                <meta charSet="utf-8" />
                <meta name="title" content="Ekenta Collection Page" />
                <meta name="description"
                    content="Ekenta Collection Page"
                />
                <meta property="og:locale" content="en_NG" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>
            <div ref={(el) => el && el.style.setProperty("background-color", "#232F3E", "important")} className="mb_padd_ bg-brand d_collection_wrapper">

                <div className="collection_grid_v pt-1">

                    <>
                        <Row>
                            <Col lg={12} className={'mb-3 p-0'}>
                                <img src={egusi} alt="" className='img-fluid' />
                            </Col>
                            <Col lg={3} className="p-2 d-none row bg-white mr-2 d-lg-flex collection_sideBar_kitchen">

                                <div className={'col-lg-12 row p-3'}>
                                    <Col lg={1}></Col>
                                    <Col lg={10}>
                                        <img src={cutlery} alt="" />
                                        <h6 className='brand-color-text font-weight-bold'><br /> KITCHEN</h6>
                                        <p className='brand-color-text'> Ready to eat</p>
                                        <p className='brand-color-text'> 100% satisfaction guarantee</p>
                                        <p className='brand-color-text'> Quick Delivery</p>
                                    </Col>
                                    <hr />
                                </div>

                                <div className={'col-lg-12 row px-3'}>
                                    <Col lg={1}></Col>
                                    <Col lg={11}>
                                        <img src={brand} alt="" />
                                        <h6 className='brand-color-text font-weight-bold'><br /> BRAND</h6>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Diamond (5 liters, 25pcs of meat)</p>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Gold (2.5 liters, 15 pcs of meat)</p>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Bronze (1 liter, 10pcs of meat)</p>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Silver (0.5liter, 5pcs of meat)</p>
                                    </Col>
                                    <hr />
                                </div>
                                <div className={'col-lg-12 row px-3'}>
                                    <Col lg={1}></Col>
                                    <Col lg={11}>
                                        <img src={fork} alt="" />
                                        <h6 className='brand-color-text font-weight-bold'><br /> SOUP</h6>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Egusi</p>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Afang</p>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Ogbono</p>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Ewedu</p>
                                    </Col>
                                    <hr />
                                </div>

                                <div className={'col-lg-12 row px-3'}>
                                    <Col lg={1}></Col>
                                    <Col lg={11}>
                                        <img src={stew} alt="" />
                                        <h6 className='brand-color-text font-weight-bold'><br /> STEW</h6>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Peppered stew</p>
                                    </Col>
                                    <hr />
                                </div>

                                <div className={'col-lg-12 row px-3'}>
                                    <Col lg={1}></Col>
                                    <Col lg={11}>
                                        <img src={score} alt="" />
                                        <h6 className='brand-color-text font-weight-bold'><br /> SELLERS SCORE</h6>
                                        <p className='brand-color-text'> <input type="checkbox" name="" id="" /> Seller&apos;s Score 80%</p>
                                    </Col>

                                </div>

                            </Col>
                            <Col xs={12} lg={9} className="collection_mainBar pt-sm-0">
                                <div className='bg-white rounded h-100 px-3'>
                                    <div className='row justify-content-between align-items-center py-2'>
                                        <div className="row col-lg-8">
                                            <div className='myflex'>
                                                <p>Home &gt; &nbsp;&nbsp; </p>
                                                <img src={cutlery_blue} style={{marginTop: '4px'}} height="15px" alt="" />
                                                <p>&nbsp;&nbsp; iKitchen</p>
                                            </div>
                                            
                                        </div>
                                        <div className="col-lg-4">
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1" style={{background: '#EAF1FB', borderRight: 'none'}}>
                                                    <FiSearch className="iconSearch" />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    placeholder="Search iKitchen"
                                                    aria-label="Search iKitchen"
                                                    aria-describedby="basic-addon1"

                                                    style={{backgroundColor: '#EAF1FB', borderLeft: 'none'}}
                                                />
                                            </InputGroup>
                                        </div>
                                        
                                    </div>

                                    <hr style={{margin: '0px'}}/>

                                    <div className='row justify-content-between align-items-center'>
                                        <div className="row col-lg-9"><h3>iKitchen</h3></div>
                                        <div className="row col-lg-3">
                                            <select name="" id="" style={{height: '50px', border: "none"}}>
                                                <option value="">Sort By: Popularity </option>
                                                <option value="Latest Arrivals">Latest Arrivals</option>
                                                <option value="Price: Lowest">Price: Lowest</option>
                                                <option value="Prices: Highest">Prices: Highest</option>
                                                <option value="Sellers Score">Seller&apos;s Score</option>
                                            </select>
                                        </div>
                                    </div>

                                    <hr style={{margin: '0px'}}/>
                                    <div className="row p-3">
                                        <h6> 
                                            {subCollectionId ? subCollectionProductData?.totalElements : null}
                                            {categoryId ? categoryProductData?.totalElements : null}
                                            {' '} products found
                                        </h6>
                                    </div>
                                        
                                    <hr style={{marginTop: '0px'}}/>

                                    <div className="row">
                                        {
                                            subCollectionId == null ? '' :
                                            <>
                                                {subCollectionProduct_isError ? (<Row className="prod_commponent_row_ p-0 m-0"><ErrorBox /></Row>) : null}
                                                {
                                                    subCollectionProduct_isSuccess ?
                                                    subCollectionProductData.content.length > 0 ?
                                                     subCollectionProductData.content.map((data:any, i:any)=> (
                                                        <IKitchenProductCard key={i} 
                                                        imageUrl={data?.thumbnailUrl} 
                                                        url={'#'} 
                                                        title={data?.name}
                                                        price={data?.price}
                                                        shortDescription={data?.shortDescription}
                                                        brandName={data?.brand?.name}
                                                        prod={data}
                                                        cartItem={ mapCartDataReturnQuantity(allCartData, data.id)}
                                                        />
                                                    )) : <EmptyBox /> : ''
                                                }
                                            </>

                                        }

                                        {
                                            categoryId == null ? '' :
                                            <>
                                                {categoryProduct_isError ? (<Row className="prod_commponent_row_ p-0 m-0"><ErrorBox /></Row>) : null}
                                                {
                                                    categoryProduct_isSuccess && categoryProductData.content.map((data:any, i:any)=> (
                                                        <IKitchenProductCard 
                                                        prod={data}
                                                        key={i}
                                                        imageUrl={data?.thumbnailUrl} 
                                                        url={'#'} 
                                                        title={data?.name}
                                                        price={data?.price}
                                                        shortDescription={data?.shortDescription}
                                                        brandName={data?.brand?.name}
                                                        cartItem={ mapCartDataReturnQuantity(allCartData, data.id)}
                                                        />
                                                    ))
                                                }
                                            </>

                                        }

                                        {/* {categoryProduct_isError ? (<Row className="prod_commponent_row_ p-0 m-0"><ErrorBox /></Row>) : null}
                                        {
                                            categoryProduct_isSuccess && categoryProductData.content.map((data:any, i:any)=> (
                                                <IKitchenProductCard key={i} imageUrl={slatelive} url={'#'} title={'Hello'}/>
                                            ))
                                        } */}

                                    </div>


                                </div>
                            </Col>

                        </Row>
                    </>

                </div>

            </div>
        </DefaultLayout>
    )
}
