import React, { useState } from "react";
import {
  Badge,
  Card,
  Col,
  Row,
  Tab,
  Tabs,
  Table,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import DashboardComponent from "../component/DashboardComponent";
import DashboardLayout from "../../../hoc/Layout/Dashboard-Layout";
import { useAppSelector } from "../../../redux/store/hook";
import {
  useFetchAccountingSalesAdminQuery,
  useFetchDailyTotalOrderQuery,
  useFetchPayoutRequestQuery,
} from "../../../redux/store/services/adminSellerApi";
import { Pagination } from "../../../components";
import axios from "axios";
import { BASE_URL } from "../../../constants";
import { useLocation } from "react-router";



export default function AdminPayoutRequest({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const [lgShow, setLgShow] = useState(false);
  const { pathname } = useLocation();
  const { token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const allOrders = useFetchDailyTotalOrderQuery(token);
  const ord = useFetchAccountingSalesAdminQuery(token);
  const [paginated, setPaginated] = useState(false);
  const result = useFetchPayoutRequestQuery({ token });
  const [data, setData] = useState<any>({});


  const paginatePayout = async (x: any) => {
    setPaginated(true);
    await axios
      .get(`${BASE_URL}/api/users/managed-users?page=${x.selected}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp: any) => {
        console.log(resp.data.content);
        setData(resp.data);
      })
      .catch(function (error: any) {
        console.log(error);
      });
  };


  return (
    <DashboardLayout>
      <Helmet>
        <title>Admin Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Orders Dashboard Page" />
        <meta name="description" content="Ekenta Orders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">
              {/* <FiChevronLeft /> */}
              Payout Request
            </h3>
            <hr className="mt-1" />
            <Row className="">
              <Col md={4}>
                ₦
                {new Intl.NumberFormat().format(
                  !allOrders.isSuccess
                    ? "---"
                    : allOrders.data.totalValueOrders,
                )}{" "}
                <br /> Total Orders Value
              </Col>
              <Col md={4}>
                {new Intl.NumberFormat().format(
                  !allOrders.isSuccess ? "---" : allOrders.data.totalOrders,
                )}{" "}
                <br /> Pending Payout Value
              </Col>
              <Col md={4}>
                ₦
                {new Intl.NumberFormat().format(
                  !allOrders.isSuccess
                    ? "---"
                    : allOrders.data.totalCashReceived,
                )}{" "}
                <br /> Completed Payout value
              </Col>
              {/* <Col md={3}>₦{new Intl.NumberFormat().format(!allOrders.isSuccess ? "---" : allOrders.data.totalDeliveryFees)} <br /> Total Delivery Fees</Col> */}
            </Row>
            <hr />
            <Col className="mt-5">
              <Card>
                <Card.Body>
                  <Tabs defaultActiveKey="pending" id="u__tab" className="mb-3">
                    <Tab
                      eventKey="pending"
                      title={
                        <span>
                          <Badge className="tab__identifier tab__identifier_pending">
                            0
                          </Badge>{" "}
                          Pending
                        </span>
                      }
                    >
                      <Col className="mt-5 tab__">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>SN</th>
                              <th>Date</th>
                              <th>Sellers</th>
                              <th>Product Name</th>
                              <th>Amount</th>
                              <th>Payment Type</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginated
                              ? data?.content?.map((e: any, i: any) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>
                                    <img
                                      className="img-thumbnail rounded-circle"
                                      style={{ height: "50px" }}
                                      src={
                                        e.imageUrl !== null
                                          ? e?.imageUrl
                                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                      }
                                      alt=""
                                    />
                                  </td>
                                  <td>{e?.firstName + " " + e?.lastName}</td>
                                  <td>{e?.email}</td>
                                  <td>{e?.phoneNumber}</td>
                                  <td>{e?.gender}</td>
                                  <td>
                                    <ButtonGroup
                                      className="me-2"
                                      aria-label="Basic example"
                                    >
                                      <Button variant="primary text-white">
                                        View
                                      </Button>
                                      <Button variant="secondary">
                                        Edit
                                      </Button>
                                      <Button variant="danger">Delete</Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              ))
                              : result.isSuccess
                                ? result?.data?.content?.map((e: any, i: any) => (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                      <img
                                        className="img-thumbnail rounded-circle"
                                        style={{ height: "50px" }}
                                        src={
                                          e?.imageUrl !== null
                                            ? e?.imageUrl
                                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                        }
                                        alt=""
                                      />
                                    </td>
                                    <td>{e?.firstName + " " + e?.lastName}</td>
                                    <td>{e?.email}</td>
                                    <td>{e?.phoneNumber}</td>
                                    <td>{e?.gender}</td>
                                    <td>
                                      <ButtonGroup
                                        className="me-2"
                                        aria-label="Basic example"
                                      >
                                        <Button
                                          variant="primary"
                                          className="text-white"
                                        >
                                          View
                                        </Button>
                                        <Button variant="secondary">
                                          Edit
                                        </Button>
                                        <Button variant="danger">Delete</Button>
                                      </ButtonGroup>
                                    </td>
                                  </tr>
                                ))
                                : ""}
                          </tbody>
                        </Table>
                        {result.isSuccess ? (
                          <Pagination
                            pathname={pathname}
                            itemPerPage={
                              paginated ? data?.size : result?.data?.size
                            }
                            itemsLength={
                              paginated
                                ? data?.totalElements
                                : result?.data?.totalElements
                            }
                            handleChange={paginatePayout}
                            currentPage={
                              paginated ? data?.number : result?.data?.number
                            }
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Tab>
                    <Tab
                      eventKey="Completed"
                      title={
                        <span>
                          <Badge className="tab__identifier tab__identifier_pending">
                            0
                          </Badge>{" "}
                          Completed
                        </span>
                      }
                    >
                      <Card></Card>
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
