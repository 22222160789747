import * as Yup from 'yup';
import "yup-phone-lite";

const DeliveryAddFormValidation = () => Yup.object().shape({

    phoneNumber: Yup.string()
                 .phone("NG", "Provide a valid phone number")
                 .min(11, '*Phone number must be 11 char')
                 .max(11, '*Phone number must be 11 char')
                .required('*Phone Number is required'),
});

export default DeliveryAddFormValidation;