import React, { useState } from 'react';
import { Container, Form,Row,Col, InputGroup, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import  {useFormik} from 'formik';
import ProfileSideBar from './component/AccountSideBar';
import DefaultLayout from '../../../hoc/Layout/Default-Layout'
import updateAccountAddressFormValidation from '../../../validations/schemas/update-account-address.schema';
import { Link } from 'react-router-dom';
import { accountProfilePathUrl } from '../../../routes/constant';
import BreadCrumb from './component/BreadCrumb';


export default function UpdateAddress() {
    const [initialValues, setInitialValues] = useState({
                                        firstname: '',
                                        lastname: '',
                                        phonenumber: '',
                                        othernumber: '',
                                        address: '',
                                        otherinformation: '',
                                        region: '',
                                        city: '',
                                    })
    const formik = useFormik({
        initialValues:initialValues,
        enableReinitialize: true,
       validationSchema: updateAccountAddressFormValidation(),
          onSubmit: values => {
              alert(JSON.stringify(values))
            const objData = {
                firstname:  values.firstname,
                lastname:   values.lastname,
                phonenumber: values.phonenumber,
                othernumber: values.othernumber,
                address: values.address,
                otherinformation: values.otherinformation,
                region: values.region,
                city: values.city,
                    
            };
           
        }
    });

    console.log(formik.errors,'___');
    return (
        <DefaultLayout>
        <Helmet>
            <title>Ekenta - Update Address</title>
            <meta charSet="utf-8" />
            <meta name="title" content="Ekenta Update Address Page" />
            <meta name="description"
                    content="Ekenta Update Account Address Page"
            />
            <meta property="og:locale" content="en_NG" />
            <meta name="robots" content="noindex,follow" />
        </Helmet>
        <Container  className="account-user-container">
             <><BreadCrumb activePage="Update Address" /></>

            <Row className="d-none d-lg-block account-pro-row g-2">
                <Col xs lg={3} className="account-col-left">
                   <ProfileSideBar isLoading={null} />
                </Col>
                <Col className="account-profile-col-right px-0">
                    <div className="mb-2 border-bottom">
                        <h5 className="mx-1 display_lg_header pb-1">
                             <Link to={ `${accountProfilePathUrl}`}>
                                    <svg className="" width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0303 8.53033C11.3232 8.23744 11.3232 7.76256 11.0303 7.46967C10.7374 7.17678 10.2626 7.17678 9.96967 7.46967L5.96967 11.4697C5.82322 11.6161 5.75 11.8081 5.75 12C5.75 12.1017 5.77024 12.1987 5.80691 12.2871C5.84351 12.3755 5.89776 12.4584 5.96967 12.5303L9.96967 16.5303C10.2626 16.8232 10.7374 16.8232 11.0303 16.5303C11.3232 16.2374 11.3232 15.7626 11.0303 15.4697L8.31066 12.75H18C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25H8.31066L11.0303 8.53033Z" fill="black"/>
                                    </svg>
                                </Link>
                            <span>Address</span>
                        </h5>
                        </div>
                    <Form noValidate onSubmit={formik.handleSubmit}>
                            <Row className="account-details-row">
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">First Name</Form.Label>
                                        <Form.Control  type="text" name="firstname"
                                          placeholder=""
                                          autoComplete='off'
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.firstname}
                                           isInvalid={!!formik.errors.firstname}
                                           />
                                           {formik.touched.firstname && formik.errors.firstname ? (
                                            <Form.Control.Feedback className="_helper_text" type="invalid">
                                                {formik.errors.firstname}
                                            </Form.Control.Feedback>
                                           ): null}
                                    </Form.Group>

                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">Last Name</Form.Label>
                                        <Form.Control  type="text" name="lastname" 
                                          placeholder="Lastname"
                                          autoComplete='off'
                                           onChange={formik.handleChange}
                                           onBlur={formik.handleBlur}
                                           value={formik.values.lastname}
                                           isInvalid={!!formik.errors.lastname}
                                           />
                                           {formik.touched.lastname && formik.errors.lastname ? (
                                            <Form.Control.Feedback className="_helper_text" type="invalid">
                                                {formik.errors.lastname}
                                            </Form.Control.Feedback>
                                           ): null}
                                      </Form.Group>
                                </Col>
                               
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">Phone Number</Form.Label>
                                            <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">234</InputGroup.Text>
                                            <Form.Control  type="text" name="phonenumber" placeholder=""
                                                autoComplete='off'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phonenumber}
                                                isInvalid={!!formik.errors.phonenumber}
                                            />
                                        </InputGroup>
                                            {formik.touched.phonenumber && formik.errors.phonenumber ? (
                                                <Form.Control.Feedback className="_helper_text" type="invalid">
                                                    {formik.errors.phonenumber}
                                                </Form.Control.Feedback>
                                            ): null}
                                       </Form.Group>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">Phone Number <i>(Additional)</i></Form.Label>
                                            <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">234</InputGroup.Text>
                                            <Form.Control  type="text" name="othernumber" placeholder=""
                                                autoComplete='off'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.othernumber}
                                                isInvalid={!!formik.errors.othernumber}
                                            />
                                        </InputGroup>
                                            {formik.touched.othernumber && formik.errors.othernumber ? (
                                                <Form.Control.Feedback className="_helper_text" type="invalid">
                                                    {formik.errors.othernumber}
                                                </Form.Control.Feedback>
                                            ): null}
                                       </Form.Group>
                                </Col>
                                <Col sm={12} lg={12}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">Address</Form.Label>
                                            <Form.Control as="textarea" type="password" name="address" 
                                            placeholder=""
                                            autoComplete='off'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address}
                                            isInvalid={!!formik.errors.address}
                                            />
                                            {formik.touched.address && formik.errors.address ? (
                                                <Form.Control.Feedback className="_helper_text" type="invalid">
                                                    {formik.errors.address}
                                                </Form.Control.Feedback>
                                            ): null}
                                       </Form.Group>
                                </Col>
                                <Col sm={12} lg={12}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">Other Information</Form.Label>
                                            <Form.Control as="textarea" type="text" name="otherinformation" 
                                            placeholder=""
                                            autoComplete='off'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.otherinformation}
                                            isInvalid={!!formik.errors.otherinformation}
                                            />
                                            {formik.touched.otherinformation && formik.errors.otherinformation ? (
                                                <Form.Control.Feedback className="_helper_text" type="invalid">
                                                    {formik.errors.otherinformation}
                                                </Form.Control.Feedback>
                                            ): null}
                                       </Form.Group>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">Region</Form.Label>
                                        <Form.Select as="select"
                                            name="region"
                                             onChange={formik.handleChange}
                                              value={formik.values.region}
                                             onBlur={formik.handleBlur}
                                            >
                                            <option value="lagos">Lagos</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={6}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">City</Form.Label>
                                        <Form.Select as="select"
                                            name="city"
                                             onChange={formik.handleChange}
                                              value={formik.values.city}
                                             onBlur={formik.handleBlur}
                                            >
                                            <option value="lagos">Lagos</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                
                              <Col className="d-flex align-items-center justify-content-center mt-3" sm={12} md={12} lg={12}>
                                <Button className="w-50 nav_button d-block text-white px-4" type="submit">Save</Button>
                            </Col>
                            </Row>
                        </Form>
                </Col>
            </Row>
        </Container>
    </DefaultLayout>
    )
}
