import React,{useState} from 'react'
import Tippy from "@tippyjs/react";
import classnames from 'classnames';

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-toward.css";

import exportStyles from "./Export.module.scss";
import { FiUpload } from 'react-icons/fi';

interface ExportPropType{
    downloadData: (type: string) => void;
    name: string;
}

const Export = ({
    downloadData,
    name
}:ExportPropType) => {

    const [isVisible, setIsVisible] = useState(false);

    const dropdownMenu = (
            <ul className={exportStyles.dropdown_menu}>
                      
                <li
                    className="DropdownMenu__item"
                    onClick={() => {
                        downloadData("csv");
                        setIsVisible(false);
                    }}
                >
                    <span className="DropdownMenu__icon">
                        <svg
                            xmlns="https://www.w3.org/2000/svg"
                            xmlnsXlink="https://www.w3.org/1999/xlink"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            width="15" height="15"
                        >
                            <defs>
                                <path
                                    id="export-a"
                                    d="M12.4545455,14.125 L13.7272727,14.125 L13.7272727,13.5 L12.4545455,13.5 L12.4545455,14.125 Z M15,16 L1,16 L1,12.875 C1,12.53125 1.28636364,12.25 1.63636364,12.25 L14.3636364,12.25 C14.7136364,12.25 15,12.53125 15,12.875 L15,16 Z M6.09090909,1.625 C6.09090909,1.28125 6.37727273,1 6.72727273,1 L9.27272727,1 C9.62272727,1 9.90909091,1.28125 9.90909091,1.625 L9.90909091,6 L13.0209091,6 C13.3709091,6 13.4663636,6.20875 13.2321818,6.465 L8.36018182,11.785 C8.126,12.04125 7.74418182,12.04 7.51127273,11.7825 L2.69527273,6.4675 C2.46363636,6.21 2.55909091,6 2.90909091,6 L6.09090909,6 L6.09090909,1.625 Z"
                                ></path>
                            </defs>
                            <use fillRule="evenodd" xlinkHref="#export-a"></use>
                        </svg>
                    </span>
                    <span className="DropdownMenu__text">Export as CSV</span>
                </li>
                <li
                    className="DropdownMenu__item"
                    onClick={() => {
                        downloadData("excel");
                        setIsVisible(false);
                    }}
                >
                    <span className="DropdownMenu__icon">
                        <svg
                            xmlns="https://www.w3.org/2000/svg"
                            xmlnsXlink="https://www.w3.org/1999/xlink"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            width="15" height="15"

                        >
                            <defs>
                                <path
                                    id="external-a"
                                    d="M15,2.5 L15,13.5 C15,14.3284375 14.3284375,15 13.5,15 L2.5,15 C1.6715625,15 1,14.3284375 1,13.5 L1,2.5 C1,1.6715625 1.6715625,1 2.5,1 L13.5,1 C14.3284375,1 15,1.6715625 15,2.5 Z M13,3.97221875 C13,3.43528125 12.5647188,3 12.0277813,3 L7.89584375,3 C7.493125,3 7.1666875,3.32646875 7.1666875,3.7291875 L7.1666875,4.1949375 C7.1666875,4.6055 7.50553125,4.935125 7.9159375,4.9238125 L9.666,4.8741875 L3.184625,11.309625 C2.9390625,11.5534375 2.938375,11.950375 3.1830625,12.1950625 L3.80490625,12.8169062 C4.04959375,13.0615937 4.44653125,13.0609062 4.69034375,12.8153437 L11.1258125,6.33396875 L11.0761875,8.0840625 C11.064875,8.49446875 11.3945,8.8333125 11.8050625,8.8333125 L12.2708125,8.8333125 C12.6735313,8.8333125 13,8.50684375 13,8.10415625 L13,3.97221875 Z"
                                ></path>
                            </defs>
                            <use
                                fillRule="evenodd"
                                xlinkHref="#external-a"
                            ></use>
                        </svg>
                    </span>
                    <span>Export as Excel</span>
                </li>
            </ul>
    );

  return (
    <Tippy
        arrow={true}
        visible={isVisible}
        interactive={true}
        onClickOutside={() => setIsVisible(false)}
        content={dropdownMenu}
        placement="bottom-end"
        theme="light"
        animation="shift-toward"
        className={exportStyles.tippy_custom_styles}
    >
        <button
            onClick={() => setIsVisible((prev) => !prev)}
            type="button"
            className={classnames("Button Button--icon w-auto btn-icon ExportButton Button--iconOnly", exportStyles.btn, exportStyles.exportButton)}
        >
            <span className="Button__icon d-flex align-items-center">
                 <FiUpload fontSize="14" className='me-1' />
                {name}
            </span>
        </button>
    </Tippy>
  )
}

export default Export