import { useFetchAllAddressesQuery } from '@ekenta/redux/store/services/accountApi';
import React from 'react'
import { Button, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { FiPlusCircle } from 'react-icons/fi';
import styled from 'styled-components';
//import TextButton from '../../../../components/CustomButtons/TextButton';
import { useAppSelector } from '../../../../redux/store/hook';


interface ChangeAddressModalPropsType {
    isChangeAddress: boolean;
    data: any;
    isSuccess: boolean;
    isLoading: boolean;
    setSelectedAddress: any;
    setIsNewAddress: any;
    handleCloseChange: () => void;
    makeDefault: () => void;

}
const ChangeAddressModal = ({ isChangeAddress, data, isLoading, isSuccess, handleCloseChange, makeDefault,
    setSelectedAddress, setIsNewAddress
}: ChangeAddressModalPropsType) => {

    const { token, account } = useAppSelector(state => state.auth.authentication);
    const getResult = useFetchAllAddressesQuery(token);

    return (

        <ModalCard>
            <Modal
                show={isChangeAddress} onHide={handleCloseChange}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="prompt__dialog"
                centered
                style={{ width: "100% !important" }}>

                <Modal.Header closeButton>
                    <Modal.Title className="_heading">Change Address</Modal.Title>
                </Modal.Header>
                <Modal.Body as={ModalBody}>

                    <h5>Address List</h5>
                    <AddressList>
                        <Scrollbars style={{ width: "100%", height: '300px' }}>
                            {getResult?.data && getResult?.isSuccess ?
                                getResult?.data.map((item: any, index: number) => (
                                    <ListItem
                                        key={`${item.id}_${index}`}
                                        id={item.id}
                                        firstName={account.user.firstName}
                                        lastName={account.user.lastName}
                                        homeAddress={item.homeAddress}
                                        cityName={""}
                                        state={""}
                                        handleChange={() => {
                                            setSelectedAddress(item)
                                        }
                                        }
                                    />
                                )) : null}

                        </Scrollbars>
                    </AddressList>
                    <NewAddress>
                        <a onClick={() => setIsNewAddress(true)}>
                            <FiPlusCircle fontSize={15} />
                            <span>Add new address</span>
                        </a>
                    </NewAddress>
                    <Modal.Footer as={ModalFooter}>
                        <Button type="submit" className="confirm_button w-100" variant="primary"
                            onClick={makeDefault}>
                            {isLoading ? (<Spinner size="sm" animation="border" variant="light" />) :
                                (<span>USE ADDRESS</span>)
                            }
                        </Button>
                    </Modal.Footer>
                </Modal.Body>

            </Modal>
        </ModalCard>
    )
}

const ListItem = (props: {
    id: string;
    firstName: string;
    lastName: string;
    homeAddress: string;
    cityName: string;
    state: string;
    handleChange: () => void;
}) => (
    <ListItemChild>
        <div className="rowAlignment">
            <div className="rowAlignment__checkbox">
                <Form.Check
                    type={'radio'}
                    name={'address'}
                    id={props.id}
                    onChange={props.handleChange}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div className="column__AddressInfo">
                <h6>{props.firstName + ' ' + props.lastName}</h6>
                <span>{props.homeAddress + ' ' + props.cityName + ', ' + props.state}</span>
            </div>
        </div>
    </ListItemChild>
)

const ModalCard = styled.div`

    width: 100% !important;
    
    .modal .prompt__dialog{
        width: 100% !important;  
    }

    .prompt__dialog .modal-content .modal-footer button {
            background-color: #E04826 !important; // #232F3E
            border-color: #E04826 !important;
            outline: none !important;
            box-shadow: none;

            &:focus{
                outline: none !important;
                box-shadow: none !important;
            }
        }

   

`;

const ModalBody = styled.div`
    h5{
        font-size: 13px;
        padding-bottom: 5px;
        padding-top: 10px;
        font-weight: 600;
        color: gray;
        padding-left: 14px;
        text-transform: uppercase;
   }
`

const AddressList = styled.div`
   width: 100%;
   height: 300px;
   overflow-x: hidden;
   overflow-y: auto;

 

   
`;

const ListItemChild = styled.div`
  width: 100%;

    .rowAlignment{
        display:flex;
        align-items: flex-start;
        margin-bottom: 20px;
        gap: 10px;
        padding-left: 15px;

        .column__AddressInfo{
            h6{
                margin: 0 !important;
                font-size: 15px;
                color: #000;
                font-weight: 500;
                padding-bottom: 3px;
                font-family: "Noto Sans", sans-serif !important;
            }
            span{
                display: block!important;
                font-size: 13px;
                color: gray;
                font-weight: 400;
                font-family: "Noto Sans", sans-serif !important;
            }
        }
    }
`;

const NewAddress = styled.div`
  a{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: -10px;
      gap: 4px;
      font-weight: 400;
      text-transform: uppercase;
  
      font-size: 12.5px;
      color: #fc624d !important;  // #60A5FA; //
      cursor: pointer;
      font-family: "Noto Sans", sans-serif !important;
  }

`;

const ModalFooter = styled.div`

        button {
            font-weight: 600 !important;
            font-size: 13px;
            color: #fff !important;
            text-transform: uppercase;
            font-family: "Noto Sans", sans-serif !important;
            background-color:#fc624d !important; // #232F3E
        }
    

`;

export default ChangeAddressModal