export const Roles = {
    ROLE_USER: "ROLE_USER",
    ROLE_SELLER: "ROLE_SELLER",
    ROLE_ADMIN: "ROLE_ADMIN",
}


export const RoleAray = [
    {
        "id": 1,
        "description": "The person that created the an account",
        "name": "ROLE_USER"
    },
    {
        "id": 2,
        "description": "A user that switch to a seller mode",
        "name": "ROLE_SELLER"
    },
    {
        "id": 3,
        "description": "Internal administrative staff",
        "name": "ROLE_ADMIN"
    }
]