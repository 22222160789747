import React from "react";
import LiveIconComp from "@ekenta/components/LiveCommerce/LiveIconComp";
import { LiveStream_BaseURL } from "@ekenta/constants";
import { SellerNotLive } from "./innercomponents/SellerNotLive";


export default function VideoStreamsContainer({liveCode,isStream,businessName}:
                        {liveCode:string | null; isStream:boolean; businessName:string;}) {
 
  return (
    <>
    <div className="video__streaming__section position-relative">
      <div  className="video__player__s3 vj3 " webkit-playsinline="true">
        {liveCode ? (
          <>
            {isStream  ? (
              <>
              <LiveIconComp />
                <div className="col-12 iframe-stream-div">
                  <div className=" text-center">
                    <iframe className="live-stream-frame" 
                        src={`${LiveStream_BaseURL}${liveCode}`} 
                        name="upstream" scrolling="no" allow="">
                      </iframe>
                  </div>
                </div>
                <div className="shade__startStream"></div>
              </>
            ): <SellerNotLive businessName={businessName} />}
          </>
          ): null}
      

         </div>
    </div>
    </>
  )
}
