import styled from "styled-components";

export const OverlayFixed = styled.div`
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.9); /* Black background with opacity */
    //z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
    display: block;
`;

export const HoveringComponent = styled.li`
    list-style: none !important;
    width: auto;
    height: auto;
    padding:  0.3rem 0 !important;

    margin: 0 !important;
`;