import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import StarRatings from "react-star-ratings";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  FiChevronDown,
  FiExternalLink,
  FiHeart,
  FiShare2,
} from "react-icons/fi";
import ProductDetailSlider from "../component/Slider/ProductDetailSlider";
import {
  useFetchSingleProductByIdQuery,
} from "../../../redux/store/services/productsApi";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { mapCartDataReturnQuantity, slugifyText } from "@ekenta/utils";
import NumberFormat from "react-number-format";
import { useAppSelector } from "@ekenta/redux/store/hook";
import AddCartButton from "@ekenta/components/Cart/CartButtons/AddCartButton/AddCartButton";
import {
  useFetchCartItemsQuery,
  useFindUserCartItemByIdReturnQuantityQuery,
} from "@ekenta/redux/store/services/cartApi";
import QuantityCartButton from "@ekenta/components/Cart/CartButtons/QuantityCartButton/QuantityCartButton";
import JustForYouComponent from "../component/JustForYouSection";
import { MdVerified } from "react-icons/md";
import ColorBox from "@ekenta/components/Box/ColorBox";
import LiveIconComp from "@ekenta/components/LiveCommerce/LiveIconComp";
import liveimg from "@ekenta/assets/images/TB1live.gif";
import { productBaseUrl, singleUserLiveBaseUrl } from "@ekenta/routes/constant";
import { HelmetHeader, SocialShare } from "@ekenta/components";
import { ProductDetailLoader } from "../component/SkeletonLoader/ProductDetailLoader";
import StockEmpty from "@ekenta/components/StockEmpty/StockEmpty";
import { withDefaultLayout } from "@ekenta/hoc";

import "./Detail.scss";
import Tag from "@ekenta/components/Tag/Tag";

const mapMediaDataToSlider = (images: string[]) => {
  if (!images) return [];
  if (images.length == 0) return [];
  const listImages: any[] = [];
  const mapD =
    images.length > 0 &&
    images.map((item) => {
      if (item) {
        const data = {
          original: `${item}`,
          thumbnail: `${item}`,
        };
        listImages.push(data);
      }
    });

  return listImages;
};

export const mapCartDataReturnLocalQuantity = (
  cartData: any,
  productid: number,
) => {
  return cartData
    ? cartData.find((item: any) => {
      if (item.product.id === productid) {
        return item;
      }
    })
    : null;
};

const ProductDetail = () => {
  const params = useParams();

  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const { cartItems } = useAppSelector((state) => state.cart.cart);

  const [cartItemId, setCartItemId] = useState<null | string>(null);
  const [localCartItem, setLocalCartItem] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [_mediaList, setMediaList] = useState<
    { original: string; thumbnail: string }[] | null
  >(null);


  const productId = params["productslug"]
    ? params.productslug!.slice(params.productslug!.lastIndexOf("-") + 1)
    : null;
  const {
    data: productData,
    isLoading: productIsLoading,
    isSuccess: productIsSuccess,
  } = useFetchSingleProductByIdQuery(productId, { skip: !productId });


  const _slugCollection = "/";

  // const _slugCollection = productIsSuccess
  //   ? slugifyText(
  //     productData.subCollectionId.toLowerCase(),
  //     productData.subCollection.id,
  //   )
  //   : "/";

  const { data: allCartData } = useFetchCartItemsQuery(token, { skip: !token });
  const { data } = useFindUserCartItemByIdReturnQuantityQuery(
    { token, cartItemId },
    {
      skip: !cartItemId,
    },
  );


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!ek_isAuth && productData) {
      const items = mapCartDataReturnLocalQuantity(cartItems, productData.id);
      setLocalCartItem(items);
    }
  }, [ek_isAuth, cartItems, productData]);

  useEffect(() => {
    if (productData) {
      const result = mapMediaDataToSlider(productData.product.imageUrls);
      const allSlideImGVid = [
        {
          id: "video",
          original: productData.product.imageUrls[1],
          thumbnail: productData.product.imageUrls[0],
          embedUrl: productData.product.videoUrl,
        },
        ...result,
      ];
      setMediaList(allSlideImGVid);

    }

    if (allCartData && productData) {
      const cartItemId = mapCartDataReturnQuantity(allCartData, productData.id);
      if (cartItemId) {
        setCartItemId(cartItemId.id);
      } else {
        setCartItemId(null);
      }
    }
  }, [productData, allCartData]);

  console.log(_mediaList)


  function getProductDetailUrl() {
    return `${window.location.origin}${productBaseUrl}/${params["productslug"]}`;
  };

  function calculatePercentage(maxPrice: number, minPrice: number) {
    const percent = ((maxPrice - minPrice) / maxPrice) * 100;
    return `-${parseInt(percent.toFixed(2))}`;
  };

  function setDiscountPercent(discountPercentage: number) {
    return `-${discountPercentage}`;
  }

  function calculateTotalPrice(price: number, discountVal: number) {
    return discountVal && discountVal > 0 ? Math.ceil(price - discountVal) : price;
  }


  return (
    <>
      <HelmetHeader
        title={`${productIsLoading
          ? "Loading...  "
          : productIsSuccess
            ? productData.product.name
            : ""
          }`}
        description="Ekenta Detail Page"
      />
      <div className="dF_aS_">
        {productIsLoading ? (
          <ProductDetailLoader />
        ) : (
          <>
            <div className="mt-3 _t_wrapper">
              <div className="_sub_wrapper">
                <Breadcrumb className="_p_">
                  <Breadcrumb.Item>
                    <Link to={`/`}>Home</Link>
                  </Breadcrumb.Item>
                  {/* {productIsSuccess && (
                    <Breadcrumb.Item>
                      <Link to={`/subcollection/${_slugCollection}`}>
                        {productData.subCollection.name}
                      </Link>
                    </Breadcrumb.Item>
                  )} */}
                  {productIsSuccess && (
                    <Breadcrumb.Item className="active_breadcrumb">
                      {productData.product.name.toLowerCase()}
                    </Breadcrumb.Item>
                  )}
                </Breadcrumb>
              </div>
            </div>

            <div className="CCS">
              {productIsSuccess ? (
                <>
                  <div className="_prod_wrapper_">
                    <div className="_top_level_">
                      <Card className="_top_level__Card">
                        <Card.Body>
                          <div className="_row_main__">
                            <div className="row__col_first_wrap">
                              <div className="slider__carouel_container carmt-2">
                                <div className="carousel__ecommerce__style position-relative">
                                  {/* {productData
                                    ? productData.user.isSellerLive && (
                                      <LiveIconComp />
                                    )
                                    : null} */}

                                  {_mediaList ? (
                                    <ProductDetailSlider
                                      imageList={productData.imageUrls}
                                      images={_mediaList}
                                    />
                                  ) : (
                                    <div className="place--holder">holder</div>
                                  )}
                                  <Row className="zoom__magnify__portal">
                                    <Col xs={2}>
                                      {" "}
                                      <div id="myPortal" />
                                    </Col>
                                  </Row>
                                  <div className="_slider_wrap_share_like">
                                    <div className="_favourite_share_btns">
                                      <div className="favorite_or_saved_item">
                                        <a className="save-item-btn">
                                          <FiHeart fontSize={16} />
                                        </a>
                                      </div>
                                      <div>
                                        <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip id="button-tooltip-2">
                                              Share
                                            </Tooltip>
                                          }
                                        >
                                          <span className="share-to-social">
                                            <FiShare2 onClick={handleShow} />
                                          </span>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="_row__col_second_wrap_sideZ">
                              <Card>
                                <Card.Body>
                                  <div className="_container_rig_col">
                                    <div className="_rig_top_col">
                                      <div className="_top_rig_detailZ">
                                        {/* {productData
                                          ? productData.user.isSellerLive && (
                                            <div className=" mb-2">
                                              <Link
                                                to={`${singleUserLiveBaseUrl}-${productData.user.id}`}
                                                className="_visit_href_"
                                              >
                                                <img
                                                  className="me-1"
                                                  src={liveimg}
                                                  width="10"
                                                  alt="Ekenta Livecommerce"
                                                />
                                                <span>
                                                  Vist Vendor Livestream
                                                </span>
                                                <FiExternalLink />
                                              </Link>
                                            </div>
                                          )
                                          : null} */}


                                        <h3 className="_product__title_">
                                          {productData.product.name.toLowerCase()}
                                        </h3>
                                      </div>
                                      <div className="_rating_row_ m-0">
                                        <div className="_k_rating_row">
                                          <StarRatings
                                            rating={
                                              0
                                            }
                                            starDimension="18px"
                                            starSpacing="3px"
                                            starRatedColor={"#fc624d"}
                                            numberOfStars={5}
                                            name="rating"
                                          />
                                          <div className="d-rating-score">
                                            <NumberFormat
                                              value={
                                                0
                                              }
                                              displayType="text"
                                              decimalSeparator="."
                                              decimalScale={1}
                                              fixedDecimalScale
                                              allowNegative={false}
                                            />
                                            <FiChevronDown />
                                          </div>
                                        </div>
                                        <div className="rating_que_">
                                          0 review
                                        </div>
                                      </div>
                                      <div className="split-line-thinner"></div>
                                      <div className="_price_prod_div">
                                        <span className="_price__digit_ mt-0">
                                          <NumberFormat
                                            value={calculateTotalPrice(productData.product.price, productData.product.discount)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="₦"
                                            className="some"
                                          />

                                        </span>
                                        <span className="Price__digit_discounted">
                                          {productData.product.discount && productData.product.discount > 0
                                            ? (
                                              <>
                                                <NumberFormat
                                                  value={`${productData.product.price}`}
                                                  displayType="text"
                                                  thousandSeparator={true}
                                                  prefix="₦"
                                                  className="some"
                                                />
                                                <Tag
                                                  numberOf={setDiscountPercent(productData.product.discountPercentage)}
                                                  className="priceOff"
                                                />
                                              </>
                                            ) : parseInt(productData.product.maxPrice) > parseInt(productData.product.price) ? (
                                              <>
                                                <NumberFormat
                                                  value={`${productData.product.maxPrice}`}
                                                  displayType="text"
                                                  thousandSeparator={true}
                                                  prefix="₦"
                                                  className="some"
                                                />
                                                <Tag
                                                  numberOf={calculatePercentage(productData.product.maxPrice, productData.product.minPrice)}
                                                  className="priceOff"
                                                />
                                              </>
                                            ) : null}

                                        </span>
                                      </div>
                                      <div className="product-discount-info">
                                        {" "}
                                        {productData.stock} item{productData.product.stock > 1 ? "s" : ""} in
                                        stock
                                        <i className="next-icon next-icon-arrow-down next-xxs"></i>
                                      </div>

                                      <Col xs={12} className="mt-2 mb-2">
                                        {/* {productData &&
                                          productData.stock >= 1 ? (
                                          <Fragment>
                                            {ek_isAuth && token ? (
                                              <>
                                                {cartItemId && data ? (
                                                  data >= 1 && (
                                                    <QuantityCartButton
                                                      cartItems={""}
                                                      cartItemId={
                                                        cartItemId
                                                          ? cartItemId
                                                          : ""
                                                      }
                                                      className="quantity--picker"
                                                    />
                                                  )
                                                ) : (
                                                  <AddCartButton
                                                    productId={productData.id}
                                                    productname={
                                                      productData.name
                                                    }
                                                    product={productData}
                                                    name="Add to cart"
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {localCartItem &&
                                                  localCartItem.quantity >= 1 ? (
                                                  <QuantityCartButton
                                                    cartItems={localCartItem}
                                                    cartItemId={
                                                      localCartItem.product.id
                                                    }
                                                    className="quantity--picker"
                                                  />
                                                ) : (
                                                  <AddCartButton
                                                    productId={productData.id}
                                                    productname={
                                                      productData.name
                                                    }
                                                    product={productData}
                                                    name="Add"
                                                  />
                                                )}
                                              </>
                                            )}
                                          </Fragment>
                                        ) : (
                                          <StockEmpty />
                                        )} */}
                                      </Col>
                                      <div className="split-line-thinner"></div>
                                    </div>
                                    <div className="_rig_bottom_col">
                                      <Col className="__feature__parent__">
                                        <div className="__feature__child">
                                          Brand:
                                          <span className="__feature__value">
                                            {" "}
                                            {/* {productData?.brand?.name} */}
                                          </span>
                                        </div>
                                      </Col>
                                      {productData.productFeatureList.length > 0
                                        ? productData.productFeatureList.map(
                                          (e: any, i: any) => {
                                            return (
                                              <>
                                                {e.feature &&
                                                  e.featureValue ? (
                                                  <Col
                                                    className="__feature__parent__"
                                                    key={"id_" + i}
                                                  >
                                                    <div className="__feature__child">
                                                      {e.feature
                                                        ? `${e.feature.name}:`
                                                        : null}
                                                      <span className="__feature__value">
                                                        {" "}
                                                        {e.featureValue.toLowerCase()}
                                                      </span>
                                                    </div>
                                                    {e.feature.name.toLowerCase() ===
                                                      "color" && (
                                                        <ul className="__feature__ulist">
                                                          <li
                                                            className="__feature__ulist_item"
                                                            data-spm-index="li_no"
                                                          >
                                                            <ColorBox
                                                              color={e.featureValue.toLowerCase()}
                                                            />
                                                          </li>
                                                        </ul>
                                                      )}
                                                  </Col>
                                                ) : null}
                                                <div className="__feature__parent__">
                                                  <div className="__feature__child mt-1">
                                                    <span className="me-1">
                                                      Condition:
                                                    </span>

                                                    <span className="pr_condition_sec">
                                                      {productData?.product.condition ==
                                                        "new" ? (
                                                        <span className="span__new">
                                                          New
                                                        </span>
                                                      ) : (
                                                        <span
                                                          className="span__used"
                                                          style={{
                                                            backgroundColor:
                                                              "#7C420C",
                                                          }}
                                                        >
                                                          Used
                                                        </span>
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          },
                                        )
                                        : null}

                                      <Col className="__delivery_dynamic_div_">
                                        <span className="__delivery_dynamic_title">
                                          Delivery Charges
                                        </span>
                                        <div className="__delivery_dynamic_description">
                                          <span className="d-block">
                                            Select a Delivery agency of your
                                            choice at checkout to see delivery
                                            charges.
                                          </span>
                                          Deliver to all state in Nigeria
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {productIsSuccess ? (
              <div className="CSS__Bottom__gen">
                <div className="_top__store__brand">
                  <Card>
                    <Card.Body>
                      <h5 className="mb-3">Product description</h5>
                      <div>{productData.product.shortDescription}</div>
                    </Card.Body>
                  </Card>
                </div>

                <div className="_down__list__prod_desc">
                  <Card>
                    <Card.Body>
                      <h5 className="mb-3">Features</h5>
                      <Row>
                        <Col>
                          <h6>Key Features</h6>
                          <div>
                            {productData.productFeatureList.length > 0
                              ? productData.productFeatureList.map(
                                (e: any, i: any) => (
                                  <Col className="__size__parent__" key={i}>
                                    <span className="_size__">
                                      {e.feature
                                        ? `${e.feature.name}:`
                                        : null}{" "}
                                    </span>
                                    <span style={{ color: "gray" }}>
                                      {" "}
                                      {e.featureValue}
                                    </span>
                                  </Col>
                                ),
                              )
                              : null}
                          </div>
                        </Col>
                        <Col>
                          <h6>Warranty</h6>
                          <p>
                            Product Warranty{" "}
                            {productData.product.hasWarranty ? "Yes" : "No"}
                          </p>
                        </Col>
                        <Col>
                          <h6>SKU</h6>
                          <p>{productData.product.skus[0]}</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>

                <div className="pd_crow j_for_you mt-5">
                  {/* <div className="main-title-u">
                                        <div className="_1KOw9" data-spm-id="2g0o">
                                            <div className="_16GMq"></div>
                                            <div className="_2jb">Just For You</div>
                                            <div className="_16GMq"></div>
                                        </div>
                                    </div> */}
                  <h5 className="mb-3">Just For You</h5>
                  <JustForYouComponent isShadow={true} />
                </div>

                <div className="_top__store__brand">
                  <Card>
                    <Card.Body>
                      <h5 className="mb-3">Verified Customer Feedback</h5>
                      <div>
                        {[1, 2, 3].map((e, i) => (
                          <Row key={i}>
                            <Col lg={10}>
                              <StarRatings
                                rating={3.403}
                                starDimension="18px"
                                starSpacing="3px"
                                starRatedColor={"#fc624d"}
                                numberOfStars={5}
                                name="rating"
                              />
                              <p>I like it</p>
                              <p>Lovely</p>
                              <p>03-06-2022 by gabriel</p>
                            </Col>
                            <Col className="down_flex">
                              <MdVerified />{" "}
                              <span> &nbsp; Verified Purchase</span>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </Card.Body>
                  </Card>
                </div>

                <div className="_top__store__brand">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col>
                          <h5>Seller&apos;s information</h5>
                          <p>Bussiness Name: {productData.sellerName}</p>
                          <p>Vendors ratings: {productData.averageSellerRating} </p>
                          <p>Number of followers: {productData.numberOfSellerFollowers}</p>

                          <button className="btn followButton">FOLLOW</button>
                        </Col>
                        <Col>
                          <h5>Seller&apos;s performance</h5>
                          <p>Order fulfillment rate:</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : null}
            {/* Modal Overlay for share  */}
            <Modal
              className="modal_component shadow"
              centered
              show={show}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Share</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <SocialShare
                  url={getProductDetailUrl()}
                  title={
                    productData
                      ? productData.product.name
                      : "Buy,Sell New and Used Product on Ekenta "
                  }
                />
              </Modal.Body>
            </Modal>
          </>
        )}

        <div className="_recommend_pro_detail_">
          <Container className="p-0">
            <br />
            <Row className="p-0">{/* <RecommendedSliderCard /> */}</Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default withDefaultLayout(ProductDetail);
