import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createFormDataMutationWithoutTokenRequest, createMutationRequestWithBody, createQueryRequest, createQueryRequestWithBody, createQueryWithTokenRequest, createQueryWithTokenRequestByText } from '.';
import { BASE_URL } from '../../../constants/index';


export const productsApi: any = createApi({
    reducerPath: 'productsApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Product', 'Viewed'],
    endpoints: (builder) => ({
        fetchAllProduct: builder.query<void, { productId: string; token: string; }>({
            query: () => createQueryRequest({ url: `/api/product/all`, method: 'GET' }),
            providesTags: ['Product'],
        }),
        addToAndRemoveFavoriteItem: builder.query<void, { token: string, productId: string }>({
            query: ({ token, productId }) => createQueryWithTokenRequest({ url: `/api/addToAndRemoveFromFavorite/${productId}`, method: 'POST', token: token }),
            providesTags: ['Product'],
        }),
        fetchProductBySubCollectionId: builder.query<void, { subCollectionId: number, pageno: string }>({
            query: ({ subCollectionId, pageno }) => createQueryRequest({ url: `/api/product/subcollection/${subCollectionId}?page=${pageno}`, method: 'GET' })
        }),
        fetchProductBySubCategoryCollectionId: builder.query<void, { categoryId: number, pageno: string }>({
            query: ({ categoryId, pageno }) => createQueryRequest({ url: `/api/product/category/${categoryId}?page=${pageno}`, method: 'GET' })
        }),
        fetchProductByCollectionId: builder.query<void, number>({
            query: (collectionId) => createQueryRequest({ url: `/api/product/collection/${collectionId}`, method: 'GET' })
        }),
        fetchSingleProductById: builder.query<void, number>({
            query: (productId) => createQueryRequest({ url: `/api/product/${productId}`, method: 'GET' })
        }),
        fetchSavedItems: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/viewFavorites`, method: 'GET', token: token }),
        }),
        generateSku: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequestByText({ url: `/api/sku-generator`, method: 'GET', token: token })
        }),

        fetchSearchProductsR: builder.query<any, { keyword: string; pageno: string }>({
            query: ({ keyword, pageno }) => createQueryRequest({ url: `/api/auth/search-for-products-and-merchant-businesses-by-keyword?keyword=${keyword}&page=${pageno}`, method: 'GET' }),
        }),

        fetchSearchProWithFilter: builder.mutation<any, { body: any, token: string, pageNo: any }>({
            query: ({ body, token, pageNo }) => createFormDataMutationWithoutTokenRequest({ url: `/api/filter-and-sort-products?page=${pageNo}`, method: 'POST', body: body }),
        }),

        searchSellerByMerchant: builder.query<void, any>({
            query: ({ query, token }) => createQueryWithTokenRequest({ url: `/api/list-of-sellers-by-merchant-business-name?merchantBusinessName=${query}`, method: 'GET', token: token }),
        }),

        // cancelOrder: builder.query<void, any>({
        //     query: ({orderId}) =>  createQueryRequestWithBody({url:`/api/cancel-order/${orderId}`,method:'PUT', body:{}})
        // }), 

        cancelOrder: builder.mutation<any, any>({
            query: ({ orderId, token }) => createMutationRequestWithBody({ url: `/api/cancel-order/${orderId}`, method: 'PUT', body: {}, token: token })
        }),
        fetchAllRatingsForSingleProduct: builder.query<void, number>({
            query: (productId) => createQueryRequest({ url: `/api/product/product-ratings/${productId}`, method: 'GET' })
        }),

        // fetchAverageRatingsForSingleProduct: builder.query<void, number>({
        //     query: (productId) => createQueryRequest({ url: `/api/product/average-product-ratings/${productId}`, method: 'GET' })
        // }),

        fetchJustForYouProduct: builder.query<void, { productId: string; token: string; }>({
            query: () => createQueryRequest({ url: `/api/product/just-for-you`, method: 'GET' }),
            providesTags: ['Product'],
        }),

        fetchSortByHighest: builder.query<void, { collectionId: string; token: string; }>({
            query: ({ collectionId }) => createQueryRequest({ url: `/api/product/sort-by-highest/${collectionId}`, method: 'GET' }),
            providesTags: ['Product'],
        }),

        fetchProductByBrand: builder.query<void, string>({
            query: (brandId) => createQueryRequest({ url: `/api/product/brand/${brandId}`, method: 'GET' }),
            providesTags: ['Product'],
        }),

        updateProduct: builder.mutation<any, any>({
            query: ({ productId, data, token }) => createMutationRequestWithBody({ url: `/api/product/update/${productId}`, method: 'PUT', body: data, token: token }),
            invalidatesTags: ['Product'],
        }),

        addRecentlyViewedProduct: builder.mutation<any, { productId: string }>({
            query: ({ productId }) => createFormDataMutationWithoutTokenRequest({ url: `/api/recently-viewed/${productId}`, method: 'POST', body: {} }),
            invalidatesTags: ['Viewed'],
        }),

        getAllRecentlyViewed: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/view-user-all-recently-viewed-item`, method: 'GET', token: token }),
            providesTags: ['Viewed'],
        }),

    }),
});

export const {
    useFetchAllProductQuery,
    useFetchProductBySubCollectionIdQuery,
    useFetchProductByCollectionIdQuery,
    useFetchProductBySubCategoryCollectionIdQuery,
    useFetchSingleProductByIdQuery,
    useAddToAndRemoveFavoriteItemQuery,
    useFetchSavedItemsQuery,
    useFetchSearchProductsRQuery,
    useCancelOrderMutation,
    useGenerateSkuQuery,
    useFetchAllRatingsForSingleProductQuery,
    //useFetchAverageRatingsForSingleProductQuery,
    useFetchJustForYouProductQuery,
    useFetchSortByHighestQuery,
    useFetchProductByBrandQuery,
    useUpdateProductMutation,

    useGetAllRecentlyViewedQuery,
    useAddRecentlyViewedProductMutation,
    useSearchSellerByMerchantQuery
} = productsApi;

