import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import {
  Container,
  Col,
  Card,
  Form,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import { FiLock, FiUser } from "react-icons/fi";
import GoogleLogin from "react-google-login";
//import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import loginFormValidation from "../../../validations/schemas/account-login.schema";
import { setUser } from "../../../redux/store/states/reducerSlice/authenticationSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store/hook";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { toast } from "react-toastify";
import { gapi } from "gapi-script";

// redux store
import {
  useSigninUserMutation,
  useSigninUserWithGoogleQuery,
} from "@ekenta/redux/store/services/authenticationApi";
import { GOOGLE_CLIENTID, FACEBOOK_APPID } from "@ekenta/constants";
import {
  DashboardPathUrl,
  DashboardSellers,
  forgotPassPathUrl,
  homePathUrl,
  registerPathUrl,
} from "@ekenta/routes/constant";
import { checkIfAdmin, checkIfAdminOrSellerRoleExist } from "@ekenta/utils";

import { removeLocalCart } from "@ekenta/redux/store/states/reducerSlice/cartSlice";
import { HelmetHeader } from "@ekenta/components";
import { useAddLocalCartItemMutation } from "@ekenta/redux/store/services/cartApi";
import { withAuthenticationLayout } from "@ekenta/hoc";

import "./Login.scss";

const Login = () => {
  const { state }: any = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [idGoogleToken, setIdGoogleToken] = useState("");
  //const [idFacebookToken, setIdFacebookToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { cart } = useAppSelector((state) => state.cart);

  const [signinUser, { data, isLoading, isError, error, isSuccess }] =
    useSigninUserMutation();
  const {
    data: google_result_data,
    isLoading: google_isLoading,
    isError: google_isError,
    error: google_error,
    isSuccess: google_isSuccess,
  } = useSigninUserWithGoogleQuery(idGoogleToken, {
    skip: idGoogleToken == "",
  });

  // const facebookResult = useSigninUserWithFacebookQuery(idFacebookToken, {
  //   skip: idFacebookToken == "",
  // });

  const [addLocalCartItem] = useAddLocalCartItemMutation();

  useEffect(() => {
    if (isError && error.status === "FETCH_ERROR") {
      toast.error(`Something went wrong,Try again`, {
        toastId: "error-status-id-toast-error",
      });
    }

    if (isError && error.status === 401) {
      toast.error(`Wrong email/password`, {
        toastId: "wrong-password-id-toast-error",
      });
    }

    if (google_isError && google_error.status) {
      toast.error(`Logging with Google failed`, {
        toastId: "google-P-id-toast-success",
      });
    }

    //Email Verification success
    if (state && state.status === "success") {
      toast.success(`${state.statusMsg}`, {
        toastId: "state-un-id-toast-success",
      });
    }

    //Email Verification error
    if (state && state.status === "error") {
      toast.error(`${state.statusMsg}`, {
        toastId: "state-un-id-toast-error",
      });
    }

    // if (facebookResult?.isError && facebookResult?.error) {
    //   toast.error(`Logging with Facebook failed`, {
    //     toastId: "facebook-login-2-id-toast-error",
    //   });
    // }
  }, [state, google_isError, isError, error]);

  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({
  //       clientId: GOOGLE_CLIENTID ? GOOGLE_CLIENTID : "",
  //       scope: "email profile",
  //     });
  //   }
  //   gapi.load("client:auth2", start);
  // }, []);

  useEffect(() => {
    if (isSuccess) {
      moveLocalCartToApi(data.token.id_token);
    }

    if (google_isSuccess) {
      moveLocalCartToApi(google_result_data.token.id_token);
    }
  }, [isSuccess, google_isSuccess]);

  const moveLocalCartToApi = useCallback((token: string) => {
    const arr = cart?.cartItems;
    if (arr.length > 0) {
      const cartItemsLocal = [];
      for (let i = 0; i < arr.length; i++) {
        cartItemsLocal.push({
          productId: arr[i].product.id,
          quantity: 1,
          price: arr[i].product.price,
        });
      }
      useAddLocalCartItem(cartItemsLocal, token);
    }
  }, []);

  const useAddLocalCartItem = async (cart: any, token: string) => {
    addLocalCartItem({
      cart,
      token,
    });
    dispatch(removeLocalCart());
    // await axios
    //   .post(`${BASE_URL}/api/cart/cart-item-list`, cart, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((resp) => {
    //     dispatch(removeLocalCart());
    //   })
    //   .catch((error: any) => {
    //     console.log(error);
    //   });
  };

  // DEFAULT AUTHENTICATION RESPONSE
  if (isSuccess) {
    dispatch(
      setUser({
        user: {
          id: data.id,
          username: data.username,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          verified: data.verified,
          phoneNumber: data.phoneNumber,
          gender: data.gender,
        },
        token: data.token.id_token,
        anonymousUser: false,
        ek_isAuth: true,
      }),
    );

    if (checkIfAdminOrSellerRoleExist(data.token.id_token) === true) {
      if (checkIfAdmin(data.token.id_token)) {
        navigate(DashboardSellers);
      } else {
        navigate(DashboardPathUrl);
      }
    } else {
      navigate(homePathUrl);
    }

    toast.success(`You"ve successfully login`, {
      toastId: "login-success-id-toast-success",
    });
  }

  // GOOGLE AUTHENTICATION RESPONSE
  if (google_isSuccess) {
    dispatch(
      setUser({
        user: {
          id: google_result_data.id,
          username: google_result_data.username,
          firstName: google_result_data.firstName,
          lastName: google_result_data.lastName,
          email: google_result_data.email,
          verified: google_result_data.verified,
          phoneNumber: google_result_data.phoneNumber,
          gender: google_result_data.gender,
        },
        token: google_result_data.token.id_token,
        anonymousUser: false,
        ek_isAuth: true,
      }),
    );

    if (
      checkIfAdminOrSellerRoleExist(google_result_data.token.id_token) === true
    ) {
      if (checkIfAdmin(google_result_data.token.id_token)) {
        navigate(DashboardSellers);
      } else {
        navigate(DashboardPathUrl);
      }
    } else {
      navigate(homePathUrl);
    }

    toast.success(`You"ve successfully login`, {
      toastId: "login-GOOGLE-success-id-toast-success",
    });
  }

  //FACEBOOK AUTHENTICATION SUCCESS RESPONSE
  // if (facebookResult?.isSuccess) {
  //   dispatch(
  //     setUser({
  //       user: {
  //         id: facebookResult?.data.id,
  //         username: facebookResult?.data.username,
  //         firstName: facebookResult?.data.firstName,
  //         lastName: facebookResult?.data.lastName,
  //         email: facebookResult?.data.email,
  //         verified: facebookResult?.data.verified,
  //       },
  //       token: facebookResult?.data.token.id_token,
  //       anonymousUser: false,
  //       ek_isAuth: true,
  //     }),
  //   );

  //   if (
  //     checkIfAdminOrSellerRoleExist(facebookResult?.data.token.id_token) ===
  //     true
  //   ) {
  //     if (checkIfAdmin(facebookResult?.data.token.id_token)) {
  //       navigate(DashboardSellers);
  //     } else {
  //       navigate(DashboardPathUrl);
  //     }
  //   } else {
  //     navigate(homePathUrl);
  //   }

  //   toast.success(`You"ve successfully login`, {
  //     toastId: "login-facebook-success-id-toast-success",
  //   });
  // }

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: loginFormValidation(),
    onSubmit: (values) => {
      const objData = {
        login: values.login.toLowerCase(),
        password: values.password,
      };
      signinUser(objData);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const respGoogleSuccess = (google_response: any) => {
    if (google_response.tokenId) {
      const token_id = google_response.tokenId;
      setIdGoogleToken(token_id);
    }
  };

  const respGoogleFailure = () => {
    console.log("google error");
  };

  // const respFacebook = (facebook_response: any) => {
  //   if (facebook_response.accessToken) {
  //     const token_id = facebook_response.accessToken;
  //     setIdFacebookToken(token_id);
  //   }
  // };

  return (
    <>
      <HelmetHeader title="Sign In" description="Ekenta Login Page" />

      <Container className="account-login">
        <Row className="row_card p-0 m-0">
          <Col md="8" lg="5" xl="4" className="col_card mt-4">
            <Card>
              <Card.Body className="">
                <Form
                  noValidate
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  <Col className="mb-4">
                    <h2 className="form-header text-center">Sign In</h2>
                  </Col>
                  {/* email form input */}
                  <Form.Group className="mb-3">
                    <div className="_group_inpu_ position-relative">
                      <Form.Control
                        type="text"
                        name="login"
                        placeholder="Username or email address"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.login}
                        isInvalid={!!formik.errors.login}
                      />
                      <FiUser className="fuser_" style={{ color: "#7C420C" }} />
                    </div>
                    {formik.touched.login && formik.errors.login ? (
                      <div className="invalid-fbk">{formik.errors.login}</div>
                    ) : null}
                  </Form.Group>

                  {/* password form input */}
                  <Form.Group className="mb-2">
                    <div className="_group_inpu_ position-relative">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        isInvalid={!!formik.errors.password}
                      />
                      <FiLock className="flock_" style={{ color: "#7C420C" }} />
                      {showPassword ? (
                        <VscEye
                          onClick={handleShowPassword}
                          className="revel_p_"
                          style={{ color: "#7C420C" }}
                        />
                      ) : (
                        <VscEyeClosed
                          onClick={handleShowPassword}
                          className="revel_p_closed"
                          style={{ color: "#7C420C" }}
                        />
                      )}
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="invalid-fbk">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </Form.Group>

                  <div className="fJK_pass">
                    <span className="forg_t__p">Forgot your password?</span>
                    <Link
                      className="for_href_block mb-1"
                      to={forgotPassPathUrl}
                    >
                      <span>Reset it</span>
                    </Link>
                  </div>

                  <Button
                    className={`account-login-btn w-100 ${isLoading || google_isLoading ? "disabled" : ""
                      }`}
                    type="submit"
                  >
                    {isLoading || google_isLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner size="sm" animation="border" variant="light" />
                        <span className="ms-1 text-sm">
                          <small>Please Wait...</small>
                        </span>
                      </div>
                    ) : (
                      <span>Sign In</span>
                    )}
                  </Button>
                </Form>

                <Col className="account-signup-col w-100 mt-2 d-flex align-items-center justify-content-between">
                  <h6 className="col-h-summ">Don&apos;t have an account?</h6>
                  <h6 className="col-h-">
                    <Link to={registerPathUrl}>Sign up</Link>
                  </h6>
                </Col>
                <Col className="account-forgt-col mt-1 w-100 text-center">
                  <div className="css-cqwa">
                    <span className=" forgt-para mt-1">or</span>
                  </div>
                </Col>

                {/* <Col xs={12} className="account-social css-1wf5ba">
                  <FacebookLogin
                    appId={FACEBOOK_APPID}
                    autoLoad={false}
                    textButton="Facebook "
                    fields="first_name,middle_name,name,last_name,email,gender,verified"
                    callback={respFacebook}
                    cssClass="facebook-style"
                    render={(renderProps: any) => (
                      <>
                        <button
                          onClick={renderProps.onClick}
                          type="button"
                          className="css-logCSD"
                        >
                          <span className="css-ahhfld">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="#FFFFFF"
                              xmlns="http://www.w3.org/2000/svg"
                              color="systemGrayscale00"
                              className="css-bao86z"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 2.667c7.364 0 13.333 5.968 13.333 13.333 0 6.656-4.875 12.172-11.25 13.172v-9.318h3.107L21.78 16h-3.698v-2.5c0-1.055.516-2.083 2.172-2.083h1.682V8.135s-1.526-.26-2.984-.26c-3.047 0-5.037 1.846-5.037 5.188V16h-3.385v3.854h3.385v9.318c-6.375-1-11.25-6.516-11.25-13.172C2.666 8.635 8.636 2.667 16 2.667z"
                                fill="#3A75EA"
                              ></path>
                            </svg>
                            Continue with Facebook
                          </span>
                        </button>
                      </>
                    )}
                  />
                </Col> */}
                <Col className="css-1wf5ba">
                  <GoogleLogin
                    clientId={GOOGLE_CLIENTID ? GOOGLE_CLIENTID : ""}
                    buttonText="Google"
                    isSignedIn={false}
                    fetchBasicProfile={false}
                    onSuccess={respGoogleSuccess}
                    onFailure={respGoogleFailure}
                    cookiePolicy={"single_host_origin"}
                    render={(renderProps: any) => (
                      <button
                        type="button"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="css-logCSD my-3"
                      >
                        <span className="css-ahhfld">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="#FFFFFF"
                            xmlns="http://www.w3.org/2000/svg"
                            color="systemGrayscale00"
                            className="css-bao86z"
                          >
                            <path
                              d="M28.667 16.292c0-.861-.071-1.727-.223-2.574H16.255v4.878h6.98a5.857 5.857 0 01-2.583 3.849v3.165h4.164c2.445-2.206 3.85-5.464 3.85-9.318z"
                              fill="#4285F4"
                            ></path>
                            <path
                              d="M16.255 28.668c3.485 0 6.424-1.122 8.566-3.058l-4.164-3.165c-1.159.772-2.654 1.21-4.397 1.21-3.371 0-6.23-2.23-7.255-5.227H4.708v3.263c2.194 4.277 6.662 6.977 11.547 6.977z"
                              fill="#34A853"
                            ></path>
                            <path
                              d="M9 18.428a7.445 7.445 0 010-4.85v-3.263H4.708a12.454 12.454 0 000 11.376L9 18.428z"
                              fill="#FBBC04"
                            ></path>
                            <path
                              d="M16.255 8.347a7.1 7.1 0 014.957 1.899l3.69-3.617a12.558 12.558 0 00-8.647-3.295c-4.885 0-9.353 2.7-11.547 6.982L9 13.578c1.021-3.002 3.884-5.231 7.255-5.231z"
                              fill="#EA4335"
                            ></path>
                          </svg>
                          Continue with Google
                        </span>
                      </button>
                    )}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withAuthenticationLayout(Login);
