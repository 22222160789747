import React from "react"
import { Container, Navbar } from "react-bootstrap"
import { FiChevronLeft } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom"

import logo from "@ekenta/assets/images/ekentanew.png";
import { cartPathUrl } from "@ekenta/routes/constant";

 
export default function CheckoutNavBar() {
    const navigate = useNavigate();
  return (
    <>
    <div   className={`nav-header checkout-nav-bg`}>
          {/* collapseOnSelect */}
        <Navbar  className="menubar checkout_menubar p-0" variant="dark"  expand="lg">
            <div className="back__icon_div">
              <button title="Back To Cart"  onClick={() => navigate(cartPathUrl)}>
                <FiChevronLeft />
              </button>
            </div>
            <Container className="Checkoutcontainer_wrapper">
                 <div className="brand_check_wrapper">
                    {/* <span><FiChevronLeft fontSize={45} color={"orange"} cursor={"pointer"}  onClick={() => navigate("/cart")}/></span> */}
                    <Navbar.Brand as={Link} to="/" className="text-white" title="Ekenta">
                            <img
                            alt="ekenta logo"
                            src={logo}
                            width="130"
                            height="38"
                            className="d-inline-block align-top ms-2"
                            />{" "}
                        </Navbar.Brand>
                    </div>
            </Container>
        </Navbar>

        </div>
        </>
  )
}
