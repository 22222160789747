import React from "react"
import styles from "./index.module.scss"
import { Typography } from "@ekenta/components"

import computer_tech from "@ekenta/assets/images/collections/01_computers_tech.png"
import mobile_phones from "@ekenta/assets/images/collections/02_mobile_phones_gadgets.png"
import ekenta_fashion from "@ekenta/assets/images/collections/08_ekenta_fashion.png"
import beauty_care from "@ekenta/assets/images/collections/13_beauty_personal_care.png"
import food_beverage from "@ekenta/assets/images/collections/16_food_drinks.png"
import baby_kids from "@ekenta/assets/images/collections/babies_kids.png"
import car_accessories from "@ekenta/assets/images/collections/Car_Accessories.png"
import classnames from "classnames"

const CollectionList = () => {

    const list = [
        {
            image: computer_tech,
            title: "Computer & Related Items",
            active: true
        },
        {
            image: ekenta_fashion,
            title: "Ekenta Fashion",
            active: false

        },
        {
            image: mobile_phones,
            title: "Cell Phones & Tablets",
            active: false

        },

        {
            image: beauty_care,
            title: "Beauty & Personal Care",
            active: false

        },
        {
            image: food_beverage,
            title: "Food & Beverages",
            active: false

        },

        {
            image: car_accessories,
            title: "Vehicle Parts & Particles",
            active: false

        }
        
    ];

    const handleClick = (active: boolean) => {
        if (active) {
            window.scrollTo(0, 700);
        }
    }
    return (
        <div className={styles.ListContainer}>
            <div className={styles.ListCardBox__Title}>
                <div className={styles.ListHeading__Wrapper}>
                    <Typography
                        as="h5"
                        className={styles.ListHeading__Wrapper_Title}
                        transform="capitalize"
                        heading>
                        Collection for the day
                    </Typography>
                </div>
            </div>
            <div className={styles.ListCardBox_Content}>
                <div className={styles.ListFlex_Item}>
                    {list.map((item, index) => (
                        <ListItem
                            key={index}
                            image={item.image}
                            title={item.title}
                            active={item.active}
                            handleClick={handleClick}
                        />
                    ))}
                </div>

            </div>
        </div>
    )
}


const ListItem = (props: { image: string; title: string; active?: boolean; handleClick: any }) => (
    <div className={classnames(styles.ItemContainer, {
        [styles.ItemActive]: props.active
    })} onClick={() => props.handleClick(props.active)}>
        <span className={styles.ItemImage}>
            <img src={props.image} alt={props.title} />
        </span>
        <Typography
            as="h5"
            className={styles.ItemText}
            transform="capitalize"
            uiText>
            {props.title}
        </Typography>
    </div>
)

export default CollectionList
