import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tab,
  Tabs,
  Stack,
  Form,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../component/DashboardComponent';
import withDashboardLayout from '../../../hoc/Layout/withDashboardLayout';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchAdmniAllOrdersQuery,
} from '../../../redux/store/services/vendorProductApi';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import {
  useAddNewSliderImageMutation,
  useAddNewVideoMutation,
  useFetchAccountingSalesAdminQuery,
  useFetchDailyTotalOrderQuery,
} from '../../../redux/store/services/adminSellerApi';
import {
  FiBarChart,
  FiBarChart2,
  FiChevronLeft,
  FiGift,
  FiToggleRight,
  FiUser,
  FiUserCheck,
} from 'react-icons/fi';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { MDBDataTable } from 'mdbreact';
import { useNavigate } from 'react-router';
import { adminVideos } from '../../../routes/constant';
import sent from '../../../assets/images/sent.gif';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';

const AddVideo = () => {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [
    addVideo,
    {
      data: addVideo_data,
      isLoading: addVideo_isLoading,
      isError: addVideo_isError,
      error: addVideo_error,
      isSuccess: addVideo_isSuccess,
    },
  ] = useAddNewVideoMutation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [details, setDetails] = useState({
    videoTitle: '',
    videoNumber: '',
    file: '',
  });
  // const formData = new FormData()
  const handleImage = (e: any) => {
    // console.log(e.target.files[0])
    setDetails({ ...details, file: e.target.files[0] });

    // console.log(formData)
  };

  const handleSubmit = () => {
    setLoading(true);
    console.log(details);
    const formData = new FormData();
    formData.append('file', details.file);
    formData.append('videoNumber', details.videoNumber);
    formData.append('videoTitle', details.videoTitle);
    // let data = details
    addVideo({ data: formData, token: token, other: details });
    if (addVideo_data) {
      setLoading(false);
      // navigate(adminManageBanner)
    }
  };

  useEffect(() => {
    if (addVideo_data) {
      handleShow();
    }
  }, [addVideo_data]);

  return (
    <>
      <HelmetHeader
        title="Add Video Dashboard - Ekenta.com"
        description="Ekenta Add Video Dashboard Page"
      />

      <Col className=" col-lg-11 mx-auto _listing_product_">
        <div className="mt-0">
          <hr />
          <h3 className="display_lg_header">
            {/* <FiChevronLeft /> */}
            Manage Videos
          </h3>
          <hr />

          <Col className="mt-1">
            <Card>
              <Card.Body>
                <Form.Label htmlFor="inputPassword5">Video Title * </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, videoTitle: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">
                  Video Number *{' '}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    setDetails({ ...details, videoNumber: e.target.value })
                  }
                />
                <br />
                <Form.Label htmlFor="inputPassword5">Upload Video *</Form.Label>
                <Form.Control
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleImage(e)}
                />
                <br />
                <Form.Label htmlFor="inputPassword5">Status</Form.Label>
                <Form.Select>
                  <option>Active</option>
                  <option>Inactive</option>
                </Form.Select>
                <br />
                <Button
                  variant="danger"
                  disabled={loading ? true : false}
                  onClick={handleSubmit}
                >
                  {loading ? 'Loading...' : 'Submit'}
                </Button>{' '}
                <Button variant="outline-danger">Cancel</Button>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Col>

      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row>
            <img src={sent} alt="" height={300} width={'100%'} />
            <Button
              variant="success"
              className="text-white text-center mx-auto"
              onClick={() => navigate(adminVideos)}
            >
              Done
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withDashboardLayout(AddVideo);
