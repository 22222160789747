import React,{useState} from 'react';
import { FiX } from 'react-icons/fi';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

interface AlternateToastPropsType {
    show: boolean;
}

export default function AlternateToast(props:AlternateToastPropsType) {

    return (
        <>
          {props.show && 
           <div style={{zIndex: 99999999}}>
             <Toaster
                position="bottom-center"
                reverseOrder={false}
                containerClassName="main-container"
                containerStyle={{}}
                toastOptions={{
                    duration: 6000,
                    success: {
                      style: {
                        background: '#dcfce7',
                        color: '#22c55e',
                        border: '1px solid #22c55e',
                        fontSize: '13.5px',
                        paddingBottom: '15px',
                        zIndex:9999999
                      },
                    },
                    error: {
                      style: {
                        background: '#ffe4e6',
                        fontSize: '13.5px',
                        color: '#e11d48',
                        border: '1px solid #f43f5e',
                        paddingBottom: '15px',
                        zIndex:9999999
                      },
                    },
                  }}
                >

                    {(t) => (
                        <ToastBar toast={t}>
                        {({ icon, message }) => (
                            <>
                            {icon}
                            <div>{message}</div>
                            {t.type !== 'loading' && (
                                <span className='cursor-pointer' onClick={() => toast.dismiss(t.id)}><FiX className='cursor-pointer' color='#444' fontSize={20} /></span>
                            )}
                            </>
                        )}
                        </ToastBar>
                    )}

                </Toaster>
           </div>
            }
        </>

    )
}
