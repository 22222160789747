import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createFormDataMutationWithoutTokenRequest, createMutationRequestWithBody, createQueryRequest, createQueryRequestWithBody, createQueryWithTokenRequest, createQueryWithTokenRequestByText } from '.';
import { BASE_URL } from '../../../constants/index';


export const homeApi: any = createApi({
    reducerPath: 'homeApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getAllMerchant: builder.query<void, string>({
            query: () => createQueryRequest(
                {
                    url: `/api/merchant/get-all-merchant-businesses-whose-product-belongs-to-the-collection-activated`,
                    method: 'GET',
                }),
        }),
        getTopDeals: builder.query<void, string>({
            query: () => createQueryRequest(
                {
                    url: `/api/auth/product/get-top-deals`,
                    method: 'GET',
                }),
        }),
        getDiscountedProduct: builder.query<void, string>({
            query: () => createQueryRequest(
                {
                    url: `/api/auth/product/get-discounted-products-up-to-a-certain-percentage?percentage=50.0`,
                    method: 'GET',
                }),
        }),
        getTopRated: builder.query<void, string>({
            query: () => createQueryRequest(
                {
                    url: `/api/auth/product/get-top-rated-products`,
                    method: 'GET',
                }),
        }),
    })

});


export const {
    useGetAllMerchantQuery,
    useGetTopDealsQuery,
    useGetDiscountedProductQuery,
    useGetTopRatedQuery,
} = homeApi;