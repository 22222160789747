import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FiShare, FiX } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as queryString from "query-string";
import AllOtherProducts from "./components/AllOtherProducts";
import MessagingStreams from "./components/MessagingStreams";
import VideoStreamsContainer from "./components/VideoStreamsContainer";

import PromptDialog from "./components/Dialog";
import { useAppSelector } from "@ekenta/redux/store/hook";
import {
  useFetchAllLiveSellersOnEkeQuery,
  useFetchAllLiveSellersQuery,
  useFetchAllSellerProductQuery,
  useFetchProductFilteredByCollectionQuery,
  useFetchSellerLiveCodeQuery,
} from '../../../redux/store/services/livecommerceApi';
import LiveProductCard from '../../../components/LiveCommerce/LiveProductCard';
import AlternateToast from '../../../components/Toast/AlternateToast';

import logo from '../../../assets/images/ekentanew.webp';
import SocialShare from '../../../components/SocialShare/SocialShare';
import { allLivePathUrl, singLiveBaseUrl } from '../../../routes/constant';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import { SpinLoader } from '../../../components/Spinner/SpinLoader';
import withDefaultLayout from '../../../hoc/Layout/withDefaultLayout';

import defaultImg from '../../../assets/images/default_livestream.webp';
import SideSection from "./SideSection";


const ViewLiveSeller = () => {
  const navigate = useNavigate();
  const { sellerId } = useParams();
  const query = queryString.parse(window.location.search);
  const isLiveType = query.type;

  const merchantId = sellerId;
  const [showDialog, setShowDialog] = useState(false);
  const { nickname } = useAppSelector((state) => state.auth.livecom);
  const { token } = useAppSelector((state) => state.auth.authentication);

  // const { data, isLoading, isFetching, isError, error, isSuccess } =

  let result: {
    data: any;
    isError: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    error: { status: string | number };
  };
  isLiveType === "isLive"
    ? (result = useFetchAllSellerProductQuery(sellerId, { skip: !sellerId }))
    : (result = useFetchProductFilteredByCollectionQuery(sellerId, {
      skip: !sellerId,
    }));

  let getResult;
  isLiveType === "isLive"
    ? (getResult = useFetchAllLiveSellersQuery())
    : (getResult = useFetchAllLiveSellersOnEkeQuery());

  const getSellerResult = useFetchSellerLiveCodeQuery(
    { merchantId, token },
    { skip: !merchantId },
  );

  const [isDisconected, setIsDisconected] = useState(false);
  const [isConected, setIsConected] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const clientRef = useRef(null);

  useEffect(() => {
    if (!nickname) {
      handleShow();
    }
  }, []);

  useEffect(() => {
    if (result?.isError && result?.error.status === "FETCH_ERROR") {
      toast.error(`Something went wrong`, {
        toastId: "view-stream-id-toast-error",
      });
    }
  }, [result?.isError]);

  useEffect(() => {
    if (!isLiveType) {
      return navigate(-1)
    }
  }, [isLiveType])

  const handleClose = () => setShowDialog(false);
  const handleShow = () => setShowDialog(true);

  const handleShowModal = () => setShowModal(true);
  const handleHideModal = () => setShowModal(false);

  const getLivestreamUrl = () => {
    return `${window.location.origin}${singLiveBaseUrl}/spm-${sellerId}`;
  };

  const closeLivestream = () => {
    return navigate(allLivePathUrl);
  };

  // const mapAllLiveSellers = (data: any, type: any) => {
  //   return (
  //     data.content.length > 0 &&
  //     data.content.map((result: any, index: number) => {
  //       return (
  //         <LiveProductCard
  //           key={`id_` + index}
  //           id={result.userId}
  //           type={type === "isLive" ? "isLive" : "isEkeLive"}
  //           className="item col__cardb-wrapper"
  //           imageUrl={
  //             type === "isLive" && result.thumbnailUrl
  //               ? result.thumbnailUrl
  //               : type === 'isEkeLive' && result.imageUrl
  //                 ? result.imageUrl
  //                 : defaultImg
  //           }
  //           title={
  //             type === "isLive" ? result.businessName : result.business_name
  //           }
  //         />
  //       );
  //     })
  //   );
  // };

  return (
    <>
      <HelmetHeader
        title="View Live "
        description="Ekenta View Live Seller Page"
      />
      <section className="dall_live_wrapper">
        <div className="single_live_main">
          <div className="_view_top_live_section">
            <div className="container_live mb-2 mb-lg-5">
              <div className="vid__stream_navbar pt-1">
                <div className="vid__stream_navigationBar pt-2 pb-2">
                  <Link to={""} className="vid__stream_brand">
                    <img
                      alt="ekenta logo"
                      src={logo}
                      width="130"
                      height="auto"
                      className="d-inline-block mx-1 mt-0 align-top"
                    />{" "}
                  </Link>
                  <div className="d-flex align-items-center vid_navigation_right">
                    <ReactTooltip
                      id="share"
                      place="top"
                      type="dark"
                      effect="solid"
                    />
                    <button
                      className="d-flex share__button me-3 Button Button--icon"
                      data-tip="Share"
                      data-for="share"
                      onClick={handleShowModal}
                    >
                      <FiShare fontSize={22} />
                    </button>
                    <span>
                      {" "}
                      <FiX
                        className="me-2"
                        fontSize={28}
                        color="white"
                        onClick={closeLivestream}
                      />{" "}
                    </span>
                  </div>
                </div>
              </div>

              <div className="live-stream-group">
                <div className="main__v_stream_col">
                  <div className="J_lr-header"></div>
                  <div className="vid__stream-section">
                    <VideoStreamsContainer
                      liveCode={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data.liveLink
                          : null
                      }
                      isStream={
                        getSellerResult?.isSuccess
                          ? isLiveType === "isLive"
                            ? getSellerResult?.data.isLive
                            : getSellerResult?.data.isSellerLiveForEkeMarket
                          : null
                      }
                      businessName={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data.businessName
                          : ""
                      }
                    />
                    <MessagingStreams
                      from={nickname}
                      clientRef={clientRef}
                      sellerId={sellerId}
                      liveCode={
                        getSellerResult?.isSuccess
                          ? getSellerResult?.data.liveLink
                          : null
                      }
                      setIsDisconected={setIsDisconected}
                      setIsConected={setIsConected}
                    />
                  </div>
                </div>
                <SideSection
                  productData={result}
                  liveData={getResult}
                  isLiveType={isLiveType}
                />
                {/* <div className="stream__col__4">
                  <div className="right__side_stream_div">
                    <div className="top__section__container">
                      <div className="__tp_avatar_text__"></div>
                      <div className="_tp__follow__btn"></div>
                    </div>
                    <div className="bottom__section__container">
                      <div className="all_p_section">
                        <h5>All Products</h5>
                      </div>

                      {result?.isSuccess ? (
                        <div className="list__user__product">
                          <AllOtherProducts
                            data={result?.data}
                            isLoading={result?.isLoading}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div> */}


              </div>
            </div>
          </div>
        </div>

        {/* { display on small devices} */}
        {/* <div className="d-lg-none mobile__display__section">
          <CustomTab columns={[]} reverse={true}>
            <CustomTabBody>
              <div className="d-lg-none custom all__list__items">
                {result?.isSuccess ? (
                  <AllOtherProducts
                    data={result?.data}
                    isLoading={result?.isLoading}
                  />
                ) : null}
              </div>
            </CustomTabBody>
            <CustomTabBody>
              <span>2</span>
            </CustomTabBody>
          </CustomTab>
        </div> */}

        {/* { display on large devices} */}
        {/* {getResult?.isSuccess
          ? getResult?.data.content.length > 0 && (
            <div className="j_k_more_live">
              <Col className="col__h3 d-flex align-items-center justify-content-start pl-0">
                <h5>More Live</h5>
              </Col>
              <Row className="row__col__div">
                {getResult?.isLoading ? (
                  <SpinLoader size={3} variant="dark" />
                ) : (
                  <>{mapAllLiveSellers(getResult?.data, isLiveType)}</>
                )}
              </Row>
            </div>
          )
          : null} */}
        <br />

        {/* {promopt dialog} */}
        <PromptDialog show={showDialog} onClose={handleClose} />
        {showToast && <AlternateToast show={showToast} />}

        {/* Modal Overlay for share  */}
        <Modal
          className="modal_component shadow"
          centered
          show={showModal}
          onHide={handleHideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SocialShare url={getLivestreamUrl()} title="Ekenta Livestream" />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default withDefaultLayout(ViewLiveSeller);
