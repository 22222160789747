import * as Yup from 'yup';

const updateProductListingFormValidation = () => Yup.object().shape({
    collectionId: Yup.string()
                  .required('Main collection is required'),
    subcategorycollectionId: Yup.string()
                  .required('Sub category collection is required'),
    subcollectionId: Yup.string()
                  .required('Sub collection is required'),
    productname: Yup.string()
               .required('Product name field is required'),
    productshortDescription: Yup.string()
                .min(50,'Description should be minimum of 50 character')
                .max(200,'Description should be maximum of 200 character')
                .required('Description field is required'),
    brandId: Yup.string()
               .required('Brand field is required'),
    defaultprice:  Yup.number()
                .typeError('Default price field must not be empty')
                .required('Default price field is required'),
                // .min(50, 'Default price must be 50 above'),
    // minimumprice:  Yup.number()
    //                 .typeError('Minimum field must not be empty')
    //                 .min(0, '')
    //                 .lessThan(Yup.ref("maximumprice"),'Minimum price field must be lesser than Maximum field')
    //                 .required('Minimum field is required'),
    // maximumprice:  Yup.number()
    //               .required('Maximum field is required')
    //               .moreThan(Yup.ref("minimumprice"),'Maximum price field must be greater than Minimum field '),

    discount:  Yup.number()
                .typeError('Discount field must be 0 by default')
                .min(0, ''),

    quantity:  Yup.number()
                .required('Quantity field is required')
                .min(1, 'Quantity field minimum value must be 1'),
 
    vatcharges: Yup.number()
                .required('Vat field is required'),
    totalprice: Yup.number(),
    condition:      Yup.string()
                        .required('*Condition field is required'),
    productWeight:      Yup.string()
                        .required('*Weight field is required (in kg)'),
    // productimages: Yup.array()
    //                         .of(
    //                             Yup.object().shape({
    //                             file: Yup.array(),
    //                             })
    //                         )
    //                     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //                     .compact((v:any) => !v.file)
    //                     .required('Product image is required')
    //                     .min(2, '*Must upload minimum of (2) quality product images')
    //                     .max(5, '*Must upload maximum of (5) quality product images'),
    // productthumbnail: Yup.array()
    //                     .min(1, '*Must upload one video thumbnail')
    //                     .required('Product video thumbnail is required'),
    featureValues: Yup.array()
                            .of(
                                Yup.object().shape({
                                  featureId: Yup.string().required('Field is required'),
                                  featureUnitMeasureId: Yup.string().required('Field is required'),
                                  featureValue: Yup.string().required('Field is required'),
                                })
                            )
                        .required('Feature is required'),

    // productvideo: Yup.array()
                    // .min(1, '*Must upload one video - maximum of 20s')
})

export default updateProductListingFormValidation;