import React from 'react'
import { Helmet } from 'react-helmet'

export const HelmetHeader = (props:{title:string;description:string}) => {
  return (
    <Helmet>
      <title>{props.title + '- Ekenta'}</title>
      <meta charSet="utf-8" />
      <meta name="title" content={props.title} />
      <meta name="description"
              content={props.description}
      />
      <meta property="og:locale" content="en_NG" />
      {/* <meta name="robots" content="noindex,follow" /> */}
   </Helmet>
  )
}
