import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import {ListGroup} from 'react-bootstrap';
import { toast } from "react-toastify";

import { accountProfilePathUrl, addressesPathUrl, cartPathUrl, DashboardPathUrl, DashboardSellers, loginPathUrl, 
        ordersPathUrl, returnRequestPathUrl, savedItemPathUrl, SellerOnboardingPathUrl } from '../../../routes/constant';
import { VscAccount, VscChevronRight, VscDashboard, VscDebugStepBack, VscHeart, VscHistory, VscQuestion } from 'react-icons/vsc';
import { useGoogleLogout } from 'react-google-login';
import { LocationIcon, LogoutIcon, SellIcon, ShoppingCartIcon, } from '../../../components/icons/icons';
import DefaultLayout from '../../../hoc/Layout/Default-Layout';
import avatar from '../../../assets/images/AVAPRO-240-240.png';
import { useAppDispatch, useAppSelector } from '../../../redux/store/hook';
import { removeUser } from '../../../redux/store/states/reducerSlice/authenticationSlice';
import { checkIfAdmin, checkIfAdminOrSellerRoleExist, logoutHandler } from '../../../utils';
import { GOOGLE_CLIENTID } from '../../../constants';
import { removeLive } from '../../../redux/store/states/reducerSlice/livecomSlice';


export default function MobileScreenAccountIndex() {
  const { account,ek_isAuth,token} = useAppSelector(state => state.auth.authentication);
  const { signOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENTID ? GOOGLE_CLIENTID : ''
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAdmin = checkIfAdmin(token)


  const logoutHandlerFunc = () => {
    logoutHandler(
        navigate,
        dispatch,
        signOut,
        loginPathUrl,
        removeUser,
        removeLive
    ); 
    toast.success(`You've successfully logout, see you again`,{
        toastId: "logout-mobile-success-id-toast-success",
    });
  }

  return (
    <DefaultLayout>
    <Helmet>
        <title>Manage Account- Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Account Profile Page" />
        <meta name="description"
                content="Ekenta Profile Account Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
    </Helmet>

       <div  className="acc-mob-user-container">
          <div className="acc-m-pro-row">
            <ListGroup>
                
                <div className=" mb-3 w-100">
                {ek_isAuth && token ? (
                     <div className=" member-login signal-resp">
                        <div className="_display-header">
                            <h5 className="_wrapper_h5">
                                <Link to={accountProfilePathUrl}>
                                    Hi, {ek_isAuth && account.user && account.user.firstName}
                                </Link>
                            </h5>
                            <p>Thanks for being Ekenta customer</p>
                            </div>
                    </div>
                ) : (
                    <div className="rv_ member-login signal-resp">
                        <div className="avatar__log_">
                        <img src={avatar} alt=""/>
                        </div>
                        <Link to={loginPathUrl} className="wrap__sign">
                        <div className="rv _signOrRegister">
                            <span className="rv _signOrRegisterText ">Sign In or Register</span>
                            </div>
                        </Link>
                  </div>
                )}
                </div>
                <div className="_list_itm_group">
                {!token && ek_isAuth && (
                      <ListGroup.Item as={Link} to={SellerOnboardingPathUrl}><div className="sD_m_ ms-2 me-auto"><div className="rv_">
                          <SellIcon className="icon__acc_2" /><span>Sell anything</span> </div>
                        <VscChevronRight fontSize={18} /></div></ListGroup.Item>
                )}
                {token && checkIfAdminOrSellerRoleExist(token) === true ? (
                    <ListGroup.Item
                       as={Link} to={isAdmin ?  DashboardSellers : DashboardPathUrl}>
                       <div className="sD_m_ ms-2 me-auto">
                         <div className="rv_">
                           <VscDashboard className="icon__acc_dash" />
                           <span> Go To Dashboard</span> 
                         </div>
                         <VscChevronRight fontSize={18} />
                       </div>
                    </ListGroup.Item>
                ): (
                    <ListGroup.Item
                    as={Link} to={SellerOnboardingPathUrl}>
                    <div className="sD_m_ ms-2 me-auto">
                      <div className="rv_">

                        <SellIcon className="icon__acc_2" />
                        <span>Sell anything</span> 
                      </div>
                      <VscChevronRight fontSize={18} />
                    </div>
                   </ListGroup.Item>
                )}
                </div>

                <div className="_list_itm_group">
                    <ListGroup.Item
                            as={Link} to={accountProfilePathUrl}>
                            <div className=" rv_ sD_m_ ms-2">
                                <div className="rv_">
                                    <VscAccount className="icon__acc_"/>
                                    <span>Manage Profile</span> 
                                </div>
                                <VscChevronRight fontSize={18} />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as={Link} to={addressesPathUrl}>
                            <div className="sD_m_ ms-2 me-auto">
                                <div className="rv_">
                                    <LocationIcon className="icon__acc_2" />
                                    <span>Addresses</span> 
                              </div>
                              <VscChevronRight fontSize={18} />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as={Link} to={cartPathUrl}>
                            <div className="sD_m_ ms-2 me-auto">
                              <div className="rv_">
                                <ShoppingCartIcon className="icon__acc_2" />
                                <span>Shopping Cart</span> 
                              </div>
                              <VscChevronRight fontSize={18} />
                            </div>
                        </ListGroup.Item>
                       
                     
                </div>
                <div className="_list_itm_group">
                    <ListGroup.Item
                            as={Link} to={ordersPathUrl}>
                            <div className="sD_m_ ms-2 me-auto">
                                <div className="rv_">
                                    <VscHistory className="icon__acc_"/>
                                    <span>Orders</span> 
                                </div>
                               <VscChevronRight fontSize={18} />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as={Link} to={savedItemPathUrl}>
                            <div className="sD_m_ ms-2 me-auto">
                                <div className="rv_">
                                    <VscHeart className="icon__acc_" />
                                    <span>Saved Items</span> 
                                </div>
                               <VscChevronRight fontSize={18} />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as={Link} to={returnRequestPathUrl}>
                            <div className="sD_m_ ms-2 me-auto">
                             <div className="rv_">
                                <VscDebugStepBack className="icon__acc_" />
                                <span>Return Request</span> 
                             </div>
                             <VscChevronRight fontSize={18} />
                            </div>
                        </ListGroup.Item>
                        
                </div>
    
    
                <div className="_list_itm_group">
                    <ListGroup.Item
                            as={Link} to={'#'}>
                            <div className="sD_m_ ms-2 me-auto">
                             <div className="rv_">
                                <VscQuestion className="icon__acc_" /> 
                                <span>Help Center</span> 
                             </div>
                             <VscChevronRight fontSize={18} />
                            </div>
                        </ListGroup.Item>
                        
                {ek_isAuth && token && (
                    <div className="mt-1">
                        <ListGroup.Item
                                as={Link} to={'#'} onClick={logoutHandlerFunc}>
                                <div className="sD_m_ ms-2 me-auto">
                                 <div className="rv_">
                                    <LogoutIcon className="icon__acc_2" />
                                    <span>Log Out</span> 
                                 </div>
                                <VscChevronRight fontSize={18} />
                                </div>
                            </ListGroup.Item>
                        </div>
                )} 
                </div>
              
        </ListGroup>
        </div>
      </div>
    </DefaultLayout>
  )
}
