import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Nav, Row, Col, Container } from "react-bootstrap";
import {
  DashboardPathUrl,
  SellerOnboardingPathUrl,
  DashboardSellers,
  allLivePathUrl,
  collectionPathUrl,
  categoryBaseUrl,
  savePlanetPathUrl,
} from "@ekenta/routes/constant";
//offCanvas/Sidebar for Collections
import { TriggerSideOffCanvasContext } from "@ekenta/context/TriggerSideOffCanvasContext";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { checkIfAdmin, checkIfAdminOrSellerRoleExist } from "@ekenta/utils";
import { MenuIcon } from "../../icons/icons";
import { Button } from "@ekenta/components";
import { HoveringComponent } from "../style";

import "./BottomNav.scss";

export default function BottomNav() {
  const { token } = useAppSelector((state) => state.auth.authentication);
  const { handleShowCanvas } = useContext(TriggerSideOffCanvasContext);
  const isAdmin = checkIfAdmin(token);

  return (
    <Container className=" d-lg-flex bottom-nav-wrapper">
      <Row className=" bottom-nav-main-row">
        <Col className="bottom-navItem-wrap">
          {/* <HoveringComponent className="button_li">
            <Button
              bgVariant="primary"
              shadow={false}
              className="custom_list_btn d-none  d-lg-flex  py-1 text-white me-2"
              onClick={() => handleShowCanvas("all")}
            >
              <div className="d-flex align-items-center">
                <MenuIcon className="text-white" />
                <span className="ms-2">See Collections</span>
              </div>
            </Button>
          </HoveringComponent> */}
          <ul className="ul_list_g list-group list-group-horizontal">
            {/* <HoveringComponent>
              <Nav.Link as={Link} to={collectionPathUrl}>
                Browse All
              </Nav.Link>
            </HoveringComponent> */}
            {/* <HoveringComponent>
              <Nav.Link
                className="d-none d-lg-block"
                as={Link}
                to={`${categoryBaseUrl}/desktop-and-monitors-2`}
              >
                Desktop & Monitors
              </Nav.Link>
            </HoveringComponent>

            <HoveringComponent>
              <Nav.Link
                className="d-none d-lg-block"
                as={Link}
                to={`${categoryBaseUrl}/mobile-phones-and-accessories-54`}
              >
                Mobile Phones & Accessories
              </Nav.Link>
            </HoveringComponent> */}

            <HoveringComponent>
              {token ? (
                checkIfAdminOrSellerRoleExist(token) === true ? (
                  <Nav.Link
                    as={Link}
                    to={isAdmin ? DashboardSellers : DashboardPathUrl}
                  >
                    Go To Dashboard
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    as={Link}
                    target="_blank"
                    to={SellerOnboardingPathUrl}
                  >
                    Sell Anything
                  </Nav.Link>
                )
              ) : (
                <Nav.Link
                  as={Link}
                  target="_blank"
                  to={SellerOnboardingPathUrl}
                >
                  Sell Anything
                </Nav.Link>
              )}
            </HoveringComponent>
            {/* <HoveringComponent>
              <Nav.Link className="_live_navlink" as={Link} to={allLivePathUrl}>
                <span className="_badge_live_"></span>
                Live
              </Nav.Link>
            </HoveringComponent> */}
            <HoveringComponent>
              {/* <Nav.Link as={Link} to={`/iKitchen/all`}>
                            iKitchen
                        </Nav.Link> */}
            </HoveringComponent>
            <HoveringComponent>
              <Nav.Link as={Link} to={savePlanetPathUrl}>
                Save the Planet
              </Nav.Link>
            </HoveringComponent>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
