import { combineReducers } from "@reduxjs/toolkit";
import authenticationReducer from './reducerSlice/authenticationSlice';
import cartReducer from './reducerSlice/cartSlice';
import loginReducer from './reducerSlice/loginSlice';
import LiveComReducer from './reducerSlice/livecomSlice';
import SellerdetailReducer from './reducerSlice/sellerSlice';

export const authReducer =  combineReducers({
    authentication: authenticationReducer,
    login: loginReducer,
    livecom:LiveComReducer,
    sellerdetail: SellerdetailReducer,
});

export const cartsReducer =  combineReducers({
    cart: cartReducer,
});

