import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import * as queryString from 'query-string';
import { FiEye } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from '../../../redux/store/hook';
import { useFetchPendingSellersQuery } from '../../../redux/store/services/adminSellerApi';
import { DashboardSellers } from '../../../routes/constant';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import withDashboardLayout from '../../../hoc/Layout/withDashboardLayout';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { Pagination, DataTable, PageTitle } from '../../../components';

const SellersAwaitingApproval = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const query = queryString.parse(window.location.search);
  const _page = query.page;

  const { token } = useAppSelector((state) => state.auth.authentication);

  const [pageno, setPageno] = useState(0);
  const result = useFetchPendingSellersQuery({ pageno, token });

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },

    {
      Header: 'Phone No',
      accessor: 'phone',
      sortType: 'basic',
    },
    {
      Header: 'Email',
      accessor: 'email',
      sortType: 'basic',
    },
    {
      Header: 'Store Name',
      accessor: 'store',
      sortType: 'basic',
    },
    {
      Header: 'Date',
      accessor: 'date',
      sortType: 'basic',
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
    {
      Header: 'Option',
      accessor: 'option',
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      if (_page) {
        const _newPage = parseInt(_page.toString());
        if (_newPage > 0) {
          setPageno(_newPage - 1);
        } else {
          navigate(`${pathname}?page=1&sortType=default`);
        }
      } else {
        setPageno(0);
      }
    }, 1000);
  }, [_page]);

  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      name: item.merchantOwner.firstName + ' ' + item.merchantOwner.lastName,
      phone: item.merchantOwner.phoneNumber,
      email: item.merchantOwner.email,
      store: item.businessName,
      date: item.merchantOwner.createdDate,
      status: item.sellerRequestStatus,
      option: (
        <FiEye
          onClick={() =>
            navigate(`/admin/seller-review/${item.merchantOwner.id}?type=awaiting`)
          }
          className="cursor-pointer text-primary"
          fontSize={15}
        />
      ),
    }));
  };

  return (
    <>
      <HelmetHeader
        title="Awaiting Approval"
        description="Ekenta Awaiting Approval Page"
      />

      <Col className="mx-auto _listing_product_ dash_top px-2">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title="Awaiting Approval"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-auto align-items-center
                    text-muted"
                  onClick={() => navigate(DashboardSellers)}
                >
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
          </Col>
          <Col className="mt-4 tab__">
            <DataTable
              theme="simple"
              data={result?.data ? mapData(result?.data.content) : []}
              loading={result?.isLoading}
              isSelectable={true}
              columns={columns}
              showPagination={false}
              hasLink={true}
              thClassName="w-auto"
              trClassName="w_Tr"
              tdClassName={`Row__col`}
              renderPagnation={
                <>
                  {result?.data
                    ? result?.data.totalElements > 20 && (
                      <Pagination
                        pathname={pathname}
                        itemPerPage={result?.data.pageable.pageSize}
                        itemsLength={result?.data.totalElements}
                        currentPage={pageno}
                      />
                    )
                    : null}
                </>
              }
            />
          </Col>
        </div>
      </Col>
    </>
  );
};

export default withDashboardLayout(SellersAwaitingApproval);
