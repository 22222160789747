
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../constants/index';


const generalApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
});

const createQueryRequest = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json'
    },
    method: method
});

const createQueryWithTokenRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
    url: url,
    headers: generalApiHeaders(token),
    method: method,
});


const createFormDataMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: {
        "Authorization": `Bearer ${token}`
    },
    method: method,
    body: body
});

const createMutationRequestWithoutBody = ({ url, method, token }: { url: string, method: string, token: string }) => {
    return {
        url: url,
        headers: generalApiHeaders(token),
        method: method,
    }
};


export const vendorProductApi: any = createApi({
    reducerPath: 'vendorProductApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Product', 'Brand', 'Order'],
    endpoints: (builder) => ({
        fetchAllProducts: builder.query<void, string>({
            query: (pageno) => createQueryRequest({ url: `/api/product/all?page=${pageno}`, method: 'GET' }),
            providesTags: ['Product'],
        }),
        fetchBrandsByCollectionId: builder.query<void, string>({
            query: (collectionId) => createQueryRequest({ url: `/api/collection/with-brands/${collectionId}`, method: 'GET' }),
            providesTags: ['Brand'],
        }),
        fetchTotalOrders: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/brand/all`, method: 'GET' })
        }),
        fetchAllOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/fetch-seller-order`, method: 'GET', token: token })
        }),
        fetchCompletedOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=COMPLETED`, method: 'GET', token: token })
        }),
        fetchProcessingOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=PROCESSING`, method: 'GET', token: token })
        }),
        fetchCancelledOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=CANCELLED`, method: 'GET', token: token })
        }),
        fetchPendingOrders: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `api/fetch-seller-orders-by-orderstatus?orderStatus=PENDING`, method: 'GET', token: token })
        }),
        // fetchAdmniAllOrders: builder.query<void, string>({
        //     query: (token) =>  createQueryWithTokenRequest({url:`/api/admin/get-all-order`,method:'GET',token:token})
        // }),
        fetchSellerProducts: builder.query<void, any>({
            query: ({ id, token, pageno }) => createQueryWithTokenRequest({ url: `/api/product/seller/${id}?page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Product'],
        }),
        fetchSellerReturnRequest: builder.query<void, any>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/getSellerProductReturnRequest`, method: 'GET', token: token })
        }),
        fetchOrderDetailsSeller: builder.query<void, { token: string, orderId: string }>({
            query: ({ token, orderId }) => createQueryWithTokenRequest({ url: `/api/fetch-seller-order-details/${orderId}`, method: 'GET', token: token })
        }),

        fetchOrderDetails: builder.query<void, { token: string, orderId: string }>({
            query: ({ token, orderId }) => createQueryWithTokenRequest({ url: `/api/admin/view-order-details/${orderId}`, method: 'GET', token: token })
        }),

        fetchAdmniAllOrders: builder.query<void, { token: string; status: string, pageNo: string }>({
            query: ({ token, status, pageNo }) => createQueryWithTokenRequest({ url: `/api/admin/get-all-order?orderStatus=${status}&page=${pageNo}`, method: 'GET', token: token })
        }),

        fetchOrderListDetails: builder.query<void, { token: string, orderId: string }>({
            query: ({ token, orderId }) => createQueryWithTokenRequest({ url: `/api/admin/view-order-details/${orderId}`, method: 'GET', token: token }),
            providesTags: ['Order']
        }),

        setOrderToDelivered: builder.mutation<void, { orderId: string; token: string; }>({
            query: ({ orderId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/update-order-status-to-delivered/${orderId}`, method: 'PUT', token: token }),
            invalidatesTags: ['Order']
        }),

        setOrderToOutForDelivery: builder.mutation<void, { orderId: string; token: string; }>({
            query: ({ orderId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/update-order-status-to-out-for-delivery/${orderId}`, method: 'PUT', token: token }),
            invalidatesTags: ['Order']
        }),

        setProductToPublishOrUnpublish: builder.mutation<void, { productId: string; token: string; visibility: boolean }>({
            query: ({ productId, token, visibility }) => createMutationRequestWithoutBody({ url: `/api/product/publish/${productId}?visibility=${visibility}`, method: 'PUT', token: token }),
            invalidatesTags: ['Product'],
        }),

        addBrandRequest: builder.mutation<void, { data: any; token: string }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/brand/new`, method: 'POST', body: data, token: token }),
        }),

        mapBrandToCollection: builder.mutation<void, { data: any; token: string }>({
            query: ({ data, token }) => createFormDataMutationRequest({ url: `/api/collection/add-brand`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Brand']
        }),

        fetchAdminAllProducts: builder.query<void, { pageno: string; token: string }>({
            query: ({ pageno, token }) => createQueryWithTokenRequest({ url: `/api/admin/products?page=${pageno}`, method: 'GET', token: token }),
            providesTags: ['Product'],
        }),

        ///api/admin/view-order-details/{orderId}

        // addNewProduct: builder.mutation<any, any>({
        //     query: ({data,token}) => createMutationRequest({url:'/api/product/new',method:'POST', body:data,token:token})    
        // }),
        // addNewProductThumbnail: builder.mutation<any, any>({
        //     query: ({data,productId,token}) => createFormDataMutationRequest({url:`/api/product/thumbnail/${productId}`,method:'POST', body:data,token:token})
        // }),
        // addNewProductVideo: builder.mutation<any, any>({
        //     query: ({data,productId,token}) => createFormDataMutationRequest({url:`/api/product/video/${productId}`,method:'POST', body:data,token:token})     
        // }),
        // addNewMultipleProductImages: builder.mutation<any, any>({
        //     query: ({data,productId,token}) => createFormDataMutationRequest({url:`/api/product/images/${productId}`,method:'POST', body:data,token:token})     
        // }),
    })
})


export const {
    useFetchAllProductsQuery,
    useFetchBrandsByCollectionIdQuery,
    useFetchAllOrdersQuery,
    useFetchCompletedOrdersQuery,
    useFetchProcessingOrdersQuery,
    useFetchCancelledOrdersQuery,
    useFetchPendingOrdersQuery,
    useFetchAdmniAllOrdersQuery,
    useFetchSellerProductsQuery,
    useFetchSellerReturnRequestQuery,
    useFetchOrderDetailsQuery,
    useFetchOrderListDetailsQuery,
    useSetOrderToDeliveredMutation,
    useSetOrderToOutForDeliveryMutation,
    useSetProductToPublishOrUnpublishMutation,
    useAddBrandRequestMutation,
    useMapBrandToCollectionMutation,
    useFetchOrderDetailsSellerQuery,
    useFetchAdminAllProductsQuery
} = vendorProductApi;