import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Form,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../redux/store/hook';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router';
import { AdminDashboardSubCollectionPathUrl } from '../../../routes/constant';
import sent from '../../../assets/images/sent.gif';
import {
  useEditSubCollectionMutation,
  useFetchAllCollectionsQuery,
  useFetchSingleSubCollectionQuery,
} from '../../../redux/store/services/collectionApi';
import toast from 'react-hot-toast';

export default function EditSubCollection({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const fetchSubCollection = useFetchSingleSubCollectionQuery(params?.id);
  const result = useFetchAllCollectionsQuery();
  const [
    editCollectionData,
    {
      data: editCollection_data,
      isLoading: editCollection_isLoading,
      isError: editCollection_isError,
      error: editCollection_error,
      isSuccess: editCollection_isSuccess,
    },
  ] = useEditSubCollectionMutation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [details, setDetails] = useState({
    name: '',
    description: '',
    visibility: 'false',
    thumbnailUrl: '',
    file: '',
    collectionId: '',
  });
  // const formData = new FormData()
  const handleImage = (e: any) => {
    // console.log(e.target.files[0])
    setDetails({ ...details, file: e.target.files[0] });

    // console.log(formData)
  };

  const handleSubmit = async () => {
    setLoading(true);
    console.log(details);
    const subCollectionId = params.id;
    const data = {
      name: details?.name,
      description: details?.description,
      visibility: details?.visibility == 'true' ? true : false,
      collectionId: parseInt(details?.collectionId),
    };
    editCollectionData({ subCollectionId, data, token });
  };

  const uploadThumbnail = async () => {
    // await axios.get(`${BASE_URL}/api/sub-collection-category/thumbnail/${editCollection_data?.id}`).then((resp: any) => {
    //     setLoading(false)
    // }).catch(function (error: any) {
    //     console.log(error)
    // });
  };

  useEffect(() => {
    if (editCollection_isSuccess) {
      uploadThumbnail();
      handleShow();
    }

    if (editCollection_isError) {
      setLoading(false);
      //toast.error(<div className="cus_t">An error occured !</div>);
      //toastDisplay(true);
    }

    if (fetchSubCollection?.isSuccess) {
      setDetails({
        ...details,
        name: fetchSubCollection?.data.name,
        description: fetchSubCollection?.data.description,
        visibility: fetchSubCollection?.data.visibility,
        thumbnailUrl: fetchSubCollection?.data.thumbnailUrl,
        collectionId:
          fetchSubCollection?.data?.subCollectionCategory?.collectionId,
      });
    }

    // navigate(adminManageBanner)
  }, [
    editCollection_isSuccess,
    editCollection_isError,
    fetchSubCollection?.isSuccess,
  ]);

  return (
    <DashboardLayout>
      <Helmet>
        <title>Admin Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Orders Dashboard Page" />
        <meta name="description" content="Ekenta Orders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">
              {/* <FiChevronLeft /> */}
              Edit Sub Collection
            </h3>
            {fetchSubCollection?.isSuccess ? (
              <Col className="mt-3">
                <Button
                  className="mb-3 text-white"
                  onClick={() => navigate(AdminDashboardSubCollectionPathUrl)}
                >
                  <FaArrowLeft /> Back
                </Button>
                <Card>
                  <Card.Body>
                    <Form.Label htmlFor="inputPassword5">
                      Sub Collection Name *{' '}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={details?.name}
                      onChange={(e) =>
                        setDetails({ ...details, name: e.target.value })
                      }
                    />
                    <br />
                    <Form.Label htmlFor="inputPassword5">
                      Description *{' '}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={details?.description}
                      onChange={(e) =>
                        setDetails({ ...details, description: e.target.value })
                      }
                    />
                    <br />
                    <Form.Label htmlFor="inputPassword5">
                      Thumbnail Image *
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => handleImage(e)}
                    />
                    <br />
                    <Form.Label htmlFor="inputPassword5">Visibility</Form.Label>
                    <Form.Select
                      value={details?.visibility?.toString()}
                      onChange={(e) =>
                        setDetails({ ...details, visibility: e.target.value })
                      }
                    >
                      <option>Select Visibility</option>
                      <option value={'true'}>True</option>
                      <option value={'false'}>False</option>
                    </Form.Select>
                    <br />
                    <br />
                    <Form.Label htmlFor="inputPassword5">
                      Collection *
                    </Form.Label>
                    <Form.Select
                      value={details?.collectionId}
                      onChange={(e) =>
                        setDetails({ ...details, collectionId: e.target.value })
                      }
                    >
                      <option>Select Collection</option>
                      {!result.isSuccess ? (
                        <option>Loading...</option>
                      ) : (
                        result?.data?.map((e: any, i: any) => (
                          <option key={i} value={e?.id}>
                            {e?.name}
                          </option>
                        ))
                      )}
                    </Form.Select>
                    <br />
                    <Button
                      variant="danger"
                      disabled={
                        loading ||
                        details?.collectionId == '' ||
                        details?.name == '' ||
                        details?.description == ''
                      }
                      onClick={handleSubmit}
                    >
                      {loading ? 'Loading...' : 'Submit'}
                    </Button>{' '}
                    <Button variant="outline-danger">Cancel</Button>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              'Loading'
            )}
          </div>
        </Col>

        <Modal
          show={show}
          onHide={handleClose}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <Row>
              <img src={sent} alt="" height={300} width={'100%'} />
              <Button
                variant="success"
                className="text-white text-center mx-auto"
                onClick={() => navigate(AdminDashboardSubCollectionPathUrl)}
              >
                Done
              </Button>
            </Row>
          </Modal.Body>
        </Modal>
      </DashboardComponent>
    </DashboardLayout>
  );
}
