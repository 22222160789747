import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import {
  SkuFormikFormType,
} from '../../../../../interfaces/ProductListing';
import styled from 'styled-components';
import { PlusIconLg } from '../../../../../components/icons/icons';
import { FiX } from 'react-icons/fi';
import { getIn } from 'formik';
import { CustomAlert } from '../../../../../components';
import { useGenerateSkuQuery } from '../../../../../redux/store/services/productsApi';
import { useAppSelector } from '../../../../../redux/store/hook';
import axios from 'axios';
import { BASE_URL } from '../../../../../constants/index';

export default function SkuComponent({
  values,
  errors,
  setFieldValue,
}: SkuFormikFormType) {
  // const defaultValue = (options:any, val:string) => {
  //     return options ? options.find((option:any) => option.value === val): "";
  // }

  const [skip, setSkip] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { token } = useAppSelector((state) => state.auth.authentication);

  const { data, isLoading, isFetching, isError, error, isSuccess } =
    useGenerateSkuQuery(token, { skip: !skip });

  const handleClick = async (item: any) => {
    setLoading(true);
    await axios
      .get(`${BASE_URL}/api/sku-generator`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp: any) => {
        console.log(resp.data);
        setLoading(false);
        setFieldValue(`sku.${item}.skuId`, resp.data);
      })
      .catch(function (error: any) {
        console.log(error);
      });

    // setSkip(true)
    // isSuccess ? setLoading(true) : setLoading(false)
    // console.log(data)
  };

  const handleAddSku = () =>
    setFieldValue('sku', [
      {
        skuId: null,
      },
    ]);

  useEffect(() => {
    const rows = [];
    for (let i = 0; i < values.quantity; i++) {
      rows.push({ skuId: null });
    }
    setFieldValue('sku', rows);
  }, [values.quantity]);

  return (
    <Row>
      {values.sku &&
        values.sku.map((item: any, index: any) => (
          <>
            <div className="mb-3 mt-3" key={index}>
              <Row>
                <Col lg={8} className="group__div">
                  <Form.Group
                    className="input_group_div formgroup__wrapper"
                    controlId="formbulkprice"
                  >
                    <Form.Label>
                      <span>Suplier SKU</span>{" "}
                      <small className="text-danger">*</small>
                    </Form.Label>
                    <Form.Control
                      onChange={(e: any) =>
                        setFieldValue(`sku.${index}.skuId`, e.target.value)
                      }
                      value={item.skuId}
                      type="text"
                      name={`sku.${index}.skuId`}
                      placeholder=""
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} className={'mt-4 pt-2'}>
                  <Button
                    className="nav_button text-white px-4"
                    onClick={() => handleClick(index)}
                  >
                    {loading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner size="sm" animation="border" variant="white" />
                        <span className="ms-1 text-sm">
                          <small>Loading..</small>
                        </span>
                      </div>
                    ) : (
                      'Generate SKU'
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
            {getIn(errors, `sku.${index}.skuId`) && (
              <CustomAlert
                variant="danger"
                show={true}
                message={
                  <>
                    <div></div>
                    <span>{getIn(errors, `sku.${index}.skuId`)}</span>
                  </>
                }
              />
            )}
          </>
        ))}
    </Row>
  );
}
