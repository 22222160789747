import React from 'react'
import styled from 'styled-components';

interface TextButtonPropsType {
    onClick: () => void;
    name: string;
    color:string;
    className: string;
    textDecoration: string;
    type: undefined | "button" | "submit";
}

export default function TextButton({onClick,name,color,className,type,textDecoration}:TextButtonPropsType) {

const Button = styled.button `
  background-color: transparent; //#F1F5F9;
  color: ${(props) => props.color};
  padding: 7px 15px;
  border-radius: 5px;
  outline: 0;
  margin: 10px 0px;
  border: none;
  cursor: pointer;
  font-size: 13.9px;
  box-shadow: none;
  transition: ease background-color 250ms;
  text-decoration: ${(props) => textDecoration};
  &:hover {
    text-decoration: underline;
    opacity: 0.7;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;  

  return (
    <Button className={className}  type={type} onClick={onClick}>
        {name}
    </Button>
  )
}


