import React from "react"
import classnames from "classnames";
import { Col } from "react-bootstrap";
import { AppContentPropsType } from "@ekenta/interfaces"
import { ErrorCard } from "../ErrorsPage/ErrorCard";

const AppContent = ({ children, className, error, full, isPad=true, noStyle=false, ...rest }:AppContentPropsType) => {

  return (
        <Col className={classnames("app-content", className, isPad ? "px-3" : "", !noStyle ? `mx-auto pt-2", ${full ? "col-12" : "col-md-11"}` : "")} {...rest}>
        {error && (
          <ErrorCard
            title="We are Sorry..."
          />
        )}
          {!error && children}
        </Col>
  )

}

export default AppContent;