import { Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Table } from 'react-bootstrap';
import { toast } from "react-toastify";

import { FaPlus } from 'react-icons/fa';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
//import { useFetchAllCollectionsQuery } from '../../../redux/store/services/collectionApi';
import { AddCollectionUrl } from '../../../routes/constant';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader'
import { useDeleteCollectionMutation, useFetchAdminAllCollectionsQuery } from '../../../redux/store/services/collectionApi';
import { useAppSelector } from '../../../redux/store/hook';


export default function AdminCollection({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }: any) {
    const navigate = useNavigate()
    const [_pageSize, setPageSize] = useState(5);
    const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const result = useFetchAdminAllCollectionsQuery(token);
    const columns = [
        {
            Header: 'No',
            accessor: 'no',
            sortType: 'basic',
        },
        {
            Header: 'Name',
            accessor: 'name',
            sortType: 'basic',
        },
        {
            Header: 'Published',
            accessor: 'published',
            sortType: 'basic',
        },
        {
            Header: 'Order Total',
            accessor: 'ordertotal',
            sortType: 'basic',
        }
    ]
    const [deleteCollectionCall, {data: deleteCollection_data, isSuccess: deleteCollection_isSuccess}] = useDeleteCollectionMutation()

    const handleDeleteCollection = async(id:any)=>{
        // alert(id)
        deleteCollectionCall({collectionId: id, token})
    }

    useEffect(()=>{
        if(deleteCollection_isSuccess){
            toast.success(`Collection Deleted Successfully!`,{
                toastId: "admincollection-id-toast-55-success",
              });

            window.location.reload();
        }

    }, [deleteCollection_isSuccess])

    return (
        <DashboardLayout>
        <HelmetHeader
            title="Add Collection - Dashboard - Ekenta.com"
            description="Ekenta Add Collection Page"
            />

            <DashboardComponent
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            >
                <Col className=" col-lg-11 mx-auto _listing_product_ mt-1 px-2">
                    <div className="mt-0">
                        <h3 className="display_lg_header">Collection</h3>
                        <Col className="mt-4">
                            <Button className="mb-3 text-white" onClick={()=>navigate(AddCollectionUrl)}><FaPlus/> Add New Collection</Button>
                            <Card>
                                <Card.Body>
                                    <Table striped hover className='custom_table_listing'>
                                        <thead>
                                            <tr>
                                                <th>Picture</th>
                                                <th>Name</th>
                                                <th>Sub Categories</th>
                                                <th>Visibility</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !result.isSuccess ? <div>Loading</div> :
                                                result?.data?.content?.map((e:any,i:any)=>{
                                                    if(e?.deleted == null || e?.deletec == false){
                                                        return <tr key={i}>
                                                            <td><div className="picture__thumb"><img className="img-thumbnail rounded" src={e?.thumbnailUrl} /></div></td>
                                                            <td>{e?.name}</td>
                                                            <td>{e?.subCollectionCategories?.length}</td>
                                                            <td>
                                                                <Switch
                                                                    defaultChecked={e?.visibility}
                                                                />
                                                            </td>
                                                            <td><FiEdit onClick={()=>navigate(`/admin/collection/${e?.id}`)} className="cursor-pointer text-primary" fontSize={15} /></td>
                                                            <td><FiTrash2 onClick={()=>handleDeleteCollection(e?.id)} className="cursor-pointer" fontSize={15} /></td>
                                                        </tr>
                                                    }
                                                    
                                                })
                                            }
                                            
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                </Col>
            </DashboardComponent>
        </DashboardLayout>
    )
}
