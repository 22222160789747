import React, { useEffect } from "react";
import { Container, Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import { useFormik } from "formik";
import ProfileSideBar from "../component/AccountSideBar";
import updateAccountFormValidation from "@ekenta/validations/schemas/update-account.schema";
import { accountProfilePathUrl } from "@ekenta/routes/constant";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../component/BreadCrumb";
import { useFetchUserProfileDetailsQuery, useUpdateProfileMutation } from "@ekenta/redux/store/services/accountApi";
import { useAppDispatch, useAppSelector } from "@ekenta/redux/store/hook";
import { setUser } from "@ekenta/redux/store/states/reducerSlice/authenticationSlice";
import { Button, Field, HelmetHeader, Loader, PageTitle } from "@ekenta/components";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { withDefaultLayout } from "@ekenta/hoc";

import styles from "./UpdateAccount.module.scss";

const UpdateAccount = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { token, account } = useAppSelector(state => state.auth.authentication);
    const { data, isSuccess } = useFetchUserProfileDetailsQuery(token);

    const [updateUser, getResult] = useUpdateProfileMutation();
    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            gender: ""
        },
        validationSchema: updateAccountFormValidation(),
        onSubmit: values => {
            const data = {
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                username: account.user.username,
                email: account.user.email,
                gender: values.gender
            };
            updateUser({ data, token });

        }
    });


    useEffect(() => {

        if (getResult?.isSuccess) {
            toast.success(`Profile updated successfully`, {
                toastId: "profile-updated-toast-success",
            });
        }

        if (getResult?.isError && getResult?.error.status === "FETCH_ERROR") {
            toast.error("Unable to update profile", {
                toastId: "update-id-toast-error",
            });
        }

        if (getResult?.isError && getResult.error.status === 500) {
            toast.error("Something went wrong", {
                toastId: "update-500-id-toast-error",
            });
        }

        if (getResult?.isError && getResult?.error.status === 400) {
            toast.error(`Unable to update profile`, {
                toastId: "update-error-id-toast-error",
            });
        }
    }, [
        getResult?.isSuccess,
        getResult?.isError,
    ]);

    useEffect(() => {
        if (isSuccess) {
            formik.setFieldValue("firstName", data.firstName)
            formik.setFieldValue("lastName", data.lastName)
            formik.setFieldValue("gender", data.gender)
            formik.setFieldValue("phoneNumber", data.phoneNumber ? data.phoneNumber : "")
        }
    }, [isSuccess, data, formik.setFieldValue]);

    useEffect(() => {
        if (getResult?.isSuccess) {
            dispatch(setUser({
                user: {
                    id: account.user.id,
                    username: account.user.username,
                    email: account.user.email,
                    verified: account.user.verified,
                    firstName: getResult?.data.firstName,
                    lastName: getResult?.data.lastName,
                    phoneNumber: getResult?.data.phoneNumber,
                    gender: getResult?.data.gender
                },
                token: token,
                anonymousUser: false,
                ek_isAuth: true
            }));

            navigate(accountProfilePathUrl);
        }

    }, [
        getResult?.isSuccess
    ])

    return (
        <>
            <HelmetHeader
                title="Update Profile "
                description="Ekenta Update Profile Page"
            />
            <Container className="account-user-container">
                <><BreadCrumb activePage="Update Account Details" /></>

                <Row className="account-pro-row g-2">
                    <Col xs lg={3} className="d-none d-lg-block account-col-left">
                        <ProfileSideBar isLoading={null} />
                    </Col>
                    <Col className="account-profile-col-right px-0">
                        <PageTitle
                            title="Update Profile"
                            margin={false}
                            subTitle={
                                <span
                                    className="cursor-pointer d-flex w-25 align-items-center 
                                    text-muted"
                                    onClick={() => navigate(accountProfilePathUrl)}>
                                    <VscArrowSmallLeft />
                                    Back to Profile
                                </span>
                            }
                        />
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            <Col lg={9} className="account-details-row">
                                <Col sm={12} lg={12}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Field
                                            id="first-name"
                                            name="firstName"
                                            label="First name"
                                            disabled={getResult?.isLoading}
                                            type="text"
                                            required
                                            childOutsideLabel={false}
                                            placeholder="First name*"
                                            fieldClassName={styles.FieldControl__Input}
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.firstName}
                                            isError={formik.touched.firstName && formik.errors.firstName ? true : false}
                                            error={formik.errors.firstName}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={12}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Field
                                            id="last-name"
                                            name="lastName"
                                            label="Last name"
                                            disabled={getResult?.isLoading}
                                            type="text"
                                            required
                                            childOutsideLabel={false}
                                            placeholder="Last name*"
                                            fieldClassName={styles.FieldControl__Input}
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.lastName}
                                            isError={formik.touched.lastName && formik.errors.lastName ? true : false}
                                            error={formik.errors.lastName}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={12}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Field
                                            id="phone-number"
                                            name="phoneNumber"
                                            label="Phone number"
                                            disabled={getResult?.isLoading}
                                            type="number"
                                            required
                                            childOutsideLabel={false}
                                            placeholder="Phone number*"
                                            numberFieldPrefix={"+234"}
                                            fieldClassName={styles.FieldControl__Input}
                                            autoComplete="off"
                                            onChange={(e) =>
                                                formik.setFieldValue('phoneNumber', e.formattedValue)
                                            }
                                            value={formik.values.phoneNumber}
                                            isError={!!formik.errors.phoneNumber}
                                            error={formik.errors.phoneNumber}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} lg={12}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label className="_label">Gender</Form.Label>
                                        <Form.Select
                                            name="gender"
                                            disabled={getResult?.isLoading}
                                            onChange={formik.handleChange}
                                            value={formik.values.gender}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                            <option value="OTHER">Others</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col className="d-flex align-items-center justify-content-left mt-2" sm={12} md={12} lg={12}>
                                    <Button
                                        name="submit"
                                        bgVariant="primary"
                                        textColor="white"
                                        disabled={getResult?.isLoading}
                                        type="submit"
                                    >
                                        {getResult?.isLoading ? (
                                            <>
                                                <Loader color="white" />
                                                <span>Saving...</span>
                                            </>
                                        ) : <span>Save</span>}
                                    </Button>
                                </Col>
                            </Col>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default withDefaultLayout(UpdateAccount);