import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LiveProductCard from "@ekenta/components/LiveCommerce/LiveProductCard";
import { CustomAlert, EmptyState, HelmetHeader } from "@ekenta/components";
import { withDefaultLayout } from "@ekenta/hoc";
import { useFetchAllLiveSellersQuery } from "@ekenta/redux/store/services/livecommerceApi";
import livemain_banner from "@ekenta/assets/images/livemain_banner.webp";
import defaultImg from "@ekenta/assets/images/default_livestream.webp";
import { AllLiveCardLoader } from "../component/SkeletonLoader/AllLiveLoader";

const AllLiveSellers = () => {
  const getResult = useFetchAllLiveSellersQuery();

  const mapAllLiveSellers = (data: any) => {
    return data.content.length > 0 ? (
      data.content.map((result: any, index: number) => {
        return (
          <LiveProductCard
            key={`id_` + index}
            id={result.userId}
            type="isLive"
            className="item col__cardb-wrapper"
            imageUrl={result.thumbnailUrl ? result.thumbnailUrl : defaultImg}
            title={result.businessName}
          />
        );
      })
    ) : (
      <>
        <EmptyState title="No active stream" />
        <br />
      </>
    );
  };

  return (
    <>
      <HelmetHeader
        title="All Live Commerce | Ekenta.com"
        description="Ekenta Live Products Page"
      />

      <div className="dall_live_wrapper_al">
        <div className="overall__pg_top">
          <Col className="Dlarge__b_img">
            <LazyLoadImage src={livemain_banner} alt="" effect="blur" />
          </Col>
          <div className="main__container_wrapper">
            <Container className="m-0">
              <Col className="col__h3 d-flex align-items-center justify-content-start pl-0">
                <h3>
                  {getResult?.data && getResult?.data.content > 0
                    ? "Happening Now"
                    : "All Live"}
                </h3>
              </Col>
              <Row className="mt-1 row__col__div">
                {getResult?.isError && (
                  <>
                    <CustomAlert
                      variant="danger"
                      show={true}
                      message="Unable to load Livecommerce"
                    />
                    <Row>
                      <AllLiveCardLoader />
                    </Row>
                  </>
                )}
                {getResult?.isLoading ? (
                  <AllLiveCardLoader />
                ) : (
                  <>
                    {getResult?.isSuccess && getResult?.data ? (
                      <>{mapAllLiveSellers(getResult?.data)}</>
                    ) : null}
                  </>
                )}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default withDefaultLayout(AllLiveSellers);
