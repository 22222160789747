import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { createMutationRequestToText } from '.';
import { BASE_URL } from '@ekenta/constants';
import { 
    SigninUserType, 
    SigninUserWithGoogleType,
    SigninUserWithFacebookType
    
} from '@ekenta/interfaces/Authentication';

// Headers
const authenticationApiHeaders = {
    'Content-Type': 'application/json',
};

// helper func for returning the headers and others
const createMutationRequest = ({url,method,body}:{url:string,method:string,body:any}) => ({
    url:url,
    headers:authenticationApiHeaders,
    method:method,
    body:body
});

const createMutationRequestInText = ({url,method,body}:{url:string,method:string,body:any}) => ({
    url:url,
    responseHandler: (response:any) => response.text(),
    headers:{
        'content-type': 'text/plain',
    },
    method:method,
    body:body
});

const createQueryRequest = ({url,method}:{url:string,method:string}) => ({
    url:url,
    headers:authenticationApiHeaders,
    method:method
});



export const authenticationApi:any = createApi({
    reducerPath: 'authenticationApi',
    baseQuery: fetchBaseQuery({ baseUrl:BASE_URL}),
    endpoints: (builder) => ({

        verifyUserEmailUsingCode: builder.query<void, {email:string,code:string}>({
            query: (data) =>  createQueryRequest({url:`/api/verify?email=${data.email}&code=${data.code}`,method:'GET'})
        }),

        signinUserWithGoogle: builder.query<void, SigninUserWithGoogleType>({
            query: (idGoogleToken) =>  createQueryRequest({url:`/api/auth/google?id_token=${idGoogleToken}`,method:'GET'})
        }),
        signinUserWithFacebook: builder.query<void, SigninUserWithFacebookType>({
            query: (idFacebookToken) =>  createQueryRequest({url:`/api/auth/facebook?id_token=${idFacebookToken}`,method:'GET'})
        }),

        registerUser: builder.mutation<any, any>({
            query: (data) =>  createMutationRequestToText({
                url:'/api/register',method:'POST', body:data
            }),
        }),

        signinUser: builder.mutation<SigninUserType, void>({
            query: (data) => createMutationRequest({url:'/api/authenticate',method:'POST', body:data})
                
        }),
        resetUserPasswordWithEmail: builder.mutation<void, void>({
            query: (email) => createMutationRequestInText({url:'/api/reset-password/init',method:'POST', body:email})
                
        }),
        restoreUserPassword: builder.mutation<void, void>({
            query: (data) => createMutationRequest({url:'/api/reset-password/finish',method:'POST', body:data})   
        }),
    })

})

export const { 
    useVerifyUserEmailUsingCodeQuery,
    useSigninUserWithGoogleQuery,
    useSigninUserWithFacebookQuery,

    useRegisterUserMutation,
    useSigninUserMutation,
    useResetUserPasswordWithEmailMutation,
    useResetPassVerifyTokenMutation,
    useRestoreUserPasswordMutation,
    

    
} =  authenticationApi;