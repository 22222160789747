import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProductMultipleImageUploadFormikFormType } from '../../../../../interfaces/ProductListing';
import FileUploader from '../../../../../components/FileUploader/FileUploader';
import singleimageplaceholder from '../../../../../assets/images/thumbnail_single_placeholder.png';

import styled from 'styled-components';
import { PlusIconLg } from '../../../../../components/icons/icons';
import { FiX } from 'react-icons/fi';

function Multiple_ProductImages({
  values,
  errors,
  setFieldValue,
}: ProductMultipleImageUploadFormikFormType) {
  const handleAddImageField = () =>
    setFieldValue('productimages', [
      ...values.productimages,
      {
        file: null,
      },
    ]);

  const handleRemoveImageField = (index: number) => {
    const list_ = [...values.productimages];
    list_.splice(index, 1);
    setFieldValue('productimages', list_);
  };

  return (
    <Wrapper className="mt-2 mb-4">
      <Row>
        {values.productimages &&
          values.productimages.map((item, index) => (
            <>
              <Col className="position-relative" key={'ind_' + index} lg={4}>
                {values.productimages.length > 2 && (
                  <span
                    className="dissmiss__button"
                    onClick={() => handleRemoveImageField(index)}
                  >
                    <FiX color="white" />
                  </span>
                )}

                {/* acceptFileTypeList={["image/jpeg","image/jpg","image/png","image/webp"]} */}

                <FileUploader
                  maxSize={9500000}
                  placeholder={singleimageplaceholder}
                  requiredText=""
                  acceptFileType="image/*"
                  acceptFileTypeList={['image']}
                  fileType="image"
                  maxFiles={5}
                  multiple={true}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  name={`productimages.${index}.file`}
                />
              </Col>
              {values.productimages.length - 1 === index &&
                values.productimages.length < 5 && (
                  <Col lg={3} className="add__button_col">
                    <span onClick={handleAddImageField}>
                      <PlusIconLg className="plus__g" />
                    </span>
                  </Col>
                )}
            </>
          ))}
      </Row>
    </Wrapper>
  );
}

export default memo(Multiple_ProductImages);

const Wrapper = styled.div`
  .dropdiv {
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    .upload-btn-wrapper {
      .btn {
        width: 100%;
        border: 2px solid #ddd;
        color: gray;
        background-color: white;
        padding: 4px 16px;
        border-radius: 8px;
        font-size: 13px;
      }
    }
  }

  .add__button_col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    border-radius: 10px;
    z-index: 1;

    span {
      .plus__g {
        width: 40px !important;
        height: 40px !important;
        fill: rgba(0, 0, 0, 0.8) !important;
        cursor: pointer;
      }
    }
  }

  .dissmiss__button {
    position: absolute;
    padding: 0.3rem 0.3rem;
    top: 5%;
    display: flex;
    align-items: center;
    right: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    cursor: pointer;

    svg {
      font-size: 13px !important;
    }
  }

  .message_alert {
    .alert {
      display: block;
      display: -webkit-box;
      overflow: hidden;
      padding-bottom: 3rem !important;
      height: 40px !important; /* Fallback for non-webkit */
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
`;
