import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createMutationRequestToText } from '.';
import { BASE_URL } from '../../../constants/index';


const onboardingApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
});

const createMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: onboardingApiHeaders(token),
    method: method,
    body: body
});

const createMutationRequestForAnonymousUser = ({ url, method, body }: { url: string, method: string, body: any }) => ({
    url: url,
    method: method,
    body: body
});

const createQueryRequestWithoutToken = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json',
    },
    method: method
});



export const onboardingApi: any = createApi({
    reducerPath: 'onboardingApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        sellerOnboardingSignUp: builder.mutation<void, { data: any; token: string; }>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/seller-user-signup`, method: 'POST', body: data, token: token })
        }),
        sellerOnboardingAnonymousSignUp: builder.mutation<void, { data: any }>({
            query: (data) => createMutationRequestForAnonymousUser({ url: `/api/seller-anonymous-signup`, method: 'POST', body: data })
        }),

        verifyAccountDetailCredential: builder.mutation<void, { data: any }>({
            query: (data) => createMutationRequestToText({ url: `/api/verify-anonymous-credentials`, method: 'POST', body: data })
        }),

        fetchCitiesByStateId: builder.query<void, { stateId: string }>({
            query: ({ stateId }) => createQueryRequestWithoutToken({ url: `/api/city/state/${stateId}`, method: 'GET' })
        }),
    })
})

export const {
    useSellerOnboardingSignUpMutation,
    useSellerOnboardingAnonymousSignUpMutation,
    useVerifyAccountDetailCredentialMutation,
    useFetchCitiesByStateIdQuery,
} = onboardingApi;

