import React, { useContext } from 'react'
import { Button, Dropdown, Typography } from '@ekenta/components';
import { Form } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';
import classnames from 'classnames';
import Sort from './Sort/Sort';
import BrandFilter from './Filter/BrandFilter/BrandFilter';
import PriceFilter from './Filter/PriceFilter/PriceFilter';
import { SearchFilterContext } from '@ekenta/context/SearchFilterContext';

import styles from "../SellerShop.module.scss";
const ShopFilterSort = () => {
    const { configureFilterUrl, } = useContext(SearchFilterContext);

    const handleInStock = (checked: boolean) => {
        let data = { key: "in_stock", value: checked, set: true };
        if (checked) {
            data = { ...data, set: true }
        } else {
            data = { ...data, set: false }
        }
        configureFilterUrl(data);
    }

    return (
        <div className={styles.ShopTop_Filter}>
            <div className={styles.ShopTop_Filter__Left}>
                <ul className={styles.ListItem}>
                    <li>
                        <Button
                            name=""
                            bgColor="#f1f1f2"
                            className={styles.Form_Button}
                            prefixIcon={
                                <Form.Check
                                    type="checkbox"
                                    label="In stock"
                                    id="in-stock"
                                    className={styles.Form_InStock}
                                    onChange={(e) => handleInStock(e.target.checked)}
                                />
                            }
                            shadow={false}
                        />
                    </li>
                    <li>
                        <Dropdown
                            placement="bottom"
                            content={
                                <DropdownContent >
                                    <PriceFilter />
                                </DropdownContent>
                            }
                            contentClass={styles.FilterContent}
                            menus={[]}
                            tooltipContent={""}
                            render={(isOpen) => (
                                <Button
                                    name="Price"
                                    bgColor="#f1f1f2"
                                    suffixIcon={<FiChevronDown className={classnames(isOpen ? styles.Icon_Up : styles.Icon_Down)} fontSize="1rem" style={{ marginTop: "3px" }} />}
                                    shadow={false}
                                />
                            )
                            }
                        />
                    </li>
                    <li>
                        <Dropdown
                            placement="bottom"
                            content={
                                <DropdownContent>
                                    <BrandFilter />
                                </DropdownContent>
                            }
                            contentClass={styles.FilterContent}
                            menus={[]}
                            tooltipContent={""}

                            render={(isOpen) => (
                                <Button
                                    name="Brand"
                                    bgColor="#f1f1f2"
                                    suffixIcon={
                                        <FiChevronDown className={classnames(isOpen ? styles.Icon_Up : styles.Icon_Down)} fontSize="1rem" style={{ marginTop: "3px" }} />
                                    }
                                    shadow={false}
                                />)
                            }
                        />


                    </li>
                    <li></li>
                </ul>
            </div>
            <div className={styles.ShopTop_Filter__Right}>
                <ul className={styles.ListItem}>
                    <li>
                        <Typography
                            as="h5"
                            color="dark"
                            size="small"
                            className={""}
                            heading>
                            Sort by
                        </Typography>
                    </li>
                    <li>|</li>
                    <li>
                        <Sort />
                    </li>
                </ul>
            </div>
        </div>
    )
}

const DropdownContent = (props: { children: React.ReactNode }) => {
    return (
        <div className={styles.ContentWrapper}>
            <div className={styles.FlexColumn}>
                <div className={styles.ContentInner}>
                    {props.children}
                </div>
                <div>
                    <hr aria-hidden="true" className={styles.ClearHr} />
                    <div className={styles.ClearFilter}>
                        {/* <Button
                            name="Clear"
                            shadow={false}
                            onClick={() => console.log('mee')}

                        /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShopFilterSort