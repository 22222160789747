import React, { memo } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import Loader from '../Loader/Loader';

const CustomModal = (props: {
  show: boolean;
  title: string;
  onHide: () => void;
  centered?: boolean;
  render?: React.ReactNode;
  showFooter?: boolean;
  onSubmit?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <Modal
      className="modal_component shadow"
      centered={props.centered}
      show={props.show}
      onHide={props.onHide}
      backdrop={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="display_lg_header p-0"
          style={{ fontSize: '17px', margin: '0 !important' }}
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.render}</Modal.Body>
      {props.showFooter && (
        <Footer
          isLoading={props.isLoading}
          isDisabled={props.isDisabled}
          onSave={props.onSubmit}
          onClose={props.onHide}
        />
      )}
    </Modal>
  );
};

const Footer = (props: {
  isLoading?: boolean;
  isDisabled?: boolean;
  onSave?: () => void;
  onClose: () => void;
}) => {
  return (
    <Container>
      <ButtonGroup>
        <button
          type="button"
          className="btn__cancel-Button_child"
          onClick={props.onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn__submit-Button_child"
          onClick={props.onSave}
          disabled={props.isDisabled ? props.isDisabled : false}
        >
          {props.isLoading ? (
            <div className="d-flex align-items-center">
              <Loader color="white" />
              <span className="ms-1">Saving</span>
            </div>
          ) : (
            'Save'
          )}
        </button>
      </ButtonGroup>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 20px;
  padding-left: 20px;
  border-top: 1px solid #f5f5f5;
`;
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    font-size: 13px;
    padding: 7px 15px;
    border-radius: 5px;
    transition: all 0.3 ease-in-out;
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    &:hover {
      opacity: 0.8;
    }
  }

  .btn__cancel-Button_child {
    border: 1px solid #ddd;
  }

  .btn__submit-Button_child {
    border: 1px solid #e04826;
    background-color: #e04826;
    color: #fff;
  }
`;

export default memo(CustomModal);
