import React, { useState } from "react";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import { FiEdit3, FiPlusCircle } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import ProfileSideBar from "../component/AccountSideBar";
import AddressForm from "../../../../components/Form/AddressForm/AddressForm";
import EditAddressForm from "../../../../components/Form/AddressForm/EditAddressForm";
import { useAppSelector } from "../../../../redux/store/hook";
import {
  useDeleteUserAddressMutation,
  useFetchAllAddressesQuery,
  useFetchUserDefaultAddressQuery,
  useFetchUserProfileDetailsQuery,
} from "../../../../redux/store/services/accountApi";
import BreadCrumb from "../component/BreadCrumb";
import { withDefaultLayout } from "@ekenta/hoc";
import { PageTitle, HelmetHeader } from "@ekenta/components";
import BackButton from "../component/BackButton";

import styles from "./Addresses.module.scss";
import classnames from "classnames";

const AccountAddresses = () => {
  const { token, account } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editAddress, setEditAddress] = useState({});
  const handleClose = () => setShowEdit(false);
  const handleCloseNew = () => setIsNewAddress(false);
  const handleShowClick = (data: any) => {
    setEditAddress(data);
    setShowEdit(true);
  };
  const { data, isLoading, isFetching, isSuccess } =
    useFetchUserDefaultAddressQuery(token, { skip: !token });
  const { data: address_data, isSuccess: address_isSuccess } =
    useFetchAllAddressesQuery(token, {
      skip: !token,
    });
  const [deleteAddress, { isLoading: deleteAddress_isLoading }] =
    useDeleteUserAddressMutation();

  const handleDeleteAddress = (id: any) => {
    deleteAddress({ id, token });
    window.location.reload();
  };



  return (
    <>
      <HelmetHeader
        title="Manage Addresses "
        description="Ekenta Delivery addresses Page"
      />

      <Container className="account-user-container">
        <>
          <BreadCrumb activePage="Addresses" />
        </>
        <Row className="account-pro-row g-2">
          <Col xs lg={3} className="d-none d-lg-block account-col-left">
            <ProfileSideBar isLoading={null} />
          </Col>

          <Col className="account-profile-col-right px-0">
            <Col className="mx-1 mb-3 mb-lg-0 mx-lg-0">
              <PageTitle
                title="Address Book"
                margin={false}
                subTitle={<BackButton />}
              />
            </Col>
            <Row className="account-row">
              <Col lg={12} className="__address__d">

                <div className={classnames(styles.AddAddress_Button, "span_extra__action")}>
                  <span onClick={() => setIsNewAddress(true)}>
                    <FiPlusCircle className="me-1" />
                    Add new address
                  </span>
                </div>

                {isLoading && isFetching && <>Loading...</>}
                <Row>
                  {isSuccess && data && (
                    <Col lg={6}>
                      <Card>
                        <Card.Body>
                          <div className="aD_de_">
                            <h5 className="_mini_header_">Default Address</h5>
                          </div>
                          <div className="aD_n_">
                            <h5>
                              {account.user
                                ? account.user.firstName
                                : "No name"}
                            </h5>
                            <p className="mb-4 _body_text">
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "13.5px",
                                }}
                              >
                                {data.homeAddress}
                              </span>{" "}
                              <br />
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                  )}

                  {address_isSuccess
                    ? address_data.map((e: any, i: any) => {
                      if (e?.address.deleted == null || e?.address.deleted == false) {
                        return (
                          <Col lg={6} key={i}>
                            <Card>
                              <Card.Body>
                                <>
                                  <div className="aD_n_ mb-2" key={i}>
                                    <h5>
                                      {account.user
                                        ? account.user.firstName
                                        : "No name"}
                                    </h5>

                                    <p className="mb-0 _body_text">
                                      <span
                                        style={{
                                          textTransform: "capitalize",
                                          fontSize: "13.5px",
                                        }}
                                      >
                                        {e.address.homeAddress}
                                      </span>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                    </p>
                                  </div>
                                </>
                                <hr />
                                <Row className="px-2">
                                  <Col lg={3}>
                                    <div
                                      className="my-btn2"
                                      onClick={() => handleShowClick(e)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span>
                                        <FiEdit3 className="aD_icon" />
                                      </span>{" "}
                                      Edit
                                    </div>
                                  </Col>

                                  <Col
                                    lg={3}
                                    style={{
                                      color: "#FC624D",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleDeleteAddress(e.id)
                                    }
                                  >
                                    {deleteAddress_isLoading ? (
                                      "Loading"
                                    ) : (
                                      <>
                                        <span>
                                          <AiOutlineDelete className="aD_icon" />
                                        </span>{" "}
                                        Delete
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      }
                    })
                    : null}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          show={isNewAddress}
          onHide={handleCloseNew}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Row className={"p-5"}>
            <AddressForm onClose={() => setIsNewAddress(false)} />
          </Row>
        </Modal>

        <Modal
          show={showEdit}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <Row className={"p-2"}>
              <EditAddressForm
                address_data={editAddress}
                onClose={() => setShowEdit(false)}
              />
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
      <br />
      <br />
    </>
  );
};
export default withDefaultLayout(AccountAddresses);
