import * as Yup from 'yup';

const addNewAddressFormValidation = () => Yup.object().shape({
    
    stateId:Yup.string()
            .required('State is required'),
    cityId: Yup.string()
            .required('City is required'),
    homeAddress: Yup.string()
            .required('Address is required'),
//     defaultAddress: Yup.bool()
//             .required('Set default is required'),
    
});

export default addNewAddressFormValidation;