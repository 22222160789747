import React, { useContext } from 'react'
import { Row } from 'react-bootstrap'

import MainContent from './MainContent/MainContent';
import SideBar from './SideBar/SideBar';
import { SearchFilterContext } from '@ekenta/context/SearchFilterContext';

import styles from "../SellerShop.module.scss";
const SellerShopGrid = (props: any) => {
    const { getMerchant } = props;
    const { filterData, isLoading } = useContext(SearchFilterContext);



    return (
        <Row className={styles.Row}>
            <SideBar
                collection={
                    getMerchant?.isSuccess
                        ? getMerchant?.data.collection.name
                        : ""
                }
            />
            <MainContent
                data={
                    filterData
                        ? filterData :
                        getMerchant?.isSuccess
                            ? getMerchant?.data.productsOfMerchantBusinessWithActiveCollection
                            : []
                }
                isLoading={
                    isLoading ||
                    getMerchant?.isLoading
                }
                isSuccess={getMerchant?.isSuccess}

            />
        </Row>
    )
}

export default SellerShopGrid