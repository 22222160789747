import { Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Table } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FaPlus } from 'react-icons/fa';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useDeleteSubCollectionMutation,
  useFetchAllCollectionsQuery,
  useFetchAllSubCollectionsQuery,
} from '../../../redux/store/services/collectionApi';
import {
  AddCollectionUrl,
  AddSubCollectionUrl,
} from '../../../routes/constant';

export default function AdminSubCollection({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const navigate = useNavigate();
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const result = useFetchAllSubCollectionsQuery();
  const [_pageSize, setPageSize] = useState(5);
  const [
    deleteCollectionCall,
    { data: deleteCollection_data, isSuccess: deleteCollection_isSuccess },
  ] = useDeleteSubCollectionMutation();

  const columns = [
    {
      Header: 'No',
      accessor: 'no',
      sortType: 'basic',
    },
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'basic',
    },
    {
      Header: 'Published',
      accessor: 'published',
      sortType: 'basic',
    },
    {
      Header: 'Order Total',
      accessor: 'ordertotal',
      sortType: 'basic',
    },
  ];

  const handleDeleteCollection = async (id: any) => {
    // alert(id)
    deleteCollectionCall({ subCollectionId: id, token });
  };

  useEffect(() => {
    if (deleteCollection_isSuccess) {
      window.location.reload();
    }
  }, [deleteCollection_isSuccess]);

  //

  return (
    <DashboardLayout>
      <Helmet>
        <title>Seller Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta All Product Page" />
        <meta name="description" content="Ekenta All Product  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_ mt-1 px-2">
          <div className="mt-0">
            <h3 className="display_lg_header">Sub-Collections</h3>
            <Col className="mt-4">
              <Button
                className="mb-3 text-white"
                onClick={() => navigate(AddSubCollectionUrl)}
              >
                <FaPlus /> Add New Sub-Collection
              </Button>
              <Card>
                <Card.Body>
                  <Table striped hover className="custom_table_listing">
                    <thead>
                      <tr>
                        <th>Picture</th>
                        <th>Name</th>
                        <th>Collection</th>
                        <th>Visibility</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!result.isSuccess ? (
                        <div>Loading</div>
                      ) : (
                        result?.data?.map((e: any, i: any) => {
                          if (e?.deleted == null || e?.deleted == false) {
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="picture__thumb">
                                    <img
                                      className="img-thumbnail rounded"
                                      src={e?.thumbnailUrl}
                                    />
                                  </div>
                                </td>
                                <td>{e?.name}</td>
                                <td>
                                  {e?.subCollectionCategory?.collection?.name}
                                </td>
                                <td>
                                  <Switch defaultChecked={e?.visibility} />
                                </td>
                                <td>
                                  <FiEdit
                                    className="cursor-pointer text-primary"
                                    onClick={() =>
                                      navigate(`/admin/sub-collection/${e?.id}`)
                                    }
                                    fontSize={15}
                                  />
                                </td>
                                <td>
                                  <FiTrash2
                                    className="cursor-pointer"
                                    fontSize={15}
                                    onClick={() =>
                                      handleDeleteCollection(e?.id)
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          }
                        })
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
