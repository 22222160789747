import React from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import { useAppSelector } from '@ekenta/redux/store/hook';
import DefaultLayout from '../../../../hoc/Layout/Default-Layout'
import ProfileSideBar from '../component/AccountSideBar'
import ActiveOrder from './component/ActiveOrder';
import CompleteOrder from './component/CompleteOrder';
import ClosedOrder from './component/ClosedOrder';
import BreadCrumb from '../component/BreadCrumb';



export default function BuyerOrders() {
    const {token } = useAppSelector(state => state.auth.authentication);

  return (
    <DefaultLayout>
    <Helmet>
        <title>Manage Account - Orders - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Order Page" />
        <meta name="description"
                content="Ekenta Update Order Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
    </Helmet>
    <Container  className="account-user-container">
                <><BreadCrumb activePage="Order History" /></>
      
              <Row className="account-pro-row g-2">
                  <Col xs lg={3} className="d-none d-lg-block account-col-left">
                     <ProfileSideBar isLoading={null}/>
                  </Col>
                  <Col className="account-profile-col-right px-0">
                      <div className="mb-0 ">
                          <h5 className="display_lg_header d-flex align-items-center">
                              <span>Orders</span>
                          </h5>
                      </div>
                      <div className="buyer__pg_tabs">
                         <Tabs
                            defaultActiveKey="activeOrder"
                            transition={false}
                            id="noanim-tab"
                            className="mb-3"
                            >
                            <Tab eventKey="activeOrder" title="Active Orders">
                                <ActiveOrder token={token} />
                            </Tab>
                            <Tab eventKey="completeOrder" title="Complete Orders">
                                <CompleteOrder token={token} />
                            </Tab>
                            <Tab eventKey="closedOrder" title="Closed Orders">
                                <ClosedOrder token={token} />
                            </Tab>
                            </Tabs>
                      </div>
             
                  </Col>
              </Row>
          </Container>
 </DefaultLayout>
  )
}


