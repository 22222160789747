import React from 'react';
import {Card, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';

export default function LowStock({collapsed,toggled,handleToggleSidebar,handleCollapsedChange}:any) {
  return (
    <DashboardLayout>
        <Helmet>
        <title>Seller Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta All Product Page" />
        <meta name="description"
                content="Ekenta All Product  Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
        </Helmet>      

        <DashboardComponent
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            >
            <Col className=" col-lg-11 mx-auto _listing_product_">
                <div className="mt-0">
                    <h3 className="display_lg_header">Low Stock</h3>
                    <Col className="mt-5"> 
                          <Card>
                             <Card.Body>
                                 <TableComponent columns={[]} data={[]} pageSizeno={5} />
                             </Card.Body>
                          </Card>
                      </Col>
                    </div>
                </Col>
        </DashboardComponent>
    </DashboardLayout>
  )
}
