
import React from 'react';
import Select from 'react-select';
import classnames from 'classnames';

import styles from "./CustomSelect.module.scss";



interface CustomSelectPropsType {
  options: { label: string; value: string }[];
  value: { label: string; value: string };
  className: string;
  placeholder: string;
  isLoading: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  reference?: any;
  noOptionsMessage: (obj: { inputValue: string; }) => React.ReactNode | undefined;
  onChanged: (value: string) => any;
  onBlur: (e: React.FocusEvent<any, Element>) => void;
}

const CustomSelect = (
  { isDisabled,
    reference,
    onChanged,
    onBlur,
    isClearable,
    noOptionsMessage,
    isLoading,
    placeholder,
    options,
    value,
    className
  }: CustomSelectPropsType) => {

  // const defaultValue = (options: { label: string; value: string }[], value: string) => {
  //   return options ? options.find((option: any) => option.value === parseInt(value)) : "";
  // }

  const style = {
    menu: (base: any) => ({
      ...base,
      fontSize: "14px",

    }),
    control: (base: any) => ({
      ...base,
      "&:hover": {
        borderColor: "",
        color: ""
      }
    }),
  };

  return (
    <div className={className}>
      <div className={classnames(styles.CustomSelect__Wrapper)}>
        <Select
          ref={reference ? reference : null}
          isClearable
          isDisabled={isDisabled}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={(value: any) => onChanged(value)}
          isLoading={isLoading}
          noOptionsMessage={noOptionsMessage}
          onBlur={onBlur}
          isSearchable
          styles={style}
        />
      </div>
    </div>
  )
}

export default CustomSelect;