import React from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import  {useFormik} from 'formik';
import { Container, Form,Row,Col, Button, Spinner } from 'react-bootstrap';
import ProfileSideBar from './component/AccountSideBar';
import DefaultLayout from '../../../hoc/Layout/Default-Layout'
import changePassFormValidation from '../../../validations/schemas/account-changepassword.schema';
import { accountProfilePathUrl } from '../../../routes/constant';
import { useAppSelector } from '../../../redux/store/hook';
import { useChangeUserPasswordMutation } from '../../../redux/store/services/accountApi';
import CustomToast from '../../../components/Toast/CustomToast';
import BreadCrumb from './component/BreadCrumb';


export default function ChangePassword() {
    const navigate = useNavigate();
    const { token } = useAppSelector(state => state.auth.authentication);
    const  [changeUserPassword, { isLoading, isError, error, isSuccess}] = useChangeUserPasswordMutation();


    const formik = useFormik({
        initialValues:{
            currentpassword: '',   
            newpassword: '',
            confirmpassword: '',
        },
       validationSchema: changePassFormValidation(),
          onSubmit: values => {
            const _data = {
                currentPassword: values.currentpassword,
                newPassword: values.newpassword,
            };
            const groupData = {
                data:_data,
                token:token
            }
            // pass in the data
            changeUserPassword(groupData);
           
        }
    });

    if(isSuccess){
        setTimeout(() =>{
            navigate(accountProfilePathUrl);
        },1500)
    }
  return (
    <DefaultLayout>
    <Helmet>
        <title> Change Password - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Change Password Page" />
        <meta name="description"
                content="Ekenta Change Password Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
    </Helmet>
    <Container  className="account-user-container">
        <><BreadCrumb activePage="Change Password" /></>

        <Row className="account-pro-row g-2">
            <Col xs={12} lg={3} className="d-none d-lg-block account-col-left">
               <ProfileSideBar isLoading={null}/>
            </Col>
            <Col className="account-profile-col-right px-0">
                <div className="mb-2  border-bottom">
                    <h5 className="mx-3 _display-header d-flex align-items-center">
                        <a onClick={() => navigate(`${accountProfilePathUrl}`)}>
                            <svg className="" width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0303 8.53033C11.3232 8.23744 11.3232 7.76256 11.0303 7.46967C10.7374 7.17678 10.2626 7.17678 9.96967 7.46967L5.96967 11.4697C5.82322 11.6161 5.75 11.8081 5.75 12C5.75 12.1017 5.77024 12.1987 5.80691 12.2871C5.84351 12.3755 5.89776 12.4584 5.96967 12.5303L9.96967 16.5303C10.2626 16.8232 10.7374 16.8232 11.0303 16.5303C11.3232 16.2374 11.3232 15.7626 11.0303 15.4697L8.31066 12.75H18C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25H8.31066L11.0303 8.53033Z" fill="black"/>
                            </svg>
                        </a>
                        <span>Change Password</span>
                    </h5>
                </div>
                <>
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Row className="account-details-row">
                     
                        <Col sm={12} lg={6}>
                        <Form.Group className="mb-3 position-relative">
                                <Form.Label className="_label">Current Password</Form.Label>
                                    <Form.Control  type="password" name="currentpassword" placeholder="******"
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.currentpassword}
                                    isInvalid={!!formik.errors.currentpassword}
                                    />
                                    {formik.touched.currentpassword && formik.errors.currentpassword ? (
                                        <Form.Control.Feedback className="_helper_text" type="invalid">
                                            {formik.errors.currentpassword}
                                        </Form.Control.Feedback>
                                    ): null}
                               </Form.Group>
                        </Col>
                       
                        <Col sm={12} lg={6}>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label className="_label">New Password</Form.Label>
                                    <Form.Control  type="password" name="newpassword" placeholder="******"
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.newpassword}
                                    isInvalid={!!formik.errors.newpassword}
                                    />
                                    {formik.touched.newpassword && formik.errors.newpassword ? (
                                        <Form.Control.Feedback className="_helper_text" type="invalid">
                                            {formik.errors.newpassword}
                                        </Form.Control.Feedback>
                                    ): null}
                               </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label className="_label">Confirm Password</Form.Label>
                                    <Form.Control  type="password" name="confirmpassword" placeholder="******"
                                    autoComplete='off'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.confirmpassword}
                                    isInvalid={!!formik.errors.confirmpassword}
                                    />
                                    {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                                        <Form.Control.Feedback className="_helper_text" type="invalid">
                                            {formik.errors.confirmpassword}
                                        </Form.Control.Feedback>
                                    ): null}
                               </Form.Group>
                        </Col>
                        <br />
                        <br />
                        <br />
                      <Col className="d-flex align-items-center justify-content-center mt-5" sm={12} md={12} lg={12}>
                        <Button className={`w-50 nav_button d-block text-white px-4 ${isLoading ? 'disabled': ''}`} type="submit">
                            {isLoading ? (<div className="d-flex align-items-center justify-content-center"><Spinner animation="grow" />
                                           <span className="ms-1 text-sm"><small>Please Wait...</small></span></div>) :
                                (<span>Save</span>)
                            }
                        </Button>
                    </Col>
                    </Row>
                </Form>

                </>
            </Col>
        </Row>
    </Container>
    
    {isSuccess  ? (<CustomToast type='success' color='success' show={true} message={'Password Changed Successfully'} />) : ""}
    {isError && error.status === 'FETCH_ERROR' ? (<CustomToast type='error' color='danger' show={true} message={'Changed Password Failed, try again'} />) : ""}
    {isError && error.status === 400 ? (<CustomToast type='error' color='danger' show={true} message={error.data.title} />) : ""}
    {isError && error.status === 401 ? (<CustomToast type='error' color='danger' show={true} message={error.data.message} />) : ""}

</DefaultLayout>
  )
}
