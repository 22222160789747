import { Button, Tooltip } from '@ekenta/components'
import { Close } from '@ekenta/components/icons';
import classnames from 'classnames';
import React, { useState } from 'react'
import styles from "./FloatingFab.module.scss";

const FloatingFab = (props: { icon: JSX.Element; actions: { text: string; type: string; icon: JSX.Element; url?: string; onClick?: () => void }[] }) => {

    const [open, setOpen] = useState(false);



    return (
        <div className={classnames(styles.FloatingFabMain__Container)}>
            {open ? (
                <>
                    {props.actions ? (
                        <>
                            {props.actions.length > 0 ? props.actions.map((item: any, index: number) => {
                                const Class = classnames({
                                    [styles.FloatingFab__Whatsapp]: item.type === "whatsapp",
                                    [styles.FloatingFab__Mail]: item.type === "mail",
                                    [styles.FloatingFab__Feedback]: item.type === "feedback",
                                });
                                return (
                                    <ActionButton
                                        text={item.text}
                                        children={item.icon}
                                        url={item.url}
                                        className={Class}
                                        onClick={item.onClick}
                                    />
                                )
                            }) : null}
                        </>
                    ) : null}
                </>
            ) : null}
            <Button
                width=""
                defaultClass={false}
                onClick={() => setOpen(prev => !prev)}
                className={styles.FloatingFabMain__Container__Button}>
                {
                    open ? <Close /> : props.icon
                }
            </Button>
        </div>
    )
}


const ActionButton = (props: { children: React.ReactNode; text: string; className: string; url?: string; onClick?: () => void }) => {
    return (
        <Tooltip
            placement="left"
            content={props.text}>
            <a
                href={props.url}
                target="_blank"
                data-value={props.text}
                data-configure="true"
                className={styles.FloatingFabMain__Container__Action}>
                <Button
                    width=""
                    defaultClass={false}
                    onClick={props.onClick}
                    className={classnames(styles.FloatingFabMain__Container__ActionButton, props.className)}>
                    {props.children}
                </Button>
            </a>
        </Tooltip>
    )
}

export default FloatingFab;