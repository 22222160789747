import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FiBarChart,
  FiBarChart2,
  FiCalendar,
  FiChevronLeft,
  FiGift,
  FiLogOut,
  FiVideo,
} from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";
import { FaUser, FaUserCheck } from "react-icons/fa";
import { VscClose, VscDashboard } from "react-icons/vsc";
import { toast } from "react-toastify";

import { Menu } from "./inner/Menu";
import { MenuItem } from "./inner/MenuItem";

import {
  adminAccountingOrders,
  adminAccountingSellers,
  adminAllOrders,
  AdminDashboardCollectionPathUrl,
  AdminDashboardSubCollectionCategoriesPathUrl,
  AdminDashboardSubCollectionPathUrl,
  AdminDiscountPathUrl,
  adminGetAllUsersUrl,
  adminGetAllVerifiedUsersUrl,
  adminManageBanner,
  adminManageLogo,
  adminPayoutRequest,
  AdminProductsPathUrl,
  adminReturnRequest,
  AdminSellerProfile,
  AdminSellerProfileId,
  AdminUserMangement,
  adminVideos,
  DashboardCollectionPathUrl,
  DashboardEventsPathUrl,
  DashboardOrdersPathUrl,
  DashboardPathUrl,
  DashboardProductsPathUrl,
  DashboardReturnRequestPathUrl,
  DashboardSellerLiveCommerceBaseUrl,
  DashboardSellerLiveCommercePathUrl,
  DashboardSellers,
  homePathUrl,
  loginPathUrl,
} from "@ekenta/routes/constant";
import logo from "@ekenta/assets/images/ekentanew.webp";
import liveAD from "@ekenta/assets/images/live-streaming3.png";
import { useAppDispatch, useAppSelector } from "@ekenta/redux/store/hook";
import {
  checkIfAdmin,
  checkIfAdminOrSellerRoleExist,
  logoutHandler,
  useMatchPath,
} from "@ekenta/utils";
import { removeUser } from "@ekenta/redux/store/states/reducerSlice/authenticationSlice";

import { SubMenu } from "./inner/SubMenu";
import { useGoogleLogout } from "react-google-login";
import { GOOGLE_CLIENTID } from "@ekenta/constants";
import { removeLive } from "@ekenta/redux/store/states/reducerSlice/livecomSlice";

export const DashboardCustomSideBar = ({
  visibilty,
  setvisibilty,
}: {
  visibilty: boolean;
  setvisibilty: any;
}) => {
  const location = useLocation();
  const { signOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENTID ? GOOGLE_CLIENTID : "",
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { ek_isAuth, account, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const isAdmin = checkIfAdmin(token);

  const logoutHandlerFunc = () => {
    logoutHandler(
      navigate,
      dispatch,
      signOut,
      loginPathUrl,
      removeUser,
      removeLive,
    );
    toast.success(`You"ve successfully logout`, {
      toastId: "logout-id-toast-success",
    });
  };

  return (
    <AnimatePresence>
      <>
        <motion.div
          transition={{ type: "spring", bounce: 0, duration: 0.1 }}
          className={`dashboard-custom-sidebar ${visibilty ? "visble-sidebar" : "not-visble-sidebar"
            }`}
        >
          <div>
            <div className="dashboard-brand d-flex align-items-center justify-content-between">
              <img
                alt="ekenta logo"
                src={logo}
                width="140px"
                height="auto"
                className="d-inline-block mx-3 mt-1 align-top"
              />{" "}
              <button
                className="navbar-toggler d-lg-none p-0 me-2 border-0"
                type="button"
                aria-expanded="false"
                onClick={() => setvisibilty(false)}
              >
                <span className="navbar-mobile-icon">
                  <VscClose className="text-white" fontSize={27} />
                </span>
              </button>
            </div>
            <div className="switch-buyer">
              <MenuItem
                text="Switch to Buyer"
                icon={
                  <FiChevronLeft
                    fontSize={20}
                    style={{ marginRight: "0.1rem", marginBottom: "0.1rem" }}
                  />
                }
                active={false}
                link={homePathUrl}
              />
            </div>
            <div className="dashboard-menu-links">
              <Menu className="topp-menu-list pb-0">
                <div className="menu-title">
                  <h5>Menu</h5>
                </div>
                <Scrollbars
                  className="t-menu-scroll"
                  thumbMinSize={50}
                  style={
                    ek_isAuth &&
                      checkIfAdminOrSellerRoleExist(token) &&
                      !isAdmin
                      ? { width: "100%", height: "250px" }
                      : { width: "100%", height: "400px", marginBottom: "40px" }
                  }
                >
                  <MenuItem
                    active={isAdmin ? location.pathname == DashboardSellers : location.pathname == DashboardPathUrl}
                    icon={<VscDashboard fontSize={17} />}
                    link={isAdmin ? DashboardSellers : DashboardPathUrl}
                    text="Dashboard"
                  />

                  <MenuItem
                    icon={<FiGift fontSize={17} />}
                    active={isAdmin ? location.pathname == AdminProductsPathUrl : location.pathname == AdminProductsPathUrl}
                    link={isAdmin ? AdminProductsPathUrl : DashboardProductsPathUrl}
                    text="Products"
                  />

                  {isAdmin && (
                    <SubMenu
                      text="Collections"
                      icon={<FiBarChart2 fontSize={16} />}
                      active={false}
                    >
                      <MenuItem
                        icon={<FiGift fontSize={17} />}
                        active={location.pathname == DashboardCollectionPathUrl}
                        link={
                          isAdmin
                            ? AdminDashboardCollectionPathUrl
                            : DashboardCollectionPathUrl
                        }
                        text="All Collections"
                      />

                      <MenuItem
                        icon={<FiGift fontSize={17} />}
                        active={
                          location.pathname ==
                          AdminDashboardSubCollectionPathUrl
                        }
                        link={AdminDashboardSubCollectionPathUrl}
                        text="All Sub-Collections"
                      />
                      <MenuItem
                        icon={<FiGift fontSize={17} />}
                        active={
                          location.pathname ==
                          AdminDashboardSubCollectionCategoriesPathUrl
                        }
                        link={AdminDashboardSubCollectionCategoriesPathUrl}
                        text="All Sub-Collection Categories"
                      />

                    </SubMenu>
                  )}


                  {isAdmin ? (
                    <MenuItem
                      active={
                        location.pathname == DashboardEventsPathUrl
                      }
                      icon={<FiCalendar fontSize={17} />}
                      link={DashboardEventsPathUrl}
                      text="Events"
                    />
                  ) : null}

                  <SubMenu
                    text="Sales"
                    icon={<FiBarChart2 fontSize={16} />}
                    active={false}
                  >
                    <MenuItem
                      icon={<FiGift fontSize={17} />}
                      active={
                        location.pathname == adminAllOrders ||
                        location.pathname == DashboardOrdersPathUrl
                      }
                      link={isAdmin ? adminAllOrders : DashboardOrdersPathUrl}
                      text="Orders"
                    />
                    <MenuItem
                      icon={<FiGift fontSize={17} />}
                      active={
                        location.pathname == DashboardReturnRequestPathUrl ||
                        location.pathname == adminReturnRequest
                      }
                      link={
                        isAdmin
                          ? adminReturnRequest
                          : DashboardReturnRequestPathUrl
                      }
                      text="Returns Request"
                    />
                  </SubMenu>
                  {isAdmin ? (
                    <>
                      <SubMenu
                        text="Accounting"
                        icon={<FiBarChart fontSize={16} />}
                        active={false}
                      >
                        <MenuItem
                          icon={<FiGift fontSize={17} />}
                          active={location.pathname == AdminDiscountPathUrl}
                          link={AdminDiscountPathUrl}
                          text="Discount"
                        />
                        <MenuItem
                          icon={<FiGift fontSize={17} />}
                          active={location.pathname == adminAccountingOrders}
                          link={adminAccountingOrders}
                          text="Orders"
                        />
                        <MenuItem
                          icon={<FiGift fontSize={17} />}
                          active={location.pathname == adminAccountingSellers}
                          link={adminAccountingSellers}
                          text="Sellers"
                        />
                        <MenuItem
                          icon={<FiGift fontSize={17} />}
                          active={location.pathname == adminPayoutRequest}
                          link={adminPayoutRequest}
                          text="Payout Requests"
                        />
                      </SubMenu>

                      <SubMenu
                        text="Manage Users"
                        icon={<FaUser fontSize={16} />}
                        active={false}
                      >
                        <MenuItem
                          icon={<FaUser fontSize={17} />}
                          active={location.pathname == adminGetAllUsersUrl}
                          link={AdminUserMangement}
                          text="User Management"
                        />
                        <MenuItem
                          icon={<FaUser fontSize={17} />}
                          active={location.pathname == adminGetAllUsersUrl}
                          link={adminGetAllUsersUrl}
                          text="All Users"
                        />
                        <MenuItem
                          icon={<FaUserCheck fontSize={17} />}
                          active={
                            location.pathname == adminGetAllVerifiedUsersUrl
                          }
                          link={adminGetAllVerifiedUsersUrl}
                          text="All Verified Users"
                        />
                      </SubMenu>

                      <MenuItem
                        text="Manage Logo"
                        icon={<FiGift fontSize={16} />}
                        active={location.pathname == adminManageLogo}
                        link={adminManageLogo}
                      />
                      <MenuItem
                        text="Manage Banner"
                        icon={<FiGift fontSize={16} />}
                        active={location.pathname == adminManageBanner}
                        link={adminManageBanner}
                      />

                      <MenuItem
                        text="Manage Video"
                        icon={<FiVideo fontSize={16} />}
                        active={location.pathname == adminVideos}
                        link={adminVideos}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Scrollbars>
              </Menu>

              <Menu className="to-menu-list mt-3">
                <div className="menu-title">
                  <h5>General</h5>
                </div>
                <MenuItem
                  text="Logout"
                  icon={<FiLogOut />}
                  active={location.pathname == adminVideos}
                  link={adminVideos}
                  onClick={logoutHandlerFunc}
                />
              </Menu>
            </div>
          </div>


        </motion.div>

        {visibilty && (
          <OutsideDiv
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setvisibilty(false)}
          />
        )}
      </>
    </AnimatePresence>
  );
};

const OutsideDiv = styled(motion.div)`
  display: flex;
  position: absolute;
  top: 0%;
  right: 0%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 99999;
`;
