import React from 'react'
import classnames from 'classnames';

import { Row } from 'react-bootstrap';
import ProductListLoader from '@ekenta/pages/Collections/component/SkeletonLoader/ProductListLoader';
import LazyLoad from 'react-lazyload';
import ProductCardComponent from '@ekenta/components/Product/ProductCardComponent';
import { Fragment } from 'react';
import { CustomAlert } from '@ekenta/components';

import styles from "./Section.module.scss";
import "./index.scss";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useFetchCartItemsQuery } from '@ekenta/redux/store/services/cartApi';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { mapCartDataReturnQuantity } from '@ekenta/utils';
const SectionCard = (props:
    {
        title: string;
        headerColor?: string;
        data?: any;
        isSuccess?: boolean;
        isLoading?: boolean;
        isError?: boolean;
        margin?: string;
        render?: React.ReactNode;
    }) => {
    const { data, isSuccess, isLoading, render, margin = "20px", headerColor = "#FEE2CC", isError } = props;
    const { token } = useAppSelector(state => state.auth.authentication);

    const { data: allCartData } = useFetchCartItemsQuery(token, { skip: !token });

    return (
        <section className={classnames(styles.CardBox)} style={{ marginTop: `${margin}` }}>
            <div className={styles.CardBox__Title} style={{ background: `${headerColor}` }}>
                <div className={styles.SectionTitle__Wrapper} data-spm-id="2g0o">
                    <div
                        className={styles.SectionTitle__Wrapper__Headings}
                        style={{ textTransform: "capitalize" }}>
                        {props.title}
                    </div>
                </div>
            </div>
            <div className={styles.CardBox__content}>
                <div className={classnames(styles.CardBox__content_Wrapper, "CardBox_Wrapper")}>
                    {!render ? (
                        <Row className="product_vid_commponent p-0 m-0">
                            {isLoading ? <ProductListLoader /> : null}
                            <Fragment>
                                {isSuccess && data ? (
                                    <>
                                        {data.length > 0
                                            ? data.map((item: any) => {
                                                return (
                                                    <LazyLoadComponent key={item.id}>
                                                        <ProductCardComponent
                                                            productId={item.id}
                                                            product={item}
                                                            layoutView={'grid'}
                                                            cartItem={mapCartDataReturnQuantity(allCartData, item.id)}
                                                            isShadow={false}
                                                            productname={item.name}
                                                            defaultprice={item.price}
                                                            discount={item.discount}
                                                            discountPercentage={item.discountPercentage}
                                                            maxPrice={item.maxPrice}
                                                            minPrice={item.minPrice}
                                                            thumbnailUrl={
                                                                item.imageUrls ? item.imageUrls[0] : ''
                                                            }
                                                            imageUrl={item.imageUrls ? item.imageUrls[1] : ''}
                                                            videoUrl={item.videoUrl}
                                                            condition={item.condition}
                                                            savedItems={[]}
                                                        />
                                                    </LazyLoadComponent>
                                                );
                                            })
                                            : null}
                                    </>
                                ) : null}
                            </Fragment>



                            {isError ? (
                                <Fragment>
                                    <CustomAlert
                                        variant="danger"
                                        show={true}
                                        message="Unable to load Content"
                                    />
                                    <ProductListLoader />
                                </Fragment>
                            ) : null}
                        </Row>
                    ) : (
                        <Fragment>
                            {render}
                        </Fragment>
                    )}
                </div>
            </div>
        </section>
    )
}

export default SectionCard