import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { withDashboardLayout } from "@ekenta/hoc";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { useSetOrderToPaidMutation } from "@ekenta/redux/store/services/adminSellerApi";
import {
  useFetchOrderListDetailsQuery,
  useSetOrderToDeliveredMutation,
  useSetOrderToOutForDeliveryMutation,
} from "@ekenta/redux/store/services/vendorProductApi";
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router";
// import OrderSkeletonLoader from "../../components/SkeletonLoader/Account/OrderSkeletonLoader"
import { checkIfAdmin } from "@ekenta/utils";
// import { OverlayLoader } from "../../styledcomponent";
import CustomLottieLoader from "@ekenta/components/Spinner/CustomLottieLoader";
import * as animationData from "@ekenta/assets/lotties_files/loading-bubbles.json";
import OrderSkeletonLoader from "@ekenta/components/SkeletonLoader/Account/OrderSkeletonLoader";
import { DataTable, Loader, Overlay, PageTitle, Pagination, Typography } from "@ekenta/components";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { adminAllOrders } from "@ekenta/routes/constant";

const OrderListDetails = () => {

  const { token } = useAppSelector((state) => state.auth.authentication);
  const isAdmin = checkIfAdmin(token);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const orderId = params.id;
  const order = useFetchOrderListDetailsQuery({ token, orderId });
  const [setDelivered, delivered] = useSetOrderToDeliveredMutation();
  const [setOutForDelivery, out] = useSetOrderToOutForDeliveryMutation();
  const [setOrderToPaid, paidResult] = useSetOrderToPaidMutation();

  const [pageno, setPageno] = useState(0);
  // const
  // const allOrders = useFetchDailyTotalOrderQuery(token)
  // const ord = useFetchAccountingSalesAdminQuery(token)

  const handleChange = (value: any) => {
    if (value == "DELIVERED") {
      setOrderToPaid({ orderId, token });
    } else if (value == "OUT_FOR_DELIVERY") {
      setOutForDelivery({ orderId, token });
    }
  };

  useEffect(() => {
    if (paidResult?.isSuccess) {
      toast.success("Set To Paid Successfully", {
        toastId: "set-paid-id-toast-success",
      });
      setDelivered({ orderId, token });
    }

    if (paidResult?.isError && paidResult?.error.status === "FETCH_ERROR") {
      toast.error("Setting Order To Paid Failed", {
        toastId: "set-paid-id-toast-error",
      });
    }

    if (paidResult?.isError && paidResult?.error.status === 500) {
      toast.error("Setting Order To Paid Failed", {
        toastId: "set-paid2-id-toast-error",
      });
    }

    if (paidResult?.isError && paidResult?.error.status === 400) {
      toast.error(`${paidResult?.error.data.title}`, {
        toastId: "set-paid3-id-toast-error",
      });
    }
  }, [
    paidResult?.isSuccess,
    paidResult?.isError,
    paidResult?.error
  ]);

  useEffect(() => {
    if (delivered?.isSuccess) {
      toast.success("Set To Delivered Successfully", {
        toastId: "set-delivered-id-toast-success",
      });
      navigate(adminAllOrders);
    }

    if (delivered?.isError && delivered?.error.status === "FETCH_ERROR") {
      toast.error("Set Order To Delivered Failed", {
        toastId: "set-delivered-id-toast-error",
      });
    }

    if (delivered?.isError && delivered?.error.status === 500) {
      toast.error("Set Order To Delivered Failed", {
        toastId: "set-delivered2-id-toast-error",
      });
    }

    if (delivered?.isError && delivered?.error.status === 400) {
      toast.error(`${delivered?.error.data.title}`, {
        toastId: "set-delivered3-id-toast-error",
      });
    }
  }, [
    delivered?.isSuccess,
    delivered?.isError,
    delivered?.error
  ]);


  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Image",
      accessor: "image",
      disableSortBy: true,
    },
    {
      Header: "Seller",
      accessor: "seller",
      disableSortBy: true,
    },
    {
      Header: "Seller Phone no",
      accessor: "seller_phone_no",
      disableSortBy: true,
    },
    {
      Header: "Seller Email",
      accessor: "seller_email",
      disableSortBy: true,
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      disableSortBy: true,
    },
    {
      Header: "Price",
      accessor: "price",
    },
    {
      Header: "Total",
      accessor: "total",
      disableSortBy: true,
    },
  ];

  const mapData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      name: item.product?.name,
      image: (
        <div
          className="picture__thumb"
          style={{ width: "70px", height: "auto" }}
        >
          <img
            className="img-thumbnail rounded"
            width="100%"
            height="50px"
            src={item.product?.imageUrls ? item.product?.imageUrls[0] : ""}
          />
        </div>
      ),
      seller: item?.product?.user?.username,
      quantity: item.quantity,
      seller_email: item?.product.user?.email,
      price: `NGN ${new Intl.NumberFormat().format(item?.price)}`,
      total: `NGN ${new Intl.NumberFormat().format(item?.price)}`,
    }));
  }

  return (
    <>
      <Helmet>
        <title>OrderList - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Orders Dashboard Page" />
        <meta name="description" content="Ekenta Orders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <Col className=" col-lg-12 _listing_product_ px-3 dash_top">

        <PageTitle
          title="Order Details"
          subTitle={
            <span
              className="cursor-pointer d-flex w-25 align-items-center 
                    text-muted"
              onClick={() => navigate(-1)}>
              <VscArrowSmallLeft />
              Back to Orders
            </span>
          }
        />

        {!order.isSuccess ? (
          <OrderSkeletonLoader />
        ) : (
          <Row className="px-3">
            <Row className={"mt-2 p-2 ps-0"}>
              <Col lg={6}>
                Status:
                {isAdmin ? (
                  <select
                    className="form-control"
                    onChange={(e: any) => handleChange(e.target.value)}
                  >
                    <option value="PENDING">PENDING</option>
                    <option value="DELIVERED">DELIVERED</option>
                    <option value="OUT_FOR_DELIVERY">OUT FOR DELIVERY</option>
                  </select>
                ) : (
                  order.data?.orderStatus
                )}
              </Col>
              <Col lg={1}>&nbsp;</Col>
              <Col>
                <h5>Items from orders #{order.data?.id}</h5>
              </Col>
            </Row>

            {/* <Row className={"mt-3 p-2"}>
              <Col className="card py-3 box-Shadow">
                <h5>Buyer details</h5>
                <div className="row">
                  <div className="col-md-offset-2 col-md-12">
                    <div className="well profile">
                      <div className="col-sm-12">
                        <div className="col-xs-12 col-sm-8">
                          <p>
                            <strong>Name</strong>:{" "}
                            {order.data?.user?.firstName +
                              " " +
                              order.data?.user?.lastName}{" "}
                          </p>
                          <p>
                            <strong>Email: </strong> {order.data?.user?.email}{" "}
                          </p>
                          <p>
                            <strong>Phone: </strong>{" "}
                            {order.data?.user?.phoneNumber}{" "}
                          </p>
                          <p>
                            <strong>Address: </strong>{" "}
                            {order.data?.address?.homeAddress}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}

            <Row className="table-responsive p-2">
              {/* <Table className="mt-4" striped bordered hover>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.data?.orderItems?.map((e: any, i: any) => (
                    <tr key={i}>
                      <td width={"50%"}>{e.product?.name}</td>
                      <td>
                        <img
                          src={
                            e.product?.thumbnailUrl ?? e.product?.imageUrls[0]
                          }
                          height="100"
                          width="100"
                          alt=""
                        />{" "}
                      </td>
                      <td>{e?.quantity}</td>
                      <td>N {new Intl.NumberFormat().format(e?.price)}</td>
                      <td>N {new Intl.NumberFormat().format(e?.price)}</td>
                    </tr>
                  ))}

                  <tr>
                    <td colSpan={4}>Total</td>
                    <td>
                      N{" "}
                      {new Intl.NumberFormat().format(
                        order.data?.totalOrderAmount,
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table> */}
              <DataTable
                theme="simple"
                data={order?.data ? mapData(order.data?.orderItems) : []}
                loading={order?.isLoading}
                isSelectable={true}
                columns={columns}
                showPagination={false}
                hasLink={true}
                thClassName="w-auto"
                tdClassName={`Row__col`}
                renderTop={
                  <div className="mb-2">
                    <Typography
                      id="buyer_details"
                      size="small"
                      className="mb-3"
                      as="h5"
                    >Buyer details</Typography>
                    <p>
                      <strong>Name</strong>:{" "}
                      {order.data?.user?.firstName +
                        " " +
                        order.data?.user?.lastName}{" "}
                    </p>
                    <p>
                      <strong>Email: </strong> {order.data?.user?.email}{" "}
                    </p>
                    <p>
                      <strong>Phone: </strong>{" "}
                      {order.data?.user?.phoneNumber}{" "}
                    </p>
                    <p>
                      <strong>Address: </strong>{" "}
                      {order.data?.address?.homeAddress}{" "}
                    </p>
                  </div>
                }
                renderBottom={
                  <div className="d-flex align-items-center justify-content-between pt-2 px-2 pe-4">
                    <Typography
                      id="totalId"
                      size="small"
                      as="h5"
                    >Total</Typography>
                    <Typography
                      id="totalNumber"
                      size="small"
                      as="span">
                      NGN {new Intl.NumberFormat().format(
                        order.data?.totalOrderAmount,
                      )}
                    </Typography>
                  </div>
                }
                renderPagnation={
                  <>
                    {order?.data
                      ? order?.data.totalElements > 20 && (
                        <Pagination
                          pathname={pathname}
                          itemPerPage={order?.data.pageable.pageSize}
                          itemsLength={order?.data.totalElements}
                          currentPage={pageno}
                        />
                      )
                      : null}
                  </>
                }

              />
            </Row>
          </Row>
        )}
      </Col>

      {delivered.isLoading || paidResult?.isLoading || out.isLoading ? (
        <Overlay bgColor="light"><Loader isCenter={true} /></Overlay>
      ) : null}

    </>
  );
}

export default withDashboardLayout(OrderListDetails);
