import React, { useState } from 'react';
import { Badge, Card, Col, Tab, Tabs } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import OrderSkeletonLoader from '../../../components/SkeletonLoader/Account/OrderSkeletonLoader';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchCancelledOrdersQuery,
  useFetchCompletedOrdersQuery,
  useFetchPendingOrdersQuery,
  useFetchProcessingOrdersQuery,
} from '../../../redux/store/services/vendorProductApi';
import withDashboardLayout from '../../../hoc/Layout/withDashboardLayout';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { VscArrowSmallLeft } from 'react-icons/vsc';
import { DashboardPathUrl } from '../../../routes/constant';

const Orders = () => {
  const navigate = useNavigate();

  const {token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  //const allOrders = useFetchAllOrdersQuery(token);
  const result = useFetchAllOrdersQuery(token);
 // const completed = useFetchCompletedOrdersQuery(token);
  const pending = useFetchPendingOrdersQuery(token);
  const cancelled = useFetchCancelledOrdersQuery(token);
  const processing = useFetchProcessingOrdersQuery(token);

  const recalibrate = (data: any) => {
    const newArr = [];
    for (let i = data.length - 1; i >= 0; i--) {
      newArr.push(data[i]);
    }

    return newArr.map((items: any, i: any) => ({
      ...items,
      datecreated: items.date,
      totalAmount: 'NGN ' + new Intl.NumberFormat().format(items.totalAmount),
      view: (
        <Link to={`/vendor/dashboard/order-details/${items.orderId}`}>
          <BsEye size={24} color={'#E04826'} />
        </Link>
      ),
    }));
  };

  const [_pageSize, setPageSize] = useState(7);

  const columns = [
    {
      Header: 'Order',
      accessor: 'orderId',
      sortType: 'basic',
    },
    {
      Header: 'Total Amount',
      accessor: 'totalAmount',
      sortType: 'basic',
    },
    {
      Header: 'Payment Status',
      accessor: 'paymentStatus',
      sortType: 'basic',
    },
    {
      Header: 'Delivery Status',
      accessor: 'deliveryStatus',
      sortType: 'basic',
    },
    {
      Header: 'Date Created',
      accessor: 'datecreated',
      sortType: 'basic',
    },
    {
      Header: 'Action',
      accessor: 'view',
      sortType: 'basic',
    },
  ];



  return (
    <>
      <HelmetHeader title="All Orders" description="Ekenta All Orders Page" />
      <Col className=" col-12 pt-0 mt-0 _listing_product_  px-2">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title=" All Orders"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-auto align-items-center
                    text-muted"
                  onClick={() => navigate(DashboardPathUrl)}
                >
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
          </Col>
          <Col className="mt-2 tab__ p-2">
            <Tabs defaultActiveKey="processing" id="u__tab" className="mb-3">
              <Tab
                eventKey="pending"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_pending">
                      {pending?.data?.content?.length}
                    </Badge>{' '}
                    Pending
                  </span>
                }
              >
                <Card>
                  <Card.Body>
                    {!pending.isSuccess ? (
                      <OrderSkeletonLoader />
                    ) : pending.data.content.length > 0 ? (
                      <TableComponent
                        columns={columns}
                        data={recalibrate(pending.data.content)}
                        pageSizeno={_pageSize}
                      />
                    ) : (
                      <EmptyOrder orderType="Pending" />
                    )}
                  </Card.Body>
                </Card>
              </Tab>
              <Tab
                eventKey="processing"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_processing">
                      {processing?.data?.content?.length}
                    </Badge>{' '}
                    Processing
                  </span>
                }
              >
                <Card>
                  <Card.Body>
                    {!processing.isSuccess ? (
                      <OrderSkeletonLoader />
                    ) : processing.data.content.length > 0 ? (
                      <TableComponent
                        columns={columns}
                        data={recalibrate(processing.data.content)}
                        pageSizeno={_pageSize}
                      />
                    ) : (
                      <EmptyOrder orderType="Processing" />
                    )}
                    {/* <TableComponent columns={columns} data={data} pageSizeno={_pageSize} /> */}
                  </Card.Body>
                </Card>
              </Tab>

              <Tab
                eventKey="completed"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_completed">
                      {' '}
                      {result?.data?.content?.length}
                    </Badge>{' '}
                    Completed
                  </span>
                }
              >
                <Card>
                  <Card.Body>
                    {!result.isSuccess ? (
                      <OrderSkeletonLoader />
                    ) : result.data.content.length > 0 ? (
                      <TableComponent
                        columns={columns}
                        data={recalibrate(result.data.content)}
                        pageSizeno={_pageSize}
                      />
                    ) : (
                      <EmptyOrder orderType="Completed" />
                    )}
                  </Card.Body>
                </Card>
              </Tab>

              <Tab
                eventKey="cancelled"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_cancelled">
                      {cancelled?.data?.content?.length}
                    </Badge>{' '}
                    Cancelled
                  </span>
                }
              >
                <Card>
                  <Card.Body>
                    {!cancelled.isSuccess ? (
                      <OrderSkeletonLoader />
                    ) : cancelled.data.content.length > 0 ? (
                      <TableComponent
                        columns={columns}
                        data={recalibrate(cancelled.data.content)}
                        pageSizeno={_pageSize}
                      />
                    ) : (
                      <EmptyOrder orderType="Cancelled" />
                    )}
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </div>
      </Col>
    </>
  );
};

export default withDashboardLayout(Orders);