import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap";
import { FiBarChart2, FiClipboard, FiFramer, FiGift, 
        FiLayers, 
        FiRefreshCcw, 
        FiSlash, 
        FiTruck, 
        FiUsers 
       } from "react-icons/fi";
import { HelmetHeader } from "@ekenta/components";
import { withDashboardLayout } from "@ekenta/hoc";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { useFetchAverageRevenueQuery, useGetTotalUserChurnRateQuery } from "@ekenta/redux/store/services/adminSellerApi";
// import { abbrevateNumber } from "../../../../utils";
import Card from "./components/Card";
import { Filters } from "./components/Filters";

const UserManagement = () => {
  const [currentDate, setCurrentDate] = useState<{
      from: null | string;
      to: null | string;
      key: string;
   }>({
     from: null,
     to: null,
     key: ""
   });

   useEffect(() => {
    console.log(currentDate,"currentDate")
   },[currentDate]);

  const { token } = useAppSelector(state => state.auth.authentication);
  const data = {
          token: token,
          from: currentDate.from,
          to: currentDate.to
        }
  const getAverage = useFetchAverageRevenueQuery(data, {skip: !data.from && !data.to});
  const churnRate =   useGetTotalUserChurnRateQuery(data, {skip: !data.from && !data.to});

  // useEffect(() => {
       
  // },[]);

  console.log(getAverage?.data)
  console.log(churnRate?.data)


  const listOfData = [
    {name:"Total Active Orders", value: 1000, icon: <FiBarChart2 />},
    {name:"Total Pending Orders", value: 120, icon: <FiClipboard />},
    {name:"Total Delivered Orders", value: 200, icon: <FiTruck />},
    {name:"Total Cancelled Orders", value: 150, icon: <FiSlash />},
    {name:"Total Sellers", value: 0, icon: <FiUsers />},
    {name:"Total Collections", value: 0, icon: <FiLayers />},
    {name:"Total Products", value: 2000, icon: <FiGift />},
    {name:"Total Return Request", value: 0, icon: <FiRefreshCcw />},
    {name:"Total Brands", value: 0, icon: <FiFramer />},
    {name:"Total Active Customers", value: 820000, icon: <FiUsers />}, 
    {name:"Total Churn Rate", value: `${churnRate?.isSuccess ? churnRate?.data : 0.0}`, icon: ""},
    {name:"Average Order Value", value: 0, icon: ""},
    {name:"Gross Marchant Value", value: 0, icon: ""},
  ]
  return (
    <>
      <HelmetHeader
        title="User Management Collection"
        description="Ekenta Add Collection Page"
        />

        <Col className=" col-lg-11 mx-auto Usermanagemenet__Div" >
                <div className="mt-1">
                  <Col className={"d-flex align-items-center justify-content-between"}>
                      <h3 className="display_lg_header m-0">
                          User Management
                      </h3>
                      <Filters
                         setCurrentDate={setCurrentDate}
                      />                                                                                                                                                                                                                                                                                                                                                      
                  </Col>
                  <Col className="w-100 mt-4">
                     <div className="UserManagement__rowDirection">
                      {listOfData.map((item:any, index:number) => (
                        <Card key={"id"+index}
                          name={item.name}
                          value={item.value}
                          icon={item.icon}
                        />
                      ))}
                     </div>
                  </Col>
               </div>
        </Col>

    </>
  )
}



export default  withDashboardLayout(UserManagement);