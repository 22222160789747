import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../../../redux/store/hook';
import { useNavigate, useParams } from 'react-router';

import { DashboardProductsPathUrl } from '../../../routes/constant';
import {
  useFetchSingleProductByIdQuery,
} from '../../../redux/store/services/productsApi';
import { slugifyText } from '../../../utils';
import ProductDetailSlider from '../../Product/component/Slider/ProductDetailSlider';
import ColorBox from '../../../components/Box/ColorBox';
import { useSetProductToPublishOrUnpublishMutation } from '../../../redux/store/services/vendorProductApi';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import withDashboardLayout from '../../../hoc/Layout/withDashboardLayout';

const mapMediaDataToSlider = (images: string[]) => {
  if (!images) return [];
  if (images.length == 0) return [];
  const listImages: any[] = [];
  const mapD =
    images.length > 0 &&
    images.map((item) => {
      if (item) {
        const data = {
          original: `${item}`,
          thumbnail: `${item}`,
        };
        listImages.push(data);
      }
    });

  return listImages;
};

const PreviewProduct = () => {
  const { token } = useAppSelector((state) => state.auth.authentication);
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const productId = params['productslug']
    ? params.productslug!.slice(params.productslug!.lastIndexOf('-') + 1)
    : null;
  const {
    data: productData,
    isLoading: productIsLoading,
    isSuccess: productIsSuccess,
  } = useFetchSingleProductByIdQuery(productId, { skip: !productId });

  const [show, setShow] = useState(false);
  const [_mediaList, setMediaList] = useState<
    { original: string; thumbnail: string }[] | null
  >(null);
  // const _slugCollection = productIsSuccess
  //   ? slugifyText( 
  //     productData.subCollection.name.toLowerCase(),
  //     productData.subCollection.id,
  //   )
  //   : '/';



  const [
    setProductToPublishOrUnpublish,
    {
      isSuccess: publishproduct_isSuccess,
    },
  ] = useSetProductToPublishOrUnpublishMutation();

  const togglePublish = () => {
    if (productIsSuccess) {
      setLoading(true);
      setProductToPublishOrUnpublish({
        productId: productData.product.id,
        token,
        visibility: true
      });
    }
  };

  useEffect(() => {
    if (publishproduct_isSuccess) navigate(DashboardProductsPathUrl);
  }, [publishproduct_isSuccess]);

  useEffect(() => {
    if (productData) {
      const result = mapMediaDataToSlider(productData.product.imageUrls);
      const allSlideImGVid = [
        {
          id: "video",
          original: productData.product.imageUrls[1],
          thumbnail: productData.product.imageUrls[0],
          embedUrl: productData.product.videoUrl,
        },
        ...result,
      ];
      setMediaList(allSlideImGVid);
    }
  }, [productData]);

  return (
    <Fragment>
      <HelmetHeader
        title="Preview Product"
        description="Ekenta Preview Product Page"
      />
      <Col className=" col-lg-11 mx-auto dash_top _add_product_ px-3">
        <Row className="row__col_d p-0 m-0">
          <Col lg={9} sm={12} className="col__div p-0">
            <h3 className="display_lg_header">Preview Product</h3>
            <p>
              Ensure that the details of the products are correct before
              publishing the product.
            </p>
          </Col>
        </Row>
        <Col lg={6}>
          <Button className={'text-white'} onClick={() => navigate(-1)}>Edit Product</Button>{' '}
          <Button
            className={'text-white btn-secondary'}
            disabled={loading}
            onClick={togglePublish}   >
            {loading ? 'Loading' : 'Publish Product'}
          </Button>
        </Col>
        <div className="dF_aS_">
          {productIsLoading ? (
            <>Loading...</>
          ) : (
            <>
              <div className="CCS">
                {productIsSuccess ? (
                  <>
                    <div className="_prod_wrapper_">
                      <div className="_top_level_">
                        <Card>
                          <Card.Body>
                            <div className="_row_main__">
                              <div className="row__col_first_wrap">
                                <div className="slider__carouel_container carmt-2">
                                  <div className="carousel__ecommerce__style">
                                    {/* <Col xs={11}> */}
                                    {_mediaList ? (
                                      <ProductDetailSlider
                                        imageList={productData.imageUrls}
                                        images={_mediaList}
                                      />
                                    ) : (
                                      <div className="place--holder">
                                        holder
                                      </div>
                                    )}
                                    {/* </Col> */}
                                    <Row className="zoom__magnify__portal">
                                      <Col xs={2}>
                                        {' '}
                                        <div id="myPortal" />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                              <div className="_row__col_second_wrap_sideZ">
                                <Card>
                                  <Card.Body>
                                    <div className="_container_rig_col">
                                      <div className="_rig_top_col">
                                        <div className="_top_rig_detailZ">
                                          {/* <a className="_visit_href_">Visit Vendors Store</a> */}
                                          <h3 className="_product__title_">
                                            {productData.product.name}
                                          </h3>
                                        </div>
                                        <div className="_rating_row_ m-0">
                                          <div className="_k_rating_row">
                                            <div className="d-rating-score">
                                              <NumberFormat
                                                value={
                                                  productData?.averageSellerRating
                                                }
                                                displayType="text"
                                                decimalSeparator="."
                                                decimalScale={1}
                                                fixedDecimalScale
                                                allowNegative={false}
                                              />
                                              <FiChevronDown />
                                            </div>
                                          </div>
                                          <div className="rating_que_">
                                            30 reviews
                                          </div>
                                        </div>
                                        <div className="split-line-thinner"></div>
                                        <div className="_price_prod_div">
                                          <span className="_price__digit_ mt-2">
                                            <NumberFormat
                                              value={productData.product.price}
                                              displayType="text"
                                              thousandSeparator={true}
                                              prefix="₦"
                                              className="some"
                                            />
                                          </span>
                                        </div>

                                        <div className="split-line-thinner"></div>
                                      </div>
                                      <div className="_rig_bottom_col">
                                        <Col className="__feature__parent__">
                                          <div className="__feature__child">
                                            Brand:
                                            {/* <span className="__feature__value">
                                              {' '}
                                              {productData?.brand?.name}
                                            </span> */}
                                          </div>
                                        </Col>
                                        {productData.productFeatureList.length > 0
                                          ? productData.productFeatureList.map(
                                            (e: any, i: any) => {
                                              return (
                                                <>
                                                  {e.feature &&
                                                    e.featureValue ? (
                                                    <Col
                                                      className="__feature__parent__"
                                                      key={i}
                                                    >
                                                      <div className="__feature__child">
                                                        {e.feature
                                                          ? e.feature.name
                                                          : null}
                                                        :
                                                        <span className="__feature__value">
                                                          {' '}
                                                          {e.featureValue.toLowerCase()}
                                                        </span>
                                                      </div>
                                                      {e.feature.name.toLowerCase() ===
                                                        'color' && (
                                                          <ul className="__feature__ulist">
                                                            <li
                                                              className="__feature__ulist_item"
                                                              data-spm-index="li_no"
                                                            >
                                                              <ColorBox
                                                                color={e.featureValue.toLowerCase()}
                                                              />
                                                            </li>
                                                          </ul>
                                                        )}
                                                    </Col>
                                                  ) : null}
                                                </>
                                              );
                                            },
                                          )
                                          : null}

                                        <Col className="__delivery_dynamic_div_">
                                          <span className="__delivery_dynamic_title">
                                            Delivery Charges
                                          </span>
                                          <div className="__delivery_dynamic_description">
                                            <span className="d-block">
                                              Select a Delivery agency of your
                                              choice at checkout to see delivery
                                              charges.
                                            </span>
                                            Deliver to all state in Nigeria
                                          </div>
                                        </Col>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {productIsSuccess ? (
                <div className="CSS__Bottom__gen">
                  <div className="_top__store__brand">
                    <Card>
                      <Card.Body>
                        <h5 className="mb-3">Product description</h5>
                        <div className="mb-5">
                          {productData.product.shortDescription}
                        </div>
                        <h5 className="my-3">Other Details</h5>
                        <Row>
                          <Col>
                            <h6>Condition</h6>
                            <p style={{ textTransform: 'capitalize' }}>
                              {productData.product.condition}
                            </p>
                          </Col>
                          {/* <Col>
                            <h6>Sub Collection</h6>
                            <p>{productData?.subCollection?.name}</p>
                          </Col> */}
                          <Col>
                            {/* <h6>Brand</h6>
                            <p>{productData?.brand?.name}</p> */}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="_down__list__prod_desc">
                    <Card>
                      <Card.Body>
                        <h5 className="mb-3">Features</h5>
                        <Row>
                          <Col>
                            <h6>Key Features</h6>
                            <div>
                              {productData.productFeatureList.length > 0 &&
                                productData.productFeatureList.map(
                                  (e: any, i: any) => (
                                    <Col className="__size__parent__" key={i}>
                                      <span className="_size__">
                                        {e.feature
                                          ? `${e.feature.name}:`
                                          : null}{' '}
                                      </span>
                                      <span style={{ color: 'gray' }}>
                                        {' '}
                                        {e.featureValue}
                                      </span>
                                    </Col>
                                  ),
                                )}
                            </div>
                          </Col>
                          <Col>
                            <h6>Warranty</h6>
                            <p>
                              Product Warranty{' '}
                              {productData.product.hasWarranty ? 'Yes' : 'No'}
                            </p>
                          </Col>
                          <Col>
                            <h6>SKU</h6>
                            <p>
                              {productData.product.skus.length > 0 &&
                                productData.product.skus[0]}
                            </p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              ) : null}
              {/* Modal Overlay for share  */}
            </>
          )}

          <div className="_recommend_pro_detail_">
            <Container className="p-0">
              <br />
              <Row className="p-0">{/* <RecommendedSliderCard /> */}</Row>
            </Container>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default withDashboardLayout(PreviewProduct);
