import classnames from 'classnames'
import React, { Fragment, useState } from 'react'
import styles from "./FeatureShop.module.scss";
import { CustomAlert, RefreshLink, Typography } from '@ekenta/components';
import { useGetAllMerchantQuery } from '@ekenta/redux/store/services/homeApi';
import SectionCard from '../SectionCard/SectionCard';
import shopLogo from "@ekenta/assets/images/shoplogo.webp";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';


const FeatureShop = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [translateValue, setTranslateValue] = useState(0);


    const getAllMerchant = useGetAllMerchantQuery();
     const length = getAllMerchant?.isSuccess ?
        getAllMerchant?.data.merchantBusinessList &&
        getAllMerchant?.data.merchantBusinessList.length : 10;


    console.log(currentIndex, Math.ceil(length / 5), "currentIndex")

    const prevSlide = () => {
        if (currentIndex === 0) return;
        setCurrentIndex(currentIndex - 1);

        setTranslateValue(translateValue + carouselSlideWidth());
    };

    const nextSlide = () => {
        if (currentIndex === Math.ceil(length / 5)) {
            return setCurrentIndex(0), setTranslateValue(0);
        }
        setCurrentIndex(currentIndex + 1);
        setTranslateValue(translateValue - carouselSlideWidth());
    };

    const carouselSlideWidth = () => {
        const ele = document.querySelector('.carousel-single');
        return ele!.clientWidth * 2;
    };


    return (
        <Fragment>
            <SectionCard
                title="Featured Shops"
                headerColor="#fff"
                margin="10px"
                render={
                    <div className={classnames(styles.Container)}>
                        <div className={styles.aside_Module}>
                            {getAllMerchant?.isLoading ? <>Loading...</> : null}
                            {getAllMerchant?.isError ? (
                                <CustomAlert
                                    variant="danger"
                                    show={true}
                                    message={"Something went wrong, Try again"}
                                />
                            ) : null}
                            <section className={styles.aside_Module__section} style={{ transform: `translate(calc(${translateValue}px))` }}>
                                {getAllMerchant?.isSuccess && getAllMerchant?.data.merchantBusinessList ? getAllMerchant?.data.merchantBusinessList.map((item: any, index: number) => (
                                    <RefreshLink to={`/seller/shop/${item.id}`} key={index}
                                        className={classnames(styles.mini_CardModule, "carousel-single")}>
                                        <div className={styles.Avatar_module_Div}>
                                            <div className={styles.ImageWrapper}>
                                                <img src={shopLogo} data-id="responsiveImage"
                                                    srcSet={shopLogo}
                                                    alt="avatar" />
                                            </div>
                                        </div>
                                        <div>
                                            <Typography
                                                as="p"
                                                color="dark"
                                                size="small"
                                                className={styles.mini_Card_title}
                                                uiText>
                                                {item.businessName}
                                            </Typography>


                                        </div>
                                    </RefreshLink>
                                )) : null}

                            </section>
                        </div>

                        <button className={classnames(styles.SlideButton, styles.Left)} onClick={prevSlide}>
                            <FiChevronLeft />
                        </button>
                        <button className={classnames(styles.SlideButton, styles.Right)} onClick={nextSlide}>
                            <FiChevronRight />
                        </button>
                    </div>
                }
            />
        </Fragment>
    )
}

export default FeatureShop
