import React from "react";
import classnames from "classnames";
import { TableCellPropType } from "../../interfaces/Table";

import styles from "./Table.module.scss";

function TableCell({
  component: Component = "td",
  align = null,
  contentAlign = null,
  children,
  className,
  widthMinimize,
  noWrap,
  ...rest
}:TableCellPropType) {
  const tableHeadClasses = classnames(
    [Component === "th" ? styles.th : styles.td],
    className,
    contentAlign === "center" ? styles["content-center"] : null,
    align === "center" ? styles["align-center"] : null,
    align === "right" ? styles["align-right"] : null,
    {
      [styles.widthMinimize]: widthMinimize,
      [styles.noWrap]: noWrap,
    },
  );
  return (
    <Component className={tableHeadClasses} {...rest}>
      {children}
    </Component>
  );
}
export default TableCell;