import React, { Fragment, useCallback, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'

import styles from "./MainContent.module.scss";
import { Typography } from '@ekenta/components';
import ProductListLoader from '../../component/SkeletonLoader/ProductListLoader';
import ProductCardComponent from '@ekenta/components/Product/ProductCardComponent';
import EmptyBox from '@ekenta/components/Box/EmptyBox';
import { mapCartDataReturnQuantity } from '@ekenta/utils';
import { useAppSelector } from '@ekenta/redux/store/hook';
import { useFetchCartItemsQuery } from '@ekenta/redux/store/services/cartApi';
import { SearchFilterContext } from '@ekenta/context/SearchFilterContext';
import { useLocation } from 'react-router-dom';

const MainContent = (props: {
    data: any; isLoading: boolean; isSuccess: boolean;
    pageno?: number;
    setPageno?: any;
    queryNo?: string;
    renderTop?: React.ReactElement
}) => {
    const {
        isLoading,
        filterData,
        pageNo,
        setPageNo,
    } = useContext(SearchFilterContext);
    //const { pathname } = useLocation();

    const { token } = useAppSelector(state => state.auth.authentication);
    const { data: allCartData } = useFetchCartItemsQuery(token);


    const newData = useCallback(() => {
        return filterData ? filterData
            : props.isSuccess ? props.data
                : [];
    }, [filterData, props.isSuccess]);



    return (
        <Col xs={12} lg={10} className={styles.MainContent__Wrapper}>
            {props.renderTop ? props.renderTop : (
                <section className={styles.HeaderSection}>
                    <Typography
                        as="h2"
                        color="dark"
                        size="small"
                        className={styles.Heading}
                        heading>
                        Shop Products
                    </Typography>
                </section>
            )}
            <section>
                <Row className="prod_commponent_row_ p-0 m-0">
                    {props.isLoading || isLoading ? (
                        <ProductListLoader />
                    ) : null}

                    {
                        newData().length > 0 ? (
                            <Fragment>
                                {newData().map((item: any) => (
                                    <ProductCardComponent
                                        key={item.id}
                                        productId={item.id}
                                        product={item}
                                        layoutView={'grid'}
                                        cartItem={mapCartDataReturnQuantity(
                                            allCartData,
                                            item.id,
                                        )}
                                        isShadow={true}
                                        maxPrice={item.maxPrice}
                                        minPrice={item.minPrice}
                                        discount={item.discount}
                                        discountPercentage={item.discountPercentage}
                                        productname={item.name}
                                        defaultprice={item.price}
                                        imageUrl={
                                            item.imageUrls ? item.imageUrls[1] : item.thumbnailUrl
                                        }
                                        thumbnailUrl={item.imageUrls[0]}
                                        videoUrl={item.videoUrl}
                                        condition={item.condition}
                                    />
                                ))}
                            </Fragment>
                        ) : <EmptyBox />
                    }

                    {/* {isSuccess &&
                        newData.total > 20 ? (
                        <Pagination
                            pathname={pathname}
                            itemPerPage={newData.per_page}
                            itemsLength={newData.total}
                            currentPage={filterData ? pageNo : pageno}
                            queryNo={props.queryNo}
                            callback={(value) => filterData ? setPageNo(value) : setPageno(value)}
                        />
                    ) : null} */}
                </Row>
            </section>
        </Col>
    )
}

export default MainContent;