import React, { Suspense, useEffect, useState, useRef, memo } from 'react'
import { Form, Spinner } from 'react-bootstrap';
//import { useFormik } from 'formik';

import { FiPlus } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { BrandsFormikFormType } from '../../../../../interfaces/ProductListing';
import { useFetchBrandsByCollectionIdQuery } from '../../../../../redux/store/services/vendorProductApi';
import { InfoIcon } from '../../../../../components/icons/icons'
import CustomModal from '../../../../../components/Modal/CustomModal';
import { useAddBrandRequestMutation, useMapBrandToCollectionMutation } from '../../../../../redux/store/services/vendorProductApi';
import { useAppSelector } from '../../../../../redux/store/hook';
import { getIn } from 'formik';
const CustomSelect = React.lazy(() => import('../../../../../components/Select/CustomSelect'));

const mapCollectionData = (data: any) => {
  return data && data.map((item: any) => (
    {
      value: item.id,
      label: item.name
    }
  ));

}


const Brands_Select_List = ({ setFieldValue,
  handleBlur,
  values,
  touched,
  setFieldTouched,
  errors }: BrandsFormikFormType) => {

  const { token } = useAppSelector(state => state.auth.authentication);

  const [options, setOptions] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [brand, setBrand] = useState({
    name: '',
  });
  const [iserror, setisError] = useState('');



  const collectionId = values.collectionId;
  const { data, isLoading, isError, error, isSuccess } = useFetchBrandsByCollectionIdQuery(collectionId, { skip: !collectionId });
  const [addBrandRequest, results] = useAddBrandRequestMutation();
  const [mapBrandToCollection, mappedResult] = useMapBrandToCollectionMutation();
  const reference = useRef<any>(null)


  useEffect(() => {
    if (data && isSuccess) {
      const obj = mapCollectionData(data.brands);
      setOptions(obj);
    }

    if (reference.current && collectionId) {
      if (reference.current.getValue().length > 0) {
        reference.current.setValue({
          value: '',
          label: ''
        });
      }
    }
  }, [data, isSuccess, collectionId]);


  useEffect(() => {
    if (results?.isSuccess && values.collectionId) {
      const data = {
        brandId: results?.data.id,
        collectionId: values.collectionId
      };
      mapBrandToCollection({
        data,
        token
      });
      handleHideModal();
    }
  }, [results?.isSuccess, values.collectionId]);


  const defaultValue = (options: any, val: string) => {
    return options ? options.find((option: any) => option.value === val) : "";
  }

  const handleHideModal = () => {
    setisError('');
    setBrand({
      name: '',
    });
    setShow(false);
  }

  const handleShowModal = () => setShow(true);

  const handleSubmit = () => {
    setisError('');

    if (brand.name.trim()) {
      const data = {
        name: brand.name,
        description: ""
      }

      addBrandRequest({
        data,
        token,
      });

    } else {
      setisError('*Field is required');
    }
  }

  return (
    <>
      <h5>
        <span>Brand</span> <small className="text-danger">*</small>
        <InfoIcon />
      </h5>
      <Form.Group className=" formgroup__wrapper" controlId="formbrands">
        <Suspense fallback={<Spinner animation='border' size='sm' />}>
          <div className="brand__ui__container">
            <CustomSelect
              reference={reference}
              isDisabled={!values.collectionId ? true : false}
              className="custom-select"
              placeholder="Select Brands..."
              noOptionsMessage={() => isError ? 'Could not load, refresh' : 'No Brands Found'}
              isLoading={isLoading}
              value={defaultValue(options, values.brandId)}
              options={options}
              onBlur={handleBlur}
              onChanged={(value: any) => {
                //reference.current.clearValue();
                setFieldValue('brandId', value.value);
                setFieldTouched('brandId', true);

              }}

            />
            <div>
              <button type="button" className="btn__new_brand disabled" data-tip="Add Brand" data-for='new_brand'
                disabled={!values.collectionId ? true : false} onClick={handleShowModal}>
                <FiPlus />
              </button>
              <ReactTooltip id='new_brand' place="top" type="dark" effect="solid" />
            </div>
          </div>
          {
            getIn(touched, 'brandId') &&
              getIn(errors, 'brandId')
              ? (
                <div className="invalid-fbk">
                  {errors.brandId}
                </div>
              ) : null}
        </Suspense>
      </Form.Group>

      <CustomModal
        title="Add Brand"
        show={show}
        centered={false}
        onHide={handleHideModal}
        showFooter={true}
        isLoading={results?.isLoading || mappedResult?.isLoading}
        isDisabled={false}
        onSubmit={handleSubmit}
        render={<RenderComponent brand={brand} error={iserror} setBrand={setBrand} />}
      />
    </>
  )
}

const RenderComponent = (props: {
  brand: { name: string }; error: string; setBrand: React.Dispatch<React.SetStateAction<{
    name: string;
  }>>
}) => {
  return (
    <DIV>
      <div className="mb-2">
        <label>Name</label>
        <Input
          type="text"
          height={'400px'}
          name="addbrand"
          value={props.brand.name}
          onChange={(e) => props.setBrand({ ...props.brand, name: e.target.value })}
        />
        {props.error && !props.brand.name ? <small>{props.error}</small> : null}
      </div>
    </DIV>
  )
}

const DIV = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;

   label{
     font-size: 13px;
     margin-bottom: 5px;
     font-family: 'Noto Sans', sans-serif;
     font-weight: 400;
   }

   small{
    font-size: 12px;
    color: red;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;

 

`;
export default memo(Brands_Select_List);