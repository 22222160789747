import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Spinner } from "@ekenta/components/icons/icons";
import styles from "./Loader.module.scss";
import "./Loader.scss";

const Loader = ({
  withWrapper = false,
  color,
  isCenter = false,
  large,
  small,
  className,
}: { withWrapper?: boolean; small?: boolean; isCenter?: boolean; color?: string, className?: string, large?: boolean }) => {
  const loadClasses = classnames(className, styles.svgLoader, {
    [styles.isCenter]: isCenter,
    "Spinner--size--large": large,
    "pinner--size--small": small,
  });

  if (withWrapper) {
    return (
      <span className={loadClasses}>
        <Spinner color={color} />
      </span>
    );
  }

  if (isCenter) {
    return (
      <span className={styles.isCenter}>
        <Spinner color={color} />
      </span>
    );
  }

  return <Spinner color={color} />;
};

Loader.propTypes = {
  /**
   * Whether the spinner has a span wrapper
   */
  withWrapper: PropTypes.bool,
  /**
   * What spinner color to use
   */
  color: PropTypes.string,
  /**
   * Whether the spinner is centered on the screen
   */
  isCenter: PropTypes.bool,
};

export default Loader;