import { motion } from 'framer-motion';
import React, { forwardRef } from 'react'
import Scrollbars from 'react-custom-scrollbars';
import { useLocation } from 'react-router';
import { DashboardSellerLiveCommercePathUrl } from '../../../routes/constant';
import { useMatchPath } from '../../../utils';
import { DashboardCustomNavBar } from './DashboardCustomNavBar'

export const DashboardCustomMainBar = forwardRef((props:{children: React.ReactNode,visibilty:boolean;setvisibilty:React.Dispatch<React.SetStateAction<boolean>>}, ref:any) => {

  const { pathname } = useLocation();
  return (
    <motion.div
      ref={ref}
      animate={{
        scale: props.visibilty ? 0.9 : 1,
        opacity: props.visibilty ? 0.6 : 1
      }}
      transition={{ type: "spring", bounce: 0, duration: 0.4 }}
      className={` dashboard-custom-main-bar ${pathname !== useMatchPath(DashboardSellerLiveCommercePathUrl,pathname)
            ? 'dashboard-custom-main-bar-margin': ''}`}>
            {pathname !== useMatchPath(DashboardSellerLiveCommercePathUrl,pathname) ? (
               <DashboardCustomNavBar  setvisibilty={props.setvisibilty} />
            ): null }
            
              <Scrollbars 
                   className={` dashboard-custom-main-bar-content-div ${pathname === useMatchPath(DashboardSellerLiveCommercePathUrl,pathname)
                    ? ' dashboard-live-stream-bg': null}`}
                   thumbMinSize={50}  style={{ height: '100vh' }}>
               {props.children}
               </Scrollbars>
    </motion.div>
  )
});

DashboardCustomMainBar.displayName = 'DashboardCustomMainBar';