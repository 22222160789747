import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import * as animationData from '../../assets/lotties_files/success_congrat.json';
import * as animData from '../../assets/lotties_files/success_check1.json';
import CustomLottieLoader from '../../components/Spinner/CustomLottieLoader';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { ordersPathUrl } from '../../routes/constant';
import { useNavigate } from 'react-router';
import withCheckoutLayout from '../../hoc/Layout/withCheckoutLayout';
import { HelmetHeader } from '../../components/HelmetHeader/HelmetHeader';

import "./index.scss";
import "./CheckoutCallbackPage.scss";

const CheckoutCallbackPage = () => {

  const navigate = useNavigate();

  return (
    <>
      <HelmetHeader 
        title="Checkout Process - Ekenta.com"
        description="Ekenta Order and Checkout Page"
      />
      <div className="d_checkout_wrapper">
         <div className="checkout_col__v">
             <Container>
                  <Col lg={12} className="checkout__mainBar mt-4">
                      <Row>
                          <Col className="checkout__stepper__pro" xs={12} lg={4}>
                             <Col lg={12} className="checkout__tit_ ">
                                <Card>
                                  <Card.Body>
                                    <div className="checkout__resp">
                                      <div className="mt-0 display__resp__status position-relative">
                                          <CustomLottieLoader animationData={animData} size={300} />
                                          <div className="position-absolute anim__abs_div">
                                              <CustomLottieLoader animationData={animationData} size={350} />
                                          </div>
                                      </div>
                                      <div className="__resp__msg_n">
                                        <h5 className="_heading p-3 pt-2 pb-0">
                                          Payment Successful
                                        </h5>
                                        <Button onClick={() => navigate(ordersPathUrl,{replace:true})} variant='outline-secondary'>
                                          Track Order here
                                          <VscArrowSmallRight fontSize={15} />
                                        </Button>
                                      </div>
                                   </div>
                                  </Card.Body>
                                </Card>

                             </Col>
                          </Col>
                      </Row>
                  </Col>
             </Container>
         </div>
    </div>
    </>
    
  )
}

export default withCheckoutLayout(CheckoutCallbackPage);
