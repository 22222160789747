/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ListGroup } from "react-bootstrap";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { useFetchCartItemsQuery } from "@ekenta/redux/store/services/cartApi";
import { slugifyText, mapCartDataReturnQuantity } from "@ekenta/utils";
import SingleProductItem from "../components/innercomponents/SingleProductItem";
import { Button, CustomAlert } from "@ekenta/components";
import styled from "styled-components";
import { checkoutOnePagePathUrl } from "@ekenta/routes/constant";
import { VscArrowSmallRight } from "react-icons/vsc";



interface AllOtherProductsPropsType {
  data: any;
  isLoading: boolean;
  isError?: boolean;
  isSuccess?: boolean;
}


export default function AllOtherProducts(props: AllOtherProductsPropsType) {
  const { token } = useAppSelector(state => state.auth.authentication);
  const { data: allCartData, isSuccess } = useFetchCartItemsQuery(token);

  const handleClick = () => {
    window.location.href = checkoutOnePagePathUrl;
  }
  return (
    <ListGroup className="list_p__item_uls">

      {props.isError ? (
        <div className="p-1">
          <CustomAlert
            variant="danger"
            show={true}
            message={"Something went wrong, Try again"}
          />
        </div>
      ) : null}
      {props.isLoading ? <>Loading..</> : null}
      {props.isSuccess ? props.data.content.map((item: any) => {
        const _slugColl = slugifyText(item.name.toLowerCase(), item.id);
        return (
          <SingleProductItem
            key={item.id}
            productId={item.id}
            product={item}
            cartItem={mapCartDataReturnQuantity(
              allCartData,
              item.id,
            )}
            imageUrl={item.imageUrls.length > 0 ? item.imageUrls[0] : ''}
            title={item.name}
            price={item.price}
            discount={item.discount}
            discountPercentage={item.discountPercentage}
            maxPrice={item.maxPrice}
            minPrice={item.minPrice}
            slug={_slugColl}
          />
        )
      }) : null}
      <CheckoutButton>
        <Button
          name="Checkout"
          bgVariant="primary"
          textColor="white"
          disabled={
            isSuccess &&
              allCartData.cartItems.length > 0
              ? false
              : true}
          suffixIcon={<VscArrowSmallRight />}
          onClick={handleClick}

        />
      </CheckoutButton>
    </ListGroup>
  )
}

const CheckoutButton = styled.div`
  position: absolute;
  width: 280px;
  height: 55px;
  bottom: 3%;
  opacity: 1;
  padding-top: 10px;
  background: #fff;;
  padding-bottom: 10px;
  border-top: 1px solid #eee;

  button{
    font-family: var(--eke-body-font-family);
    width: 100% !important;
    height: 40px;
    display: flex;
    justify-content: center;
  }
`;
