import React from 'react'
import { Col } from 'react-bootstrap';
import emptycart from '../../assets/images/empty_cd15791.webp';

export default function EmptyCart({imgSize,textClass}:{imgSize:string,textClass:string}) {
  return (
    <Col lg={12} className="d-flex flex-column align-items-center justify-content-center mt-4 empty__cart__div">
        <div className="mb-1">
            <img src={emptycart} width={imgSize} height="auto" alt="empty cartItems"  />
        </div>
        <div className='text-center'>
            <h5 className={textClass}>Shopping Cart is Empty</h5>
            <p></p>
        </div>
    </Col>
  )
}
