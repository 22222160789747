import React, { useContext, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { toast } from "react-toastify";
import { VscChromeClose } from 'react-icons/vsc';
import { useAppDispatch, useAppSelector } from '../../../redux/store/hook';
import { useRemoveUserCartItemMutation } from '../../../redux/store/services/cartApi';
import { removeFromCart } from '../../../redux/store/states/reducerSlice/cartSlice';

interface RemoveCartButtonPropsType {
    text:string;
    cartItemId:string;
    className:string;
}

export default function RemoveCartButton(props:RemoveCartButtonPropsType) {
  const dispatch = useAppDispatch();
  const { ek_isAuth,token } = useAppSelector(state => state.auth.authentication);

  const [removeUserCartItem,{isLoading, isFetching, isError,isSuccess}] = useRemoveUserCartItemMutation()

  const handleRemoveCartItem = (cartItemId:string) => {
    if(cartItemId){
        if(ek_isAuth && token){
          removeUserCartItem({ token, cartItemId });
        }else{
          dispatch(removeFromCart({id:cartItemId}));
        }
    }else{
       toast.error('Could not find product',{
        toastId: "qty-cart-remove-item_id-toast-error-id",
      });
    }
  }


  useEffect(() => {
    if(isSuccess){
      toast.success(`Cart item was removed from your cart list`,{
        toastId: "cart-remove-comp-item-id-toast-success",
      });
     }
  
     if(isError){
      toast.error(`Cart Error unable to remove the item to your cart list`,{
        toastId: "cart-remove-comp-item-id-toast-error",
      });
     }
}, [isSuccess,isError]);


  return (
    <>
     {isLoading || isFetching  ? (<span className="spinner_div">
         <Spinner size="sm" animation="border" variant="secondary" /></span>):
        (<span className={props.className} onClick={() => handleRemoveCartItem(props.cartItemId)}>
            <VscChromeClose fontSize={13}/>{props.text}</span>)
    }
    </>
  )
}
