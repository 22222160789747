const loginPathUrl = "/user/account/login";
const registerPathUrl = "/user/account/register";
const emailVerifyPathUrl = "/user/account/emailverify";
const forgotPassPathUrl = "/user/account/forgotpassword";

// reset password link from email -> redirect to verify and new password
const resetPassPathUrl = "/user/account/resetpassword";
// restore or new password  -> redirect to reset passord success message

// Account
const mobileAccountIndexPath = "/user/myekenta";
const accountBaseUrl = "/user/account/";
const accountProfilePathUrl = "/user/account/profile";
const accountUpdateProfilePathUrl = "/user/account/profile/edit/";
const accountUpdateAddressPathUrl = "/user/account/profile/address/edit/";
const accountChangePasswordPathUrl =
  "/user/account/profile/changepassword/edit";
const returnRequestPathUrl = "/user/account/return-request";
const newreturnRequestPathUrl = "/user/account/return-request/new";
const savedItemPathUrl = "/user/account/saved-item";
const ordersPathUrl = "/user/account/orders";
const addressesPathUrl = "/user/account/addresses";
const inboxPathUrl = "/user/account/inbox";
const WalletSystemPathUrl = "/user/account/wallet";
const pendingReviewsPathUrl = "/user/account/pending-reviews";
const ratingAndReviewsPathUrl = "/user/account/ratings-and-reviews/:id";

// Seller Shop

const sellerShopPathUrl = "/seller/shop/:id";

// Collection
const collectionPathUrl = "/all-collections";
const subBaseUrl = "/subcollection";
const subCollectionPathUrl = "/subcollection/:subslug";
const categoryBaseUrl = "/category";
const SubCollectionCartegoryPathUrl = "/category/:categoryslug";
const productBaseUrl = "/product";
const productDetailPathUrl = "/product/:productslug";
//Cart
const cartPathUrl = "/cart";

//Checkout
const checkoutOnePagePathUrl = "/checkoutonepage/delivery/";
const checkoutCallbackPagePathUrl = "/checkoutonepage/delivery/response";
const checkoutVerifyPagePathUrl = "/checkoutonepage/verify";

// Seller/Vendor Onboarding
const SellerOnboardingPathUrl = "/switch/vendor/onboarding";

//LiveStream
const allLivePathUrl = "/live";
const ekeMarketLivePathUrl = "/eke-market";
export const singLiveBaseUrl = "/live/p/streams";
export const singleUserLiveBaseUrl = "/live/p/streams/spm";
const singleLivePathUrl = "/live/p/streams/spm-:sellerId";
const DashboardLivePathUrl = "/live/p/streams/view-:sellerId";

//Save The Planet
const savePlanetPathUrl = "/save-the-planet";
const iKitchenComingSoonPathUrl = "/ikitchen-coming-soon";
const rentItPathUrl = "/rent-it-coming-soon";

// Dashboard ()
const DashboardPathUrl = "/vendor/dashboard";
const DashboardAddProductPathUrl = "/vendor/dashboard/product";
const DashboardProductsPathUrl = "/vendor/dashboard/products";
const DashboardOrdersPathUrl = "/vendor/dashboard/orders";
const DashboardLowStockPathUrl = "/vendor/dashboard/low-stock";
const DashboardCollectionPathUrl = "/vendor/dashboard/collections";
const AdminDashboardCollectionPathUrl = "/admin/dashboard/collections";
const DashboardReturnRequestPathUrl = "/vendor/dashboard/returns-requested";
const DashboardWareHousePathUrl = "/vendor/dashboard/warehouse";

const AdminDashoardEkeMarketPathUrl = "/admin/dashboard/eke-market";
const AdminDashboardSubCollectionPathUrl = "/admin/dashboard/sub-collections";
const AdminDashboardSubCollectionCategoriesPathUrl =
  "/admin/dashboard/sub-collection-categories";
const EditCollectionUrl = "/admin/collection/:id";
const EditSubCollectionUrl = "/admin/sub-collection/:id";
const EditSubCollectionCategoryUrl = "/admin/sub-collection-category/:id";
const DashboardEventsPathUrl = "/admin/events";
//
export const DashboardSellerLiveCommerceBaseUrl =
  "/vendor/dashboard/live/s/streams";
const DashboardSellerLiveCommercePathUrl =
  "/vendor/dashboard/live/s/streams/view-:sellerId";
//
const AdminUserMangement = "/admin/user-management";

const DashboardAdminIndex = "/admin/dashboard";
const DashboardSellers = "/admin/sellers";
const SingleSeller = "/admin/seller/:id";
const awaitingSellers = "/admin/sellers/awaiting-approval";
const activeSellersPathUrl = "/admin/sellers/active";

const sellerReview = "/admin/seller-review/:id";
const adminAllOrders = "/admin/all-orders";
const adminAccountingOrders = "/admin/accounting-orders";
const adminManageLogo = "/admin/manage-logo";
const adminManageBanner = "/admin/manage-banner";
const adminAddBanner = "/admin/add-banner";
const viewReturnRequest = "/vendor/dashboard/returns-requested/:id";
const DashboardOrdersPathUrlSingle = "/vendor/dashboard/order-details/:id";
const AdminOrdersPathUrlSingle = "/admin/order-details/:id";
const AdminProductsPathUrl = "/admin/all-products";
const adminAddLogo = "/admin/add-banner";
const adminReturnRequest = "/admin/all-product-return-request";
const adminPayoutRequest = "/admin/payout-request";
const adminAccountingSellers = "/admin/accounting-sellers";
const adminVideos = "/admin/manage-videos";
const adminAddVideos = "/admin/add-videos";
const adminGetOrderDetails = "/admin/get-order-details/:id";
const adminGetAllUsersUrl = "/admin/get-all-users";
const adminGetAllVerifiedUsersUrl = "/admin/get-all-verified-users";
const AdminSellerProfile = "/admin/seller-profile/:id";
export const AdminSellerProfileId = "/admin/seller-profile";

const AdminDiscountPathUrl = "/admin/discount";

const AddCollectionUrl = "/admin/collection/new";
const AddSubCollectionUrl = "/admin/sub-collection/new";
const AddSubCollectionCategoryUrl = "/admin/sub-collection-category/new";
const previewProductUrl = "/preview-product/:productslug";

const termsAndConditionsUrl = "/terms-and-conditions";
const privacyUrl = "/privacy-policy";

const homePathUrl = "/";
const searchPathUrl = "/search-result";
const iKitchenCollectionUrl = "/iKitchen/:id";

export {
  loginPathUrl,
  registerPathUrl,
  emailVerifyPathUrl,
  forgotPassPathUrl,
  resetPassPathUrl,
  mobileAccountIndexPath,
  accountBaseUrl,
  accountProfilePathUrl,
  addressesPathUrl,
  inboxPathUrl,
  WalletSystemPathUrl,
  accountUpdateProfilePathUrl,
  accountUpdateAddressPathUrl,
  accountChangePasswordPathUrl,
  returnRequestPathUrl,
  newreturnRequestPathUrl,
  savedItemPathUrl,
  ordersPathUrl,
  subBaseUrl,
  categoryBaseUrl,
  sellerShopPathUrl,
  collectionPathUrl,
  subCollectionPathUrl,
  SubCollectionCartegoryPathUrl,
  productDetailPathUrl,
  cartPathUrl,
  checkoutOnePagePathUrl,
  checkoutCallbackPagePathUrl,
  checkoutVerifyPagePathUrl,
  productBaseUrl,
  allLivePathUrl,
  ekeMarketLivePathUrl,
  singleLivePathUrl,
  DashboardLivePathUrl,
  savePlanetPathUrl,
  SellerOnboardingPathUrl,
  DashboardPathUrl,
  DashboardProductsPathUrl,
  DashboardOrdersPathUrl,
  DashboardLowStockPathUrl,
  DashboardCollectionPathUrl,
  DashboardAddProductPathUrl,
  DashboardWareHousePathUrl,
  DashboardReturnRequestPathUrl,
  DashboardSellerLiveCommercePathUrl,
  homePathUrl,
  DashboardAdminIndex,
  DashboardSellers,
  SingleSeller,
  awaitingSellers,
  activeSellersPathUrl,
  sellerReview,
  adminAllOrders,
  adminAccountingOrders,
  adminManageBanner,
  adminManageLogo,
  adminAddBanner,
  viewReturnRequest,
  DashboardOrdersPathUrlSingle,
  AdminOrdersPathUrlSingle,
  AdminProductsPathUrl,
  AdminUserMangement,
  adminAddLogo,
  adminReturnRequest,
  adminPayoutRequest,
  adminAccountingSellers,
  searchPathUrl,
  adminVideos,
  adminAddVideos,
  adminGetOrderDetails,
  termsAndConditionsUrl,
  iKitchenCollectionUrl,
  privacyUrl,
  pendingReviewsPathUrl,
  ratingAndReviewsPathUrl,
  adminGetAllUsersUrl,
  adminGetAllVerifiedUsersUrl,
  AdminDashboardCollectionPathUrl,
  AddCollectionUrl,
  AdminDashboardSubCollectionPathUrl,
  AdminDashboardSubCollectionCategoriesPathUrl,
  AdminDashoardEkeMarketPathUrl,
  AdminSellerProfile,
  AdminDiscountPathUrl,
  DashboardEventsPathUrl,
  AddSubCollectionUrl,
  AddSubCollectionCategoryUrl,
  previewProductUrl,
  EditCollectionUrl,
  EditSubCollectionUrl,
  EditSubCollectionCategoryUrl,
  iKitchenComingSoonPathUrl,
  rentItPathUrl,
};
