import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { VscHeart } from "react-icons/vsc";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import RemoveCartButton from "../../../components/Cart/CartButtons/RemoveCartButton";
import QuantityCartPageButton from "../../../components/Cart/CartButtons/QuantityCartPageButton";
import Tag from "@ekenta/components/Tag/Tag";

import styles from "./CartPageItem.module.scss";
import { calculatePercentage, calculateTotalPrice, setDiscountPercent } from "@ekenta/utils";

export default function CartItem(props: any) {


  return (
    <>
      <ListGroup className="list_cart_ul" as="ul">
        <ListGroup.Item as={"li"}>
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Text>
                {/* <span className="saved__item-">
                  <VscHeart />
                </span> */}
                {props.discount && props.discountPercentage > 0 ?
                  <Tag
                    numberOf={setDiscountPercent(props.discountPercentage)}
                    className="saved__item"
                  />
                  : (
                    <>
                      {parseInt(props.maxPrice) > parseInt(props.productPrice) ? (
                        <Tag
                          numberOf={calculatePercentage(props.maxPrice, props.minPrice)}
                          className="priceOff"
                        />
                      ) : null}
                    </>
                  )}
                <Row>
                  <Col className="_group_img_title">
                    <Col className="_card_img_col" xs={4} md={3}>
                      <Link to={props.productUrl}>
                        <Card.Img variant="top" src={props.imageUrl} />
                      </Link>
                    </Col>
                    <Col className="_title_desc_ ms-3">
                      <Row className="d_wrapper">
                        <Col xs={12} lg={9} className="p-0">
                          {" "}
                          <Link to={props.productUrl}>
                            <h5 className={"card-title"} style={{ textTransform: "capitalize" }}>{props.title.toLowerCase()}</h5>
                          </Link>
                        </Col>
                        <Col
                          lg={3}
                          className="cart_actual_price d-flex align-items-center justify-content-end"
                        >
                          <NumberFormat
                            value={`${props.cartItemPrice}`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="₦"
                            className="some"
                          />
                        </Col>
                      </Row>
                      <div className="d_wrapper_rig">
                        <Col>
                          <Col className="cart_listing_price">
                            <p className="actual_price_quantity">
                              <NumberFormat
                                value={calculateTotalPrice(props.productPrice, props.discount)}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="₦"
                                className="some"
                              />
                              <span className="ms-1">
                                x {props.quantity}{" "}
                                {props.quantity > 1 ? "Items" : "Item"}
                              </span>
                            </p>
                          </Col>
                          <div className="pr_condition_sec">
                            {props.condition === "new" ? (
                              <span className="span__new">New</span>
                            ) : (
                              <span
                                className="span__used"
                                style={{ backgroundColor: "#7C420C" }}
                              >
                                Used
                              </span>
                            )}
                          </div>
                        </Col>

                        <Col
                          xs={12}
                          lg={12}
                          className="cart_quantity_remove_col d-flex align-items-center"
                        >
                          <div className="_action_icons_col">
                            <RemoveCartButton
                              text="Remove"
                              className="cursor-pointer remove__item_"
                              cartItemId={props.cartItemId}
                            />
                          </div>
                          <div className="cart_listing_quantity">
                            <QuantityCartPageButton
                              cartItem={props.cartItem}
                              cartItemId={
                                props.cartItemId ? props.cartItemId : ""
                              }
                            />
                          </div>
                        </Col>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
}
