import React from "react"
import { Avatar, DataTable, HelmetHeader, PageTitle, Switch, Typography } from "@ekenta/components"
import { Col, Row } from "react-bootstrap"
import { VscArrowSmallLeft } from "react-icons/vsc"
import { useNavigate } from "react-router-dom"
import { DashboardSellers } from "@ekenta/routes/constant"
import { Button } from "@ekenta/components"
import { FiPlus } from "react-icons/fi"
import Card from "../UserManagement/components/Card"
import avatar from "@ekenta/assets/images/avatar.png";

import styles from "./SellerProfile.module.scss";
import classnames from "classnames"
import DashboardComponent from "../../component/DashboardComponent"

const SellerProfile = () => {
    const navigate = useNavigate();

    const columns = [
      {
        Header: "ProductName",
        accessor: "name",
        sortType: "basic",
      },
      {
        Header: "Collection",
        accessor: "collection",
        sortType: "basic",
      },
      {
        Header: "Brand",
        accessor: "brand",
        sortType: "basic",
      },
      {
        Header: "Price",
        accessor: "price",
        sortType: "basic",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableSortBy: true,
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
      }
    ];

    const data = [
      {
        name: "Pavion",
        collection: "Computer & Related Items",
        brand: "Hp",
        price: "N500",
        quantity: 4,
        date: "20/11/2022",
        status: "Published"
      },
      {
        name: "Pavion",
        collection: "Computer & Related Items",
        brand: "Hp",
        price: "N500",
        quantity: 4,
        date: "20/11/2022",
        status: "Published"
      },
      {
        name: "Pavion",
        collection: "Computer & Related Items",
        brand: "Hp",
        price: "N500",
        quantity: 4,
        date: "20/11/2022",
        status: "Published"
      }

    ]

    const listCollection = [
      {
        name: "Phone & Tablet",
        value: false
      },
      {
        name: "Computer & Related Items",
        value: true,

      },
      {
        name: "Home & Kitchen",
        value: true

      },
      {
        name: "Ekenta Fashion",
        value: false

      },
      {
        name: "Electronics & accessories",
        value: false

      },
      {
        name: "Home & Kitchen",
        value: true

      },
      {
        name: "Children World",
        value: true

      }
    ]

  return (
    <DashboardComponent full={true}>
      <div className={styles.SellerProfile_Container}>
        <HelmetHeader
          title="Seller Profile"
          description="Ekenta All Active Sellers Page"
        />
        <Col  className=" col-lg-12 dash_top mt-0 px-2">
          <div className="mt-0">
          <div className="AppEvents_col mx-1 mt-0 d-block d-lg-none">
            <PageTitle 
              title="Seller Profile"
                subTitle={
                  <span 
                    className="cursor-pointer d-flex w-auto align-items-center 
                    text-muted"
                    onClick={() => navigate(DashboardSellers)}>
                      <VscArrowSmallLeft />
                      Back to Dashboard
                  </span>
                }
              />
          </div>
              <Row className={styles.SellerProfile_Row}>
                <Col xs={12} lg={2} className={classnames(styles.SellerProfile_RowLeft, "px-0")}>
                  <div className={classnames(styles.AvatarImg_Icon," mb-4")}>
                    <div className={styles.AvatarImg}>
                      <Avatar
                          url={avatar}
                          title="Seller"
                          imageclassName={styles.Img}
                        />
                    </div>
                  </div>
                  
                  <div className={styles.CollectionConfig__Sec}>
                    <div className={classnames(styles.CollectionSec," mt-4")}>
                          <Typography 
                            id="Typography_h5"
                            as="h5" heading>
                              Collections Configure
                          </Typography>
                          <div className={styles.CollectionConfig}>
                                 <Typography 
                                  id="Typography_h6"
                                  as="h6" 
                                  uiText
                                  className={styles.Typography_h6}
                                  >
                                    Can Add Collection
                                </Typography>
                                <Switch
                                  value={false}
                                  outerClass={styles.outerClass}
                                  innerClass={styles.innerClass}
                                  activeColor={styles.activeColor}
                                  innerPos={styles.innerPos}
                                  onChange={() => console.log("noo")}
                                />
                            </div>
                    </div>

                    <div className={classnames(styles.SellerCollection,"mt-4")}>
                        <Typography 
                          id="Typography_h5"
                          as="h5" heading>
                            Seller Collections
                        </Typography>
                        <div className={styles.SellerCollection__Child}>
                          {listCollection.map(item => (
                            <div className={styles.SellerCollection__ChildItem}>
                                <Typography 
                                  id="Typography_h6"
                                  as="h6" 
                                  uiText
                                  className={styles.Typography_h6}
                                  >
                                    {item.name}
                                </Typography>
                                <Switch
                                  value={item.value}
                                  outerClass={styles.outerClass}
                                  innerClass={styles.innerClass}
                                  activeColor={styles.activeColor}
                                  innerPos={styles.innerPos}
                                  onChange={() => console.log("noo")}
                                />
                            </div>
                          ))}

                        </div>
                    </div>
                  </div>

                </Col>
                <Col xs={12} lg={10} className={styles.SellerProfile_RowRight}>
                  <div className="AppEvents_col mx-1 mx-lg-0 d-none d-lg-block">
                    <PageTitle 
                      title="Seller Profile"
                        subTitle={
                          <span 
                            className="cursor-pointer d-flex w-auto align-items-center 
                            text-muted"
                            onClick={() => navigate(DashboardSellers)}>
                              <VscArrowSmallLeft />
                              Back to Dashboard
                          </span>
                        }
                      />
                  </div>
                  <div className="CardSection mb-4">
                      <Row>
                        <Col xs={12} lg={6}>
                          <Card
                            name={"Total Product"}
                            value={12}
                            />
                        </Col>
                        <Col  xs={12} lg={6}>
                          <Card
                            name={"Published Product"}
                            value={4}
                            />
                        </Col>
                      </Row>
                  </div>
                  <div className="Data-table">
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                            name="Import"
                            onClick={() => console.log("Product")}
                          />
                          <Button
                            prefixIcon={<FiPlus />}
                            name="Add Product"
                            onClick={() => console.log("Product")}
                          />
                      </div>
                      <div className="table mt-4">
                        <DataTable
                          theme="simple"
                          data={data}
                          loading={false}
                          isSelectable={true}
                          columns={columns}
                          showPagination={false}
                          hasLink={true}
                          thClassName="w-25"
                          trClassName="w_Tr"
                          tdClassName={`Row__col`}
                        />
                      </div>
                  </div>
                  
                </Col>
              </Row>
          </div>
        </Col>
      </div>
    </DashboardComponent>
  )
}

export default SellerProfile;
