import React, { Suspense, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tab,
  Tabs,
  Stack,
  Spinner,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import TableComponent from '../../../components/Dashboard/components/TableComponent';
import DashboardComponent from '../component/DashboardComponent';
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout';
import { useAppSelector } from '../../../redux/store/hook';
import {
  useFetchAllOrdersQuery,
  useFetchAdmniAllOrdersQuery,
} from '../../../redux/store/services/vendorProductApi';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import {
  useFetchAccountingSalesAdminQuery,
  useFetchAdminSliderImagesQuery,
  useFetchAdminVideosQuery,
  useFetchDailyTotalOrderQuery,
} from '../../../redux/store/services/adminSellerApi';
import {
  FiBarChart,
  FiBarChart2,
  FiChevronLeft,
  FiGift,
  FiToggleRight,
  FiUser,
  FiUserCheck,
} from 'react-icons/fi';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { MDBDataTable } from 'mdbreact';
import { adminAddBanner, adminAddVideos } from '../../../routes/constant';
import { useNavigate } from 'react-router';
import OrderSkeletonLoader from '../../../components/SkeletonLoader/Account/OrderSkeletonLoader';
import VideoPlayerWithoutControls from '../../../components/VideoPlayer/VideoPlayerWithoutControls';

const Tdata = {
  columns: [
    {
      label: 'id',
      field: 'id',
      sort: 'asc',
    },
    {
      label: 'Video Title',
      field: 'videoTitle',
      sort: 'asc',
    },

    {
      label: 'Disabled',
      field: 'status',
      sort: 'asc',
    },
    {
      label: 'Video',
      field: 'video',
      sort: 'asc',
    },
    {
      label: 'Options',
      field: 'option',
      sort: 'asc',
    },
  ],
  rows: [
    {
      id: '61',
      title: 'Tiger Nixon',
      image: 'System Architect',
      status: 'ahah',
      option: 'nanan',
    },
  ],
};
export default function ManageVideo({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}: any) {
  const [lgShow, setLgShow] = useState(false);
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const navigate = useNavigate();

  const banners = useFetchAdminVideosQuery(token);

  const recalibrate = (data: any) => {
    const newArr = [];
    for (let i = data.length - 1; i >= 0; i--) {
      newArr.push(data[i]);
    }

    Tdata.rows = newArr;
    return Tdata;
  };

  const [_pageSize, setPageSize] = useState(7);

  const recab = (data: any) => {
    return data.map((e: any) => ({
      ...e,
      video: (
        <div className="video_wrapper_dashboard">
          <Suspense fallback={<Spinner animation="border" size="sm" />}>
            <VideoPlayerWithoutControls
              videoUrl={e.videoUrl}
              thumbnailUrl={
                'https://www.pngkit.com/png/full/267-2678423_bacteria-video-thumbnail-default.png'
              }
            />
          </Suspense>
        </div>
      ),
    }));
  };

  const handleViewOrder = (id: string) => {
    setLgShow(true);
    alert(id);
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Admin Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Orders Dashboard Page" />
        <meta name="description" content="Ekenta Orders Dashboard  Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">
            <h3 className="display_lg_header">
              {/* <FiChevronLeft /> */}
              Manage Videos
            </h3>

            <Col className="mt-5">
              <Card>
                <Card.Body>
                  <Col lg={12} clasName="text-right float-right">
                    <Button
                      onClick={() => navigate(adminAddVideos)}
                      className="text-right float-right text-white"
                    >
                      <FaPlus /> Add Videos
                    </Button>
                  </Col>

                  {banners.isSuccess ? (
                    <MDBDataTable
                      striped
                      bordered
                      hover
                      responsive={true}
                      searchLabel="Search"
                      exportToCSV
                      data={recalibrate(recab(banners.data.content))}
                    />
                  ) : (
                    <OrderSkeletonLoader />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  );
}
