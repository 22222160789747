import * as Yup from 'yup';

export const MessagingFormValidation = () => Yup.object().shape({

    message: Yup.string()
            .max(153,'message must less than 153 characters')
            .required('message is required'),
});

export const SetNicknameFormValidation = () => Yup.object().shape({

    nickname: Yup.string()
            .max(20,'nickname must less than 20 characters')

});