import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { createFormDataMutationRequest, createMutationRequestWithoutBody, createQueryRequest, createQueryWithTokenRequest } from '.';
import { BASE_URL } from '../../../constants/index';


export const liveCommerceApi:any = createApi({
    reducerPath: 'liveCommerceApi',
    baseQuery: fetchBaseQuery({ baseUrl:BASE_URL}),
    tagTypes: ['IsLive','isEkeLive'], 
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({  
        fetchAllSellerProduct: builder.query({
            query: (sellerId) => createQueryRequest({url:`/api/product/seller/${sellerId}`,method:'GET'})
        }),
         //Fetch all Seller filtered by collection for ekemarket
        fetchProductFilteredByCollection: builder.query<void,string>({
            query: (sellerId) =>  createQueryRequest({url:`/api/product/seller/${sellerId}/filtered-by-collection`,method:'GET'}),
        }),
        setSellerGoLiveStatus: builder.mutation<void, {isLive:boolean;token:string;}>({
            query: ({isLive,token}) =>  createMutationRequestWithoutBody({url:`/api/set-seller-live-status?is_live=${isLive}`,method:'PUT', token:token}),
            invalidatesTags: ['IsLive'],
        }),
        addThumbnailToSellerLive: builder.mutation<void, {merchantOwnerId:string;data:any,token:string;}>({
            query: ({merchantOwnerId,data,token}) =>  createFormDataMutationRequest({url:`/api/merchant-owner/thumbnail/${merchantOwnerId}`,method:'POST', body:data, token:token}),
            invalidatesTags: ['IsLive'],
        }),
        fetchSellerLiveCode: builder.query<void, {merchantId:string;token:string;}>({
            query: ({merchantId,token}) =>  createQueryWithTokenRequest({url:`/api/get-seller-merchant-live-link/${merchantId}`,method:'GET',token:token})
        }),
        fetchAllLiveSellers: builder.query({
            query: () => createQueryRequest({url:`/api/user/get-all-live-seller?isLive=true`,method:'GET' }),
            providesTags: ['IsLive'],
        }),
        fetchAllLiveSellersOnEke: builder.query({
            query: () => createQueryRequest({url:`/api/all/seller/live-for-eke-market-day`,method:'GET' }),
            providesTags: ['isEkeLive'],
        }),
        setSellerToEkeLive: builder.mutation<void, {isEkeLive:boolean,token:string;}>({
            query: ({isEkeLive,token}) =>  createMutationRequestWithoutBody({url:`/api/set-seller-live-ekemart-status?is_live=${isEkeLive}`,method:'PUT',token:token}),
            invalidatesTags: ['isEkeLive'],
        }),
    
    })
});
 
export const {
    useFetchAllSellerProductQuery,
    useFetchSellerLiveCodeQuery,
    useFetchAllLiveSellersQuery,
    useFetchAllLiveSellersOnEkeQuery,
    useFetchProductFilteredByCollectionQuery,
    useSetSellerGoLiveStatusMutation, 
    useAddThumbnailToSellerLiveMutation,
    useSetSellerToEkeLiveMutation
} =  liveCommerceApi;

