import React from 'react';
import { Col } from 'react-bootstrap';
import emptyOrder from '../../../../../assets/images/returning_request_icon.png';


export default function EmptyRequest() {
  return (
        <Col className="empty__request_place">
        <div className="empty__div_centered">
            <div className="image__container_order">
                <img src={emptyOrder} alt="" />
            </div>
            <p>No return requested</p>
        </div>
    </Col>
  )
}
