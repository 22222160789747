import React, { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';
import ReactJoyride from 'react-joyride';
import { useAppDispatch, useAppSelector } from '../../../redux/store/hook';
import { myjoyride } from '../../../redux/store/states/reducerSlice/authenticationSlice';

export default function DashboardTour() {
  const dispatch = useAppDispatch();
  const { ek_joyride } = useAppSelector(state => state.auth.authentication);

    const [joyride, setJoyride] = useState({
        run: false,
        steps: [
          {
            title: "Welcome to react-joyride!",
            target: "body",
            placement: "center",
            content: <div><h4>This is the sellers dashboard.</h4><p>This is where a seller adds product, tracks orders, etc.</p></div>,
            disableBeacon: true,
          },
          {
            title: "LIve Commerce",
            target: ".go-live",
            content: "This is where the seller showcase their products on a livestream.",
          },
          {
            title: "Finally ...",
            target: ".App-link",
            content: "Take action!",
          },
        ]
      });

      useEffect(() => {
        setJoyride({ ...joyride, run: ek_joyride });
        dispatch(myjoyride())
      },[]);


  return (
        <React.Fragment>
            <ReactJoyride
                steps={[
                    {
                      title: "Welcome to Ekenta Dashboard",
                      target: "body",
                      placement: "center",
                      content: <div><h4>This is the sellers dashboard.</h4><p>This is where a seller adds product, tracks orders, etc.</p></div>,
                      disableBeacon: true,
                    },
                    {
                      title: "Go Live",
                      target: ".go-live",
                      content: "This is where the seller showcase their products on a livestream.",
                    },
                    {
                      title: "Switch Back to Buyer Mode",
                      target: ".buyer-mode",
                      content: "Take action!",
                    },
                  ]}
                run={joyride.run}
                continuous
                showProgress
                showSkipButton />
        </React.Fragment>
  )
}
