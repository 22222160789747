import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import CartItem from './component/CartPageItem';

import NumberFormat from 'react-number-format';
import { Link, useNavigate } from 'react-router-dom';
import {
  checkoutOnePagePathUrl,
  collectionPathUrl,
  productBaseUrl,
} from '../../routes/constant';
import { VscArrowSmallLeft, VscArrowSmallRight } from 'react-icons/vsc';
import { useFetchCartItemsQuery } from '../../redux/store/services/cartApi';
import { useAppSelector } from '../../redux/store/hook';
import { mapCartDataReturnQuantity, slugifyText } from '../../utils';
import EmptyCart from '../../components/Box/EmptyCartBox';
import withDefaultLayout from '../../hoc/Layout/withDefaultLayout';

const mapCartReturnTotal = (cartData: any) => {
  const quantities: number[] = [];
  cartData
    ? cartData.cartItems.map((item: any) => {
      if (item) {
        quantities.push(item.quantity);
      }
    })
    : null;
  if (quantities.length > 0) {
    const total = quantities.reduce((a: number, b: number) => {
      return a + b;
    });
    return total;
  }
  return null;
};

const Cart = () => {
  const navigate = useNavigate();
  const [noOfCart, setNoOfCart] = useState<number | null>(null);

  const { token } = useAppSelector((state) => state.auth.authentication);
  const { data, isLoading, isSuccess } = useFetchCartItemsQuery(token, {
    skip: !token,
  });

  useEffect(() => {
    if (data && isSuccess) {
      const noCart = mapCartReturnTotal(data);
      setNoOfCart(noCart);
    }

  }, [data, isSuccess]);

  return (
    <>
      <Helmet>
        <title>Your Cart - Ekenta </title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Cart Page" />
        <meta name="description" content="Ekenta Cart Page" />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <div className="d_cart_wrapper">
        <div className="cart_column_v">
          <Container>
            <Col className="cart_mainBar mt-2">
              <>
                {/* <div className="mb-3">
                  <Button
                    variant="outline-secondary"
                    color="secondary"
                    style={{ textTransform: 'uppercase' }}
                    className="d-flex outline-button align-items-center border-none"
                    onClick={() => navigate(collectionPathUrl)}
                  >
                    <VscArrowSmallLeft />
                    Keep Shopping
                  </Button>
                </div> */}

                <Row>
                  <Col
                    xs={12}
                    lg={data ? (data.cartItems.length > 0 ? 8 : 12) : 12}
                  >
                    <div className="_heading">
                      <Card>
                        <Card.Body className="d-flex align-items-center justify-content-between">
                          <h5>Shopping Cart</h5>
                          {isSuccess && noOfCart ? (
                            <span>
                              {' '}
                              {`${noOfCart} ${noOfCart > 1 ? 'Items' : 'Item'}`}
                            </span>
                          ) : null}
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="h-50">
                      {isSuccess && data ? (
                        <>
                          {data.cartItems.length > 0 ? (
                            <>
                              {data.cartItems.map((item: any) => {
                                const _slugProduct = item
                                  ? slugifyText(
                                    item.product.name.toLowerCase(),
                                    item.product.id,
                                  )
                                  : '/';
                                return (
                                  <CartItem
                                    key={item.id}
                                    cartItemId={item.id}
                                    imageUrl={item.product.imageUrls[0]}
                                    cartItemPrice={item.price}
                                    cartItem={mapCartDataReturnQuantity(
                                      data,
                                      item.product.id,
                                    )}
                                    productPrice={item.product.price}
                                    discount={item.product.discount}
                                    discountPercentage={item.product.discountPercentage}
                                    maxPrice={item.product.maxPrice}
                                    minPrice={item.product.minPrice}
                                    title={item.product.name}
                                    quantity={item.quantity}
                                    condition={item.product.condition}
                                    productUrl={`${productBaseUrl}/${_slugProduct}`}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <EmptyCart
                              imgSize="230"
                              textClass="display_lg_header"
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                  </Col>

                  {isLoading ? <div>Loading...</div> : null}
                  {isSuccess && !isLoading && data ? (
                    <>
                      {data.cartItems.length > 0 ? (
                        <>
                          <Col className="cart__total_price" xs={12} lg={4}>
                            <Card>
                              <Card.Body>
                                <Card.Title>
                                  <span>Summary</span>
                                </Card.Title>

                                <>
                                  <div className="summary__shipping__price">
                                    <Col>
                                      <ListGroup>
                                        <ListGroup.Item className="_top_cart_total">
                                          <span style={{ fontSize: '13px' }}>
                                            Subtotal
                                          </span>
                                          <span>
                                            <NumberFormat
                                              value={data.totalCost}
                                              displayType="text"
                                              decimalSeparator="."
                                              decimalScale={2}
                                              fixedDecimalScale
                                              allowNegative={false}
                                              thousandSeparator={true}
                                              prefix="₦"
                                              className=""
                                            />
                                          </span>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="_shipping__charges">
                                          <span>
                                            <span style={{ fontSize: '13px' }}>
                                              Delivery Charges:
                                            </span>
                                          </span>
                                          <span className="_shipping_">
                                            Select a Delivery agency of your
                                            choice at checkout to see delivery
                                            charges
                                          </span>
                                        </ListGroup.Item>
                                      </ListGroup>
                                    </Col>
                                  </div>

                                  <Col className="px-2 summary__total__price">
                                    <ListGroup>
                                      <ListGroup.Item className="_subtotal__digit">
                                        <span className="subtotal_label">
                                          Estimated total
                                        </span>
                                        <span>
                                          <NumberFormat
                                            value={
                                              data.cartItems.length > 0
                                                ? data.totalCost
                                                : 0.0
                                            }
                                            displayType="text"
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            prefix="₦"
                                            className="subtotal_price"
                                          />
                                        </span>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Col>
                                  <Col className="">
                                    <Link to={checkoutOnePagePathUrl}>
                                      <Button className="checkout-btn">
                                        Continue to checkout
                                        <VscArrowSmallRight />
                                      </Button>
                                    </Link>
                                  </Col>
                                </>
                              </Card.Body>
                            </Card>
                            <Card className="mt-3">
                              <Card.Body></Card.Body>
                            </Card>
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Row>
              </>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
};
export default withDefaultLayout(Cart);
