import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import DefaultLayout from '../../hoc/Layout/Default-Layout';

export default function TermsAndCondition() {
    return (
        <DefaultLayout>
            <Helmet>
                <title>Terms and Condition - Ekenta.com</title>
                <meta charSet="utf-8" />
                <meta name="title" content="Ekenta Terms and Condition Page" />
                <meta name="description"
                    content="Ekenta Terms and Condition Page"
                />
                <meta property="og:locale" content="en_NG" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>
            <div className="main2 main__">
                <Container className="landingpage__container top__container pt-5">
                    <Row>
                        <Col lg={12} className="py-3 mb-3">
                            <p className={'text-bold bold-header'}>Signup &gt; <span style={{color: '#0A54B1'}}>Terms and Conditions</span></p>
                        </Col>
                        <Col lg={12} className="text-center py-3 mb-3">
                            <h3 className={'text-bold bold-header'}>Terms and Conditions</h3>
                        </Col>
                        <Col lg={12} className={'text-justify'}>
                            <div>
                                <b> Conditions of use:</b> <br />
                                Hello and welcome to Ekenta.com. When you visit or shop at Ekenta.com, use Ekenta products or services, utilize Ekenta mobile applications, or use software provided by Ekenta in connection with any of the foregoing, Ekenta.com Services LLC and/or its affiliates (&quot;Absolute Robotics&quot;) provide website features and other products and services to you (collectively, &quot;Ekenta Services&quot;). By using the Ekenta Services, you agree to the following terms on behalf of yourself, your family, and anyone else who uses any Service under your account.
                            </div> <br />
                            <div>
                                <b>Please read these conditions carefully:</b> <br />
                                We provide a wide range of Services, and extra terms may apply in some cases. When you use an Ekenta Service (for example, Ekenta applications), you are also bound by the Ekenta Service&apos;s rules, terms, and agreements (&quot;Service Terms&quot;). If these Terms of Service conflict with the Service Terms, the Service Terms will take precedence.


                            </div> <br />
                            <div>
                                <b>Privacy:</b> <br />
                                To learn more about our privacy practices, please read our Privacy Notice, which also governs your usage of Ekenta Services.
                            </div> <br />

                            <div>
                                <b>Electronic Communications:</b> <br />
                                You may be communicating with us electronically when you use Ekenta Services or send emails, text messages, or other communications to us from your desktop or mobile device. You agree to receive electronic communications from us, such as e-mails, texts, mobile push notifications, or alerts and messages on our site or through other Ekenta Services, such as our Message Center, and you may keep copies of these communications for your records. You acknowledge that any legal requirement that such communications be in writing is satisfied by all agreements, notices, disclosures, and other communications that we provide to you electronically.
                            </div> <br />

                            <div>
                                <b>Copyright:</b> <br />
                                Text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, as well as all other content included in or made available through any Ekenta Service, are the property of Absolute Robotics Limited or its content suppliers and are protected by Nigeria and international copyright laws. All content included in or made available through any Ekenta Service is Absolute Robotics&apos;s exclusive property and is protected by copyright laws in Nigeria and elsewhere.
                            </div> <br />

                            <div>
                                <b>Trademark:</b> <br />
                                To see a non-exhaustive list of Ekenta trademarks, go here. Furthermore, all images, logos, page headers, button icons, scripts, and service names used in or made available through any Ekenta Service are Ekenta trademarks or trade dress in Nigeria and other countries. Ekenta&apos;s trademarks and trade dress may not be used in connection with any non-Ekenta goods or service, in any way that is likely to cause customer confusion, or in any way that disparages or discredits Ekenta. All other trademarks appearing in an Ekenta Service that are not owned by Ekenta are the property of their respective owners, who may or may not be affiliated with, connected to, or supported by Ekenta.
                            </div>

                            <div>
                                <b>Patent:</b> <br />
                                Ekenta Services, as well as the features and services available through the Ekenta Platform, are covered by one or more patents owned by Ekenta. Parts of Ekenta&apos;s services are licensed under one or more patents. To see a non-exhaustive list of applicable Ekenta patents and licensed patents, go here.
                            </div>

                            <div>
                                <b>License and access:</b> <br />
                                Ekenta Services, as well as the features and services available through the Ekenta Platform, are covered by one or more patents owned by Ekenta. Parts of Ekenta&apos;s services are licensed under one or more patents. To see a non-exhaustive list of applicable Ekenta patents and licensed patents, go here.
                            </div>

                            <div>
                                <b>License and access:</b> <br />
                                Ekenta or its content providers grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Ekenta Services for personal and non-commercial purposes, subject to your compliance with these Conditions of Use and any Service Terms, and your payment of any applicable fees. Any resale or commercial use of any Ekenta Service or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of any Ekenta Service or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools are not permitted under this license. Ekenta or its licensors, suppliers, publishers, rightsholders, or other content providers reserve and retain all rights not expressly granted to you in these Conditions of Use or any Service Terms. Without Ekenta&apos;s express written authorization, no Ekenta Service, or any part of an Ekenta Service, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose. Without Ekenta&apos;s express written approval, you may not frame or use framing methods to enclose any trademark, logo, or other proprietary material (including images, text, page layout, or form). Without Ekenta&apos;s prior written approval, you may not use any meta tags or other &quot;hidden text&quot; containing Ekenta&apos;s name or trademarks. You are not permitted to abuse the Ekenta Services. You may only use the Ekenta Services to the extent that it is permitted by law. If you do not comply with these Conditions of Use or any Service Terms, Ekenta&apos;s licenses will be terminated.

                            </div>

                            <div>
                                <b>Your Account:</b> <br />
                                To use certain Ekenta Services, you may need your own Ekenta account, and you may need to be logged in and have a valid payment method linked with it. We may charge any other valid payment method linked with your account if there is a problem billing your selected payment method. To manage your payment options, go to Your Payments. You agree to take responsibility for any activities that occur under your account or password, including maintaining the security of your account and password and restricting access to your account. Ekenta provides children&apos;s products, but only to adults who can pay with a credit card or another permitted payment method. If you are under the age of 18, you may only use the Ekenta Services with the assistance of a parent or guardian. Parents and guardians can create Ekenta Household profiles for their teenagers. Ekenta&apos;s alcohol listings are for adults only. To purchase alcohol or use any site activity relating to alcohol, you must be at least 21 years old. In its sole discretion, Ekenta has the right to reject service, close accounts, terminate your rights to use Ekenta Services, remove or edit content, or cancel orders.

                            </div> <br />
                            <div>
                                <b>Reviews, Comments, Communications, and Other Content:</b> <br />

                                You may post reviews, comments, photos, videos, and other content; send e-cards and other communications; and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including publicity rights), or otherwise injurious to third parties or objectionable, and does not consist of or contain software viruses, political campaigning, or any other content that is illegal, You may not impersonate any person or entity, use a false e-mail address, or otherwise mislead as to the origin of a card or other content. Ekenta reserves the right to remove or amend such content (but not the obligation).
                                <br />
                                You grant Ekenta a nonexclusive, royalty-free, permanent, irrevocable, and fully sublicensable right to use, reproduce, edit, adapt, publish, perform, translate, create derivative works from, distribute, and display such content throughout the globe in any media unless we say otherwise. If Ekenta and sublicensees desire, you grant them the right to use the name you submit in connection with such content. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that the content you supply does not violate this policy and will not cause injury to any person or entity. and that you will hold Ekenta harmless from any lawsuits arising from the content you provide. Ekenta has the right to monitor and modify or remove any behavior or content, but not the duty to do so. Ekenta disclaims all responsibility and liability for any content posted by you or others.
                            </div> <br />

                            <div>
                                <b>Intellectual Property Complaints:</b> <br />
                                Ekenta values other people&apos;s intellectual property. If you believe your intellectual property rights have been violated, please see our Notice and Procedure for Making Copyright Infringement Claims.
                            </div> <br />

                            <div>
                                <b>Risk or loss:</b> <br />
                                All physical item purchases on Ekenta are conducted under the terms of a shipment contract. This means that when we deliver such things to the carrier, the risk of loss and title pass to you.
                            </div> <br />

                            <div>
                                <b> Returns, Refunds And Title:</b> <br />
                                Returns do not become the property of Ekenta until they arrive at our warehouse. A refund may be provided without the need for a return at our discretion. In this case, Ekenta does not claim ownership of the refunded goods. Please visit our Returns Center for more details on our returns and refunds.
                            </div> <br />

                            <div>
                                <b> Product Description:</b> <br />
                                Ekenta makes every effort to be as precise as possible. However, Ekenta makes no representations or warranties about the accuracy, completeness, reliability, currentness, or error-free nature of product descriptions or other Ekenta Service material. If an Ekenta product does not meet your expectations, your only option is to return it in unused condition.

                            </div> <br />
                            <div>
                                <b>Pricing:</b> <br />
                                &quot;List Price&quot; refers to a manufacturer&apos;s, supplier&apos;s, or seller&apos;s suggested retail price for a product. We compare List Prices to prices lately obtained on Ekenta and other stores on a daily basis. Certain products may have a &quot;Was Price&quot; shown, which is based on the product&apos;s recent Ekenta price history.
                                We are unable to confirm the price of an item sold by Ekenta until you place an order. Despite our best efforts, there is a chance that some of the items in our catalog are incorrectly priced. If an item&apos;s correct price is higher than our listed price, we shall, at our discretion, either contact you for instructions before shipment or cancel your purchase and notify you of the cancellation. In the event of a mispriced goods, other sellers may have different policies.
                                We usually do not charge your credit card until your order has entered the shipping process, or until we make the digital product available to you in the case of digital products.

                            </div> <br />

                            <div>
                                <b>App Permissions:</b> <br />
                                When you use Ekenta apps, such as the Ekenta App, you may give us permission to access your device. The majority of mobile devices will tell you about these permissions. Click here to learn more about these rights.
                            </div> <br />

                            <div>
                                <b>Sanctions and Export Policy:</b> <br />

                                If you are subject to Nigeria sanctions or sanctions compatible with Nigeria law imposed by the governments of the countries where you are using Ekenta Services, you may not use any Ekenta Service. You must abide by all export and re-export restrictions imposed by Nigeria or other countries on products, software (including Ekenta Software), technology, and services.

                            </div> <br />
                            <div>
                                <b>Other Bussiness:</b> <br />
                                Ekenta Services are used by parties other than Ekenta to run businesses, provide services or software, and sell product lines. In addition, we provide links to linked companies&apos; and other businesses&apos; websites. You are purchasing directly from these businesses or individuals, not from Ekenta, if you purchase any of the products or services they offer. We are not responsible for investigating or assessing the offerings of any of these businesses or individuals, and we make no representations or warranties about them (including the content of their Web sites). Ekenta accepts no responsibility or liability for the conduct, products, or content of any of these or other third parties. You should read their privacy policies and other terms of service carefully.

                            </div> <br />
                            <div>
                                <b>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY:</b> <br />
                                THE EKENTA SERVICES, AS WELL AS ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE), AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE EKENTA SERVICES, ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING BY EKENTA. UNLESS OTHERWISE EXPRESSLY STATED IN WRITING, EKENTA MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE EKENTA SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE), OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH YOU AGREE THAT USE OF THE EKENTA SERVICES IS AT YOUR OWN RISK.
                                <br />
                                EKENTA DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. EKENTA DOES NOT WARRANT THAT THE EKENTA SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE), OR OTHER SERVICES MADE AVAILABLE TO YOU THROUGH THE EKENTA SERVICES, EKENTA&apos;S SERVERS, OR ELECTRONIC COMMUNICATIONS SENT FROM EKENTA ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. EKENTA WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY EKENTA SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE), OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY EKENTA SERVICE, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.

                            </div> <br />
                            <div>
                                <b>Disputes:</b> <br />
                                You consent to the exclusive jurisdiction and venue of the state and federal High Court, Ikeja, in any dispute or claim pertaining in any way to your use of any Ekenta Service. We each agree to forego our right to a jury trial.

                            </div> <br />
                            <div>
                                <b> Site Policies, Modification, and Serverability:</b> <br />
                                Please consider our other policies on this site, such as our price policy. Your usage of Ekenta Services is likewise governed by these policies. At any moment, we have the right to modify our site, policies, Service Terms, and these Terms of Service. If any of these conditions is found to be invalid, void, or unenforceable for any reason, that condition is deemed severable and has no bearing on the legality or enforceability of the other conditions.

                            </div> <br />

                            <div>
                                <b>Our Addresses:</b> <br />
                                Dabsoluterobotics.com, Inc. <br />
                                P.O. Box <br />
                                Ikeja, LA <br />
                                https://www.ekenta.com

                            </div> <br />
                            <div>
                                <b> Additional Software Terms:</b> <br />

                                The following terms (“Software Terms”) apply to any software (including any updates or upgrades to the software) and any related documentation we make available to you in connection with Ekenta Services (the &quot;Ekenta Software&quot;). <br />
                                1. Use of Ekenta Software: You may only use Ekenta Software to enable you to utilize the Ekenta Services as offered by Ekenta, and only in accordance with these Conditions of Use and applicable Service Terms. You may not copy (except to exercise rights granted in this section), modify, create derivative works of, distribute, assign any rights to, or license the Ekenta Software in whole or in part, or otherwise copy, modify, create derivative works of, distribute, assign any rights to, or license the Ekenta Software in whole or in part. All software used in any Ekenta Service is owned by Ekenta or its software suppliers and is protected by copyright laws in Nigeria and around the world. <br />
                                2. Use of Third Party Services: You may also be using the services of one or more third parties when you use the Ekenta Software, such as a wireless carrier or a mobile software provider. These third-party services may have their own policies, terms of service, and fees that apply to your use of them.
                                No Reverse Engineering: You may not, in whole or in part, reverse engineer, decompile or disassemble, tamper with, or defeat any security associated with the Ekenta Software. <br />
                                3. Updates: We may offer automatic or manual updates to the Ekenta Software at any time and without notice to you. <br />
                                4. Conflicts: If these Conditions of Use contradict with any other Ekenta or third-party terms that apply to any element of the Ekenta Software, such as open-source license terms, the other terms will apply to that part of the Ekenta Software and to the extent of the conflict.

                            </div> <br />

                            <div>
                                <b>How to Serve a Subpoena or Other Legal Process:</b> <br />

                                Subpoenas and other legal processes are exclusively served through Amazon&apos;s national registered agent, Corporation Service Company (CSC). Subpoenas and other legal documents can be served on CSC by mailing them to the following address: <br /><br />
                                Dabsoluterobotics.com, Inc. <br />
                                P.O. Box <br />
                                Ikeja, LA <br />
                                https://www.ekenta.com <br />
                                Please keep in mind that giving precise and correct information at the onset will help us handle your request quickly. For example, for retail purchase information, the e-mail and/or credit card number used to make purchases; seller information, the name, e-mail, and physical address of a seller; and IP address and complete time stamps for Ekenta services.

                            </div> <br />

                            <div>
                                <b> Notice and Procedure for Making CLaims of Intellectual Property Infringement:</b> <br />

                                Please use our online form to file a complaint if you believe your intellectual property rights have been violated. This form can be used to report any forms of intellectual property claims, including copyright, trademark, and patent claims, but not restricted to them. <br />

                                We respond rapidly to rights holders&apos; concerns about any claimed infringement, and in appropriate instances, we terminate repeat infringers.
                                For copyright concerns exclusively, we provide the following alternative to our online form. You can file a copyright infringement claim in writing with our Copyright Agent at: <br />
                                Copyright Agent <br />
                                Ekenta.com Legal Department <br />
                                P.O. Box <br />
                                Lagos, IKJ <br />
                                phone: (814) 655-2089 <br />
                                e-mail: copyright@ekenta.com <br /> <br />

                                Courier address: <br />
                                Copyright Agent <br />
                                Ekenta.com Legal Department <br />
                                11 Bayode Oluwole <br />
                                Lagos, IKJ <br />
                                Nigeria <br />
                                The following information must be included in written accusations of copyright infringement: <br />
                                1. A physical or electronic signature of a person authorized to act on behalf of the copyright interest owner; <br />
                                2. A description of the allegedly infringed copyrighted work; <br />
                                3. A description of the location on the site of the allegedly infringing material; <br />
                                4. Your mailing address, phone number, and email address; <br />
                                5. A statement from you stating that you believe the disputed use is not authorized by the copyright owner, its agent, or the law; and <br />
                                6. Under penalty of perjury, a statement by you that the above information in your notice is correct and that you are the copyright owner or authorized to act on the copyright owner&apos;s behalf.


                            </div> <br />
                        </Col>


                    </Row>
                </Container>
            </div>
        </DefaultLayout>
    )
}
