import React, { Suspense, useEffect, useState } from "react";
import { Card, Col, Spinner } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import StarRatings from "react-star-ratings";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { FiHeart } from "react-icons/fi";
import { productBaseUrl } from "../../routes/constant";
import { slugifyText } from "../../utils";
import {
  useAddToAndRemoveFavoriteItemQuery,
} from "../../redux/store/services/productsApi";
import VideoPlayerWithoutControls from "../VideoPlayer/VideoPlayerWithoutControls";
import AddCartButton from "../Cart/CartButtons/AddCartButton/AddCartButton";
import QuantityCartButton from "../Cart/CartButtons/QuantityCartButton/QuantityCartButton";
import { useAppSelector } from "../../redux/store/hook";
import { FaHeart } from "react-icons/fa";
import LiveIconComp from "../LiveCommerce/LiveIconComp";

import { toast } from "react-toastify";
import StockEmpty from "../StockEmpty/StockEmpty";
import classnames from "classnames";
import Tag from "../Tag/Tag";

import "./ProductCardComponent.scss";
import { RefreshLink } from '@ekenta/components';

interface ProductCardComponent {
  productname: string;
}

export const mapCartDataReturnLocalQuantity = (
  cartData: any,
  productid: number,
) => {
  return cartData
    ? cartData.find((item: any) => {
      if (item.product.id === productid) {
        return item;
      }
    })
    : null;
};

export default function ProductCardComponent({
  cartItem,
  condition,
  videoUrl,
  thumbnailUrl,
  product,
  productId,
  productname,
  imageUrl,
  defaultprice,
  layoutView,
  maxPrice,
  minPrice,
  isShadow,
  discount,
  discountPercentage,
  savedItems = [],
}: any) {
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const { cartItems: _cartItems } = useAppSelector((state) => state.cart.cart);

  const [mysaved, setMySaved] = useState(savedItems);
  const allSaved = (y: any) => {
    const x = [];
    for (let i = 0; i < y.length; i++) {
      x.push(y[i].id);
    }
    setMySaved(x);
  };
  const [_cartItem, setCartItem] = useState<any>(null);
  const [localCartItem, setLocalCartItem] = useState<any>(null);

  // Add and Remove Product from favorite/ saved Item List
  const [prodId, setProdId] = useState<{ id: number | null }>({ id: null });
  const addToFavoriteObj = { token: token, productId: prodId.id };
  const {
    isLoading: toFavorite_isLoading,
    isFetching: toFavorite_isFetching,
    isSuccess: toFavorite_isSuccess,
    data: toFavorite_data,
  } = useAddToAndRemoveFavoriteItemQuery(addToFavoriteObj, {
    skip: !addToFavoriteObj.token || !addToFavoriteObj.productId,
  });

  const _slugProduct = productId
    ? slugifyText(productname.toLowerCase(), productId)
    : "/";

  const showToast = () => {
    if (!token) {
      toast.error("Sign in to save item !", {
        toastId: "signin-to-save-id-toast-error",
      });
    }
  };
  useEffect(() => {
    allSaved(savedItems);
  }, []);

  useEffect(() => {
    if (toFavorite_isSuccess) {
      toast.success("Favourite list updated!", {
        toastId: "favourite-id-toast-error",
      });
      allSaved(toFavorite_data?.products);
    }
  }, [toFavorite_isSuccess]);

  useEffect(() => {
    if (ek_isAuth) {
      if (cartItem) {
        setCartItem(cartItem);
      } else {
        setCartItem(null);
      }
    } else {
      const items = mapCartDataReturnLocalQuantity(_cartItems, productId);
      setLocalCartItem(items);
    }
  }, [ek_isAuth, cartItem, _cartItems]);

  function calculatePercentage(maxPrice: number, minPrice: number) {
    const percent = ((maxPrice - minPrice) / maxPrice) * 100;
    return `-${parseInt(percent.toFixed(2))}`;
  };

  function setDiscountPercent(discountPercentage: number) {
    return `-${discountPercentage}`;
  }

  function calculateTotalPrice(price: number, discountVal: number) {
    return discountVal && discountVal > 0 ? Math.ceil(price - discountVal) : price;
  }


  return (
    <>
      <Col
        xs={12}
        sm={6}
        md={4}
        lg={`${layoutView === "grid" ? "3" : "12"}`}
        className="product__card__col position-relative"
        style={{ height: "100%" }}
      >
        <LazyLoad offset={50}>
          <Card
            className={classnames(isShadow ? "boxShadow" : "card-noBorder")}
          >
            {discount && discountPercentage > 0 ?
              <Tag
                numberOf={setDiscountPercent(discountPercentage)}
                className="priceOff"
              /> : (
                <>
                  {parseInt(maxPrice) > parseInt(defaultprice) ? (
                    <Tag
                      numberOf={calculatePercentage(maxPrice, minPrice)}
                      className="priceOff"
                    />
                  ) : null}
                </>
              )}


            {product.user ? product.user.isSellerLive && <LiveIconComp /> : null}

            <div className="saved--item_div">
              {toFavorite_isLoading || toFavorite_isFetching ? (
                <Spinner size="sm" animation="border" variant="secondary" />
              ) : mysaved.includes(productId) ? (
                <a
                  className="add-save-item-bt"
                  style={{ color: "red" }}
                  onClick={() => setProdId({ ...prodId, id: productId })}
                >
                  <FaHeart fontSize={16} />
                </a>
              ) : (
                <a
                  className="add-save-item-btn"
                  onClick={() => {
                    setProdId({ ...prodId, id: productId });
                    showToast();
                  }}
                >
                  <FiHeart fontSize={16} />
                </a>
              )}
            </div>
            {product && product.stock >= 1 ? (
              <div className="_add_cart_button">
                {ek_isAuth && token ? (
                  <>
                    {cartItem && cartItem.quantity >= 1 ? (
                      <div className="group--components">
                        <QuantityCartButton
                          cartItems={cartItem}
                          cartItemId={cartItem.id}
                          className="quantity--picker"
                        />
                      </div>
                    ) : (
                      <>
                        <AddCartButton
                          productId={productId}
                          productname={productname}
                          product={product}
                          name="Add"
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {localCartItem && localCartItem.quantity >= 1 ? (
                      <div className="group--components">
                        <QuantityCartButton
                          cartItems={localCartItem}
                          cartItemId={
                            localCartItem ? localCartItem.product.id : ""
                          }
                          className="quantity--picker"
                        />
                      </div>
                    ) : (
                      <>
                        <AddCartButton
                          productId={productId}
                          productname={productname}
                          product={product}
                          name="Add"
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <StockEmpty className="StockEmptyWrapper_float" />
            )}

            <RefreshLink
              className={`card--inner ${layoutView === "grid" ? "" : "row"}`}
              to={`${productBaseUrl}/${_slugProduct}`}
            >
              <Col
                className={`_card_image-wrapper position-relative ${layoutView === "grid" ? "col-lg-12" : "col-md-4"
                  }`}
              >
                <RefreshLink to={`${productBaseUrl}/${_slugProduct}`}>
                  <div className="video__wrapper">
                    <Suspense
                      fallback={<Spinner animation="border" size="sm" />}
                    >
                      <VideoPlayerWithoutControls
                        videoUrl={videoUrl}
                        thumbnailUrl={thumbnailUrl}
                        title={productname}
                      />
                    </Suspense>
                  </div>
                </RefreshLink>
              </Col>
              <Col
                className={`${layoutView === "grid" ? "col-lg-12" : "col-md-8"
                  }`}
              >
                <Card.Body>
                  <Card.Title className="w-100">
                    {productname.toLowerCase()}
                  </Card.Title>
                  <div className="card-text">
                    <div className="no_item_sold_">
                      <div className="_no_p_" style={{ color: "#666" }}>
                        <span className="_text_">
                          {product.stock} item{product.stock > 1 ? "s" : ""} in
                          stock
                        </span>
                      </div>
                      <div className="_n_rating_row">
                        <div className="star-ratings">
                          <StarRatings
                            rating={
                              0
                            }
                            starDimension="18px"
                            starSpacing="3px"
                            starRatedColor={"#fc624d"}
                            numberOfStars={1}
                            name="rating"
                          />
                        </div>
                        <div className="rate-score">
                          <NumberFormat
                            value={
                              0
                            }
                            displayType="text"
                            decimalSeparator="."
                            decimalScale={1}
                            fixedDecimalScale
                            allowNegative={false}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="wrap__t__group d-flex align-items-center justify-content-between">
                      <div className="card-image-wrapper me-2">
                        {/* <img src={imageUrl} /> */}
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          <div className="_main_price_">
                            <h3>
                              <NumberFormat
                                value={calculateTotalPrice(defaultprice, discount)}
                                displayType="text"
                                thousandSeparator={true}
                                prefix="₦"
                                className="some"
                              />
                            </h3>

                            {discount && discount > 0
                              ? (
                                <span className="span__body">
                                  <NumberFormat
                                    value={`${defaultprice}`}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix="₦"
                                    className="some"
                                  />
                                </span>
                              ) : parseInt(maxPrice) > parseInt(defaultprice) ? (
                                <span className="span__body">
                                  <NumberFormat
                                    value={`${maxPrice}`}
                                    displayType="text"
                                    thousandSeparator={true}
                                    prefix="₦"
                                    className="some"
                                  />
                                </span>
                              ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="pr_condition_sec">
                        {condition == "new" ? (
                          <span className="span__new">New</span>
                        ) : (
                          <span
                            className="span__used"
                            style={{ backgroundColor: "#7C420C" }}
                          >
                            Used
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Col>
            </RefreshLink>
          </Card>
        </LazyLoad>
      </Col>
    </>
  );
}
