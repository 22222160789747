import classnames from "classnames";
import React from "react"
import styles from "./InputPrepend.module.scss";

interface InputPrependPropsType {
  label?: string;
  children: React.ReactNode;
  className?: string;
}



const InputPrepend = ({ label, children, className }:InputPrependPropsType) => {
  return (
    <div className={classnames(styles.inputPrepend__Level,className)}>
      {label? (
        <span className="add-on">{label}:</span>
      ): null}
        {children}
    </div>
  )
}

export default InputPrepend