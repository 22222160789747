import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAnalytics } from 'use-analytics';
import {
  Container,
  Col,
  Card,
  Form,
  Row,
  Button,
  Spinner,
} from 'react-bootstrap';
import { useAppDispatch } from '../../../redux/store/hook';
import { FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FaFacebookSquare } from 'react-icons/fa';
import { GoogleLogin } from 'react-google-login';
import { toast } from 'react-toastify';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import registerFormValidation from '../../../validations/schemas/account-register.schema';
import { gapi } from 'gapi-script';
// redux services
import {
  useRegisterUserMutation,
  useSigninUserWithFacebookQuery,
  useSigninUserWithGoogleQuery,
} from '../../../redux/store/services/authenticationApi';

import { GOOGLE_CLIENTID, FACEBOOK_APPID } from '../../../constants';
import {
  loginPathUrl,
  emailVerifyPathUrl,
  homePathUrl,
  DashboardSellers,
  DashboardPathUrl,
} from '../../../routes/constant';
import { setUser } from '../../../redux/store/states/reducerSlice/authenticationSlice';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { checkIfAdmin, checkIfAdminOrSellerRoleExist } from '../../../utils';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import withAuthenticationLayout from '../../../hoc/Layout/withAuthenticationLayout';

import "./Register.scss";


const Register = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { track } = useAnalytics();

  const [email, setEmail] = useState<string>();
  const [registerUser, { isLoading, isError, error, isSuccess }] =
    useRegisterUserMutation();

  const [idGoogleToken, setIdGoogleToken] = useState('');
  //const [idFacebookToken, setIdFacebookToken] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    data: google_result_data,
    isLoading: google_isLoading,
    isError: google_isError,
    error: google_error,
    isSuccess: google_isSuccess,
  } = useSigninUserWithGoogleQuery(idGoogleToken, {
    skip: idGoogleToken == '',
  });

  // const facebookResult = useSigninUserWithFacebookQuery(idFacebookToken, {
  //   skip: idFacebookToken == '',
  // });

  useEffect(() => {
    if (isError && error.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong,Try again`, {
        toastId: 'error-status-register-id-toast-error',
      });
    }

    if (isError && error.status === 401) {
      toast.error(`${error.data.title}`, {
        toastId: 'error-401-register-id-toast-error',
      });
    }

    if (isError && error.status === 400) {
      toast.error(`Something went wrong`, {
        toastId: 'error-400-register-id-toast-error',
      });
    }

    if (google_isError) {
      toast.error(`Logging with Google failed`, {
        toastId: 'google-signup-id-toast-success',
      });
    }

    // if (facebookResult?.isError) {
    //   toast.error(`Logging with Facebook failed`, {
    //     toastId: 'facebook-signup-id-toast-success',
    //   });
    // }
  }, [isError, error, google_isError]);

  //GOOGLE AUTHENTICATION SUCCESS RESPONSE

  if (google_isSuccess) {
    dispatch(
      setUser({
        user: {
          id: google_result_data.id,
          username: google_result_data.username,
          firstName: google_result_data.firstName,
          lastName: google_result_data.lastName,
          email: google_result_data.email,
          verified: google_result_data.verified,
        },
        token: google_result_data.token.id_token,
        anonymousUser: false,
        ek_isAuth: true,
      }),
    );

    track('Google Registration Success');
    if (
      checkIfAdminOrSellerRoleExist(google_result_data?.token.id_token) === true
    ) {
      if (checkIfAdmin(google_result_data?.token.id_token)) {
        navigate(DashboardSellers);
      } else {
        navigate(DashboardPathUrl);
      }
    } else {
      navigate(homePathUrl);
    }

    toast.success(`You've successfully login`, {
      toastId: 'signup-google-success-id-toast-success',
    });
  }

  //FACEBOOK AUTHENTICATION SUCCESS RESPONSE
  // if (facebookResult?.isSuccess) {
  //   dispatch(
  //     setUser({
  //       user: {
  //         id: facebookResult?.data.id,
  //         username: facebookResult?.data.username,
  //         firstName: facebookResult?.data.firstName,
  //         lastName: facebookResult?.data.lastName,
  //         email: facebookResult?.data.email,
  //         verified: facebookResult?.data.verified,
  //       },
  //       token: facebookResult?.data.token.id_token,
  //       anonymousUser: false,
  //       ek_isAuth: true,
  //     }),
  //   );
  //   track('Facebook Registration Success');
  //   if (
  //     checkIfAdminOrSellerRoleExist(facebookResult?.data.token.id_token) ===
  //     true
  //   ) {
  //     if (checkIfAdmin(facebookResult?.data.token.id_token)) {
  //       navigate(DashboardSellers);
  //     } else {
  //       navigate(DashboardPathUrl);
  //     }
  //   } else {
  //     navigate(homePathUrl);
  //   }

  //   toast.success(`You've successfully login`, {
  //     toastId: 'signup-facebook--success-id-toast-success',
  //   });
  // }

  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({
  //       clientId: GOOGLE_CLIENTID ? GOOGLE_CLIENTID : '',
  //       scope: 'email profile',
  //     });
  //   }
  //   gapi.load('client:auth2', start);
  // }, []);

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: '',
    },
    validationSchema: registerFormValidation(),
    onSubmit: (values) => {
      setEmail(values.email);
      const objData = {
        username: values.username.toLowerCase(),
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email.toLowerCase(),
        password: values.password,
      };
      registerUser(objData);
    },
  });

  const responseGoogleSuccess = (google_response: any) => {
    console.log(google_response, 'google_response');
    if (google_response) {
      const token_id = google_response.tokenId;
      setIdGoogleToken(token_id);
    }
  };

  const responseGoogleFailure = (google_response: any) => {
    if (google_response) {
      toast.error(`${google_response}`, {
        toastId: '-register-google_response-id-toast-error',
      });
    }
  };

  // const responseFacebook = (facebook_response: any) => {
  //   if (facebook_response.accessToken) {
  //     const token_id = facebook_response.accessToken;
  //     setIdFacebookToken(token_id);
  //   }
  // };

  // { regular register success response check}
  if (isSuccess) {
    track('Default Registration Success');
    setTimeout(() => {
      navigate(emailVerifyPathUrl, {
        state: {
          email: email,
        },
        replace: true,
      });
    }, 1000);
  }

  return (
    <>
      <HelmetHeader
        title="Sign Up"
        description="Ekenta Register Page"
      />
      <div className="authenticationWrapper">
        <Container className="account-register">
          <Row className="row_card p-0">
            <Col md="6" lg="5" xl="4" className="col-card  p-1">
              <Card>
                <Card.Body className="">
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <Col className="mb-4">
                      <h2 className="form-header text-center mb-0">Sign Up</h2>
                      <div className="text-center">
                        <small style={{ color: '#ddd' }}>
                          It&apos;s quick and easy.
                        </small>
                      </div>
                    </Col>

                    <Row className="mb-0">
                      <Form.Group as={Col} md="5" className="mb-2 ">
                        <div className="_group_inpu_ position-relative">
                          <FiUser className="fiuser_ text-white" />
                          <Form.Control
                            type="text"
                            name="firstname"
                            placeholder="Enter Firstname"
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            isInvalid={!!formik.errors.firstname}
                          />
                        </div>
                        {formik.touched.firstname && formik.errors.firstname ? (
                          <div className="invalid-fbk">
                            {formik.errors.firstname}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <div className="_group_inpu_ position-relative">
                          <Form.Control
                            type="text"
                            name="lastname"
                            placeholder="Enter Lastname"
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            isInvalid={!!formik.errors.lastname}
                          />
                        </div>
                        {formik.touched.lastname && formik.errors.lastname ? (
                          <div className="invalid-fbk">
                            {formik.errors.lastname}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Row>

                    {/* username form input */}
                    <Form.Group as={Col} className="mb-3">
                      <div className="_group_inpu_ position-relative">
                        <Form.Control
                          type="text"
                          name="username"
                          placeholder="Enter Username"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.username}
                          isInvalid={!!formik.errors.username}
                        />
                        <FiUser className="fuser_ text-white" />
                      </div>
                      {formik.touched.username && formik.errors.username ? (
                        <div className="invalid-fbk">
                          {formik.errors.username}
                        </div>
                      ) : null}
                    </Form.Group>

                    {/* email form input */}
                    <Form.Group as={Col} className="mb-3">
                      <div className="_group_inpu_ position-relative">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter Email"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          isInvalid={!!formik.errors.email}
                        />
                        <FiMail className="fmail_ text-white" />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="invalid-fbk">{formik.errors.email}</div>
                      ) : null}
                    </Form.Group>

                    {/* password form input */}
                    <Form.Group as={Col} className="mb-3">
                      <div className="_group_inpu_ position-relative">
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          placeholder="Enter Password"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          isInvalid={!!formik.errors.password}
                        />
                        <FiLock className="flock_ text-white" />
                        {showPassword ? (
                          <VscEye
                            onClick={handleShowPassword}
                            className="revel_p_ text-white"
                          />
                        ) : (
                          <VscEyeClosed
                            onClick={handleShowPassword}
                            className="revel_p_closed text-white"
                          />
                        )}
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="invalid-fbk">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </Form.Group>

                    {/* confirmpassword form input */}
                    <Form.Group as={Col} className="mb-3">
                      <div className="_group_inpu_ position-relative">
                        <Form.Control
                          type={showConfirmPassword ? 'text' : 'password'}
                          name="confirmpassword"
                          placeholder="Enter Confirm Password"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmpassword}
                          isInvalid={!!formik.errors.confirmpassword}
                        />
                        <FiLock className="flock_ text-white" />
                        {showConfirmPassword ? (
                          <VscEye
                            onClick={handleShowConfirmPassword}
                            className="revel_p_ text-white"
                          />
                        ) : (
                          <VscEyeClosed
                            onClick={handleShowConfirmPassword}
                            className="revel_p_closed text-white"
                          />
                        )}
                      </div>
                      {formik.touched.password &&
                        formik.errors.confirmpassword ? (
                        <div className="invalid-fbk">
                          {formik.errors.confirmpassword}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Col className="account-signup-col w-100 my-3 d-flex align-items-center justify-content-between">
                      <h6 className="col-h-summ">
                        * By signing up, you agree to our{' '}
                        <a href="/terms-and-conditions" target={'_blank'}>
                          terms and conditions
                        </a>{' '}
                      </h6>
                    </Col>

                    {/* button form submit */}
                    <Button
                      className={`account-register-btn w-100  ${isLoading || google_isLoading ? 'disabled' : ''
                        }`}
                      type="submit"
                      onClick={() => {
                        track('ButtonClicked', {
                          text: 'Register ButtonClicked',
                        });
                      }}
                    >
                      {isLoading || google_isLoading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <Spinner
                            size="sm"
                            animation="border"
                            variant="warning"
                          />
                          <span className="ms-1 text-sm">
                            <small>Please Wait...</small>
                          </span>
                        </div>
                      ) : (
                        <span>SIGN UP</span>
                      )}
                    </Button>
                  </Form>

                  <Col className="account-signup-col w-100 mt-3 d-flex align-items-center justify-content-between">
                    <h6 className="col-h-summ">Already have an account?</h6>
                    <h6 className="col-h-">
                      <Link to={loginPathUrl}>Sign in</Link>
                    </h6>
                  </Col>
                  {/* social register user buttons*/}
                  {/* <Col className="account-social css-1wf5ba mt-4">
                  <FacebookLogin
                    appId={FACEBOOK_APPID}
                    autoLoad={false}
                    textButton="Facebook "
                    fields="first_name,middle_name,name,last_name,email,gender,verified"
                    onClick={() => console.log('click')}
                    callback={responseFacebook}
                    cssClass="facebook-style"
                    icon={
                      <FaFacebookSquare
                        style={{ color: '#3b5998', fontSize: '1.3rem' }}
                      />
                    }
                    render={(renderProps: any) => (
                      <button
                        onClick={renderProps.onClick}
                        type="button"
                        className="css-logCSD"
                      >
                        <span className="css-ahhfld">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="#FFFFFF"
                            xmlns="http://www.w3.org/2000/svg"
                            color="systemGrayscale00"
                            className="css-bao86z"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16 2.667c7.364 0 13.333 5.968 13.333 13.333 0 6.656-4.875 12.172-11.25 13.172v-9.318h3.107L21.78 16h-3.698v-2.5c0-1.055.516-2.083 2.172-2.083h1.682V8.135s-1.526-.26-2.984-.26c-3.047 0-5.037 1.846-5.037 5.188V16h-3.385v3.854h3.385v9.318c-6.375-1-11.25-6.516-11.25-13.172C2.666 8.635 8.636 2.667 16 2.667z"
                              fill="#3A75EA"
                            ></path>
                          </svg>
                          Continue with Facebook
                        </span>
                      </button>
                    )}
                  />
                </Col> */}
                  <Col className="css-1wf5ba">
                    <GoogleLogin
                      clientId={GOOGLE_CLIENTID ? GOOGLE_CLIENTID : ''}
                      buttonText="Google "
                      isSignedIn={true}
                      fetchBasicProfile={false}
                      onSuccess={responseGoogleSuccess}
                      onFailure={responseGoogleFailure}
                      render={(renderProps: any) => (
                        <button
                          type="button"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className="css-logCSD my-3"
                        >
                          <span className="css-ahhfld">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="#FFFFFF"
                              xmlns="http://www.w3.org/2000/svg"
                              color="systemGrayscale00"
                              className="css-bao86z"
                            >
                              <path
                                d="M28.667 16.292c0-.861-.071-1.727-.223-2.574H16.255v4.878h6.98a5.857 5.857 0 01-2.583 3.849v3.165h4.164c2.445-2.206 3.85-5.464 3.85-9.318z"
                                fill="#4285F4"
                              ></path>
                              <path
                                d="M16.255 28.668c3.485 0 6.424-1.122 8.566-3.058l-4.164-3.165c-1.159.772-2.654 1.21-4.397 1.21-3.371 0-6.23-2.23-7.255-5.227H4.708v3.263c2.194 4.277 6.662 6.977 11.547 6.977z"
                                fill="#34A853"
                              ></path>
                              <path
                                d="M9 18.428a7.445 7.445 0 010-4.85v-3.263H4.708a12.454 12.454 0 000 11.376L9 18.428z"
                                fill="#FBBC04"
                              ></path>
                              <path
                                d="M16.255 8.347a7.1 7.1 0 014.957 1.899l3.69-3.617a12.558 12.558 0 00-8.647-3.295c-4.885 0-9.353 2.7-11.547 6.982L9 13.578c1.021-3.002 3.884-5.231 7.255-5.231z"
                                fill="#EA4335"
                              ></path>
                            </svg>
                            Continue with Google
                          </span>
                        </button>
                      )}
                    />
                  </Col>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default withAuthenticationLayout(Register);
