import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';

interface productTypes{
  imageList: any;
  images:{original:string;
           thumbnail:string;
          }[];

}

class ProductDetailSlider extends Component<productTypes> {

  _renderVideo(item: any) {
    return (
          <div className='video-wrapper lite-vid-wrapper'>
            <video className="video-component" muted controls autoPlay={true}>
              <source src={item.embedUrl} />
            </video>
          </div>
    );
  
}

  render() {

    const { images } = this.props;



    const mapMediaDataToSlider = (images: any[]) => {
      if (!images) return [];
      if (images.length == 0) return [];
      const listImgVid: any[] = [];
      images.length > 0 && images.map((item) => {

        if(item.id == 'video'){
            if(item.embedUrl){
             const videoEmbed = {
                ...item,
                renderItem: this._renderVideo.bind(this)
             }
             listImgVid.push(videoEmbed);
            }
          }else{
            listImgVid.push(item);
          }
        
      })
      
      return listImgVid;



    }

  
    const properties = {
      useBrowserFullscreen: false,
      lazyLoad:true,
      showPlayButton: false,
      showBullets: false,
      //renderItem: this.myRenderItem.bind(this),
      items: mapMediaDataToSlider(images)
    };

    return <ImageGallery 
              thumbnailPosition= "bottom"
              {...properties} 
          />;
  }
}

export default ProductDetailSlider;


// export default function ProductDetailSlider(props:any) {

//     const  myRenderItem = useCallback(
//             () => {
//                return <ReactImageMagnify {...props} />;
//             },
//             [],
//           );

    

//   return (
//     <ImageGallery 
//         showPlayButton={false}
//         thumbnailPosition="left"
//         items={props.images}
//     />
//   )
// }


