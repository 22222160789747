import React from 'react'
import { Link } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { DashboardAddProductPathUrl } from '../../../routes/constant';


export default function Timeline() {
  return (
    <VerticalTimeline
      animate={false }
      layout={'1-column'}
      lineColor={ '#fcede9' }
      >
      <VerticalTimelineElement
        className="vertical-timeline-element--work ps-0"
        contentArrowStyle={{ borderRight: '7px solid #f1f1f4' }}
        icon={'1'}
        >
        <h4 className="vertical-timeline-element-subtitle">
           <Link to={`${DashboardAddProductPathUrl}?type=add`}>Create Product</Link>
        </h4>
        
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work ps-0"
        contentArrowStyle={{ borderRight: '7px solid  #f1f1f4' }}
        icon={'2'}
        >
        <h4 className="vertical-timeline-element-subtitle">
        <Link to={'#'}>Setup Shipping</Link>
        </h4>
      </VerticalTimelineElement>

      <VerticalTimelineElement
            className="vertical-timeline-element--work ps-0"
            contentArrowStyle={{ borderRight: '7px solid  #f1f1f4' }}
            icon={'3'}
        >
        <h4 className="vertical-timeline-element-subtitle">
          <Link to={'#'}>Accept Payment</Link>
        </h4>

      </VerticalTimelineElement>
      <VerticalTimelineElement
            className="vertical-timeline-element--work ps-0"
            contentArrowStyle={{ borderRight: '7px solid  #f1f1f4' }}
            icon={'4'}
        >
        <h4 className="vertical-timeline-element-subtitle">
          Setup Completed
        </h4>
      </VerticalTimelineElement>
    </VerticalTimeline>
  )
}
