import React from "react";
import { FieldPropsType } from "@ekenta/interfaces";
//import { Field as FormikField } from "formik";
//import { useField } from 'formik';
import InputField from "../InputField";
import NumberFormat from "react-number-format";
import classnames from "classnames";

import styles from "./Field.module.scss";

const Field = React.forwardRef(({
  id,
  name,
  label,
  help,
  fieldClassName,
  placeholder,
  onChange,
  onKeyUp,
  onBlur,
  onFocus,
  required,
  value,
  errorClassName,
  isError,
  error,
  disabled,
  inline,
  autoComplete,
  className,
  type = "text",
  size = "small",
  withMargin = false,
  childOutsideLabel,
  renderDescription,
  numberFieldPrefix,
  renderPrefix,
  thousandSeparator,
  ...rest
}: FieldPropsType, ref: any) => {
  //const [field, meta] = useField(name);
  const classes = classnames("InputField__input", className);

  return (
    <InputField
      id={id}
      label={label}
      size={size}
      withMargin={withMargin}
      description={help}
      renderDescription={renderDescription}
      error={error}
      isError={isError}
      errorClassName={errorClassName}
      required={required}
      inline={inline}
      childOutsideLabel={childOutsideLabel}
    >
      {/* <FormikField name={name}>
      {({ field }:{field:any}) => ( */}
      {type === "number" ? (
        <NumberFormat
          value={value ? value : ""}
          name={name}
          allowNegative={false}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className={classnames(fieldClassName, "InputField__input")}
          thousandSeparator={thousandSeparator}
          prefix={numberFieldPrefix}
          disabled={disabled}
          onValueChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      ) : (
        <>
          {renderPrefix ? (
            <span className={styles.FieldInput__Prefix}>
              {renderPrefix}
            </span>
          ) : null}
          <input
            {...rest}
            ref={ref}
            className={classnames(fieldClassName, "InputField__input", {
              [styles.FieldInput__PrefixPadding]: renderPrefix
            })}
            name={name}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            value={value ? value : ""}
            autoComplete={autoComplete}
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
          />
        </>
      )}
      {/* )}
     </FormikField> */}
    </InputField>
  );
});

export default Field;
