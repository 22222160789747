import React from "react"
import { Accordion as BAccordion, } from "react-bootstrap";
import styles from "./Accordion.module.scss";
import { Typography } from "..";
import classnames from "classnames";

interface AccordionPropsType {
    title: string;
    titleClass?: string;
    children: React.ReactNode;
    className?: string;
    defaultActiveKey?: string;
}

const Accordion: React.FC<AccordionPropsType> = ({ title, children, titleClass, className, defaultActiveKey }) => {
    return (
        <div className={styles.AccordionCustom_Wrapper}>
            <BAccordion defaultActiveKey={defaultActiveKey} className={classnames(styles.AccordionCustom_Wrapper__Container, className)}>
                <BAccordion.Item eventKey="0">
                    <BAccordion.Header className={styles.AccordionCustom_Wrapper__Header}>
                        <Typography
                            as="h4"
                            color="dark"
                            size="small"
                            className={classnames(titleClass)}
                            heading>
                            {title}
                        </Typography>
                    </BAccordion.Header>
                    <BAccordion.Body className={classnames(styles.AccordionCustom_Wrapper__Body)}>
                        {children}
                    </BAccordion.Body>
                </BAccordion.Item>
            </BAccordion>
        </div>
    )
}

export default Accordion;