import React from 'react'
import { Link } from 'react-router-dom'

export const MenuItem = (props:{link:string; active:boolean; icon:any; text:string; onClick?:() => void}) => {
  return (
    <li className={props.active ? 'active menu-item' : 'menu-item'}>
        <Link to={props.link} onClick={props.onClick}>
            <span>{props.icon}</span>
            {props.text}
        </Link>
    </li>
  )
}
