import { useFormik } from 'formik';
import React, { Suspense, useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { FiX } from 'react-icons/fi';
import styled from 'styled-components';
const FileUploader = React.lazy(() => import('../../../../../components/FileUploader/FileUploader'));
import thumbnailimageplaceholder   from '../../../../../assets/images/thumbnail_single_placeholder.png';
import AlternateToast from '../../../../../components/Toast/AlternateToast';
import { useAppSelector } from '../../../../../redux/store/hook';
import { useAddThumbnailToSellerLiveMutation } from '../../../../../redux/store/services/livecommerceApi';

interface LiveStreamImageDialogPropsType {
    merchantId: string;
    show:boolean;
    thumbnailUrl: string | null;
    style?: string;
    bodyClass?: string;
    onCancel: () => void;
}


const LiveStreamImageDialog = (props:LiveStreamImageDialogPropsType) => {

    const [showToast, setShowToast] = useState(false);
    const [error, setError] = useState('');
    const { token } = useAppSelector(state => state.auth.authentication);
    const [addThumbnailToSellerLive, getResult] = useAddThumbnailToSellerLiveMutation();
    
    
    const formik = useFormik({
        initialValues: {
            thumbnail: [],
            imgSrc: props.thumbnailUrl,
        },
        onSubmit: (values) => {

            if(values.thumbnail.length > 0) {

                const _data = new FormData();
                _data.append('files', values.thumbnail[0])
                
                addThumbnailToSellerLive({
                   merchantOwnerId:props.merchantId,
                   data: _data,
                   token: token
                });

                setError('');

            }else{
                setError('*Thumbnail cannot be empty')
            }
        }
    });


    useEffect(() =>{
        if(formik.values.thumbnail.length > 0){
            setError('');
            _getSelectedImage(formik.values.thumbnail[0]);

        }
    },[formik.values.thumbnail]);


   useEffect(() =>{
     if(getResult?.isError){
        toast.error('Something went wrong')
        setShowToast(true);
     }

    if(getResult?.isSuccess){
        toast.success('Stream image added successfully');
        setShowToast(true);
        const thumbnailUrl = getResult?.data.thumbnailUrl;
        formik.setFieldValue('imgSrc',thumbnailUrl);
        props.onCancel();
    }


   },[getResult?.isSuccess,getResult?.isError]);

   
    // const _getResizeImg = async (file:File) => {
    //     try {
    //        const image = await resizeImageFile(
    //             file, 303, 300
    //         );
    //       return image;

    //     } catch (err) {
    //       console.log(err);
    //     }
    // };

    
    const _getSelectedImage = (value:any) => {
        // Assuming only image
        const file = value
        const reader = new FileReader();
        const url = reader.readAsDataURL(file);
         reader.onloadend = function (e:any) {
            formik.setFieldValue('imgSrc',[reader.result]);
      }
    }
      const clearImgSrc = () =>{
        formik.setFieldValue('imgSrc','');
        formik.setFieldValue('thumbnail',[]);
      }

   
  return (
    <>
    <Modal 
        dialogClassName="prompt__dialog close__dialog"
        show={props.show} 
        centered
        onHide={props.onCancel}
    >
    <Modal.Header closeButton>
    <Modal.Title>Add Stream Image</Modal.Title>
    </Modal.Header>
        <Modal.Body className={props.bodyClass}>
            <div className="mb-1 context_spa">
                <h5 className="mb-title" style={{fontSize:'13px'}}>Add a thumbnail image for your livestream</h5>
                <div>
               
                {formik.values.imgSrc ?

                   (<ImageContainer>
                    <img src={formik.values.imgSrc} width="100%" height="250px" />
                    <span onClick={clearImgSrc}><FiX /></span>
                    </ImageContainer>)
                 
                 : (
                    <Suspense fallback={ <Spinner animation="border" variant="dark" />}>
                    <FileUploader
                        maxSize={4000000}
                        placeholder={thumbnailimageplaceholder}
                        requiredText="(Only a single image can be selected)"
                        acceptFileType="image/*"
                        acceptFileTypeList={["image"]}
                        fileType="image"
                        maxFiles={1}
                        multiple={false}
                        setFieldValue={formik.setFieldValue}
                        name={'thumbnail'}
                    /> 
                 </Suspense>
                 )
                }
                {error && <span style={{fontSize:'12px', color:'red', textAlign:'center'}}>{error}</span>}
                </div>
            </div>
        </Modal.Body>
            <Modal.Footer className="mt-0 mb-closeDialog-footer">
                <Button onClick={props.onCancel} className="cancel_btn" variant="">Cancel</Button>
                <Button type="button" onClick={() => formik.handleSubmit()} className={`confirm_btn_close d-flex align-items-center justify-content-center ${getResult?.isLoading ? 'disabled': ''}`} 
                    style={{backgroundColor:" rgb(255, 0, 0) !important"}}>
                    {getResult?.isLoading && <Spinner size="sm" animation="border" variant="light" />}
                      <span className="ms-1">Save</span>
                </Button>
            </Modal.Footer>

    </Modal>
      {showToast && <AlternateToast show={showToast} />} 
    </>

  )
}

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 250px;

    img{
        width: 100%;
        height: 250px;
    }

    span{
        position: absolute;
        top: -2%;
        right: -1%;
        width:20px;
        height:20px;
        border-radius: 50%;
        background-color: #000;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;

        svg{
            color: #fff;
            font-size:13px;
        }

    }

`;

export default LiveStreamImageDialog;