import React from 'react'

const SvgClose = ({
    width = "1rem",
    height = "1rem",
    className
}: {
    className?: string,
    width?: string;
    height?: string
}) => (
    <svg xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="close-chat-widget"
        x="0px" y="0px"
        width={width}
        height={height}
        className={className}
        viewBox="0 0 47.971 47.971"
        xmlSpace="preserve"><g><g>
            <path d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
                data-original="#000000" className="active-path" data-old_color="#000000"
            />
        </g></g> </svg>

)

export default SvgClose;