import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
//import toast from "react-hot-toast";
import { FiPlus } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "@ekenta/redux/store/hook";
import { useAddCartItemMutation } from "@ekenta/redux/store/services/cartApi";
import { addToCart } from "@ekenta/redux/store/states/reducerSlice/cartSlice";
import { toast } from "react-toastify";
import classnames from "classnames";

import styles from "./AddCartButton.module.scss";
import { Button } from '@ekenta/components';


interface AddCartButtonPropsType {
  productId: string;
  productname: string;
  product: any;
  className?: string;
  name: string;
}

export default function AddCartButton(props: AddCartButtonPropsType) {
  const dispatch = useAppDispatch();

  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const { cart } = useAppSelector((state) => state.cart);
  const [addCartItem, { isLoading, isFetching, isError, error, isSuccess }] =
    useAddCartItemMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success(`${props.productname} was added to your cart list`, {
        toastId: "cart-added-list--id-toast-success",
      });
    }

    if (isError && error.status === "FETCH_ERROR") {
      toast.error("Cart Error, unable to add the item to your cart list", {
        toastId: "cart-not-added-id-toast-error",
      });
    }

    if (isError && error.status === 500) {
      toast.error("Cart Error, unable to add the item to your cart list", {
        toastId: "cart-not-added-id-toast-error",
      });
    }

    if (isError && error.status === 400) {
      toast.error(`${error.data.title}`, {
        toastId: "cart-not-added-id-toast-error",
      });
    }
  }, [isSuccess, isError, error]);

  const handleAddToCart = () => {
    if (ek_isAuth && token) {
      if (props.productId) {
        addCartItem({
          productId: props.productId,
          token: token,
        });
      } else {
        toast.error("Could not find product", {
          toastId: "-add-cart-list-id-toast-error-id",
        });
      }
    } else {
      dispatch(addToCart(props.product));
    }
  };
  return (
    <>
      <Button
        name="addCart__name"
        type="button"
        defaultClass={false}
        className={classnames(styles.AddCart_Button, props.className, `${isLoading || isFetching ? 'disabled' : ''
          }`)}
        onClick={handleAddToCart}
      >
        {isLoading || isFetching ? (
          <Spinner size="sm" animation="border" variant="white" />
        ) : (
          <>
            <FiPlus fontSize={18} />
            <span className="mr2">{props.name}</span>
          </>
        )}
      </Button>
    </>
  );
}