import React, { Fragment, useContext, useState } from 'react';
import * as queryString from "query-string";
import { Row } from 'react-bootstrap';
import { HelmetHeader, PageTitle } from '@ekenta/components';
import { withDefaultLayout } from '@ekenta/hoc';

import styles from "./SearchResult.module.scss";
import { SearchFilterProvider } from '@ekenta/context/SearchFilterContext';
import SideBar from '../common/SideBar/SideBar';
import MainContent from '../common/MainContent/MainContent';
import { useFetchSearchProductsRQuery } from '@ekenta/redux/store/services/productsApi';
import { useGetSingleMerchantQuery } from '@ekenta/redux/store/services/shopApi';


const SearchResult = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const myParam = urlParams.get("q")
    const category = urlParams.get("category");
    const sellerId = urlParams.get("id");

    const queryParam = queryString.parse(window.location.search);
    const _page = queryParam.page;
    const [pageno, setPageno] = useState(0);

    const keyword = category === "products" ? myParam : null;
    const getResult = useFetchSearchProductsRQuery({ keyword, pageno }, { skip: !keyword });

    const getMerchant = useGetSingleMerchantQuery(sellerId, { skip: !sellerId });


    const resultData = () => {
        if (category === "sellers" && getMerchant?.isSuccess) {
            return getMerchant?.data.productsOfMerchantBusinessWithActiveCollection;
        } else if (getResult?.data) {
            return getResult?.data.productPage.content
        } else {
            return { data: [] };
        }
    }

    return (
        <Fragment>
            <HelmetHeader
                title={"Search Results"}
                description="Ekenta Search Results"
            />

            <SearchFilterProvider>
                <div className={styles.SearchContainer}>
                    <section className={styles.SearchMain_Bar}>
                        <Row className={styles.Row}>
                            <SideBar
                                collection={""}
                                style={{ background: "#fff" }}
                            />
                            <MainContent
                                data={resultData()}
                                isLoading={
                                    getResult?.isLoading ||
                                    getMerchant?.isLoading
                                }
                                queryNo={_page}
                                isSuccess={
                                    getResult?.isSuccess ||
                                    getMerchant?.isSuccess
                                }
                                pageno={pageno}
                                setPageno={setPageno}

                                renderTop={
                                    <div
                                        className=""
                                        style={{
                                            marginBottom: "15px",
                                            marginTop: "8px",
                                            borderBottom: "1px solid#e8eaef",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",

                                        }}
                                    >
                                        <PageTitle
                                            title={`Result for: ${myParam}`}
                                            margin={false}
                                        />
                                    </div>
                                }
                            />
                        </Row>
                    </section>
                </div>
            </SearchFilterProvider>


        </Fragment>
    )
}

export default withDefaultLayout(SearchResult);