import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import AppContent from '../../components/AppContent';
import ErrorBoundaries from '../../components/ErrorBoundary/ErrorBoundaries';
import DashboardComponent from '../../pages/Dashboard/component/DashboardComponent';
// import NavigationBar from '../../components/NavigationBar/NavigationBar';
// import DashboardNavigationBar from '../../components/NavigationBar/Dashboard_NavigationBar';

export default function withDashboardLayout(
  WrappedComponent: () => JSX.Element,
  className?: string,
) {
  const DashboardLayout = (props: any) => {
    return (
      <>
        <DashboardComponent full={false} className={className}>
          <WrappedComponent {...props} />
        </DashboardComponent>
      </>
    );
  };

  DashboardLayout.displayName = 'DashboardLayout';
  return DashboardLayout;
}
