import React, { useState } from 'react'
import { VscChevronDown, VscChevronRight } from 'react-icons/vsc';

export const SubMenu = (props:{className?: string; active:boolean; icon:any; text:string; children:React.ReactNode}) => {
   const [visible, setVisible] = useState(false);

  const handleDropdown = () => setVisible((prevState) => !prevState);
  return (
         <li className="sub-menu ">
            <a className="cursor-pointer sub-menu-href" onClick={handleDropdown}>
                <div className="left-icon-text">
                    <span>{props.icon}</span>
                    {props.text}
                </div>
                <div className="icon-arrow">
                   {visible ? <VscChevronDown /> : <VscChevronRight/>}
                </div>
            </a>
            <ul className={`sub-menu-ul  w-100 ${visible ? 'visible-dropdown' : 'not-visible-dropdown'}`} style={{paddingLeft: '18px'}}>
                {props.children}
            </ul>
      </li>
  )
}
