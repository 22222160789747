import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Container,
  Col,
  Card,
  Row,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap';
import { FiLock } from 'react-icons/fi';
import PinInput from 'react-pin-input';
import { toast } from 'react-toastify';
import newPasswordFormValidation from '../../../validations/schemas/account-newpassword.schema';
import { useResetUserPasswordWithEmailMutation, useRestoreUserPasswordMutation } from '../../../redux/store/services/authenticationApi';
import { loginPathUrl } from '../../../routes/constant';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import withAuthenticationLayout from '../../../hoc/Layout/withAuthenticationLayout';

const PIN_LENGTH = 6;

const PasswordReset = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    emailCode: '',
    password: '',
    confirmPassword: '',
  });
  const emailCodeRef = useRef(null);
  const [resetUserPasswordWithEmail, { isLoading: reset_isLoading, isError: reset_isError, error: reset_error, isSuccess: reset_isSuccess }] =
    useResetUserPasswordWithEmailMutation();

  const resendEmail = () => {
    const email = localStorage.getItem('resetEmail');
    resetUserPasswordWithEmail(email);
    toast.success(`Code Sent Successfully`, {
      toastId: 'state-un-id-toast-success',
    });
  }

  const [
    restoreUserPassword,
    { isSuccess, isError, error: errorPass, isLoading },
  ] = useRestoreUserPasswordMutation();

  useEffect(() => {
    if (isError && errorPass.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong,Try again`, {
        toastId: 'error-status-fp-id-toast-error',
      });
    }
    if (isError && errorPass.status === 400) {
      toast.error(`${errorPass.data.title}`, {
        toastId: 'error-status-passR-400-id-toast-error',
      });
    }

    if (isError && errorPass.status === 401) {
      toast.error(`${errorPass.data.title}`, {
        toastId: 'error-status-passR-401--id-toast-error',
      });
    }

    if (isSuccess) {
      navigate(loginPathUrl, {
        state: {
          status: 'success',
          statusMsg: 'Password reset successfully',
        },
        replace: true,
      });
    }
  }, [isError, errorPass, isSuccess, reset_isSuccess]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: newPasswordFormValidation(),
    onSubmit: (values) => {
      const data = {
        key: values.emailCode,
        newPassword: values.password,
      };

      restoreUserPassword(data);
    },
  });

  const handleChange = (value: string) => {
    formik.setFieldValue('emailCode', value);
  };

  return (
    <>
      <HelmetHeader
        title="Password Reset"
        description="Ekenta Password Reset Page"
      />

      <Container fluid className="account-forgotpass">
        <Row className="row_card">
          <Col md="8" lg="5" xl="4" className="col--card  p-1">
            <Card>
              <Card.Body className="p-4">
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Col className="mb-4">
                    <h2 className="form-header text-center">Password Reset</h2>
                  </Col>
                  <Col className="text-center">
                    <p className="para__text">
                      We sent you a 6 (six) digits reset code (key) to your
                      email address
                    </p>
                  </Col>
                  <Form.Group className="form-reset-gp pin-pass-reset mb-3 position-relative">
                    <PinInput
                      ref={emailCodeRef}
                      length={PIN_LENGTH}
                      focus
                      onChange={handleChange}
                      type="custom"
                      inputMode="number"
                      inputStyle={{
                        borderColor: 'white',
                        padding: '0 !important',
                      }}
                      onComplete={(value) => null}
                      autoSelect={true}
                    />
                    {formik.errors.emailCode ? (
                      <div className="invalid-fbk m-0 p-0 text-center">
                        {formik.errors.emailCode}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="form-reset-gp mb-3 position-relative">
                    <div className="_group_inpu_ position-relative">
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="New password"
                        className="position-relative"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <FiLock className="flock_" style={{ color: '#7C420C' }} />
                    </div>
                    {formik.errors.password ? (
                      <div className="invalid-fbk">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="form-reset-gp mb-4 position-relative">
                    <div className="_group_inpu_ position-relative">
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm new password"
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />
                      <FiLock className="flock_" style={{ color: '#7C420C' }} />
                    </div>
                    {formik.errors.confirmPassword ? (
                      <div className="invalid-fbk">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Button
                    className={`account-forgot-btn ${isLoading ? 'disabled' : ''
                      } w-100 `}
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        {' '}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                        />
                        <span className="ms-1">Please wait...</span>
                      </>
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <p>Didn&apos;t receive code?
                    {reset_isLoading ?
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                        />
                        <span className="ms-1">Please wait...</span>
                      </>
                      :
                      <span style={{ color: "#E04826", cursor: "pointer" }} onClick={resendEmail} > Resend Code</span>
                    }
                  </p>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withAuthenticationLayout(PasswordReset);