import React from "react"
import { Card, Col, Form, ListGroup, Spinner } from "react-bootstrap"
import { Button, Loader } from "@ekenta/components"
import Header from "../Header/Header";

import flutterwave from "@ekenta/assets/images/Flutterwave_002316.png";
import paystack from "@ekenta/assets/images/Paystack_100234.png";
import cash_on_delivery from "@ekenta/assets/images/cash_on_delivery.png";

import "./PaymentOption.scss";
import PaymentOptionItem from "./PaymentOptionItem";
import { VscArrowSmallRight } from "react-icons/vsc";

interface PaymentOptionPropType {
  isLoading: boolean;
  values: any;
  errors: any;
  handleBlur: any;
  isSuccess: boolean;
  orderLoading: boolean;
  handleSubmit: () => void;
  setFieldValue: any;
  paymentOptionEnums: {
    PAYSTACK: string;
    FLUTTERWAVE: string;
    POD: string;
  }
}

const PaymentOption: React.FC<PaymentOptionPropType> = ({
  isLoading,
  values,
  errors,
  handleBlur,
  isSuccess,
  orderLoading,
  handleSubmit,
  setFieldValue,
  paymentOptionEnums
}) => {


  return (
    <Col className="checkout__payment__option">
      <Form noValidate onSubmit={handleSubmit}>
        <Card>
          <Card.Body>
            {isLoading ? <Loader isCenter /> : (
              <>
                <Header
                  title="Payment Option"
                  active={values.deliveryChannel && values.deliveryCharge !== null}
                  isBorder={values.deliveryChannel && values.deliveryCharge !== null}
                />
                {isSuccess ? (
                  <Col className="Payment_option__Col pt-0">
                    <ListGroup>
                      <PaymentOptionItem
                        id="paymentOption"
                        onlyLabel={true}
                        label={`
                                 ${values.paymentChannel === paymentOptionEnums.PAYSTACK ? "Pay With Paystack"
                            : values.paymentChannel === paymentOptionEnums.FLUTTERWAVE ? "Pay With Flutterwave"
                              : values.paymentChannel === paymentOptionEnums.POD ? "Pay On Delivery"
                                : null
                          } 
                                `}
                        renderImage={
                          values.paymentChannel === paymentOptionEnums.PAYSTACK
                            ? paystack
                            : values.paymentChannel === paymentOptionEnums.FLUTTERWAVE ? flutterwave
                              : values.paymentChannel === paymentOptionEnums.POD ? cash_on_delivery
                                : null}
                      />
                    </ListGroup>
                  </Col>
                ) : (
                  <>
                    {values.deliveryChannel && values.deliveryCharge !== null ? (
                      <>
                        <Col className="Payment_option__Col pt-0">
                          <ListGroup>
                            {/* <PaymentOptionItem
                              id="paystack"
                              label="Pay With Paystack"
                              paymentChannel={paymentOptionEnums.PAYSTACK}
                              values={values}
                              errors={errors}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={paystack}
                            />
                            <PaymentOptionItem
                              id="flutterwave"
                              label="Pay with Flutterwave"
                              paymentChannel={paymentOptionEnums.FLUTTERWAVE}
                              values={values}
                              errors={errors}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={flutterwave}
                            /> */}
                            <PaymentOptionItem
                              id="flutterwave"
                              label="Pay with Flutterwave"
                              paymentChannel={paymentOptionEnums.FLUTTERWAVE}
                              values={values}
                              errors={errors}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={flutterwave}
                            />
                            <PaymentOptionItem
                              id="POD"
                              // disabled={values.logistics === "EDS" ? false : true}
                              label="Pay on Delivery"
                              paymentChannel={paymentOptionEnums.POD}
                              values={values}
                              errors={errors}
                              imageClass="w-auto"
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              renderImage={cash_on_delivery}
                            />
                          </ListGroup >
                        </Col>

                        {values.paymentChannel ? (
                          <>
                            {isSuccess ? null : (
                              <div className="mt-3 w-100 ButtonOp_Wrapper">
                                <Button
                                  className="d-flex align-items-center ButtonOp_ justify-content-center"
                                  width="w-100"
                                  bgVariant="primary"
                                  name="paymentOption"
                                  disabled={orderLoading}
                                  type="submit">
                                  {orderLoading ? (
                                    <Spinner size="sm" animation="border" variant="white" />
                                  ) : (
                                    <>
                                      Proceed
                                      {" "}
                                      <VscArrowSmallRight fontSize={15} />
                                    </>
                                  )}
                                </Button>
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Form>
    </Col>
  )
}

export default PaymentOption;