/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Typography } from '@ekenta/components';
import React, { memo, useCallback, useState } from 'react'

import styles from "./FollowButton.module.scss"
import { useAppSelector } from '@ekenta/redux/store/hook';
import { toast } from "react-toastify";
import { FiPlus } from 'react-icons/fi';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';

const FollowButton = (props: { sellerId: string | null, count: number, suffixCount?: boolean, inline?: boolean, callback?: (value: number) => void }) => {
    const { sellerId, count = 0, inline, suffixCount } = props;
    const { token } = useAppSelector(
        (state) => state.auth.authentication,
    );
    const [result, setResult] = useState<number | null>(null);
    const [isLoading, setLoading] = useState(false);


    const handleSubmit = (id: string | null) => {

        if (id) {
            toggleFunction(id);
        } else if (!id && !token) {
            toast.error(`Sign in to follow seller`, {
                toastId: "error-status-follow-id",
            });
        }
    }

    const toggleFunction = useCallback((id: string) => {
        const config = {
            method: 'PUT',
            url: `${BASE_URL}/api/follow-or-unfollow-seller/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: ""
        };
        setLoading(true);
        axios(config)
            .then(function (response: any) {
                setLoading(false);
                toast.success(`${response?.data.message}`, {
                    toastId: "status-follow-success-id-toast",
                });
                setResult(response?.data.totalFollowers);
                if (props.callback) {
                    props.callback(response?.data.totalFollowers);
                }
            })
            .catch(function () {
                setLoading(false);
                toast.error(`Something went wrong`, {
                    toastId: "-toggle-follow-error-400-id-toast-error",
                });
            });
    }, []);



    const _count = result ? result : count;

    return (
        <div className={styles.FollowingButton_Wrapper}>
            <Button
                name="Follow"
                bgVariant="primary"
                textVariant="light"
                className={styles.Button}
                prefixIcon={<FiPlus />}
                suffixIcon={
                    suffixCount ?
                        <span>{_count}</span>
                        : <></>}
                disabled={isLoading}
                isLoading={isLoading}
                isLoadingText="Following.."
                shadow={false}
                type="button"
                onClick={() => handleSubmit(sellerId)}
            />
            {!inline ? (
                <div className={styles.FlexTypography}>
                    <Typography
                        as="span"
                        size="small"
                        color="dark"
                        uiText>
                        {_count}
                    </Typography>
                    <Typography
                        as="span"
                        size="smallest"
                        className={styles.FollowerTypography}
                        color="light"
                        uiText>
                        Follower{_count > 1 ? "s" : ""}
                    </Typography>
                </div>
            ) : null}

        </div>
    )
}

export default memo(FollowButton);