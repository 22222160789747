import React, { useState } from "react";
import { Badge, Button, Card, Col, Row, Stack } from "react-bootstrap";
import Image from "react-bootstrap/Image"
import { Helmet } from "react-helmet"
import { BiExport } from "react-icons/bi"
import { BsPiggyBank, BsEye } from "react-icons/bs"
import { AiOutlinePlus } from "react-icons/ai"
import { Link } from "react-router-dom"
import { useFetchPendingSellersQuery, useFetchAdminDashboardQuery, useFetchActiveSellersQuery }
    from "@ekenta/redux/store/services/adminSellerApi"
import { useAppSelector } from "@ekenta/redux/store/hook"
import { activeSellersPathUrl, awaitingSellers } from "@ekenta/routes/constant";
import { useLocation, useNavigate } from "react-router";
import CountUp from "react-countup"
import { withDashboardLayout } from "@ekenta/hoc"
import { DataTable, HelmetHeader, Pagination } from "@ekenta/components"

const Sellers = () => {
    const { pathname } = useLocation();
    const [lgShow, setLgShow] = useState(false);
    const [pageno, setPageno] = useState(0);

    const { token } = useAppSelector(state => state.auth.authentication);
    const pendingSellers = useFetchPendingSellersQuery({ pageno, token });
    const dashboardResult = useFetchAdminDashboardQuery(token);
    const activeSellers = useFetchActiveSellersQuery(token);

    const recalibrate = (data: any) => {
        return data.map((items: any, i: any) => ({
            ...items,
            id: items.id,
            name: items.merchantOwner.firstName + " " + items.merchantOwner.lastName,
            business_name: items.businessName,
            logo: <Image thumbnail={true} />,
            status: <Badge pill bg={items.status == "active" ? "dark" : "light"} text={items.status == "active" ? "light" : "dark"}>{items.status}</Badge>,
            // service_status: <Form.Switch size={24} className="lg" checked={items.service_status == "active" ? true : false}></Form.Switch>,
            address: items.address.homeAddress,
            bank: <BsPiggyBank size={20} color={"#E04826"} />,
            view: <Link to={`/admin/seller-review/${items.merchantOwner.id}?type=awaiting`}><BsEye size={20} color={"#E04826"} /></Link>
        }))

    }


    const columns = [
        {
            Header: "Logo",
            accessor: "logo",
            sortType: "basic",
        },
        {
            Header: "Name",
            accessor: "name",
            sortType: "basic",
        },
        {
            Header: "Address",
            accessor: "address",
            sortType: "basic",
        },
        {
            Header: "Business Name",
            accessor: "business_name",
            sortType: "basic",
        },
        {
            Header: "Products",
            accessor: "products",
            sortType: "basic",
        },
        {
            Header: "Status",
            accessor: "status",
            disableSortBy: true,
        },
        // {
        //     Header: "Service Status",
        //     accessor: "service_status",
        //     sortType: "basic",
        // },
        {
            Header: "Bank",
            accessor: "bank",
            disableSortBy: true,
        },
        {
            Header: "View",
            accessor: "view",
            disableSortBy: true,
        },


    ]

    const navigate = useNavigate()
    return (
        <>
            <HelmetHeader
                title="Seller Dashboard"
                description="Ekenta Seller Dashboard Page"
            />

            <Col className="col-lg-12 mx-auto _listing_product_ mt-2 px-2">
                <div className="mt-0">
                    <Row className="mt-4">
                        <Col className="text-center">
                            <Card className="boxShadow" style={{ border: "none !important" }}>
                                <Card.Body>
                                    <h3>
                                        {dashboardResult.isSuccess ?
                                            <CountUp
                                                start={0}
                                                end={dashboardResult.data.totalActiveSellers} /> : "---"}
                                        <br /> Total Sellers</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center" role={"button"} onClick={() => navigate(activeSellersPathUrl)}>
                            <Card className="boxShadow" style={{ border: "none !important" }}>
                                <Card.Body>
                                    <h3>{dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult.data.totalActiveSellers} />

                                        : "---"} <br /> Active Sellers</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center">
                            <Card className="boxShadow" style={{ border: "none !important" }}>
                                <Card.Body>
                                    <h3>{dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult.data.totalproducts} />
                                        : "---"} <br /> Total Products</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col className="text-center">
                            <Card className="boxShadow" style={{ border: "none !important" }}>
                                <Card.Body>
                                    <h3>{dashboardResult.isSuccess ?
                                        <CountUp
                                            start={0}
                                            end={dashboardResult.data.totalPendingOrders} />
                                        : "---"} <br /> Active Orders</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center" role={"button"} onClick={() => navigate(awaitingSellers)}>
                            <Card className="boxShadow" style={{ border: "none !important" }}>
                                <Card.Body>
                                    <h3>{pendingSellers.isFetching ? "---"
                                        :
                                        <CountUp
                                            start={0}
                                            end={pendingSellers?.data?.content?.length} />
                                    } <br /> Awaiting Approval</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center">
                            <Card className="boxShadow" style={{ border: "none !important" }}>
                                <Card.Body>
                                    <h3>{activeSellers.isFetching ? "---" :
                                        <CountUp
                                            start={0}
                                            end={activeSellers?.data?.blockedSeller} />
                                    } <br /> Blocked</h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Col className="mt-5 tab__">
                        {/* <Card>
                            <Card.Body>
                                {
                                    !pendingSellers.isSuccess? "Loading" : <TableComponent columns={columns} data={recalibrate(pendingSellers.data.content)} pageSizeno={_pageSize} />
                                }
                                
                            </Card.Body>
                        </Card> */}
                        <DataTable
                            theme="simple"
                            data={pendingSellers?.data ? recalibrate(pendingSellers?.data.content) : []}
                            loading={pendingSellers?.isLoading}
                            isSelectable={true}
                            columns={columns}
                            showPagination={false}
                            hasLink={true}
                            thClassName="W"
                            trClassName="w_Tr"
                            tdClassName={`Row__col`}
                            renderPagnation={
                                <>
                                    {pendingSellers?.data
                                        ? pendingSellers?.data.totalElements > 20 && (
                                            <Pagination
                                                pathname={pathname}
                                                itemPerPage={pendingSellers?.data.pageable.pageSize}
                                                itemsLength={pendingSellers?.data.totalElements}
                                                currentPage={pageno}
                                            />
                                        )
                                        : null}
                                </>
                            }
                        />
                        <br />
                        <br />
                    </Col>
                </div>
            </Col>
        </>
    )
}

export default withDashboardLayout(Sellers);