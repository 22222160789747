import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createQueryRequest, createQueryRequestWithBody, createQueryWithTokenRequest, createQueryWithTokenRequestByText } from '.';
import { BASE_URL } from '../../../constants/index';


export const shopApi: any = createApi({
    reducerPath: 'shopApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    endpoints: (builder) => ({
        getSingleMerchant: builder.query<void, string>({
            query: (id) => createQueryRequest(
                {
                    url: `/api/merchant/get-merchant-business-info-for-eke-market-day/${id}`,
                    method: 'GET',
                }),
        }),
        getAllBrands: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest(
                {
                    url: 'api/brand/get-all-brands-with-collection-is-active',
                    method: 'GET',
                    token: token
                }),
        }),
    })

});


export const {
    useGetSingleMerchantQuery,
    useGetAllBrandsQuery
} = shopApi;


