import { FormikProvider, getIn, useFormik } from 'formik';
import React, {useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { SendIcon } from '../../../../components/icons/icons';
import { MessagingFormValidation } from '../../../../validations/schemas/live-commerce.schema';
import AllMessagesList from './innercomponents/AllMessagesList';
import SockJsClient from 'react-stomp';
import { BASE_URL } from '../../../../constants';
import { checkIfSeller, useMatchPath } from '../../../../utils';
import { useAppSelector } from '../../../../redux/store/hook';
import { useLocation } from 'react-router';
import { DashboardSellerLiveCommercePathUrl, singleLivePathUrl } from '../../../../routes/constant';
import { FiGift } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

interface MessagingStreamsPropType {
  from: string;
  clientRef: any;
  sellerId: string | undefined;
  setMsgList?: any;
  liveCode?: string | null;
  addClass?: string | null;
  setHandle?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDisconected: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConected: React.Dispatch<React.SetStateAction<boolean>>;
  
}

export default function MessagingStreams({setHandle,addClass,clientRef,from,sellerId,liveCode,setIsDisconected,setIsConected,setMsgList}:MessagingStreamsPropType) {
  const { pathname } = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [messagesList, setMessageList] = useState<{from:string;text:string}[]>([]);
  const [message, setMessage] = useState('');

  const { token } = useAppSelector(state => state.auth.authentication);


  useEffect(() => {
    if(message){
      sendMessage(from,message);
      setMessage('');
    }

  }, [clientRef,message])
  


  const formik = useFormik({
    initialValues:{
      message: ''
    },
   validationSchema: MessagingFormValidation(),
      onSubmit: (values, {resetForm}) => {
        setLoading(true);
        console.log('aaaYmessage...',)
        if(values.message){
          setMessage(values.message);
          setLoading(false);
        }
        resetForm({
          values: {
            message: ''
          },
        })
     
     
    }
});



const sendMessage = (from:string,msg:string) => {
  const _from = checkIfSeller(token) 
                &&  pathname === useMatchPath(DashboardSellerLiveCommercePathUrl,pathname) ? 'seller' : from;
  const data = {
    from:_from,
    text:msg
  }
  if(clientRef){
    clientRef.sendMessage(`/ls/seller-group/${sellerId}`, JSON.stringify(data));
  }
}

const handleKeyDown = () => {
  const { message } = formik.values;
  //if(message){}
 }

 const renderMessageSocketClient = () => {
  return (
    <SockJsClient url={`${BASE_URL}/live`} 
      topics={[`/g/${sellerId}`,`/ls/seller-group/${sellerId}`]}
      onConnect={() => {
        console.log("connected")
        setIsDisconected(false);
        setIsConected(true);

      }}
      onDisconnect={() => {
          console.log("Disconnected")
          setIsConected(false);
          setIsDisconected(true);
          
      }}
      onMessage={(msg:any) => { 
        const resList = [
          ...messagesList,
          {
            from:msg.from,
            text:msg.text
          }
        ];
        setMessageList(resList);
        setMsgList(resList);
      }}

      ref={ (client:any) => { 
        clientRef = client 
      }}
   />
  )
 }

 const handleFunc = () => {
  if(setHandle){
    setHandle(true)
  }
 } 


  return (
    <> 
      <div className="msg__main__div">
        <div className="msges__list__r">
          <AllMessagesList 
            messagesList={messagesList}
          />
        </div>
        <div className={`messaging__stream__div ${addClass ? addClass : ''}`}>
          <div className="form__container_div">
            <FormikProvider value={formik}>
              <Form  noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-0 d-f form-groups " controlId="formB">
                  <div className="input_group"
                      style={pathname === useMatchPath(singleLivePathUrl,pathname) ? {width: "100%"} : {width: "100%"}}
                  >
                    <span className="word__counter__input">{formik.values.message.length}/153</span>
                    <Form.Control 
                      type="text" 
                      autoComplete="none" 
                      name="message"
                      value={formik.values.message}
                      onKeyDown={handleKeyDown}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Type a message"
                      max={153} 
                     // disabled={!liveCode ? true : false}
                      />
                  </div>
                    <Button disabled={!formik.isValid || !formik.values.message.trim() || isLoading} type='submit'>
                    <SendIcon className="send__icon_svg" />
                  </Button>
                </Form.Group>
              </Form>
            </FormikProvider>   
           {pathname !== useMatchPath(singleLivePathUrl,pathname) && (<div className="message__other__option">
              <div>
                <ReactTooltip id='list_product' place="top" type="dark" effect="solid"/>
                <button className="d-lg-none product__button Button Button--icon" data-tip="Select Product" data-for='list_product'
                 onClick={handleFunc}>
                  <FiGift fontSize={20} />
                </button>
              </div>
            </div>
           )}
          </div>
          
        </div>
      </div>
      {renderMessageSocketClient()}
    </>
  )
}
