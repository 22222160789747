import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import {
  Container,
  Col,
  Card,
  Form,
  Row,
  Button,
  Spinner,
} from 'react-bootstrap';
import { FiMail, FiChevronLeft } from 'react-icons/fi';
import forgotPassFormValidation from '../../../validations/schemas/account-forgotpassword.schema';
import { useResetUserPasswordWithEmailMutation } from '../../../redux/store/services/authenticationApi';
import { loginPathUrl, resetPassPathUrl } from '../../../routes/constant';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import withAuthenticationLayout from '../../../hoc/Layout/withAuthenticationLayout';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({ email: '' });
  const [resetUserPasswordWithEmail, { isLoading, isError, error, isSuccess }] =
    useResetUserPasswordWithEmailMutation();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: forgotPassFormValidation(),
    onSubmit: (values) => {
      const email = values.email;
      resetUserPasswordWithEmail(email);
    },
  });

  useEffect(() => {
    if (isError && error.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong`, {
        toastId: 'error-status-fp-id-toast-error',
      });
    }
    if (isError && error.status === 400) {
      toast.error(`${error.data.title}`, {
        toastId: 'error-status-fp400--id-toast-error',
      });
    }

    if (isError && error.status === 401) {
      toast.error(`${error.data.title}`, {
        toastId: 'error-status-fp-401--id-toast-error',
      });
    }

    // { success response check}
    if (isSuccess) {
      localStorage.setItem('resetEmail', initialValues.email)
      setTimeout(() => {
        navigate(resetPassPathUrl, {
          state: {
            email: initialValues.email,
          },
        });
      }, 1000);
    }
  }, [isSuccess, error, isError]);

  return (
    <>
      <HelmetHeader
        title=" Recover Password"
        description="Ekenta Forgot Password Page"
      />

      <Container fluid className="account-forgotpass">
        <Row className="row_card">
          <Col md="8" lg="5" xl="4" className="col--card  p-1">
            <Card>
              <Card.Body className="p-4">
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Col className="mb-4">
                    <h2 className="form-header text-center">
                      Forgotten Password
                    </h2>
                  </Col>
                  {/* email form input */}
                  <Form.Group className="form-reset-gp mb-3 position-relative">
                    <div className="_group_inpu_ position-relative">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email address"
                        autoComplete="off"
                        onChange={(e) =>
                          setInitialValues({ email: e.target.value })
                        }
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      <FiMail
                        className="fmail_ "
                        style={{ color: '#7C420C' }}
                      />
                    </div>
                    {formik.errors.email ? (
                      <div className="invalid-fbk">{formik.errors.email}</div>
                    ) : null}
                  </Form.Group>
                  <Button
                    className={`account-forgot-btn ${isLoading ? 'disabled' : ''
                      } w-100 `}
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        {' '}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-1">Please wait...</span>
                      </>
                    ) : (
                      <span>Reset Password</span>
                    )}
                  </Button>
                </Form>

                <Col className="account-backlogin-col w-100 mt-3 d-flex align-items-center justify-content-between">
                  <h6 className="col-h-">
                    <Link
                      to={loginPathUrl}
                      className="d-flex align-items-start"
                    >
                      <FiChevronLeft
                        style={{
                          width: '1.25rem',
                          height: 'auto',
                          marginTop: '0rem',
                        }}
                      />
                      <span>Back to sign in</span>
                    </Link>
                  </h6>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* { for all error handing} */}
    </>
  );
};

export default withAuthenticationLayout(ForgetPassword);
