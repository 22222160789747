import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Tab, Tabs } from "react-bootstrap";
import * as queryString from "query-string";
import { useAppSelector } from "@ekenta/redux/store/hook";
import {
  useFetchAdmniAllOrdersQuery,
  useFetchAllOrdersQuery,
  useFetchCancelledOrdersQuery,
  useFetchCompletedOrdersQuery,
  useFetchPendingOrdersQuery,
  useFetchProcessingOrdersQuery,
} from "@ekenta/redux/store/services/vendorProductApi";

import EmptyOrder from "@ekenta/components/Box/EmptyOrder";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsEye } from "react-icons/bs";
import OrderSkeletonLoader from "@ekenta/components/SkeletonLoader/Account/OrderSkeletonLoader";
import { withDashboardLayout } from "@ekenta/hoc";
import { DataTable, HelmetHeader, PageTitle, Pagination } from "@ekenta/components";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { DashboardSellers } from "@ekenta/routes/constant";
import moment from "moment";

const AdminOrders = () => {
  const query = queryString.parse(window.location.search);
  const _page = query.page;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [lgShow, setLgShow] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [activeTab, setActiveTab] = useState<string | null>("pending");
  const { token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const pending = "PENDING";
  const completed = "COMPLETED";
  const processing = "PROCESSING";
  const cancelled = "CANCELLED";


  const pendingOrders = useFetchAdmniAllOrdersQuery({
    token: token,
    status: pending,
    pageNo: pending.toLowerCase() === activeTab ? pageNo : 0
  });
  const completedOrders = useFetchAdmniAllOrdersQuery({
    token: token,
    status: completed,
    pageNo: completed.toLowerCase() === activeTab ? pageNo : 0
  });
  const processingOrders = useFetchAdmniAllOrdersQuery({
    token: token,
    status: processing,
    pageNo: processing.toLowerCase() === activeTab ? pageNo : 0
  });
  const cancelledOrders = useFetchAdmniAllOrdersQuery({
    token: token,
    status: cancelled,
    pageNo: cancelled.toLowerCase() === activeTab ? pageNo : 0
  });
  // const result = useFetchAllOrdersQuery(token, "PENDING")
  // const completed = useFetchCompletedOrdersQuery(token)
  // const pending = useFetchPendingOrdersQuery(token)
  // const cancelled = useFetchCancelledOrdersQuery(token)
  // const processing = useFetchProcessingOrdersQuery(token)

  //const [_pageSize, setPageSize] = useState(7);

  const columns = [
    {
      Header: "Order No",
      accessor: "orderNo",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Total Amount",
      accessor: "orderTotal",
      sortType: "basic",
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
      disableSortBy: true,
    },
    {
      Header: "Logistics",
      accessor: "logistics",
      disableSortBy: true,
    },
    {
      Header: "Delivery Status",
      accessor: "deliveryStatus",
      disableSortBy: true,
    },
    {
      Header: "Date Created",
      accessor: "createdDate",
      disableSortBy: true,
    },
    {
      Header: "Action",
      accessor: "view",
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      if (_page) {
        const _newPage = parseInt(_page.toString());
        if (_newPage > 0) {
          setPageNo(_newPage - 1);
        } else {
          navigate(`${pathname}?page=1&sortType=default`);
        }
      } else {
        setPageNo(0);
      }
    }, 1000);
  }, [_page]);


  const recalibrate = (data: any) => {

    const newArr = []
    for (let i = (data.length - 1); i >= 0; i--) {
      newArr.push(data[i])
    }

    return newArr.map((items: any, i: any) => ({
      ...items,
      orderNo: items.orderNumber,
      logistics: items.logistics,
      orderTotal: "NGN " + new Intl.NumberFormat().format(items.totalOrderAmount),
      deliveryStatus: items.deliveryStatus,
      paymentStatus: items.paymentStatus,
      createdDate: moment(items.createdDate).format("MMM D, YYYY"),
      view: <Link to={`/admin/get-order-details/${items.id}`}><BsEye size={20} color={"#E04826"} /></Link>
    }))
  }



  // const recalibrate = (data:any) =>{
  //     return data.map((items:any, i:any) => ({
  //       ...items,
  //       quantity: items.orderItems.length,
  //       totalamount: "NGN " + new Intl.NumberFormat().format(items.totalOrderAmount),
  //       deliverystatus: items.deliveryStatus,
  //       paymentstatus: items.paymentStatus

  //     //   view: <Link to={`#`}><BsEye size={24} color={"#E04826"}/></Link>
  //     }))
  // }

  const handleViewOrder = (id: string) => {
    setLgShow(true);
    alert(id);
  };

  return (
    <>
      <HelmetHeader
        title="Orders"
        description="Ekenta Orders Dashboard Page"
      />
      <Col className=" col-lg-12 _listing_product_ px-2">
        <div className="mt-0">
          <div className="mx-1 mx-lg-0">
            <PageTitle
              title="Orders"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-25 align-items-center 
                        text-muted"
                  onClick={() => navigate(DashboardSellers)}>
                  <VscArrowSmallLeft />
                  Back to Dashboard
                </span>
              }
            />
          </div>
          <Col className="mt-1 tab__">
            <Tabs defaultActiveKey="pending" id="u__tab" className="mb-3"
              onSelect={(value) => {
                setPageNo(0);
                setActiveTab(value);
              }}>
              <Tab
                eventKey="pending"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_pending">
                      {!pendingOrders.isSuccess
                        ? "-"
                        : pendingOrders?.data.totalElements}
                    </Badge>{" "}
                    Pending
                  </span>
                }
              >
                <DataTable
                  theme="simple"
                  data={pendingOrders?.data ? recalibrate(pendingOrders?.data.content) : []}
                  loading={pendingOrders?.isLoading}
                  isSelectable={true}
                  columns={columns}
                  showPagination={false}
                  hasLink={true}
                  showSearch
                  thClassName="w"
                  trClassName="w_Tr"
                  tdClassName={`Row__col`}
                  renderPagnation={
                    <>
                      {pendingOrders?.data
                        ? pendingOrders?.data.totalElements > 20 && (
                          <Pagination
                            pathname={pathname}
                            itemPerPage={pendingOrders?.data.pageable.pageSize}
                            itemsLength={pendingOrders?.data.totalElements}
                            currentPage={pageNo}
                          />
                        )
                        : null}
                    </>
                  }
                />
                <br />
              </Tab>
              <Tab
                eventKey="processing"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_processing">
                      {!processingOrders.isSuccess
                        ? "-"
                        : processingOrders?.data.totalElements}
                    </Badge>{" "}
                    Processing
                  </span>
                }
              >
                <DataTable
                  theme="simple"
                  data={processingOrders?.data ? recalibrate(processingOrders?.data.content) : []}
                  loading={processingOrders?.isLoading}
                  isSelectable={true}
                  columns={columns}
                  showPagination={false}
                  hasLink={true}
                  thClassName="w"
                  trClassName="w_Tr"
                  tdClassName={`Row__col`}
                  renderPagnation={
                    <>
                      {processingOrders?.data
                        ? processingOrders?.data.totalElements > 20 && (
                          <Pagination
                            pathname={pathname}
                            itemPerPage={processingOrders?.data.pageable.pageSize}
                            itemsLength={processingOrders?.data.totalElements}
                            currentPage={pageNo}
                          />
                        )
                        : null}
                    </>
                  }
                />
                <br />
              </Tab>

              <Tab
                eventKey="completed"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_completed">
                      {" "}
                      {!completedOrders.isSuccess
                        ? "-"
                        : completedOrders?.data.totalElements}
                    </Badge>{" "}
                    Completed
                  </span>
                }
              >
                <DataTable
                  theme="simple"
                  data={completedOrders?.data ? recalibrate(completedOrders?.data.content) : []}
                  loading={completedOrders?.isLoading}
                  isSelectable={true}
                  columns={columns}
                  showPagination={false}
                  hasLink={true}
                  thClassName="w"
                  trClassName="w_Tr"
                  tdClassName={`Row__col`}
                />
              </Tab>

              <Tab
                eventKey="cancelled"
                title={
                  <span>
                    <Badge className="tab__identifier tab__identifier_cancelled">
                      {!cancelledOrders.isSuccess
                        ? "-"
                        : cancelledOrders?.data.totalElements}
                    </Badge>{" "}
                    Cancelled
                  </span>
                }>
                <DataTable
                  theme="simple"
                  data={cancelledOrders?.data ? recalibrate(cancelledOrders?.data.content) : []}
                  loading={cancelledOrders?.isLoading}
                  isSelectable={true}
                  columns={columns}
                  showPagination={false}
                  hasLink={true}
                  thClassName="w"
                  trClassName="w_Tr"
                  tdClassName={`Row__col`}
                />
              </Tab>
            </Tabs>
          </Col>
        </div>
      </Col>
      <br />
      <br />
    </>
  );
}

export default withDashboardLayout(AdminOrders);
