import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { HelmetHeader } from '../../components/HelmetHeader/HelmetHeader';
import DefaultLayout from '../../hoc/Layout/Default-Layout';

export default function PrivacyPolicy() {
    return (
        <DefaultLayout>
              
            <HelmetHeader
                title="Terms and Condition - Ekenta.com"
                description="Ekenta Terms and Condition Page"
             />

            <div className="main2 main__">
                <Container className="landingpage__container top__container pt-5">
                    <Row>
                        <Col lg={12} className="py-3 mb-3">
                            <p className={'text-bold bold-header'}>Signup &gt; <span style={{ color: '#0A54B1' }}>Terms and Conditions</span></p>
                        </Col>
                        <Col lg={12} className="text-center py-3 mb-3">
                            <h3 className={'text-bold bold-header'}>Ekenta.com Privacy Notice</h3>
                        </Col>
                        <Col lg={12} className={'text-justify'}>
                            <div>
                                We understand that how information about you is used and shared is important to you, and we appreciate your confidence in our ability to do so safely and responsibly. This Privacy Notice explains how Ekenta.com and its affiliates (collectively, &qouut;Ekenta&qouut;) gather and process your personal information via Ekenta websites, devices, products, services, online and physical stores, and applications (together &qouut;Ekenta Services&qouut;). You consent to the practices outlined in this Privacy Notice by using Ekenta Services.
                            </div> <br />
                            <div>
                                <ul>
                                    <li>What Personal Information About Customers Does Ekenta Collect?</li>
                                    <li>For What Purposes Does Ekenta Use Your Personal Information?</li>
                                    <li>What About Cookies and Other Identifiers?</li>
                                    <li>Does Ekenta Share Your Personal Information?</li>
                                    <li>How Secure Is Information About Me?</li>
                                    <li>What About Advertising?</li>
                                    <li>What Information Can I Access?</li>
                                    <li>What Choices Do I Have?</li>
                                    <li>Are Children Allowed to Use Ekenta Services?</li>
                                    <li>Conditions of Use, Notices, and Revisions</li>
                                    <li>Related Practices and Information</li>
                                    <li>Examples of Information Collected</li>
                                </ul>


                            </div> <br />
                            <div>
                                <b>What Personal Information About Customers Does Ekenta Collect?</b> <br />
                                We collect your personal information in order to provide and continually improve our products and services.
                            </div> <br />
                            <p>Here are the types of personal information we collect:</p>
                            <div>
                                <li>Information You Supply: Any information you provide in connection with Ekenta Services is received and stored by us. To see examples of what we collect, go here. You can choose not to share certain information, but you may be unable to use several of our Ekenta Services as a result.</li>
                                <li>Automatic Information: We collect and store certain types of information regarding your use of Ekenta Services, such as information about your interactions with content and services available through Ekenta Services, automatically. When your web browser or device accesses Ekenta Services and other material delivered by or on behalf of Ekenta on other websites, we employ &qout;cookies&qout; and other unique identifiers, and we receive certain sorts of information.</li>
                                <li>Other Sources of Information: We may obtain information about you from other sources, such as updated delivery and address information from our carriers, which we utilize to adjust our records and make your next purchase easier to deliver. To see more instances of the information we receive, go here.</li>
                            </div> <br />


                            <div>
                                <b>For What Purposes Does Ekenta Use Your Personal Information?</b> <br />
                                We use your personal information to operate, provide, develop, and improve the products and services that we offer our customers. These purposes include:
                            </div> <br />
                            <div>
                                <li>Product and service purchase and delivery. We use your personal information to process payments, fulfill orders, and communicate with you about orders, products, and promotional offers.</li>
                                <li>Ekenta Services are provided, troubleshooted, and improved. We utilize your personal information to offer functionality, analyze performance, correct mistakes, and improve the Ekenta Services&apos; usability and effectiveness.</li>
                                <li>Personalization and recommendations We use your personal information to suggest features, products, and services that may be of interest to you, as well as to recognize your preferences and personalize your Ekenta Services experience.</li>
                                <li>Voice, picture, and camera services are available. We utilize your voice input, photos, videos, and other personal information to answer your requests, offer the desired service to you, and improve our services when you use our voice, image, and camera services. Click here for more information about Alexa voice services.</li>
                                <li>Comply with all legal requirements. We collect and utilize your personal information to comply with legislation in some situations. For example, we collect information from sellers about their business location and bank account details for identification verification and other uses.</li>
                                <li>Make contact with you. We use your personal information to interact with you via various channels about Ekenta Services (e.g., by phone, email, chat).</li>
                                <li>Advertising. We use your personal information to show you adverts for features, products, and services that you might be interested in. We do not display interest-based advertising based on information that specifically identifies you. Please read our Interest-Based Ads notice for more information.</li>
                                <li>Credit Risks and Fraud Prevention To protect the security of our customers, Ekenta, and others, we use personal information to prevent and identify fraud and abuse. To measure and manage credit risks, we may apply scoring methods.</li>
                            </div> <br />

                            <div>
                                <b>How Secure Is Information About Me?</b> <br />
                                We design our systems with your security and privacy in mind.
                            </div> <br />
                            <div>
                                <li>We use encryption techniques and software to protect the security of your personal information during transmission.</li>
                                <li>When it comes to credit card data, we follow the Payment Card Industry Data Security Standard (PCI DSS).</li>
                                <li>When it comes to the collection, storage, and disclosure of customer personal information, we use physical, electronic, and procedural measures. Because of our security processes, we may need to confirm your identity before disclosing personal information to you.</li>
                                <li>Our gadgets include security safeguards that guard against illegal access and data loss. You have complete control over these features and can adjust them to meet your specific requirements. For additional information on how to manage your device&apos;s security settings, see here.</li>
                                <li>It is critical that you safeguard your password as well as your computers, gadgets, and applications from illegal access. We recommend creating a password for your Ekenta account that is different from any other online account. When you&apos;re done using a shared computer, remember to sign off. For more information on how to sign off, see here.</li>

                            </div> <br />

                            <div>
                                <b>What About Advertising?</b> <br />
                                {/* We design our systems with your security and privacy in mind. */}
                            </div> <br />
                            <div>
                                <li>
                                    Third-Party Advertisers and Links to Other Websites: Ekenta Services may feature advertisements from third parties as well as links to other websites and apps.When you interact with third-party advertising partners&apos; content, advertising, and services, they may collect information about you. Please read our Interest-Based Ads notice for more information about third-party advertising at Ekenta, including interest-based ads. Please visit the Advertising Options page to change your advertising preferences.
                                </li>
                                <li>Third-Party Advertising Services: We offer information to ad firms so they can serve you with more relevant and useful Ekenta advertising and track their success. When we do this, we never share your name or any other information that directly identifies you. Instead, we utilize a cookie or another device identifier as an advertising identifier. If you have already downloaded one of our apps, for example, we will exchange your advertising identifier and data about that event so that you are not presented with an ad to download the app again. This information is also used by some ad providers to deliver you relevant advertising from other advertisers. Go to the Advertising page to learn more about how to opt-out of interest-based advertising.</li>
                                <li>Third-Party Advertising Services: We offer information to ad firms so they can serve you with more relevant and useful Ekenta advertising and track their success. When we do this, we never share your name or any other information that directly identifies you. Instead, we utilize a cookie or another device identifier as an advertising identifier. If you have already downloaded one of our apps, for example, we will exchange your advertising identifier and data about that event so that you are not presented with an ad to download the app again. This information is also used by some ad providers to deliver you relevant advertising from other advertisers. Go to the Advertising page to learn more about how to opt-out of interest-based advertising.</li>
                            </div> <br />


                            <div>
                                <b>What Information Can I Access?</b> <br />
                            </div> <br />
                            <div>
                                <li>
                                In the &qout;Your Account&qout; part of the website, you may view your information, including your name, address, payment options, profile information, Prime membership, household settings, and purchase history. You can find a list of examples by clicking here.

                                </li>
                            </div> <br />

                            <div>
                                <b>What choices do I Have</b> <br />
                            </div> <br />
                            <div>
                                <b>What Information Can I Access?</b> <br />
                                Please contact our Customer Service if you have any queries about how we gather and utilize your personal information. Many of our Ekenta Services also include settings that provide you control over how your information is utilized.

                            </div> <br />
                            <div>
                                <li>
                                You can choose not to share certain information, as indicated above, but you may not be able to use many Ekenta Services if you do.
                                </li>
                                <li>
                                On pages like the ones mentioned in What Information Can I Access?, you can add or change particular information. We normally preserve a copy of the previous version for our records when you update information.
                                </li>
                                <li>
                                Please update your Customer Communication Preferences if you do not wish to receive email or other messages from us. Please update your notification settings in the app or device if you do not want to receive in-app alerts from us.
                                </li>
                                <li>
                                Please modify your Advertising Preferences if you do not want to receive interest-based adverts.
                                </li>
                                <li>
                                Most browsers and devices offer a Help feature that will explain to you how to prevent your browser or device from accepting new cookies or other identifiers, how to have the browser warn you when a new cookie is received, or how to completely block cookies. We recommend that you leave cookies and identifiers turned on since they allow you to enjoy some of Ekenta Services&apos; most important services. You will not be able to add products to your Shopping Cart, proceed to Checkout, or utilize any Services that require you to Sign in if you block or otherwise reject our cookies. See our Cookies Notice for more information on cookies and other identifiers.
                                </li>
                                <li>
                                Logging out of your account here and disabling cookies on your browser will allow you to visit our websites without associating your browsing history to your account.
                                </li>
                                <li>
                                You may change your browsing history here, manage the suggestions you receive in our store, and delete items you don&apos;t want to see by selecting View All and Manage, then selecting the Remove Items option at the top of the page.
                                </li>
                                <li>
                                By modifying your settings on the corresponding Ekenta website (e.g., in &qout;Manage Your Content and Devices&qout;), device, or application, you will be able to opt out of some other forms of data usage. Click here for additional details. Users of most non-Ekenta devices can also adjust device permissions (for example, disable/access location services and contacts). These parameters can usually be found in the device&apos;s settings menu. If you have any issues about changing your device permissions on third-party devices, we recommend contacting your mobile service provider or the device manufacturer.
                                </li>
                                <li>
                                If you&apos;re a seller, you can add or amend some information in Seller Central, change your account details by going to Seller Account Information, and change your notification preferences by going to Notification Preferences.
                                </li>
                                <li>
                                If you are an author, you can visit your Author Portal and Author Central accounts to add or amend the information you have supplied in the Author Portal and Author Central, respectively.
                                </li>

                                <p>You may also have the right to seek access to or deletion of your personal information, to the extent permitted by relevant legislation. Please contact Customer Service if you wish to accomplish any of these things. Certain services may be limited or unavailable depending on your data settings.</p>

                            </div> <br />
                            
                            <div>
                                <b>Are Children Allowed to Use Ekenta Services?</b> <br />
                                Ekenta does not sell things for children to buy. Adults can purchase children&apos;s items from us. If you&apos;re under the age of 18, you can only use Ekenta Services with the help of a parent or guardian. Without the approval of the child&apos;s parent or guardian, we do not knowingly collect personal information from children under the age of 13. Please check our Children&apos;s Privacy Disclosure for more details.

                            </div> <br />
                            <div>
                                <b>Conditions of Use, Notices, and Revisions</b> <br />
                                If you choose to use Ekenta Services, your use and any privacy dispute are governed by this Notice and our Conditions of Use, which include restrictions on damages, dispute resolution, and the application of Nigeria law. If you have any concerns regarding Ekenta&apos;s privacy, please contact us with a detailed explanation of your issue, and we will do our best to fix it. Our business evolves at a rapid pace, and our Privacy Notice will evolve as well. For the most up-to-date information, you should visit our websites frequently. Unless otherwise noted, our current Privacy Notice applies to all information we have on you and your account, unless otherwise stated. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of customer information collected in the past without the consent of affected customers.

                            </div> <br />

                            <div>
                                <b>Related Practices and Information</b>
                            </div>
                            <div>
                                <ul>
                                    <li>Conditions of Use</li>
                                    <li>Seller Program Policies</li>
                                    <li>Help Department</li>
                                    <li>Most Recent Purchases</li>
                                    <li>Your Profile and Community Guidelines</li>
                                </ul>


                            </div> <br />

                            <div>
                                <b>Examples of Information Collected</b> <br />
                                <p>Information You Give Us When You Use Ekenta Services</p>
                                <p>You provide information to us when you:</p>
                            </div>
                            <div>
                                <ul>
                                    <li>Search for products or services in our stores, add or delete items from your cart, or utilize Ekenta Services to place a purchase;</li>
                                    <li>On a device or through a service or application on a device, download, stream, view, or consume material;</li>
                                    <li>provide information in Your Account (you may have multiple accounts if you used more than one email address or cell phone number when buying with us) or Your Profile;</li>
                                    <li>disclose information in your Seller Account, Developer Account, or any other account we provide that allows you to develop or sell software, goods, or services to Ekenta customers;</li>
                                    <li>offer your products or services on Ekenta Services or through it;</li>
                                    <li>offer your products or services on Ekenta Services or through it;</li>
                                    <li>contact us by phone, email, or other means;</li>
                                    <li>fill out a survey, a support ticket, or a contest entry form</li>
                                    <li>upload or stream photos, movies, or other materials to Ekenta Photos, Ekenta Drive, or other Ekenta Services;</li>
                                    <li>create Playlists, Watchlists, Wish Lists, and other gift registries</li>
                                    <li>take part in online forums or other community features;</li>
                                    <li>provide and rate Reviews;</li>
                                    <li>specify a Special Occasion Reminder; or</li>
                                    <li>Employ Productivity Availability Alerts, such as Available to Order Notifications.</li>
                                </ul>


                            </div> <br />

                            <div>
                                <b>As a result of your actions, you may provide us with information such as:</b> <br />
                            </div>
                            <div>
                                <ul>
                                    <li>information that can be used to identify you, such as your name, address, and phone number;</li>
                                    <li>information about payments;</li>
                                    <li>when you were born;</li>
                                    <li>information about your location;</li>
                                    <li>IP address of your computer;</li>
                                    <li>In your Addresses, you have persons, addresses, and phone numbers;</li>
                                    <li>email addresses of friends and acquaintances;</li>
                                    <li>the content of customer reviews and communications sent to us;</li>
                                    <li>In Your Profile, include a personal description and a photograph;</li>
                                    <li>pictures and videos gathered or stored as part of Ekenta Services</li>
                                    <li>identity-related information and papers, such as Social Security and driver&apos;s license numbers;</li>
                                    <li>financial and corporate information</li>
                                    <li>details about your credit history;</li>
                                    <li>if you choose to automatically synchronize them with your other Ekenta devices, device log files and configurations, including Wi-Fi credentials</li>
                                </ul>


                            </div> <br />

                            <div>
                                <b>Information that is generated automatically</b> <br />
                                <p>The following are some examples of the data we collect and analyze:</p>
                            </div>
                            <div>
                                <ul>
                                    <li>the internet protocol (IP) address that your computer uses to connect to the internet</li>
                                    <li>a username, an email address, and a password;</li>
                                    <li>your device&apos;s or computer&apos;s location;</li>
                                    <li>disclose information in your Seller Account, Developer Account, or any other account we provide that allows you to develop or sell software, goods, or services to Ekenta customers;</li>
                                    <li>content interaction information, such as content downloads, streams, and playback details, including duration and number of simultaneous streams and downloads, and network details for streaming and download quality, including information about your internet service provider; </li>
                                    <li>When a device is in operation, application consumption, connectivity data, and any problems or event failures are all metrics that can be collected.</li>
                                    <li>Ekenta Services metrics (e.g., technical failures, interactions with service features and content, settings preferences and backup information, location of your device running an app, information about uploaded photos and files such as file name, dates, times, and position of your images);</li>
                                    <li>settings for version and time zone;</li>
                                    <li>purchase and content usage history, which we may combine with similar data from other customers to generate services such as Top Sellers;</li>
                                    <li>the complete Uniform Resource Locator (URL) clickstream to, through, and from our websites, including the date and time; the products and content you viewed or searched for; page response times, download errors, length of visits to certain pages, and page interaction information (such as scrolling, clicks, and mouse-overs); </li>
                                    <li>photos or videos when you shop in our stores or stores that use Ekenta Services; and phone numbers used to call our customer care number.</li>
                                    
                                </ul>
                                <p>On devices, applications, and our web pages, we may use device identifiers, cookies, and other technologies to gather browsing, usage, and other technical information.</p>

                            </div> <br />

                            <div>
                                <b>Other Resources of Information</b> <br />
                                <p>The following are some examples of information we get from other sources:</p>
                            </div>
                            <div>
                                <ul>
                                    <li>updated delivery and address information from our carriers or other third parties, which we use to update our records and make it easier for you to receive your next purchase or communication;</li>
                                    <li>account information, purchase or redemption information, and page-view data from some merchants with whom we run co-branded businesses or provide technical, fulfillment, advertising, or other services;</li>
                                    <li>search results and links, including sponsored listings (such as Sponsored Links); and credit history information from credit bureaus, which we use to help prevent and detect fraud and to offer certain credit or financial services to some customers.</li>
                                </ul>
                            </div> <br />

                            <div>
                                <b>Information you have Access to</b> <br />
                                <p>The following are some examples of information available through Ekenta Services:</p>
                            </div>
                            <div>
                                <ul>
                                    <li>recent order status (including subscriptions);</li>
                                    <li>all of your previous orders;</li>
                                    <li>Name, email, password, and address book are examples of personally identifiable information.</li>
                                    <li>payment options (containing payment card details, balances on promotional certificates and gift cards);</li>
                                    <li>Product Availability Alerts, Delivers, Special Occasion Reminders, and Newsletters are among the email notification options;</li>
                                    <li>recommendations, as well as the products you&apos;ve lately visited that serve as the foundation for recommendations (such as Recommended for You and Improve Your Recommendations);</li>
                                    <li>Wish lists and baby and wedding registries are examples of shopping lists and gift registries.</li>
                                    <li>your communications and personalized advertising preferences, as well as your content, devices, services, and related settings;</li>
                                    <li>material you&apos;ve recently seen;</li>
                                    <li>recordings of your voice that are linked to your account;</li>
                                    <li>Your personal profile (which includes your product reviews, recommendations, and reminders);</li>
                                    <li>If you&apos;re a seller, you can update your account in Seller Central to gain access to your account and other information, as well as change your communication options.</li>
                                    <li>If you&apos;re a developer in our Developer Services Program, you can update your accounts via the Developer Services Portal to access your account and other information, as well as change your communication options.</li>
                                    
                                </ul>
                            </div> <br />

                        </Col>


                    </Row>
                </Container>
            </div>
        </DefaultLayout>
    )
}
