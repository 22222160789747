import React from "react";
import { useDispatch } from 'react-redux';
import { Navigate } from "react-router";
import { useAppSelector } from "../../redux/store/hook";
import { removeUser } from "../../redux/store/states/reducerSlice/authenticationSlice";
import { checkIsSellerOrAdminRoleOnboardingRoute } from "../../utils";
import { checkTokenValidityAndExpireAndReturnRoles } from "../../utils/";
import { DashboardPathUrl, loginPathUrl } from "../constant";


const OnboardingRoute= ({ component: RouteComponent, ...rest }:{component: () => any;}) => {
  const dispatch = useDispatch();
  const { ek_isAuth,token } = useAppSelector((state:any) => state.auth.authentication); 


   if(ek_isAuth && token) {
      const actorRoles = checkTokenValidityAndExpireAndReturnRoles(token);
      if (!actorRoles) {
        dispatch(removeUser());
        return <Navigate to={loginPathUrl} />;
      }
      const result = checkIsSellerOrAdminRoleOnboardingRoute(actorRoles);
      if (result){
          return <Navigate to={DashboardPathUrl} />
      } 
   }
  
   return <RouteComponent {...rest} />
};

export default OnboardingRoute;

