import React, { Suspense, Fragment, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FormikProvider, getIn, useFormik } from "formik";
import NumberFormat from "react-number-format";

import { InfoIcon } from "../../../../components/icons/icons";
import withDashboardLayout from "../../../../hoc/Layout/withDashboardLayout";
import { useAppSelector } from "../../../../redux/store/hook";
import * as animationData from "../../../../assets/lotties_files/loading-bubbles.json";

import Brands_Select_List from "./component/Brands_Select_List";
import addProductListingFormValidation from "../../../../validations/schemas/add-product-listing";
import {
  useAddNewProductVideoMutation,
  useAddNewMultipleProductImagesMutation,
  useAddNewProductMutation,
} from "../../../../redux/store/services/collectionApi";

const Multi_Step_Collections = React.lazy(
  () => import("./component/Multi_Step_Collections"),
);
const NewFileUploader = React.lazy(
  () => import("../../../../components/FileUploader/FileUploader"),
);
const Price_QuantityComponent = React.lazy(
  () => import("./component/Price_QuantityComponent"),
);
const FeatureValueComponent = React.lazy(
  () => import("./component/FeatureValueComponent"),
);

import videoplaceholder from "../../../../assets/images/video_placeholder.png";
import CustomLottieLoader from "../../../../components/Spinner/CustomLottieLoader";
import { Overlay, PageTitle, Typography } from "@ekenta/components";
import Multiple_ProductImages from "./component/Multiple_ProductImages";
import SkuComponent from "./component/SkuComponent";
import { checkIfAdmin, formatBytes, resizeImageFile, slugifyText } from "../../../../utils";
import { SpinLoader } from "../../../../components/Spinner/SpinLoader";
import { UnAuthorizedCard } from "../../component/UnAuthorizedCard";
import { useGetSingleSellerDetailQuery } from "../../../../redux/store/services/adminSellerApi";
import { HelmetHeader } from "../../../../components/HelmetHeader/HelmetHeader";
import { useFetchSingleProductByIdQuery, useUpdateProductMutation } from "../../../../redux/store/services/productsApi";
import updateProductListingFormValidation from "@ekenta/validations/schemas/update-product-listing";
import { AdminProductsPathUrl, DashboardProductsPathUrl } from "@ekenta/routes/constant";
import { VscArrowSmallLeft } from "react-icons/vsc";

const AddProduct = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("condition");
  const type = urlParams.get("type");
  console.log(type, "type");
  const productId = urlParams.get("_id");
  const navigate = useNavigate();

  if (type == '') {
    navigate(DashboardProductsPathUrl)
  }

  const { token } = useAppSelector((state) => state.auth.authentication);

  const getProductResult = productId
    ? useFetchSingleProductByIdQuery(productId, { skip: !productId })
    : null;

  const getResult = useGetSingleSellerDetailQuery(token);

  const [
    addNewProduct,
    {
      data: addproduct_data,
      isLoading: addproduct_isLoading,
      isError: addproduct_isError,
      error,
      isSuccess: addproduct_isSuccess,
    },
  ] = useAddNewProductMutation();

  const [
    updateProduct,
    {
      data: updateproduct_data,
      isLoading: updateproduct_isLoading,
      isError: updateproduct_isError,
      error: update_error,
      isSuccess: updateproduct_isSuccess,
    },
  ] = useUpdateProductMutation();



  const [
    addNewProductVideo,
    {
      isLoading: addVideo_isLoading,
      isError: addVideo_isError,
      error: addVideo_error,
      isSuccess: addVideo_isSuccess,
    },
  ] = useAddNewProductVideoMutation();

  const [
    addNewMultipleProductImages,
    {
      isLoading: addimages_isLoading,
      isError: addimages_isError,
      error: addimages_error,
      isSuccess: addimages_isSuccess,
    },
  ] = useAddNewMultipleProductImagesMutation();

  const isAdmin = checkIfAdmin(token);

  const [files, setFiles] = useState<File[]>([]);
  const [weight, setWeight] = useState("11");
  const [initialValue, setIntialValue] = useState({
    sku: [{ skuId: "" }],
    collectionId: "",
    collection: "",
    subCollectionCategory: "",
    subcategorycollectionId: "",
    subCollectionCategoryId: "",
    subcollectionId: "",
    productname: "",
    productshortDescription: "",
    productWeight: "1",
    brandId: "",
    defaultprice: 0,
    minimumprice: 0,
    maximumprice: 0,
    discount: 0,
    quantity: 1,
    vatcharges: 7.5,
    totalprice: 0,
    bulkprice: 0,
    acceptwarehouse: "",
    condition: `${myParam}`,
    waranty: false,
    returnpolicy: "7days",
    featureValues: [
      {
        id: "",
        featureId: "",
        featureUnitMeasureId: "",
        featureValue: "",
      },
    ],
    productimages: [{ file: null }, { file: null }],
    productthumbnail: [],
    productvideo: [],
  });

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: productId ? updateProductListingFormValidation() : addProductListingFormValidation(),
    onSubmit: (values) => {
      const newArrSku = [];
      for (let i = 0; i < values.sku.length; i++) {
        newArrSku.push(values.sku[i].skuId);
      }
      const data = {
        name: values.productname,
        shortDescription: values.productshortDescription,
        collection: values.collectionId,
        collectionId: values.collectionId,
        subCollectionCategory: values.subcategorycollectionId,
        subCollectionCategoryId: values.subcategorycollectionId,
        subCollectionId: values.subcollectionId,
        description: "no description",
        skus: newArrSku,
        price: parseFloat(values.defaultprice.toString()),
        minPrice: parseFloat(values.minimumprice.toString()),
        maxPrice: parseFloat(values.maximumprice.toString()),
        discount: values.discount,
        returnPolicy: values.returnpolicy,
        condition: values.condition,
        weight: parseFloat(values.productWeight.toString()),
        visibility: false,
        stock: values.quantity,
        brandId: values.brandId,
        featureValues: values.featureValues,
        storeInWarehouse: false,
        hasWarranty: values.waranty,
      };

      if (productId) {
        updateProduct({ productId, data, token })
      } else {
        addNewProduct({ data, token });
      }
    },
  });


  // const customResizeFile = async (file: File) => {
  //   const image = await resizeImageFile(file, 500, 500);
  //   return image;
  // };

  // useEffect(() => {
  //     if (formik.values.productimages.length > 0) {
  //       //product images file
  //       const imagesData = new FormData();
  //       formik.values.productimages.forEach((file: any, i) => {
  //         if (file.file) {
  //           customResizeFile(file.file[0]).then((response:any) =>{
  //               console.log(response,"result")
  //               imagesData.append("files", response);
  //           })
  //         }
  //       });
  //       console.log(imagesData.getAll("files"),"imagesData")
  //     }
  // },[formik.values.productimages]);

  useEffect(() => {
    //upload product images/video if product is success
    if (updateproduct_isSuccess) {
      const productIdUpdate = productId;
      const { productimages, productvideo } = formik.values;

      if (productimages.length > 0 && productimages[0].file !== null) {
        //product images file
        console.log(productimages[0].file)
        // alert(productimages.length)
        const imagesData = new FormData();
        formik.values.productimages.forEach((file: any, i) => {
          if (file.file) {
            imagesData.append("files", file.file[0]);
          }
        });

        addNewMultipleProductImages({
          data: imagesData,
          productId: productIdUpdate,
          token: token,
        });
      } else {
        const _slugProduct = slugifyText(
          updateproduct_data?.name.toLowerCase(),
          updateproduct_data?.id,
        );
        const host = window.location.protocol + "//" + window.location.host;
        window.location.href = host + '/preview-product/' + _slugProduct
        // navigate("/preview-product/" + _slugProduct);
      }

      if (productvideo.length > 0) {
        //product video file
        const vData = new FormData();
        formik.values.productvideo.map((file: any, i) => {
          vData.append("file", file);
        });
        addNewProductVideo({
          data: vData,
          productId: productId,
          token: token,
        });
      }
    }

    if (addproduct_isSuccess) {
      const productId = addproduct_data.id;
      const { productimages, productvideo } = formik.values;

      if (productimages.length > 0) {
        //product images file
        const imagesData = new FormData();
        formik.values.productimages.forEach((file: any, i) => {
          if (file.file) {
            imagesData.append("files", file.file[0]);
          }
        });

        addNewMultipleProductImages({
          data: imagesData,
          productId: productId,
          token: token,
        });
      }

      if (productvideo.length > 0) {
        //product video file
        const vData = new FormData();
        formik.values.productvideo.map((file: any, i) => {
          vData.append("file", file);
        });
        addNewProductVideo({
          data: vData,
          productId: productId,
          token: token,
        });
      }
    }
  }, [addproduct_isSuccess, updateproduct_isSuccess]);



  useEffect(() => {
    if (type === "edit") {
      renderDataToState();
    }
  }, [type, getProductResult]);

  useEffect(() => {
    if (addproduct_isError && error.status === "FETCH_ERROR") {
      toast.error(`Something went wrong`, {
        toastId: "product-upload-fetch-id-toast-error",
      });
    }

    if (addproduct_isError && error.status === 400) {
      toast.error(`${error.title}`, {
        toastId: "product-upload-error-400-id-toast-error",
      });
    }

    if (addVideo_error && addVideo_error.status === "FETCH_ERROR") {
      toast.error(`Something went wrong`, {
        toastId: "video-upload-fetch-error-toast-error",
      });
    }

    if (addVideo_isError && addVideo_error.status === 400) {
      toast.error(`${addVideo_error.title}`, {
        toastId: "video-upload-error-400-id-toast-error",
      });
    }

    if (addimages_isError && addimages_error.status === "FETCH_ERROR") {
      toast.error(`Something went wrong`, {
        toastId: "video-upload-fetch-error-toast-error",
      });
    }

    if (addimages_isError && addimages_error.status === 400) {
      toast.error(`${addimages_error.title}`, {
        toastId: "images-upload-error-400-id-toast-error",
      });
    }
  }, [
    addproduct_isError,
    error,
    addVideo_isError,
    addVideo_error,
    addimages_isError,
    addimages_error,
  ]);

  //edit function
  //edit function
  const renderDataToState = () => {
    const result = getProductResult?.data;

    if (result) {
      const product = result.product;
      console.log("editinggggggg", "editting")

      const skuItems: any = [];
      product.skus.map((item: any) => skuItems.push({ skuId: item }));

      const skus = product.skus ? product.skus.length > 0 ? skuItems : [{ skuId: "" }] : [{ skuId: "" }];

      setIntialValue({
        ...initialValue,
        maximumprice: product.maxPrice,
        minimumprice: product.minPrice,
        defaultprice: product.price,
        productname: product.name,
        productshortDescription: product.shortDescription,
        collectionId: product.collectionId ? product.collectionId : "",
        subCollectionCategoryId: product.subCollectionCategoryId ? product.subCollectionCategoryId : "",
        subcategorycollectionId: product.subCollectionCategoryId ? product.subCollectionCategoryId : "",
        productWeight: "1",
        brandId: product.brandId,
        quantity: product.stock,
        condition: product.condition,
        discount: product.discount,
        subcollectionId: product.subCollectionId ? product.subCollectionId : "",
        acceptwarehouse: product.storeInWarehouse,
        sku: skus
      });
    }
  };

  if (addimages_isSuccess) {
    let _slugProduct = '';
    if (type == 'edit') {
      _slugProduct = slugifyText(
        updateproduct_data?.name.toLowerCase(),
        updateproduct_data?.id,
      );
    } else {
      _slugProduct = slugifyText(
        addproduct_data?.name.toLowerCase(),
        addproduct_data?.id,
      );
    }

    if (addVideo_isSuccess) {
      // navigate("/preview-product/" + _slugProduct);
      const host = window.location.protocol + "//" + window.location.host;
      window.location.href = host + '/preview-product/' + _slugProduct
    } else if (!addVideo_isSuccess) {
      const host = window.location.protocol + "//" + window.location.host;
      window.location.href = host + '/preview-product/' + _slugProduct
    }
    // navigate(DashboardProductsPathUrl);
  }

  const renderSkus = () => {
    const rows = [];
    for (let i = 0; i < formik.values.quantity; i++) {
      rows.push(
        <div className="mb-3 mt-3">
          <Form.Group className=" formgroup__wrapper" controlId="formbulkprice">
            <Form.Label>Suplier SKU</Form.Label>
            <Form.Control type="text" placeholder="" />
          </Form.Group>
        </div>,
      );
    }
    return rows;
  };

  return (
    <Fragment>
      <HelmetHeader
        title={`${type === "edit" ? "Edit" : "Add"
          } Product - Dashboard - Ekenta.com`}
        description="Ekenta Add Product Page"
      />

      {getResult?.isLoading ? (
        <SpinLoader size={3} variant="dark" />
      ) : (
        <>
          {(getResult?.isSuccess &&
            getResult?.data.sellerRequestStatus === "APPROVED") ||
            isAdmin ? (
            <Fragment>
              <FormikProvider value={formik}>
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <div className="mt-0">
                    <Row className="row__col_d p-0 m-0">
                      <Col lg={12} sm={12} className="col__div p-0">
                        <PageTitle
                          title={
                            type === "edit" ? "Edit Product" : "Add Product"
                          }
                          subTitle={
                            <div>
                              <span
                                className="cursor-pointer d-flex w-25 align-items-center text-muted"
                                onClick={() => navigate(isAdmin ? AdminProductsPathUrl : DashboardProductsPathUrl)}>
                                <VscArrowSmallLeft />
                                Back to AllProducts
                              </span>
                              <p className="mt-4">
                                The most important feature. When
                                {type === "edit" ? "editing" : "adding"} product here,
                                do not ignore to fill the required fields completely
                                and follow the product adding rules.
                              </p>
                            </div>
                          }
                        />

                        <p></p>
                      </Col>
                    </Row>
                    <div className="collection_container w-100 mt-3 mb-2">
                      <Suspense
                        fallback={
                          <div className="text-center">
                            <Spinner animation="border" size="sm" />
                          </div>
                        }
                      >
                        <Multi_Step_Collections
                          initialValue={initialValue}
                          setIntialValue={setIntialValue}
                          values={formik.values}
                          setFieldValue={formik.setFieldValue}
                          errors={formik.errors}
                          touched={formik.touched}
                          handleBlur={formik.handleBlur}
                          setFieldTouched={formik.setFieldTouched}
                        />
                      </Suspense>
                      <br />

                      <div className="_coll_add_product_">
                        <Row>
                          <Col lg={6} md={12} sm={12} className="section__left">
                            <div className="group__div">
                              <h5 className="position-relative">
                                <span>Product Name</span>{" "}
                                <small className="text-danger">*</small>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={<Tooltip>Add product name </Tooltip>}
                                >
                                  <InfoIcon />
                                </OverlayTrigger>
                              </h5>
                              <Form.Group
                                className="mb-3 formgroup__wrapper"
                                controlId="formproductName"
                              >
                                <Form.Control
                                  type="text"
                                  name="productname"
                                  value={formik.values.productname}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder=""
                                // isInvalid={!!getIn(formik.errors, "productname")}
                                />

                                {getIn(formik.touched, "productname") &&
                                  getIn(formik.errors, "productname") ? (
                                  <Form.Control.Feedback
                                    className="invalid-fbk"
                                    type="invalid"
                                  >
                                    {formik.errors.productname}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Form.Group>
                            </div>

                            <div className="group__div">
                              <h5 className="position-relative">
                                <span>Description</span>{" "}
                                <small className="text-danger">*</small>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={<Tooltip>Add product name </Tooltip>}
                                >
                                  <InfoIcon />
                                </OverlayTrigger>
                              </h5>
                              <Form.Group
                                className="mb-3 formgroup__wrapper"
                                controlId="formproductName"
                              >
                                <Form.Control
                                  type="text"
                                  height={"400px"}
                                  name="productshortDescription"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.productshortDescription}
                                  placeholder=""
                                />
                                {getIn(
                                  formik.touched,
                                  "productshortDescription",
                                ) &&
                                  getIn(
                                    formik.errors,
                                    "productshortDescription",
                                  ) ? (
                                  <div className="invalid-fbk">
                                    {formik.errors.productshortDescription}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </div>

                            <div className="group__div mb-3 brands_select_div">
                              <Brands_Select_List
                                values={formik.values}
                                setFieldValue={formik.setFieldValue}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleBlur={formik.handleBlur}
                                setFieldTouched={formik.setFieldTouched}
                              />
                              {/* <div className="add___pl___o">
                                  <p>
                                    <span>
                                      <FiPlus />
                                      Add Custom Brand
                                    </span>
                                  </p>
                                </div> */}
                            </div>

                            <div className="group__div">
                              <h5>
                                <span>Product Features</span>{" "}
                                <small className="text-danger">*</small>
                              </h5>
                              <div className="mb-2 formgroup__wrapper mt-2">
                                <Suspense
                                  fallback={
                                    <div className="text-center">
                                      <Spinner animation="border" size="sm" />
                                    </div>
                                  }
                                >
                                  <FeatureValueComponent
                                    values={formik.values}
                                    setFieldValue={formik.setFieldValue}
                                    errors={formik.errors}
                                    handleBlur={formik.handleBlur}
                                  />
                                </Suspense>
                              </div>
                            </div>

                            <div className="group__div">
                              <h5 className="position-relative">
                                <span>Product Weight (kg)</span>{" "}
                                <small className="text-danger">*</small>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip>Add product weight </Tooltip>
                                  }
                                >
                                  <InfoIcon />
                                </OverlayTrigger>
                              </h5>
                              <Form.Group
                                className="mb-3 formgroup__wrapper"
                                controlId="formproductWeight"
                              >
                                <Form.Control
                                  className="mt-3"
                                  type="text"
                                  height={"400px"}
                                  name="productWeight"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.productWeight}
                                  placeholder="Enter the weight"
                                />
                                {getIn(formik.touched, "productWeight") &&
                                  getIn(formik.errors, "productWeight") ? (
                                  <Form.Control.Feedback
                                    className="invalid-fbk"
                                    type="invalid"
                                  >
                                    {formik.errors.productWeight}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Form.Group>
                            </div>
                          </Col>

                          <Col
                            lg={6}
                            md={12}
                            sm={12}
                            className="section__right"
                          >
                            <div className="group__div">
                              <h5>
                                <span>Product Images</span>{" "}
                                <small className="text-danger">*</small>
                                <InfoIcon />
                              </h5>
                              <Typography
                                as="span"
                                color="default"
                                size="small"
                                className="group_div__Span"
                                uiText>
                                Image must be maximum of {formatBytes(9500000)}
                              </Typography>
                              <div className="mt-0">
                                <Suspense
                                  fallback={
                                    <div className="text-center">
                                      <Spinner animation="border" size="sm" />
                                    </div>
                                  }
                                >
                                  <Multiple_ProductImages
                                    values={formik.values}
                                    errors={formik.errors}
                                    setFieldValue={formik.setFieldValue}
                                  />
                                  {getIn(formik.touched, "productimages") &&
                                    getIn(formik.errors, `productimages`) ? (
                                    <div className="invalid-fbk">
                                      {formik.errors.productimages}
                                    </div>
                                  ) : null}
                                </Suspense>
                              </div>
                            </div>

                            <div className="group__div">
                              <h5>
                                <span>Product Video</span>
                                <InfoIcon />
                              </h5>
                              <div className="mb-2 mt-0">
                                <Suspense
                                  fallback={
                                    <div className="text-center">
                                      <Spinner animation="border" size="sm" />
                                    </div>
                                  }
                                >
                                  <NewFileUploader
                                    maxSize={80000000}
                                    placeholder={videoplaceholder}
                                    requiredText="(Only a single 20 seconds video can be drop here)"
                                    acceptFileType="video/mp4,video/mov,video/x-m4v,video/*"
                                    acceptFileTypeList={["video"]}
                                    fileType="video"
                                    maxFiles={1}
                                    multiple={false}
                                    setFieldValue={formik.setFieldValue}
                                    setFieldTouched={formik.setFieldTouched}
                                    touched={formik.touched}
                                    errors={formik.errors}
                                    name={"productvideo"}
                                  />
                                </Suspense>
                              </div>
                            </div>

                            {/* <div className="group__div mt-4">
                                <h5>
                                  <span>Product Video Thumbnail</span>
                                  <InfoIcon />
                                </h5>
                                <div className="mb-2 mt-2">
                                  <Suspense fallback={<div className="text-center"><Spinner animation="border" size="sm" /></div>}>
                                    <NewFileUploader
                                      maxSize={10000000}
                                      placeholder={thumbnailimageplaceholder}
                                      requiredText="(Only a single image can be selected)"
                                      acceptFileType="image/*"
                                      acceptFileTypeList={["image"]}
                                      fileType="image"
                                      maxFiles={1}
                                      multiple={false}
                                      setFieldValue={formik.setFieldValue}
                                      errors={formik.errors}
                                      name={"productthumbnail"}
                                    />
                                  </Suspense>
                                </div>
                              </div> */}
                          </Col>
                        </Row>
                      </div>

                      <br />
                      <div className="_coll_p__d mt-1">
                        <Col>
                          <div className="group__div">
                            <h5>
                              <span>Price</span>{" "}
                              <small className="text-danger">*</small>
                              <InfoIcon />
                            </h5>
                            <div className="mt-4">
                              <Suspense
                                fallback={
                                  <div className="text-center">
                                    <Spinner animation="border" size="sm" />
                                  </div>
                                }
                              >
                                <Price_QuantityComponent
                                  values={formik.values}
                                  errors={formik.errors}
                                  touched={formik.touched}
                                  handleChange={formik.handleChange}
                                  handleBlur={formik.handleBlur}
                                  setFieldValue={formik.setFieldValue}
                                  setFieldTouched={formik.setFieldTouched}
                                />
                              </Suspense>
                            </div>
                          </div>
                        </Col>
                      </div>

                      <br />

                      <br />
                      <div className="_coll_p__d mt-1">
                        <Col>
                          <div className="group__div">
                            <h5>
                              <span>Other Product Info</span>{" "}
                              <small className="text-danger">*</small>
                              <InfoIcon />
                            </h5>
                            <div className="mt-2">
                              <Row>
                                <Col lg={6}>
                                  <div className="mt-3">
                                    <div className="formgroup__wrapper">
                                      <Form.Label className="">
                                        Condition
                                      </Form.Label>
                                    </div>
                                    <Form.Group as={Row} className="mb-1 ms-2 ">
                                      <Form.Check
                                        className=""
                                        type={"radio"}
                                        id={`ide-conditioncheck`}
                                      >
                                        <Form.Check.Input
                                          color="secondary"
                                          required
                                          checked={
                                            formik.values.condition === "new"
                                              ? true
                                              : false
                                          }
                                          name="condition"
                                          type={"radio"}
                                          onChange={(e: any) =>
                                            formik.setFieldValue(
                                              "condition",
                                              "new",
                                            )
                                          }
                                        />
                                        <Form.Check.Label>New</Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check
                                        type={"radio"}
                                        id={`used-conditioncheck`}
                                      >
                                        <Form.Check.Input
                                          color="secondary"
                                          required
                                          checked={
                                            formik.values.condition === "used"
                                              ? true
                                              : false
                                          }
                                          name="condition"
                                          type={"radio"}
                                          onChange={(e: any) =>
                                            formik.setFieldValue(
                                              "condition",
                                              "used",
                                            )
                                          }
                                        />
                                        <Form.Check.Label>
                                          Used
                                        </Form.Check.Label>
                                      </Form.Check>
                                      <Form.Check
                                        type={"radio"}
                                        id={`expiry-conditioncheck`}
                                      >
                                        <Form.Check.Input
                                          color="secondary"
                                          required
                                          checked={
                                            formik.values.condition ===
                                              "aboutexpiry"
                                              ? true
                                              : false
                                          }
                                          name="condition"
                                          type={"radio"}
                                          onChange={(e: any) =>
                                            formik.setFieldValue(
                                              "condition",
                                              "aboutexpiry",
                                            )
                                          }
                                        />
                                        <Form.Check.Label>
                                          Discounted
                                        </Form.Check.Label>
                                      </Form.Check>
                                      {getIn(formik.errors, "condition") ? (
                                        <div className="invalid-fbk">
                                          {formik.errors.condition}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <SkuComponent
                                    values={formik.values}
                                    errors={formik.errors}
                                    setFieldValue={formik.setFieldValue}
                                  />
                                  {
                                    // renderSkus()
                                  }
                                  {/* <div className="mb-3 mt-3">
                                      <Form.Group className=" formgroup__wrapper" controlId="formbulkprice">
                                        <Form.Label >Suplier SKU</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                      </Form.Group>
                                    </div> */}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3 mt-3">
                                    <Form.Group
                                      className=" formgroup__wrapper"
                                      controlId="formreturnpolicy"
                                    >
                                      <Form.Label>Return Policy </Form.Label>
                                      <NumberFormat
                                        customInput={Form.Control}
                                        value={formik.values.returnpolicy}
                                        prefix="days: "
                                        name="returnpolicy"
                                        disabled={true}
                                        isInvalid={
                                          !!getIn(formik.errors, "returnpolicy")
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-2 mt-3">
                                    <Form.Group
                                      className=" formgroup__wrapper"
                                      controlId="formbulkprice"
                                    >
                                      <Form.Label>Bulk Price </Form.Label>
                                      <NumberFormat
                                        customInput={Form.Control}
                                        value={formik.values.bulkprice}
                                        thousandSeparator={true}
                                        prefix="₦ "
                                        disabled={true}
                                      />
                                    </Form.Group>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </div>

                      <br />
                      <div className="_coll_p__d mt-1">
                        <Col>
                          <div className="group__div">
                            <h5>
                              <span>Waranty</span>
                              <InfoIcon />
                            </h5>
                            <div className="mt-3">
                              <Form.Group
                                as={Row}
                                className="mb-1 ms-2 position-relative"
                              >
                                <Form.Check
                                  type={"checkbox"}
                                  id={`wara-positive`}
                                >
                                  <Form.Check.Input
                                    color="secondary"
                                    required
                                    name="warantycheck"
                                    checked={
                                      formik.values.waranty === true
                                        ? true
                                        : false
                                    }
                                    type={"radio"}
                                    onChange={(e: any) =>
                                      formik.setFieldValue("waranty", true)
                                    }
                                  />
                                  <Form.Check.Label>Yes</Form.Check.Label>
                                </Form.Check>
                              </Form.Group>
                              <Form.Group
                                as={Row}
                                className="mb-1 ms-2 position-relative"
                              >
                                <Form.Check
                                  type={"checkbox"}
                                  id={`wara-negative`}
                                >
                                  <Form.Check.Input
                                    color="secondary"
                                    required
                                    name="warantycheck"
                                    checked={
                                      formik.values.waranty === false
                                        ? true
                                        : false
                                    }
                                    type={"radio"}
                                    onChange={(e: any) =>
                                      formik.setFieldValue("waranty", false)
                                    }
                                  />
                                  <Form.Check.Label>No</Form.Check.Label>
                                </Form.Check>
                              </Form.Group>
                            </div>
                          </div>
                        </Col>
                      </div>

                      <br />

                      <div className="d-flex align-items-center justify-content-start">
                        <Button className={`submit__btn`} type="submit">
                          {addproduct_isLoading ||
                            addVideo_isLoading ||
                            addimages_isLoading ? (
                            <div className="d-flex align-items-center justify-content-center">
                              <Spinner
                                size="sm"
                                animation="border"
                                variant="white"
                              />
                              <span className="ms-1 text-sm">
                                <small>Loading..</small>
                              </span>
                            </div>
                          ) : (
                            <>
                              <span>Submit</span>
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
              {addproduct_isLoading ||
                updateproduct_isLoading ||
                addVideo_isLoading ||
                addimages_isLoading ? (
                <Overlay>
                  <CustomLottieLoader
                    animationData={animationData}
                    size={200}
                  />
                </Overlay>
              ) : null}
            </Fragment>
          ) : (
            <UnAuthorizedCard />
          )}
        </>
      )}
    </Fragment>
  );
};

export default withDashboardLayout(AddProduct, "_add_product_");
