import { Roles } from "../routes/roleConfig";
import { DecodeTokenType } from "../interfaces/decode";
import { decodeToken } from "./decodeToken";
import slugify from 'slugify';
import { matchPath, NavigateFunction } from "react-router";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import Resizer from "react-image-file-resizer";


export const checkTokenValidityAndExpireAndReturnRoles = (token:string) => {
  if(!token) return false;
  const decoded_Token:DecodeTokenType | any  =  decodeToken(token);
  if (!decoded_Token || decoded_Token.exp * 1000 < Date.now()) return false;
  return decoded_Token.auth;
}


export const checkAuthorizationRoles = (actorRole:string[], permissions:string[]) => {
    if (!permissions) return true;
    return actorRole.some(item => permissions.includes(item));
  };


export const checkIsSellerOrAdminRoleOnboardingRoute = (role:string) => {
  const actorRole = role.indexOf(',') > -1 ? role.split(',') : [role];
  return actorRole.some(item => item == Roles.ROLE_SELLER || item == Roles.ROLE_ADMIN ? true : false );
}

export const setDiscountPercent = (discountPercentage: number) => {
  return `-${discountPercentage}`;
}

export const calculatePercentage = (maxPrice: number, minPrice: number) => {
  const percent = ((maxPrice - minPrice) / maxPrice) * 100;
  return `-${parseInt(percent.toFixed(2))}`;
};


export const calculateTotalPrice = (price: number, discountVal: number) => {
  return discountVal && discountVal > 0 ? Math.ceil(price - discountVal) : price;
}


// { change the sellanything nav item to dashboard for seller and admin}
export const checkIfAdminOrSellerRoleExist = (token:string) => {
   if(!token) return false;
   const role =  checkTokenValidityAndExpireAndReturnRoles(token);
   if(!role) return false;
    const actorRole = role.indexOf(',') > -1 ? role.split(',') : [role];
    return actorRole.some((item:string) => item == Roles.ROLE_SELLER || item == Roles.ROLE_ADMIN ? true : false);
}

export const checkIfAdmin = (token:string) => {
  if(!token) return false;
  const role =  checkTokenValidityAndExpireAndReturnRoles(token);
  if(!role) return false;
   const actorRole = role.indexOf(',') > -1 ? role.split(',') : [role];
   return actorRole.some((item:string) => item == Roles.ROLE_ADMIN ? true : false);
}

export const checkIfSeller = (token:string) => {
  if(!token) return false;
  const role =  checkTokenValidityAndExpireAndReturnRoles(token);
  if(!role) return false;
   const actorRole = role.indexOf(',') > -1 ? role.split(',') : [role];
   return actorRole.some((item:string) => item == Roles.ROLE_SELLER ? true : false);
}

export const getUserToken = () => {
  return ''
};

export const formatBytes = (bytes:number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


export const slugifyText = (text:string,id:number) => {
    const result = text + ' '+ id;
    const slugUrl = slugify(result);
    return slugUrl;
}


export const formatDurationInSeconds = (seconds:any) => {
  if(isNaN(seconds)) return null;
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`;
}

function pad (string:any) {
  return ('00' + string).slice(-2);
}

export const useMatchPath = (path:string,pathname:string) => {
  const route = matchPath(path, pathname);
  if(!route) return 'undefined';
  return route.pathname;
}


export const mapCartDataReturnQuantity = (cartData:any,productid:number) => {
  return cartData ? cartData.cartItems.find((item:any) => {
      if(item.product.id === productid) {
        return item;
      }
  }) : null
}


export const getNameInitial = (nameString:string) => {
  return nameString[0].substring(0, 1).toUpperCase();
}


export const logoutHandler = (
   navigate:NavigateFunction,
   dispatch:Dispatch<AnyAction>,
   signOut: () => void,
   loginPathUrl: string,
   removeUser:() => AnyAction,
   removeLive: () => AnyAction
   ) => {
  signOut();
  dispatch(removeUser());
  dispatch(removeLive());
  navigate(loginPathUrl);
}

export const resizeImageFile = (
     file:File,
     maxWidth:number,
     maxHeight:number,) => {

     return new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          maxWidth,
          maxHeight,
          "png",
          100,
          0,
          (uri) => {
            return resolve(uri);
          },
          "file"
        );
      });

    }
  
export const abbrevateNumber = (value:number) => {
      const suffixes = ["", "k", "m", "b","t"];
      const suffixNum = Math.floor((""+value).length/3);
      let shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000,suffixNum)) : value).toPrecision(2));
      if (shortValue % 1 != 0) {
          shortValue = parseInt(shortValue.toFixed(1))
        }
        return shortValue+suffixes[suffixNum];}


