/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect } from 'react';
import * as queryString from "query-string";
import { useAppSelector } from "@ekenta/redux/store/hook";
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import { Col, Form, ListGroup } from 'react-bootstrap';
import { CustomAlert, Typography } from '@ekenta/components';
import styles from "./BrandFilter.module.scss";
import classnames from 'classnames';
import { useGetAllBrandsQuery } from '@ekenta/redux/store/services/shopApi';
import { SearchFilterContext } from '@ekenta/context/SearchFilterContext';

const BrandFilter = () => {
    const queryParam = queryString.parse(window.location.search);

    const { configureFilterUrl } = useContext(SearchFilterContext);
    const { token } = useAppSelector((state: any) => state.auth.authentication);
    const getBrand = useGetAllBrandsQuery(token);
    const [brandIds, setBrandIds] = useState<any>([]);

    useEffect(() => {
        if (brandIds.length > 0) {
            const data = { key: "brands", value: brandIds.join(","), set: true };
            configureFilterUrl(data);
        } else if (brandIds.length <= 0 && queryParam.brands) {
            configureFilterUrl({ key: "brands", value: "", set: false });
        }
    }, [brandIds]);

    const handleFilter = (value: { checked: boolean; id: string }) => {
        if (value.checked) {
            setBrandIds((prevState: any) => [...prevState, value.id]);
        } else {
            const brandId_ = brandIds.filter((_id: any) => _id !== value.id);
            setBrandIds(brandId_);
        }
    }


    return (
        <>
            <Col className={styles.FeaturesGroup}>
                <div className={styles.FeaturesOverflow}>
                    <ListGroup className={styles.ListGroup}>
                        {getBrand?.isLoading ? "Loading..." : null}

                        {getBrand?.isSuccess && getBrand?.data.map(
                            (item: { id: number; name: string; }) => {
                                return (
                                    <ListGroup.Item
                                        key={item.id}
                                        as={'li'}
                                        className={classnames(styles.ListItem, "d-flex justify-content-between align-items-center")}>
                                        <Form.Check

                                            id={item.id.toString()}
                                            type="checkbox"
                                            className={styles.FormCheck}
                                            name={item.name}
                                            label={item.name}
                                            onChange={(e) => {
                                                handleFilter({
                                                    checked: e.target.checked,
                                                    id: item.id.toString()
                                                })
                                            }}
                                            style={{ width: "100%" }}
                                        />
                                    </ListGroup.Item>
                                );
                            },
                        )}
                        {getBrand.isError ? (
                            <CustomAlert
                                variant="danger"
                                show={true}
                                message={"Something went wrong, Try again"}
                            />
                        ) : null}
                    </ListGroup>
                </div>
            </Col>
        </>
    );
}


export default BrandFilter;