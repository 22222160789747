import { Card as BCard } from "react-bootstrap";
import CountUp from 'react-countup';

const Card = ({name,value,icon}:{name: string; value: number; icon?: JSX.Element}) => {

    return(
        <div className="UserManagement__Col">
          <BCard className="border-none">
            <BCard.Body >
                <div>
                   <h5 className="userManagement__heading-title">{name}</h5>
                   <h2 className="userManagement__heading-count display_lg_header">
                      <CountUp 
                        start={0.0}
                        end={value} />
                   </h2>
                </div>
                <span className="userManagement__icon">
                   {icon}
                </span>
            </BCard.Body>
          </BCard>
        </div>
    );
  } 

  export default Card;