import React from "react"
import ekentalogo from "@ekenta/assets/images/ekentanew.png";
import unplug_black from "@ekenta/assets/images/unplug_black.webp";

import "./ErrorUI.scss";

export const ErrorUI = () => {
  return (
    <>
    <div className="ErrorUI___Wrapper">
        <div className="ErrorBound__container"> 
            <div className="ErrorBound_Logo__wrapper">
                <img src={ekentalogo} alt="Ekenta"   />
            </div>
            <div className="ErrorBound_Content__wrapper">
                <div className="ErrorBound__unplugged">
                    <img src={unplug_black} alt="" />
                </div>
                
                <h2 className="lg_header">
                    <span>
                         This site is currently 
                    </span>
                    <span>
                        down for maintenance
                    </span>
                </h2>
                <p>
                    <span> We apologize for inconvenience caused.</span>
                    <span>We&rsquo;re almost done.</span>
                </p>
            </div>
         
        </div>

    </div>
    </>
  )
}
