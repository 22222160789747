import React from 'react'
import { Card, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { BsEye } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import EmptyOrder from '../../../components/Box/EmptyOrder'
import TableComponent from '../../../components/Dashboard/components/TableComponent'
import DashboardComponent from '../component/DashboardComponent'
import OrderSkeletonLoader from '../../../components/SkeletonLoader/Account/OrderSkeletonLoader'
import DashboardLayout from '../../../hoc/Layout/Dashboard-Layout'
import { useAppSelector } from '../../../redux/store/hook'
import { useFetchAdminAllReturnRequestQuery } from '../../../redux/store/services/adminSellerApi'
import { useFetchSellerReturnRequestQuery } from '../../../redux/store/services/vendorProductApi'
import { checkIfAdmin } from '../../../utils'

export default function ReturnsRequested({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }: any) {

  const { token } = useAppSelector(state => state.auth.authentication);
  const isAdmin = checkIfAdmin(token)
  const returnRequest = isAdmin ? useFetchAdminAllReturnRequestQuery(token) : useFetchSellerReturnRequestQuery(token)

  const columns = [
    {
      Header: 'Order',
      accessor: 'orderId',
      sortType: 'basic',
    },
    {
      Header: 'Product',
      accessor: 'product',
      sortType: 'basic',
    },
    {
      Header: 'Customer',
      accessor: 'customer',
      sortType: 'basic',
    },
    {
      Header: 'Quality Returned',
      accessor: 'returned',
      sortType: 'basic',
    },
    {
      Header: 'Order Number',
      accessor: 'order',
      sortType: 'basic',
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortType: 'basic',
    },
    {
      Header: 'Date',
      accessor: 'date',
      sortType: 'basic',
    },
    {
      Header: 'Action',
      accessor: 'view',
      sortType: 'basic',
    },
  ]

  const livedata = [
    {
      "adminNotes": "string",
      "adminPersonnel": "string",
      "comment": "string",
      "customerEmail": "string",
      "id": 0,
      "orderItem": {
        "id": 0,
        "price": 0,
        "product": {
          "brand": {
            "description": "string",
            "descriptionMetaKeywords": "string",
            "id": 0,
            "name": "string",
            "nameMetaKeywords": "string",
            "visibility": true
          },
          "brandId": 0,
          "condition": "string",
          "createdBy": "string",
          "createdDate": "2022-04-20T11:18:59.295Z",
          "description": "string",
          "descriptionMetaKeywords": "string",
          "discount": 0,
          "featureValues": [
            {
              "featureId": 0,
              "featureUnitMeasureId": 0,
              "featureValue": "string",
              "id": 0,
              "productId": 0
            }
          ],
          "hasWarranty": true,
          "id": 0,
          "imageUrls": [
            "string"
          ],
          "lastModifiedBy": "string",
          "lastModifiedDate": "2022-04-20T11:18:59.295Z",
          "maxPrice": 0,
          "minPrice": 0,
          "name": "string",
          "nameMetaKeywords": "string",
          "price": 0,
          "returnPolicy": "string",
          "shortDescription": "string",
          "skus": [
            "string"
          ],
          "stock": 0,
          "storeInWarehouse": true,

        },
        "quantity": 0
      },
      "productReturnAction": "string",
      "productReturnReason": "string",
      "productReturnStatus": "APPROVED",
      "returnQuantity": 0
    }
  ]

  const recalibrate = (data: any) => {
    return data.map((items: any, i: any) => ({
      ...items,
      order: items.orderItem.id,
      product: items.orderItem.product.name,
      customer: items.customerEmail,
      returned: items.returnQuantity,
      id: items.id,
      totalamount: 'NGN ' + new Intl.NumberFormat().format(items.totalOrderAmount),
      status: items.productReturnStatus,
      date: items.productReturnAction,
      view: <Link to={`/vendor/dashboard/returns-requested/${items.id}`}><BsEye size={24} color={'#E04826'} /></Link>
    }))
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Dashboard - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Returns Requested Page" />
        <meta name="description"
          content="Ekenta Returns Requested  Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <DashboardComponent
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <Col className=" col-lg-11 mx-auto _listing_product_">
          <div className="mt-0">

            <Col className="mt-5">
              <Card>
                <Card.Body>
                  <div>Returns Request</div>
                  {
                    !returnRequest.isSuccess ? <OrderSkeletonLoader /> : returnRequest.data.content.length > 0 ? <TableComponent columns={columns} data={recalibrate(returnRequest.data.content)} pageSizeno={5} /> : <EmptyOrder orderType='Return Request' />
                  }
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Col>
      </DashboardComponent>
    </DashboardLayout>
  )
}
