import { getIn, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { VscAccount } from 'react-icons/vsc';
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hook';
import { setNickname } from '../../../../redux/store/states/reducerSlice/livecomSlice';
import { SetNicknameFormValidation } from '../../../../validations/schemas/live-commerce.schema';

interface PromptDialogPropsType {
    show:boolean;
    onClose: () => void;
}


export default function PromptDialog(props:PromptDialogPropsType) {
    const { account,ek_isAuth,token } = useAppSelector(state => state.auth.authentication);
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false)

    const [initialValues, setInitialValues] = useState({
        nickname: ''
    })

    useEffect(() => {
      
        if(ek_isAuth && token){
            setInitialValues({
                ...initialValues,
                nickname: account.user.firstName
            })
        }else{
            setInitialValues({
                ...initialValues,
                nickname: 'anonymous'
            })
        }

    }, [])
    

    const formik = useFormik({
        initialValues:initialValues,
        enableReinitialize: true,
        validationSchema: SetNicknameFormValidation(),
        onSubmit: (values) => {
            const _nickname = values.nickname;

            if(_nickname){
                dispatch(setNickname({nickname:_nickname.toLowerCase()}))
            }

            if(ek_isAuth && !_nickname){
                dispatch(setNickname({nickname:account.user.firstName.toLowerCase()}))
            }else if(!ek_isAuth && !_nickname){
                dispatch(setNickname({nickname:'anonymous'}))
            }
            props.onClose();
        }
    });


  return (
        <Modal 
            dialogClassName="prompt__dialog"
            show={props.show} 
            centered
            contentClassName="w-auto"
        >
        <Modal.Header closeButton>
        <Modal.Title>Set Nickname</Modal.Title>
        </Modal.Header>
           <Form  noValidate onSubmit={formik.handleSubmit}>
            <Modal.Body>
                <div className="mb-3 context_spa">
                    Use 
                        {ek_isAuth && account.user ? (<i className="text-muted">{' ' + account.user.firstName + ' '}</i>) : ' anonymous '}
                    as your nickname or {'  '} change it.
                </div>
                <div>
                    <Form.Group className="mb-3 form--group">
                        <Form.Control
                            className="form-input-contol"
                            type="text"
                            placeholder="Enter nickname"
                            autoComplete="none" 
                            name="nickname"
                            value={formik.values.nickname}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            isInvalid={!!getIn(formik.errors,'nickname')}
                            max={20}
                            autoFocus
                        />
                        { formik.errors.nickname ? (
                            <div className="invalid-fbk">
                            {formik.errors.nickname}
                        </div>
                        ): null}

                        <span className="user_icon">
                            <VscAccount fontSize={15} />
                        </span>
                        <span className="msg__size">{formik.values.nickname.length}/20</span>
                    </Form.Group>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose} className="cancel_btn button__btn" variant="">Cancel</Button>
                <Button type="submit" className="confirm_btn button__btn" variant="primary">Confirm</Button>
            </Modal.Footer>
            </Form>
    
    </Modal>
  )
}
