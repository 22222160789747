/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useState } from 'react';
import { BASE_URL } from '@ekenta/constants';
import axios from 'axios';
import { toast } from "react-toastify";
import { useEffect } from 'react';
import * as queryString from "query-string";
import { useLocation, useNavigate, createSearchParams, useParams, useSearchParams } from 'react-router-dom';


interface SearchFilterContextPropsType {
    isLoading: boolean;
    pageNo: number;
    setPageNo: any;
    filterData: any;
    configureFilterUrl: any;
}


const defaultState = {
    isLoading: false,
    pageNo: 1,
    filterData: null,
    setPageNo: () => false,
    configureFilterUrl: () => false,
};

export const SearchFilterContext = createContext<SearchFilterContextPropsType>(defaultState);

export const SearchFilterProvider = ({ children }: any) => {
    const navigate = useNavigate();
    const params = useParams();
    const { pathname } = useLocation();
    const query = queryString.parse(window.location.search);
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [pageNo, setPageNo] = useState(0);
    const [filterData, setFilterData] = useState(null);
    const queryKeys = ["discounted", "min_max", "sort", "brands", "rating", "in_stock"];


    const allData = {
        maxPrice: null,
        minPrice: null,
        stock: 0,
        keyword: null,
        merchantOwnerId: null,
        minAverageRatings: null,
        minDiscountPercentage: null,
        sort: false,
        brandIdList: [],
    };

    useEffect(() => {
        const list = paramsToObject(searchParams.entries());
        const isMatch = queryKeys.some(key => list.includes(key));
        if (isMatch) {
            const data = handleQueryParams();

            handleRequest(data);
        } else {
            setFilterData(null)
        }
    }, [searchParams]);



    const configureFilterUrl = useCallback((data: { key: string; value: any; set: boolean }) => {
        if (data.set) {
            searchParams.set(data.key, data.value);
        } else {
            searchParams.delete(data.key);
        }
        navigate({
            pathname: pathname,
            search: `?${createSearchParams(searchParams)}`,
        });
    }, [searchParams]);


    function paramsToObject(entries: any) {
        const result: any = []
        for (const [key] of entries) { // each 'entry' is a [key, value] tupple
            result.push(key);
        }
        return result;
    }


    function handleQueryParams() {
        const data: any = { ...allData };
        if (query.brands) {
            data["brandIdList"] = query.brands.split(",");
        } else {
            data["brandIdList"] = []
        }
        if (query.in_stock) {
            data["stock"] = 1;
        } else {
            data["stock"] = 0
        }

        if (query.discounted) {
            data["minDiscountPercentage"] = 1
        } else {
            data["minDiscountPercentage"] = null
        }
        // if (query.sort) {
        //     data["sort"] = query.sort;
        // } else {
        //     data["sort"] = "popular"
        // }

        if (query.rating) {
            const val = `${query.rating.toString()}.00`
            data["minAverageRatings"] = parseFloat(val)
        } else {
            data["minAverageRatings"] = null
        }

        if (query.min_max) {
            data["minPrice"] = parseInt(query.min_max.split("-")[0]);
            data["maxPrice"] = parseInt(query.min_max.split("-")[1]);
        } else {
            data["minPrice"] = null;
            data["maxPrice"] = null;
        }

        if (query.q) {
            data["keyword"] = query.q;
        } else {
            data["keyword"] = null;
        }
        const merchantId = setMerchantId();
        data["merchantOwnerId"] = merchantId
        return data;
    }

    const setMerchantId = useCallback(() => {
        const merchantId = params.id ? parseInt(params.id) : null
        return merchantId
    }, [params]);


    const handleRequest = useCallback((data: any) => {
        setIsLoading(true);
        const config = {
            method: 'POST',
            url: `${BASE_URL}/api/auth/product/get-products-by-multiple-filter`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
                setFilterData(response.data);
            })
            .catch(function () {
                setIsLoading(false);
                toast.error(`Something went wrong`, {
                    toastId: "-filter-error-400-id-toast-error",
                });
            });
    }, []);


    return (
        <SearchFilterContext.Provider value={{
            isLoading,
            filterData,
            pageNo,
            setPageNo,
            configureFilterUrl
        }}>
            {children}
        </SearchFilterContext.Provider>
    )


}