import React from 'react';

export const InfoIcon = () => {
  return (
    <>
      <svg
        version="1.1"
        id="Info_circled"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        enableBackground="new 0 0 20 20"
      >
        <path
          fill=""
          d="M10,0.4c-5.303,0-9.601,4.298-9.601,9.6c0,5.303,4.298,9.601,9.601,9.601c5.301,0,9.6-4.298,9.6-9.601
			C19.6,4.698,15.301,0.4,10,0.4z M10.896,3.866c0.936,0,1.211,0.543,1.211,1.164c0,0.775-0.62,1.492-1.679,1.492
			c-0.886,0-1.308-0.445-1.282-1.182C9.146,4.719,9.665,3.866,10.896,3.866z M8.498,15.75c-0.64,0-1.107-0.389-0.66-2.094l0.733-3.025
			c0.127-0.484,0.148-0.678,0-0.678c-0.191,0-1.022,0.334-1.512,0.664L6.74,10.094c1.555-1.299,3.343-2.061,4.108-2.061
			c0.64,0,0.746,0.756,0.427,1.92l-0.84,3.18c-0.149,0.562-0.085,0.756,0.064,0.756c0.192,0,0.82-0.232,1.438-0.719l0.362,0.486
			C10.786,15.168,9.137,15.75,8.498,15.75z"
        />
      </svg>
    </>
  );
};

export const FavoriteIcon = () => (
  <svg
    className="favorite"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4 5.25C5.61914 5.25 3.25 7.3293 3.25 10.0298C3.25 11.8927 4.12235 13.4612 5.27849 14.7604C6.43066 16.0552 7.91714 17.142 9.26097 18.0516L11.5796 19.6211C11.8335 19.793 12.1665 19.793 12.4204 19.6211L14.739 18.0516C16.0829 17.142 17.5693 16.0552 18.7215 14.7604C19.8777 13.4612 20.75 11.8927 20.75 10.0298C20.75 7.3293 18.3809 5.25 15.6 5.25C14.1665 5.25 12.9052 5.92214 12 6.79183C11.0948 5.92214 9.83347 5.25 8.4 5.25Z"
      fill=""
    />
  </svg>
);

export const PlayIcon = () => (
  <svg className="ek-icon play" viewBox="-4 0 22 22">
    <path
      d="M17.446 11.966L1.701 21.348A1.125 1.125 0 0 1 0 20.38V1.618A1.125 1.125 0 0 1 1.7.652l15.746 9.381a1.125 1.125 0 0 1 0 1.933z"
      fill="#fff"
      fillRule="evenodd"
    ></path>
  </svg>
);

export const Spinner = ({ color = 'currentColor' }: { color: string | undefined }) => (
  <svg viewBox="0 0 24 24" className="Spinner-svg" data-test-id="loading">
    <g transform="translate(1 1)" fillRule="nonzero" fill="none">
      <circle cx="11" cy="11" r="11"></circle>
      <path
        d="M10.998 22a.846.846 0 0 1 0-1.692 9.308 9.308 0 0 0 0-18.616 9.286 9.286 0 0 0-7.205 3.416.846.846 0 1 1-1.31-1.072A10.978 10.978 0 0 1 10.998 0c6.075 0 11 4.925 11 11s-4.925 11-11 11z"
        fill={color}
      ></path>
    </g>
  </svg>
);

export const CirclePlayIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10 16.5L16 12L10 7.5V16.5ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
      fill=""
    />
  </svg>
);

export const RefreshIcon = (color: string) => (
  <svg className="ek-icon refresh" viewBox="0 0 1024 1024">
    <path
      fill={color}
      d="M512 64C264.64 64 64 264.576 64 512c0 247.36 200.64 448 448 448 247.424 0 448-200.64 448-448C960 264.576 759.424 64 512 64zM511.936 896.768C299.52 896.768 127.232 724.48 127.232 512S299.52 127.232 511.936 127.232c212.48 0 384.704 172.224 384.704 384.768S724.416 896.768 511.936 896.768zM863.168 581.568c-10.816 10.688-28.096 10.688-38.848 0l-52.544-52.608c-8.896 136.064-121.344 244.48-259.84 244.48-73.152 0-143.36-30.912-192.704-84.8-11.2-12.16-10.304-31.04 1.856-42.24 12.032-11.2 31.104-10.496 42.24 1.856 38.08 41.472 92.16 65.344 148.544 65.344 104.128 0 189.12-79.616 199.552-180.928l-48.832 48.96c-10.752 10.688-28.096 10.688-38.784 0-10.688-10.752-10.688-28.096 0-38.784l100.16-100.224c5.248-5.184 12.16-8.064 19.456-8.064s14.208 2.88 19.328 8.064l100.352 100.224C873.856 553.408 873.856 570.752 863.168 581.568zM400.128 481.152 299.968 581.376C294.656 586.56 287.744 589.44 280.512 589.44S266.304 586.56 261.184 581.376L160.832 481.152c-10.688-10.688-10.688-28.032 0-38.784 10.816-10.688 28.096-10.688 38.848 0l52.544 52.672c8.96-136.128 121.344-244.416 259.712-244.416 73.728 0 144.512 31.36 193.984 86.144 11.136 12.224 10.112 31.168-2.112 42.24-12.032 11.136-31.104 10.304-42.24-2.112C623.36 334.656 568.832 310.464 511.936 310.464c-104.064 0-188.928 79.552-199.424 180.8l48.832-48.96c10.752-10.688 28.096-10.688 38.784 0C410.816 453.056 410.816 470.464 400.128 481.152z"
    ></path>
  </svg>
);

export const CircleIcon = () => (
  <svg className="ek-icon circle" viewBox="0 0 60 60">
    <circle
      cx="30"
      cy="30"
      r="28"
      stroke="#fff"
      strokeWidth="2"
      strokeOpacity="0.8"
      fill="#000"
      fillOpacity="0.4"
    ></circle>
  </svg>
);

export const ShoppingCartIcon = (props: { className?: string }) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1477 5.25H5.33514L4.15497 3.1346C4.0225 2.89715 3.7719 2.75 3.5 2.75H2C1.58579 2.75 1.25 3.08579 1.25 3.5C1.25 3.91421 1.58579 4.25 2 4.25H3.0596L4.22429 6.33765L6.91037 12.2809L6.91312 12.2869L7.14971 12.8104L4.45287 15.687C4.25895 15.8939 4.19825 16.1924 4.29599 16.4585C4.39372 16.7247 4.63317 16.913 4.91486 16.9452L7.37299 17.2261C10.4477 17.5775 13.5524 17.5775 16.627 17.2261L19.0852 16.9452C19.4967 16.8981 19.7922 16.5264 19.7452 16.1148C19.6981 15.7033 19.3264 15.4078 18.9149 15.4549L16.4567 15.7358C13.4952 16.0742 10.5048 16.0742 7.54331 15.7358L6.56779 15.6243L8.54717 13.513C8.56632 13.4925 8.5841 13.4713 8.60052 13.4494L9.35334 13.5474C10.4083 13.6847 11.4746 13.7116 12.5351 13.6277C15.0086 13.4321 17.301 12.2551 18.9015 10.3591L19.4795 9.67425C19.499 9.65125 19.517 9.62711 19.5335 9.60194L20.6109 7.96009C21.3745 6.79633 20.5397 5.25 19.1477 5.25ZM8.65627 11.944C8.49086 11.9225 8.34823 11.8175 8.27858 11.666L8.27725 11.6631L6.05674 6.75H19.1477C19.3466 6.75 19.4658 6.9709 19.3567 7.13716L18.3042 8.74123L17.7552 9.39152C16.4132 10.9814 14.4909 11.9683 12.4169 12.1324C11.4603 12.208 10.4984 12.1837 9.54688 12.0599L8.65627 11.944Z"
      fill=""
    />
    <path
      d="M6.5 18.5C5.67157 18.5 5 19.1716 5 20C5 20.8284 5.67157 21.5 6.5 21.5C7.32843 21.5 8 20.8284 8 20C8 19.1716 7.32843 18.5 6.5 18.5Z"
      fill=""
    />
    <path
      d="M16 20C16 19.1716 16.6716 18.5 17.5 18.5C18.3284 18.5 19 19.1716 19 20C19 20.8284 18.3284 21.5 17.5 21.5C16.6716 21.5 16 20.8284 16 20Z"
      fill=""
    />
  </svg>
);

export const LocationIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25003 10C7.25003 7.37665 9.37667 5.25 12 5.25C14.6234 5.25 16.75 7.37665 16.75 10C16.75 12.6234 14.6234 14.75 12 14.75C9.37667 14.75 7.25003 12.6234 7.25003 10ZM12 6.75C10.2051 6.75 8.75003 8.20507 8.75003 10C8.75003 11.7949 10.2051 13.25 12 13.25C13.795 13.25 15.25 11.7949 15.25 10C15.25 8.20507 13.795 6.75 12 6.75Z"
      fill=""
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52439 8.85685C3.87872 4.55824 7.47087 1.25 11.7841 1.25H12.216C16.5292 1.25 20.1213 4.55824 20.4757 8.85685C20.666 11.166 19.9527 13.4589 18.4861 15.2526L13.693 21.1144C12.818 22.1845 11.1821 22.1845 10.307 21.1144L5.51399 15.2526C4.04733 13.4589 3.33405 11.166 3.52439 8.85685ZM11.7841 2.75C8.25152 2.75 5.30952 5.45948 5.01932 8.98008C4.8609 10.9019 5.45455 12.8102 6.67521 14.3031L11.4683 20.1649C11.7431 20.501 12.2569 20.501 12.5318 20.1649L17.3248 14.3031C18.5455 12.8102 19.1391 10.9019 18.9807 8.98008C18.6905 5.45948 15.7485 2.75 12.216 2.75H11.7841Z"
      fill=""
    />
  </svg>
);

export const WalletIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 12C15.5 11.1716 16.1716 10.5 17 10.5C17.8284 10.5 18.5 11.1716 18.5 12C18.5 12.8284 17.8284 13.5 17 13.5C16.1716 13.5 15.5 12.8284 15.5 12Z"
      fill=""
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4413 6.67402C19.7836 5.12836 18.3302 4.01723 16.6007 3.83523L15.9488 3.76664C12.6565 3.42018 9.33575 3.44303 6.04851 3.83475L5.61657 3.88622C3.94777 4.08508 2.62552 5.38889 2.40324 7.05473C1.96528 10.337 1.96528 13.663 2.40324 16.9453C2.62552 18.6111 3.94777 19.9149 5.61657 20.1138L6.04851 20.1653C9.33575 20.557 12.6565 20.5798 15.9488 20.2334L16.6007 20.1648C18.3302 19.9828 19.7836 18.8717 20.4413 17.326C21.4806 17.0166 22.2738 16.1156 22.404 15.0024C22.6373 13.0076 22.6373 10.9924 22.404 8.99764C22.2738 7.88442 21.4806 6.98344 20.4413 6.67402ZM15.7918 5.2584C12.6107 4.92365 9.40218 4.94573 6.226 5.32421L5.79406 5.37568C4.80524 5.49351 4.02177 6.26606 3.89007 7.25312C3.46967 10.4038 3.46967 13.5963 3.89007 16.7469C4.02177 17.734 4.80525 18.5065 5.79406 18.6243L6.226 18.6758C9.40218 19.0543 12.6107 19.0764 15.7918 18.7416L16.4437 18.673C17.2942 18.5835 18.0468 18.1643 18.5683 17.542C17.0602 17.6299 15.532 17.5906 14.0417 17.4241C12.7724 17.2822 11.7458 16.2826 11.5961 15.0024C11.3628 13.0076 11.3628 10.9924 11.5961 8.99764C11.7458 7.71738 12.7724 6.71784 14.0417 6.57597C15.532 6.40942 17.0602 6.37012 18.5683 6.45806C18.0468 5.83574 17.2942 5.4165 16.4437 5.327L15.7918 5.2584ZM19.2774 8.01471C19.278 8.01855 19.2786 8.02239 19.2792 8.02623L19.2852 8.06511L19.4839 8.03426C19.5867 8.04444 19.6893 8.05524 19.7917 8.06669C20.3791 8.13234 20.8468 8.59648 20.9141 9.17189C21.1339 11.0509 21.1339 12.9491 20.9141 14.8281C20.8468 15.4035 20.3791 15.8677 19.7917 15.9333C19.6893 15.9448 19.5866 15.9556 19.4839 15.9658L19.2852 15.9349L19.2792 15.9738C19.2786 15.9776 19.278 15.9815 19.2774 15.9853C17.5987 16.1372 15.8772 16.1199 14.2084 15.9333C13.621 15.8677 13.1532 15.4035 13.0859 14.8281C12.8661 12.9491 12.8661 11.0509 13.0859 9.17189C13.1532 8.59648 13.621 8.13234 14.2084 8.06669C15.8772 7.88017 17.5987 7.86285 19.2774 8.01471Z"
      fill=""
    />
  </svg>
);

export const LogoutIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 18.25C11.5858 18.25 11.25 18.5858 11.25 19C11.25 19.4142 11.5858 19.75 12 19.75H18C18.9665 19.75 19.75 18.9665 19.75 18V6C19.75 5.0335 18.9665 4.25 18 4.25H12C11.5858 4.25 11.25 4.58579 11.25 5C11.25 5.41421 11.5858 5.75 12 5.75L18 5.75C18.1381 5.75 18.25 5.86193 18.25 6L18.25 18C18.25 18.1381 18.1381 18.25 18 18.25H12Z"
      fill=""
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5031 14.3652C15.1934 14.3652 15.7531 13.8056 15.7531 13.1152V10.8747C15.7531 10.1843 15.1934 9.6247 14.5031 9.6247L9.89048 9.6247C9.88396 9.55128 9.87713 9.47787 9.87 9.40448L9.81597 8.8486C9.73354 8.00049 8.83294 7.49258 8.06451 7.86084C6.43029 8.64403 4.95085 9.71578 3.69736 11.0245L3.59816 11.1281C3.13395 11.6128 3.13395 12.3771 3.59815 12.8618L3.69736 12.9654C4.95085 14.2741 6.43029 15.3459 8.06451 16.1291C8.83293 16.4973 9.73354 15.9894 9.81597 15.1413L9.87 14.5854C9.87713 14.512 9.88396 14.4386 9.89048 14.3652H14.5031ZM9.19511 12.8652C8.92874 12.8652 8.69326 13.0045 8.56008 13.216C8.49523 13.319 8.45464 13.4391 8.44656 13.5685C8.42842 13.8594 8.40524 14.15 8.37703 14.4403L8.36135 14.6017C7.3253 14.0677 6.36316 13.4028 5.49838 12.6239C5.27402 12.4218 5.05622 12.2121 4.84538 11.995C5.86892 10.9409 7.05651 10.0607 8.36135 9.38824L8.37703 9.54959C8.40524 9.83987 8.42842 10.1305 8.44656 10.4214C8.47122 10.8167 8.79902 11.1247 9.19511 11.1247H14.2531V12.8652H9.19511Z"
      fill=""
    />
  </svg>
);

export const InfoIcon2 = (props: { className: string }) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z"
      fill=""
    />
    <path
      d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
      fill=""
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12ZM12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75Z"
      fill=""
    />
  </svg>
);

export const SendIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2428 12.4371C21.4016 12.3489 21.5 12.1816 21.5 12C21.5 11.8184 21.4016 11.6511 21.2428 11.5629L18.9605 10.295C14.464 7.79689 9.72391 5.76488 4.81421 4.2306L4.14914 4.02276C3.99732 3.97532 3.83198 4.00294 3.70383 4.09716C3.57568 4.19138 3.5 4.34094 3.5 4.5V10.25C3.5 10.5159 3.70816 10.7353 3.97372 10.7493L4.98336 10.8025C7.44497 10.932 9.89156 11.2659 12.2979 11.8006L12.5362 11.8536C12.5892 11.8654 12.6122 11.887 12.625 11.9042C12.6411 11.926 12.6536 11.9594 12.6536 12C12.6536 12.0406 12.6411 12.0741 12.625 12.0958C12.6122 12.113 12.5892 12.1347 12.5362 12.1464L12.2979 12.1994C9.89157 12.7341 7.44496 13.068 4.98334 13.1976L3.97372 13.2507C3.70816 13.2647 3.5 13.4841 3.5 13.75V19.5C3.5 19.6591 3.57568 19.8086 3.70383 19.9029C3.83198 19.9971 3.99732 20.0247 4.14914 19.9772L4.81422 19.7694C9.72391 18.2351 14.464 16.2031 18.9605 13.705L21.2428 12.4371Z"
      fill=""
    />
  </svg>
);

export const LiveIcon = (props: { className: string }) => (
  <svg
    className={props.className}
    enableBackground="new 0 0 100 100"
    version="1.1"
    viewBox="0 0 100 100"
  >
    <g display="none" fill="none" strokeMiterlimit="10" strokeWidth="3">
      <g display="inline" stroke="#00AEEF">
        <line x1="71.097" x2="71.097" y1="-121.5" y2="-3" />
        <line y1="-121.5" y2="-3" />
        <line x1="15.798" x2="15.798" y1="-121.5" y2="-3" />
        <line x1="7.902" x2="7.902" y1="-121.5" y2="-3" />
        <line x1="23.701" x2="23.701" y1="-121.5" y2="-3" />
        <line x1="31.604" x2="31.604" y1="-121.5" y2="-3" />
        <line x1="39.5" x2="39.5" y1="-121.5" y2="-3" />
        <line x1="47.403" x2="47.403" y1="-121.5" y2="-3" />
        <line x1="55.298" x2="55.298" y1="-121.5" y2="-3" />
        <line x1="63.201" x2="63.201" y1="-121.5" y2="-3" />
        <line x1="78.999" x2="78.999" y1="-121.5" y2="-3" />
        <line x1="86.895" x2="86.895" y1="-121.5" y2="-3" />
        <line x1="94.798" x2="94.798" y1="-121.5" y2="-3" />
        <line x1="102.7" x2="102.7" y1="-121.5" y2="-3" />
        <line x1="110.6" x2="110.6" y1="-121.5" y2="-3" />
        <line x1="118.5" x2="118.5" y1="-121.5" y2="-3" />
        <line x1="118.5" y1="-121.5" y2="-121.5" />
        <line x1="118.5" y1="-113.59" y2="-113.59" />
        <line x1="118.5" y1="-105.7" y2="-105.7" />
        <line x1="118.5" y1="-97.796" y2="-97.796" />
        <line x1="118.5" y1="-89.901" y2="-89.901" />
        <line x1="118.5" y1="-81.998" y2="-81.998" />
        <line x1="118.5" y1="-74.094" y2="-74.094" />
        <line x1="118.5" y1="-66.199" y2="-66.199" />
        <line x1="118.5" y1="-58.298" y2="-58.298" />
        <line x1="118.5" y1="-50.401" y2="-50.401" />
        <line x1="118.5" y1="-42.5" y2="-42.5" />
        <line x1="118.5" y1="-34.603" y2="-34.603" />
        <line x1="118.5" y1="-26.701" y2="-26.701" />
        <line x1="118.5" y1="-18.798" y2="-18.798" />
        <line x1="118.5" y1="-10.903" y2="-10.903" />
        <line x1="118.5" y1="-3" y2="-3" />
      </g>
      <line
        x2="118.5"
        y1="-113.59"
        y2="-113.59"
        display="inline"
        stroke="#ED1C24"
      />
      <line
        x2="118.5"
        y1="-10.903"
        y2="-10.903"
        display="inline"
        stroke="#ED1C24"
      />
    </g>
    <line
      x1="15.798"
      x2="15.798"
      y1="-10.903"
      y2="-113.59"
      display="none"
      fill="none"
      stroke="#ED1C24"
      strokeMiterlimit="10"
      strokeWidth="3"
    />
    <line
      x1="102.7"
      x2="102.7"
      y1="-10.903"
      y2="-113.59"
      display="none"
      fill="none"
      stroke="#ED1C24"
      strokeMiterlimit="10"
      strokeWidth="3"
    />
    <g
      fill="none"
      stroke=""
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    >
      <line x1="49.244" x2="49.244" y1="55.369" y2="93.401" />
      <ellipse cx="49.249" cy="47.971" rx="6.035" ry="6.033" />
      <path d="m18.104 72.171c-5.581-6.887-8.927-15.663-8.927-25.22 0-22.13 17.942-40.075 40.072-40.075s40.071 17.945 40.071 40.075c0 9.553-3.337 18.316-8.917 25.202" />
      <path d="m29.219 56.863c-1.13-2.633-1.758-5.534-1.758-8.585 0-12.031 9.753-21.784 21.783-21.784 12.038 0 21.789 9.753 21.789 21.784 0 3.252-0.71 6.336-1.986 9.105" />
    </g>
  </svg>
);

export const StoreIcon = (props: { className?: string }) => (
  <svg
    className={props.className}
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <title>Shop</title>
    <path d="M475.635,154.341l-.005,0-33.8-90.833a32.14,32.14,0,0,0-29.99-20.839H100.156a32.139,32.139,0,0,0-29.99,20.841l-33.8,90.833c-4.2,11.313-4.6,23.32-1.125,33.81a63.847,63.847,0,0,0,39.427,40.168V416A53.4,53.4,0,0,0,128,469.333H384A53.4,53.4,0,0,0,437.333,416V228.319a63.847,63.847,0,0,0,39.427-40.168C480.24,177.661,479.839,165.654,475.635,154.341Zm-124.771-10.51A74.573,74.573,0,0,1,352,156.8V168a42.667,42.667,0,1,1-85.333,0V64h70.109ZM160,156.8a74.573,74.573,0,0,1,1.135-12.974L175.225,64h70.109V168A42.667,42.667,0,1,1,160,168ZM55.49,181.438c-1.958-5.9-1.646-12.883.87-19.661l33.8-90.828a10.717,10.717,0,0,1,10-6.948h53.4l-13.435,76.122a96.139,96.139,0,0,0-1.458,16.682V168A42.675,42.675,0,0,1,55.49,181.438ZM160,448V288h74.667V448Zm256-32a32.035,32.035,0,0,1-32,32H256V288a21.354,21.354,0,0,0-21.333-21.333H160A21.354,21.354,0,0,0,138.667,288V448H128a32.035,32.035,0,0,1-32-32V232a64,64,0,0,0,53.333-28.656,63.959,63.959,0,0,0,106.667,0,63.959,63.959,0,0,0,106.667,0A64,64,0,0,0,416,232Zm40.51-234.562A42.675,42.675,0,0,1,373.333,168V156.8a96.139,96.139,0,0,0-1.458-16.682L358.44,64h53.4a10.718,10.718,0,0,1,10,6.945l33.792,90.828C458.156,168.555,458.469,175.536,456.51,181.438Z" />
    <path d="M352,266.667H309.333A21.354,21.354,0,0,0,288,288v42.667A21.354,21.354,0,0,0,309.333,352H352a21.354,21.354,0,0,0,21.333-21.333V288A21.354,21.354,0,0,0,352,266.667Zm-42.667,64V288H352l.016,42.667Z" />
  </svg>
);

export const SellIcon = (props: { className?: string }) => (
  <svg
    className={props.className}
    version="1.1"
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
  >
    <path
      className="st0"
      d="M3,25l2.6-4.2c1.5-2.3,4-3.8,6.8-3.8H19v0c0,2.2-1.8,4-4,4h-2"
    />
    <path
      className="st0"
      d="M15,21h8l1.2-1.6c1.1-1.5,2.9-2.4,4.8-2.4h0l-2.7,4.8c-1.4,2.6-4.2,4.2-7.1,4.2h0c-4.7,0-9.3,1.4-13.2,4l0,0"
    />
    <circle className="st0" cx="20.5" cy="10.5" r="3.5" />
    <path
      className="st0"
      d="M19,7.3C18.9,5.5,17.4,4,15.5,4C13.6,4,12,5.6,12,7.5s1.6,3.5,3.5,3.5c0.5,0,1-0.1,1.5-0.3"
    />
  </svg>
);

export const MenuIcon = (props: { className?: string }) => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="currentColor"
  >
    <path
      d="M14.6161 1.44455C15.1043 0.956391 15.8957 0.956391 16.3839 1.44455L19.5659 4.62653C20.054 5.11468 20.054 5.90614 19.5659 6.39429L16.3839 9.57627C15.8957 10.0644 15.1043 10.0644 14.6161 9.57627L11.4341 6.39429C10.946 5.90614 10.946 5.11468 11.4341 4.62653L14.6161 1.44455L14.0963 0.924774L14.6161 1.44455ZM0.75 3C0.75 2.30965 1.30964 1.75 2 1.75H7C7.69036 1.75 8.25 2.30965 8.25 3V8C8.25 8.69036 7.69036 9.25 7 9.25H2C1.30964 9.25 0.75 8.69036 0.75 8V3ZM0.75 14C0.75 13.3096 1.30964 12.75 2 12.75H7C7.69036 12.75 8.25 13.3096 8.25 14V19C8.25 19.6904 7.69036 20.25 7 20.25H2C1.30964 20.25 0.75 19.6904 0.75 19V14ZM11.75 14C11.75 13.3096 12.3096 12.75 13 12.75H18C18.6904 12.75 19.25 13.3096 19.25 14V19C19.25 19.6904 18.6904 20.25 18 20.25H13C12.3096 20.25 11.75 19.6904 11.75 19V14Z"
      fill="currentColor"
      fillOpacity="0.1"
      stroke="currentColor"
      strokeWidth="1"
    ></path>
  </svg>
);

export const SortIcon = (props: { className?: string }) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4697 7.53033C13.7626 7.82322 14.2374 7.82322 14.5303 7.53033L15.25 6.81066L15.25 17C15.25 17.4142 15.5858 17.75 16 17.75C16.4142 17.75 16.75 17.4142 16.75 17L16.75 6.81066L17.4697 7.53033C17.7626 7.82322 18.2374 7.82322 18.5303 7.53033C18.8232 7.23744 18.8232 6.76256 18.5303 6.46967L16.5303 4.46967C16.2374 4.17678 15.7626 4.17678 15.4697 4.46967L13.4697 6.46967C13.1768 6.76256 13.1768 7.23744 13.4697 7.53033Z"
      fill=""
    />
    <path
      d="M8.75 17.1893L9.46967 16.4697C9.76256 16.1768 10.2374 16.1768 10.5303 16.4697C10.8232 16.7626 10.8232 17.2374 10.5303 17.5303L8.53033 19.5303C8.23744 19.8232 7.76256 19.8232 7.46967 19.5303L5.46967 17.5303C5.17678 17.2374 5.17678 16.7626 5.46967 16.4697C5.76256 16.1768 6.23744 16.1768 6.53033 16.4697L7.25 17.1893L7.25 7C7.25 6.58579 7.58579 6.25 8 6.25C8.41421 6.25 8.75 6.58579 8.75 7L8.75 17.1893Z"
      fill=""
    />
  </svg>
);

export const FilterIcon = (props: { className?: string }) => (
  <svg
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9859 5.42367C14.0077 4.97906 9.99236 4.97906 6.01419 5.42367C5.74502 5.45376 5.6154 5.76941 5.78574 5.97998L9.30276 10.3275C10.5627 11.8849 11.25 13.8275 11.25 15.8307V18.7199L12.75 19.8199V15.8307C12.75 13.8275 13.4374 11.8849 14.6973 10.3275L18.2143 5.97998C18.3846 5.76941 18.255 5.45376 17.9859 5.42367ZM5.84758 3.93296C9.93648 3.47596 14.0636 3.47596 18.1525 3.93296C19.5994 4.09467 20.2962 5.79146 19.3805 6.92339L15.8635 11.2709C14.8195 12.5614 14.25 14.1709 14.25 15.8307V21.3C14.25 21.5826 14.0911 21.8413 13.839 21.969C13.5869 22.0968 13.2844 22.0719 13.0565 21.9048L10.0565 19.7048C9.86384 19.5635 9.75002 19.3389 9.75002 19.1V15.8307C9.75002 14.1709 9.18049 12.5614 8.13658 11.2709L4.61956 6.92339C3.70387 5.79146 4.40065 4.09467 5.84758 3.93296Z"
      fill=""
    />
  </svg>
);

export const PlusIconLg = (props: { className?: string }) => (
  <svg
    className={props.className}
    enableBackground="new 0 0 38.342 38.342"
    version="1.1"
    viewBox="0 0 38.342 38.342"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m19.171 0c-10.571 0-19.171 8.6-19.171 19.171 0 10.569 8.6 19.171 19.171 19.171 10.569 0 19.171-8.602 19.171-19.171 0-10.571-8.602-19.171-19.171-19.171zm0 34.341c-8.365 0-15.171-6.808-15.171-15.171 0-8.365 6.806-15.171 15.171-15.171s15.171 6.806 15.171 15.171c0 8.363-6.806 15.171-15.171 15.171zm11.684-15.17c0 1.656-1.344 3-3 3h-5.685v5.685c0 1.655-1.345 3-3 3-1.657 0-3-1.345-3-3v-5.685h-5.684c-1.657 0-3-1.344-3-3 0-1.657 1.343-3 3-3h5.684v-5.683c0-1.657 1.343-3 3-3 1.655 0 3 1.343 3 3v5.683h5.685c1.657 0 3 1.343 3 3z" />
  </svg>
);
