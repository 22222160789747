import { useFormik } from 'formik';
import React, { useContext } from 'react'
import { Button, Card, Col, Form, FormControl, InputGroup, ListGroup, Offcanvas, Row } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { FiArrowLeft, FiSearch } from 'react-icons/fi';
import { VscArrowLeft } from 'react-icons/vsc';
import { useNavigate } from 'react-router';
//import { Link } from 'react-router-dom';
import { TriggerMobileSearchOffCanvasContext } from '../../context/TriggerMobileSearchOffCanvasContext';
import { useAppSelector } from '../../redux/store/hook';
import searchFormValidation from '../../validations/schemas/search-input.schema';

export default function SearchMobileOffCanavs() {
  const { offCanvas, handleOnHideCanvas } = useContext(TriggerMobileSearchOffCanvasContext);
  const { account, ek_isAuth, token } = useAppSelector(state => state.auth.authentication);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: searchFormValidation(),
    onSubmit: values => {
      navigate('/search-result?q=' + values.search)
    }
  });

  const getSearch = (myParam: any) => {
    const body = {
      "productName": myParam.target.value,
    }
    // console.log(body)
  }

  return (
    <Offcanvas scroll={true} backdrop={true} show={offCanvas.show} placement={offCanvas.placement} onHide={handleOnHideCanvas}>
      <Offcanvas.Header className="pl-0" closeVariant='white'>
        <Offcanvas.Title className="pl-0">
          <span className="d-flex align-items-center d-block" id='closeIt' onClick={handleOnHideCanvas}>
            <FiArrowLeft fontSize={20} />
            <span className="ms-2">Search</span>
          </span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Scrollbars
        className="offcanvas_bd"
        style={{ width: "100%" }}>
        <Col className="form-search-col">
          <Form noValidate className="d-flex" onSubmit={formik.handleSubmit}>
            <InputGroup className="input_group_div">
              <Col className="pe-0 form-search-col">
                <FormControl
                  type="search"
                  name="search"
                  autoComplete="off"
                  placeholder="I'm shopping for..."
                  className=" form-search-input"
                  aria-label="Search"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.search}
                  onKeyUp={(e) => getSearch(e)}
                />
                {/* <FiSearch className="iconSearch" /> */}
                {formik.values.search && (<Col className="p-0 search-auto-complete_canvas">
                  {/* <AutoComplete /> */}
                  <Col className="w-100 auto-complete-wrapper">
                    <Card>
                      <Card.Body>
                      </Card.Body>
                    </Card>
                  </Col>
                </Col>
                )}
              </Col>
              {/* <Button className="nav_button text-white px-4 " type="submit">
                Search
              </Button> */}
            </InputGroup>
          </Form>
        </Col>
      </Scrollbars>
    </Offcanvas>
  )
}
