import React, { useState, useEffect } from 'react';
import {
  Card, Col, ListGroup,
} from "react-bootstrap";
import { toast } from 'react-toastify';

import { useFormik } from "formik";
import { FiArrowRightCircle, FiCheck, FiEdit3, FiPlus, FiPlusCircle, FiX } from "react-icons/fi";
import { VscArrowSmallRight } from "react-icons/vsc";
import Field from "@ekenta/components/InputField/Field/Field";
import { Button, Loader } from "@ekenta/components";
import { useAppSelector } from "@ekenta/redux/store/hook";
import Header from "../Header/Header";
import DeliveryAddFormValidation from "@ekenta/validations/schemas/delivery-address.schema";
import { useUpdateProfileMutation } from "@ekenta/redux/store/services/accountApi";
import axios from "axios";

interface DeliveryAddressPropsType {
  values: any;
  setIsChangeAddress: any;
  getGIGResult: any;
  userProfile: any;
  addressData: any;
  addressSuccess: boolean;
  isNewAddress: boolean;
  isLoading: boolean;
  setIsNewAddress: (val: boolean) => void;
}

function DeliveryAddress({
  values,
  setIsChangeAddress,
  getGIGResult,
  userProfile,
  addressData,
  addressSuccess,
  isNewAddress,
  isLoading,
  setIsNewAddress,

}: DeliveryAddressPropsType) {

  const [show, setShow] = useState(false);
  const { account, token } = useAppSelector((state) => state.auth.authentication);

  const [updateUser, getResult] = useUpdateProfileMutation();

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    validationSchema: DeliveryAddFormValidation(),
    onSubmit: values => {
      const data = {
        email: account.user.email,
        lastName: account.user.lastName,
        firstName: account.user.firstName,
        username: account.user.username,
        phoneNumber: values.phoneNumber
      }
      updateUser({ data, token });
    }
  });

  useEffect(() => {

    if (getResult?.isSuccess) {
      toast.success(`Phone number added successfully`, {
        toastId: 'phone--id-toast-success',
      });
    }

    if (getResult?.isError && getResult?.error.status === 'FETCH_ERROR') {
      toast.error('Unable to add phone number', {
        toastId: 'phone-added-id-toast-error',
      });
    }

    if (getResult?.isError && getResult.error.status === 500) {
      toast.error('Something went wrong', {
        toastId: 'phone-500-id-toast-error',
      });
    }

    if (getResult?.isError && getResult?.error.status === 400) {
      toast.error(`Unable to add phone number`, {
        toastId: 'phone-error-id-toast-error',
      });
    }
  }, [
    getResult?.isSuccess,
    getResult?.isError,
  ]);







  return (
    <Col lg={12} className="checout__address__deliv">
      <Card>
        <Card.Body style={{ paddingBottom: "1rem" }}>
          {isLoading ? <Loader isCenter /> : (
            <>
              <Header
                title="Delivery Address"
                active={values.address}
                isBorder
                renderAction={() => (
                  <>
                    {addressData ? (
                      <div className="span_extra__action">
                        <span style={{ cursor: "pointer" }} onClick={() => setIsChangeAddress(true)}>
                          <FiEdit3 className="me-1" />
                          CHANGE
                        </span>
                      </div>
                    ) : null}
                  </>
                )}
              />
              <Col lg={12} className="delivery__address_option">
                <>
                  {addressSuccess && addressData ? (
                    <ListGroup>
                      <ListGroup.Item className="d-flex align-items-center mb-1">
                        <h5>
                          {account.user.firstName}
                          ,
                        </h5>
                        <span className="ms-2">{userProfile?.phoneNumber}</span>
                      </ListGroup.Item>
                      <ListGroup.Item className="mb-2">
                        <span style={{ textTransform: "capitalize" }}>
                          {/* <input type="radio" onChange={()=>setSelectedAddress(defaultaddress_data)} name="address" value={defaultaddress_data.id} />  */}
                          {
                            `${addressData.address.homeAddress} ${addressData.city.name}, ${addressData.city.state.name
                            } ...`
                          }
                        </span>
                      </ListGroup.Item>
                    </ListGroup>

                  ) : (
                    <>
                      {
                        !isNewAddress && (
                          <div className="span_extra__action mt-3">
                            <span onClick={() => setIsNewAddress(true)}>
                              <FiPlus className="me-1" />
                              Add new address
                            </span>

                          </div>
                        )}
                    </>
                  )}
                </>
              </Col >

              {!userProfile?.phoneNumber && addressData ? (
                <div className="AccountUser_Phone_Div">
                  <div className="AccountUser_Phone__Flex">
                    {!show ? (
                      <span className="AccountUser_Phone_Span"
                        onClick={() => setShow(true)}>
                        <FiPlus className="me-1" />
                        Add phone number
                      </span>
                    ) : (
                      <span className="AccountUser_Phone_Span Close_Span"
                        onClick={() => {
                          setShow(false);
                          formik.setFieldValue("phoneNumber", "");
                        }}>
                        <FiX className="me-1" />
                        Close field
                      </span>
                    )}
                  </div>

                  {show ? (
                    <div className="AccountUser_Input_Div">
                      <div className="AccountUser_InputGroup">
                        <form onSubmit={formik.handleSubmit}>
                          <Field
                            id="phone-field"
                            name="phoneNumber"
                            type="text"
                            disabled={getResult?.isLoading}
                            value={formik.values.phoneNumber}
                            fieldClassName="AccountUser__Input"
                            placeholder="Add phone number"
                            autoComplete="off"
                            onChange={formik.handleChange}
                          />

                          <Button
                            name="submitBtn"
                            type="submit"
                            disabled={getResult?.isLoading}
                            className="AccountUser__Button m-0"
                          >
                            <FiArrowRightCircle />
                          </Button>
                          {getResult?.isLoading ? <Loader withWrapper className="AccountUser__Loader" /> : null}
                        </form>
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                          <div className="invalid-fbk">{formik.errors.phoneNumber}</div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null
              }
            </>
          )
          }


          {/* {addressSuccess && addressData &&
                    <>
                        {getGIGResult?.isSuccess ? null : (
                            <div className="mt-3 w-100 deliver__address_btn">
                                <Button disabled={getGIGResult?.isLoading} type="button" className='checkout-btn'
                                    onClick={() => handleSendAddress({ ...addressData })}>
                                    {getGIGResult?.isLoading
                                        ? (<><Spinner size="sm" animation="border" variant="white" /> <span className="ms-1">Getting price</span></>) :
                                        (<>Proceed <VscArrowSmallRight fontSize={15} /></>
                                        )
                                    }
                                </Button>
                            </div>
                        )}
                    </>

                }  */}

        </Card.Body >
      </Card >
    </Col >

  );
}

export default DeliveryAddress;
