import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { SellerOnboardFormikFormType } from '../../../../../interfaces/sellerOnboarding.interface';
import { VscArrowSmallLeft, VscArrowSmallRight } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { loginPathUrl } from '../../../../../routes/constant';
import { getIn } from 'formik';
import NumberFormat from 'react-number-format';

export default function BankDetails({ handleChange, handleBlur, values, errors, touched, setFieldValue, nextPage, prevPage }: SellerOnboardFormikFormType) {
    return (
        <div className="seller_account_details_form_col">
            <div className="_top_header mt-1">
                <h5>Seller Bank Details</h5>

            </div>

            <Col lg={8} className="mx-auto _col_container">
                <div className=" mt-4">
                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label>
                            Bank<span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <Form.Select
                                name="bank_detail.bank"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bank_detail.bank}
                                //isInvalid={!!getIn(errors, 'bank_detail.bank')}
                            >
                                <option value="">Select Bank</option>
                                <option value="accessbank">Access Bank</option>
                                <option value="citibank">Citibank</option>
                                <option value="diamond">Diamond Bank</option>
                                <option value="ecobank">Ecobank</option>
                                <option value="fidelity">Fidelity Bank</option>
                                <option value="firstbank">First Bank</option>
                                <option value="fcmb">First City Monument Bank (FCMB)</option>
                                <option value="gtb">Guaranty Trust Bank (GTB)</option>
                                <option value="heritage">Heritage Bank</option>
                                <option value="keystone">Keystone Bank</option>
                                <option value="Kuda">Kuda Bank</option>
                                <option value="polaris">Polaris Bank</option>
                                <option value="Paycom">Paycom (OPAY) Bank</option>
                                <option value="providus">Providus Bank</option>
                                <option value="stanbic">Stanbic IBTC Bank</option>
                                <option value="standard">Standard Chartered Bank</option>
                                <option value="sterling">Sterling Bank</option>
                                <option value="suntrust">Suntrust Bank</option>
                                <option value="union">Union Bank</option>
                                <option value="uba">United Bank for Africa (UBA)</option>
                                <option value="unity">Unity Bank</option>
                                <option value="wema">Wema Bank</option>
                                <option value="zenith">Zenith Bank</option>
                            </Form.Select>
                            {getIn(touched, 'bank_detail.bank') &&
                                getIn(errors, 'bank_detail.bank') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.bank_detail?.bank}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4 position-relative">
                        <Form.Label>
                            Account Name<span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="bank_detail.account_name" placeholder=""
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bank_detail.account_name}
                                //isInvalid={!!getIn(errors, 'bank_detail.account_name')}
                            />
                            {getIn(touched, 'bank_detail.account_name') &&
                                getIn(errors, 'bank_detail.account_name') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {getIn(errors, 'bank_detail.account_name')}
                                </Form.Control.Feedback>
                            ) : null}

                        </Col>

                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 position-relative">
                        <Form.Label>
                            Account Number<span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <NumberFormat
                                customInput={Form.Control}
                                name="bank_detail.account_number"
                                value={values.bank_detail.account_number}
                                format="##########"
                                allowNegative={false}
                                //isInvalid={!!getIn(errors, 'bank_detail.account_number')}
                                onValueChange={(value) => {
                                    setFieldValue('bank_detail.account_number', value.value);
                                }} />

                            {
                                getIn(errors, 'bank_detail.account_number') ? (
                                    <div className="invalid-fbk">
                                        {errors.bank_detail?.account_number}
                                    </div>
                                ) : null}

                        </Col>

                    </Form.Group>
                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label>
                            Bank code
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="bank_detail.bank_code"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="#####"
                                value={values.bank_detail.bank_code}
                                isInvalid={!!getIn(errors, 'bank_detail.bank_code')}
                            />
                            {getIn(touched, 'bank_detail.bank_code') &&
                                getIn(errors, 'bank_detail.bank_code') ? (
                                <div className="invalid-fbk">
                                    {getIn(errors, 'bank_detail.bank_code')}
                                </div>
                            ) : null}
                        </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label>
                            SWIFT
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="bank_detail.bankSwift"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bank_detail.bankSwift}
                                isInvalid={!!getIn(errors, 'bank_detail.bankSwift')}
                            />
                            {getIn(touched, 'bank_detail.bankSwift') &&
                                getIn(errors, 'bank_detail.bankSwift') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {getIn(errors, 'bank_detail.bankSwift')}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label>
                            IBAN
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="bank_detail.bankIban"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bank_detail.bankIban}
                                isInvalid={!!getIn(errors, 'bank_detail.bankIban')}
                            />
                            {getIn(touched, 'bank_detail.bankIban') &&
                                getIn(errors, 'bank_detail.bankIban') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {getIn(errors, 'bank_detail.bankIban')}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group> */}
{/* 
                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label>
                            Bank Information
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="bank_detail.bank_information"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bank_detail.bank_information}
                                isInvalid={!!getIn(errors, 'bank_detail.bank_information')}
                            />
                            {getIn(touched, 'bank_detail.bank_information') &&
                                getIn(errors, 'bank_detail.bank_information') ? (
                                <Form.Control.Feedback type="invalid">
                                    {getIn(errors, 'bank_detail.bank_information')}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-1 position-relative">
                <Form.Label>
                    BVN
                </Form.Label>
                <Col sm="12">
                    <Form.Control  type="text" name="bank_detail.bankBvn" 
                    autoComplete='off'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bank_detail.bankBvn}
                    isInvalid={!!getIn(errors,'bank_detail.bankBvn')}
                    />
                    {getIn(touched,'bank_detail.bankBvn') &&
                        getIn(errors, 'bank_detail.bankBvn') ? (
                        <Form.Control.Feedback type="invalid">
                            {getIn(errors,'bank_detail.bankBvn')}
                        </Form.Control.Feedback>
                    ): null}
                </Col>
            </Form.Group> */}

                    <div className="_stepper_trigger_col">
                        <Button className="_button_A_ me-1" onClick={prevPage}>
                            <VscArrowSmallLeft />
                            <span>Back</span>
                        </Button>
                        <Button disabled={
                            !values.bank_detail.bank ||
                            !values.bank_detail.account_name ||
                            !values.bank_detail.account_number ||
                            getIn(errors, 'bank_detail.bank') ||
                            getIn(errors, 'bank_detail.account_name') ||
                            getIn(errors, 'bank_detail.account_number')
                        }

                            className="_button_A_" onClick={nextPage}>
                            <span>Next</span>
                            <VscArrowSmallRight />
                        </Button>
                    </div>
                    <div className="highlight">Already have an account?
                        <Link to={loginPathUrl}> Sign in here! </Link>
                    </div>

                </div>
            </Col>
        </div>

    );
}
