import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import { FiChevronDown, FiSliders } from 'react-icons/fi'
import Dropdown from '../../../../../components/Dropdown/Dropdown'
import Tooltip from '../../../../../components/Tooltips/Tooltips';
 


export const Filters = (props:{setCurrentDate:React.Dispatch<React.SetStateAction<{
      from: null | string;
      to: null | string;
      key: string;
}>>}) => {

    const [content, setContent] = useState('');
    const [current, setCurrent] = useState({
        key: 'today',
        name: 'Today'
    });

    useEffect(()=> {
      const content = getDateRangeString(current.key)
      setContent(content)
     },[current.key]);


    const handler = (value:{key:string, name:string}) => {
      setCurrent(value);
    }

    const menuArray = [
        {  
            name: 'Today', 
            icon:'', 
            active: false, 
            key:'today', 
            handler: () => handler({key: 'today', name:'Today'})},
        { 
            name: 'This Week', 
            icon:'',active: false, 
            key:'this_week', 
            handler:() => handler({key: 'this_week', name:'This Week'})},
        {  
           name: 'This Month', 
           icon:'', 
           active: false, 
           key:'this_month', 
           handler:() => handler({key: 'this_month', name:'This Month'})
        },
        { 
            name: 'This Year', 
            icon:'', active: false, 
            key:'this_year',  
            handler:() => handler({key: 'this_year', name:'This Year'})
        },
        { 
            name: 'Yesterday', 
            icon:'',
            active: false, 
            key:'yesterday', 
            handler:() => handler({key: 'yesterday', name:'Yesterday'})
        },
        { 
            name: 'Last Week', 
            icon:'',
            active: false, 
            key:'last_week', 
            handler:() => handler({key: 'last_week', name:'Last Week'})
        }, 
        { 
          name: 'Last Month', 
          icon:'',active: false, 
          key:'last_month',  
          handler:() => handler({key: 'last_month', name:'Last Month'})
        },
        { 
           name: 'Last Year', 
           icon:'', 
           active: false, 
           key:'last_year', 
           handler:() => handler({key: 'last_year', name:'Last Year'})
        },
        { 
            name: 'All Time', 
            icon:'',
            active: false, 
            key:'all_time', 
            handler:() => handler({key: 'all_time', name:'All Time'})
        },
        { 
            name: 'Custom', 
            icon:'', active: false,
            key:'custom', 
            handler:() => handler({key: 'custom', name:'Custom'})
        },

    ];

    const filterActive = useCallback((data:any,current:{key:string,name:string}) => {
        return data.filter((item:any,index:number) => {
            if(item.key === current.key){
                data[index].active = true;
            }
            return data;
         });

    },[menuArray,current])

    const getDateRange = (startDate:any, endDate:Date) =>{
        if (moment(startDate).format("MMM") === moment(endDate).format("MMM")) {
            return `${moment(startDate).format("MMM D")}-${moment(
            endDate,
            ).format("D")}, ${moment(endDate).format("YYYY")}`;
        } else {
          return `${moment(startDate).format("MMM D")}-${moment(
           endDate,
         ).format("MMM D")}, ${moment(endDate).format("YYYY")}`; 
        }

     
      }

      const getDateRangeString = useCallback((value:string) => {
        let endDate = new Date();
        let startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
        const result = '';
  
        if(value === 'today'){
          updateCurrentDate(endDate, endDate);
          return `${moment(endDate).format("MMM D")}, 
            ${moment(endDate).format("YYYY")}`;
  
        }else if(value === 'this_week'){
          startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 
                      endDate.getDate() - endDate.getDay());
          updateCurrentDate(startDate, endDate);
          return getDateRange(startDate, endDate)
  
        }else if(value === 'this_month'){
          startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
          updateCurrentDate(startDate, endDate);
          return getDateRange(startDate, endDate)
          
        }else if(value === 'this_year') {
          startDate = new Date(endDate.getFullYear(), 0, 1);
          updateCurrentDate(startDate, endDate);
          return getDateRange(startDate, endDate);
  
        }else if(value === 'yesterday'){
          startDate = new Date(endDate.valueOf() - 1000*60*60*24);
          updateCurrentDate(startDate, startDate);
          return `${moment(startDate).format("MMM D")}, 
            ${moment(endDate).format("YYYY")}`;
  
        }else if(value === 'last_week') {
          const startDate = new Date().setDate(endDate.getDate()-endDate.getDay()-7);
          endDate = new Date(startDate);
          const _endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 
                                    endDate.getDate() - endDate.getDay() + 6);
          updateCurrentDate(endDate, _endDate);
          return getDateRange(startDate, _endDate)
  
        }else if(value === 'last_month') {
          startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, 1);
          endDate = new Date(endDate.getFullYear(), endDate.getMonth(),0);
          updateCurrentDate(startDate, endDate);
          return getDateRange(startDate, endDate)
  
        }else if(value === 'last_year'){
          startDate = new Date(endDate.getFullYear()-1, 0, 1);
          endDate = new Date(endDate.getFullYear()-1 ,12,0);
          updateCurrentDate(startDate, endDate);
          return getDateRange(startDate, endDate);
  
        } else if(value === 'all_time'){
          startDate = new Date(endDate.getFullYear()-1, 0, 1);
          updateCurrentDate(startDate, endDate);
          return getDateRange(startDate, endDate);
        }else{
          return result;
        }
  
    },[current.key])



    const updateCurrentDate = (startDate:Date, endDate:Date) => {
      const from = moment(startDate).format("YYYY-MM-DD");
      const to =  moment(endDate).format("YYYY-MM-DD");
      
      props.setCurrentDate({
        from:from,
        to:to,
        key: current.key
      })
    }

  
  return (
     <>
        <Dropdown 
            name={current ? current.name : 'Filters'}
            prefixIcon={<FiSliders />}
            suffixIcon={<FiChevronDown />}
            placement="bottom" 
            menus={filterActive(menuArray,current)}
            tooltipContent={content}
        />
    </>
  )
}
