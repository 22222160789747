import React, { useState } from "react"
//import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router";
import { PaginationPropsType } from "@ekenta/interfaces";
import classnames from "classnames";

import "./Pagination.scss";

const Pagination = ({ pathname, handleChange, itemsLength, itemPerPage, currentPage }: PaginationPropsType) => {

    const navigate = useNavigate();
    const [value, setValue] = useState("");

    const _totalPages = Math.ceil(itemsLength / itemPerPage);
    const changePage = ({ selected }: any) => {
        navigate(`${pathname}?page=${selected + 1}&sortType=default`);
    };

    const handleClick = () => {
        if (value && parseInt(value.toString()) > 0 && parseInt(value.toString()) <= _totalPages) {
            navigate(
                `${pathname}?page=${parseInt(value.toString())}&sortType=default`,
            );
            setValue("");
        }
    };

    return (
        <div className="list-pagination-container eke-Pagination__container">
            <div className="next-pagination">
                <div className="next-pagination-pages">
                    <ReactPaginate
                        breakLabel="..."
                        forcePage={currentPage}
                        marginPagesDisplayed={2}
                        nextLabel={<>next <FiChevronRight /> </>}
                        onPageChange={handleChange ? handleChange : changePage}
                        pageCount={_totalPages}
                        previousLabel={<><FiChevronLeft /> previous</>}
                        disabledClassName={"disabledbtn"}
                    />
                </div>
            </div>
            <div className="jump--ara_">
                <span>Total {_totalPages} pages</span>
                <span>Go to Page</span>
                <span className="next-input next-large">
                    <input aria-label="Large" height="100%" autoComplete="off" value={value}
                        onChange={(e) => setValue(e.target.value)} />
                </span>
                <span className={classnames("j-btn", parseInt(value.toString()) <= _totalPages ? "" : "disabledbtn")} onClick={handleClick}>Go</span>
            </div>
        </div>
    )
}


// const Container = styled.div`
//     height: 32px;
//     box-sizing: content-box;
//     padding: 16px;
//     background: #fff;
//     border-radius: 8px;

//     .next-pagination {
//         display: inline-block;
//         box-sizing: border-box;

//         ul{
//             list-style: none !important;
//             display: flex;
//             align-items: center;
//             padding-left: 0 !important;


//             li{
//                 margin-left: 10px;

//                 a{
//                     border-radius: 2px;
//                     padding: 0 10px;
//                     text-decoration: none;
//                     position: relative;
//                     display: inline-block;
//                     box-shadow: none;
//                     text-decoration: none;
//                     text-align: center;
//                     text-transform: none;
//                     white-space: nowrap;
//                     vertical-align: middle;
//                     user-select: none;
//                     transition: all .3s ease-out;
//                     cursor: pointer;
//                     box-sizing: border-box;
//                     border: 1px solid #ccc!important;
//                     background-color: #fff!important;
//                     height: 32px;
//                     line-height: 29.7px;
//                     font-size: 13.8px;
//                     font-family: "Noto Sans", sans-serif;
//                     font-weight: 400;
//                 }
//             }

//             li.selected{

//                 a{
//                    background: #E04826 !important;
//                    color: #fff !important;
//                    border: none !important;
//                    line-height: 29.7px;
//                 }
//             }

//             li.previous{
//                 margin-left: 0px !important;

//                 a{
//                     text-transform: capitalize;
//                     display: flex;
//                     align-items: center;
//                     color: #232F3E !important;
//                 }
//             }


//             li.next{
//                 a{
//                     text-transform: capitalize;
//                     display: flex;
//                     align-items: center;
//                     color: #232F3E !important;
//                 }
//             }

//             li.disabledbtn{
//                 a{
//                     color: #ccc !important;
//                     border-color: #eee !important;
//                 }
//             }
//         }
//     }





//     .jump--ara_ {
//         display: inline-flex;
//         align-items: center;
//         vertical-align: top;

//        span {
//             margin-left: 10px;
//             font-size: 14px;
//             font-family: "Noto Sans", sans-serif;
//                 font-weight: 400;
//         }

//         .total-page {
//             line-height: 36px;
//         }

//         .next-input.next-large {
//             vertical-align: top;
//             border-radius: 4px;
//         }

//         .next-input{
//             border-radius: 2px!important;
//             width: 55px !important;
//             height: 32px!important;

//             input{
//                 width: 55px !important;
//                 height: 32px!important;
//                 padding: 0 12px;
//                 font-size: 14px;
//                 border: none;
//                 outline: none;
//                 border-spacing: 0;
//                 transition: all .3s ease-out;
//                 border: 1px solid #ccc;
//                 background-color: #fff;
//             }
//         }

//         .j-btn {
//             border: 1px solid #ccc;
//             border-radius: 2px;
//             padding: 4px 15px;
//             min-width: 50px;
//             height: 32px;
//             display: inline-block;
//             cursor: pointer;
//             vertical-align: top;
//         }

//     }

// `;

export default Pagination;
