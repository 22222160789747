import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'react-bootstrap';
import Stepper from "react-stepper-horizontal";
import SellerOnboadingForms from './components/SellerOnboadingForms';
import { useNavigate } from 'react-router';

import DefaultLayout from '../../../hoc/Layout/Default-Layout';
import { animateScroll } from 'react-scroll';


const formSteps = [
  { title: "Personal account details" },
  { title: "Business Details" },
  { title: "Bank Details" },
  { title: "Summary" },
];


export default function SellerOnboarding() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [step, setStep] = useState(formSteps);


  useEffect(() => {
    if (page) {
      scrollToTop();
    }
  }, [page]);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  }


  const nextPage = () => setPage(page + 1);
  const prevPage = () => setPage(page - 1);
  const handleStepper = () => setPage(0);


  return (
    <DefaultLayout>
      <Helmet>
        <title>Sell on Ekenta - Ekenta </title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Seller Page" />
        <meta name="description"
          content="Ekenta Seller Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <div className="seller_wrapper position-relative">
        <Container className="_container_s_div mt-3">
          <div className="_top_level_onboarding text-center">
            <h3>Register and start selling today - Create a Seller account</h3>
            <Container>
              <Col className="_stepper_col_" lg="7">
                <Stepper activeColor="#E04826" completeColor="#E04826" size={30} steps={step} activeStep={page} onClick={handleStepper} /></Col>
            </Container>
          </div>
          <div className="_second_level_form_onboard">
            {/* seller account details form */}
            <Container>
              <Row className="justify-content-md-center">
                <Col lg={6} className="col_seller_form">
                  <SellerOnboadingForms
                    page={page}
                    nextPage={nextPage}
                    prevPage={prevPage}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>


    </DefaultLayout>
  );
}

