import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  Col,
  Card,
  Form,
  Row,
  Button,
  Spinner,
} from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useVerifyUserEmailUsingCodeQuery } from '../../../redux/store/services/authenticationApi';
import { loginPathUrl, registerPathUrl } from '../../../routes/constant';
import { HelmetHeader } from '../../../components/HelmetHeader/HelmetHeader';
import withAuthenticationLayout from '../../../hoc/Layout/withAuthenticationLayout';

const PIN_LENGTH = 6;

const EmailVerification = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [emailCode, setEmailCode] = useState('');
  const [error, setError] = useState('');

  const [initialValues, setInitialValues] = useState<{
    email: string;
    code: string;
  }>({
    email: '',
    code: '',
  });
  const {
    isLoading,
    isError,
    error: errors,
    isSuccess,
  } = useVerifyUserEmailUsingCodeQuery(initialValues, {
    skip: initialValues.email === '' && initialValues.code === '',
  });
  const emailCodeRef = useRef(null);

  useEffect(() => {
    if (!state || !state.email) {
      navigate(`${registerPathUrl}`);
    }
  }, []);

  useEffect(() => {
    if (isError && errors.status === 'FETCH_ERROR') {
      toast.error(`Something went wrong,Try again`, {
        toastId: 'error-emailverification-id-toast-error',
      });
    }

    if (isError && errors.status === 401) {
      toast.error(`${errors.data.message}`, {
        toastId: 'error-emailverification-401-id-toast-error',
      });
    }

    if (isError && errors.status === 400) {
      toast.error(`Verification code is incorrect`, {
        toastId: 'error-email-verification-400-id-toast-error',
      });
    }
  }, [isError, errors]);

  const validatePin = (value: string) => {
    if (value.trim() === '') return false;
    if (value.length < PIN_LENGTH) return false;

    return true;
  };

  const handleChange = (value: string) => {
    setEmailCode(value);
    if (validatePin(value) === false)
      return setError('Pin fields are required');
    setError('');
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validatePin(emailCode) === false)
      return setError('Pin fields are required');
    setError('');
    const objData = {
      email: state.email,
      code: emailCode,
    };
    setInitialValues(objData);
  };

  if (isSuccess) {
    navigate(loginPathUrl, {
      state: {
        status: 'success',
        statusMsg: 'Email successfully verified',
      },
      replace: true,
    });
  }

  return (
    <>
      <HelmetHeader
        title="Email Verification"
        description="Email Verification Page"
      />

      <Container fluid className="account-resetpass">
        <Row className="row_card">
          <Col md="8" lg="5" xl="4" className="col--card  p-1">
            <Card>
              <Card.Body className="p-4">
                <Form noValidate onSubmit={handleSubmit}>
                  <Col className="mb-4">
                    <h2 className="form-header text-center">
                      Verify Your Email
                    </h2>
                  </Col>
                  <Col className="text-center">
                    <p className="para__text">
                      We sent you a {PIN_LENGTH} digits verification code to
                      your email address, please check and enter the code
                    </p>
                  </Col>

                  {/* code form input */}
                  <Form.Group className="form-group mb-4 position-relative">
                    <Row className="align-items-center justify-content-center">
                      <PinInput
                        ref={emailCodeRef}
                        length={PIN_LENGTH}
                        focus
                        onChange={handleChange}
                        type="custom"
                        inputMode="number"
                        inputStyle={{ borderColor: 'white' }}
                        onComplete={(value) => null}
                        autoSelect={true}
                      />
                    </Row>
                    <Col className="mt-1 text-center">
                      {error ? (
                        <span className="text-danger text-sm ">{error}</span>
                      ) : null}
                    </Col>
                  </Form.Group>

                  <Button
                    className={`account-forgot-btn w-100 ${
                      isLoading ? 'disabled' : ''
                    } ${validatePin(emailCode) ? '' : 'disabled'}`}
                    type="submit"
                  >
                    {isLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner
                          size="sm"
                          animation="border"
                          variant="warning"
                        />
                        <span className="ms-1 text-sm">
                          <small>Please Wait...</small>
                        </span>
                      </div>
                    ) : (
                      <span>VERIFY</span>
                    )}
                  </Button>
                </Form>
                <Col className="account-backlogin-col w-100 mt-3 d-flex align-items-center justify-content-between">
                  <h6 className="col-h- trigger_cus">
                    <span className="ques__active">Questions? Email us</span>{' '}
                    <Link to="">
                      <span>help@ekenta.com</span>
                    </Link>
                  </h6>
                </Col>
                <br />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withAuthenticationLayout(EmailVerification);
