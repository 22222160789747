import React from 'react'

const SvgArrowUp = ({
    width = "1rem",
    height = "1rem",
    className
}: {
    className?: string,
    width?: string;
    height?: string
}) => (
    <svg
        stroke="currentColor"
        width={width}
        height={height}
        className={className}
        viewBox="0 0 18 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0L17.6603 6H0.339746L9 0Z" fill="white" />
    </svg>

)

export default SvgArrowUp;