import React, { useContext } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { VscAccount, VscDashboard } from 'react-icons/vsc';
import { Link } from 'react-router-dom'
import { TriggerSideOffCanvasContext } from '../../../../context/TriggerSideOffCanvasContext';
import { useAppSelector } from '../../../../redux/store/hook';
import { mobileAccountIndexPath, loginPathUrl, SellerOnboardingPathUrl, DashboardSellers, DashboardPathUrl } from '../../../../routes/constant';
import { checkIfAdmin, checkIfAdminOrSellerRoleExist, getNameInitial } from '../../../../utils';
import { StoreIcon } from '../../../icons/icons'

export default function TopSection() {
  const { account,ek_isAuth,token} = useAppSelector(state => state.auth.authentication);
  const { handleOnHideCanvas } = useContext(TriggerSideOffCanvasContext);
    
  const isAdmin = checkIfAdmin(token)

//
  return (
      <div className="d-block d-lg-none">
    <div className="off_profile">
        <ul className="off_profile_ul">
      
            <li>
                <Link to={ek_isAuth && token ? mobileAccountIndexPath : loginPathUrl} className="lite-btn  mobile-nav--nav-item--1hfFJ mobile-nav--highlighted--M1nGW" 
                    data-css-toggle-id="header-toggle-side-profile">
                    <div className="lite-block-list-item-content" onClick={handleOnHideCanvas}>
                
                <span className="mobile-nav--profile-section-content---4ow">
                    <span style={{display:"inline-block", position: "relative"}}>
                    {ek_isAuth && token ? (
                        <span  className="lite-avatar lite-avatar-image" 
                         style={{width: "4.0rem", height: "4.0rem"}}>
                            {ek_isAuth && account.user && getNameInitial(account.user.firstName)}
                        </span>
                     ):(
                        <VscAccount className="ms-2" fontSize={22} />
                     )}
                    </span>
                    <span style={{flex: "1 1 0%"}}>

                    {ek_isAuth && token ? (
                        <>
                         <span className="lite-heading-md mobile-nav--profile-name--39fkZ">Hi, {ek_isAuth && account.user && account.user.firstName}</span>
                         <span className="lite-text-sm mobile-nav--profile-welcome--Z65dC">Welcome customer</span>
                        </>
                     ):(
                         <>
                        <span className="lite-text-sm mobile-nav--profile-welcome--Z65dC">Sign In</span>
                        <span className="lite-heading-md mobile-nav--profile-name--39fkZ">Account</span>
                        </>
                    )}
                    </span>
                </span>
                    </div>
                    <FiChevronRight className="icon__right" color="gray"  fontSize={22}/>
            </Link>
            </li>
        </ul>
        <ul className="off_profile_ul">
        <li>
        {!token && ek_isAuth && (
            <Link className="switch_user_link"  to={SellerOnboardingPathUrl} onClick={handleOnHideCanvas}>
                <StoreIcon className="store__icon" />
                Start Selling Anything
            </Link>
        )}
         {token && checkIfAdminOrSellerRoleExist(token) === true ? (
              <Link className="switch_user_link"  to={isAdmin ?  DashboardSellers : DashboardPathUrl} onClick={handleOnHideCanvas}>
                 <VscDashboard fontSize={18} className="icon__acc_dash " />
                 Go To Dashboard
             </Link>
         ):(
            <Link className="switch_user_link"  to={SellerOnboardingPathUrl} onClick={handleOnHideCanvas}>
                <StoreIcon className="store__icon" />
                Start Selling Anything
           </Link>
         )
        }
        </li>
    </ul>
    </div>
    
    <div className="m_collection_title"></div>
    </div>
  )
}
