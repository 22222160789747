import React from 'react';
import classnames from 'classnames';
//import { Icon } from "@iconify/react";
import PropTypes from 'prop-types';

import TableHead from './TableHead';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCell from './TableCell';

import styles from './Table.module.scss';
import {
  TableMorePropType,
  TablePropType,
} from '../../interfaces/Table';
import { FiMoreHorizontal } from 'react-icons/fi';

export { default as TableRow } from './TableRow';
export { default as TableCell } from './TableCell';
export const TableMoreIcon = ({ onClick,text }: TableMorePropType) => (
  <button
    className={`btn btn-small btn-icon btn-more ${styles.more}`}
    title={text ? text :"More options"}
    onClick={onClick}
  >
    <FiMoreHorizontal width="1rem" />
  </button>
);

const Table = ({
  simple,
  withMargin,
  children,
  className,
  ...rest
}: TablePropType) => {
  const classes = classnames(className, styles.table, {
    [styles.simple]: simple,
    [styles.withMargin]: withMargin,
  });

  return (
    <table {...rest} className={classes}>
      {children}
    </table>
  );
};

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.More = TableMoreIcon;

Table.propTypes = {
  /**
   * Apply simple and basic design
   */
  simple: PropTypes.bool,
};

export default Table;
