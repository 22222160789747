import React, { useEffect, useRef, useState } from 'react';
import { animateScroll } from "react-scroll";


interface AllMessagesList {
    messagesList: {from:string; text:string}[]
}

export default function AllMessagesList({messagesList}:AllMessagesList) {


  useEffect(() => {
      if(messagesList){
          scrollToBottom()
      }
  }, [messagesList]);


  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "messaging__wrapper_list"
    });
  }

  return (
    
      <div id="messaging__wrapper_list" className="messaging__stream__WRAPPER">
        <div className="messaging__comment__list">
            <div className="messaging__display__section absolute">
            {messagesList.length > 0 ? messagesList.map((item,index) => (
                <>
                <p key={'msg_'+index} className={` ${item.from === "seller"? "seller_identity__text single__text_msg_" : "single__text_msg_"}  `}>
                    <span className="msg__u__info">{item.from}</span>
                    <span className="msg__txt">{item.text}</span>
                </p>
                </>
            )) : null}
            </div>
        </div>
     </div>
     
  )
}
