import { useCancelOrderMutation } from "@ekenta/redux/store/services/productsApi";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useAppSelector } from "../../../../../redux/store/hook";

export default function MainOrder({ itemData }: any) {
  const { ek_isAuth, token } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  // const cancelOrder =
  const [cancelOrder, data] = useCancelOrderMutation();
  const handleCancelOrder = async () => {
    setIsLoading(true);
    // alert(itemData?.id)
    cancelOrder({ orderId: itemData?.id, token });
  };

  useEffect(() => {
    if (data.isSuccess) {
      setIsLoading(false);
      handleClose();
    }
  }, [data.isSuccess]);

  return (
    <>
      <Accordion.Item eventKey={itemData?.orderNumber}>
        <Accordion.Header>
          <div className="col-lg-12 row">
            <div className="col-lg-6">
              <p>Order Number: #{itemData?.orderNumber}</p>
              <p>Delivery Status: {itemData?.deliveryStatus}</p>
            </div>
            <div className="col-lg-6">
              <p>
                {" "}
                Total Amount: ₦{" "}
                {new Intl.NumberFormat().format(itemData?.totalOrderAmount)}
              </p>
              <p>Order Status: {itemData?.orderStatus}</p>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="float-end mb-2">
            <Badge
              className="px-3 py-2"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
            >
              Cancel Order
            </Badge>
            <br />
          </div>

          {itemData?.orderItems.map((e: any, i: any) => (
            <div key={i} className="col-lg-12 card order--card-div mb-3">
              <div className="row g-0">
                <div
                  className="col-3 col-md-3 pe-0 card--image_div"
                  style={{ backgroundColor: "#f2f2f2" }}
                >
                  <img
                    src={e?.product?.imageUrls[0]}
                    className="img-fluid rounded-start"
                    alt="..."
                  />
                </div>
                <div className="col-9 col-md-8 ps-0">
                  <div className="card-body">
                    <div className="top--section__d">
                      <p className="headings">{e?.product?.name}</p>
                      <p className="body-text_p">Price: ₦{new Intl.NumberFormat().format(e?.price)}</p>
                      <p className="body-text_p">
                        Total Amount:{' '}
                        <span>₦
                          {new Intl.NumberFormat().format(
                            e?.price * e?.quantity
                          )}
                        </span>
                      </p>
                      <p className="body-text_p">
                        Quantity: <span>{e?.quantity}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Accordion.Body>
      </Accordion.Item>

      {/* <Col lg={9}>
                
            </Col> */}

      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row className="p-3">
            <div>
              Are you sure you want to cancel order {itemData?.orderNumber} ?{" "}
              <br />
              <br />
            </div>
            <Col>
              <Button
                variant="danger"
                className="text-white text-center mx-auto"
                onClick={handleClose}
              >
                No
              </Button>{" "}
              <Button
                variant="success"
                className="text-white text-center mx-auto"
                onClick={handleCancelOrder}
              >
                {isLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="grow" />
                    <span className="ms-1 text-sm">
                      <small>Please Wait...</small>
                    </span>
                  </div>
                ) : (
                  <span>Yes</span>
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
