import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Breadcrumb, Card, Col, Container, Row, Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { CustomAlert, HelmetHeader } from "@ekenta/components";

import AddressForm from "@ekenta/components/Form/AddressForm/AddressForm";
import { useAppSelector } from "@ekenta/redux/store/hook";
import {
  useChangeDefaultAddressMutation,
  useFetchUserDefaultAddressQuery,
  useFetchUserProfileDetailsQuery,
} from "@ekenta/redux/store/services/accountApi";
import { useFetchGIGLogisticPriceMutation } from "@ekenta/redux/store/services/checkoutApi";
import { cartPathUrl, collectionPathUrl } from "@ekenta/routes/constant";
import checkoutFormValidation from "@ekenta/validations/schemas/checkout-p.schema";
import {
  useFetchCartItemsQuery, usePlaceOrderCheckoutMutation,
} from "@ekenta/redux/store/services/cartApi";
import { withCheckoutLayout } from "@ekenta/hoc";
import ChangeAddressModal from "./components/Modal/ChangeAddressModal";
import ReviewOrder from "./components/ReviewOrder";
import DeliveryMethod from "./components/DeliveryMethod/DeliveryMethod";
import DeliveryAddress from "./components/DeliveryAddress/DeliveryAddress";
import PaymentOption from "./components/PaymentOption/PaymentOption";

import "./index.scss";

function CheckoutOnePage() {
  const { token } = useAppSelector((state) => state.auth.authentication);

  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({
    id: "",
    homeAddress: "",
    stateId: "",
    cityId: "",
    defaultAddress: true,
    cityName: { id: "" },
    state: {
      id: "",
    },
  });
  const [isSaveChanged, setisSaveChanged] = useState(false);
  const handleClose = () => setIsNewAddress(false);
  const handleCloseChange = () => setIsChangeAddress(false);
  const [initialValues, setInitialValues] = useState({
    address: null,
    deliveryChannel: "",
    deliveryCharge: null,
    deliveryDate: "",
    paymentChannel: "",
    logistics: "",
  });
  // const [reference, setReference] = useState<null | string>(null);
  // const [deliveryData, setDeliveryData] = useState<any>({
  //         object: null
  //     });

  const [placeOrderCheckout, {
    data, isLoading, isError, error, isSuccess,
  }] = usePlaceOrderCheckoutMutation();

  const {
    data: defaultaddress_data,
    isLoading: defaultaddress_isLoading,
    isSuccess: defaultaddress_isSuccess,
    isError: defaultaddress_isError,
  } = useFetchUserDefaultAddressQuery(token);

  const { data: profile_data, isSuccess: profile_isSuccess } = useFetchUserProfileDetailsQuery(token);

  const [
    changeDefaultAddress,
    {
      data: changeAddress_data,
      isLoading: changeAddress_isLoading,
      isSuccess: changeAddress_isSuccess,
    },
  ] = useChangeDefaultAddressMutation();

  const cartItems = useFetchCartItemsQuery(token);

  const [fetchGIGLogisticPrice, getGIGResult] = useFetchGIGLogisticPriceMutation();


  const paymentOptionEnums = {
    PAYSTACK: "PAYSTACK",
    FLUTTERWAVE: "FLUTTERWAVE",
    POD: "PAY_ON_DELIVERY",
  };

  useEffect(() => {
    if (cartItems?.isError && cartItems?.error.status === "FETCH_ERROR") {
      toast.error("Something went wrong", {
        toastId: "cart-isErr-id-toast-error",
      });
    }

    if (getGIGResult?.isError && getGIGResult?.error.status === "FETCH_ERROR") {
      toast.error("Something went wrong", {
        toastId: "error-getGIG-id-toast-error",
      });
    }

    // if(getGIGResult?.isError && getGIGResult?.error.status === 400){
    //     toast.error(`${getGIGResult?.error.data.fieldErrors[0].message}`,{
    //      toastId: "error-getGig-400-id-toast-error",
    //      });
    // }

    if (isError && error.status === "FETCH_ERROR") {
      toast.error("Something went wrong", {
        toastId: "error-isErr-id-toast-error",
      });
    }

    if (isError && error.status === 400) {
      toast.error(`${error.data.title}`, {
        toastId: "error-is-400-id-toast-error",
      });
    }
  }, [getGIGResult?.isError, isError, error]);

  // useEffect
  useEffect(() => {
    if (defaultaddress_isError) {
      toast.error("Unable to load default address", {
        toastId: "error-defaultAddress-id-toast-error",
      });
    }

    if (changeAddress_isSuccess) {
      handleCloseChange();
    }
    if (isSuccess) {
      cartItems.refetch();
    }
  }, [isSuccess, defaultaddress_isError, changeAddress_isSuccess]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: checkoutFormValidation(),
    onSubmit: (values) => {
      if (!values.deliveryChannel) {
        toast.error(`${formik.errors.deliveryChannel}`, {
          toastId: "delivery-error-defaultAddress-id-toast-error",
        });
      }

      const data = {
        address: values.address,
        deliveryChannel: values.deliveryChannel,
        deliveryCharge: values.deliveryCharge,
        deliveryDate: values.deliveryDate,
        paymentChannel: values.paymentChannel,
        logistics: values.logistics,
      };
      placeOrderCheckout({ data, token });
    },
  });

  useEffect(() => {
    if (defaultaddress_isSuccess && defaultaddress_data) {
      handleSendAddress({ ...defaultaddress_data });
      if (formik.values.deliveryChannel === "DOOR_DELIVERY" && cartItems.data) {
        sendDataToLogistics(
          cartItems.data,
          defaultaddress_data.city,
          defaultaddress_data.address.homeAddress,
        );
      }
    }
  }, [
    defaultaddress_isSuccess,
    defaultaddress_data,
    formik.values.deliveryChannel,
  ]);

  useEffect(() => {
    if (isSaveChanged && getGIGResult?.isSuccess && changeAddress_isSuccess) {
      handleSendAddress({ ...changeAddress_data });
      setisSaveChanged(false);

      if (formik.values.deliveryChannel === "DOOR_DELIVERY" && cartItems.data) {
        sendDataToLogistics(
          cartItems.data,
          changeAddress_data.city,
          changeAddress_data.address.homeAddress,
        );
      }
    }
  }, [
    isSaveChanged,
    changeAddress_isSuccess,
    getGIGResult?.isSuccess,
    formik.values.deliveryChannel,
  ]);

  const handleSendAddress = useCallback(
    (defaultAddress: any) => {
      formik.setFieldValue("address", {
        cityId: defaultAddress.city.id,
        stateId: defaultAddress.city.state.id,
        homeAddress: defaultAddress.address.homeAddress,
        defaultAddress: true,
      });
    },
    [],
  );

  const sendDataToLogistics = (cart: any, city: any, homeAddress: string) => {
    const data = {
      cart,
      city,
      homeAddress,
    };
    fetchGIGLogisticPrice({ data, token });
  };

  const makeDefault = () => {
    const { id } = selectedAddress;
    changeDefaultAddress({
      id,
      token
    });
    setisSaveChanged(true);
  };


  return (
    <>
      <HelmetHeader
        title="Checkout Process - Ekenta.com"
        description="Ekenta Order and Checkout Page"
      />
      <div className="d_checkout_wrapper">
        <div className="checkout_col__v">
          <Container>
            <Col lg={12} className="checkout__mainBar mt-4">
              <Row>
                <Col className="checkout__stepper__pro" xs={12} lg={7}>
                  <Col lg={12} className="checkout__tit_">
                    <Card>
                      <Card.Body className="p-0">
                        <div className="mt-0 _ct_wrapper">
                          <div className="__sub_wrapper">
                            <Breadcrumb className="_p_">
                              <Breadcrumb.Item>
                                <Link to={collectionPathUrl}>
                                  All Collection
                                </Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>
                                <Link to={cartPathUrl}>Cart</Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item className="active_breadcrumb" />
                            </Breadcrumb>
                          </div>
                        </div>
                        <h5 className="_heading p-3 pt-2 pb-2">Checkout</h5>
                      </Card.Body>
                    </Card>
                  </Col>

                  <LazyLoad>
                    <DeliveryAddress
                      values={formik.values}
                      setIsChangeAddress={setIsChangeAddress}
                      getGIGResult={getGIGResult}
                      userProfile={profile_data}
                      addressData={defaultaddress_data}
                      addressSuccess={defaultaddress_isSuccess}
                      isNewAddress={isNewAddress}
                      isLoading={
                        defaultaddress_isLoading || cartItems?.isLoading
                      }
                      setIsNewAddress={setIsNewAddress}
                    />
                  </LazyLoad>

                  {!profile_data?.phoneNumber || !defaultaddress_data ? (
                    <CustomAlert show={true} variant="warning">
                      <span className="Warning__Note">
                        <b>Note:</b> <br />
                        Delivery address and phone number are required for the checkout process, please provide a
                        {!defaultaddress_data ? " valid address " : null}
                        {!profile_data?.phoneNumber ? " phone number " : null}
                      </span>
                    </CustomAlert>
                  ) : null}


                  {profile_data?.phoneNumber && defaultaddress_data ? (
                    <Fragment>
                      {/* DELIVERY METHOD */}

                      <LazyLoad>
                        <DeliveryMethod
                          handleBlur={formik.handleBlur}
                          setFieldValue={formik.setFieldValue}
                          errors={formik.errors}
                          values={formik.values}
                          touched={formik.touched}
                          isSuccess={isSuccess}
                          // isLoading={isLoading}
                          loading={defaultaddress_isLoading || cartItems?.isLoading}
                          getGIGResult={getGIGResult}
                          data={data}
                        />
                      </LazyLoad>

                      {/* PAYMENT OPTION SECTION */}
                      <LazyLoad>
                        <PaymentOption
                          handleSubmit={formik.handleSubmit}
                          isLoading={
                            defaultaddress_isLoading || cartItems?.isLoading
                          }
                          values={formik.values}
                          handleBlur={formik.handleBlur}
                          setFieldValue={formik.setFieldValue}
                          errors={formik.errors}
                          isSuccess={isSuccess}
                          orderLoading={isLoading}
                          paymentOptionEnums={paymentOptionEnums}
                        />
                      </LazyLoad>

                      {/* ORDER,SHIPMENT AND PAYMENT  SECTION */}
                      <LazyLoad>
                        <ReviewOrder
                          orderSuccess={isSuccess}
                          orderData={data}
                          values={formik.values}
                          isLoading={
                            defaultaddress_isLoading || cartItems?.isLoading
                          }
                          paymentOptionEnums={paymentOptionEnums}

                        // generateReference={geneRef_data}
                        />
                      </LazyLoad>
                    </Fragment>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Container>
        </div>

        <ChangeAddressModal
          isChangeAddress={isChangeAddress}
          data={profile_data}
          isSuccess={profile_isSuccess}
          isLoading={changeAddress_isLoading}
          setSelectedAddress={setSelectedAddress}
          handleCloseChange={handleCloseChange}
          makeDefault={makeDefault}
          setIsNewAddress={setIsNewAddress}
        />

        <Modal
          show={isNewAddress}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          dialogClassName="prompt__dialog"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="_heading">New Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="p-2">
              <AddressForm
                isModal={true}
                defaultAddress={!defaultaddress_data ? true : false}
                onClose={() => setIsNewAddress(false)} />
            </Row>
            <br />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default withCheckoutLayout(CheckoutOnePage);
