import classnames from "classnames";

export function PrevBannerArrow(props: any) {
    const { className, onClick } = props;
    return (
        <button type="button" data-role="none" className={classnames(className, "ArrowButton ArrowButton_Prev")}
            style={{ display: "block" }}
            onClick={onClick}>
            Previous
        </button>
    );
}

export function NextBannerArrow(props: any) {
    const { className, onClick } = props;
    return (
        <button type="button" data-role="none" className={classnames(className, "ArrowButton ArrowButton_Next")}
            style={{ display: "block" }}
            onClick={onClick}>
            Previous
        </button>
    );
}

export function BackArrow(props: any) {
    const { className, onClick } = props;
    return (
        <button type="button" data-role="none" className={classnames(className, "SliderButton SliderButton_Back")}
            style={{ display: "block" }}
            onClick={onClick}>
            Previous
        </button>
    );
}

export function NextArrow(props: any) {
    const { className, onClick } = props;
    return (
        <button type="button" data-role="none" className={classnames(className, "SliderButton SliderButton_Next")}
            style={{ display: "block" }}
            onClick={onClick}>
            Previous
        </button>
    );
}