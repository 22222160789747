import React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'react-bootstrap';
import {
  LazyLoadComponent,
} from "react-lazy-load-image-component";

import ProfileSideBar from './component/AccountSideBar';
import { useFetchUserFavoriteItemsQuery } from '../../../redux/store/services/accountApi';
import { useAppSelector } from '../../../redux/store/hook';
import ProductCardComponent from '../../../components/Product/ProductCardComponent';
import BreadCrumb from './component/BreadCrumb';
import EmptyOrder from '../../../components/Box/EmptyOrder';
import { useFetchCartItemsQuery } from '../../../redux/store/services/cartApi';
import { mapCartDataReturnQuantity } from '../../../utils';
import { withDefaultLayout } from '@ekenta/hoc';
import { PageTitle } from '@ekenta/components';
import BackButton from './component/BackButton';


const SavedItem = () => {
  const { token } = useAppSelector(state => state.auth.authentication);

  const { data, isLoading, isFetching, isError, error, isSuccess } = useFetchUserFavoriteItemsQuery(token, { skip: !token });
  const { data: allCartData } = useFetchCartItemsQuery(token, { skip: !token });

  const recalibrate = (data: any) => {
    const newArr = []
    for (let i = (data.length - 1); i >= 0; i--) {
      newArr.push(data[i])
    }
    return newArr
  }

  return (
    <>
      <Helmet>
        <title>Saved Item - Ekenta</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Ekenta Update Address Page" />
        <meta name="description"
          content="Ekenta Update Account Address Page"
        />
        <meta property="og:locale" content="en_NG" />
        <meta name="robots" content="noindex,follow" />
      </Helmet>
      <Container className="account-user-container">
        <><BreadCrumb activePage="Saved Item" /></>

        <Row className="account-pro-row g-2">
          <Col xs lg={3} className="d-none d-lg-block account-col-left">
            <ProfileSideBar isLoading={null} />
          </Col>
          <Col className="account-profile-col-right px-0">
            <div className="mb-0">
              <PageTitle
                title="Saved Items"
                margin={false}
                subTitle={<BackButton />}
              />
            </div>
            <div className="div util-clearfix-content mt-3">
              <Row className="product_vid_commponent p-0 m-0">
                {isSuccess && data ?
                  <>
                    {data.products.length > 0 ? data.products.map((item: any) => {
                      return (
                        <LazyLoadComponent key={item.id}>
                          <ProductCardComponent
                            key={item.id}
                            productId={item.id}
                            product={item}
                            layoutView={'grid'}
                            cartItem={mapCartDataReturnQuantity(allCartData, item.id)}
                            productname={item.name}
                            defaultprice={item.price}
                            discount={item.discount}
                            discountPercentage={item.discountPercentage}
                            maxPrice={item.maxPrice}
                            minPrice={item.minPrice}
                            thumbnailUrl={
                              item.imageUrls ? item.imageUrls[0] : ''
                            }
                            imageUrl={item.imageUrls ? item.imageUrls[1] : ''}
                            videoUrl={item.videoUrl}
                            condition={item.condition}
                            savedItems={isSuccess ? data.products : []}
                          />
                        </LazyLoadComponent>
                      )
                    }) : null}
                  </>
                  : null}
                {isSuccess && data.products.length < 0 ? <EmptyOrder orderType='Complete' /> : null}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withDefaultLayout(SavedItem);