import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../constants/index';
import { changeUserPasswordType } from '../../../interfaces/Authentication';


const accountApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`

});

const createMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: accountApiHeaders(token),
    method: method,
    body: body
});

const createQueryRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
    url: url,
    headers: accountApiHeaders(token),
    method: method
});

const createMutationRequestWithoutBody = ({ url, method, token }: { url: string, method: string, token: string }) => {
    return {
        url: url,
        headers: accountApiHeaders(token),
        method: method,
    }
};

const createQueryRequestWithoutToken = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json',
    },
    method: method
});

// /api/seller-merchant-details
// /api/admin/fetch-all-seller-daily-total-order-accounting
// add weight to the ui of product listing 
export const accountApi: any = createApi({
    reducerPath: 'accountApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Address', 'Account'],
    endpoints: (builder) => ({
        fetchUserProfileDetails: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/account`, method: 'GET', token: token }),
            providesTags: ['Account']
        }),
        fetchUserFavoriteItems: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/viewFavorites`, method: 'GET', token: token })
        }),
        fetchAllState: builder.query<void, string>({
            query: () => createQueryRequestWithoutToken({ url: `/api/fetchAllState`, method: 'GET' })
        }),
        fetchCitiesByStateId: builder.query<void, { stateId: string }>({
            query: ({ stateId }) => createQueryRequestWithoutToken({ url: `/api/city/state/${stateId}`, method: 'GET' })
        }),
        fetchUserDefaultAddress: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/address/defaulted`, method: 'GET', token: token }),
            providesTags: ['Address']
        }),
        fetchUserActiveOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `api/orderHistory/active`, method: 'GET', token: token })
        }),
        fetchUserClosedOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `api/orderHistory/closed`, method: 'GET', token: token })
        }),
        fetchUserCompletedOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `api/orderHistory/completed`, method: 'GET', token: token })
        }),
        changeUserPassword: builder.mutation<void, { data: changeUserPasswordType; token: string; }>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/account/change-password`, method: 'POST', body: data, token: token })
        }),
        addNewUserAddress: builder.mutation<void, { data: { cityId: string; stateId: string; defaultAddress: boolean; homeAddress: string }; token: string }>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/address/new`, method: 'POST', body: data, token: token }),
            invalidatesTags: ['Account']
        }),
        addNewReturnRequest: builder.mutation<void, { data: { comment: string; action: string; reason: string; }; token: string, orderItemId: string }>({
            query: ({ data, token, orderItemId }) => createMutationRequest({ url: `/api/productReturnRequest/${orderItemId}`, method: 'POST', body: data, token: token })
        }),
        fetchProductToReturn: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/fetch-orders-within-policy-window`, method: 'GET', token: token })
        }),
        updateProfile: builder.mutation({
            query: ({ data, token }) => createMutationRequest({ url: '/api/update-user-phone-number', method: 'PUT', body: data, token: token }),
            invalidatesTags: ['Account']
        }),
        changeDefaultAddress: builder.mutation<void, { token: string, id: string }>({
            query: ({ id, token }) => createMutationRequest({ url: `/api/address/change-default/${id}`, method: 'PUT', body: null, token: token }),
            invalidatesTags: ['Address']
        }),
        updateUserAddress: builder.mutation<void, { data: { cityId: string; stateId: string; defaultAddress: boolean; homeAddress: string, id: string }; token: string }>({
            query: ({ data, token }) => createMutationRequest({ url: `/api/address/update`, method: 'PUT', body: data, token: token }),
            invalidatesTags: ['Address']
        }),
        deleteUserAddress: builder.mutation<void, { id: string; token: string }>({
            query: ({ id, token }) => createMutationRequestWithoutBody({ url: `/api/address/delete/${id}`, method: 'PUT', token: token }),
            invalidatesTags: ['Address']
        }),
        fetchAllAddresses: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `/api/address/user`, method: 'GET', token: token }),
            providesTags: ['Address']
        }),
        fetchUserAllOrder: builder.query<void, string>({
            query: (token) => createQueryRequest({ url: `api/list-order-History`, method: 'GET', token: token })
        }),
    })
});

export const {
    useFetchUserProfileDetailsQuery,
    useFetchUserFavoriteItemsQuery,
    useFetchUserActiveOrderQuery,
    useFetchUserClosedOrderQuery,
    useFetchUserCompletedOrderQuery,
    useFetchUserDefaultAddressQuery,
    useFetchAllStateQuery,
    useFetchCitiesByStateIdQuery,
    useFetchProductToReturnQuery,
    useUpdateUserProfileMutation,
    useAddNewUserAddressMutation,
    useChangeUserPasswordMutation,
    useAddNewReturnRequestMutation,
    useUpdateProfileMutation,
    useChangeDefaultAddressMutation,
    useUpdateUserAddressMutation,
    useDeleteUserAddressMutation,
    useFetchAllAddressesQuery,
    useFetchUserAllOrderQuery,
} = accountApi;