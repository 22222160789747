import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FiEdit3 } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import ProfileSideBar from "../component/AccountSideBar";
import ProfileLoader from "../../../../components/SkeletonLoader/Account/ProfileLoader";
import { useAppSelector } from "../../../../redux/store/hook";
import { useFetchUserProfileDetailsQuery } from "../../../../redux/store/services/accountApi";
import {
  accountChangePasswordPathUrl,
  loginPathUrl,
} from "../../../../routes/constant";
import BreadCrumb from "../component/BreadCrumb";
import withDefaultLayout from "../../../../hoc/Layout/withDefaultLayout";
import { HelmetHeader } from "../../../../components/HelmetHeader/HelmetHeader";
import BackButton from "../component/BackButton";
import { CustomAlert, PageTitle } from "../../../../components";

import styles from "./Profile.module.scss";
import classnames from "classnames";

const Profile = () => {
  const navigate = useNavigate();
  const { ek_isAuth, token, account } = useAppSelector(
    (state) => state.auth.authentication,
  );
  const { data, isLoading, isFetching, isError, error, isSuccess } =
    useFetchUserProfileDetailsQuery(token, {
      skip: !token,
    });
  const urlPath = "/user/account/profile/edit";


  useEffect(() => {
    if (!ek_isAuth && !token) {
      return navigate(loginPathUrl);
    }
  }, [ek_isAuth, token]);

  return (
    <>
      <HelmetHeader
        title="Manage Profile "
        description="Ekenta Profile Account Page"
      />

      <Container className="account-user-container">
        <>
          <BreadCrumb activePage="Manage Profile" />
        </>

        <Row className={classnames(styles.ProfileRow__Parent, "account-pro-row g-2")}>

          <Col xs lg={3} className="d-none d-lg-block account-col-left">
            <ProfileSideBar isLoading={isLoading} />
          </Col>
          <Col lg={9} className="account-profile-col-right">
            <Col className="mx-1 mx-lg-0">
              <PageTitle
                title="Profile"
                margin={false}
                subTitle={<BackButton />}
              />
            </Col>

            {isLoading || isFetching ? (
              <ProfileLoader />
            ) : isSuccess ? (
              <>
                <Row className="account-row">
                  <Col xs={12} lg={6}>
                    <Card>
                      <Card.Body>
                        <div className="aD_de_">
                          <h5 className="_mini_header_">Account Details</h5>
                          <FiEdit3
                            onClick={() => navigate(`${urlPath}/`)}
                            className="aD_icon"
                          />
                        </div>
                        <div className="aD_n_">
                          <h5>
                            {account
                              ? account.user.firstName +
                              " " +
                              account.user.lastName
                              : ""}
                          </h5>
                          <p className="_body_text">
                            {account ? account.user.email : "undefined"}
                          </p>
                          <p className="_body_text">
                            {data ? data.phoneNumber : "undefined"}
                          </p>
                          <p className="_body_text">
                            {data ? data.gender : "undefined"}
                          </p>
                        </div>
                        <div className="aE_pas_">
                          <Link to={accountChangePasswordPathUrl}>
                            Change Password
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : null}
            {isError && error.status === "FETCH_ERROR" ? (
              <CustomAlert
                variant="danger"
                show={true}
                message={"Failed to fetch, Try again"}
              />
            ) : (
              ""
            )}
            {isError && error.status === 401 ? (
              <CustomAlert
                variant="danger"
                show={true}
                message={error.data.detail}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withDefaultLayout(Profile);
