import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as queryString from "query-string";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { VscArrowSmallLeft } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router";
import { withDashboardLayout } from "@ekenta/hoc";
import { useAppSelector } from "@ekenta/redux/store/hook";
import {
  useApproveSellerRequestMutation,
  useBlockSellerRequestMutation,
  useFetchSellerDetailsQuery,
  useUpdateSellerProfileMutation,
} from "@ekenta/redux/store/services/adminSellerApi";

import { HelmetHeader, PageTitle } from "@ekenta/components";

const SellerDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const query = queryString.parse(window.location.search);
  const type = query.type;

  const { token } = useAppSelector((state) => state.auth.authentication);
  const [ApproveSeller, data] = useApproveSellerRequestMutation();
  const [blockSeller, seller] = useBlockSellerRequestMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("PENDING");
  const merchantId = params.id;
  const result = useFetchSellerDetailsQuery({ merchantId, token });
  const [updateSellerProfile, response] = useUpdateSellerProfileMutation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [sellerInfo, setSellerInfo] = useState<any>(null);
  const [liveLink, setLiveLink] = useState<any>(null);


  useEffect(() => {
    if (response?.isError) {
      console.log(response?.isError);
    }

    if (result?.isSuccess) {
      const data = result?.data;
      setSellerInfo(data);
      setLiveLink(data.liveLink);
    }
    if (result?.Error) {
      console.log("error");
    }
  }, [result?.isSuccess, response?.isError]);

  if (response?.isSuccess) {
    setIsLoading(false);
    const data = response?.data;
    console.log(data);
    // setLiveLink(data.liveLink);
    // window.scrollTo(0, 0)
  }

  const formik = useFormik({
    initialValues: {
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    },

    onSubmit: (values) => {
      setIsLoading(true);
      const groupData = {
        merchantid: result.data.merchantOwner.id,
        token: token,
      };
      if (status === "BLOCKED") {
        blockSeller(groupData);
        setIsLoading(false);
        setSuccess(false);
        setError(true);
      }

      if (status === "ACTIVE") {
        ApproveSeller(groupData);
        setIsLoading(false);
        setSuccess(true);
        setError(false);
      }

      if (liveLink && sellerInfo) {
        const obj = {
          ...sellerInfo,
          liveLink,
        };

        updateSellerProfile({
          data: obj,
          merchantOwnerId: merchantId,
          token: token,
        });
      }

      window.scrollTo(0, 0);

      // pass in the data
    },
  });

  return (
    <>
      <HelmetHeader
        title="Seller Details"
        description="Ekenta Seller Detail Page"
      />
      <Col className=" col-lg-11 mx-auto _listing_product_ dash_top px-2">
        <div className="mt-0">
          <Col className="AppEvents_col mx-1 mx-lg-0">
            <PageTitle
              title="Seller Details"
              subTitle={
                <span
                  className="cursor-pointer d-flex w-auto align-items-center 
                      text-muted"
                  onClick={() => navigate(-1)}
                >
                  <VscArrowSmallLeft />
                  Back to {type === "awaiting" ? "Awaiting Approval" : "All Active Seller"}
                </span>
              }
            />
          </Col>

          <Col className="mt-1 tab__">
            <br />
            {success ? (
              <Alert variant={"success"}>Seller Approved Successfully</Alert>
            ) : (
              ""
            )}

            {error ? (
              <Alert variant={"danger"}>Seller Blocked Successfully</Alert>
            ) : (
              ""
            )}

            {response?.isSuccess ? (
              <Alert variant={"success"}>
                Seller Detail Updated Successfully
              </Alert>
            ) : (
              ""
            )}

            {result.isSuccess ? (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row className="mt-2">
                  <Col lg={12}>
                    <h5 style={{ fontSize: "17px" }}>Personal Details</h5>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3 mt-1">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          result.data?.merchantOwner?.firstName +
                          " " +
                          result.data?.merchantOwner?.lastName
                        }
                        placeholder="Enter Name"
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.merchantOwner?.username}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.merchantOwner?.phoneNumber}
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.merchantOwner?.email}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row className="mt-5">
                  <Col lg={12}>
                    <h5 style={{ fontSize: "17px" }}>Business Details</h5>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3 mt-1">
                      <Form.Label>Address 1</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          result.data?.merchantOwner?.addresses.length ?
                            result.data?.merchantOwner?.addresses[0].homeAddress
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>District</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          result.data?.merchantOwner?.addresses.length ?
                            result.data?.merchantOwner?.addresses[0].homeAddress
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Country"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="formBasictext">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          ""
                          // result.data?.merchantOwner?.addresses.length ?
                          //   result.data?.merchantOwner?.addresses[0].cityName.name
                          //   : ""
                        }
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          ""
                          // result.data?.merchantOwner?.addresses.length ?
                          //   result.data?.merchantOwner?.addresses[0].state.name
                          //   : ""
                        }
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Business Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={result.data?.contactPersonPhoneNumber}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                {type === "awaiting" && (
                  <Row className="mt-5">
                    <Col lg={12}>
                      <h5>Store Settings</h5>
                    </Col>
                    <Col lg={6}>
                      <img src={result.data?.merchantOwner?.imageUrl} alt="" />
                    </Col>

                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Status</Form.Label>
                        <Form.Select onChange={(e) => setStatus(e.target.value)}>
                          <option value={"PENDING"}>Pending</option>
                          <option value={"ACTIVE"}>Active</option>
                          <option value={"BLOCKED"}>Blocked</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {type === "active" && (
                  <>
                    <Row className="mt-2 mb-4">
                      <Col lg={6}>
                        <h5>EkeLive</h5>
                      </Col>
                      <Col lg={6}>
                        <Form.Control
                          type="text"
                          placeholder="EkeLive"
                          value={
                            result.data?.merchantOwner?.isSellerLiveForEkeMarket
                          }
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                      <Col lg={6}>
                        <h5>Live Link</h5>
                      </Col>
                      <Col lg={6}>
                        <Form.Control
                          type="text"
                          placeholder="Live Stream Link"
                          value={liveLink}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </>
                )}

                <hr />
                <Row className="mt-5">
                  <Col lg={12} style={{ fontSize: "17px" }}>
                    <h5>Bank Account</h5>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Bank </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bank"
                        value={result.data?.bankName}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Account Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Account Name"
                        value={result.data?.bankAccountName}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Account Number"
                        value={result.data?.bankAccountNumber}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {type === "awaiting" && (
                  <Row>
                    <Col>
                      <Button variant="outline-danger">Cancel</Button>
                      {"     "}
                      <Button variant="primary text-white" type="submit">
                        {isLoading ? (
                          <div className="d-flex align-items-center justify-content-center">
                            <Spinner animation="grow" />
                            <span className="ms-1 text-sm">
                              <small>Please Wait...</small>
                            </span>
                          </div>
                        ) : (
                          <span>Update</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                )}
                <br />
                <br />
              </Form>
            ) : (
              "Loading"
            )}
          </Col>
        </div>
      </Col>
    </>
  );
};

export default withDashboardLayout(SellerDetails);
