import React, { Fragment } from 'react'
import classnames from 'classnames'
import { ButtonPropsType } from '@ekenta/interfaces'
import Tooltip from '../Tooltips/Tooltips'
import styles from "./Button.module.scss";
import Loader from '../Loader/Loader';


const Button = ({
  as = "default",
  tooltipContent,
  prefixIcon,
  suffixIcon,
  onClick,
  name,
  className,
  bgColor,
  textColor,
  bgVariant,
  textVariant,
  width = "w-auto",
  style,
  type = "button",
  disabled = false,
  defaultClass = true,
  shadow = true,
  isLoadingText,
  isLoading,
  children,

}: ButtonPropsType) => {
  const mainClass = classnames(bgVariant ? `bg-${bgVariant}` : '', textVariant ? `text-${textVariant}` : '', {
    [styles.btn]: defaultClass,
    [styles.AppButton]: defaultClass && as === "default",
    [styles.Shadow]: shadow,
    [width]: width,
    [styles.TextButton__Style]: defaultClass,
    [styles.TextButton__Style]: as === "text",
    [styles.Disabled]: disabled
  });

  return (
    <Tooltip
      content={tooltipContent ? tooltipContent : ''}
      style={!tooltipContent ? { display: 'none' } : {}}
    >
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={
          classnames("Button btn-icon", mainClass, className)}
        style={{ backgroundColor: bgColor, color: textColor, ...style, }}>
        {children ? (
          <>{children}</>
        ) : (
          <span className="Button__icon d-flex align-items-center gap-2">
            {isLoading ? (
              <Fragment>
                <Loader
                  color={`${as === "text" ? "gray" : "white"}`} />
                <span className={classnames(styles.Button__Name, "BtnSpan", {
                  [styles.ButtonName_Font]: as !== "text",
                  [styles.ButtonName_FontLight]: as === "text"
                })}>
                  {isLoadingText ? isLoadingText : "Loading.."}
                </span>
              </Fragment>)
              :
              (<Fragment>
                {prefixIcon}
                <span className={classnames(styles.Button__Name, "BtnSpan", {
                  [styles.ButtonName_Font]: as !== "text",
                  [styles.ButtonName_FontLight]: as === "text"
                })}>
                  {name}
                </span>
              </Fragment>)
            }
            {suffixIcon}
          </span>
        )}
      </button>
    </Tooltip>
  )
}

export default Button;
