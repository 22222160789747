import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useAppSelector } from '../../../../redux/store/hook';
import { useUpdateSellerProfileMutation } from '../../../../redux/store/services/adminSellerApi';
import toast from 'react-hot-toast';

interface ActiveSellerDialogPropsType {
  data: any | null;
  show: boolean;
  onClose: () => void;
}

export default function ActiveSellerDialog({
  data,
  show,
  onClose,
}: ActiveSellerDialogPropsType) {
  const { token } = useAppSelector((state: any) => state.auth.authentication);
  const [updateSellerProfile, getResult] = useUpdateSellerProfileMutation();
  const [initialValues, setInitialValues] = useState({
    liveLink: '',
    liveKey: '',
  });

  useEffect(() => {
    if (data) {
      const _livelink = data.liveLink ? data.liveLink : '';
      const _livekey = data.liveKey ? data.liveKey : '';
      setInitialValues((prevState) => ({
        ...prevState,
        liveLink: _livelink,
        liveKey: _livekey,
      }));
    }

    if (getResult?.isSuccess) {
      toast.success(<div className="cus_t">Successfully Updated</div>);
      //toastDisplay(true);
      onClose();
    }

    if (getResult?.isError) {
      toast.error(<div className="cus_t">Error Ocurred while updating</div>);
      //toastDisplay(true);
    }
  }, [data, getResult?.isSuccess, getResult?.isError]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      liveLink: Yup.string(),
      liveKey: Yup.string(),
    }),
    onSubmit: (values) => {
      if (values.liveLink) {
        const newData = {
          ...data,
          liveLink: values.liveLink,
          liveKey: values.liveKey,
        };
        updateSellerProfile({
          data: newData,
          merchantOwnerId: data.merchantOwner.id,
          token: token,
        });
        setTimeout(() => {
          handleClose();
        }, 1000);
      }
    },
  });

  const handleClose = () => {
    setInitialValues({ ...initialValues, liveLink: '', liveKey: '' });
    onClose();
  };

  return (
    <Modal dialogClassName="prompt__dialog" show={show} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit LiveCode & Key</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div>
            <Form.Group className="mb-3 mt-2 form--group">
              <Form.Control
                className="form-input-contol"
                type="text"
                placeholder="Enter Livecode"
                autoComplete="none"
                name="liveLink"
                value={formik.values.liveLink}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                max={20}
                autoFocus
              />
              {formik.errors.liveLink ? (
                <div className="invalid-fbk">{formik.errors.liveLink}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3 mt-2 form--group">
              <Form.Control
                className="form-input-contol"
                type="text"
                placeholder="Enter Livekey"
                autoComplete="none"
                name="liveKey"
                value={formik.values.liveKey}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                max={20}
                autoFocus
              />
              {formik.errors.liveKey ? (
                <div className="invalid-fbk">{formik.errors.liveKey}</div>
              ) : null}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} className="cancel_btn" variant="">
            Cancel
          </Button>
          <Button
            type="submit"
            className={`confirm_btn ${getResult?.isLoading ? 'disabled' : ''}`}
            variant="primary"
          >
            {getResult?.isLoading && (
              <Spinner
                className="me-1"
                size="sm"
                animation="border"
                variant="light"
              />
            )}
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
