
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../constants/index';


const generalApiHeaders = (token: string) => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
});

const createQueryRequest = ({ url, method }: { url: string, method: string }) => ({
    url: url,
    headers: {
        'Content-Type': 'application/json'
    },
    method: method
});

const createQueryWithTokenRequest = ({ url, method, token }: { url: string, method: string, token: string }) => ({
    url: url,
    headers: generalApiHeaders(token),
    method: method,
});


const createMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => {
    return {
        url: url,
        headers: generalApiHeaders(token),
        method: method,
        body: body
    }
};

const createFormDataMutationRequest = ({ url, method, body, token }: { url: string, method: string, body: any, token: string }) => ({
    url: url,
    headers: {
        "Authorization": `Bearer ${token}`
    },
    method: method,
    body: body
});

const createMutationRequestWithoutBody = ({ url, method, token }: { url: string, method: string, token: string }) => {
    return {
        url: url,
        headers: generalApiHeaders(token),
        method: method,
    }
};

export const collectionApi: any = createApi({
    reducerPath: 'collectionApi',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Collection'],
    endpoints: (builder) => ({
        fetchAllCollections: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/collection/all`, method: 'GET' }),
            providesTags: ['Collection'],
        }),
        fetchAdminAllCollections: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/admin/collection/all`, method: 'GET', token: token }),
            providesTags: ['Collection'],
        }),
        // 
        fetchAllSubCollectionsByCollection: builder.query<void, string>({
            query: (categoryId) => createQueryRequest({ url: `/api/sub-collection-category/collection-id/${categoryId}`, method: 'GET' })
        }),
        fetchSingleSubCollection: builder.query<void, string>({
            query: (subCollectionId) => createQueryRequest({ url: `/api/sub-collection/${subCollectionId}`, method: 'GET' }),
            providesTags: ['Collection'],
        }),
        fetchSingleSubCollectionCategory: builder.query<void, string>({
            query: (categoryId) => createQueryRequest({ url: `/api/sub-collection-category/${categoryId}`, method: 'GET' }),
            providesTags: ['Collection'],
        }),
        fetchAllBrands: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/brand/all`, method: 'GET' })
        }),
        fetchAllFeatures: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/feature/all`, method: 'GET', token: token })
        }),
        fetchAllUnits: builder.query<void, string>({
            query: (token) => createQueryWithTokenRequest({ url: `/api/weight/all-units`, method: 'GET', token: token })
        }),

        addNewProduct: builder.mutation<any, any>({
            query: ({ data, token }) => createMutationRequest({ url: '/api/product/new', method: 'POST', body: data, token: token })
        }),
        addNewProductThumbnail: builder.mutation<any, any>({
            query: ({ data, productId, token }) => createFormDataMutationRequest({ url: `/api/product/thumbnail/${productId}`, method: 'POST', body: data, token: token })
        }),



        addNewProductVideo: builder.mutation<any, any>({
            query: ({ data, productId, token }) => createFormDataMutationRequest({ url: `/api/product/video/${productId}`, method: 'PUT', body: data, token: token })
        }),
        addNewMultipleProductImages: builder.mutation<any, any>({
            query: ({ data, productId, token }) => createFormDataMutationRequest({ url: `/api/product/images/${productId}`, method: 'PUT', body: data, token: token })
        }),


        fetchBrandsByCollection: builder.query<void, { id: string }>({
            query: ({ id }) => createQueryRequest({ url: `/api/collection/with-brands/${id}`, method: 'GET' })
        }),
        addNewCollection: builder.mutation<any, any>({
            query: ({ data, token }) => createMutationRequest({ url: '/api/collection/new', method: 'POST', body: data, token: token })
        }),
        fetchAllSubCollections: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/sub-collection/all`, method: 'GET' })
        }),
        fetchAllSubCollectionCategories: builder.query<void, string>({
            query: () => createQueryRequest({ url: `/api/sub-collection-category/all`, method: 'GET' })
        }),
        addNewSubCollection: builder.mutation<any, any>({
            query: ({ data, token }) => createMutationRequest({ url: '/api/sub-collection/new', method: 'POST', body: data, token: token })
        }),
        addNewSubCollectionCategory: builder.mutation<any, any>({
            query: ({ data, token }) => createMutationRequest({ url: '/api/sub-collection-category/new', method: 'POST', body: data, token: token }),
        }),
        fetchSingleCollections: builder.query<void, { id: string }>({
            query: ({ id }) => createQueryRequest({ url: `/api/collection/${id}`, method: 'GET' }),
            providesTags: ['Collection'],
        }),
        updateCollection: builder.mutation<any, any>({
            query: ({ collectionId, data, token }) => createMutationRequest({ url: `/api/admin/product/collection/update/${collectionId}`, method: 'PUT', body: data, token: token })
        }),
        editSubCollection: builder.mutation<any, any>({
            query: ({ subCollectionId, data, token }) => createMutationRequest({ url: `/api/admin/product/sub-collection/update/${subCollectionId}`, method: 'PUT', body: data, token: token })
        }),
        editSubCollectionCategory: builder.mutation<any, any>({
            query: ({ subCollectionCategoryId, data, token }) => createMutationRequest({ url: `/api/admin/product/sub-collection-category/update/${subCollectionCategoryId}`, method: 'PUT', body: data, token: token })
        }),
        deleteCollection: builder.mutation<any, any>({
            query: ({ collectionId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/product/collection/${collectionId}`, method: 'PUT', token: token })
        }),
        deleteSubCollection: builder.mutation<any, any>({
            query: ({ subCollectionId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/product/sub-collection/${subCollectionId}`, method: 'PUT', token: token })
        }),
        deleteSubCollectionCategory: builder.mutation<any, any>({
            query: ({ subCollectionCategoryId, token }) => createMutationRequestWithoutBody({ url: `/api/admin/product/sub-collection-category/${subCollectionCategoryId}`, method: 'PUT', token: token })
        }),
    })
})


export const {
    useAddNewProductMutation,
    useAddNewProductThumbnailMutation,
    useAddNewProductVideoMutation,
    useAddNewMultipleProductImagesMutation,


    useFetchAllCollectionsQuery,
    useFetchSingleSubCollectionQuery,
    useFetchSingleSubCollectionCategoryQuery,
    useFetchAllBrandsQuery,
    useFetchAllFeaturesQuery,
    useFetchAllUnitsQuery,
    useFetchBrandsByCollectionQuery,
    useFetchAllSubCollectionsByCollectionQuery,
    useAddNewCollectionMutation,
    useFetchAllSubCollectionsQuery,
    useAddNewSubCollectionMutation,
    useFetchAllSubCollectionCategoriesQuery,
    useAddNewSubCollectionCategoryMutation,
    useFetchSingleCollectionsQuery,
    useUpdateCollectionMutation,
    useEditSubCollectionMutation,
    useEditSubCollectionCategoryMutation,
    useDeleteCollectionMutation,
    useDeleteSubCollectionMutation,
    useDeleteSubCollectionCategoryMutation,
    useFetchAdminAllCollectionsQuery
} = collectionApi;