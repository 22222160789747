import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useFetchUserAllOrderQuery, useFetchUserCompletedOrderQuery } from '../../../../../redux/store/services/accountApi';
import OrderSkeletonLoader from '../../../../../components/SkeletonLoader/Account/OrderSkeletonLoader';
import EmptyOrder from '../../../../../components/Box/EmptyOrder';
//import SingleOrderCard from './SingleOrderCard';
import MainOrder from './mainOrder';

interface CompleteOrderPropsType {
  token: string | null;
}

export default function CompleteOrder({ token }: CompleteOrderPropsType) {
  // const {data, isLoading, isFetching, isError, error, isSuccess}  = useFetchUserCompletedOrderQuery(token, {skip: !token});
  const { data, isLoading, isFetching, isError, isSuccess } =
    useFetchUserAllOrderQuery(token, { skip: !token });

  const recalibrate = (data: any) => {
    const newArr = [];
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i]?.orderStatus == 'COMPLETED') {
        newArr.push(data[i]);
      }
    }
    return newArr;
  };

  return (
    <div className="w-100">
      {isLoading || isFetching ? (
        <OrderSkeletonLoader />
      ) : (
        <>
          {isError ? <>We could not fetch this at this moment</> : null}
          <Accordion>
            {isSuccess && data ? (
              recalibrate(data).length > 0 ? (
                recalibrate(data).map((item: any) => {
                  if (item?.orderStatus == 'COMPLETED') {
                    return (
                      <MainOrder itemData={item} />
                      //  <SingleOrderCard
                      //    key={item.id}
                      //    orderId={item.id}
                      //    thumbnailUrl={item.productImgUrl}
                      //    name={item.productName}
                      //    quantity={item.quantity}
                      //    orderStatus={item.orderStatus}
                      //    date={item.createdDate}
                      //    orderNumber={item.orderNumber} isPending={true}/>
                    );
                  } else {
                    null;
                  }
                })
              ) : (
                <EmptyOrder orderType="Completed" />
              )
            ) : null}
          </Accordion>
        </>
      )}
    </div>
  );
}
