import React, { memo, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { SellerOnboardBusinessDetailType } from '../../../../../interfaces/sellerOnboarding.interface';
import { VscArrowSmallLeft, VscArrowSmallRight } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { loginPathUrl } from '../../../../../routes/constant';
import { getIn } from 'formik';
import { useFetchAllStateQuery } from '../../../../../redux/store/services/accountApi';
import { useFetchCitiesByStateIdQuery } from '../../../../../redux/store/services/onboardingApi';
import CustomSelect from '../../../../../components/Select/CustomSelect';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../../../../../redux/store/hook';
import styled from 'styled-components';
import { Plus, Pencil } from '@ekenta/components/icons';


const mapStateData = (data: any) => {
    return data && data.map((item: any) => (
        {
            value: item.id,
            label: item.name
        }
    ));

}


const BusinessDetail = ({ handleChange, handleBlur, values, errors, touched, setFieldValue,
    setVatFile,
    setLegalForm,
    nextPage,
    prevPage }: SellerOnboardBusinessDetailType
) => {

    const { ek_isAuth } = useAppSelector(state => state.auth.authentication);

    const [stateId, setStateId] = useState<null | string>(null);
    const [stateOptions, setStateOption] = useState<any>(null);
    const [cityOptions, setCityOption] = useState(null);
    const [previewImage, setPreviewImage] = useState<any>(null);

    const { data, isLoading, isFetching, isError, } = useFetchAllStateQuery();
    const { data: fetchCity_data, isLoading: fetchCity_isLoading, isFetching: fetchCity_isFetching, isError: fetchCity_isError }
        = useFetchCitiesByStateIdQuery({ stateId }, { skip: !stateId });


    const defaultValue = (options: any, val: string) => {
        return options ? options.find((option: any) => option.value === val) : "";
    }


    useEffect(() => {
        if (data) {
            const objs = mapStateData(data);
            setStateOption(objs);
        }

        if (fetchCity_data) {
            const resObj = mapStateData(fetchCity_data);
            setCityOption(resObj);
        }

    }, [data, fetchCity_data]);

    const handleStateChange = (value: string) => {
        setStateOption(null);
        setCityOption(null)
        setStateId(value);
    }


    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
            setFieldValue("business_detail.businessLogo", file);
        } else {
            setPreviewImage(null);
        }
    };


    return (
        <div className="seller_account_details_form_col">
            <div className="_top_header mt-1">
                <h5>Business Details</h5>
            </div>

            <Col lg={8} className="mx-auto _col_container">
                <div className=" mt-4">
                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="">
                            Business Logo
                        </Form.Label>
                        <Uploader>
                            <div className="uploader-container">
                                {previewImage ? (
                                    <div className="imageUpload-preview">
                                        <ImagePreview
                                            src={previewImage}
                                        />
                                        <button>
                                            <input type="file" accept='image/jpg,image/png' onChange={handleImageChange} />
                                            <Pencil />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="empty-upload">
                                        <h5>Upload Your Logo</h5>
                                        <button>
                                            <input type="file" accept='image/jpg,image/png' onChange={handleImageChange} />
                                            <Plus />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Uploader>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4  position-relative">
                        <Form.Label>
                            Business Name  <span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="business_detail.businessname" placeholder=""
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.businessname}
                            //isInvalid={!!getIn(errors, 'business_detail.businessname')}
                            />
                            {getIn(touched, 'business_detail.businessname') &&
                                getIn(errors, 'business_detail.businessname') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.business_detail?.businessname}
                                </Form.Control.Feedback>
                            ) : null}

                            <Form.Text className=" text-muted">
                                Choose a distinctive name for your online space: this will be the name that appears on the  Seller space
                                It is prohibited to use a registered trademark in your business name without the permission of the brand.
                            </Form.Text>
                        </Col>

                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 position-relative">
                        <Form.Label >
                            Address <span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="business_detail.address" placeholder=""
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.address}
                            />
                            {getIn(touched, 'business_detail.address') &&
                                getIn(errors, 'business_detail.address') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.business_detail?.address}
                                </Form.Control.Feedback>
                            ) : null}

                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label>
                            Country<span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <Form.Select
                                name="business_detail.country"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.country}
                                isInvalid={!!getIn(errors, 'business_detail.country')}
                            >
                                <option value="">Select Country</option>
                                <option value="nigeria">Nigeria</option>
                            </Form.Select>
                            {getIn(touched, 'business_detail.country') &&
                                getIn(errors, 'business_detail.country') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.business_detail?.country}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4 position-relative">
                        <Form.Label>
                            State <span>*</span>
                        </Form.Label>
                        <Col sm="12" >
                            <Suspense fallback={<Spinner animation='border' size='sm' />}>
                                <CustomSelect
                                    className="custom-select"
                                    placeholder="Select State"
                                    isLoading={isLoading && isFetching}
                                    noOptionsMessage={() => isError ? 'Could not load, refresh' : 'No State Found'}
                                    value={stateOptions ? defaultValue(stateOptions, values.business_detail.state.id) : ''}
                                    options={stateOptions ? stateOptions : []}
                                    onBlur={handleBlur}
                                    onChanged={(value: any) => {
                                        setFieldValue('business_detail.state', { id: value.value, name: value.label });
                                        handleStateChange(value.value);
                                    }}
                                />

                                {getIn(errors, 'business_detail.state') ? (
                                    <div className="invalid-fbk">
                                        {getIn(errors, 'business_detail.state')}
                                    </div>
                                ) : null}
                            </Suspense>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-4 position-relative">
                        <Form.Label>
                            City/Town <span>*</span>
                        </Form.Label>
                        <Col sm="12" >
                            <Suspense fallback={<Spinner animation='border' size='sm' />}>
                                <CustomSelect
                                    isDisabled={cityOptions ? false : true}
                                    className="custom-select"
                                    placeholder="Select City"
                                    isLoading={fetchCity_isLoading && fetchCity_isFetching}
                                    noOptionsMessage={() => fetchCity_isError ? 'Could not load, refresh' : 'No City Found'}
                                    value={cityOptions ? defaultValue(cityOptions, values.business_detail.city_or_town.id) : ''}
                                    options={cityOptions ? cityOptions : []}
                                    onBlur={handleBlur}
                                    onChanged={(value: any) => {
                                        setFieldValue('business_detail.city_or_town', { id: value.value, name: value.label })
                                    }
                                    }
                                />
                                {getIn(errors, 'business_detail.city_or_town') ? (
                                    <div className="invalid-fbk">
                                        {getIn(errors, 'business_detail.city_or_town')}
                                    </div>
                                ) : null}
                            </Suspense>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="">
                            Business Phone Number <span>*</span>
                        </Form.Label>
                        <Col sm="12">
                            <NumberFormat
                                customInput={Form.Control}
                                name="business_detail.business_phone_number"
                                value={values.business_detail.business_phone_number}
                                format="###########"
                                allowNegative={false}
                                onValueChange={(value: any) => {
                                    setFieldValue('business_detail.business_phone_number', value.value);
                                }} />
                            {getIn(touched, 'business_detail.business_phone_number') &&
                                getIn(errors, 'business_detail.business_phone_number') ? (
                                <div className="invalid-fbk">
                                    {errors.business_detail?.business_phone_number}
                                </div>
                            ) : null}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3 mt-2 position-relative">
                        <Form.Label className="">
                            Business Registration Number
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="business_detail.business_registration_number"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.business_registration_number}
                            //isInvalid={!!getIn(errors, 'business_detail.business_registration_number')}
                            />
                            {getIn(touched, 'business_detail.business_registration_number') &&
                                getIn(errors, 'business_detail.business_registration_number') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.business_detail?.business_registration_number}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group>

                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="">
                            VAT Information File
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="file" name="business_registration_number"
                                onChange={() => setVatFile(null)}
                            />
                        </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="">
                            VAT Registration Number
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="business_detail.vat_registration_number"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.vat_registration_number}
                                isInvalid={!!getIn(errors, 'business_detail.vat_registration_number')}
                            />
                            {getIn(touched, 'business_detail.vat_registration_number') &&
                                getIn(errors, 'business_detail.vat_registration_number') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.business_detail?.vat_registration_number}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="">
                            Seller VAT
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="seller_vat"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.seller_vat}
                                isInvalid={!!getIn(errors, 'business_detail.seller_vat')}
                            />
                            {getIn(touched, 'business_detail.seller_vat') &&
                                getIn(errors, 'business_detail.seller_vat') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.business_detail?.seller_vat}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group> */}
                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="">
                            Legal form
                        </Form.Label>
                        <Col sm="12">
                            <Form.Select name="legal_form" onChange={(e) => setLegalForm(e.target.value)}>
                                <option value="">Select Form</option>
                            </Form.Select>
                        </Col>
                    </Form.Group> */}

                    {/* <Form.Group as={Row} className="mb-3 position-relative">
                        <Form.Label className="">
                            TIN (Tax Identification No)
                        </Form.Label>
                        <Col sm="12">
                            <Form.Control type="text" name="business_detail.tax_identification_number"
                                autoComplete='off'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.business_detail.tax_identification_number}
                                isInvalid={!!getIn(errors, 'business_detail.tax_identification_number')}
                            />
                            {getIn(touched, 'business_detail.tax_identification_number') &&
                                getIn(errors, 'business_detail.tax_identification_number') ? (
                                <Form.Control.Feedback type="invalid" className="invalid-fbk">
                                    {errors.business_detail?.tax_identification_number}
                                </Form.Control.Feedback>
                            ) : null}
                        </Col>
                    </Form.Group> */}

                    <div className="_stepper_trigger_col">
                        <Button className="_button_A_ me-1" onClick={prevPage}
                            disabled={ek_isAuth}>
                            <VscArrowSmallLeft />
                            <span>Back</span>
                        </Button>
                        <Button disabled={
                            !values.business_detail.city_or_town ||
                            !values.business_detail.address ||
                            !values.business_detail.businessname ||
                            !values.business_detail.business_phone_number ||
                            getIn(errors, 'business_detail.sellername') ||
                            getIn(errors, 'business_detail.address') ||
                            getIn(errors, 'business_detail.city_or_town') ||
                            getIn(errors, 'business_detail.business_phone_number')
                        } className="_button_A_" onClick={nextPage}>
                            <span>Next</span>
                            <VscArrowSmallRight />
                        </Button>
                    </div>
                    <div className="highlight">Already have an account?
                        <Link to={loginPathUrl}> Sign in here! </Link>
                    </div>

                </div>
            </Col>
        </div>
    );
}


const Uploader = styled.div`
width: 100%;
display: flex;
justify-content: center;
  .uploader-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    background: var(--eke-bg-primary-color);
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 8px;
    border-radius: 50%;

    input {
          width: 75px;
          height: 60px;
          top: 0px;
          bottom: 0;
          opacity: 0;
          margin-left: auto;
          margin-right: auto;
          position: absolute;
          padding: 5px 9px;
          transform: rotate(45deg);
          font-size: 120%;
          //z-index: -1;
    
          &::-webkit-file-upload-button {
            visibility: hidden;
          }
          &::before {
            display: inline-block;
            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
          }
        }
  }

  .imageUpload-preview{
    position: absolute;

    button{
        position: absolute;
        bottom: 20px;
        right: 21px;
        width:30px;
        height:30px;
        outline: none;
        background-color: #fff;;
        border: 1px solid #eee;
        padding-bottom: 5px;
        overflow: hidden;

        input{
            left: -10% !important;
          padding: 5px -50px !important;
          transform:none;
        }

       

        svg{
            stroke: var(--eke-border-primary-color);  
        }
    }
  }

  .empty-upload{

      h5 {
        font-family: var(--eke-body-font-family);
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: var( --eke-body-text-light-color);
      }
    
      button {
        position: relative;
        width: 40px;
        height: 40px;
        background: var(--eke-bg-light-color);
        border-radius: 100px;
        display: flex;
        outline: none;
        border: none;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin: 0 auto;
      cursor: pointer;


    
    
        svg {
          stroke: var(--eke-border-primary-color);
        }
    
      
  }
}
`;

const ImagePreview = styled.img`
    object-fit: cover;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin: 2px;


`
export default memo(BusinessDetail);