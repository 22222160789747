import { FormikErrors, getIn } from 'formik';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { ProductListingFormType } from '../../interfaces/ProductListing';
import { formatBytes } from '../../utils';
import CustomAlert from '../Alert/CustomAlert';

interface FileUploaderPropsType {
    maxSize: number;
    placeholder: string;
    requiredText: string;
    acceptFileType: string;
    acceptFileTypeList: string[];
    maxFiles: number;
    multiple: boolean;
    fileType: string;
    setFieldValue: any;
    setFieldTouched?: any;
    touched?: any;
    name: string;
    errors?: FormikErrors<ProductListingFormType>;
}

export default function FileUploader({
    maxSize, placeholder,
    requiredText, acceptFileType,
    maxFiles,
    multiple,
    fileType,
    acceptFileTypeList,
    name,
    setFieldValue,
    setFieldTouched,
    touched,
    errors
}: FileUploaderPropsType) {

    const [fileerrors, setFileErrors] = useState<{ msg: string }[] | []>([]);
    const [fileAccepted, setFileAccepted] = useState<{ filename: string }[] | []>([]);



    useEffect(() => {

        if (fileAccepted.length > 0) {
            setFieldValue(name, fileAccepted);
        }

    }, [fileAccepted]);



    const getFileFromEvent = (e: any) => {
        const files = e.target.files;
        return files;
    }

    const validateImagesType = (e: any) => {
        setFileErrors([]);
        setFileAccepted([]);


        const files = getFileFromEvent(e);


        if (files.length > 0) {

            const _resultArr = Array.from(files);

            _resultArr.map((file: any) => {


                const _fileTypeSplit = file.type.split('/')[0];

                const fileTypeArr = [fileType];
                //const res_T = acceptFileTypeList.some(item => file.type.includes(item));
                const res_T = fileTypeArr.some(item => _fileTypeSplit.includes(item));


                if (res_T && file.type !== 'image/svg+xml' && file.type !== 'image/gif'
                    && file.type !== 'image/webp') {

                    setFileAccepted([
                        file,
                    ]);
                    setFileErrors([]);

                } else {

                    setFileErrors([
                        ...fileerrors,
                        { msg: `File Type must be ${acceptFileType}` }
                    ]);
                    setFileAccepted([]);
                }


                if (file.size > maxSize) {
                    setFileErrors([
                        ...fileerrors,
                        { msg: `File Size Exceeds ${formatBytes(maxSize)}` }
                    ])
                    setFileAccepted([]);
                }
            });

        }
    }



    const _borderColor = fileerrors.length > 0 ? "#f67f8a" : fileAccepted.length > 0 && !getIn(errors, name) ? "#83e4b8" : getIn(touched, name) && getIn(errors, name)
        ? "#f67f8a" : "#ddd";

    return (

        <Container >
            <div className='dropdiv' style={{ border: `2px dashed ${_borderColor}` }}>
                <div className="d-flex align-items-center justify-content-center mb-1">
                    <img width={'50px'} height={'50px'} src={placeholder} />
                </div>
                <p><span>Click the</span> button to select your {fileType}
                    <br /><i>{requiredText}</i>
                </p>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="upload-btn-wrapper mb-3">
                        <button className="btn">Choose file</button>
                        <input className="form-control"
                            type="file" name="files"
                            accept={acceptFileType}
                            onChange={e => {
                                validateImagesType(e)
                                setFieldTouched(name, true)

                            }}
                            multiple={multiple} />
                    </div>

                </div>
            </div>

            <div className="message_alert">



                {fileerrors.length > 0 && (<CustomAlert
                    variant='danger'
                    show={true}
                    message={<>
                        {fileerrors.map((items: any, index: number) => {
                            return (
                                <span key={'ind' + '_' + index} className="d-block mt-1 w-100"
                                    style={{ fontSize: "11px" }}>
                                    Error: {items?.msg}
                                </span>
                            )
                        })}
                    </>}
                />)}

                {fileAccepted.length > 0 && !getIn(errors, name) && (<CustomAlert
                    variant='success'
                    show={true}
                    message={<>{fileAccepted.map((items: any, index: number) => {
                        return (
                            <span key={'ind' + '_' + index} className="d-block mt-1 w-100"
                                style={{ fontSize: "11px" }}>
                                Filename:  {items.name}
                            </span>
                        )
                    })}</>}
                />)}


                {
                    getIn(touched, name) &&
                    getIn(errors, name) && (
                        <CustomAlert
                            variant='danger'
                            show={true}
                            message={<><div></div><span>{getIn(errors, name)}</span></>}
                        />
                    )}
            </div>
        </Container>
    )
}




const Container = styled.div`
 .dropdiv{
     width: 100%;
     padding: 2.0rem;
     margin: 1.3rem;
     margin-left: 0 !important;
     margin-bottom: 0 !important;
     border-radius: 10px;
     background-color: #fafafa;

     

     .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        width: auto;
        display: inline;
        cursor: pointer ;


        .btn {
            border: 2px solid #ddd;
            color: gray;
            background-color: white;
            padding: 4px 16px;
            border-radius: 8px;
            font-size: 15px;
            font-weight: bold;
            margin: 0 auto;
            cursor: pointer !important;
         }

        

         input[type=file] {
            font-size: 100px;
            width: auto;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer !important;
        }
        }


     img{
         filter: grayscale(0.8) !important;
         opacity: .3;
     }

     p{
         font-size: 14px;
         text-align: center;
         color: gray;
         opacity: .7;
         font-family: greyCliffcflight;

         i{
             font-size: 12.5px !important;
         }

         span{
             font-weight: 600;
             color: grey;
         }
     }

 }
     .message_alert{ 
         .alert{
             padding-top: 0 !important;
             padding-bottom: 0 !important;
             border-radius: 0 !important;
             margin-top: -10px !important;
             
             p{
                 padding: 0 !important;
                 span{
                     font-size: 13px !important   ;

                     li{
                         list-style: none;
                     }

                 }
             }

         }
     }
 `;
