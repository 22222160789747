import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Col, Container,Form,Button, Row } from 'react-bootstrap';
import { newreturnRequestPathUrl } from '../../../../routes/constant';
import { Helmet } from 'react-helmet'
import ProfileSideBar from '../component/AccountSideBar'
import DefaultLayout from '../../../../hoc/Layout/Default-Layout'
import { FiPlus } from 'react-icons/fi';
import EmptyRequest from './component/EmptyRequest';
import BreadCrumb from '../component/BreadCrumb';
import { useFetchProductToReturnQuery } from '../../../../redux/store/services/accountApi';
import { useAppSelector } from '../../../../redux/store/hook';

export default function ReturnRequest() {
    const navigate = useNavigate();
    // useFetchProductToReturnQuery
    const { ek_isAuth, token } = useAppSelector(state => state.auth.authentication);
    const { data, isLoading, isFetching, isError, error, isSuccess } = useFetchProductToReturnQuery(token, { skip: !token });

  return (
    <DefaultLayout>
        <Helmet>
            <title>Return Request - Ekenta</title>
            <meta charSet="utf-8" />
            <meta name="title" content="Ekenta Ureturn request" />
            <meta name="description"
                    content="Ekenta return request"
            />
            <meta property="og:locale" content="en_NG" />
            <meta name="robots" content="noindex,follow" />
        </Helmet>
        <Container  className="account-user-container">
            <><BreadCrumb activePage="Return Request" /></>

                <Row className="account-pro-row g-2">
                    <Col xs lg={3} className="d-none d-lg-block account-col-left">
                       <ProfileSideBar isLoading={null}/>
                    </Col>
                    <Col className="account-profile-col-right px-0">
                        <div className="mb-2">
                            <h5 className="display_lg_header d-flex align-items-center">
                                <span>Return Request</span>
                            </h5>
                        </div>
                        <div className="return__req">
                            <div className="d-flex align-items-center justify-content-end">
                                <Link to={newreturnRequestPathUrl}><Button variant="outline-secondary" className="d-flex align-items-center btn__new__request ">
                                    <FiPlus fontSize={15} />
                                    <span>New return request </span>
                                </Button></Link>
                            </div>
                            <div className="main--content_div">
                                <div><EmptyRequest /></div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
    </DefaultLayout>
  )
}
