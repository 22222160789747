import React, { useEffect, useState } from 'react'
import { animateScroll } from "react-scroll";
import MessagingStreams from '../../../../LiveCommerce/SingleLiveCommerce/components/MessagingStreams';

interface MessageFramePropsType {
    nickname: string;
    clientRef:any;
    sellerId:string;
    setIsDisconected: any;
    setIsConnected: any;
}

export const MessageFrame = ({nickname,clientRef,sellerId,setIsDisconected,setIsConnected}:MessageFramePropsType) => {
    const [msgList, setMsgList] = useState<{from:string;text:string}[]>([]);

    useEffect(() => {
        if(msgList){
            scrollToBottom()
        }
    }, [msgList]);
  
  
    const scrollToBottom = () => {
      animateScroll.scrollToBottom({
        containerId: "messaging__chatbox_area"
      });
    }

  return (
    <div  className="message-frame-area">
        <div className="header">
            <h5>Message</h5>
        </div>
        <div id="messaging__chatbox_area" className="message-chat-box">
        {msgList.length > 0 ? msgList.map((item,index) => (
                <>
                {item.from === "seller" ? (
                    <div className="message-chat outgoing">
                        <div className="message-details">
                         <p>
                            <span className="msg__u__info">{item.from}</span>
                            <span className="msg__u__content"> {item.text}</span>
                         </p>
                        </div>
                    </div>

                ):(
                <div className="message-chat incoming">
                  <div className="message-details">
                      <p>
                       <span className="msg__u__info">{item.from}</span>
                       <span className="msg__u__content"> {item.text}</span>
                    </p>
                    </div>
                </div>
             )}
            </>
        )): <div className="starter-msg">Start Conversation</div>}
            
       
        </div>
        <div className="message-chat-input">
            <MessagingStreams
                from={nickname}
                clientRef={clientRef}
                sellerId={sellerId}
                setIsDisconected={setIsDisconected}
                setIsConected={setIsConnected}
                setMsgList={setMsgList}
        /> 
        </div>
    </div>
  )
}
