import React from 'react';
import { Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const ProductDetailLoader = () => {
  return (
    <>
      <div className="mt-3 _t_wrapper">
        <div className="_sub_wrapper">
          <Skeleton width={200} height={10} />
        </div>
      </div>
      <div className="CCS  mt-4">
        <div className="_prod_wrapper_">
          <div className="_top_level_">
            <div className="row">
                <Col className="d-none d-lg-block col-lg-1">
                  <Skeleton width={50} height={50} />
                  <Skeleton width={50} height={50} />
                </Col>
                <Col className="col-12 col-lg-10">
                    <Skeleton width={"40%"} height={400} />
                </Col>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
