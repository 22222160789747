import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { animateScroll } from "react-scroll";
import styles from "./Footer.module.scss";
import classnames from 'classnames';
import { Button, Field, Logo, Typography } from '@ekenta/components';
import { ArrowUp } from '../icons';
import FloatingFab from './FloatingFab/FloatingFab';
import { TriggerHelpCanvasContext } from "../../context/TriggerHelpCanvasContext";
import InputPrepend from './../InputField/InputPrepend';
import TwitterSvg from "@ekenta/assets/icons/twitter.svg";
import FacebookSvg from "@ekenta/assets/icons/facebook.svg";
import InstagramSvg from "@ekenta/assets/icons/instagram.svg";
import ChatSvg from "@ekenta/assets/icons/chat.svg";
import WhatAppSvg from "@ekenta/assets/icons/whatapp.svg";
import MailSvg from "@ekenta/assets/icons/envelope.svg";
import PlayStoreSvg from "@ekenta/assets/icons/play-store.svg";
import AppleStoreSvg from "@ekenta/assets/icons/app-store.svg";
import FeedBackSvg from "@ekenta/assets/icons/feedback.svg";



const Footer = () => {
    const { handleHelpShowCanvas } = useContext(TriggerHelpCanvasContext);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }


    const itemList = [
        {
            id: "need-support",
            title: "Need Support",
            list: [
                { item: "Email Assistant Help" },
                { item: "Help@ekenta.com" }
            ]
        },
        {
            id: "phone-assistance",
            title: "Phone Assistance",
            list: [
                { item: "081 4655 2089" }
            ]
        },
        {
            id: "whatapp-assistance",
            title: "WhatsApp Assistance",
            list: [
                { item: "081 4655 2089" }
            ]
        }
    ];

    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.FooterMain_Wrapper}>
            <Container className={styles.FooterMain_Wrapper__Container}>
                <div className={styles.FooterMain_Wrapper__BackToTop}
                    onClick={scrollToTop}>
                    <ArrowUp />
                    <Typography
                        as="span"
                        size="small"
                        color="light"
                        className={styles.FooterMain_Wrapper__BackToTop__Span}>
                        Back to top
                    </Typography>
                </div>
                <div className={styles.FooterMain_Wrapper__Content}>
                    <Row>
                        <Col
                            xs={12}

                            className={styles.FooterMain_Wrapper__FooterItem}>
                            <Logo
                                width="100"
                                height="32"
                            />
                            <Typography
                                as="div"
                                size="small"
                                color="light"
                                uiText
                                className={classnames(styles.FooterMain_Wrapper__FooterItem__About)}>
                                Ekenta is the world’s leading social commerce
                                for buyer and sellers to achieve an
                                social experience.
                            </Typography>
                        </Col>
                        {itemList.map((item, index) => (
                            <FooterItem
                                key={`${item.id}_` + index}
                                title={item.title}
                                itemList={item.list}
                            />
                        ))}
                        <Col
                            xs={12}
                            className={styles.FooterMain_Wrapper__FooterItem}>
                            <Typography
                                as="h5"
                                size="medium"
                                color="light"
                                heading
                                className={styles.FooterMain_Wrapper__FooterItem__Title}>
                                Get Connected with us on
                            </Typography>
                            <div>
                                <ul className={styles.FooterMain_Wrapper__FooterItem__SocialList}>
                                    <li className={styles.FooterMain_Wrapper__FooterItem__SocialList__Item}>
                                        <a href="#" title="Twitter Ekenta Nigeria" target="_blank" rel="noopener">
                                            <SVG src={TwitterSvg} />
                                        </a>
                                    </li>
                                    <li className={styles.FooterMain_Wrapper__FooterItem__SocialList__Item}>
                                        <a href="#" title="Facebook Ekenta Nigeria" target="_blank" rel="noopener">
                                            <SVG src={FacebookSvg} />
                                        </a>
                                    </li>
                                    <li className={styles.FooterMain_Wrapper__FooterItem__SocialList__Item}>
                                        <a href="#" title="Instagram Ekenta Nigeria" target="_blank" rel="noopener">
                                            <SVG src={InstagramSvg} />
                                        </a>
                                    </li>
                                </ul>
                                <Typography
                                    as="h5"
                                    size="medium"
                                    color="light"
                                    heading
                                    className={styles.FooterMain_Wrapper__FooterItem__SubTitle}>
                                    New To Ekenta
                                </Typography>
                                <div className="mt-2">
                                    <InputPrepend>
                                        <Field
                                            id="subscribe-id"
                                            name="subscribe"
                                            type="email"
                                            placeholder="Email address"
                                            fieldClassName={styles.FooterMain_Wrapper__FooterItem__Field}
                                            onChange={() => console.log("subscribe")}
                                        />
                                        <Button
                                            name="Subscribe"
                                            bgVariant="primary"
                                            textColor="white"
                                            shadow={false}
                                            className={styles.FooterMain_Wrapper__FooterItem__Button}
                                        />
                                    </InputPrepend>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Col className={styles.FooterMain_Wrapper__Bottom}>
                <div className={styles.FooterMain_Wrapper__Bottom__Group}>
                    <Typography
                        as="h5"
                        size="medium"
                        color="light"
                        heading
                        className={styles.FooterMain_Wrapper__Bottom__Heading}>
                        &copy; {currentYear} Ekenta, Inc. All rights reserved.
                    </Typography>
                    <div className={styles.FooterMain_Wrapper__Bottom__AppStore}>
                        <a title="Download Ekenta app" rel="noopener" target="_blank"><SVG src={PlayStoreSvg} /></a>
                        <a title="Download Ekenta app" rel="noopener" target="_blank"><SVG src={AppleStoreSvg} /></a>
                    </div>

                </div>
            </Col>

            {/* floating fab icon */}
            <FloatingFab
                icon={<SVG src={ChatSvg} />}
                actions={
                    [
                        {
                            text: "Give your feedback",
                            icon: <SVG src={FeedBackSvg} />,
                            type: "feedback",
                            onClick: () => handleHelpShowCanvas()
                        },
                        {
                            text: "Go to WhatsApp",
                            icon: <SVG src={WhatAppSvg} />,
                            type: "whatsapp",
                            onClick: () => console.log("")
                        },
                        {
                            text: "Company Mail",
                            icon: <SVG src={MailSvg} />,
                            type: "mail",
                            onClick: () => window.location.href = "mailto:support@ekenta.com"
                        },

                    ]
                }
            />
        </div>
    )
}


const FooterItem = ({ itemList, title }: { itemList: any, title: string }) => {
    return (
        <Col
            xs={12}
            className={styles.FooterMain_Wrapper__FooterItem}>
            <Typography
                as="h5"
                size="medium"
                color="light"
                heading
                className={styles.FooterMain_Wrapper__FooterItem__Title}>
                {title}
            </Typography>
            <div className={styles.FooterMain_Wrapper__FooterItem__Div}>
                <ul className={styles.FooterMain_Wrapper__FooterItem__List}>
                    {itemList.map((result: any, index: number) => (
                        <li key={index} className={styles.FooterMain_Wrapper__FooterItem__ListItem}>
                            <Typography
                                as="span"
                                size="small"
                                color="light"
                                uiText>
                                {result.item}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </div>
        </Col>
    );
}

export default Footer;