import React from 'react'
import { Breadcrumb, Button, Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet'
import ProfileSideBar from './component/AccountSideBar'
import DefaultLayout from '../../../hoc/Layout/Default-Layout'
import ActiveOrder from './BuyerOrders/component/ActiveOrder';
import CompleteOrder from './BuyerOrders/component/CompleteOrder';
import ClosedOrder from './BuyerOrders/component/ClosedOrder';
import { useAppSelector } from '../../../redux/store/hook';
import BreadCrumb from './component/BreadCrumb';
import img5 from '../../../assets/images/slatelive4.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { pendingReviewsPathUrl } from '../../../routes/constant';
import ReactStars from "react-rating-stars-component"
import  {useFormik, FormikProvider} from 'formik';
import RatingAndReviewFormValidation from '../../../validations/schemas/rating-review.schema';

export default function RatingAndReviews() {
    const { token } = useAppSelector(state => state.auth.authentication);
    const navigate = useNavigate();
    const [ratingError, setRatingError] = React.useState(false)
    const [rating, setRating] = React.useState('')
    const formik = useFormik({
        initialValues:{   
            name: '',
            title: '',
            details: ''
        },
       validationSchema: RatingAndReviewFormValidation(),
          onSubmit: values => {
           
            const data = {
                name:   values.name,
                details: values.details,
                title: values.title,
                rating: rating
            };
            console.log(data);
            if(rating == ''){
                return setRatingError(true) 
            }else{
                setRatingError(false)  
            }
            // updateUser({ data, token });
           
        }
    });

    const ratingChanged = (newRating: any) => {
        setRating(newRating)
    };

    return (
        <DefaultLayout>
            <Helmet>
                <title>Manage Account - Rating and Reviews - Ekenta</title>
                <meta charSet="utf-8" />
                <meta name="title" content="Ekenta Order Page" />
                <meta name="description"
                    content="Ekenta Update Order Page"
                />
                <meta property="og:locale" content="en_NG" />
                <meta name="robots" content="noindex,follow" />
            </Helmet>
            <Container className="account-user-container">
                <><BreadCrumb activePage="Order History" /></>

                <Row className="account-pro-row g-2">
                    <Col xs lg={3} className="d-none d-lg-block account-col-left">
                        <ProfileSideBar isLoading={null} />
                    </Col>
                    <Col className="account-profile-col-right px-0">
                        <div className="mb-1  ">
                            <h5 className="mx-0 display_lg_header d-flex align-items-center">
                                <a onClick={() => navigate(`${pendingReviewsPathUrl}`)} style={{cursor:'pointer'}}>
                                    <svg className="" width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0303 8.53033C11.3232 8.23744 11.3232 7.76256 11.0303 7.46967C10.7374 7.17678 10.2626 7.17678 9.96967 7.46967L5.96967 11.4697C5.82322 11.6161 5.75 11.8081 5.75 12C5.75 12.1017 5.77024 12.1987 5.80691 12.2871C5.84351 12.3755 5.89776 12.4584 5.96967 12.5303L9.96967 16.5303C10.2626 16.8232 10.7374 16.8232 11.0303 16.5303C11.3232 16.2374 11.3232 15.7626 11.0303 15.4697L8.31066 12.75H18C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25H8.31066L11.0303 8.53033Z" fill="black" />
                                    </svg>
                                </a>
                                <span>Rate &amp; Review</span>
                            </h5>
                        </div>
                        <div className="buyer__pg_tabs p-2">
                            <h4 className="mb-2">Select the stars to rate this product</h4>
                            <div className="row card p-2">
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12 mt-3">
                                        <div className="row g-0">
                                            <div className="col-3 col-md-3 pe-0 card--image_div">
                                                <img src={img5} className="img-fluid rounded-start" alt="..." />
                                            </div>
                                            <div className="col-7 col-md-7 ps-0">
                                                <div className="card-body">
                                                    <div className="top--section__d">
                                                        <p className="headings_review">{'Sit amet ultrices vestibulum eu quis nam diam nulla.'}</p>
                                                        <Form.Group className="mb-3 position-relative">
                                                            <ReactStars
                                                                count={5}
                                                                name='rating'
                                                                onChange={ratingChanged}
                                                                size={48}
                                                                color="#C4C4C4"
                                                                activeColor="#E04826"
                                                            />
                                                            {ratingError ? (
                                                                <small className="_helper_text" style={{color: 'red'}}>
                                                                    Rating is required
                                                                </small>
                                                            ): null}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="col-lg-12 mt-1">
                                            <h4>Leave a review</h4>
                                        </div>
                                        <div className="row mt-5">
                                            <Col sm={6} lg={6}>
                                                <Form.Group className="mb-3 position-relative">
                                                    <Form.Label className="_label">Your name</Form.Label>
                                                    <Form.Control  type="text" name="name"
                                                    placeholder=""
                                                    autoComplete='off'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                    isInvalid={!!formik.errors.name}
                                                    />
                                                    {formik.touched.name && formik.errors.name ? (
                                                        <Form.Control.Feedback className="_helper_text" type="invalid">
                                                            {formik.errors.name}
                                                        </Form.Control.Feedback>
                                                    ): null}
                                                </Form.Group>

                                            </Col>
                                            <Col sm={6} lg={6}>
                                                <Form.Group className="mb-3 position-relative">
                                                    <Form.Label className="_label">Review Title</Form.Label>
                                                    <Form.Control  type="text" name="title"
                                                    placeholder=""
                                                    autoComplete='off'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.title}
                                                    isInvalid={!!formik.errors.title}
                                                    />
                                                    {formik.touched.title && formik.errors.title ? (
                                                        <Form.Control.Feedback className="_helper_text" type="invalid">
                                                            {formik.errors.title}
                                                        </Form.Control.Feedback>
                                                    ): null}
                                                </Form.Group>

                                            </Col>
                                            <Col sm={12} lg={12}>
                                                <Form.Group className="mb-3 position-relative">
                                                    <Form.Label className="_label">Review Details</Form.Label>
                                                    <Form.Control  type="text" name="details" as="textarea"
                                                    placeholder=""
                                                    autoComplete='off'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.details}
                                                    isInvalid={!!formik.errors.details}
                                                    />
                                                    {formik.touched.details && formik.errors.details ? (
                                                        <Form.Control.Feedback className="_helper_text" type="invalid">
                                                            {formik.errors.details}
                                                        </Form.Control.Feedback>
                                                    ): null}
                                                </Form.Group>

                                            </Col>
                                        </div>
                                        <Button disabled={!formik.isValid} className="text-white save-button w-100" type="submit">
                                            <span>Submit</span>
                                        </Button>
                                    </div>
                                </Form>
                            </FormikProvider>

                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </DefaultLayout>
    )
}


